import {
  LOAD_MASTERDATA_VEHICLE,
  LOAD_MASTERDATA_DRIVER,
  LOAD_VEHICLE,
  LOAD_AUTOCOMPLETE,
  SELECTED_AUTOCOMPLETE,
  LOAD_AUTOCOMPLETE_DRIVER_NAME,
  SELECTED_AUTOCOMPLETE_DRIVER_NAME,
  LOAD_AUTOCOMPLETE_DRIVER1,
  SELECTED_AUTOCOMPLETE_DRIVER1,
  LOAD_AUTOCOMPLETE_DRIVER2,
  SELECTED_AUTOCOMPLETE_DRIVER2,
  LOAD_AUTOCOMPLETE_DRIVER_CODE,
  SELECTED_AUTOCOMPLETE_DRIVER_CODE,
  CREATE_VEHICLE,
  MATCH_VEHICLE,
  UNMATCH_VEHICLE,
  LOAD_VEHICLE_FOR_EDIT,
  UPDATE_MASTERDATA_VEHICLE,
  LOAD_DRIVER_HISTORY,
  LOAD_VEHICLE_PART,
  REMOVE_LISTS,
} from "./actions";

const initialState = {
  lists: [],
  total: 1,

  driverhistory_lists: [],
  driverhistory_total: 1,
  driverhistory_loading: false,

  part_lists: [],
  part_total: 1,
  part_loading: false,

  listsdriver: [],
  totaldriver: 1,
  loading: false,
  loadingdriver: false,
  loadingvehicle: false,
  check1: true,
  draw: -1,
  autoComplete: [],
  autoCompleteDriverName: {},
  autoCompleteDriver1: [],
  autoCompleteDriver2: [],
  autoCompleteDriverCode: [],
  vehicleforedit: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;

    case LOAD_MASTERDATA_VEHICLE.REQUEST:
      const { loading } = action.payload.data;
      return {
        ...state,
        loading: loading,
      };

    case LOAD_MASTERDATA_VEHICLE.SUCCESS:
      const { page } = action.payload;

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };

    case LOAD_MASTERDATA_DRIVER.REQUEST:
      const loadingdriver = action.payload.loading;
      return {
        ...state,
        loadingdriver: loadingdriver,
      };

    case LOAD_MASTERDATA_DRIVER.SUCCESS:
      const pagedriver = action.payload.page;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (pagedriver > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (pagedriver - 1) * 10;
      }

      return {
        ...state,
        listsdriver: action.payload.lists.data,
        totaldriver:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loadingdriver: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };

    case LOAD_AUTOCOMPLETE.SUCCESS:
      console.log();
      return {
        ...state,
        autoComplete: action.payload,
      };

    // case SELECTED_AUTOCOMPLETE:
    //     const { id, lists, selectedName, cb } = action.payload;

    //     let selected = lists.filter(function(ele) {
    //             return ele.id == id;
    //         })[0];
    //         cb(selected);
    //     return { ...state, [selectedName]: selected, autoComplete: [] };

    case LOAD_AUTOCOMPLETE_DRIVER_NAME.SUCCESS:
      return {
        ...state,
        autoCompleteDriverName: action.payload,
      };

    case LOAD_AUTOCOMPLETE_DRIVER1.SUCCESS:
      return {
        ...state,
        autoCompleteDriver1: action.payload,
      };

    case LOAD_AUTOCOMPLETE_DRIVER2.SUCCESS:
      return {
        ...state,
        autoCompleteDriver2: action.payload,
      };

    // case SELECTED_AUTOCOMPLETE_DRIVER_NAME:
    //     console.log("============ reduce =================");
    //     console.log(action.payload);
    //     const { id, lists, selectedName, cb } = action.payload;

    //     let selected = lists.filter(function(ele) {
    //             return ele.id == id;
    //         })[0];
    //         cb(selected);
    //     return { ...state, [selectedName]: selected, autoComplete: [] };
    case LOAD_AUTOCOMPLETE_DRIVER_CODE.SUCCESS:
      return {
        ...state,
        autoCompleteDriverCode: action.payload,
      };

    case CREATE_VEHICLE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case MATCH_VEHICLE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UNMATCH_VEHICLE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LOAD_VEHICLE_FOR_EDIT.REQUEST:
      return {
        ...state,
        loadingvehicle: action.payload.loading,
      };

    case LOAD_VEHICLE_FOR_EDIT.SUCCESS:
      console.log("success");
      return {
        ...state,
        vehicleforedit: action.payload.listsVehicle.data[0],
        loadingvehicle: false,
        check1: false,
      };

    case UPDATE_MASTERDATA_VEHICLE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case LOAD_DRIVER_HISTORY.REQUEST:
      return {
        ...state,
        driverhistory_loading: action.payload.data.loading,
      };

    case LOAD_DRIVER_HISTORY.SUCCESS:
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (action.payload.page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber +
            (action.payload.page - 1) * 10;
      }
      return {
        ...state,
        driverhistory_lists: action.payload.lists.data,
        driverhistory_total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        driverhistory_loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };

    case LOAD_VEHICLE_PART.REQUEST:
      return {
        ...state,
        part_loading: action.payload.data.loading,
      };

    case LOAD_VEHICLE_PART.SUCCESS:
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (action.payload.page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber +
            (action.payload.page - 1) * 10;
      }
      return {
        ...state,
        part_lists: action.payload.lists.data,
        part_total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        part_loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };

    default:
      return state;
  }
};

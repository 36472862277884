import { createTypes, createAction } from "lib/action";
const LOAD_VEHICLES_VERIFY = createTypes("vehiclesverify", "load");
const LOAD_DETAILS_VEHICLES_VERIFY = createTypes("detailsVehicleVerify", "load");
const VERIFY_VEHICLE = createTypes("verifyVehicle", "create");

const loadVehiclesVerify = {
  request: (data, loading) =>
      createAction(LOAD_VEHICLES_VERIFY.REQUEST, { data, loading}),
  success: (lists, page) =>
      createAction(LOAD_VEHICLES_VERIFY.SUCCESS, {lists, page}),
  failure: () => createAction(LOAD_VEHICLES_VERIFY.FAITLURE)  
};

const loadDetailsVehicleVerify = {
  request: (data, loading) =>
      createAction(LOAD_DETAILS_VEHICLES_VERIFY.REQUEST, {data, loading}),
  success: (listsVehicle) =>
      createAction(LOAD_DETAILS_VEHICLES_VERIFY.SUCCESS, {listsVehicle}),
  failure: () => createAction(LOAD_DETAILS_VEHICLES_VERIFY.FAITLURE)
};

const verifyVehicle = {
  request: (data, loading) =>
      createAction(VERIFY_VEHICLE.REQUEST, { data, loading}),
  success: res => createAction(VERIFY_VEHICLE.SUCCESS, { res }),
      failure: () => createAction(VERIFY_VEHICLE.FAILURE)
};

export{
  loadVehiclesVerify,
  LOAD_VEHICLES_VERIFY,
  loadDetailsVehicleVerify,
  LOAD_DETAILS_VEHICLES_VERIFY,
  verifyVehicle,
  VERIFY_VEHICLE,
};
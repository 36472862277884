import React, { Component, Fragment } from "react";
import { Icon, Table, Tag, Tooltip, Badge } from "antd";
import GoogleMapReact from "google-map-react";
export class CustomMap extends Component {
  render() {
    return (
      <GoogleMapReact {...this.props}>{this.props.children}</GoogleMapReact>
    );
  }
}

export default CustomMap;

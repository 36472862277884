import React, { Component } from "react";
import { Col, Steps, Row, Button, Modal, message } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import {
  StyledDiv,
  StyledFormRegister,
  StyledStepFloat,
  StyledCircleStep,
  StyleBackgroundImage
} from "../../../styled/common-styled";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { loadProvince, loadAmphur, loadSubAmphur } from "../actions";
import Personalform from "./Personalform";
import Vehicledriverform from "./Vehicledriverform";
import "../../../styled/formstep.css";

const { Step } = Steps;
class Personal extends Component {
  state = {
    loading: false,
    current: 0,
    vehicle_type: [],
    phone: "",
    email: "",
    step: 4,
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current, step: "4/4" });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  componentDidMount() {
    this.vehicleyType();
    this.getUserInfor();
    // console.log("id", this.props.match.params.id);
  }

  onSubmit = (values) => {
    //const { id } = this.props;
    const {
      title_name,
      firstname,
      lastname,
      buildingname,
      roomnumber,
      floor,
      village,
      houseno,
      villageno,
      soi,
      province,
      district,
      subdistrict,
      postalcode,
      fileidcard,
      filedriverlicense,
    } = values;
    let self = this;

    this.setState({ loading: true });

    const formData = new FormData();
    // let fileIdCard_new = null;
    // let filedriverlicense_new = null;

    fileidcard.forEach((File) => {
      formData.append("fileidcard", File);
      //fileIdCard_new = File;
    });

    filedriverlicense.forEach((File) => {
      formData.append("filedriverlicense", File);
      //filedriverlicense_new = File;
    });

    formData.append("id", this.props.match.params.id);

    formData.append("title_name", title_name);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("buildingname", buildingname);
    formData.append("roomnumber", roomnumber);
    formData.append("floor", floor);
    formData.append("village", village);
    formData.append("houseno", houseno);
    formData.append("villageno", villageno);
    formData.append("soi", soi);
    formData.append("province", province);
    formData.append("district", district);
    formData.append("subdistrict", subdistrict);
    formData.append("postalcode", postalcode);

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/personalregister`,
        formData
      )
      .then(function(response) {
        if (response.status === "fail") {
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okText: (
              <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        } else {
          self.next();
          self.setState({ loading: false });
        }
      })
      .catch(function(error) {
        // console.log("error:", error);
        Modal.error({
          content: (
            <LangContext.Consumer>
              {(i18n) => i18n.f.fail_save}
            </LangContext.Consumer>
          ),
          okText: (
            <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
          ),
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
        self.setState({ loading: false });
      });
  };

  vehicleyType = () => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicletype`,
        {}
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let vehicle_type = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name,
            };
          });
          self.setState({ vehicle_type });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getUserInfor = () => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getuserbyid`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ email: res.email, phone: res.phone });
      });
  };

  render() {
    const { current } = this.state;
    const steps = [
      {
        title: "",
        content: (
          <Personalform
            // next={this.next}
            prev={this.prev}
            onSubmit={this.onSubmit}
            current={current}
            loading={this.state.loading}
            loadProvince={this.props.loadProvince}
            loadAmphur={this.props.loadAmphur}
            loadSubAmphur={this.props.loadSubAmphur}
            provinceLists={this.props.provinceLists}
            amphurLists={this.props.amphurLists}
            subamphurLists={this.props.subamphurLists}
            email={this.state.email}
            phone={this.state.phone}
            id={this.props.match.params.id}
            step={this.state.step}
          />
        ),
      },
      {
        title: "",
        content: (
          <Vehicledriverform
            // next={this.next}
            prev={this.prev}
            current={current}
            loading={this.state.loading}
            loadProvince={this.props.loadProvince}
            provinceLists={this.props.provinceLists}
            vehicle_type={this.state.vehicle_type}
            id={this.props.match.params.id}
            step={this.state.step}
          />
        ),
      },
    ];

    return (
      <StyledDiv>
      <StyleBackgroundImage top="65px"/>
        <StyledFormRegister>
          {/* <Row gutter={24}> */}
            {/* <StyledStepFloat>
              <StyledCircleStep>{this.state.step}</StyledCircleStep>
            </StyledStepFloat> */}
            {/* <Col span={2} />
            <Col span={20}>
              <Steps current={this.state.current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
            <Col span={2} /> */}
          {/* </Row> */}
          <Row gutter={24}>
              {steps[this.state.current].content}
          </Row>
        </StyledFormRegister>
      </StyledDiv>
    );
  }
}

export default connect(
  ({
    token,
    profile,
    auth: { provinceLists, amphurLists, subamphurLists, accessToken },
  }) => ({
    token,
    profile,
    provinceLists,
    amphurLists,
    subamphurLists,
  }),
  {
    loadProvince: loadProvince.request,
    loadAmphur: loadAmphur.request,
    loadSubAmphur: loadSubAmphur.request,
  }
)(Personal);

import {
  REMOVE_LISTS,
  LOAD_LOGISTICGROUP,
  CREATE_LOGISTICGROUP,
  LOAD_LOGISTICGROUP_DETAIL,
  UPDATE_LOGISTICGROUP,
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  logisticgroupDetailloading: false,
  draw: -1,
  success: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;

    case LOAD_LOGISTICGROUP.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading,
      };
    case LOAD_LOGISTICGROUP.SUCCESS:
      const { page } = action.payload;

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };
    case LOAD_LOGISTICGROUP_DETAIL.REQUEST:
      //  const { logisticgroupDetailloading } = action.payload;
      return {
        ...state,
        loading: loading,
      };

    case LOAD_LOGISTICGROUP_DETAIL.SUCCESS:
      const { detailvehiclepage } = action.payload;
      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (detailvehiclepage > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber +
            (detailvehiclepage - 1) * 10;
      }
      console.log(action);
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };
    case CREATE_LOGISTICGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_LOGISTICGROUP.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

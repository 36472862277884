import React from "react";
import { Switch, Route } from "react-router-dom";
import Expense from "./Index";
import Add from "./Add";
import Edit from "./Edit";
import Detail from "./Detail";
export default () => (
  <Switch>
    <Route exact path="/route-expense" component={Expense} />
    <Route path="/route-expense/create" component={Add} />
    <Route path="/route-expense/edit/:id" component={Edit} />
    <Route path="/route-expense/detail/:id" component={Detail} />
  </Switch>
);

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  GeneralStyledContent,
  PageTitleStyled,
} from "../../../../styled/backhaul-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import {
  Row,
  Col,
  Divider,
  Table,
  Pagination,
  Spin,
  Button,
  Modal,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import Forms from "./Forms";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { numberFormat, translate } from "../../../../lib/helper";
import axios from "axios";
import Directjob from "../../worksheet/components/Directjob";
//const { confirm } = Modal;
const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "r",
    keyLang: "row_number",
    dataKey: "row_number",
    sorter: false,
    render: "normal",
    width: 60,
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_job",
    dataKey: "document_number",
    sorter: false,
    render: "normal",
    width: 120,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "company_name",
    dataKey: "customer",
    sorter: false,
    render: "normal",
    width: 170,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "product_detail",
    dataKey: "product_name",
    sorter: false,
    render: "m_product_type",
    width: 200,
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: false,
    render: "normal",
    width: 160,
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "normal",
    width: 160,
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_receiver",
    dataKey: "received_name",
    sorter: false,
    render: "received_name",
    width: 160,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "origin_receive_date",
    dataKey: "origin_receive_date",
    sorter: false,
    render: "normal",
    width: 140,
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_delivery_time",
    dataKey: "receive_date",
    sorter: false,
    render: "normal",
    width: 160,
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_vehicle_type",
    dataKey: "vehicle_type",
    sorter: false,
    render: "vehicle_type",
    width: 120,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "service_payment_type",
    dataKey: "service_payment_type",
    sorter: false,
    render: "translate",
    width: 80,
    cls: "vertical-top",
  },
  {
    idxLang: "expense",
    keyLang: "price",
    dataKey: "price",
    sorter: false,
    render: "normal",
    width: 80,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "unit",
    dataKey: "price_unit",
    sorter: false,
    render: "m_unit_price",
    width: 120,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "credit_term",
    dataKey: "credit_term",
    sorter: false,
    render: "m_credit_term",
    width: 120,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "staff_lift",
    dataKey: "staff_lift",
    sorter: false,
    render: "translate",
    width: 80,
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "comment",
    dataKey: "comment",
    sorter: false,
    render: "normal",
    width: 150,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "frequency_routine",
    dataKey: "frequency_routine",
    sorter: false,
    render: "m_frequency_routine",
    width: 150,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "contract_term",
    dataKey: "contract_term",
    sorter: false,
    render: " m_contract_term",
    width: 150,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "name_contact_load",
    dataKey: "name_contact_load",
    sorter: false,
    render: "contact_load_name",
    width: 150,
    cls: "vertical-top",
  },
  {
    idxLang: "worksheet",
    keyLang: "phone_contact_load",
    dataKey: "phone_contact_load",
    sorter: false,
    render: "normal",
    width: 120,
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "createdAt",
    dataKey: "created_at",
    sorter: false,
    render: "normal",
    width: 150,
    cls: "vertical-top",
  },
];

const getText = (key, cateKey) => (
  <LangContext.Consumer>{(i18n) => i18n[cateKey][key]}</LangContext.Consumer>
);

const getCreditTerm = (key) => (
  <LangContext.Consumer>
    {(i18n) => i18n.m_credit_term[key]}
  </LangContext.Consumer>
);

const getVehicleType = (key) => (
  <LangContext.Consumer>
    {(i18n) => i18n.vehicle_type[key]}
  </LangContext.Consumer>
);

const getProductType = (key) => (
  <LangContext.Consumer>
    {(i18n) => i18n.m_product_type[key]}
  </LangContext.Consumer>
);

const getUnitPrice = (key) => (
  <LangContext.Consumer>
    {(i18n) => i18n.m_unit_price[key]}
  </LangContext.Consumer>
);

const getContractTerm = (key) => (
  <LangContext.Consumer>
    {(i18n) => i18n.m_contract_term[key]}
  </LangContext.Consumer>
);

const getFrequencyRoutine = (key) => (
  <LangContext.Consumer>
    {(i18n) => i18n.m_frequency_routine[key]}
  </LangContext.Consumer>
);

const renderReceivedName = (items) => {
  return items.detail.map((val, i) => {
    let line = i + 1;
    return (
      <p>
        {line} {getText(val.contact_titlename, "m")} {val.receiver}
      </p>
    );
  });
};

const renderContactLoadName = (val) => {
  return (
    <p>
      {getText(val.title_contact_load, "m")} {val.name_contact_load}
    </p>
  );
};

const renderNormalTxt = (val) => {
  return <div dangerouslySetInnerHTML={{ __html: val }} />;
};

class Index extends Component {
  state = {
    direct_visible: false,
    logisticgroupLists: [],
    transporterLists: [],
    page: 1,
    currentPage: 1,
    pageSize: 10,
    totalRows: 0,
    loading: false,
    lists: [],
    data: {
      id: "",
      origin: "",
      document_number: "",
      detail: [
        {
          cause_reject_id: "",
          cause_some_part_id: "",
          complete_quantity: "",
          confirm_deliver_at: "",
          confirm_receive_at: "",
          contact_firstname: "",
          contact_lastname: "",
          contact_phone: "",
          contact_titlename: "",
          created_at: "",
          destination: "",
          destination_id: "",
          document_img_path: "",
          id: "",
          product_img_path: "",
          quantity: "",
          reason: "",
          receive_date: "",
          seq: "",
          status: "",
          unit: "",
          updated_at: "",
          worksheet_id: "",
        },
      ],
    },
  };

  loadLogisticgroup = () => {
    let self = this;
    const ACCESSTOKEN = self.props.auth.accessToken;
    const COMPANY_ID = self.props.auth.profile.company_id;

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlogisticgroupbycompany`,
        {
          company_id: COMPANY_ID,
        },
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let logisticgroupLists = response.data.data.map((d) => {
            return {
              key: d.id,
              name: d.name,
            };
          });
          self.setState({ logisticgroupLists });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  loadTransporter = () => {
    let self = this;
    const ACCESSTOKEN = self.props.auth.accessToken;
    const COMPANY_ID = self.props.auth.profile.company_id;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettransporter`,
        {
          company_id: COMPANY_ID,
        },
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let transporterLists = response.data.data.map((d) => {
            return {
              id: d.id,
              name: d.name,
            };
          });
          self.setState({ transporterLists });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  genTableHeader = () => {
    const columns = [];
    const type = this.props.match.params.type;
    tableHeader.map((v) => {
      if (
        (type === "onetime" &&
          v.dataKey !== "frequency_routine" &&
          v.dataKey !== "contract_term") ||
        type === "routine"
      ) {
        columns.push({
          title: getText(v.keyLang, v.idxLang),
          dataIndex: `${v.dataKey}`,
          key: `${v.dataKey}`,
          sorter: v.sorter,
          width: v.width,
          className: v.cls,
          render: (text, record, index) => {
            return {
              props: {
                style: columnStyle,
              },
              children: this.renderComponent(
                v.render,
                record,
                v.dataKey,
                v.idxLang
              ),
            };
          },
        });
      }
    });

    return columns;
  };

  renderComponent = (type, val, key, idxLang) => {
    switch (type) {
      case "received_name":
        return renderReceivedName(val);
      case "contact_load_name":
        return renderContactLoadName(val);
      case "translate":
        return getText(val[key], idxLang);
      case "vehicle_type":
        return getVehicleType(val[key]);
      case "m_product_type":
        return getProductType(val[key]);
      case "m_credit_term":
        return getCreditTerm(val[key]);
      case "m_unit_price":
        return getUnitPrice(val[key]);
      case "m_contract_term":
        return getContractTerm(val[key]);
      case "m_frequency_routine":
        return getFrequencyRoutine(val[key]);
      default:
        return renderNormalTxt(val[key]);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.loadWorkSheet({
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
    });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.loadWorkSheet({
      page: page,
      pageSize: pageSize,
    });
  };

  renderExpandable = (record, index, indent, expanded) => {
    const columnsA = [
      {
        title: getText("row_number", "r"),
        dataIndex: "seq",
        key: "seq",
        width: 70,
      },
      {
        title: getText("md_destination", "m"),
        dataIndex: "destination",
        key: "destination",
        width: 200,
      },
      {
        title: getText("md_receiver", "m"),
        dataIndex: "receiver",
        key: "receiver",
        width: 150,
        render: (val, rec) => (
          <span>
            {getText(rec.contact_titlename, "m")} {val}
          </span>
        ),
      },
      {
        title: getText("phone", "p"),
        dataIndex: "contact_phone",
        key: "contact_phone",
        width: 150,
      },
      {
        title: getText("md_sending_qty", "m"),
        dataIndex: "quantity",
        key: "quantity",
        width: 120,
        render: (val) => numberFormat(val, 0),
      },
      {
        title: getText("unit", "worksheet"),
        dataIndex: "unit",
        key: "unit",
        width: 120,
      },
    ];
    const data =
      typeof this.state.lists[index] !== "undefined"
        ? this.state.lists[index].detail
        : [];
    return <Table columns={columnsA} dataSource={data} pagination={false} />;
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.loadWorkSheet(page, pageSize);
    this.loadLogisticgroup();
    this.loadTransporter();
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.props.type,prevProps.type).match.params.type
    if (this.props.match.params.type !== prevProps.match.params.type) {
      const { page, pageSize } = this.state;
      this.loadWorkSheet(page, pageSize);
    }
  }

  loadWorkSheet = (page, pageSize) => {
    const worksheet_type = this.props.match.params.type;
    let self = this;
    self.setState({
      loading: true,
    });
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/loadworksheetimport`,
        {
          page,
          pageSize,
          company_id: self.props.auth.profile.company_id,
          worksheet_type,
        },
        {
          headers: { Authorization: `Bearer ${self.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        console.log(response);
        self.setState({
          lists: response.data.data,
          totalRows: response.data.total,
          loading: false,
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleDirectOk = (values) => {
    let self = this;
    const { page, pageSize } = this.state;
    const { list_logistic_id, transporter_id, direct_type } = values;
    const worksheet_type = this.props.match.params.type;
    const ACCESSTOKEN = self.props.auth.accessToken;
    const COMPANY_ID = self.props.auth.profile.company_id;

    const formData = new FormData();
    formData.append("company_id", COMPANY_ID);
    formData.append("worksheet_type", worksheet_type);

    formData.append("list_logistic_id", JSON.stringify(list_logistic_id));
    formData.append("transporter_id", transporter_id);
    formData.append("direct_type", direct_type);

    self.setState({ loading: true });
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/importdirect`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${ACCESSTOKEN}`,
          },
        }
      )
      .then(function(response) {
        console.log("response:", response);
        if (response.data.status === "fail") {
          Modal.error({
            content: i18n[self.props.langBtn].f.fail,
            okText: i18n[self.props.langBtn].o.okay,
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
        } else {
          Modal.info({
            content: i18n[self.props.langBtn].s.success,
            okText: i18n[self.props.langBtn].o.okay,
            okButtonProps: { "data-cy" : "btn-modal-ok" },
            onOk() {
              self.setState({
                direct_visible: false,
                loading: false,
              });
              self.loadWorkSheet(page, pageSize);
            },
          });
        }
      })
      .catch(function(error) {
        // console.log("error:", error);
        Modal.error({
          content: i18n[self.props.langBtn].f.fail,
          okText: i18n[self.props.langBtn].o.okay,
          okButtonProps: { "data-cy" : "btn-modal-ok" },
          onOk() {
            self.setState({ loading: false });
          },
        });
      });
  };

  submitHandle = (type) => {
    let self = this;
    const { page, pageSize } = this.state;
    Modal.confirm({
      // title: i18n.d.doYouWantToAddTheseItems,
      content:
        type === "market"
          ? i18n[self.props.langBtn].worksheet.post_to_market
          : i18n[self.props.langBtn].worksheet.cancel_to_import,
      okText: i18n[self.props.langBtn].o.okay,
      cancelText: i18n[self.props.langBtn].c.cancel,
      okButtonProps: { "data-cy" : "btn-modal-ok" },
      onOk() {
        const ACCESSTOKEN = self.props.auth.accessToken;
        const COMPANY_ID = self.props.auth.profile.company_id;
        const worksheet_type = self.props.match.params.type;

        const formData = new FormData();
        formData.append("company_id", COMPANY_ID);
        formData.append("type_submit", type);
        formData.append("worksheet_type", worksheet_type);

        self.setState({ loading: true });
        axios
          .post(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/importsubmit`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${ACCESSTOKEN}`,
              },
            }
          )
          .then(function(response) {
            if (response.data.status === "fail") {
              Modal.error({
                content: i18n[self.props.langBtn].f.fail,
                okText: i18n[self.props.langBtn].o.okay,
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else {
              Modal.info({
                content: i18n[self.props.langBtn].s.success,
                okText: i18n[self.props.langBtn].o.okay,
                okButtonProps: { "data-cy" : "btn-modal-ok" },
                onOk() {
                  self.setState({
                    loading: false,
                  });

                  self.loadWorkSheet(page, pageSize);
                },
              });
            }
          })
          .catch(function(error) {
            //console.log("error:", error);
            Modal.error({
              content: i18n[self.props.langBtn].f.fail,
              okText: i18n[self.props.langBtn].o.okay,
              onOk() {
                self.setState({ loading: false });
              },
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          });
      },
      onCancel() {},
    });
  };

  handleDirectCancel = (e) => {
    this.setState({
      direct_visible: false,
    });
  };

  showDirectModal = () => {
    this.setState({
      direct_visible: true,
    });
  };

  render() {
    const type = this.props.match.params.type;
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="importworksheet">
        <GeneralStyledContent>
          <Directjob
            visible={this.state.direct_visible}
            onOk={this.handleDirectOk}
            logisticgroupLists={this.state.logisticgroupLists}
            onCancel={this.handleDirectCancel}
            transporterLists={this.state.transporterLists}
          />
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
            <PageTitleStyled>
              {type === "onetime" ? (
                <LangContext.Consumer>
                  {(i18n) => i18n.worksheet.importworksheetonetime}
                </LangContext.Consumer>
              ) : (
                <LangContext.Consumer>
                  {(i18n) => i18n.worksheet.importworksheetroutine}
                </LangContext.Consumer>
              )}
            </PageTitleStyled>
            <Forms
              auth={this.props.auth}
              type={type}
              loadWorkSheet={this.loadWorkSheet}
              page={this.state.page}
              pageSize={this.state.pageSize}
              langBtn={this.props.langBtn}
            />
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                {type==="onetime"?translate("description_onetime", "d"):translate("description_routine", "d")}
              </Divider>
            </Col>
            <Col span={24}>
              <Button
              data-cy="btn-cancel"
                type="danger"
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => this.submitHandle("cancel")}
              >
                <LangContext.Consumer>
                  {(i18n) => i18n.c.cancel}
                </LangContext.Consumer>
              </Button>

              <Button
              data-cy="btn-marget"
                type="primary"
                onClick={() => this.submitHandle("market")}
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <LangContext.Consumer>
                  {(i18n) => i18n.worksheet.market}
                </LangContext.Consumer>
              </Button>

              <Button
              data-cy="btn-direct"
                type="primary"
                onClick={() => this.showDirectModal()}
                style={{
                  marginBottom: "10px",
                }}
              >
                <LangContext.Consumer>
                  {(i18n) => i18n.worksheet.direct}
                </LangContext.Consumer>
              </Button>

              <Spin spinning={this.state.loading}>
                <Table
                data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.state.lists}
                  bordered
                  size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                  expandedRowRender={this.renderExpandable}
                  scroll={{ x: 1500 }}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
              data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.state.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ auth, auth: { profile }, ui: { langBtn } }) => ({
    auth,
    profile,
    langBtn,
  }),
  {}
)(Index);

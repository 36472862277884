import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  GeneralStyledContent,
  StyledSearchForm,
  PageTitleStyled,
} from "../../../../styled/backhaul-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { Row, Col } from "antd";
import LangContext from "modules/shared/context/langContext";

class Index extends Component {
  state = {};

  componentDidMount() {
    //console.log("type:", this.props.match.params.type);
  }

  render() {
    const type = this.props.match.params.type;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <PageTitleStyled>
          {type === "onetime" ? (
            <LangContext.Consumer>
              {(i18n) => i18n.worksheet.downloadworksheetonetime}
            </LangContext.Consumer>
          ) : (
            <LangContext.Consumer>
              {(i18n) => i18n.worksheet.downloadworksheetroutine}
            </LangContext.Consumer>
          )}
        </PageTitleStyled>
        <StyledSearchForm>
          <LangContext.Consumer>
            {(i18n) => (
              <Fragment>
                <table>
                  <tr>
                    <td>
                      {type === "onetime"
                        ? i18n.worksheet.downloadworksheetonetime + " : "
                        : i18n.worksheet.downloadworksheetroutine + " : "}
                    </td>
                    <td>
                      {type === "onetime" ? (
                        <div>
                          <a
                            data-cy="btn-download"
                            href="/dowloadworksheets/template_worksheet_onetime.xlsx"
                            download
                          >
                            {i18n.d.download}
                          </a>
                        </div>
                      ) : (
                        <div>
                          <a
                          data-cy="btn-download"
                            href="/dowloadworksheets/template_worksheet_routine.xlsx"
                            download
                          >
                            {i18n.d.download}
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                </table>
              </Fragment>
            )}
          </LangContext.Consumer>
        </StyledSearchForm>
      </GeneralStyledContent>
    );
  }
}

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {}
)(Index);

import React, { Component } from "react";
import { Col, Steps, Row, Button, Modal, message } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import {
  StyledDiv,
  StyledFormRegister,
  StyledStepFloat,
  StyledCircleStep,
  StyleBackgroundImage,
} from "../../../styled/common-styled";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { loadProvince, loadAmphur, loadSubAmphur } from "../actions";
import Transporterform from "./Transporterform";
import Vehicledriverform from "./Vehicledriverform";
import "../../../styled/formstep.css";

const { Step } = Steps;
class Transporter extends Component {
  state = {
    loading: false,
    current: 0,
    vehicle_type: [],
    phone: "",
    email: "",
    step: 4,
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current, step: "4/4" });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  componentDidMount() {
    this.vehicleyType();
    // this.getUserInfor();
    // console.log("id", this.props.match.params.id);
  }

  onSubmit = (values) => {
    //const { id } = this.props;
    const {
      company_name,
      company_type,
      juristic_id,
      province_head_offfice,
      founded_year,
      number_employee,
      buildingname,
      roomnumber,
      floor,
      village,
      houseno,
      villageno,
      soi,
      province,
      district,
      subdistrict,
      postalcode,
      certificate_file,
      license_transport_file,
      logo_file,
    } = values;
    let self = this;

    this.setState({ loading: true });

    const formData = new FormData();

    certificate_file.forEach((File) => {
      formData.append("certificate_file", File);
    });

    license_transport_file.forEach((File) => {
      formData.append("license_transport_file", File);
    });

    logo_file.forEach((File) => {
      formData.append("logo_file", File);
    });

    formData.append("id", this.props.match.params.id);

    formData.append("company_name", company_name);
    formData.append("company_type", company_type);
    formData.append("juristic_id", juristic_id);
    formData.append("province_head_offfice", province_head_offfice);
    formData.append("founded_year", founded_year);
    formData.append("number_employee", number_employee);
    formData.append("buildingname", buildingname);
    formData.append("roomnumber", roomnumber);
    formData.append("floor", floor);
    formData.append("village", village);
    formData.append("houseno", houseno);
    formData.append("villageno", villageno);
    formData.append("soi", soi);
    formData.append("province", province);
    formData.append("district", district);
    formData.append("subdistrict", subdistrict);
    formData.append("postalcode", postalcode);

    if (this.props.check_contact === false) {
      Modal.error({
        content: (
          <LangContext.Consumer>
            {(i18n) => i18n.signup.contact_require}
          </LangContext.Consumer>
        ),
        okText: (
          <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
        ),
        okButtonProps: { "data-cy" : "btn-modal-ok" }
      });
      self.setState({ loading: false });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/transporterregister`,
          formData
        )
        .then(function(response) {
          if (response.status === "fail") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.f.fail_save}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
            self.setState({ loading: false });
          } else {
            self.next();
            self.setState({ loading: false });
          }
        })
        .catch(function(error) {
          // console.log("error:", error);
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okText: (
              <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        });
    }
  };

  vehicleyType = () => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicletype`)
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let vehicle_type = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name,
            };
          });
          self.setState({ vehicle_type });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  companyType = () => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/companytype`)
      .then(function(response) {
        self.setState({ company_type: response.data.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const { current } = this.state;
    const steps = [
      {
        title: "",
        content: (
          <Transporterform
            onSubmit={this.onSubmit}
            current={current}
            loading={this.state.loading}
            loadProvince={this.props.loadProvince}
            loadAmphur={this.props.loadAmphur}
            loadSubAmphur={this.props.loadSubAmphur}
            provinceLists={this.props.provinceLists}
            amphurLists={this.props.amphurLists}
            subamphurLists={this.props.subamphurLists}
            id={this.props.match.params.id}
            step={this.state.step}
          />
        ),
      },
      {
        title: "",
        content: (
          <Vehicledriverform
            next={this.next}
            prev={this.prev}
            current={current}
            loading={this.state.loading}
            loadProvince={this.props.loadProvince}
            provinceLists={this.props.provinceLists}
            vehicle_type={this.state.vehicle_type}
            id={this.props.match.params.id}
            step={this.state.step}
          />
        ),
      },
    ];

    return (
      <StyledDiv>
        <StyleBackgroundImage top="65px"/>
        <StyledFormRegister>
          {/* <Row gutter={24}> */}
            {/* <StyledStepFloat>
              <StyledCircleStep>{this.state.step}</StyledCircleStep>
            </StyledStepFloat> */}
            {/* <Col span={24}>
              <Steps current={this.state.current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col> */}
            {/* <Col span={2} />
          </Row> */}
          <Row gutter={24}>
            {/* <Col span={2} /> */}
            <Col span={24}>
              <div style={{ margin: "45px 0px 0px 0px" }}>
                {steps[this.state.current].content}
              </div>
            </Col>
            <Col span={2} />
          </Row>
        </StyledFormRegister>
      </StyledDiv>
    );
  }
}

export default connect(
  ({
    token,
    profile,
    auth: {
      provinceLists,
      amphurLists,
      subamphurLists,
      accessToken,
      check_contact,
    },
  }) => ({
    token,
    profile,
    provinceLists,
    amphurLists,
    subamphurLists,
    check_contact,
  }),
  {
    loadProvince: loadProvince.request,
    loadAmphur: loadAmphur.request,
    loadSubAmphur: loadSubAmphur.request,
  }
)(Transporter);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { Row, Col, Layout, Menu, Icon, Modal } from "antd";
import { ACCESSTOKEN, FIRSTNAME } from "../../../constants/local_storage";
import CustomBadge from "./CustomBadge";
import Notification from "./Notification";
import LangContext from "modules/shared/context/langContext";
import * as authActions from "modules/auth/actions";
import {
  clearFlashMessage,
  loadNotificationCount,
  unregisterNotificate,
  removeUIState,
} from "modules/ui/actions";
import { messaging } from "../../../lib/fcm/fcm_init";
import styled from "styled-components";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Header: H } = Layout;
const StyledHearder = styled(H)`
  background: white;
  padding: 0px 0px px 0px;
  height: 45px;
`;

class Header extends Component {
  state = {
    notiCount: 0,
    haveNewNoti: false,
    inputLng: "",
    token: "",
  };

  _isMounted = false;

  onRemoveCredentials = () => {
    this.props.unregisterNotificate({
      username: this.props.profile.username,
    });
    this.props.removeCredentials();
    this.props.removeUIState();
  };

  onDismiss = () => {
    this.props.clearFlashMessage();
  };

  componentDidMount() {
    this._isMounted = true;
    let self = this;
    let { tokenExp } = this.props;
    let timeNow = Date.now();

    if (typeof tokenExp == "undefined" || tokenExp == null || tokenExp == "") {
      console.log("Token Expired");

      setTimeout(function() {
        self.modal(
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {(i18n) => i18n.t.tokenExpired}
          </LangContext.Consumer>
        );
      }, 500);
    }
    if (Math.floor(timeNow / 1000) < tokenExp) {
      let diffTime = (tokenExp - Math.floor(timeNow / 1000)) * 1000;
      this.tokenExpTimeout = setTimeout(function() {
        console.log("Token Expired");
        self.modal(
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {(i18n) => i18n.t.tokenExpired}
          </LangContext.Consumer>
        );
      }, diffTime);
    }

    this.setState({
      inputLng: "th",
    });

    // if (messaging) {     // รับ Token Notification
    //   messaging
    //     .getToken()
    //     .then((token) => {
    //       console.log("Token refreshed.======================");
    //       console.log(token);
    //       this.setToken(token);
    //     })
    //     .catch((err) => {
    //       console.log("Unable to retrieve refreshed token ", err);
    //     });
    // }
  }

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.tokenExpTimeout);
  }

  checkSelectLang = (inputLng) => {
    this.props.changeLang(inputLng);
    if (inputLng == "en") {
      this.setState({
        inputLng: "us",
      });
    } else {
      this.setState({
        inputLng: "th",
      });
    }
  };

  modal = (title, content) => {
    this.onRemoveCredentials();
    Modal.warning({
      title: title,
      okText: "Ok",
      okType: "default",
      okButtonProps: { "data-cy" : "btn-modal-ok" },
      onOk() {
        // window.location.href = "/";
      },
      content: content,
    });
  };

  render() {
    const { accessToken, done, flash, collapsed, toggle, profile } = this.props;

    const CheLan = (
      <LangContext.Consumer>
        {(i18n) => i18n.changeLanguage}
      </LangContext.Consumer>
    );

    return (
      <Fragment>
        <StyledHearder>
          <audio id="audio-alert">
            <source src="/audio/alert/serious-strike.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-sos">
            <source src="/audio/alert/sos.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-notification">
            <source
              src="/audio/notification/slow-spring-board.mp3"
              type="audio/mpeg"
            />
          </audio>
          {/* <NotificationReceiver /> */}
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              lineHeight: "20px",
              padding: "0px 0px 0px 0px",
              margin: "0px 0px 0px 0px",
            }}
          >
            <Col xs={{ span: 0 }} sm={8} md={4} lg={4} xl={4}>
              <Icon
                style={{ marginTop: "3px" }}
                className="trigger"
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={toggle}
              />
            </Col>
            <Col sm={16} md={20} lg={20} xl={20} align="right">
              <Menu
                theme="light"
                mode="horizontal"
                style={{ height: "46px" }}
                align="right"
              >
                <SubMenu
                  title={
                    <span>
                      <CustomBadge
                        companyID={profile.company_id}
                        userID={profile.id}
                        mqttData={this.props.data}
                        // notiCount={this.props.notiCount}
                        loadNotificationCount={this.props.loadNotificationCount}
                        // onNotiCountChange={this.onNotiCountChange}
                        //onHaveNewNotiChange={this.onHaveNewNotiChange}
                      >
                        <Notification
                          history={this.props.history}
                          lastestNotificationAt={
                            this.props.lastestNotificationAt
                          }
                          // haveNewNoti={this.state.haveNewNoti}
                          // newNoti={this.props.data}
                          // notificationCount={this.props.notificationCount}
                          // onNotiCountChange={this.onNotiCountChange}
                          //onHaveNewNotiChange={this.onHaveNewNotiChange}
                        />
                      </CustomBadge>
                    </span>
                  }
                ></SubMenu>

                <SubMenu
                  title={
                    <span>
                      <ReactCountryFlag
                        styleProps={{
                          margin: "0px 15px 5px 0px",
                          width: "20px",
                          height: "20px",
                        }}
                        code={this.state.inputLng}
                        svg
                      />
                      <Icon type="caret-down" />
                    </span>
                  }
                >
                  <MenuItemGroup title={CheLan}>
                    <Menu.Item key="en" selected={true}>
                      <span
                        className="nav-text"
                        onClick={() => this.checkSelectLang("en")}
                      >
                        <ReactCountryFlag
                          styleProps={{
                            margin: "0px 15px 5px 0px",
                            width: "20px",
                            height: "20px",
                          }}
                          code="us"
                          svg
                        />
                        English
                      </span>
                    </Menu.Item>
                    <Menu.Item key="th" selected={true}>
                      <span
                        className="nav-text"
                        onClick={() => this.checkSelectLang("th")}
                      >
                        <ReactCountryFlag
                          styleProps={{
                            margin: "0px 15px 5px 0px",
                            width: "20px",
                            height: "20px",
                          }}
                          code="th"
                          svg
                        />
                        ไทย
                      </span>
                    </Menu.Item>
                  </MenuItemGroup>
                </SubMenu>
                <SubMenu
                  title={
                    <div>
                      <Row>
                        {" "}
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                          <Icon type="user" />
                        </Col>{" "}
                      </Row>
                      <Row>
                        <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                          <Icon type="user" />
                          {localStorage.getItem("profile") !== null && JSON.parse(localStorage.getItem("profile")).username}
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <MenuItemGroup
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.t.titleAccount}
                      </LangContext.Consumer>
                    }
                  >
                    <Menu.Item key="setting:1" style={{ paddingLeft: "14px" }}>
                      <Icon type="home" />
                      <LangContext.Consumer>
                        {(i18n) => i18n.h.home}
                      </LangContext.Consumer>
                      <Link to="/" />
                      <Link to="/" />
                    </Menu.Item>
                    {done ? (
                      <Menu.Item
                        key="menu:1"
                        style={{ paddingLeft: "14px" }}
                        onClick={this.onRemoveCredentials}
                      >
                        <Icon type="logout" />
                        <LangContext.Consumer>
                          {(i18n) => i18n.l.logout}
                        </LangContext.Consumer>
                        <Link to="/" />
                      </Menu.Item>
                    ) : (
                      <Menu.Item key="menu:1">
                        <LangContext.Consumer>
                          {(i18n) => i18n.l.login}
                        </LangContext.Consumer>
                        <Link to="/" />
                      </Menu.Item>
                    )}
                  </MenuItemGroup>
                  {/* <MenuItemGroup title="Setting">
                    <Menu.Item key="setting:3">Option 3</Menu.Item>
                    <Menu.Item key="setting:4">Option 4</Menu.Item>
                  </MenuItemGroup> */}
                </SubMenu>
              </Menu>
            </Col>
          </Row>
        </StyledHearder>
        <Row type="flex" justify="end" style={{ margin: "0px" }} />
        {flash && (
          <Row type="flex" justify="center" style={{ margin: "0px" }}>
            {/* <Col span={24}>
              <Alert
                message={flash.message}
                type={flash.type}
                closable
                afterClose={this.handleClose}
              />
            </Col> */}
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, lastestNotificationAt },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  lastestNotificationAt,
  tokenExp,
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  removeUIState: removeUIState,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: clearFlashMessage,
  loadNotificationCount: loadNotificationCount.request,
  unregisterNotificate: unregisterNotificate.request,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

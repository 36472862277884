import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";

import {
  loadVehicle,
  removeLists,
  loadVehicleForEdit,
  updateMasterDataVehicle,
} from "../actions";

import { loadProvince } from "../../../auth/actions";

import {
  StyledSearchForm,
  GeneralStyledContent,
  PageTitleStyled,
  TitleText,
  DividerStyled,
} from "../../../../styled/backhaul-styled";

import axios from "axios";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";
import AddFacilityVehicle from "modules/master-data/facility/components/AddFacilityVehicle";

import {
  Button,
  Row,
  Col,
  DatePicker,
  Modal,
  Input,
  Form,
  Select,
  Icon,
  message,
  Upload,
} from "antd";
const InputGroup = Input.Group;
const { confirm } = Modal;
const { Option } = Select;
const { Item } = Form;
const FormItem = Item;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject();
  });
}

class FormAddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      button_type: "",
      Vehicle_Type: [],
      file_front_vehicle_list: [],
      file_front_vehicle_preview_visible: false,
      file_front_vehicle_preview: "",
      file_vehicle_list: [],
      file_vehicle_preview_visible: false,
      file_vehicle_preview: "",
      change_file_vehicle: false,
      change_file_front_vehicle: false,
      origin_lat: "",
      origin_lng: "",
      end_lat: "",
      end_lng: "",
      data: {
        vehicle_type: "",
        plate_no: "",
        plate_no_province: "",
        expire_date: "",
        expire_act_date: "",
        start_province: "",
        end_province: "",
        brand: "",
        model: "",
        year: "",
        color: "",
        link_vehicle_type_image: "",
        filefrontvehicle: [],
        image_vehicle: [],
      },
    };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    //  console.log("warunee");
    this.loadData(true);

    let self = this;
    const { loadProvince } = this.props;
    loadProvince();

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicletype`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let vehicle_type = response.data.data.map((type) => {
            return {
              key: type.vehicle_types_id,
              value: type.vehicle_types_name,
              link_image: type.link_image,
            };
          });
          self.setState({ Vehicle_Type: vehicle_type });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  onChangeStartProvice = (value, setFieldValue) => {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpointbyprovince`,
        {
          province: value,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.lat !== "undefined") {
          self.setState({
            origin_lat: response.data.lat,
            origin_lng: response.data.lng,
            data: {
              ...self.state.data,
              start_province: value,
            },
          });
          setFieldValue("start_province", value);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  onChangeEndProvice = (value, setFieldValue) => {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getpointbyprovince`,
        {
          province: value,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.lat !== "undefined") {
          self.setState({
            end_lat: response.data.lat,
            end_lng: response.data.lng,
            data: {
              ...self.state.data,
              end_province: value,
            },
          });
          setFieldValue("end_province", value);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleFrontVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank");
    } else {
      this.setState({
        file_front_vehicle_preview: file.url || file.preview,
        file_front_vehicle_preview_visible: true,
      });
    }
  };

  handleVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if (file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank");
    } else {
      this.setState({
        file_vehicle_preview: file.url || file.preview,
        file_vehicle_preview_visible: true,
      });
    }
  };

  handleVehicleCancel = () =>
    this.setState({ file_vehicle_preview_visible: false });

  handleCancel = () =>
    this.setState({ file_front_vehicle_preview_visible: false });

  handleCancelModel = () => {
    this.setState({ visible: false });
  };

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(this.props.vehicle.check1, nextProps.vehicle.check1);
    if (this.props.vehicle.check1 != nextProps.vehicle.check1) {
      // console.log("update");
      if (nextProps.vehicle.vehicleforedit != "") {
        console.log(nextProps.vehicle.vehicleforedit.link_image);
        this.setState({
          file_front_vehicle_list: [
            {
              uid: "-1",
              name: "filefrontvehicle.png",
              url: nextProps.vehicle.vehicleforedit.file_front_vehicle,
            },
          ],
          file_vehicle_list: [
            {
              uid: "-2",
              name: "file_vehicle.jpg",
              url: nextProps.vehicle.vehicleforedit.file_vehicle,
            },
          ],
          origin_lat: nextProps.vehicle.vehicleforedit.start_lat,
          origin_lng: nextProps.vehicle.vehicleforedit.start_lng,
          end_lat: nextProps.vehicle.vehicleforedit.end_lat,
          end_lng: nextProps.vehicle.vehicleforedit.end_lng,
          data: {
            vehicle_type: nextProps.vehicle.vehicleforedit.vehicle_type_id,
            plate_no: nextProps.vehicle.vehicleforedit.plate_no,
            vehicle_id: nextProps.vehicle.vehicleforedit.id,
            expire_date: moment(
              nextProps.vehicle.vehicleforedit.insurance_expire_date,
              "YYYY/MM/DD"
            ),
            expire_act_date: moment(
              nextProps.vehicle.vehicleforedit.act_expire_date,
              "YYYY/MM/DD"
            ),
            brand: nextProps.vehicle.vehicleforedit.brand,
            model: nextProps.vehicle.vehicleforedit.model,
            year: nextProps.vehicle.vehicleforedit.year,
            color: nextProps.vehicle.vehicleforedit.color,
            link_vehicle_type_image:
              nextProps.vehicle.vehicleforedit.link_image,
            plate_no_province:
              nextProps.vehicle.vehicleforedit.plate_province_id,
            start_province: nextProps.vehicle.vehicleforedit.start_province_id,
            end_province: nextProps.vehicle.vehicleforedit.end_province_id,

            filefrontvehicle: [],
            image_vehicle: [],
          },
        });
      }
    }
    return true;
  }

  goBack() {
    this.props.history.goBack();
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  loadData = (loading) => {
    this.props.loadVehicleForEdit(
      {
        id: this.props.match.params.id,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };

  showModal = (type) => {
    this.setState({
      visible: true,
      button_type: type,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
      button_type: "",
    });
  };

  handleSubmit = (values, i18n) => {
    let self = this;
    console.log(values);
    this.props.updateMasterDataVehicle(
      {
        change_file_front_vehicle: this.state.change_file_front_vehicle,
        change_file_vehicle: this.state.change_file_vehicle,
        vehicles_id: this.state.data.vehicle_id,
        company_id: this.props.auth.profile.company_id,
        user_id: this.props.auth.profile.id,
        vehicle_type: values.vehicle_type,
        plate_no: values.plate_no,
        plate_no_province: values.plate_no_province,
        expire_date: values.expire_date,
        expire_act_date: values.expire_act_date,
        start_province: values.start_province,
        end_province: values.end_province,
        start_lat: this.state.origin_lat,
        start_lng: this.state.origin_lng,
        end_lat: this.state.end_lat,
        end_lng: this.state.end_lng,
        brand: values.brand,
        model: values.model,
        color: values.color,
        year: values.year,
        filefrontvehicle: values.filefrontvehicle,
        image_vehicle: values.image_vehicle,
        accessToken: this.props.auth.accessToken,
      },
      function(res) {
        if (res.status == "fail" && res.errors) {
          message.error(i18n.u.update_fail);
        } else {
          message.success(i18n.u.update_success);
          self.props.history.push(`/vehicle`);
        }
      }
    );
  };

  showConfirm = (values, i18n) => {
    let self = this;
    confirm({
      title: i18n.d.doYouWantToEditTheseItems,
      content: i18n.c.confirmToEditThisItem,
      okText: i18n.o.ok,
      cancelText: i18n.c.cancel,
      onOk() {
        self.handleSubmit(values, i18n);
      },
      onCancel() {},
    });
  };

  //End Confirm Add Vehicle

  addArea = (lat, lng, province) => {
    if (this.state.button_type === "origin") {
      this.setState({
        visible: false,
        button_type: "",
        origin_lat: lat,
        origin_lng: lng,
        data: {
          ...this.state.data,
          start_province: province,
        },
      });
    } else if (this.state.button_type === "end") {
      this.setState({
        visible: false,
        button_type: "",
        end_lat: lat,
        end_lng: lng,
        data: {
          ...this.state.data,
          end_province: province,
        },
      });
    }
  };

  render() {
    const { provinceLists } = this.props;
    const {
      file_front_vehicle_list,
      file_front_vehicle_preview,
      file_front_vehicle_preview_visible,
      file_vehicle_list,
      file_vehicle_preview,
      file_vehicle_preview_visible,
      change_file_vehicle,
      change_file_front_vehicle,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="upload" />
        <LangContext.Consumer>
          {(i18n) => i18n.s.select_file}
        </LangContext.Consumer>
      </div>
    );

    return (
      <AuthorizeComponent matching_name="vehicle">
        <GeneralStyledContent>
          <Modal
            className="test-modal-map"
            title={
              <LangContext.Consumer>
                {(i18n) => i18n.p.pinDown}
              </LangContext.Consumer>
            }
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancelModel}
            cancelButtonProps={{
              style: { display: "none" },
              "data-cy": "btn-modal-cancel",
            }}
            okButtonProps={{
              style: { display: "none" },
              "data-cy": "btn-modal-ok",
            }}
            width={1000}
            destroyOnClose={true}
          >
            <AddFacilityVehicle addArea={this.addArea}></AddFacilityVehicle>
          </Modal>
          <LangContext.Consumer>
            {(i18n) => (
              <Fragment>
                <Row gutter={24}>
                  <Col span={24}>
                    <Breadcrumb
                      match={this.props.match}
                      style={{ margin: "0px 0px 14px 10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <PageTitleStyled>
                    <LangContext.Consumer>
                      {(i18n) => i18n.veh.edit_veh}
                    </LangContext.Consumer>
                  </PageTitleStyled>
                  <Col sm={24} md={24} lg={24} xl={24}>
                    <StyledSearchForm>
                      <Formik
                        enableReinitialize={true}
                        initialValues={this.state.data}
                        validate={(values) => {
                          let errors = {};

                          if (/^\d+$/.test(values.year) === false) {
                            errors.year = i18n.veh.year_invalid;
                          }

                          if (
                            values.filefrontvehicle.length == 0 &&
                            change_file_front_vehicle == true
                          ) {
                            errors.filefrontvehicle =
                              i18n.veh.filefrontvehicle_require;
                          }

                          if (
                            values.image_vehicle.length == 0 &&
                            change_file_vehicle == true
                          ) {
                            errors.image_vehicle =
                              i18n.veh.image_vehicle_require;
                          }

                          return errors;
                        }}
                        validationSchema={yup.object().shape({
                          vehicle_type: yup
                            .string()
                            .required(i18n.veh.vehicle_type_require),
                          plate_no: yup
                            .string()
                            .required(i18n.veh.plate_no_require),
                          plate_no_province: yup
                            .string()
                            .required(i18n.veh.plate_no_province_require),
                          brand: yup.string().required(i18n.veh.brand_require),
                          model: yup.string().required(i18n.veh.model_require),
                          year: yup.string().required(i18n.veh.year_require),
                          color: yup.string().required(i18n.veh.color_require),
                          expire_date: yup
                            .string()
                            .required(i18n.veh.expire_date_require),
                          expire_act_date: yup
                            .string()
                            .required(i18n.veh.expire_act_date_require),
                          start_province: yup
                            .string()
                            .required(i18n.veh.start_province_require),
                          end_province: yup
                            .string()
                            .required(i18n.veh.end_province_require),
                        })}
                        onSubmit={(values, actions) => {
                          this.showConfirm(values, i18n);
                        }}
                        render={({
                          values,
                          errors,
                          status,
                          touched,
                          handleBlur,
                          setFieldValue,
                          handleSubmit,
                        }) => (
                          <div>
                            <Form
                              onSubmit={handleSubmit}
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <Row gutter={[8, 24]}>
                                <Col span={24}>
                                  <TitleText>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.vehicleinfo}
                                    </LangContext.Consumer>
                                  </TitleText>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.v.vehicleType}
                                      </LangContext.Consumer>
                                    }
                                    required={true}
                                    validateStatus={
                                      touched.vehicle_type &&
                                      errors.vehicle_type
                                        ? "error"
                                        : undefined
                                    }
                                    help={
                                      touched.vehicle_type &&
                                      errors.vehicle_type
                                        ? errors.vehicle_type.substr(
                                            errors.vehicle_type.indexOf(" ") + 1
                                          )
                                        : undefined
                                    }
                                  >
                                    <InputGroup compact>
                                      <Select
                                        data-cy={`vehicle_type`}
                                        name="vehicle_type"
                                        style={{ width: "100%" }}
                                        // onChange={(e) => {
                                        //   setFieldValue("vehicle_type", e);
                                        // }}
                                        onChange={(e, option) => {
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              vehicle_type: e,
                                              link_vehicle_type_image:
                                                option.props.link_image,
                                            },
                                          });
                                        }}
                                        //onBlur={handleBlur}
                                        value={values.vehicle_type}
                                      >
                                        <Option value="">
                                          <LangContext.Consumer>
                                            {(i18n) => i18n.p.pleaseSelect}
                                          </LangContext.Consumer>
                                        </Option>
                                        {this.state.Vehicle_Type &&
                                          this.state.Vehicle_Type.map(
                                            (data, i) => (
                                              <Option
                                                key={data.key}
                                                value={data.key}
                                                link_image={data.link_image}
                                              >
                                                {data.value}
                                              </Option>
                                            )
                                          )}
                                      </Select>
                                    </InputGroup>
                                  </FormItem>
                                </Col>

                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.p.plateNo}
                                      </LangContext.Consumer>
                                    }
                                    required={true}
                                    validateStatus={
                                      touched.plate_no && errors.plate_no
                                        ? "error"
                                        : undefined
                                    }
                                    help={
                                      touched.plate_no && errors.plate_no
                                        ? errors.plate_no.substr(
                                            errors.plate_no.indexOf(" ") + 1
                                          )
                                        : undefined
                                    }
                                  >
                                    <InputGroup compact>
                                      <Input
                                        data-cy={`plate_no`}
                                        //   style={{ width: "80%" }}
                                        type="text"
                                        name="plat_no"
                                        value={values.plate_no}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "plate_no",
                                            e.target.value
                                          );
                                        }}
                                        // onBlur={handleBlur}
                                        onBlur={(e) => {
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              plate_no: e.target.value,
                                            },
                                          });
                                        }}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.plate_no_province}
                                      </LangContext.Consumer>
                                    }
                                    required={true}
                                    validateStatus={
                                      touched.plate_no_province &&
                                      errors.plate_no_province &&
                                      "error"
                                    }
                                    help={
                                      touched.plate_no_province &&
                                      errors.plate_no_province
                                    }
                                  >
                                    <Select
                                      data-cy={`plate_no_province`}
                                      onChange={(e) => {
                                        this.setState({
                                          data: {
                                            ...this.state.data,
                                            plate_no_province: e,
                                          },
                                        });
                                      }}
                                      value={values.plate_no_province}
                                    >
                                      <Option value="">
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.p.pleaseSelect}
                                        </LangContext.Consumer>
                                      </Option>
                                      {typeof provinceLists !== "undefined" &&
                                        provinceLists.map((v) => (
                                          <Option key={v.id} value={v.id}>
                                            {v.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.brand}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.brand && errors.brand
                                        ? "error"
                                        : undefined
                                    }
                                    help={
                                      touched.brand && errors.brand
                                        ? errors.brand.substr(
                                            errors.brand.indexOf(" ") + 1
                                          )
                                        : undefined
                                    }
                                    required={true}
                                  >
                                    <InputGroup compact>
                                      <Input
                                        data-cy={`brand`}
                                        name="brand"
                                        value={values.brand}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "brand",
                                            e.target.value
                                          );
                                        }}
                                        // onBlur={handleBlur}
                                        onBlur={(e) => {
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              brand: e.target.value,
                                            },
                                          });
                                        }}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                  </FormItem>
                                </Col>

                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.model}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.model && errors.model
                                        ? "error"
                                        : undefined
                                    }
                                    help={
                                      touched.model && errors.model
                                        ? errors.model.substr(
                                            errors.model.indexOf(" ") + 1
                                          )
                                        : undefined
                                    }
                                    required={true}
                                  >
                                    <InputGroup compact>
                                      <Input
                                        data-cy={`model`}
                                        type="text"
                                        name="model"
                                        value={values.model}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "model",
                                            e.target.value
                                          );
                                        }}
                                        // onBlur={handleBlur}
                                        onBlur={(e) => {
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              model: e.target.value,
                                            },
                                          });
                                        }}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                  </FormItem>
                                </Col>

                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.year}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.year && errors.year
                                        ? "error"
                                        : undefined
                                    }
                                    help={
                                      touched.year && errors.year
                                        ? errors.year.substr(
                                            errors.year.indexOf(" ") + 1
                                          )
                                        : undefined
                                    }
                                    required={true}
                                  >
                                    <InputGroup compact>
                                      <Input
                                        data-cy={`year`}
                                        type="text"
                                        name="year"
                                        value={values.year}
                                        onChange={(e) => {
                                          setFieldValue("year", e.target.value);
                                        }}
                                        // onBlur={handleBlur}
                                        onBlur={(e) => {
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              year: e.target.value,
                                            },
                                          });
                                        }}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                  </FormItem>
                                </Col>

                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.c.Color}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.color && errors.color
                                        ? "error"
                                        : undefined
                                    }
                                    help={
                                      touched.color && errors.color
                                        ? errors.color.substr(
                                            errors.color.indexOf(" ") + 1
                                          )
                                        : undefined
                                    }
                                    required={true}
                                  >
                                    <InputGroup compact>
                                      <Input
                                        data-cy={`color`}
                                        type="text"
                                        name="color"
                                        value={values.color}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "color",
                                            e.target.value
                                          );
                                        }}
                                        // onBlur={handleBlur}
                                        onBlur={(e) => {
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              color: e.target.value,
                                            },
                                          });
                                        }}
                                        autoComplete="off"
                                      />
                                    </InputGroup>
                                  </FormItem>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    required={true}
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.expire_date}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.expire_date &&
                                      errors.expire_date &&
                                      "error"
                                    }
                                    help={
                                      touched.expire_date && errors.expire_date
                                    }
                                  >
                                    <DatePicker
                                      data-cy={`expire_date`}
                                      // onChange={(value) =>
                                      //   setFieldValue("expire_date", value)
                                      // }
                                      onChange={(e) => {
                                        this.setState({
                                          data: {
                                            ...this.state.data,
                                            expire_date: e,
                                          },
                                        });
                                      }}
                                      style={{ width: "100%" }}
                                      value={values.expire_date}
                                      format="DD/MM/YYYY"
                                      dateRender={(current) => {
                                        const style = {};
                                        if (current.date() === 1) {
                                          style.border = "1px solid #1890ff";
                                          style.borderRadius = "50%";
                                        }
                                        return (
                                          <div
                                            className="ant-calendar-date"
                                            style={style}
                                          >
                                            {current.date()}
                                          </div>
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    required={true}
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.expire_act_date}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.expire_act_date &&
                                      errors.expire_act_date &&
                                      "error"
                                    }
                                    help={
                                      touched.expire_act_date &&
                                      errors.expire_act_date
                                    }
                                  >
                                    <DatePicker
                                      data-cy={`expire_act_date`}
                                      // onChange={(value) =>
                                      //   setFieldValue("expire_act_date", value)
                                      // }
                                      onChange={(e) => {
                                        this.setState({
                                          data: {
                                            ...this.state.data,
                                            expire_act_date: e,
                                          },
                                        });
                                      }}
                                      style={{ width: "100%" }}
                                      value={values.expire_act_date}
                                      format="DD/MM/YYYY"
                                      dateRender={(current) => {
                                        const style = {};
                                        if (current.date() === 1) {
                                          style.border = "1px solid #1890ff";
                                          style.borderRadius = "50%";
                                        }
                                        return (
                                          <div
                                            className="ant-calendar-date"
                                            style={style}
                                          >
                                            {current.date()}
                                          </div>
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    required={true}
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.filefrontvehicle}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.filefrontvehicle &&
                                      errors.filefrontvehicle &&
                                      "error"
                                    }
                                    help={
                                      touched.filefrontvehicle &&
                                      errors.filefrontvehicle
                                    }
                                  >
                                    <Upload
                                      className="test-upload-file-front-vehicle-list"
                                      data-cy={`file_front_vehicle_list`}
                                      name="avatar"
                                      listType="picture-card"
                                      fileList={file_front_vehicle_list}
                                      onPreview={this.handleFrontVehiclePreview}
                                      beforeUpload={(file) => {
                                        const isCheckTypeFile =
                                          file.type === "image/jpeg" ||
                                          file.type === "image/png" ||
                                          file.type === "application/pdf";
                                        if (!isCheckTypeFile) {
                                          message.error(
                                            <LangContext.Consumer>
                                              {(i18n) =>
                                                i18n.worksheet
                                                  .upload_file_support
                                              }
                                            </LangContext.Consumer>
                                          );
                                        }
                                        // const isLt2M = file.size / 1024 / 1024 < 1;
                                        const checkSizeFile =
                                          file.size / 1024 <= 5120;
                                        if (!checkSizeFile) {
                                          message.error(
                                            <LangContext.Consumer>
                                              {(i18n) =>
                                                i18n.worksheet
                                                  .upload_size_support
                                              }
                                            </LangContext.Consumer>
                                          );
                                        }

                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("filefrontvehicle", [
                                            file,
                                          ]);
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              filefrontvehicle: [file],
                                            },
                                          });
                                        }

                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        const isCheckTypeFile =
                                          file.type === "image/jpeg" ||
                                          file.type === "image/png" ||
                                          file.type === "application/pdf";
                                        const checkSizeFile =
                                          file.size / 1024 <= 5120;

                                        if (fileList.length === 0) {
                                          this.setState({
                                            file_front_vehicle_list: fileList,
                                            change_file_front_vehicle: true,
                                          });
                                        } else {
                                          if (
                                            isCheckTypeFile &&
                                            checkSizeFile
                                          ) {
                                            this.setState({
                                              file_front_vehicle_list: fileList,
                                              change_file_front_vehicle: true,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      {file_front_vehicle_list.length >= 1
                                        ? null
                                        : uploadButton}
                                    </Upload>
                                    <Modal
                                      className="test-modal-file-front-vehicle"
                                      visible={
                                        file_front_vehicle_preview_visible
                                      }
                                      footer={null}
                                      onCancel={this.handleCancel}
                                    >
                                      <img
                                        alt="file"
                                        style={{
                                          width: "100%",
                                        }}
                                        src={file_front_vehicle_preview}
                                      />
                                    </Modal>
                                  </Form.Item>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    required={true}
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.image_vehicle}
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.image_vehicle &&
                                      errors.image_vehicle &&
                                      "error"
                                    }
                                    help={
                                      touched.image_vehicle &&
                                      errors.image_vehicle
                                    }
                                  >
                                    <Upload
                                      className="test-upload-file-vehicle-list"
                                      data-cy={`file_vehicle_list`}
                                      name="avatar"
                                      listType="picture-card"
                                      fileList={file_vehicle_list}
                                      onPreview={this.handleVehiclePreview}
                                      beforeUpload={(file) => {
                                        const isCheckTypeFile =
                                          file.type === "image/jpeg" ||
                                          file.type === "image/png";
                                        if (!isCheckTypeFile) {
                                          message.error(
                                            <LangContext.Consumer>
                                              {(i18n) =>
                                                i18n.worksheet
                                                  .upload_file_support
                                              }
                                            </LangContext.Consumer>
                                          );
                                        }
                                        // const isLt2M = file.size / 1024 / 1024 < 1;
                                        const checkSizeFile =
                                          file.size / 1024 <= 5120;
                                        if (!checkSizeFile) {
                                          message.error(
                                            <LangContext.Consumer>
                                              {(i18n) =>
                                                i18n.worksheet
                                                  .upload_size_support
                                              }
                                            </LangContext.Consumer>
                                          );
                                        }

                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("image_vehicle", [
                                            file,
                                          ]);
                                        }

                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        const isCheckTypeFile =
                                          file.type === "image/jpeg" ||
                                          file.type === "image/png" ||
                                          file.type === "application/pdf";
                                        const checkSizeFile =
                                          file.size / 1024 <= 5120;
                                        // console.log(fileList);
                                        // console.log(file);

                                        if (fileList.length === 0) {
                                          //delete
                                          this.setState({
                                            file_vehicle_list: fileList,
                                            change_file_vehicle: true,
                                          });
                                        }

                                        if (isCheckTypeFile && checkSizeFile) {
                                          this.setState({
                                            file_vehicle_list: fileList,
                                            change_file_vehicle: true,
                                          });
                                          this.setState({
                                            data: {
                                              ...this.state.data,
                                              image_vehicle: [file],
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      {file_vehicle_list.length >= 1
                                        ? null
                                        : uploadButton}
                                    </Upload>
                                    <Modal
                                      className="test-modal-file-vehicle"
                                      visible={file_vehicle_preview_visible}
                                      footer={null}
                                      onCancel={this.handleVehicleCancel}
                                    >
                                      <img
                                        alt="file"
                                        style={{
                                          width: "100%",
                                        }}
                                        src={file_vehicle_preview}
                                      />
                                    </Modal>
                                  </Form.Item>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.v.vehicle_type}
                                      </LangContext.Consumer>
                                    }
                                  >
                                    {this.state.data.link_image != "" ? (
                                      <img
                                        src={`/img/vehicletype/${this.state.data.link_vehicle_type_image}`}
                                        // width="150"
                                        // height="100"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>

                              <DividerStyled />
                              <Row gutter={[8, 24]}>
                                <Col span={24}>
                                  <TitleText>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.service_area}
                                    </LangContext.Consumer>
                                  </TitleText>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.start_province}
                                      </LangContext.Consumer>
                                    }
                                    required={true}
                                    validateStatus={
                                      touched.start_province &&
                                      errors.start_province &&
                                      "error"
                                    }
                                    help={
                                      touched.start_province &&
                                      errors.start_province
                                    }
                                  >
                                    <InputGroup compact>
                                      <Select
                                        data-cy={`start_province`}
                                        onChange={(value) =>
                                          this.onChangeStartProvice(
                                            value,
                                            setFieldValue
                                          )
                                        }
                                        value={values.start_province}
                                        style={{ width: "70%" }}
                                      >
                                        <Option value="">
                                          <LangContext.Consumer>
                                            {(i18n) => i18n.p.pleaseSelect}
                                          </LangContext.Consumer>
                                        </Option>
                                        {typeof provinceLists !== "undefined" &&
                                          provinceLists.map((v) => (
                                            <Option key={v.id} value={v.id}>
                                              {v.name}
                                            </Option>
                                          ))}
                                      </Select>
                                      <Button
                                        data-cy={`btn-map-origin`}
                                        icon="plus"
                                        style={{ width: "30%" }}
                                        onClick={() => this.showModal("origin")}
                                      >
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.m.map}
                                        </LangContext.Consumer>
                                      </Button>
                                    </InputGroup>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.veh.end_province}
                                      </LangContext.Consumer>
                                    }
                                    required={true}
                                    validateStatus={
                                      touched.end_province &&
                                      errors.end_province &&
                                      "error"
                                    }
                                    help={
                                      touched.end_province &&
                                      errors.end_province
                                    }
                                  >
                                    <InputGroup compact>
                                      <Select
                                        data-cy={`end_province`}
                                        onChange={(value) =>
                                          this.onChangeEndProvice(
                                            value,
                                            setFieldValue
                                          )
                                        }
                                        value={values.end_province}
                                        style={{ width: "70%" }}
                                      >
                                        <Option value="">
                                          <LangContext.Consumer>
                                            {(i18n) => i18n.p.pleaseSelect}
                                          </LangContext.Consumer>
                                        </Option>
                                        {typeof provinceLists !== "undefined" &&
                                          provinceLists.map((v) => (
                                            <Option key={v.id} value={v.id}>
                                              {v.name}
                                            </Option>
                                          ))}
                                      </Select>
                                      <Button
                                        data-cy={`btn-map-destination`}
                                        icon="plus"
                                        style={{ width: "30%" }}
                                        onClick={() => this.showModal("end")}
                                      >
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.m.map}
                                        </LangContext.Consumer>
                                      </Button>
                                    </InputGroup>
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.l.latitude}
                                      </LangContext.Consumer>
                                    }
                                  >
                                    {this.state.origin_lat}
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.l.latitude}
                                      </LangContext.Consumer>
                                    }
                                  >
                                    {this.state.end_lat}
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.l.longitude}
                                      </LangContext.Consumer>
                                    }
                                  >
                                    {this.state.origin_lng}
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                  <FormItem
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.l.longitude}
                                      </LangContext.Consumer>
                                    }
                                  >
                                    {this.state.end_lng}
                                  </FormItem>
                                </Col>
                              </Row>

                              <Row
                                style={{
                                  marginTop: "45px",
                                  marginBottom: "5px",
                                }}
                              >
                                <Col sm={24} md={11} lg={11} xl={11}>
                                  <Form.Item>
                                    <Link
                                      to={`/vehicle`}
                                      style={{ padding: "0px 15px 0px 5px" }}
                                    >
                                      <Button
                                        data-cy={`btn-go-back`}
                                        type="default"
                                        style={{
                                          marginLeft: "5px",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.b.back}
                                        </LangContext.Consumer>
                                      </Button>
                                    </Link>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      data-cy={`btn-submit`}
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.s.save}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                      />
                    </StyledSearchForm>
                  </Col>
                </Row>
              </Fragment>
            )}
          </LangContext.Consumer>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ vehicle, auth, auth: { provinceLists } }) => ({
    vehicle,
    auth,
    provinceLists,
  }),
  {
    loadVehicle: loadVehicle.request,
    updateMasterDataVehicle: updateMasterDataVehicle.request,
    loadVehicleForEdit: loadVehicleForEdit.request,
    loadProvince: loadProvince.request,
    removeLists,
  }
)(FormAddVehicle);

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadCompaniesVerify(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompaniesverify`,
      {
        searchName,
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadCompaniesVerify.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadCompaniesVerify.failure());
  }
}

function* verifyCompany(action) {
  
  const {
    data: {
      companyID,
      accessToken,
    }
  } = action.payload;
  const { loading } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/verifycompany`,
      {
          companyID,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.verifyCompany.success(res.data));
  } catch (err) {
    yield put(actions.verifyCompany.failure());
  }
}

export default function* watchCompanyVerify() {
  yield all([
    takeEvery(actions.LOAD_COMPANIES_VERIFY.REQUEST, loadCompaniesVerify),
    takeEvery(actions.VERIFY_COMPANY.REQUEST, verifyCompany),
  ]);
}

export {
  loadCompaniesVerify,
};

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import FormDivider from "../../../shared/components/FormDivider";
import { loadDetailsVehicleVerify, verifyVehicle } from "../actions";
import axios from "axios";
import { StyledSearchForm } from "../../../../styled/common-styled";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";

import {
  Button,
  Row,
  Col,
  Divider,
  Modal,
  Form,
  Upload,
  Icon,
  message,
} from "antd";

const { Item } = Form;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject();
  });
}
class VehicleVerifyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      file_front_vehicle_list: [],
      file_front_vehicle_preview_visible: false,
      file_front_vehicle_preview: "",
      file_vehicle_list: [],
      file_vehicle_preview_visible: false,
      file_vehicle_preview: "",
      data: {
        vehicle_type: "",
        plate_no: "",
        plate_no_province: "",
        expire_date: "",
        expire_act_date: "",
        start_province: "",
        end_province: "",
        brand: "",
        model: "",
        year: "",
        color: "",
        verified: 0,
      },
      display_button: "none",
      checkVehiclePlateno: [],
    };
    this.goBack = this.goBack.bind(this);
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.loadData(true);
  }

  componentWillReceiveProps(nextProps) {
    let self = this;

    if (nextProps.vehicleVerify.detailsVehicleVerify != "") {
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/checkvehicleplateno`,
          {
            Plateno: nextProps.vehicleVerify.detailsVehicleVerify.plate_no,
          },
          {
            headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
          }
        )
        .then(function(response) {
          console.log(
            "VehicleVerifyDetail -> shouldComponentUpdate -> response",
            response
          );
          if (typeof response.data.data !== "undefined") {
            self.setState({
              checkVehiclePlateno: response.data.data,
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });

      self.setState({
        display_button: "block",
        file_front_vehicle_list: [
          {
            uid: "-1",
            name: "filefrontvehicle.png",
            url:
              nextProps.vehicleVerify.detailsVehicleVerify.file_front_vehicle,
          },
        ],
        file_vehicle_list: [
          {
            uid: "-2",
            name: "file_vehicle.jpg",
            url: nextProps.vehicleVerify.detailsVehicleVerify.file_vehicle,
          },
        ],
        data: {
          vehicle_type:
            nextProps.vehicleVerify.detailsVehicleVerify.vehicle_type_name,
          plate_no: nextProps.vehicleVerify.detailsVehicleVerify.plate_no,
          vehicle_id: nextProps.vehicleVerify.detailsVehicleVerify.id,
          expire_date: moment(
            nextProps.vehicleVerify.detailsVehicleVerify.insurance_expire_date
          ).format("DD/MM/YYYY"),
          expire_act_date: moment(
            nextProps.vehicleVerify.detailsVehicleVerify.act_expire_date
          ).format("DD/MM/YYYY"),
          brand: nextProps.vehicleVerify.detailsVehicleVerify.brand,
          model: nextProps.vehicleVerify.detailsVehicleVerify.model,
          year: nextProps.vehicleVerify.detailsVehicleVerify.year,
          color: nextProps.vehicleVerify.detailsVehicleVerify.color,
          plate_no_province:
            nextProps.vehicleVerify.detailsVehicleVerify.plate_province_name,
          start_province:
            nextProps.vehicleVerify.detailsVehicleVerify.start_province_name,
          end_province:
            nextProps.vehicleVerify.detailsVehicleVerify.end_province_name,
          verified: nextProps.vehicleVerify.detailsVehicleVerify.verified,
        },
      });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  loadData = (loading) => {
    this.props.loadDetailsVehicleVerify(
      {
        id: this.props.match.params.id,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };
  handleFrontVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if (file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank");
    } else {
      this.setState({
        file_front_vehicle_preview: file.url || file.preview,
        file_front_vehicle_preview_visible: true,
      });
    }
  };

  handleVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      file_vehicle_preview: file.url || file.preview,
      file_vehicle_preview_visible: true,
    });
  };

  handleVehicleCancel = () =>
    this.setState({ file_vehicle_preview_visible: false });

  handleCancel = () =>
    this.setState({ file_front_vehicle_preview_visible: false });

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmitVerify = (i18n) => {
    let self = this;
    this.props.verifyVehicle(
      {
        vehicleID: this.props.match.params.id,
        accessToken: this.props.auth.accessToken,
      },
      function(res) {
        if (res.status == "fail" && res.errors) {
          message.error(i18n.u.update_fail);
        } else {
          message.success(i18n.u.update_success);
          self.props.history.push(`/vehicleverify`);
        }
      }
    );
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      file_front_vehicle_list,
      file_front_vehicle_preview,
      file_front_vehicle_preview_visible,
      file_vehicle_list,
      file_vehicle_preview,
      file_vehicle_preview_visible,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="upload" />
        <LangContext.Consumer>
          {(i18n) => i18n.s.select_file}
        </LangContext.Consumer>
      </div>
    );
    return (
      <AuthorizeComponent matching_name="vehicle_verify">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            {/* <Col  sm={24} md={2} lg={2} xl={2}></Col> */}
            <Col sm={24} md={24} lg={24} xl={24}>
              <StyledSearchForm>
                <Formik
                  enableReinitialize={true}
                  initialValues={this.state.data}
                  validate={(values) => {
                    let errors = {};

                    return errors;
                  }}
                  validationSchema={yup.object().shape({})}
                  onSubmit={(values, actions) => {
                    this.showModal(values);
                  }}
                  render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <div>
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {this.state.checkVehiclePlateno &&
                        this.state.checkVehiclePlateno.length > 0 ? (
                          <Row
                            gutter={24}
                            style={{ margin: "0px 0px 0px 10px", color: "red" }}
                          >
                            <Col
                              sm={24} 
                              md={4} 
                              lg={4} 
                              xl={2}
                              style={{ padding: "5px" }}
                              // align="right"
                            >
                              <LangContext.Consumer>
                                {(i18n) => "*" + i18n.p.plateNoExist + ": "}
                              </LangContext.Consumer>
                            </Col>
                            <Col 
                              sm={24} 
                              md={20} 
                              lg={20} 
                              xl={22}
                              style={{ padding: "5px" }}>
                              {this.state.checkVehiclePlateno.map((item, i) => (
                                <div>{item.company_name}</div>
                              ))}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        <Row gutter={24}>
                          <FormDivider>
                            <LangContext.Consumer>
                              {(i18n) => i18n.v.vehicle}
                            </LangContext.Consumer>
                          </FormDivider>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.v.vehicleType}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.vehicle_type}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.plate_no_province}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.plate_no_province}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.m.model}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.model}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.color}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.color}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.expire_act_date}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.expire_act_date}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.p.plateNo}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.plate_no}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.brand}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.brand}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.year}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.year}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.expire_date}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.expire_date}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <FormDivider>
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.picture}
                            </LangContext.Consumer>
                          </FormDivider>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "12px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.filefrontvehicle}
                                    </LangContext.Consumer>
                                  }
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <Item style={{ margin: "0px" }}>
                                  <Upload
                                    className="test-upload-file-front-vehicle-list"
                                    name="avatar"
                                    listType="picture-card"
                                    fileList={file_front_vehicle_list}
                                    onPreview={this.handleFrontVehiclePreview}
                                    showRemoveIcon={false}
                                    onRemove={false}
                                    disabled={true}
                                  >
                                    {file_front_vehicle_list.length >= 1
                                      ? null
                                      : uploadButton}
                                  </Upload>
                                  <Modal
                                    className="test-modal-file-front-vehicle-preview-visible"
                                    visible={file_front_vehicle_preview_visible}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{ width: "100%" }}
                                      src={file_front_vehicle_preview}
                                    />
                                  </Modal>
                                </Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "12px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.image_vehicle}
                                    </LangContext.Consumer>
                                  }
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <Item style={{ margin: "0px" }}>
                                  <Upload
                                    className="test-upload-file-vehicle-list"
                                    name="avatar"
                                    listType="picture-card"
                                    fileList={file_vehicle_list}
                                    onPreview={this.handleVehiclePreview}
                                    showRemoveIcon={false}
                                    onRemove={false}
                                    disabled={true}
                                  >
                                    {file_vehicle_list.length >= 1
                                      ? null
                                      : uploadButton}
                                  </Upload>
                                  <Modal
                                    className="test-modal-file-vehicle-preview-visible"
                                    visible={file_vehicle_preview_visible}
                                    footer={null}
                                    onCancel={this.handleVehicleCancel}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{ width: "100%" }}
                                      src={file_vehicle_preview}
                                    />
                                  </Modal>
                                </Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <FormDivider>
                            <LangContext.Consumer>
                              {(i18n) => i18n.veh.service_area}
                            </LangContext.Consumer>
                          </FormDivider>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.start_province}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.start_province}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.end_province}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.end_province}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <LangContext.Consumer>
                              {(i18n) => (
                                <Item>
                                  <Link to="/vehicleverify">
                                    <Button
                                      data-cy="btn-go-back"
                                      type="default"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.b.back}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Link>
                                  {values.verified === 0 ? (
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      data-cy="btn-submit"
                                    >
                                      {i18n.v.verify}
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                  <LangContext.Consumer>
                                    {(i18n) => (
                                      <Modal
                                        className="test-modal-company-verify"
                                        title={i18n.c.confirmVerifyVehicle}
                                        visible={this.state.visible}
                                        onOk={() =>
                                          this.handleSubmitVerify(i18n)
                                        }
                                        onCancel={this.hideModal}
                                        okText={i18n.o.ok}
                                        cancelText={i18n.c.cancel}
                                        okButtonProps={{
                                          "data-cy": "btn-modal-ok"
                                        }}
                                        cancelButtonProps={{
                                          "data-cy": "btn-modal-cancel"
                                        }}
                                      >
                                        <p>{i18n.c.confirmVerifyVehicle}</p>
                                      </Modal>
                                    )}
                                  </LangContext.Consumer>
                                </Item>
                              )}
                            </LangContext.Consumer>
                          </Col>
                        </Row>

                        {/* <Row gutter={24}>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}></Col>
                          <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                            {this.state.data.vehicle_companies_id != "" ? (
                              <Item>
                                <div>
                                  <Link
                                    to={`/vehicleverify`}
                                    style={{ padding: "0px 15px 0px 5px" }}
                                  >
                                    <Button
                                      type="default"
                                      style={{
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.b.back}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Link>
                                  {values.verified === 0 ? 
                                    <Button 
                                        type="primary"
                                        onClick={this.showModal}
                                        style={{ padding: "0px 5px 0px 5px" }}
                                    >
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.v.verify}
                                        </LangContext.Consumer>
                                    </Button>
                                    : ""
                                  }
                      
                                </div>
                              </Item>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row> */}
                      </Form>
                    </div>
                  )}
                />
              </StyledSearchForm>
            </Col>
            {/* <Col  sm={24} md={2} lg={2} xl={2}></Col> */}
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(({ vehicleVerify, auth }) => ({ vehicleVerify, auth }), {
  loadDetailsVehicleVerify: loadDetailsVehicleVerify.request,
  verifyVehicle: verifyVehicle.request,
})(VehicleVerifyDetail);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../actions";
import {
  Row,
  Col,
  Spin,
  Icon,
  Divider,
  Table,
  Pagination,
  Rate,
  Button,
  Modal,
  message,
} from "antd";
import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { translate } from "../../../../../../lib/helper";

const { confirm } = Modal;


const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "n",
    keyLang: "no",
    dataKey: "#",
    sorter: true,
    render: "no",
    width: "5%",
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "company",
    dataKey: "companyname",
    sorter: true,
    render: "company",
    width: "25%",
    cls: "vertical-top",
  },
  {
    idxLang: "r",
    keyLang: "rateServiceQuality",
    dataKey: "rating",
    sorter: false,
    render: "rating",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "business",
    keyLang: "companyDetails",
    dataKey: "contact",
    sorter: false,
    render: "detail",
    width: "35%",
    cls: "vertical-top",
  },
  {
    idxLang: "j",
    keyLang: "job_quotation",
    dataKey: "quotation",
    sorter: false,
    render: "quotation",
    width: "10%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "e",
    keyLang: "editStatus",
    dataKey: "status",
    sorter: false,
    render: "action",
    width: "10%",
    cls: "vertical-top algin-center",
  },
];

export class AllQuotations extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
    orderBy: "worksheets.created_at",
    orderType: "desc",
    filters: [],
  };

  showConfirm = (val) => () => {
    const that = this;
    confirm({
      title: translate("confirm_quotation", "c", this.props.langBtn),
      content: translate("confirm_quotation_desc", "c", this.props.langBtn),
      onOk() {
        that.updateStatusQt(val);
      },
      onCancel() {},
      cancelText: translate("close", "c", this.props.langBtn),
      okText: translate("confirm", "c", this.props.langBtn),
      okButtonProps: {
        "data-cy": "btn-confirm-ok"
      },
      cancelButtonProps:{
        "data-cy": "btn-confirm-cancel"
      }
    });
  };

  genTableHeader = () => {
    const columns = [];
    const that = this;
    tableHeader.map((v) => {
      columns.push({
        title: translate(`${v.keyLang}`, `${v.idxLang}`),
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: that.renderComponent(v.render, record, v.dataKey, index),
          };
        },
      });
    });

    return columns;
  };

  renderComponent = (type, val, key, index) => {
    switch (type) {
      case "no":
        return index + 1;
      case "company":
        return this.renderCompany(val);
      case "status":
        return this.renderStatus(val[key]);
      case "quotation":
        return this.renderQuotation(val[key]);
      case "rating":
        return this.renderRating(val[key], val);
      case "detail":
        return this.renderCompanyDetail(val);
      case "action":
        return this.renderActionBtn(val);
      default:
        return this.renderNormalTxt(val[key]);
    }
  };
  renderCompany = (val) => {
    return (
      <Fragment>
        <div style={{ width: "90px", height: "40px", display: "inline-block" }}>
          <img src={val.logo} style={{ width: "100%" }} />
        </div>
        &nbsp;&nbsp;{val.companyname}
      </Fragment>
    );
  };

  renderRating = (val, rec) => {
    let rate = typeof val === "undefined" ? 0 : val
    if(rate % 1 >= 0.5) {
      rate = Math.floor(val) + 0.5
    } 

    return (
      <Fragment>
        <div className="algin-center">
          <Rate disabled allowHalf defaultValue={rate} />
          <br />({val})
        </div>
      </Fragment>
    );
  };

  renderCompanyDetail = (val) => {
    return (
      <Fragment>
        <p>
          {translate("province_head_offfice", "business")} :{" "}
          {this.props.langBtn === "th" ? val.province_name_th : val.province_name_en}
        </p>
        <p>
          <b>{translate("contact_business", "business")}</b>
        </p>
        {typeof val.contact !== "undefined" &&
          val.contact.map((v) => {
            return (
              <Fragment>
                {v.firstname !== "" && 
                  <p>
                    {translate("name", "n")} :{" "}
                    {translate(v.title_name, "m")} {v.firstname + " " + v.lastname}
                  </p>
                }
                <p>
                  {translate("phone", "p")} : {v.phone}, {translate("email", "e")} :{" "}
                  {v.email}
                </p>
              </Fragment>
            );
          })}
      </Fragment>
    );
  };

  renderQuotation = (val) => (
    <Fragment>
      <Icon
        type="file-search"
        style={{ fontSize: "25px", padding: "10px" }}
        onClick={this.previewFile(val)}
      />
    </Fragment>
  );

  previewFile = (val) => () => {
    window.open(val, "_blank");
  };

  renderActionBtn = (val) => {
    if (val.status === "waiting") {
      return (
        <div>
          <Button
            data-cy="btn-confirm"
            className="margin_btn_status"
            type="primary"
            size="large"
            disabled={val.has_accept_qt}
            onClick={this.showConfirm(val)}
          >
              {translate("confirm", "c")}
          </Button>
        </div>
      );
    }

    if (val.status === "accept") {
      return (
        <span style={{ color: "#1890ff" }}>
          {translate("confirm", "assign")}
        </span>
      );
    }

    if (val.status === "reject") {
      return <div>{translate("reject", "r")}</div>;
    }

    if (val.status === "return") {
      return <div>{translate("return", "assign")}</div>;
    }

    if (val.status === "cancel") {
      return <div>{translate("cancel", "c")}</div>;
    }

    if (val.status === "complete") {
      return (
        <div style={{ color: "#1890ff" }}>
          {translate("assign_job", "assign")}
        </div>
      );
    }
  };
  renderStatus = (val) => <div className="job_open_status_txt">{val}</div>;

  renderNormalTxt = (val) => <div>{val}</div>;

  updateStatusQt = (val) => {
    this.props.updateStatusQt(val.worksheet_id, val.quotation_id);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = "worksheets.created_at";
      orderType = "asc";
    } else {
      orderBy = this.getRealKey(sorter.columnKey);
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    let id = this.props.match.params.id;
    this.setState({
      orderBy,
      orderType,
    });

    this.props.loadQuotations(
      this.state.currentPage,
      this.state.pageSize,
      orderBy,
      orderType,
      this.state.filters,
      id
    );
  };

  handlePageChange = (page, pageSize) => {
    let id = this.props.match.params.id;
    this.setState({ page: page, currentPage: page });
    this.props.loadQuotations(
      page,
      pageSize,
      this.state.orderBy,
      this.state.orderType,
      this.state.filters,
      id
    );
  };

  getRealKey = (key) => {
    switch (key) {
      case "product_type":
        return "m_product_type.name";
      case "origin":
        return "locations.name";
      case "vehicle_type":
        return "vehicle_types.name";
      case "status":
        return "worksheets.status";
      default:
        return key;
    }
  };

  loadData = () => {
    const { page, pageSize, orderBy, orderType, filters } = this.state;
    let id = this.props.match.params.id;
    this.props.loadQuotations(page, pageSize, orderBy, orderType, filters, id);
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.success !== this.props.success &&
      this.props.success === true
    ) {
      message.success(translate("confirm_quotation_success", "c", this.props.langBtn));
      this.loadData();
    }

    if (
      prevProps.success !== this.props.success &&
      this.props.success === false
    ) {
      message.success(translate("confirm_quotation_unsuccess", "c", this.props.langBtn));
    }
  }

  render() {
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="allwork_dashboard">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, "/productowner/allwork"]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
              {translate("job_quotation", "j")}
              </Divider>
            </Col>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
          <Row style={{ margin: "0px 0px 100px 0px" }}>
            <Col>
              <Link to={`/productowner/allwork`}>
                <Button
                  data-cy="btn-go-back"
                  type="default"
                  style={{
                    marginLeft: "5px",
                    marginRight: "10px",
                  }}
                >
                  {translate("back", "b")}
                </Button>
              </Link>
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ productOwnerAllwork, ui: {langBtn} }) => ({
  data: productOwnerAllwork.lists_qt,
  totalRows: productOwnerAllwork.totalRows,
  loading: productOwnerAllwork.loading,
  success: productOwnerAllwork.success,
  langBtn
});

const mapDispatchToProps = {
  loadQuotations: actions.loadQuotations.request,
  updateStatusQt: actions.updateStatusQt.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllQuotations);

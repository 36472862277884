import React from "react";
import { Switch, Route } from "react-router-dom";
import IndexGroup from "./Index";
import Add from "./LogisticGroupAdd";
import LogisticGroupDeatil from "./LogisticGroupDetail";
import LogisticGroupEditTest from "./LogisticGroupEditTest";

export default () => (
  <Switch>
    <Route exact path="/logisticgroup" component={IndexGroup} />
    <Route exact path="/logisticgroup/create" component={Add} />
    <Route
      exact
      path="/logisticgroup/detail/:logistic_groups_id"
      component={LogisticGroupDeatil}
    />
    <Route
      exact
      path="/logisticgroup/edit/:logistic_groups_id"
      component={LogisticGroupEditTest}
    />
  </Switch>
);

import { createTypes, createAction } from "lib/action";

const LOAD_COMPANY_PROFILE = createTypes("companyprofile", "load");

const loadCompanyProfile = {
    request: (data, loading) =>
        createAction(LOAD_COMPANY_PROFILE.REQUEST, { data, loading}),
    success: (lists, page) =>
        createAction(LOAD_COMPANY_PROFILE.SUCCESS, {lists, page}),
    failure: () => createAction(LOAD_COMPANY_PROFILE.FAITLURE)  
  };

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
return {
    type: REMOVE_LISTS
};
}

export{
    loadCompanyProfile,
    LOAD_COMPANY_PROFILE,
    REMOVE_LISTS,
    removeLists
};
import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import IndexTransportationrating from "./Index";

export default () => (
    <Router>
      <div>
        <Route exact path="/transportationrating/:worksheetID" component={IndexTransportationrating} />
      </div>
    </Router>
  );
  
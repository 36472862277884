import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadCompanyProfile(action) {
  const {
    data: {
      companyID,
      accessToken,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanyprofile`,
      {
        companyID,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadCompanyProfile.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadCompanyProfile.failure());
  }
}


export default function* watchCompanyProfile() {
  yield all([
    takeEvery(actions.LOAD_COMPANY_PROFILE.REQUEST, loadCompanyProfile),
  ]);
}

export {
  loadCompanyProfile,
};

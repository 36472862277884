import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Input, Row, Col, Select } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { getlocalstorage } from "./../../../../constants/local_storage";
import axios from "axios";
const FormItem = Form.Item;
const Option = Select.Option;

export default class Filter extends Component {
  state = {
    vehicle_type: [],
    locationLists: [],
  };

  componentDidMount() {
    this.vehicleyType();
    this.loadLocation();
  }
  vehicleyType = () => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicletype`,
        {}
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let vehicle_type = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name_key,
            };
          });
          self.setState({ vehicle_type });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  loadLocation = () => {
    let self = this;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem("profile"),
      "company_id"
    );

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        company_id: COMPANY_ID,
        name: "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ locationLists: res.data });
      });
  };

  render() {
    const { OnClose, onSubmit } = this.props;
    const { vehicle_type, locationLists } = this.state;

    return (
      <div>
        <Formik
          initialValues={{
            vehicle_type_id: "",
            origin_location: "",
            destination_location: "",
          }}
          validationSchema={yup.object().shape({
            // name: yup.string.required("กรุณาเลือกช่วงวันที่"),
            // code: yup.string.required("กรุณาเลือกรถ")
          })}
          onSubmit={(values) => {
            const loading = true;
            onSubmit(values, loading);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <Row>
              <LangContext.Consumer>
                {(i18n) => (
                  <Form onSubmit={handleSubmit}>
                    <FormItem
                      label={i18n.veh.vehicle_type}
                      required={false}
                      validateStatus={
                        touched.vehicle_type_id &&
                        errors.vehicle_type_id &&
                        "error"
                      }
                      help={touched.vehicle_type_id && errors.vehicle_type_id}
                    >
                      <Select
                        data-cy="vehicle_type_id"
                        onChange={(value) =>
                          setFieldValue("vehicle_type_id", value)
                        }
                        value={values.vehicle_type_id}
                      >
                        <Option value="">{i18n.a.all}</Option>
                        {vehicle_type.map((v) => (
                          <Option key={v.id} value={v.id}>
                            {i18n.vehicle_type[v.name]}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem
                      label={i18n.expense.origin_location}
                      required={false}
                      validateStatus={
                        touched.origin_location &&
                        errors.origin_location &&
                        "error"
                      }
                      help={touched.origin_location && errors.origin_location}
                    >
                      <Select
                        data-cy="origin_location"
                        onChange={(value) =>
                          setFieldValue("origin_location", value)
                        }
                        value={values.origin_location}
                      >
                        <Option value="">{i18n.a.all}</Option>
                        {locationLists.map((v) => (
                          <Option key={v.id} value={v.name}>
                            {v.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem
                      label={i18n.expense.destination_location}
                      required={false}
                      validateStatus={
                        touched.destination_location &&
                        errors.destination_location &&
                        "error"
                      }
                      help={
                        touched.destination_location &&
                        errors.destination_location
                      }
                    >
                      <Select
                        data-cy="destination_location"
                        onChange={(value) =>
                          setFieldValue("destination_location", value)
                        }
                        value={values.destination_location}
                      >
                        <Option value="">{i18n.a.all}</Option>
                        {locationLists.map((v) => (
                          <Option key={v.id} value={v.name}>
                            {v.name}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <FormItem>
                      <Row gutter={24}>
                        <Col span={3}>
                          <Button
                            data-cy="btn-filter-close"
                            // onClick={OnClose.bind(null, resetForm)}
                            onClick={OnClose}
                            // style={{ paddingLeft: 5 }}
                          >
                            {i18n.c.cancel}
                          </Button>
                        </Col>
                        <Col span={1} />
                        <Col span={3}>
                          <Button htmlType="submit" type="primary" data-cy="btn-filter-submit">
                            {i18n.a.apply}
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </Form>
                )}
              </LangContext.Consumer>
            </Row>
          )}
        />
      </div>
    );
  }
}

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadVehiclesVerify(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclesverify`,
      {
        searchName,
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadVehiclesVerify.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehiclesVerify.failure());
  }
}

function* loadDetailsVehicleVerify(action) {
  const {
    data: { id, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdetailsvehicleverify`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadDetailsVehicleVerify.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDetailsVehicleVerify.failure());
  }
}

function* verifyVehicle(action) {
  
  const {
    data: {
      vehicleID,
      accessToken,
    }
  } = action.payload;
  const { loading } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/verifyvehicle`,
      {
        vehicleID,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.verifyVehicle.success(res.data));
  } catch (err) {
    yield put(actions.verifyVehicle.failure());
  }
}

export default function* watchCompanyVerify() {
  yield all([
    takeEvery(actions.LOAD_VEHICLES_VERIFY.REQUEST, loadVehiclesVerify),
    takeEvery(actions.LOAD_DETAILS_VEHICLES_VERIFY.REQUEST, loadDetailsVehicleVerify),
    takeEvery(actions.VERIFY_VEHICLE.REQUEST, verifyVehicle),
  ]);
}

export {
  loadVehiclesVerify,
  loadDetailsVehicleVerify,
  verifyVehicle,
};

import { createTypes, createAction } from "lib/action";

const LOAD_TRANSPORT_MARKETS = createTypes("transportmarket", "load");
const SHOW_TRANSPORT_MARKET = createTypes("transportmarket", "show");
const UPLOAD_QT_TRANSPORT_MARKET = createTypes("transportmarket", "upload");

const loadTransportMarket = {
  request: (page, pageSize, orderBy, orderType, filters) =>
    createAction(LOAD_TRANSPORT_MARKETS.REQUEST, { page, pageSize, orderBy, orderType, filters }),
  success: (lists) => createAction(LOAD_TRANSPORT_MARKETS.SUCCESS, { lists }),
  failure: () => createAction(LOAD_TRANSPORT_MARKETS.FAILURE),
};

const showTransportMarket = {
  request: id =>
    createAction(SHOW_TRANSPORT_MARKET.REQUEST, { id }),
  success: (data) => createAction(SHOW_TRANSPORT_MARKET.SUCCESS, { data }),
  failure: () => createAction(SHOW_TRANSPORT_MARKET.FAILURE),
};

const uploadQuotation = {
  request: (id, file, price, document_number) =>
    createAction(UPLOAD_QT_TRANSPORT_MARKET.REQUEST, { id, file, price, document_number }),
  success: (data) => createAction(UPLOAD_QT_TRANSPORT_MARKET.SUCCESS, { data }),
  failure: () => createAction(UPLOAD_QT_TRANSPORT_MARKET.FAILURE),
}

export {
  LOAD_TRANSPORT_MARKETS,
  SHOW_TRANSPORT_MARKET,
  UPLOAD_QT_TRANSPORT_MARKET,
  loadTransportMarket,
  showTransportMarket,
  uploadQuotation
};

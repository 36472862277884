import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../dashboard/product-owner/components/mywork/actions";
import { Link } from "react-router-dom";
import {
  Divider,
  Row,
  Col,
  Table,
  Pagination,
  Spin,
  Icon,
  Tooltip,
  Popconfirm,
  message,
  Button,
} from "antd";

import { removeWorksheet } from "../../../dashboard/transport/components/inprogress/actions";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/backhaul-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { translate } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
import "../../../../styled/transport-dashboard.css";
const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "worksheet",
    keyLang: "document_number",
    dataKey: "document_number",
    sorter: true,
    render: "normal",
    width: "9%",
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "customer",
    dataKey: "customer",
    sorter: true,
    render: "normal",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: true,
    render: "address",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "address",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_porter",
    dataKey: "staff_lift",
    sorter: true,
    render: "staff_lift",
    width: "7%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_payment_status",
    dataKey: "payment_type",
    sorter: true,
    render: "payment_type",
    width: "8%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_vehicle_type",
    dataKey: "vehicle_type",
    sorter: true,
    render: "normal",
    width: "10%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "a",
    keyLang: "action",
    dataKey: "worksheet_id",
    sorter: false,
    render: "action",
    width: "6%",
    cls: "vertical-top algin-center",
  },
];

const renderAddress = (val, key) => {
  if (typeof val === "undefined") return "";
  let items = val.split(",");
  let line = 1;
  let withLine = key === "origin" ? false : true;

  if (val === "") {
    return <p></p>;
  }

  return items.map((item, i) => {
    let txt = item.split(" (");
    if (withLine)
      return (
        <p key={i}>
          {line + i}. {txt[0]} <br /> ({txt[1]}
        </p>
      );
    else
      return (
        <p key={i}>
          {txt[0]} <br /> ({txt[1]}
        </p>
      );
  });
};

export class Index extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
    orderBy: "w.created_at",
    orderType: "desc",
    filters: [],
  };

  genTableHeader = () => {
    const columns = [];
    tableHeader.map((v) => {
      columns.push({
        title: translate(`${v.keyLang}`, `${v.idxLang}`),
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderComponent(v.render, record, v.dataKey),
          };
        },
      });
    });

    return columns;
  };

  renderComponent = (type, val, key) => {
    switch (type) {
      case "address":
        return renderAddress(val[key], key);
      case "action":
        return this.renderActionBtn(val);
      case "staff_lift":
        let keyInx = val[key] === "มี" ? "have_porter" : "have_no_porter";
        return <div>{translate(keyInx, "h")}</div>;
      case "payment_type":
        return <div>{translate(val[key], "worksheet")}</div>;
      default:
        return this.renderNormalTxt(val[key], key);
    }
  };

  renderActionBtn = (val) => (
    <div>
      { this.props.match.params.type === "routine" && <Tooltip
        placement="left"
        title={
            <LangContext.Consumer>
              {(i18n) => i18n.d.description}
            </LangContext.Consumer>
        }
        arrowPointAtCenter
        style={{
          padding: "0px 1px 0px 0px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <Link
          to={`/worksheet/detail/${this.props.match.params.type}/${val.worksheet_id}`}
          style={{ padding: "0px 5px 0px 5px" }}
        >
          <Icon type="file-text" style={{ fontSize: "15px" }}/>
        </Link>
      </Tooltip>}
      <Tooltip
        placement="left"
        title={
          this.props.match.params.type === "routine" ? (
            <LangContext.Consumer>{(i18n) => i18n.e.edit}</LangContext.Consumer>
          ) : (
            <LangContext.Consumer>
              {(i18n) => i18n.d.description}
            </LangContext.Consumer>
          )
        }
        arrowPointAtCenter
        style={{
          padding: "0px 1px 0px 0px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <Link
          data-cy={`btn-view-${val.worksheet_id}`}
          to={
            this.props.match.params.type === "routine"
              ? `/worksheet/edit/${this.props.match.params.type}/${val.worksheet_id}`
              : `/worksheet/detail/${this.props.match.params.type}/${val.worksheet_id}`
          }
          style={{ padding: "0px 5px 0px 5px" }}
        >
          {this.props.match.params.type === "routine" ? (
            <Icon type="edit" style={{ fontSize: "15px" }}/>
          ) : (
            <Icon type="file-text" style={{ fontSize: "15px" }}/>
          )}
        </Link>
      </Tooltip>
      <Tooltip
        placement="left"
        title={
          <LangContext.Consumer>{(i18n) => i18n.d.delete}</LangContext.Consumer>
        }
        arrowPointAtCenter
        style={{
          padding: "0px 1px 0px 0px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <Popconfirm
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
            </LangContext.Consumer>
          }
          onConfirm={() => {
            this.props.removeWorksheet(val.worksheet_id);
          }}
          disabled={[2, 3].includes(parseInt(val.worksheet_status_id))}
        >
          <a
            data-cy={`btn-del-${val.worksheet_id}`}
            href="javascript:;"
            style={{ padding: "0px 5px 0px 5px" }}
            disabled={[2, 3].includes(parseInt(val.worksheet_status_id)) && this.props.match.params.type === "onetime"}
          >
            <Icon type="delete" style={{ fontSize: "15px" }}/>
          </a>
        </Popconfirm>
      </Tooltip>
    </div>
  );

  renderNormalTxt = (val, key) => {
    let txt = "";
    switch (key) {
      case "vehicle_type":
        txt = i18n[this.props.langBtn]["vehicle_type"][val];
        break;
      case "product_type":
        txt = i18n[this.props.langBtn]["m_product_type"][val];
        break;
      default:
        txt = val;
        break;
    }

    if (typeof txt !== "undefined") {
      if (txt.length > 55)
        return (
          <Tooltip title={txt}>
            <span>{txt.substr(0, 55) + "..."}</span>
          </Tooltip>
        );
      else return <span>{txt}</span>;
    } else {
      return <span>{val}</span>;
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = "w.created_at";
      orderType = "asc";
    } else {
      orderBy = this.getRealKey(sorter.columnKey);
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.setState({
      orderBy,
      orderType,
    }, this.loadData);
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page }, this.loadData);
  };

  getRealKey = (key) => {
    switch (key) {
      case "product_type":
        return "m_product_type.name";
      case "origin":
        return "l.name";
      case "vehicle_type":
        return "vt.name";
      case "payment_type":
        return "w.service_payment_type";
      default:
        return key;
    }
  };

  componentDidMount() {
    this.loadData()
  }

  loadData = () => {
    const { page, pageSize, orderBy, orderType, filters } = this.state;
    this.props.loadWorksheetCompany(
      page,
      pageSize,
      orderBy,
      orderType,
      filters,
      this.props.match.params.type
    );
  }

  componentDidUpdate(prevProps) {
    console.log("this.props.match.params.type", this.props.match.params.type)
    if(this.props.match.params.type !== prevProps.match.params.type) {
      this.loadData()
    }

    if (this.props.success === true && prevProps.success !== this.props.success) {
        message.success(i18n[this.props.langBtn]["s"]["success"]);
        this.loadData()
    }
  }

  render() {
    const columns = this.genTableHeader();
    return (
      // <AuthorizeComponent matching_name="allwork_dashboard">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                {this.props.match.params.type === "onetime"
                  ? translate("worksheetonetime", "worksheet")
                  : translate("worksheetroutine", "worksheet")}
              </Divider>
            </Col>
          </Row>
          <Row gutter={[24,24]}>
            <Col span={18}>
              <Link to={`/worksheet/create/${this.props.match.params.type}`}>
                <Button type="primary" icon="plus" size="small" data-cy="btn-create">
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.add}
                  </LangContext.Consumer>
                </Button>
              </Link>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  pagination={false}
                  onChange={this.handleTableChange}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      // </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({
  transportworkinprogress,
  productOwnerAllwork,
  ui: { langBtn },
}) => ({
  data: productOwnerAllwork.lists,
  totalRows: productOwnerAllwork.totalRows,
  loading: productOwnerAllwork.loading,
  langBtn,
  success: transportworkinprogress.success,
});

const mapDispatchToProps = {
  loadWorksheetCompany: actions.loadWorksheetCompany.request,
  removeWorksheet: removeWorksheet.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

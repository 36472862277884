import { createTypes, createAction } from "lib/action";

const LOAD_EXPENSE = createTypes("expense", "load");
const CREATE_EXPENSE = createTypes("expense", "create");
const UPDATE_EXPENSE = createTypes("expense", "update");
const GET_EXPENSE = createTypes("expense", "get");
const CLEAR_EXPENSE = createTypes("expense", "clear");
const CLEAR_RESPONSE = createTypes("response", "clear");

const loadExpense = {
  request: (data, loading) =>
    createAction(LOAD_EXPENSE.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_EXPENSE.SUCCESS, { data }),
  failure: () => createAction(LOAD_EXPENSE.FAILURE),
};

const createExpense = {
  request: (data, loading, history) =>
    createAction(CREATE_EXPENSE.REQUEST, {
      data,
      loading,
      history,
    }),
  success: (data) => createAction(CREATE_EXPENSE.SUCCESS, { data }),
  failure: () => createAction(CREATE_EXPENSE.FAILURE),
};

const updateExpense = {
  request: (data, id, history, loading) =>
    createAction(UPDATE_EXPENSE.REQUEST, { data, id, history, loading }),
  success: (data) => createAction(UPDATE_EXPENSE.SUCCESS, { data }),
  failure: () => createAction(UPDATE_EXPENSE.FAILURE),
};

const getExpenseByID = {
  request: (id) => createAction(GET_EXPENSE.REQUEST, { id }),
  success: (data) => createAction(GET_EXPENSE.SUCCESS, { data }),
  failure: () => createAction(GET_EXPENSE.FAILURE),
};

const clearDataResponse = () => {
  return {
    type: CLEAR_RESPONSE,
  };
};

const clearExpense = () => {
  return {
    type: CLEAR_EXPENSE,
  };
};

export {
  LOAD_EXPENSE,
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  GET_EXPENSE,
  loadExpense,
  createExpense,
  updateExpense,
  getExpenseByID,
  CLEAR_RESPONSE,
  clearDataResponse,
  clearExpense,
  CLEAR_EXPENSE,
};

import {
  LOAD_TRANSPORT_WORKINPROGRESS,
  LOAD_TRANSPORT_WORKINPROGRESS_DETAIL,
  TRANSPORT_REMOVE_WORKSHEET,
  TRANSPORT_COPY_WORKSHEET,
} from "./actions";

const initialState = {
  lists: [],
  data:{
    id: "",
    origin: "",
    document_number: "",
    detail: [
      {
        cause_reject_id: "",
        cause_some_part_id: "",
        complete_quantity: "",
        confirm_deliver_at: "",
        confirm_receive_at: "",
        contact_firstname: "",
        contact_lastname: "",
        contact_phone: "",
        contact_titlename: "",
        created_at: "",
        destination: "",
        destination_id: "",
        document_img_path: "",
        id: "",
        product_img_path: "",
        quantity: "",
        reason: "",
        receive_date: "",
        seq: "",
        status: "",
        unit: "",
        updated_at: "",
        worksheet_id: "",
      },
    ],
  },
  totalRows: 0,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSPORT_WORKINPROGRESS.REQUEST:
      return {
        ...state,
        success: null,
        loading: true,
      };
    case LOAD_TRANSPORT_WORKINPROGRESS.SUCCESS:
      return {
        data: state.data,
        lists: action.payload.lists.data,
        totalRows: action.payload.lists.total,
        loading: false,
      };
    case LOAD_TRANSPORT_WORKINPROGRESS.FAILURE:
      return {
        ...state,
        loading: false,
      };

      case LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.REQUEST:
        return {
          ...state,
          success: null,
          loading: true,
        };
      case LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.SUCCESS:
        return {
          data: action.payload.data.data,
          totalRows: action.payload.data.total,
          loading: false,
        };
      case LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.FAILURE:
        return {
          ...state,
          loading: false,
        };


        case TRANSPORT_COPY_WORKSHEET.REQUEST:
          return {
            ...state,
            success: null,
            loading: true,
          };
        case TRANSPORT_COPY_WORKSHEET.SUCCESS:
          return {
            success: true,
            loading: false,
          };
        case TRANSPORT_COPY_WORKSHEET.FAILURE:
          return {
            ...state,
            loading: false,
          };
        case TRANSPORT_REMOVE_WORKSHEET.REQUEST:
          return {
            ...state,
            success: null,
            loading: true,
          };
        case TRANSPORT_REMOVE_WORKSHEET.SUCCESS:
          return {
            success: true,
            loading: false,
          };
        case TRANSPORT_REMOVE_WORKSHEET.FAILURE:
          return {
            ...state,
            loading: false,
          };
    default:
      return state;
  }
};

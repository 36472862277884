import { createTypes, createAction } from "lib/action";

const LOAD_PRODUCTOWNER_WORKSHEETS = createTypes("productownerworksheet", "load");
const SHOW_PRODUCTOWNER_WORKSHEET = createTypes("productownerworksheet", "show");
const LOAD_PRODUCTOWNER_QUOTATIONS = createTypes("productownerworksheet", "quotation");
const UPDATE_PRODUCTOWNER_QUOTATIONS = createTypes("productownerworksheet", "updateqt");

const loadWorksheetCompany = {
  request: (page, pageSize, orderBy, orderType, filters, type="onetime") =>
    createAction(LOAD_PRODUCTOWNER_WORKSHEETS.REQUEST, { page, pageSize, orderBy, orderType, filters, type }),
  success: (lists) => createAction(LOAD_PRODUCTOWNER_WORKSHEETS.SUCCESS, { lists }),
  failure: () => createAction(LOAD_PRODUCTOWNER_WORKSHEETS.FAILURE),
};

const showWorksheet = {
  request: (id, requotation = false) =>
    createAction(SHOW_PRODUCTOWNER_WORKSHEET.REQUEST, { id, requotation }),
  success: data => createAction(SHOW_PRODUCTOWNER_WORKSHEET.SUCCESS, { data }),
  failure: () => createAction(SHOW_PRODUCTOWNER_WORKSHEET.FAILURE),
}

const loadQuotations = {
  request: (page, pageSize, orderBy, orderType, filters, worksheet_id) =>
    createAction(LOAD_PRODUCTOWNER_QUOTATIONS.REQUEST, { page, pageSize, orderBy, orderType, filters, worksheet_id }),
  success: (listsQt) => createAction(LOAD_PRODUCTOWNER_QUOTATIONS.SUCCESS, { listsQt }),
  failure: () => createAction(LOAD_PRODUCTOWNER_QUOTATIONS.FAILURE),
}

const updateStatusQt = {
  request: (worksheet_id, quotation_id) =>
    createAction(UPDATE_PRODUCTOWNER_QUOTATIONS.REQUEST, { worksheet_id, quotation_id }),
  success: (data) => createAction(UPDATE_PRODUCTOWNER_QUOTATIONS.SUCCESS, { data }),
  failure: () => createAction(UPDATE_PRODUCTOWNER_QUOTATIONS.FAILURE),
}

export {
  LOAD_PRODUCTOWNER_WORKSHEETS,
  LOAD_PRODUCTOWNER_QUOTATIONS,
  SHOW_PRODUCTOWNER_WORKSHEET,
  UPDATE_PRODUCTOWNER_QUOTATIONS,
  loadWorksheetCompany,
  loadQuotations,
  showWorksheet,
  updateStatusQt
};

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

class AuthorizeComponent extends Component {
  render() {
    const { permissions } = this.props.auth.profile;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    let canAccess = permissions.find((ele) => {
      return ele.matching_name == this.props.matching_name && ele.can_view == 1;
    });

    if (this.props.matching_name == "") {
      canAccess = true;
    } else if (typeof canAccess == "undefined") {
      canAccess = false;
    } else {
      canAccess = true;
    }
    if (ACCESSTOKEN === null) {
      window.location.href = "/";
    } else {
      if (!canAccess) {
        return (
          <Redirect
            to={{
              pathname: "/",
              data: { msg: "คุณไม่มีสิทธิ์เข้าหน้านี้" },
            }}
          />
        );
      }
    }

    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default connect(({ auth }) => ({ auth }), null)(AuthorizeComponent);

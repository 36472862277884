import { createTypes, createAction } from "lib/action";

const LOAD_TRACKING_INIT_DATA = createTypes("trackinginitdata", "load");
const LOAD_TRACKING = createTypes("tracking", "load");
const LOAD_DASHCAMERA = createTypes("dashcamera", "load");
const LOAD_EMERGENCYCASE = createTypes("emergencycase", "load");
const LOAD_EARNINGWIDGET = createTypes("earningwidget", "load");
const LOAD_ACCEARNING = createTypes("accearning", "load");
const LOAD_BOOKINGWIDGET = createTypes("bookingwidget", "load");
const LOAD_ACCBOOKING = createTypes("accbooking", "load");
const LOAD_TOPBOOKINGDATA = createTypes("topbookingdata", "load");
const LOAD_ALERT_ALARM = createTypes("alertalarm", "load");
const LOAD_HEAT_MAP = createTypes("heatmap", "load");
const LOAD_NEXT_EVENT_DETAIL = `LOAD_NEXT_EVENT_DETAIL`;
const REMOVE_LISTS = `REMOVE_LISTS`;
const LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER = createTypes(
  "vehicle_type_master_data_filter",
  "load"
);
const LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER = createTypes(
  "vehicle_group_master_data_filter",
  "load"
);
const LOAD_VEHICLE_MASTER_DATA_FILTER = createTypes(
  "vehicle_master_data_filter",
  "load"
);

function removeLists() {
  return {
    type: REMOVE_LISTS
  };
}

const loadDashcamera = {
  request: data => createAction(LOAD_DASHCAMERA.REQUEST, { data }),
  success: (lists, total) =>
    createAction(LOAD_DASHCAMERA.SUCCESS, { lists, total }),
  failure: () => createAction(LOAD_DASHCAMERA.FAILURE)
};

const loadEmergencyCase = {
  request: data => createAction(LOAD_EMERGENCYCASE.REQUEST, { data }),
  success: emergencycase =>
    createAction(LOAD_EMERGENCYCASE.SUCCESS, { emergencycase }),
  failure: () => createAction(LOAD_EMERGENCYCASE.FAILURE)
};

const loadEarningWidget = {
  request: data => createAction(LOAD_EARNINGWIDGET.REQUEST, { data }),
  success: data => createAction(LOAD_EARNINGWIDGET.SUCCESS, { data }),
  failure: () => createAction(LOAD_EARNINGWIDGET.FAILURE)
};

const loadAccEarning = {
  request: data => createAction(LOAD_ACCEARNING.REQUEST, { data }),
  success: data => createAction(LOAD_ACCEARNING.SUCCESS, { data }),
  failure: () => createAction(LOAD_ACCEARNING.FAILURE)
};

const loadBookingWidget = {
  request: data => createAction(LOAD_BOOKINGWIDGET.REQUEST, { data }),
  success: data => createAction(LOAD_BOOKINGWIDGET.SUCCESS, { data }),
  failure: () => createAction(LOAD_BOOKINGWIDGET.FAILURE)
};

const loadAccBooking = {
  request: data => createAction(LOAD_ACCBOOKING.REQUEST, { data }),
  success: data => createAction(LOAD_ACCBOOKING.SUCCESS, { data }),
  failure: () => createAction(LOAD_ACCBOOKING.FAILURE)
};

const loadTopBookingData = {
  request: data => createAction(LOAD_TOPBOOKINGDATA.REQUEST, { data }),
  success: data => createAction(LOAD_TOPBOOKINGDATA.SUCCESS, { data }),
  failure: () => createAction(LOAD_TOPBOOKINGDATA.FAILURE)
};

const loadTrackingInitData = {
  request: data => createAction(LOAD_TRACKING_INIT_DATA.REQUEST, { data }),
  success: data => createAction(LOAD_TRACKING_INIT_DATA.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRACKING_INIT_DATA.FAILURE)
};

const loadTracking = {
  request: data => createAction(LOAD_TRACKING.REQUEST, { data }),
  success: data => createAction(LOAD_TRACKING.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRACKING.FAILURE)
};

const loadVehicleTypeMasterData = {
  request: data =>
    createAction(LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.REQUEST, { data }),
  success: data =>
    createAction(LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.FAILURE)
};

const loadVehicleGroupMasterData = {
  request: data =>
    createAction(LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.REQUEST, { data }),
  success: data =>
    createAction(LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.FAILURE)
};

const loadVehicleMasterData = {
  request: data =>
    createAction(LOAD_VEHICLE_MASTER_DATA_FILTER.REQUEST, { data }),
  success: data =>
    createAction(LOAD_VEHICLE_MASTER_DATA_FILTER.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_MASTER_DATA_FILTER.FAILURE)
};

const loadAlertAlarm = {
  request: data => createAction(LOAD_ALERT_ALARM.REQUEST, { data }),
  success: (data, isSearch) =>
    createAction(LOAD_ALERT_ALARM.SUCCESS, { data, isSearch }),
  failure: () => createAction(LOAD_ALERT_ALARM.FAILURE)
};

const loadHeatMap = {
  request: data => createAction(LOAD_HEAT_MAP.REQUEST, { data }),
  success: data => createAction(LOAD_HEAT_MAP.SUCCESS, { data }),
  failure: () => createAction(LOAD_HEAT_MAP.FAILURE)
};

function loadNextEventDetail(data) {
  return {
    type: LOAD_NEXT_EVENT_DETAIL,
    payload: data
  };
}

export {
  LOAD_DASHCAMERA,
  loadDashcamera,
  REMOVE_LISTS,
  removeLists,
  LOAD_EMERGENCYCASE,
  loadEmergencyCase,
  LOAD_EARNINGWIDGET,
  loadEarningWidget,
  loadAccEarning,
  LOAD_ACCEARNING,
  LOAD_BOOKINGWIDGET,
  LOAD_ACCBOOKING,
  loadBookingWidget,
  loadAccBooking,
  loadTopBookingData,
  LOAD_TOPBOOKINGDATA,
  loadTracking,
  LOAD_TRACKING,
  loadVehicleTypeMasterData,
  LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER,
  loadVehicleGroupMasterData,
  LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER,
  loadAlertAlarm,
  LOAD_ALERT_ALARM,
  loadVehicleMasterData,
  LOAD_VEHICLE_MASTER_DATA_FILTER,
  LOAD_HEAT_MAP,
  loadHeatMap,
  loadNextEventDetail,
  LOAD_NEXT_EVENT_DETAIL,
  loadTrackingInitData,
  LOAD_TRACKING_INIT_DATA
};

import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  Spin,
  Upload,
  Icon,
  message,
  Modal,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import axios from "axios";
import FormDivider from "../../../shared/components/FormDivider";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
class FormEditPersonal extends Component {
  state = {
    disabled: false,
    fileIdCard: [],
    fileDrivingCard: [],
    loading: false,
    check_have_state: false,

    idCardFileVisible: false,
    idCardFileImage: '',
    idCardFileTitle: '',
    idCardFileList: [],
    change_file_idCard: false,

    driverLicenseFileVisible: false,
    driverLicenseFileImage: '',
    driverLicenseFileTitle: '',
    driverLicenseFileList: [],
    change_file_driverLicense: false,

    data: {
      company_name: "",
      buildingname: "",
      roomnumber: "",
      floor: "",
      village: "",
      houseno: "",
      villageno: "",
      soi: "",
      province: "",
      district: "",
      subdistrict: "",
      postalcode: "",
      phone: "",
      email: "",
      fileidcard: [],
      filedriverlicense: [],
    },
  };

  componentDidMount() {
    const { loadProvince } = this.props;
    loadProvince();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.companyProfile.lists.companyInformation && this.state.check_have_state === false){
      const province = nextProps.companyProfile.lists.companyInformation[0].province_id
      const district = nextProps.companyProfile.lists.companyInformation[0].district_id
      const subdistrict = nextProps.companyProfile.lists.companyInformation[0].subdistrict_id

      nextProps.loadAmphur(province);
      nextProps.loadSubAmphur(district);

      this.setState({
        disabled: nextProps.companyProfile.lists.companyInformation[0].verified === 1 ? true : false,
        idCardFileList: nextProps.companyProfile.lists.companyInformation[0].id_card_file !== '' &&[
          {
            uid: "-1",
            name: "idcardfile",
            url: nextProps.companyProfile.lists.companyInformation[0].id_card_file
          }
        ],
        driverLicenseFileList: nextProps.companyProfile.lists.companyInformation[0].driver_license_file !== '' &&[
          {
            uid: "-2",
            name: "driverLicensefile",
            url: nextProps.companyProfile.lists.companyInformation[0].driver_license_file
          }
        ],
        data: {
          company_name: nextProps.companyProfile.lists.companyInformation[0].company_name,
          buildingname: nextProps.companyProfile.lists.companyInformation[0].building_name,
          roomnumber: nextProps.companyProfile.lists.companyInformation[0].room_number,
          floor: nextProps.companyProfile.lists.companyInformation[0].floor,
          village: nextProps.companyProfile.lists.companyInformation[0].village,
          houseno: nextProps.companyProfile.lists.companyInformation[0].house_no,
          villageno: nextProps.companyProfile.lists.companyInformation[0].village_no,
          soi: nextProps.companyProfile.lists.companyInformation[0].soi,
          province: province,
          district: district,
          subdistrict: subdistrict,
          postalcode: nextProps.companyProfile.lists.companyInformation[0].postcode,
          fileidcard: [
            {
              uid: "-1",
              name: "idcardfile",
              url: nextProps.companyProfile.lists.companyInformation[0].id_card_file
            }
          ],
          filedriverlicense: [
            {
              uid: "-2",
              name: "driverLicensefile",
              url: nextProps.companyProfile.lists.companyInformation[0].driver_license_file
            }
          ],
        },
        check_have_state: true,
      });
    }
  }

  onChangeProvince = (value, option, setFieldValue) => {
    const { loadAmphur } = this.props;
    setFieldValue("province", value, false);
    setFieldValue("district", "");
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadAmphur(value);
  };

  onChangeDistrict = (value, option, setFieldValue) => {
    const { loadSubAmphur } = this.props;
    setFieldValue("district", value, false);
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadSubAmphur(value);
  };

  onChangeSubDistrict = (value, option, setFieldValue) => {
    setFieldValue("subdistrict", value, false);
    setFieldValue("postalcode", option.props.code, false);
  };

  //Id Card File
  handleIdCardFilePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if(file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank")
    } else {
      this.setState({
        idCardFileImage: file.url || file.preview,
        idCardFileVisible: true,
      });
    }
  };
  handleIdCardFileCancel = () => this.setState({ idCardFileVisible: false });

   //Driver License File
   handleDriverLicenseFilePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if(file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank")
    } else {
      this.setState({
        driverLicenseFileImage: file.url || file.preview,
        driverLicenseFileVisible: true,
      });
    }
  };
  handleDriverLicenseFileCancel = () => this.setState({ driverLicenseFileVisible: false });

  onSubmit = (values, i18n) => {
    const {
      company_name,
      buildingname,
      roomnumber,
      floor,
      village,
      houseno,
      villageno,
      soi,
      province,
      district,
      subdistrict,
      postalcode,
      fileidcard,
      filedriverlicense,
    } = values;
    let self = this;

    this.setState({ loading: true });
    

    const formData = new FormData();
    // let fileIdCard_new = null;
    // let filedriverlicense_new = null;

    fileidcard.forEach((File) => {
      formData.append("fileidcard", File);
      //fileIdCard_new = File;
    });

    filedriverlicense.forEach((File) => {
      formData.append("filedriverlicense", File);
      //filedriverlicense_new = File;
    });

    formData.append("id", this.props.id);

    formData.append("company_name", company_name);
    formData.append("buildingname", buildingname);
    formData.append("roomnumber", roomnumber);
    formData.append("floor", floor);
    formData.append("village", village);
    formData.append("houseno", houseno);
    formData.append("villageno", villageno);
    formData.append("soi", soi);
    formData.append("province", province);
    formData.append("district", district);
    formData.append("subdistrict", subdistrict);
    formData.append("postalcode", postalcode);

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editcompanyprofilepersonal`,
        formData
      )
      .then(function(response) {
        if (response.status === "fail" && response.errors) {
          Modal.error({
            content: i18n.f.fail_save,
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        } else {
          message.success(i18n.u.update_success);
          self.props.history.push(`/companyprofile`);
        }
      })
  };

  render() {
    const {
      onSubmit,
      provinceLists,
      amphurLists,
      subamphurLists,
      loading,
      langBtn,
    } = this.props;
    const { companyInformation, contactInformation } = this.props.companyProfile.lists
    const { 
      idCardFileVisible,
      idCardFileImage,
      idCardFileTitle,
      idCardFileList,
      driverLicenseFileVisible,
      driverLicenseFileImage,
      driverLicenseFileTitle,
      driverLicenseFileList,
    } = this.state

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div>
        {this.props.companyProfile.lists.companyInformation[0] ?
          <LangContext.Consumer>
            {(i18n) => (
              <Formik
                enableReinitialize={true}
                initialValues={this.state.data}
                validate={(values) => {
                  let errors = {};

                  if (values.fileidcard.length == 0) {
                    errors.fileidcard = i18n.addr.fileIdCard_require;
                  }

                  if (values.filedriverlicense.length == 0) {
                    errors.filedriverlicense = i18n.addr.fileDrivingCard_require;
                  }

                  return errors;
                }}
                validationSchema={yup.object().shape({
                  houseno: yup.string().required(i18n.addr.houseno_require),
                  company_name: yup.string().required(i18n.business.company_name_require),
                  province: yup.string().required(i18n.addr.province_require),
                  district: yup.string().required(i18n.addr.district_require),
                  subdistrict: yup
                    .string()
                    .required(i18n.addr.subdistrict_require),
                  postalcode: yup.string().required(i18n.addr.postalcode_require),
                })}
                onSubmit={(values) => {
                  this.onSubmit(values, i18n);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                }) => (
                  <Spin spinning={this.state.loading}>
                    <Form onSubmit={handleSubmit}>
                      <FormDivider>
                        <h2>{i18n.c.contactinfo}</h2>
                      </FormDivider>
                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                        <Form.Item
                            required={true}
                            label={i18n.business.company_name}
                            validateStatus={
                              touched.company_name &&
                              errors.company_name &&
                              "error"
                            }
                            help={touched.company_name && errors.company_name}
                          >
                            <Input
                              data-cy="company_name"
                              type="text"
                              name="company_name"
                              value={values.company_name}
                              disabled={this.state.disabled}
                              onChange={(e) => {
                                setFieldValue(
                                  "company_name",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.company_name && errors.company_name
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.buildingName}
                            validateStatus={
                              touched.buildingname &&
                              errors.buildingname &&
                              "error"
                            }
                            help={touched.buildingname && errors.buildingname}
                          >
                            <Input
                              data-cy="buildingname"
                              type="text"
                              name="buildingname"
                              value={values.buildingname}
                              onChange={(e) => {
                                setFieldValue(
                                  "buildingname",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.buildingname && errors.buildingname
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.roomNumber}
                            validateStatus={
                              touched.roomnumber && errors.roomnumber && "error"
                            }
                            help={touched.roomnumber && errors.roomnumber}
                          >
                            <Input
                              data-cy="roomnumber"
                              type="text"
                              name="roomnumber"
                              value={values.roomnumber}
                              onChange={(e) => {
                                setFieldValue(
                                  "roomnumber",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.roomnumber && errors.roomnumber
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.floor}
                            validateStatus={
                              touched.floor && errors.floor && "error"
                            }
                            help={touched.floor && errors.floor}
                          >
                            <Input
                              data-cy="floor"
                              type="text"
                              name="floor"
                              value={values.floor}
                              onChange={(e) => {
                                setFieldValue(
                                  "floor",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.floor && errors.floor
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.village}
                            validateStatus={
                              touched.village && errors.village && "error"
                            }
                            help={touched.village && errors.village}
                          >
                            <Input
                              data-cy="village"
                              type="text"
                              name="village"
                              value={values.village}
                              onChange={(e) => {
                                setFieldValue(
                                  "village",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.village && errors.village
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.addr.houseNo}
                            validateStatus={
                              touched.houseno && errors.houseno && "error"
                            }
                            help={touched.houseno && errors.houseno}
                          >
                            <Input
                              data-cy="houseno"
                              type="text"
                              name="houseno"
                              value={values.houseno}
                              onChange={(e) => {
                                setFieldValue(
                                  "houseno",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.houseno && errors.houseno
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.villageNo}
                            validateStatus={
                              touched.villageno && errors.villageno && "error"
                            }
                            help={touched.villageno && errors.villageno}
                          >
                            <Input
                              data-cy="villageno"
                              type="text"
                              name="villageno"
                              value={values.villageno}
                              onChange={(e) => {
                                setFieldValue(
                                  "villageno",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.villageno && errors.villageno
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.soi}
                            validateStatus={touched.soi && errors.soi && "error"}
                            help={touched.soi && errors.soi}
                          >
                            <Input
                              data-cy="soi"
                              type="text"
                              name="soi"
                              value={values.soi}
                              onChange={(e) => {
                                setFieldValue(
                                  "soi",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.soi && errors.soi ? "invalid" : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                      <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            label={i18n.addr.province}
                            validateStatus={
                              touched.province && errors.province && "error"
                            }
                            help={touched.province && errors.province}
                          >
                            <Select
                              data-cy="province"
                              onChange={(value, option) =>{
                                // this.setState({
                                //   data: {
                                //     ...this.state.data,
                                //     province: value,
                                //   },
                                // })
                                this.onChangeProvince(
                                  value,
                                  option,
                                  setFieldValue
                                )}
                              }
                              value={provinceLists ? values.province : ""}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {provinceLists ? provinceLists.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                              )) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            label={i18n.addr.district}
                            validateStatus={
                              touched.district && errors.district && "error"
                            }
                            help={touched.district && errors.district}
                          >
                            <Select
                              data-cy="district"
                              onChange={(value, option) =>{
                                this.onChangeDistrict(
                                  value,
                                  option,
                                  setFieldValue
                                )}
                              }
                              value={amphurLists ? values.district : ""}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {amphurLists ? amphurLists.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                              )) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            label={i18n.addr.subdistrict}
                            validateStatus={
                              touched.subdistrict && errors.subdistrict && "error"
                            }
                            help={touched.subdistrict && errors.subdistrict}
                          >
                            <Select
                              data-cy="subdistrict"
                              onChange={(value, option) =>{
                                this.onChangeSubDistrict(
                                  value,
                                  option,
                                  setFieldValue
                                )}
                              }
                              value={subamphurLists ? values.subdistrict : ""}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {subamphurLists ? subamphurLists.map((v) => (
                                <Option key={v.id} value={v.id} code={v.postalcode}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                              )) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.addr.postalCode}
                            validateStatus={
                              touched.postalcode && errors.postalcode && "error"
                            }
                            help={touched.postalcode && errors.postalcode}
                          >
                            <Input
                              data-cy="postalcode"
                              type="text"
                              name="postalcode"
                              value={values.postalcode}
                              onChange={(e) => {
                                setFieldValue(
                                  "postalcode",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.postalcode && errors.postalcode
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <FormDivider>
                        <h2>{i18n.i.identification_documents}</h2>
                      </FormDivider>

                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.c.copy_id_card}
                            validateStatus={
                              touched.fileidcard && errors.fileidcard && "error"
                            }
                            help={touched.fileidcard && errors.fileidcard}
                          >
                            <Upload
                            className="test-upload-fileidcard"
                            name="fileidcard"
                            listType="picture-card"
                            fileList={idCardFileList}
                            onPreview={this.handleIdCardFilePreview}
                            disabled={this.state.disabled}
                            beforeUpload={(file) => {
                              const isCheckTypeFile =
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "application/pdf";
                              if (!isCheckTypeFile) {
                                message.error(
                                  i18n.y.youCanOnlyUploadJPGPNGPDFFile
                                );
                              }
                              // const isLt2M = file.size / 1024 / 1024 < 1;
                              const checkSizeFile = file.size / 1024 <= 5120;
                              if (!checkSizeFile) {
                                message.error( 
                                  i18n.worksheet.upload_size_support
                                );
                              }

                              if (isCheckTypeFile && checkSizeFile) {
                                setFieldValue("fileidcard", [file]);
                              }

                              return false;
                            }}
                            onChange={({ file, fileList }) => {
                              const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png" ||
                              file.type === "application/pdf";
                              const checkSizeFile = file.size / 1024 <= 5120;

                              if (isCheckTypeFile && checkSizeFile || file.status === "removed") {
                                setFieldValue(
                                  "fileidcard", file.status !== "removed" ? [file] : []
                                );
                                this.setState({
                                  idCardFileList: fileList,
                                  change_file_idCard: true,
                                });
                              }
                            }}
                          >
                              {idCardFileList.length >= 1
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                              className="test-modal-id-card"
                              visible={idCardFileVisible}
                              // title={idCardFileTitle}
                              footer={null}
                              onCancel={this.handleIdCardFileCancel}
                          >
                            <img alt="example" style={{ width: '100%' }} src={idCardFileImage} />
                          </Modal>
                        </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.c.copy_driver_license}
                            validateStatus={
                              touched.filedriverlicense &&
                              errors.filedriverlicense &&
                              "error"
                            }
                            help={
                              touched.filedriverlicense &&
                              errors.filedriverlicense
                            }
                          >
                            
                            <Upload
                            className="test-upload-filedriverlicense"
                            name="filedriverlicense"
                            listType="picture-card"
                            fileList={driverLicenseFileList}
                            onPreview={this.handleDriverLicenseFilePreview}
                            disabled={this.state.disabled}
                            beforeUpload={(file) => {
                              const isCheckTypeFile =
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "application/pdf";
                              if (!isCheckTypeFile) {
                                message.error(
                                  i18n.y.youCanOnlyUploadJPGPNGPDFFile
                                );
                              }
                              // const isLt2M = file.size / 1024 / 1024 < 1;
                              const checkSizeFile = file.size / 1024 <= 5120;
                              if (!checkSizeFile) {
                                message.error(i18n.worksheet.upload_size_support);
                              }

                              if (isCheckTypeFile && checkSizeFile) {
                                setFieldValue("filedriverlicense", [file]);
                              }

                              return false;
                            }}
                            onChange={({ file, fileList }) => {
                              const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png" ||
                              file.type === "application/pdf";
                              const checkSizeFile = file.size / 1024 <= 5120;

                              if(isCheckTypeFile && checkSizeFile || file.status === "removed") {
                                setFieldValue(
                                  "filedriverlicense", file.status !== "removed" ? [file] : []
                                );
                                this.setState({
                                  driverLicenseFileList: fileList,
                                  change_file_driverLicense: true,
                                });
                              }
                            }}        
                          >
                              {driverLicenseFileList.length >= 1
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                              className="test-modal-driver-license"
                              visible={driverLicenseFileVisible}
                              // title={driverLicenseFileTitle}
                              footer={null}
                              onCancel={this.handleDriverLicenseFileCancel}

                          >
                            <img alt="example" style={{ width: '100%' }} src={driverLicenseFileImage} />
                          </Modal>
                        </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                          <LangContext.Consumer>
                            {(i18n) => (
                              <Form.Item>
                                <Link to="/companyprofile">
                                  <Button
                                    data-cy="btn-go-back"
                                    type="default"
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  >
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.b.back}
                                    </LangContext.Consumer>
                                  </Button>
                                </Link>
                                <Button type="primary" htmlType="submit" data-cy="btn-submit">
                                  {i18n.s.save}
                                </Button>
                              </Form.Item>
                            )}
                          </LangContext.Consumer>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                )}
              />
            )}
          </LangContext.Consumer>
        : "" }
      </div>
    );
  }
}

export default withRouter(FormEditPersonal)
import { createTypes, createAction } from "lib/action";

const LOAD_TRANSPORT_QUOTATION = createTypes("transportqt", "load");
const SHOW_TRANSPORT_QUOTATION = createTypes("transportqt", "show");
const ASSIGN_JOB = createTypes("transportqt", "upload");
const UPDATE_STATUS_QT = createTypes("transportqt", "updatestatus");

const loadTransportQT = {
  request: (page, pageSize, orderBy, orderType, filters) => {
    return createAction(LOAD_TRANSPORT_QUOTATION.REQUEST, { page, pageSize, orderBy, orderType, filters })
  },
  success: (lists) => createAction(LOAD_TRANSPORT_QUOTATION.SUCCESS, { lists }),
  failure: () => createAction(LOAD_TRANSPORT_QUOTATION.FAILURE),
};

const showTransportQT = {
  request: id =>
    createAction(SHOW_TRANSPORT_QUOTATION.REQUEST, { id }),
  success: (data) => createAction(SHOW_TRANSPORT_QUOTATION.SUCCESS, { data }),
  failure: () => createAction(SHOW_TRANSPORT_QUOTATION.FAILURE),
};

const assignJob = {
  request: (worksheet_id, qt_id, vehicle_id, driver_id, phone_no) =>
    createAction(ASSIGN_JOB.REQUEST, { worksheet_id, qt_id, vehicle_id, driver_id, phone_no }),
  success: (data) => createAction(ASSIGN_JOB.SUCCESS, { data }),
  failure: () => createAction(ASSIGN_JOB.FAILURE),
}

const updateStatusQt = {
  request: (worksheet_id, qt_id, status) =>
    createAction(UPDATE_STATUS_QT.REQUEST, { worksheet_id, qt_id, status }),
  success: (data) => createAction(UPDATE_STATUS_QT.SUCCESS, { data }),
  failure: () => createAction(UPDATE_STATUS_QT.FAILURE),
}

export {
  LOAD_TRANSPORT_QUOTATION,
  SHOW_TRANSPORT_QUOTATION,
  ASSIGN_JOB,
  UPDATE_STATUS_QT,
  loadTransportQT,
  showTransportQT,
  assignJob,
  updateStatusQt
};

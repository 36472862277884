import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID
} from "../../../../../constants/local_storage";
import * as actions from "./actions";

function* loadWorkinProgress(action) {

  const { page, pageSize, types } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loadworksheetworkingprocess`,
      {
        page,
        pageSize,
        company_id: COMPANY_ID,
        types 
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadWorkinProgress.success(res.data));
  } catch (err) {
    yield put(actions.loadWorkinProgress.failure());
  }
}

function* loadWorkinProgressDetail(action) {

  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loaddetail/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` }}
    );

    yield put(actions.loadWorkinProgressDetail.success(res.data));
  } catch (err) {
    yield put(actions.loadWorkinProgressDetail.failure());
  }
}

function* copyWorksheet(action) {

  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/copyworksheet/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` }}
    );

    yield put(actions.copyWorksheet.success(res.data));
  } catch (err) {
    yield put(actions.copyWorksheet.failure());
  }
}

function* removeWorksheet(action) {

  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/delete/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` }}
    );

    yield put(actions.removeWorksheet.success(res.data));
  } catch (err) {
    yield put(actions.removeWorksheet.failure());
  }
}

export default function* watchTransporWorksheetCompanyState() {
  yield all([
    takeEvery(actions.LOAD_TRANSPORT_WORKINPROGRESS.REQUEST, loadWorkinProgress),
    takeEvery(actions.LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.REQUEST, loadWorkinProgressDetail),
    takeEvery(actions.TRANSPORT_COPY_WORKSHEET.REQUEST, copyWorksheet),
    takeEvery(actions.TRANSPORT_REMOVE_WORKSHEET.REQUEST, removeWorksheet)
  ]);
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import { loadCompaniesVerify } from "../actions";

import { GeneralStyledContent } from "../../../../styled/backhaul-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import IndexFilterDrawer from "./IndexFilterDrawer";
import LangContext from "modules/shared/context/langContext";
import {
  Button,
  Table,
  Row,
  Col,
  Pagination,
  Spin,
  Badge,
  Icon,
  Tooltip,
  Divider,
} from "antd";
const columnStyle = { fontSize: "12px", fontWeight: "400" };
const logo_img = {
  width: "50px",
  height: "50px",
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      isFilter: false,
      filterVisible: false,
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      companyID: "",
      visible: false,
      orderBy: "companies.id",
      orderType: "asc",
      filterObj: {
        FilterObjCompanyType: "all",
        FilterObjCompanyVerify: "all",
      },
      PERMIS_ADD: false, // set default permissioon add
      PERMIS_EDIT: false, // set default permissioon edit
      PERMIS_SUPER: 0, // 1 เป็น super admin
    };

    this.columns = [
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.n.no}</LangContext.Consumer>
        ),
        dataIndex: "rowNumber",
        width: "50px",
        // fixed: "left",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.business.logo_file}
          </LangContext.Consumer>
        ),
        dataIndex: "logo_file",
        key: "logo_file",
        sorter: true,
        // width: "12%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>{text ? <img src={text} style={logo_img} /> : ""}</div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.business.company_name}
          </LangContext.Consumer>
        ),
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        // width: "12%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.business.company_type}
          </LangContext.Consumer>
        ),
        dataIndex: "types",
        key: "types",
        sorter: true,
        // width: "12%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <LangContext.Consumer>
                  {(i18n) => i18n.companyDetails[text]}
                </LangContext.Consumer>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.verify_status}
          </LangContext.Consumer>
        ),
        dataIndex: "verified",
        key: "verified",
        sorter: true,
        // width: "12%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <LangContext.Consumer>
                {(i18n) => (text === 1 ? i18n.v.verify_yes : i18n.v.verify_no)}
              </LangContext.Consumer>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: "90px",
        fixed: "right",
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "vehicle",
                "can_edit"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                  data-cy={`btn-edit-${record.company_id}`}
                    to={`/companyverify/detail/${record.company_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
    //table in index
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {}

  loadData = (loading) => {
    this.props.loadCompaniesVerify(
      {
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      loading
    );
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "companies.id",
        orderType: "asc",
      });
      orderBy = "companies.id";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadCompaniesVerify(
      {
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });

    this.props.loadCompaniesVerify(
      {
        page: page,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  // Filter

  onFilterButtonClick = (e) => {
    this.loadData(true);
  };

  handleFilterObjCompanyType = (e) => {
    this.setState({
      page: 1,
      currentPage: 1,
      filterObj: {
        ...this.state.filterObj,
        FilterObjCompanyType: e,
      },
      isFilter: e != null && e != "",
    });
  };

  handleFilterObjCompanyVerify = (e) => {
    this.setState({
      page: 1,
      currentPage: 1,
      filterObj: {
        ...this.state.filterObj,
        FilterObjCompanyVerify: e,
      },
      isFilter: e != null && e != "",
    });
  };

  // End Filter

  render() {
    const { total, loading } = this.props.companyVerify;
    const columns = this.columns.map((col) => col);

    return (
      <AuthorizeComponent matching_name="company_verify">
        {checkPermissions(
          this.props.auth.profile.permissions,
          "company_verify",
          "can_view"
        ) == true ? (
          <GeneralStyledContent>
            <div>
              <Row gutter={24}>
                <Col span={24}>
                  <Breadcrumb
                    match={this.props.match}
                    style={{ margin: "0px 0px 14px 10px" }}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Divider orientation="left">
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.company_verify}
                    </LangContext.Consumer>
                  </Divider>
                </Col>
              </Row>
              <Row
                gutter={24}
                type="flex"
                justify="end"
                style={{ height: "38px" }}
              >
                <Col xs={15} sm={20} md={21} lg={22} xl={22}></Col>
                <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                  <Badge dot={this.state.isFilter} align="right">
                    <Tooltip
                      placement="right"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.o.openFilter}
                        </LangContext.Consumer>
                      }
                    >
                      <Button size="small" data-cy="btn-filter" onClick={this.showFilterDrawer}>
                        <Icon type="filter" style={{ marginRight: "5px" }} />
                        <LangContext.Consumer>
                          {(i18n) => i18n.f.filter}
                        </LangContext.Consumer>
                      </Button>
                    </Tooltip>
                  </Badge>
                  <IndexFilterDrawer
                    onClose={this.onFilterDrawerClose}
                    visible={this.state.filterVisible}
                    onFilterButtonClick={this.onFilterButtonClick}
                    handleFilterObjCompanyType={this.handleFilterObjCompanyType}
                    handleFilterObjCompanyVerify={
                      this.handleFilterObjCompanyVerify
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Spin spinning={loading}>
                  <Table
                  data-cy="data-table"
                    scroll={{ x: 1300 }}
                    bordered
                    rowKey="company_id"
                    columns={columns}
                    dataSource={this.props.companyVerify.lists}
                    // size="small"
                    pagination={false}
                    onChange={this.handleTableChange}
                    getTrProps={(state, rowNumber) => {
                      return {
                        style: {
                          background:
                            rowNumber === this.state.selected
                              ? "#00afec"
                              : "white",
                          color:
                            rowNumber === this.state.selected
                              ? "white"
                              : "black",
                        },
                      };
                    }}
                  />
                </Spin>
              </Row>
              <Row type="flex" justify="end" style={{ padding: "15px" }}>
                <Col span={24} align="right">
                  <Pagination
                  data-cy="pagination"
                    defaultPageSize={this.state.pageSize}
                    size="small"
                    current={this.state.currentPage}
                    total={total}
                    onChange={this.handlePageChange}
                  />
                </Col>
              </Row>
            </div>
          </GeneralStyledContent>
        ) : (
          ""
        )}
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ companyVerify, vehicle, auth }) => ({
    companyVerify,
    vehicle,
    auth,
  }),
  {
    loadCompaniesVerify: loadCompaniesVerify.request,
  }
)(Index);

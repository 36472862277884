import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
    Button,
    Table,
    Row,
    Col,
    Select,
    Modal,
    Pagination,
    Spin,
    Badge,
    Popconfirm,
    Tooltip,
    Icon
  } from "antd";

import { 
    loadVehicle, 
    removeLists 
} from "../actions";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import axios from "axios";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import IndexFilterDrawer from "./IndexFilterDrawer";
import LangContext, { i18n } from  "modules/shared/context/langContext";
import { StyledSearchForm2 } from "../../../../styled/common-styled";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const Option = Select.Option;

class MappingDriver extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTruck: [],
            dataMatching: [],
            dataDriver: [],
            selectedRowKeys: [],
            visible: false,
            confirmLoading: false,
        };

        this.columnsTruck = [
            {
              title: <LangContext.Consumer>{i18n=>i18n.p.plant}</LangContext.Consumer>,
              dataIndex: "plant",
              width: 150
            },
            {
            title: <LangContext.Consumer>{i18n=>i18n.v.vehicleID}</LangContext.Consumer>,
              dataIndex: "vehicle_id",
              width: 150
            }
        ];

        this.columnsDriver = [
            {
                title: <LangContext.Consumer>{i18n=>i18n.p.plant}</LangContext.Consumer>,
                dataIndex: "plant",
                // width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.d.driverCode}</LangContext.Consumer>,
                dataIndex: "driver_code",
                // width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.f.firstName}</LangContext.Consumer>,
                dataIndex: "frist_name",
                // width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.l.lastName}</LangContext.Consumer>,
                dataIndex: "last_name",
                // width: 70
            },
        ];

        this.columnsTruckDriverMapping = [
            {
                title: <LangContext.Consumer>{i18n=>i18n.p.plant}</LangContext.Consumer>,
                dataIndex: "plant",
                width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.v.vehicleID}</LangContext.Consumer>,
                dataIndex: "vehicle_id",
                width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.d.driverCode}</LangContext.Consumer>,
                dataIndex: "driver_code",
                width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.f.firstName}</LangContext.Consumer>,
                dataIndex: "frist_name",
                width: 70
            },
            {
                title: <LangContext.Consumer>{i18n=>i18n.l.lastName}</LangContext.Consumer>,
                dataIndex: "last_name",
                width: 70
            },
        ];

    }


    componentDidMount() {
        for (let i = 0; i < 15; i++) {
            this.state.dataTruck.push({
                key: i,
                plant: `Plant ${i}`,
                vehicle_id: `${i+100}`,
            });
        }

        for (let k = 20; k < 35; k++ ){
            this.state.dataDriver.push({
                key: k,
                plant: `Plant ${k}`,
                driver_code: `${k+50}`,
                frist_name: `ชื่อ ${k}`,
                last_name: `นามสกุล ${k}`
            });
        }

        for (let j = 100; j < 110; j ++){
            this.state.dataMatching.push({
                key: j,
                plant: `Plant ${j}`,
                vehicle_id: `${j+100}`,
                driver_code: `${j+260}`,
                frist_name: `ชื่อ ${j}`,
                last_name: `นามสกุล ${j}`
            });
        }
    };
  
    componentWillUnmount() {
        this.onRemoveLists();
    };
    
    onRemoveLists = () => {
        this.props.removeLists();
    };

    rowSelectionruckDriverMapping = (selectedRowKeys,selectedRows) => {
        console.log(selectedRowKeys);
        console.log(selectedRows);
    };
    
    handleMappin = (selectedRowKeys,selectedRows) => {
        console.log(selectedRowKeys);
        console.log(selectedRows);
    };

    onRowClick = (selectedRowKeys,selectedRows) =>{
        console.log("111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111");
        console.log(selectedRowKeys);
        console.log(selectedRows);
        this.showModal();
    }

// Modal Add Vehicle Group

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            ModalText: 'เพิ่มกลุ่มรถ',
            confirmLoading: true,
        });

        this.createVeticle(true);


        setTimeout(() => {
            this.setState({
                visible: false,
                confirmLoading: false,
            });

            // this.loadData(true);
        }, 1200);
    };

    handleCancel = () => {
        this.setState({
        visible: false,
        });
    };

  // End Modal Add Vehicle Group

    render() {
        const columnsTruck = this.columnsTruck.map(col => col);
        const columnsTruckDriverMapping = this.columnsTruckDriverMapping.map(col => col);
        const columnsDriver = this.columnsDriver.map(col => col);

        const rowSelectionTruckDriverMapping  = {
            type: "radio",

            onChange: (selectedRowKeys, selectedRows) => {
                this.rowSelectionruckDriverMapping(selectedRowKeys,selectedRows );
            },
            getCheckboxProps: record => ({
              disabled: record.name === "Disabled User", // Column configuration not to be checked
              name: record.name
            })
        };
        
        const rowSelectionTruck  = {
            type: "radio",
            onChange: (selectedRowKeys, selectedRows) => {
                this.handleMappin(selectedRowKeys,selectedRows );
            },
            getCheckboxProps: record => ({
              disabled: record.name === "Disabled User", // Column configuration not to be checked
              name: record.name
            })
        };

        const rowSelectionDriver  = {
            type: "radio",
            onChange: (selectedRowKeys, selectedRows) => {
                this.handleMappin(selectedRowKeys,selectedRows );
            },
            getCheckboxProps: record => ({
              disabled: record.name === "Disabled User", // Column configuration not to be checked
              name: record.name
            })
        };


        return (

            <GeneralStyledContent>
                <Row gutter={24}>
                    <Col span={24}>
                        <Breadcrumb
                            match={this.props.match}
                            style={{ margin: "0px 0px 14px 10px" }}
                        />
                    </Col>
                </Row>
                <br/>
                <Row gutter={24}>
                    <Col sm={24} md={1} lg={1} xl={1} style={{marginLeft: "20px", padding: "5px"}} >
                        <p><LangContext.Consumer>{i18n=>i18n.p.plant}</LangContext.Consumer>:</p>
                    </Col>
                    <Col sm={24} md={10} lg={10} xl={10} style={{marginLeft: "15px", padding: "5px"}} >
                        <Select
                                //showSearch
                                style={{ width: "100%" }}
                                // onChange={value =>
                                //   setFieldValue("alarmalert_type_id", value)
                                // }
                                // value={values.alarmalert_type_id}
                        >
                            <Option value="">Test</Option>

                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={13} lg={13} xl={13}>
                        <StyledSearchForm2>
                            <Row style={{marginBottom: "10px"}}>
                                <h4 style={{marginLeft: "10px"}}>
                                    <LangContext.Consumer>
                                        {i18n=>i18n.t.truckDriverMatching}
                                    </LangContext.Consumer>
                                </h4>
                                <Table
                                    columns={columnsTruckDriverMapping}
                                    rowKey="key"
                                    rowSelection={rowSelectionTruckDriverMapping}
                                    dataSource={ this.state.dataMatching}
                                    bordered
                                    pagination={{ size: "small" }}
                                    scroll={{ y: 610}}
                                    style={{marginLeft: "10px",fontSize: "12px", fontWeight: "400"}}
                                />
                            </Row>
                            <Row type="flex" justify="center">
                                <Button type="primary" block>
                                    <LangContext.Consumer>{i18n=>i18n.u.unMatch}</LangContext.Consumer>
                                </Button>
                            </Row>
                        </StyledSearchForm2>    
                    </Col>

                    <Col sm={24} md={11} lg={11} xl={11}>
                        <StyledSearchForm2>
                            <Row style={{marginBottom: "10px"}}>
                                <h4 style={{marginLeft: "10px"}}>
                                    <LangContext.Consumer>
                                        {i18n=>i18n.t.truck}
                                    </LangContext.Consumer>
                                </h4> 
                                <Table
                                    columns={columnsTruck}
                                    rowSelection={rowSelectionTruck}
                                    dataSource={ this.state.dataTruck}
                                    bordered
                                    pagination={{ size: "small" }}
                                    scroll={{ y: 240 }}
                                    style={{marginRight: "10px",fontSize: "12px", fontWeight: "400" }}
                                />
                            </Row>

                            <Row style={{marginBottom: "10px"}} >
                                <h4 style={{marginLeft: "10px"}}>
                                    <LangContext.Consumer>
                                        {i18n=>i18n.c.chauffeur}
                                    </LangContext.Consumer>
                                </h4> 
                                <Table
                                    columns={columnsDriver}
                                    rowSelection={rowSelectionDriver}
                                    // onRowClick={this.onRowClick}
                                    onRowClick={this.showModal}
                                    dataSource={ this.state.dataDriver}
                                    bordered
                                    pagination={{ size: "small" }}
                                    scroll={{ y: 240 }}
                                    style={{marginRight: "10px",fontSize: "12px", fontWeight: "400"}}
                                />
                            </Row>

                            <Row type="flex" justify="center">
                                <Button type="primary" block onClick={this.showModal}>
                                    <LangContext.Consumer>{i18n=>i18n.m.match}</LangContext.Consumer>
                                </Button>
                            </Row>
                        </StyledSearchForm2>
                    </Col>
                </Row>
            </GeneralStyledContent>
        );
    }

}
export default connect(
    ({vehicle, auth}) => ({vehicle, auth}),
    {
        loadVehicle:  loadVehicle.request,
        removeLists
    }
)(MappingDriver);
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import axios from "axios";
import {
  createTransportationrating,
} from "../actions";
import {
  COMPANY_ID,
} from "../../../../constants/local_storage";
import { GeneralStyledContent } from "../../../../styled/common-styled";
 import LangContext from "modules/shared/context/langContext";
import { StyledSearchForm } from "../../../../styled/common-styled";
import {
  Button,
  Row,
  Col,
  Modal,
  message,
  Divider,
  Rate,
} from "antd";


const img = {
  width: "100px",
  height: "100px",
}

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmation_period: 5,
      punctuality: 5,
      personality_driver: 5,
      good_service: 5,
      companyInformation: [],
      contactInformation: [{}],
      checkWorkSheetID: "",
      checkRating: "",
      submitBtn: false,
    };
  }

  componentDidMount() {
    let self = this;

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanyinformation`,
        {
          worksheetID: this.props.match.params.worksheetID,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({ 
            companyInformation: response.data.companyInformation,
            contactInformation: response.data.contactInformation,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

      //check worksheetID is exist ?
      axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/checkworksheetisexist`,
        {
          worksheetID: this.props.match.params.worksheetID,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({ 
            checkWorkSheetID: response.data,
          });
        }
      })
      .catch(function(error) {
        self.setState({ 
          checkWorkSheetID: "not exist",
        });
        console.log(error);
      });

      //check Transportationrating is exist ?
      axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/checktransportationratingisexist`,
        {
          worksheetID: this.props.match.params.worksheetID,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({ 
            checkRating: response.data,
            submitBtn: response.data == "not exist" ? false : true,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
  }

  handleRateChange = (keyName,value) => {
    this.setState({ [keyName]: value });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = (i18n) => {
    this.props.createTransportationrating(
      {
        confirmation_period: this.state.confirmation_period,
        punctuality: this.state.punctuality,
        personality_driver: this.state.personality_driver,
        good_service: this.state.good_service,
        totalRating: (this.state.confirmation_period+this.state.punctuality+this.state.personality_driver+this.state.good_service)/4,
        worksheetID: this.props.match.params.worksheetID,
        accessToken: this.props.auth.accessToken,
        history: this.props.history,
        message: message,
        word: i18n.e.evaluationsSuccessfully,
      },
    );
    this.setState({
      visible: false,
    });
  };

  render() {
     const totalRating = (this.state.confirmation_period+this.state.punctuality+this.state.personality_driver+this.state.good_service)/4;
    return (
      <AuthorizeComponent matching_name="transportation_rating">
        <GeneralStyledContent>
          <LangContext.Consumer>
            {i18n => (
              <div>
              <Row>
                <Col span={24}>
                  <Divider orientation="left">
                    {i18n.s.satisfactionSurvey}
                  </Divider>
                </Col>
              </Row>
              {this.state.checkWorkSheetID && this.state.checkWorkSheetID !== "is exist" ?
                <p style={{ color: "red",}}>
                  {i18n.w.workSheetIdNotExist}
                </p>
                :
                this.state.companyInformation[0] ?
                  this.state.companyInformation[0].company_id == COMPANY_ID ?
                    <Row>
                      <Row  
                        type="flex"
                        justify="start"
                        style={{marginTop:"20px"}}
                      >
                        <Col span={24} style={{display: "flex"}}>
                          <div>
                            {this.state.companyInformation[0] ? <img src={this.state.companyInformation[0].logo_file} style={img}/> : ""}
                          </div>
                          <div 
                            style={{
                              alignItems: "center",
                              display: "flex",
                              margin: "0px 0px 0px 20px",
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.companyInformation[0] ? this.state.companyInformation[0].company_name : ""}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{margin:"40px 0px 20px 0px"}}>
                        <Col span={24}>
                          <div>
                            <div>
                              {`${i18n.addr.floor} ${this.state.companyInformation[0].floor} ${this.state.companyInformation[0].building_name},
                              ${i18n.addr.roomNumber} ${this.state.companyInformation[0].room_number}, ${i18n.addr.village} ${this.state.companyInformation[0].village}, 
                              ${i18n.addr.houseNo} ${this.state.companyInformation[0].house_no}, ${i18n.addr.villageNo} ${this.state.companyInformation[0].village_no}, 
                              ${i18n.addr.soi} ${this.state.companyInformation[0].soi}, ${i18n.addr.subdistrict} ${this.state.companyInformation[0].subdistrict}`}
                            </div>
                            <div>
                              {`${this.state.companyInformation[0].district}, ${i18n.addr.province}${this.state.companyInformation[0].province_name}, 
                              ${i18n.addr.postalCode} ${this.state.companyInformation[0].postcode}`}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{margin:"0px 0px 40px 0px"}}>
                        <Col span={24}>
                          <div style={{fontWeight: "bold"}}>
                            {i18n.j.job_contact_name}
                          </div>
                          {this.state.contactInformation.map((item, i) => (
                            <div>
                              {`${i18n.n.name} ${item.firstname} ${item.lastname} ${i18n.c.contact_telno} ${item.phone_no} 
                              ${i18n.e.email} ${item.email}`}
                            </div>
                          ))}
                        </Col>
                      </Row>
                      <StyledSearchForm style={{lineHeight:"40px"}}>
                        {this.state.checkRating === "not exist" ?
                          <Row>
                            <Row> 
                              <Col span={24} style={{textAlign:"center", fontSize:"18px", margin:"10px 0px 0px 0px"}}>
                                {i18n.r.rateServiceQuality}
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24} style={{textAlign:"center"}}>
                                <Rate
                                  data-cy="star-rating" 
                                  disabled
                                  value={totalRating} 
                                  style={{fontSize:"30px"}}
                                  allowHalf={true} 
                                />
                              </Col>
                            </Row>
                            <Row  
                              gutter={24}
                              type="flex"
                              justify="center"
                              style={{marginTop:"40px"}}
                            >
                              <Col style={{margin:"0px 0px 10px 0px"}}>
                                <div style={{display:"flex"}}>
                                  <div style={{width:"200px"}}>
                                    {i18n.r.rateConfirmationPeriod}
                                  </div>
                                  <div>
                                    <Rate
                                      data-cy="star-rating-confirmation_period" 
                                      onChange={e => this.handleRateChange("confirmation_period", e)}
                                      value={this.state.confirmation_period} 
                                      allowClear={false}
                                      allowHalf={true} 
                                    />
                                  </div>
                                </div>
                                <div style={{display:"flex"}}>
                                  <div style={{width:"200px"}}>
                                    {i18n.r.ratePunctuality}
                                  </div>
                                  <div>
                                    <Rate
                                      data-cy="star-rating-punctuality" 
                                      onChange={e => this.handleRateChange("punctuality", e)}
                                      value={this.state.punctuality}  
                                      allowClear={false} 
                                      allowHalf={true} 
                                    />
                                  </div>
                                </div>
                                <div style={{display:"flex"}}>
                                  <div style={{width:"200px"}}>
                                    {i18n.r.ratePersonality_driver}
                                  </div>
                                  <div>
                                    <Rate
                                      data-cy="star-rating-personality_driver" 
                                      onChange={e => this.handleRateChange("personality_driver", e)}
                                      value={this.state.personality_driver}
                                      allowClear={false}
                                      allowHalf={true}   
                                    />
                                  </div>
                                </div>
                                <div style={{display:"flex"}}>
                                  <div style={{width:"200px"}}>
                                    {i18n.r.rateGoodservice}
                                  </div>
                                  <div>
                                    <Rate
                                      data-cy="star-rating-good_service" 
                                      onChange={e => this.handleRateChange("good_service", e)}
                                      value={this.state.good_service}
                                      allowClear={false}
                                      allowHalf={true}  
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                          :
                          <Row>
                            <Row> 
                              <Col span={24} style={{textAlign:"center", fontSize:"18px", margin:"10px 0px 0px 0px"}}>
                                {i18n.t.thisWorkSheetisAlreadyhaveRating}
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24} style={{textAlign:"center"}}>
                                <Rate
                                  data-cy="star-rating-total" 
                                  disabled
                                  value={totalRating} 
                                  style={{fontSize:"30px"}}
                                  allowHalf={true} 
                                />
                              </Col>
                            </Row>
                          </Row>
                        }
                        </StyledSearchForm>
                        <Row
                          gutter={24}
                          type="flex"
                          justify="center"
                          style={{marginTop:"50px"}}
                        >
                          <Col>
                            <Button 
                              data-cy="btn-submit"
                              type="primary"
                              onClick={this.showModal}
                              disabled={this.state.submitBtn}
                            >
                              {i18n.o.ok}
                            </Button>
                            <Modal
                              className="test-modal-confirm-submit"
                              title={i18n.d.doYouWantToAddTheseItems}
                              visible={this.state.visible}
                              onOk={() => this.handleSubmit(i18n)}
                              onCancel={this.hideModal}
                              okText={i18n.o.ok}
                              cancelText={i18n.c.cancel}
                              okButtonProps={{
                                "data-cy": "btn-modal-ok"
                              }}
                              cancelButtonProps={{
                                "data-cy": "btn-modal-cancel"
                              }}
                            >
                              <p>{i18n.r.rateConfirmationPeriod} : {this.state.confirmation_period}</p>
                              <p>{i18n.r.ratePunctuality} : {this.state.punctuality}</p>
                              <p>{i18n.r.ratePersonality_driver} : {this.state.personality_driver}</p>
                              <p>{i18n.r.rateGoodservice} : {this.state.good_service}</p>
                            </Modal>
                          </Col>
                        </Row>
                      </Row>
                    :
                    <Row>
                      <Col span={24}>
                        <p 
                          style={{
                            color: "red",
                          }}
                        >
                          {i18n.y.youHaveNoRightToEvaluateThisWorksheet}
                        </p>
                      </Col>
                    </Row>
                  :
                  ""
                }
                </div>
              )}
            </LangContext.Consumer>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ vehicle, auth }) => ({
    vehicle,
    auth,
  }),
  {
    createTransportationrating: createTransportationrating.request,
  }
)(Index);

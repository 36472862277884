import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  AutoComplete,
  InputNumber,
  DatePicker,
  Upload,
  Icon,
  message,
  Checkbox,
  Tooltip,
  List,
  Descriptions,
  Popconfirm,
  Modal,
} from "antd";

import moment from "moment";

import Contactform from "./Contactform";
import Directjob from "./Directjob";
import {
  StyledSearchForm,
  TitleText,
  DividerStyled,
} from "../../../../styled/backhaul-styled";

import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID,
} from "../../../../constants/local_storage";
import axios from "axios";
import AddFacilityWorksheet from "modules/master-data/facility/components/AddFacilityWorksheet";
import { translate } from "../../../../lib/helper";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const OptionAuto = AutoComplete.Option;
const { TextArea } = Input;
const format = "HH:mm";

const getImage = (val) => {
  let images = [];
  if (val !== "" && typeof val !== "undefined") {
    let img = decodeURIComponent(val).split(",");
    if (img.length > 0) {
      return img;
    } else {
      return images.push(val);
    }
  }
  return [];
};

const initialState = {
  document_number: "",
  end_visible: false,
  direct_visible: false,
  visible: false,
  loading: false,
  vehicle_type_img: "",
  origin_location_name: "",
  origin_location_id: "",
  button_type: "",
  contactloading: false,
  add: false,
  contact_id: 0,
  contactdestination: [],
  contact: {
    title_name: "mr",
    firstname: "",
    lastname: "",
    phone: "",
    destination_location_name: "",
    destination_location_id: "",
    destination_receive_date: "",
    quantity: "",
    unit: "",
  },
  dataform: {},
  worksheet_id: "",
  showPreview: false,
};

export default class Forms extends PureComponent {
  state = {
    ...initialState,
    vehicle_type: [],
    product_detail: [],
    credit_term: [],
    frequency_routine: [],
    contract_term: [],
    originlocationLists: [],
    destinationlocationLists: [],
    logisticgroupLists: [],
    transporterLists: [],
    expenseLists: [],
    product_file: [],
    priceunitLists: [],
  };

  genDocumentNumber = (name) => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gendocnumber`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ document_number: res.document_number });
      });
  };

  loadOriginLocation = (name) => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        company_id: COMPANY_ID,
        name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ originlocationLists: res.data });
      });
  };

  loadDestinationLocation = (name) => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        company_id: COMPANY_ID,
        name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ destinationlocationLists: res.data });
      });
  };

  loadLogisticgroup = () => {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlogisticgroupbycompany`,
        {
          company_id: COMPANY_ID,
        },
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let logisticgroupLists = response.data.data.map((d) => {
            return {
              key: d.id,
              name: d.name,
            };
          });
          self.setState({ logisticgroupLists });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  loadWorksheetMasterData() {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/getworksheetmasterdata`,
        {
          company_id: COMPANY_ID,
        },
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let value = response.data.data;
          let contract_term = self.formatMasterData(
              value.contract_term,
              "nameKey"
            ),
            frequency_routine = self.formatMasterData(
              value.frequency,
              "nameKey"
            ),
            credit_term = self.formatMasterData(value.credit_term, "nameKey"),
            priceunitLists = self.formatMasterData(value.price_unit, "nameKey"),
            product_detail = self.formatMasterData(value.product, "nameKey"),
            transporterLists = self.formatMasterData(value.transport, "name"),
            logisticgroupLists = self.formatMasterData(value.logistic, "key"),
            expenseLists = self.formatMasterData(value.expense, "expense"),
            vehicle_type = self.formatMasterData(
              value.vehicle_type,
              "vehicle_type"
            );

          self.setState({
            contract_term,
            frequency_routine,
            credit_term,
            priceunitLists,
            product_detail,
            transporterLists,
            logisticgroupLists,
            expenseLists,
            vehicle_type,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  formatMasterData = (data, type) => {
    if (type === "nameKey") {
      return data.map((d) => {
        return {
          id: d.id,
          name: d.name_key,
        };
      });
    }

    if (type === "key") {
      return data.map((d) => {
        return {
          key: d.id,
          name: d.name,
        };
      });
    }

    if (type === "name") {
      return data.map((d) => {
        return {
          id: d.id,
          name: d.name,
        };
      });
    }

    if (type === "expense") {
      return data.map((d) => {
        return {
          id: d.id,
          name:
            d.origin_location_name +
            " - " +
            d.destination_location_name +
            " (" +
            d.price +
            " " +
            d.unit_name +
            ")",
          origin_location_id: d.origin_location_id,
          destination_location_id: d.destination_location_id,
          origin_location_name: d.origin_location_name,
          destination_location_name: d.destination_location_name,
          price: d.price,
          price_unit_id: d.price_unit_id,
          vehicle_type_id: d.vehicle_type_id,
          vehicle_type_img: d.vehicle_type_img,
        };
      });
    }

    if (type === "vehicle_type") {
      return data.map((type) => {
        return {
          id: type.vehicle_types_id,
          name: type.vehicle_types_name_key,
          link_image: type.link_image,
        };
      });
    }
  };

  componentDidMount() {
    this.loadOriginLocation("");
    this.loadDestinationLocation("");
    this.loadWorksheetMasterData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type) {
      this.inputElement.click();
    }

    if (this.state.worksheet_id !== this.props.data.id) {
      this.setState({
        worksheet_id: this.props.data.id,
        document_number: this.props.data.document_number,
        origin_location_id: this.props.data.origin_location_id,
        origin_location_name: this.props.data.origin_location,
        contactdestination: this.props.data.destinationlocationLists,
        fileList: [],
        vehicle_type_img: this.props.data.vehicle_type_img,
      });
    }
  }

  selectOriginLocation = (value, option, setFieldValue) => {
    let id = value,
      name = option.props.children;

    if (parseInt(value) > 0) {
      setFieldValue("origin_location_id", value);
      setFieldValue("origin_location_name", option.props.children);

      this.setState({
        origin_location_name: name,
        origin_location_id: id,
      });
    } else if (typeof this.state.originlocationLists !== "undefined") {
      let idx = this.state.originlocationLists.findIndex(
        (x) => x.name === value
      );
      if (idx > -1) {
        let values = this.state.originlocationLists[idx];
        setFieldValue("origin_location_id", values.id);
        setFieldValue("origin_location_name", values.name);
        this.setState({
          origin_location_name: values.name,
          origin_location_id: values.id,
        });
      } else {
        this.setState({ origin_location_id: "" });
      }
    } else {
      this.setState({ origin_location_id: "" });
    }
  };

  //onChangeLocation = () =>

  onSearchOriginLocation = (searchText) => {
    if (searchText != "") {
      this.loadOriginLocation(searchText);
    }
    this.setState({ origin_location_name: searchText });
  };

  addContact = (id) => {
    this.setState({
      contactdestination: [{ id: 0 }, ...this.state.contactdestination],
      add: true,
    });
  };

  editContact = (
    id,
    title_name,
    firstname,
    lastname,
    phone,
    destination_location_name,
    destination_location_id,
    destination_receive_date,
    quantity,
    unit
  ) => {
    if (this.state.add === true) {
      this.setState({
        contactdestination: [
          ...this.state.contactdestination.slice(
            1,
            this.state.contactdestination.length
          ),
        ],
        add: false,
        contact_id: id,
        contact: {
          title_name,
          firstname,
          lastname,
          phone,
          destination_location_name,
          destination_location_id,
          destination_receive_date,
          quantity,
          unit,
        },
      });
    } else {
      this.setState({
        contact_id: id,
        contact: {
          title_name,
          firstname,
          lastname,
          phone,
          destination_location_name,
          destination_location_id,
          destination_receive_date,
          quantity,
          unit,
        },
      });
    }
  };

  deleteContact = (id) => {
    const new_list = this.state.contactdestination.filter((item) => {
      return item.id !== id;
    });

    let self = this;
    new_list.forEach(function(part, index) {
      if (self.state.add === false) {
        new_list[index].id = index + 1;
        new_list[index].row_number = index + 1;
      } else {
        if (new_list[index].id !== 0) {
          new_list[index].id = index;
          new_list[index].row_number = index;
        }
      }
    });

    //console.log("newlist:", new_list);

    this.setState({
      contactdestination: [...new_list],
    });
  };

  cancelContact = () => {
    if (this.state.add === true) {
      this.setState({
        contactdestination: [
          ...this.state.contactdestination.slice(
            1,
            this.state.contactdestination.length
          ),
        ],
        add: false,
        contact: {
          title_name: "mr",
          firstname: "",
          lastname: "",
          phone: "",
          destination_location_name: "",
          destination_location_id: "",
          destination_receive_date: "",
          quantity: "",
          unit: "",
        },
      });
    } else {
      this.setState({
        contact_id: 0,
        contact: {
          title_name: "mr",
          firstname: "",
          lastname: "",
          phone: "",
          destination_location_name: "",
          destination_location_id: "",
          destination_receive_date: "",
          quantity: "",
          unit: "",
        },
      });
    }
  };

  onSubmitDestination = (values) => {
    if (this.state.contactdestination.length > 6) {
      message.error(
        translate("limit_destination", "worksheet", this.props.lang)
      );
      return false;
    }

    const {
      title_name,
      firstname,
      lastname,
      phone,
      destination_location_id,
      destination_receive_date,
      destination_location_name,
      quantity,
      unit,
    } = values;

    if (this.state.contact_id !== 0) {
      let new_contactdestination = this.state.contactdestination;
      const index_edit = this.state.contactdestination.findIndex(
        (item) => item.id === this.state.contact_id
      );
      new_contactdestination[index_edit] = {
        id: this.state.contact_id,
        row_number: new_contactdestination[index_edit].row_number,
        title_name: title_name,
        firstname: firstname,
        lastname: lastname,
        phone: phone,
        destination_location_id: destination_location_id,
        destination_location_name: destination_location_name,
        destination_receive_date: destination_receive_date,
        quantity,
        unit,
      };

      this.setState({
        contact_id: 0,
        contact: {
          title_name: "mr",
          firstname: "",
          lastname: "",
          phone: "",
          destination_location_name: "",
          destination_location_id: "",
          destination_receive_date: "",
          quantity: "",
          unit: "",
        },
        contactdestination: new_contactdestination,
      });
    } else {
      let id =
        this.props.mode === "edit"
          ? `new_${this.state.contactdestination.length - 1 + 1}`
          : this.state.contactdestination.length - 1 + 1;
      this.setState({
        contact_id: 0,
        contact: {
          title_name: "mr",
          firstname: "",
          lastname: "",
          phone: "",
          destination_location_name: "",
          destination_location_id: "",
          destination_receive_date: "",
          quantity: "",
          unit: "",
        },
        contactdestination: [
          ...this.state.contactdestination,
          {
            id: id,
            row_number: this.state.contactdestination.length - 1 + 1,
            title_name: title_name,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            destination_location_id: destination_location_id,
            destination_location_name: destination_location_name,
            destination_receive_date: destination_receive_date,
            quantity,
            unit,
          },
        ],
      });
    }
  };

  onSubmit = (values) => {
    const company_id = COMPANY_ID;
    const user_id = USER_ID;
    const {
      company_name,
      origin_location_id,
      origin_receive_date,
      product_detail,
      vehicle_type,
      price,
      price_unit_id,
      expense,
      service_payment_type,
      credit_term_id,
      frequency_routine_id,
      contract_term_id,
      title_name,
      firstname,
      lastname,
      phone,
      product_file,
      staff_lift,
      comment,
    } = values;
    let self = this;
    if (this.state.contactdestination.length !== 0) {
      this.setState({ loading: true });
      const formData = new FormData();

      product_file.forEach((File) => {
        //console.log("File:", File.originFileObj);
        formData.append("product_file[]", File.originFileObj);
      });

      formData.append("worksheet_id", this.state.worksheet_id);
      formData.append("document_number", this.state.document_number);
      formData.append("company_id", company_id);
      formData.append("user_id", user_id);
      formData.append("company_name", company_name);
      formData.append("origin_location_id", origin_location_id);
      formData.append("origin_receive_date", origin_receive_date);
      formData.append("product_detail", product_detail);
      formData.append("vehicle_type", vehicle_type);
      formData.append("price", price);
      formData.append("price_unit_id", price_unit_id);
      formData.append("expense", expense);
      formData.append("service_payment_type", service_payment_type);
      formData.append("credit_term_id", credit_term_id);
      formData.append("frequency_routine_id", frequency_routine_id);
      formData.append("contract_term_id", contract_term_id);
      formData.append("title_name", title_name);
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("phone", phone);
      formData.append(
        "contactdestination",
        JSON.stringify(this.state.contactdestination)
      );
      formData.append("worksheet_type", this.props.type);
      formData.append("staff_lift", staff_lift);
      formData.append("comment", comment);

      formData.append("list_logistic_id", []);
      formData.append("transporter_id", "");
      formData.append("direct_type", "");

      let url = this.props.mode === "edit" ? "edit" : "create";

      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/${url}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${ACCESSTOKEN}`,
            },
          }
        )
        .then(function(response) {
          console.log("response:", response);
          if (response.data.status === "fail") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.f.fail_save}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
            self.setState({ loading: false });
          } else {
            if (self.props.mode !== "create") {
              Modal.success({
                content: i18n[self.props.lang]["s"]["success"],
                onOk() {
                  window.location.href = self.props.backUrl;
                },
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else {
              self.setState({
                end_visible: true,
                loading: false,
                direct_visible: false,
              });
            }
          }
        })
        .catch(function(error) {
          // console.log("error:", error);
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false, direct_visible: false });
        });
    } else {
      Modal.error({
        content: (
          <LangContext.Consumer>
            {(i18n) => i18n.worksheet.contact_destination_info_require}
          </LangContext.Consumer>
        ),
        okButtonProps: { "data-cy" : "btn-modal-ok" }
      });
    }
  };

  showModal = (type) => {
    this.setState({
      visible: true,
      button_type: type,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
      button_type: "",
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      button_type: "",
    });
  };

  showDirectModal = (value, setFieldValue, handleSubmit) => {
    setFieldValue("button_type", "direct", false);
    setFieldValue("origin_location_id", this.state.origin_location_id, false);
    setFieldValue(
      "origin_location_name",
      this.state.origin_location_name,
      false
    );
    handleSubmit();
  };

  handleSubmitForm = (value, setFieldValue, handleSubmit) => {
    setFieldValue("button_type", "market", false);
    setFieldValue("origin_location_id", this.state.origin_location_id, false);
    setFieldValue(
      "origin_location_name",
      this.state.origin_location_name,
      false
    );
    console.log("submitform", value);
    handleSubmit();
  };

  handleDirectOk = (values) => {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const company_id = COMPANY_ID;
    const user_id = USER_ID;
    const { list_logistic_id, transporter_id, direct_type } = values;
    const {
      company_name,
      origin_location_id,
      origin_receive_date,
      product_detail,
      vehicle_type,
      price,
      price_unit_id,
      expense,
      service_payment_type,
      credit_term_id,
      frequency_routine_id,
      contract_term_id,
      title_name,
      firstname,
      lastname,
      phone,
      product_file,
      comment,
      staff_lift,
    } = this.state.dataform;
    let self = this;

    if (this.state.contactdestination.length !== 0) {
      this.setState({ loading: true });
      const formData = new FormData();

      product_file.forEach((File) => {
        //console.log("File:", File.originFileObj);
        formData.append("product_file[]", File.originFileObj);
      });

      formData.append("worksheet_id", this.state.worksheet_id);
      formData.append("document_number", this.state.document_number);
      formData.append("company_id", company_id);
      formData.append("user_id", user_id);
      formData.append("company_name", company_name);
      formData.append("origin_location_id", origin_location_id);
      formData.append("origin_receive_date", origin_receive_date);
      formData.append("product_detail", product_detail);
      formData.append("vehicle_type", vehicle_type);
      formData.append("price", price);
      formData.append("price_unit_id", price_unit_id);
      formData.append("expense", expense);
      formData.append("service_payment_type", service_payment_type);
      formData.append("credit_term_id", credit_term_id);
      formData.append("frequency_routine_id", frequency_routine_id);
      formData.append("contract_term_id", contract_term_id);
      formData.append("title_name", title_name);
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("phone", phone);
      formData.append(
        "contactdestination",
        JSON.stringify(this.state.contactdestination)
      );
      formData.append("worksheet_type", this.props.type);
      formData.append("comment", comment);
      formData.append("staff_lift", staff_lift);
      formData.append("list_logistic_id", JSON.stringify(list_logistic_id));
      formData.append("transporter_id", transporter_id);
      formData.append("direct_type", direct_type);

      self.setState({
        direct_visible: false,
      });

      let url = this.props.mode === "edit" ? "edit" : "create";

      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/${url}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${ACCESSTOKEN}`,
            },
          }
        )
        .then(function(response) {
          console.log("response:", response);
          if (response.data.status === "fail") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.f.fail_save}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
            self.setState({ loading: false });
          } else {
            if (self.props.mode !== "create") {
              Modal.success({
                content: i18n[self.props.lang]["s"]["success"],
                onOk() {
                  window.location.href = self.props.backUrl;
                },
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else {
              self.setState({
                end_visible: true,
                loading: false,
                // direct_visible: false,
              });
            }
          }
        })
        .catch(function(error) {
          // console.log("error:", error);
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        });
    } else {
      Modal.error({
        content: (
          <LangContext.Consumer>
            {(i18n) => i18n.worksheet.contact_destination_info_require}
          </LangContext.Consumer>
        ),
        okButtonProps: { "data-cy" : "btn-modal-ok" }
      });
    }
  };

  handleEndOk = (e) => {
    //this.genDocumentNumber();
    this.setState({
      end_visible: false,
    });
  };

  handleEndCancel = (e) => {
    // this.setState({
    //   end_visible: false,
    // });
    window.location.reload();
  };

  handleDirectCancel = (e) => {
    this.setState({
      direct_visible: false,
    });
  };

  addLocation = (id, name) => {
    if (this.state.button_type === "origin") {
      this.loadOriginLocation("");
      this.setState({
        visible: false,
        button_type: "",
        origin_location_id: id,
        origin_location_name: name,
      });
    } else if (this.state.button_type === "destination") {
      this.loadDestinationLocation("");
      this.setState({
        visible: false,
        button_type: "",
        contact: {
          ...this.state.contact,
          destination_location_name: name,
          destination_location_id: id,
        },
      });
    }
  };

  setDestination = (id, name) => {
    this.setState({
      contact: {
        ...this.state.contact,
        destination_location_name: name,
        destination_location_id: id,
      },
    });
  };

  setContactValue = (name, val) => {
    if (typeof name === "object" && typeof val === "object") {
      let items = {};
      name.map((value, i) => {
        items[value] = val[i];
      });

      this.setState({
        contact: {
          ...this.state.contact,
          ...items,
        },
      });
    } else {
      this.setState({
        contact: {
          ...this.state.contact,
          [name]: val,
        },
      });
    }
  };

  onExpenseChange = (value, option, setFieldValue) => {
    setFieldValue("expense", value);
    setFieldValue("origin_location_id", option.props.origin_location_id);
    setFieldValue("origin_location_name", option.props.origin_location_name);
    setFieldValue("price", option.props.price);
    setFieldValue("price_unit_id", option.props.price_unit_id);
    setFieldValue("vehicle_type", option.props.vehicle_type_id);

    if (this.state.add === true) {
      this.setState({
        origin_location_id: option.props.origin_location_id,
        origin_location_name: option.props.origin_location_name,
        vehicle_type_img: option.props.vehicle_type_img,
        contact: {
          destination_location_name: option.props.destination_location_name,
          destination_location_id: option.props.destination_location_id,
          title_name: "mr",
        },
      });
    } else {
      this.setState({
        origin_location_id: option.props.origin_location_id,
        origin_location_name: option.props.origin_location_name,
        contactdestination: [{ id: 0 }, ...this.state.contactdestination],
        vehicle_type_img: option.props.vehicle_type_img,
        add: true,
        contact: {
          ...this.state.contact,
          destination_location_name: option.props.destination_location_name,
          destination_location_id: option.props.destination_location_id,
        },
      });
    }
  };

  handleResetForm = (resetForm) => () => {
    resetForm();
    this.setState(initialState);
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment()
          .add(-1, "days")
          .endOf("day")
    );
  };

  showPreviewImgModal = () => {
    this.setState({ showPreview: true });
  };

  handleClosePreview = () => {
    this.setState({ showPreview: false });
  };

  render() {
    const {
      vehicle_type,
      product_detail,
      credit_term,
      frequency_routine,
      contract_term,
      originlocationLists,
      origin_location_name,
      priceunitLists,
      logisticgroupLists,
      loading,
      expenseLists,
      transporterLists,
    } = this.state;

    const { type, data, disable, backUrl, displayUploadImg } = this.props;

    const originlists =
      typeof originlocationLists !== "undefined" &&
      originlocationLists.map((v) => (
        <OptionAuto key={v.id} lat={v.lat} lng={v.lng}>
          {v.name}
        </OptionAuto>
      ));

    return (
      <Fragment>
        <Directjob
          visible={this.state.direct_visible}
          onOk={this.handleDirectOk}
          loadVehicle={this.loadLogisticgroup}
          logisticgroupLists={logisticgroupLists}
          onCancel={this.handleDirectCancel}
          transporterLists={transporterLists}
        />

        <Modal
          className="test-modal-upload-prd"
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.business.product_file}
            </LangContext.Consumer>
          }
          visible={this.state.showPreview}
          onCancel={this.handleClosePreview}
          width={400}
          footer={[
            <Button
              data-cy="btn-close-modal"
              key="back"
              onClick={this.handleClosePreview}
            >
              <LangContext.Consumer>
                {(i18n) => i18n.c.cancel}
              </LangContext.Consumer>
            </Button>,
          ]}
        >
          {data.path_product !== null && data.path_product !== "" ? (
            getImage(data.path_product).map((val, i) => {
              let img = val.replace(
                "https://terminus-master.sgp1.digitaloceanspaces.com/",
                ""
              );
              return (
                <Row gutter={[24, 24]} style={{ marginBottom: "10px" }}>
                  <Col span={24} key={i}>
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        padding: "20px",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      <img
                        alt="example"
                        src={`https://terminus-master.sgp1.digitaloceanspaces.com/${img}`}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <LangContext.Consumer>
              {(i18n) => i18n.worksheet.not_found_img}
            </LangContext.Consumer>
          )}
        </Modal>

        <Modal
          className="test-modal-success-create"
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.s.success}
            </LangContext.Consumer>
          }
          visible={this.state.end_visible}
          onOk={this.handleEndOk}
          onCancel={this.handleEndCancel}
          width={400}
          okButtonProps={{ "data-cy": "btn-ok-modal" }}
          cancelButtonProps={{ "data-cy": "btn-close-modal" }}
          okText={
            <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
          }
          cancelText={
            <LangContext.Consumer>
              {(i18n) => i18n.c.cancel}
            </LangContext.Consumer>
          }
        >
          <LangContext.Consumer>
            {(i18n) => i18n.worksheet.status_success_continue}
          </LangContext.Consumer>
        </Modal>
        <Modal
          className="test-modal-add-location"
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.a.add_location}
            </LangContext.Consumer>
          }
          destroyOnClose={true}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelButtonProps={{
            style: { display: "none" },
            "data-cy": "btn-modal-cancel",
          }}
          okButtonProps={{
            style: { display: "none" },
            "data-cy": "btn-modal-ok",
          }}
          width={1000}
        >
          <AddFacilityWorksheet
            addLocation={this.addLocation}
          ></AddFacilityWorksheet>
        </Modal>
        <Spin spinning={loading}>
          <Row>
            <LangContext.Consumer>
              {(i18n) => (
                <StyledSearchForm>
                  <Formik
                    enableReinitialize={true}
                    initialValues={data}
                    validate={(values) => {
                      let errors = {};
                      if (type === "routine") {
                        if (values.frequency_routine_id === "") {
                          errors.frequency_routine_id =
                            i18n.worksheet.frequency_routine_id_require;
                        }

                        if (values.contract_term_id === "") {
                          errors.contract_term_id =
                            i18n.worksheet.contract_term_id_require;
                        }
                      }

                      if (/^\d+$/.test(values.phone) === false) {
                        errors.phone = i18n.p.phone_invalid;
                      }

                      if (this.state.origin_location_id === "") {
                        errors.origin_location_id =
                          i18n.expense.origin_location_require;
                      }

                      let keys = [
                        "title_name",
                        "firstname",
                        "lastname",
                        "phone",
                        "destination_location_name",
                        "destination_location_id",
                        "destination_receive_date",
                        "quantity",
                        "unit",
                      ];

                      if (this.state.contactdestination.length === 0) {
                        errors.contactlist =
                          i18n.worksheet.validate_destination;
                      } else {
                        for (
                          let i = 0;
                          i <= this.state.contactdestination.length - 1;
                          i++
                        ) {
                          let list = this.state.contactdestination[i];
                          keys.map((k) => {
                            if (i === 0) {
                              if (
                                typeof list[k] === "undefined" &&
                                this.state.contactdestination.length === 1
                              ) {
                                errors.contactlist =
                                  i18n.worksheet.validate_destination;
                              }

                              if (
                                typeof list[k] !== "undefined" &&
                                list[k] === ""
                              ) {
                                errors.contactlist =
                                  i18n.worksheet.validate_destination;
                              }
                            } else {
                              if (typeof list[k] === "undefined") {
                                errors.contactlist =
                                  i18n.worksheet.validate_destination;
                              } else {
                                if (list[k] === "") {
                                  errors.contactlist =
                                    i18n.worksheet.validate_destination;
                                }
                              }
                            }
                          });
                        }
                      }

                      return errors;
                    }}
                    validationSchema={yup.object().shape({
                      company_name: yup
                        .string()
                        .required(i18n.worksheet.company_name_require),
                      // origin_location_id: yup
                      //   .string()
                      //   .required(i18n.expense.origin_location_require),
                      origin_receive_date: yup
                        .string()
                        .required(i18n.worksheet.origin_receive_date_require),
                      product_detail: yup
                        .string()
                        .required(i18n.worksheet.product_detail_require),
                      vehicle_type: yup
                        .string()
                        .required(i18n.veh.vehicle_type_require),

                      service_payment_type: yup
                        .string()
                        .required(i18n.worksheet.service_payment_type_require),
                      credit_term_id: yup
                        .string()
                        .required(i18n.worksheet.credit_term_id_require),
                      // frequency_routine_id: yup
                      //   .string()
                      //   .required(i18n.worksheet.frequency_routine_id_require),
                      // contract_term_id: yup
                      //   .string()
                      //   .required(i18n.worksheet.contract_term_id_require),

                      firstname: yup
                        .string()
                        .required(i18n.worksheet.firstname_require),
                      lastname: yup
                        .string()
                        .required(i18n.worksheet.lastname_require),
                      phone: yup
                        .string()
                        .required(i18n.worksheet.phone_require)
                        .matches(/^(\d{10})$/, i18n.v.validate_phone),
                      price: yup.string().required(i18n.expense.price_require),
                      price_unit_id: yup
                        .string()
                        .required(i18n.expense.price_unit_require),

                      // destination_location_id: yup
                      //   .string()
                      //   .required(i18n.expense.destination_location_require),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      if (values.button_type === "direct") {
                        this.setState({
                          direct_visible: true,
                          dataform: values,
                        });
                      } else {
                        this.onSubmit(values);
                      }
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      resetForm,
                      setErrors,
                    }) => (
                      <Row>
                        <LangContext.Consumer>
                          {(i18n) => (
                            <Form onSubmit={handleSubmit}>
                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}></Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}></Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  {/* <h2>{i18n.worksheet.document_number}</h2>
                                  <h2>{this.state.document_number}</h2> */}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    required={true}
                                    label={i18n.c.customerName}
                                    validateStatus={
                                      touched.company_name &&
                                      errors.company_name &&
                                      "error"
                                    }
                                    help={
                                      touched.company_name &&
                                      errors.company_name
                                    }
                                  >
                                    <Input
                                      data-cy="company_name"
                                      type="text"
                                      name="company_name"
                                      value={values.company_name}
                                      disabled={disable}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.company_name &&
                                        errors.company_name
                                          ? "invalid"
                                          : undefined
                                      }
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    label={i18n.worksheet.expense}
                                    required={false}
                                    validateStatus={
                                      touched.expense &&
                                      errors.expense &&
                                      "error"
                                    }
                                    help={touched.expense && errors.expense}
                                  >
                                    <Select
                                      data-cy="expense"
                                      disabled={disable}
                                      onChange={(value, option) =>
                                        this.onExpenseChange(
                                          value,
                                          option,
                                          setFieldValue
                                        )
                                      }
                                      value={values.expense}
                                    >
                                      <Option value="">
                                        {i18n.p.pleaseSelect}
                                      </Option>
                                      {expenseLists.map((v) => (
                                        <Option
                                          key={v.id}
                                          value={v.id}
                                          origin_location_id={
                                            v.origin_location_id
                                          }
                                          origin_location_name={
                                            v.origin_location_name
                                          }
                                          destination_location_id={
                                            v.destination_location_id
                                          }
                                          destination_location_name={
                                            v.destination_location_name
                                          }
                                          price_unit_id={v.price_unit_id}
                                          price={v.price}
                                          vehicle_type_id={v.vehicle_type_id}
                                          vehicle_type_img={v.vehicle_type_img}
                                        >
                                          {v.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    label={i18n.expense.origin_location}
                                    required={true}
                                    validateStatus={
                                      touched.origin_location_id &&
                                      errors.origin_location_id &&
                                      "error"
                                    }
                                    help={
                                      touched.origin_location_id &&
                                      errors.origin_location_id
                                    }
                                  >
                                    <InputGroup compact>
                                      <AutoComplete
                                        className="autocomplete-origin"
                                        disabled={disable}
                                        dataSource={originlists}
                                        style={{ width: "83%" }}
                                        optionLabelProp="children"
                                        filterOption={true}
                                        optionFilterProp="children"
                                        value={origin_location_name}
                                        onSelect={(value, option) =>
                                          this.selectOriginLocation(
                                            value,
                                            option,
                                            setFieldValue
                                          )
                                        }
                                        onChange={(value, option) =>
                                          this.selectOriginLocation(
                                            value,
                                            option,
                                            setFieldValue
                                          )
                                        }
                                        onSearch={this.onSearchOriginLocation}
                                      />
                                      {!disable && (
                                        <Button
                                          data-cy="btn-toggle-modal-origin"
                                          icon="plus"
                                          style={{
                                            width: "17%",
                                            padding: "0px 2px 0px 0px",
                                          }}
                                          onClick={() =>
                                            this.showModal("origin")
                                          }
                                        >
                                          <LangContext.Consumer>
                                            {(i18n) => i18n.a.add}
                                          </LangContext.Consumer>
                                        </Button>
                                      )}
                                    </InputGroup>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <Form.Item
                                    required={true}
                                    label={i18n.worksheet.origin_receive_date}
                                    validateStatus={
                                      touched.origin_receive_date &&
                                      errors.origin_receive_date &&
                                      "error"
                                    }
                                    help={
                                      touched.origin_receive_date &&
                                      errors.origin_receive_date
                                    }
                                  >
                                    <DatePicker
                                      data-cy="origin_receive_date"
                                      showTime
                                      disabled={disable}
                                      disabledDate={this.disabledDate}
                                      placeholder={i18n.s.selectDate}
                                      onChange={(value) =>
                                        setFieldValue(
                                          "origin_receive_date",
                                          value
                                        )
                                      }
                                      style={{ width: "100%" }}
                                      value={values.origin_receive_date}
                                      format="DD/MM/YYYY HH:mm"
                                      showTime={{ format: "HH:mm" }}
                                      dateRender={(current) => {
                                        const style = {};
                                        if (current.date() === 1) {
                                          style.border = "1px solid #1890ff";
                                          style.borderRadius = "50%";
                                        }
                                        return (
                                          <div
                                            className="ant-calendar-date"
                                            style={style}
                                          >
                                            {current.date()}
                                          </div>
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    label={i18n.worksheet.product_detail}
                                    required={true}
                                    validateStatus={
                                      touched.product_detail &&
                                      errors.product_detail &&
                                      "error"
                                    }
                                    help={
                                      touched.product_detail &&
                                      errors.product_detail
                                    }
                                  >
                                    <Select
                                      data-cy="product_detail"
                                      disabled={disable}
                                      onChange={(value) =>
                                        setFieldValue("product_detail", value)
                                      }
                                      value={values.product_detail}
                                    >
                                      <Option value="">
                                        {i18n.p.pleaseSelect}
                                      </Option>
                                      {product_detail.map((v) => (
                                        <Option
                                          key={v.id}
                                          value={v.id}
                                          title={i18n.m_product_type[v.name]}
                                        >
                                          {i18n.m_product_type[v.name]}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    required={false}
                                    multiple={true}
                                    label={i18n.business.product_file_limit}
                                    validateStatus={
                                      touched.product_file &&
                                      errors.product_file &&
                                      "error"
                                    }
                                    help={
                                      touched.product_file &&
                                      errors.product_file
                                    }
                                  >
                                    {!displayUploadImg && (
                                      <Upload
                                        className="test-upload-update-product-file"
                                        name="product_file"
                                        disabled={
                                          this.state.worksheet_id !== ""
                                        }
                                        fileList={values.product_file}
                                        beforeUpload={(file, fileList) => {
                                          const isCheckTypeFile =
                                            file.type === "image/jpeg" ||
                                            file.type === "image/png";
                                          if (!isCheckTypeFile) {
                                            message.error(
                                              i18n.worksheet
                                                .upload_image_support
                                            );
                                            return false;
                                          }

                                          const checkSizeFile =
                                            file.size / 1024 <= 5120;
                                          if (!checkSizeFile) {
                                            message.error(
                                              i18n.worksheet.upload_size_support
                                            );
                                            return false;
                                          }
                                          return false;
                                        }}
                                        onChange={({ file, fileList }) => {
                                          const isCheckTypeFile =
                                            file.type === "image/jpeg" ||
                                            file.type === "image/png";

                                          const checkSizeFile =
                                            file.size / 1024 <= 5120;

                                          if (fileList.length > 5) {
                                            message.error(
                                              i18n.worksheet
                                                .upload_amount5_support
                                            );
                                          }

                                          if (
                                            isCheckTypeFile &&
                                            checkSizeFile &&
                                            fileList.length <= 5
                                          ) {
                                            setFieldValue(
                                              "product_file",
                                              fileList
                                            );

                                            this.setState({
                                              fileList: fileList,
                                            });
                                          }
                                        }}
                                        onRemove={(file) => {
                                          let items = this.state.fileList.filter(
                                            (x) => x.uid !== file.uid
                                          );
                                          this.setState({ fileList: items });
                                          setFieldValue("product_file", items);
                                        }}
                                      >
                                        <Button data-cy="btn-upload-prd">
                                          <Icon type="upload" />
                                          {i18n.s.select_file}
                                        </Button>
                                      </Upload>
                                    )}

                                    {displayUploadImg && (
                                      <Button
                                        data-cy="btn-toggle-show-img"
                                        type="primary"
                                        onClick={this.showPreviewImgModal}
                                      >
                                        {i18n.worksheet.preview_img}
                                      </Button>
                                    )}
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    label={i18n.veh.vehicle_type}
                                    required={true}
                                    validateStatus={
                                      touched.vehicle_type &&
                                      errors.vehicle_type &&
                                      "error"
                                    }
                                    help={
                                      touched.vehicle_type &&
                                      errors.vehicle_type
                                    }
                                  >
                                    <Select
                                      data-cy="vehicle_type"
                                      disabled={disable}
                                      onChange={(value, options) => {
                                        this.setState({
                                          vehicle_type_img:
                                            options.props.vehicle_type_img,
                                        });
                                        setFieldValue("vehicle_type", value);
                                      }}
                                      value={values.vehicle_type}
                                    >
                                      <Option value="">
                                        {i18n.p.pleaseSelect}
                                      </Option>
                                      {vehicle_type.map((v) => (
                                        <Option
                                          key={v.id}
                                          value={v.id}
                                          vehicle_type_img={v.link_image}
                                        >
                                          {i18n.vehicle_type[v.name]}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                  {this.state.vehicle_type_img !== "" && (
                                    <Col span={24} align="middle">
                                      <div
                                        style={{
                                          width: "170px",
                                          height: "80px",
                                          margin: "10px 0px 5px 0px",
                                          padding: "10px",
                                        }}
                                      >
                                        <img
                                          alt="example"
                                          src={`/img/vehicletype/${this.state.vehicle_type_img}`}
                                          style={{
                                            maxwidth: "100%",
                                            maxHeight: "100%",
                                          }}
                                        />
                                      </div>
                                      <span>{i18n.j.job_vehicle_pic}</span>
                                    </Col>
                                  )}
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    required={true}
                                    label={
                                      <LangContext.Consumer>
                                        {(i18n) =>
                                          i18n.worksheet.service_payment_type
                                        }
                                      </LangContext.Consumer>
                                    }
                                    validateStatus={
                                      touched.service_payment_type &&
                                      errors.service_payment_type &&
                                      "error"
                                    }
                                    help={
                                      touched.service_payment_type &&
                                      errors.service_payment_type
                                    }
                                  >
                                    <Select
                                      data-cy="service_payment_type"
                                      disabled={disable}
                                      onChange={(value) =>
                                        setFieldValue(
                                          "service_payment_type",
                                          value
                                        )
                                      }
                                      value={values.service_payment_type}
                                    >
                                      <Option value="">
                                        {i18n.p.pleaseSelect}
                                      </Option>
                                      <Option value="cash">
                                        {i18n.worksheet.cash}
                                      </Option>
                                      <Option value="credit">
                                        {i18n.worksheet.credit}
                                      </Option>
                                    </Select>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    required={true}
                                    label={i18n.expense.price}
                                    validateStatus={
                                      touched.price && errors.price && "error"
                                    }
                                    help={touched.price && errors.price}
                                  >
                                    <InputNumber
                                      data-cy="price"
                                      disabled={disable}
                                      style={{ width: "100%" }}
                                      value={values.price}
                                      min={0}
                                      max={9999999}
                                      step={0.01}
                                      onChange={(value) => {
                                        if (value !== null) {
                                          if (
                                            value.toString().match(/\d+/g) ||
                                            value === ""
                                          ) {
                                            setFieldValue("price", value);
                                          }
                                        }
                                      }}
                                    />
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    required={true}
                                    label={i18n.expense.price_unit}
                                    validateStatus={
                                      touched.price_unit_id &&
                                      errors.price_unit_id &&
                                      "error"
                                    }
                                    help={
                                      touched.price_unit_id &&
                                      errors.price_unit_id
                                    }
                                  >
                                    <Select
                                      data-cy="price_unit_id"
                                      disabled={disable}
                                      onChange={(value) =>
                                        setFieldValue("price_unit_id", value)
                                      }
                                      value={values.price_unit_id}
                                    >
                                      <Option value="">
                                        {i18n.p.pleaseSelect}
                                      </Option>
                                      {priceunitLists.map((v) => (
                                        <Option key={v.id} value={v.id}>
                                          {i18n.m_unit_price[v.name]}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    label={i18n.worksheet.credit_term}
                                    required={true}
                                    validateStatus={
                                      touched.credit_term_id &&
                                      errors.credit_term_id &&
                                      "error"
                                    }
                                    help={
                                      touched.credit_term_id &&
                                      errors.credit_term_id
                                    }
                                  >
                                    <Select
                                      data-cy="credit_term_id"
                                      disabled={disable}
                                      onChange={(value) =>
                                        setFieldValue("credit_term_id", value)
                                      }
                                      value={values.credit_term_id}
                                    >
                                      <Option value="">
                                        {i18n.p.pleaseSelect}
                                      </Option>
                                      {credit_term.map((v) => (
                                        <Option key={v.id} value={v.id}>
                                          {i18n.m_credit_term[v.name]}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                </Col>

                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    label={i18n.worksheet.staff_lift}
                                    validateStatus={
                                      touched.staff_lift &&
                                      errors.staff_lift &&
                                      "error"
                                    }
                                    help={
                                      touched.staff_lift && errors.staff_lift
                                    }
                                  >
                                    <Checkbox
                                      data-cy="staff_lift"
                                      disabled={disable}
                                      checked={values.staff_lift}
                                      onChange={(value) =>
                                        setFieldValue(
                                          "staff_lift",
                                          value.target.checked
                                        )
                                      }
                                    >
                                      {i18n.worksheet.have}
                                    </Checkbox>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    //required="true"
                                    label={i18n.c.comment}
                                    validateStatus={
                                      touched.comment &&
                                      errors.comment &&
                                      "error"
                                    }
                                    help={touched.comment && errors.comment}
                                  >
                                    <TextArea
                                      data-cy="comment"
                                      disabled={disable}
                                      name="comment"
                                      rows={3}
                                      onChange={(value) =>
                                        setFieldValue(
                                          "comment",
                                          value.target.value
                                        )
                                      }
                                      value={values.comment}
                                    />
                                  </FormItem>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  {type === "routine" ? (
                                    <FormItem
                                      label={i18n.worksheet.frequency_routine}
                                      required={true}
                                      validateStatus={
                                        touched.frequency_routine_id &&
                                        errors.frequency_routine_id &&
                                        "error"
                                      }
                                      help={
                                        touched.frequency_routine_id &&
                                        errors.frequency_routine_id
                                      }
                                    >
                                      <Select
                                        data-cy="frequency_routine_id"
                                        disabled={disable}
                                        onChange={(value) =>
                                          setFieldValue(
                                            "frequency_routine_id",
                                            value
                                          )
                                        }
                                        value={values.frequency_routine_id}
                                      >
                                        <Option value="">
                                          {i18n.p.pleaseSelect}
                                        </Option>
                                        {frequency_routine.map((v) => (
                                          <Option key={v.id} value={v.id}>
                                            {i18n.m_frequency_routine[v.name]}
                                          </Option>
                                        ))}
                                      </Select>
                                    </FormItem>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  {type === "routine" ? (
                                    <FormItem
                                      label={i18n.worksheet.contract_term}
                                      required={true}
                                      validateStatus={
                                        touched.contract_term_id &&
                                        errors.contract_term_id &&
                                        "error"
                                      }
                                      help={
                                        touched.contract_term_id &&
                                        errors.contract_term_id
                                      }
                                    >
                                      <Select
                                        data-cy="contract_term_id"
                                        disabled={disable}
                                        onChange={(value) =>
                                          setFieldValue(
                                            "contract_term_id",
                                            value
                                          )
                                        }
                                        value={values.contract_term_id}
                                      >
                                        <Option value="">
                                          {i18n.p.pleaseSelect}
                                        </Option>
                                        {contract_term.map((v) => (
                                          <Option key={v.id} value={v.id}>
                                            {i18n.m_contract_term[v.name]}
                                          </Option>
                                        ))}
                                      </Select>
                                    </FormItem>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>

                              <DividerStyled />
                              <Row gutter={[8, 24]}>
                                <Col span={24}>
                                  <TitleText>
                                    {i18n.worksheet.contact_origin_info}
                                  </TitleText>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <FormItem
                                    required={true}
                                    label={i18n.f.firstName}
                                    validateStatus={
                                      touched.firstname &&
                                      errors.firstname &&
                                      "error"
                                    }
                                    help={touched.firstname && errors.firstname}
                                  >
                                    <InputGroup compact>
                                      <Select
                                        data-cy="title_name"
                                        disabled={disable}
                                        style={{ width: "30%" }}
                                        onChange={(value) =>
                                          setFieldValue("title_name", value)
                                        }
                                        value={values.title_name}
                                      >
                                        <Option value="mr">{i18n.m.mr}</Option>
                                        <Option value="mrs">
                                          {i18n.m.mrs}
                                        </Option>
                                        <Option value="miss">
                                          {i18n.m.miss}
                                        </Option>
                                      </Select>
                                      <Input
                                        data-cy="firstname"
                                        disabled={disable}
                                        style={{ width: "70%" }}
                                        type="text"
                                        name="firstname"
                                        value={values.firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={
                                          touched.firstname && errors.firstname
                                            ? "invalid"
                                            : undefined
                                        }
                                      />
                                    </InputGroup>
                                  </FormItem>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <Form.Item
                                    label={i18n.l.lastName}
                                    required={true}
                                    validateStatus={
                                      touched.lastname &&
                                      errors.lastname &&
                                      "error"
                                    }
                                    help={touched.lastname && errors.lastname}
                                  >
                                    <Input
                                      data-cy="lastname"
                                      disabled={disable}
                                      type="text"
                                      name="lastname"
                                      value={values.lastname}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.lastname && errors.lastname
                                          ? "invalid"
                                          : undefined
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                  <Form.Item
                                    required={true}
                                    label={i18n.p.phone}
                                    validateStatus={
                                      touched.phone && errors.phone && "error"
                                    }
                                    help={touched.phone && errors.phone}
                                  >
                                    <Input
                                      data-cy="phone"
                                      disabled={disable}
                                      type="text"
                                      name="phone"
                                      value={values.phone}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.phone && errors.phone
                                          ? "invalid"
                                          : undefined
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <DividerStyled />
                              <Row gutter={[8, 24]}>
                                <Col span={24}>
                                  <TitleText>
                                    {i18n.worksheet.contact_destination_info}
                                  </TitleText>
                                </Col>
                              </Row>

                              <Row gutter={[8, 16]}>
                                <Col span={8}>
                                  *{translate("destination_info", "worksheet")}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Spin spinning={this.state.contactloading}>
                                    <div
                                      style={{
                                        border: "2px solid #BEC2C2",
                                        padding: "10px 10px 10px 10px",
                                      }}
                                    >
                                      <List
                                        itemLayout="horizontal"
                                        dataSource={
                                          this.state.contactdestination
                                        }
                                        header={
                                          <Row>
                                            <Col
                                              xs={24}
                                              sm={4}
                                              md={4}
                                              lg={4}
                                              xl={4}
                                            >
                                              {this.state.add === false &&
                                              this.state.contact_id === 0 ? (
                                                <Fragment>
                                                  {/* <span style={{ color: "red" }}>* </span> */}
                                                  {!disable && (
                                                    <Button
                                                      data-cy="btn-add-contact"
                                                      type="primary"
                                                      onClick={() =>
                                                        this.addContact()
                                                      }
                                                    >
                                                      <span>* </span>&nbsp;
                                                      <LangContext.Consumer>
                                                        {(i18n) =>
                                                          i18n.a.add_destination
                                                        }
                                                      </LangContext.Consumer>
                                                    </Button>
                                                  )}
                                                </Fragment>
                                              ) : (
                                                ""
                                              )}
                                            </Col>
                                          </Row>
                                        }
                                        renderItem={(item) => (
                                          <List.Item>
                                            {item.id === 0 ||
                                            item.id ===
                                              this.state.contact_id ? (
                                              <Contactform
                                                contact={this.state.contact}
                                                setContactValue={
                                                  this.setContactValue
                                                }
                                                cancelContact={
                                                  this.cancelContact
                                                }
                                                onSubmit={
                                                  this.onSubmitDestination
                                                }
                                                showModal={this.showModal}
                                                loadDestinationLocation={
                                                  this.loadDestinationLocation
                                                }
                                                destinationlocationLists={
                                                  this.state
                                                    .destinationlocationLists
                                                }
                                                setDestination={
                                                  this.setDestination
                                                }
                                                setErrors={setErrors}
                                                formErrors={errors}
                                              />
                                            ) : (
                                              <Fragment>
                                                <Descriptions
                                                  title={
                                                    item.row_number +
                                                    ". " +
                                                    item.destination_location_name +
                                                    " (" +
                                                    item.quantity +
                                                    " " +
                                                    item.unit +
                                                    ")"
                                                  }
                                                  column={3}
                                                >
                                                  <Descriptions.Item
                                                    label={
                                                      <LangContext.Consumer>
                                                        {(i18n) => i18n.n.name}
                                                      </LangContext.Consumer>
                                                    }
                                                  >
                                                    <LangContext.Consumer>
                                                      {(i18n) =>
                                                        i18n.m[
                                                          item.title_name
                                                        ] +
                                                        item.firstname +
                                                        " " +
                                                        item.lastname
                                                      }
                                                    </LangContext.Consumer>
                                                  </Descriptions.Item>
                                                  <Descriptions.Item
                                                    label={
                                                      <LangContext.Consumer>
                                                        {(i18n) => i18n.p.phone}
                                                      </LangContext.Consumer>
                                                    }
                                                  >
                                                    {item.phone}
                                                  </Descriptions.Item>

                                                  <Descriptions.Item>
                                                    <LangContext.Consumer>
                                                      {(i18n) => (
                                                        <Tooltip
                                                          placement="left"
                                                          title={i18n.e.edit}
                                                          arrowPointAtCenter
                                                          style={{
                                                            padding:
                                                              "0px 1px 0px 0px",
                                                            marginLeft: "5px",
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          <a
                                                            data-cy={`btn-edit-contact-${item.id}`}
                                                            disabled={disable}
                                                            onClick={() =>
                                                              this.editContact(
                                                                item.id,
                                                                item.title_name,
                                                                item.firstname,
                                                                item.lastname,
                                                                item.phone,
                                                                item.destination_location_name,
                                                                item.destination_location_id,
                                                                item.destination_receive_date,
                                                                item.quantity,
                                                                item.unit
                                                              )
                                                            }
                                                            style={{
                                                              padding:
                                                                "0px 5px 0px 5px",
                                                            }}
                                                          >
                                                            <Icon type="edit" />
                                                          </a>
                                                        </Tooltip>
                                                      )}
                                                    </LangContext.Consumer>
                                                    <LangContext.Consumer>
                                                      {(i18n) => (
                                                        <Tooltip
                                                          placement="left"
                                                          title={i18n.d.delete}
                                                          arrowPointAtCenter
                                                          style={{
                                                            padding:
                                                              "0px 1px 0px 0px",
                                                            marginLeft: "5px",
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          <Popconfirm
                                                            title={
                                                              i18n.c
                                                                .confirmToDeleteThisItem
                                                            }
                                                            onConfirm={() => {
                                                              let self = this;
                                                              self.deleteContact(
                                                                item.id
                                                              );
                                                            }}
                                                            cancelText={
                                                              i18n.c.cancel
                                                            }
                                                            okText={i18n.o.ok}
                                                          >
                                                            <a
                                                              data-cy={`btn-del-contact-${item.id}`}
                                                              disabled={disable}
                                                              href="javascript:;"
                                                              style={{
                                                                padding:
                                                                  "0px 5px 0px 5px",
                                                              }}
                                                            >
                                                              <Icon type="delete" />
                                                            </a>
                                                          </Popconfirm>
                                                        </Tooltip>
                                                      )}
                                                    </LangContext.Consumer>
                                                  </Descriptions.Item>
                                                </Descriptions>
                                              </Fragment>
                                            )}
                                          </List.Item>
                                        )}
                                      />
                                    </div>
                                    {errors.contactlist && (
                                      <span style={{ color: "red" }}>
                                        {errors.contactlist}
                                      </span>
                                    )}
                                  </Spin>
                                </Col>
                              </Row>

                              <Row
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "5px",
                                }}
                              >
                                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                  <Form.Item>
                                    <Button
                                      data-cy="btn-save"
                                      type="defualt"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    >
                                      {this.state.worksheet_id === "" ? (
                                        <Link
                                          to={window.location.pathname}
                                          onClick={() =>
                                            window.location.reload()
                                          }
                                        >
                                          {i18n.c.cancel}
                                        </Link>
                                      ) : (
                                        <Link to={`${backUrl}`}>
                                          {disable === true
                                            ? i18n.b.back
                                            : i18n.c.cancel}
                                        </Link>
                                      )}
                                    </Button>

                                    {!disable && (
                                      <Button
                                        data-cy="btn-market"
                                        type="primary"
                                        onClick={(value) =>
                                          this.handleSubmitForm(
                                            value,
                                            setFieldValue,
                                            handleSubmit
                                          )
                                        }
                                        // htmlType="submit"
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        {i18n.worksheet.market}
                                      </Button>
                                    )}
                                    {!disable && (
                                      <Button
                                        data-cy="btn-direct"
                                        type="primary"
                                        onClick={(value) =>
                                          this.showDirectModal(
                                            value,
                                            setFieldValue,
                                            handleSubmit
                                          )
                                        }
                                        //onClick={this.showDirectModal}
                                      >
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.worksheet.direct}
                                        </LangContext.Consumer>
                                      </Button>
                                    )}
                                  </Form.Item>
                                  {!disable && (
                                    <Button
                                      data-cy="btn-reset"
                                      style={{ display: "none" }}
                                      disabled={disable}
                                      onClick={this.handleResetForm(resetForm)}
                                    >
                                      <input
                                        data-cy=""
                                        ref={(input) =>
                                          (this.inputElement = input)
                                        }
                                      />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </LangContext.Consumer>
                      </Row>
                    )}
                  />
                </StyledSearchForm>
              )}
            </LangContext.Consumer>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LangContext, { i18n } from "modules/shared/context/langContext";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import axios from "axios";
import { checkPermissions } from "../../../../lib/helper";
import {
  Button,
  Table,
  Pagination,
  Spin,
  Row,
  Col,
  Icon,
  Popconfirm,
  Tooltip,
  Badge,
  Divider
} from "antd";

import {
  loadLogisticGroup,
  createLogisticGroup,
  removeLists,
} from "../actions";

import Breadcrumb from "../../../shared/components/Breadcrumb";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import IndexFilterDrawer from "./IndexFilterDrawer";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false,
      expand: false,
      searchName: "",
      currentPage: 1,
      page: 1,
      pageSize: 10,
      visible: false,
      orderBy: "logistic_groups.id",
      orderType: "desc",
      companyID: "",
      isFilter: false,
      searchText: "",
      company: [],
      logisticgroupname: "",
      selected: [],
      unselected: [],
      i18n: i18n.en,
      langBtn: "TH",
      filterObj: {
        filterObjLogisticGroupName: "",
        // filterObjLogisticDetail: "",
      },
    };
    this.name = [
      <LangContext.Consumer>{(i18n) => i18n.n.no}</LangContext.Consumer>,
    ];
    this.columns = [
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.n.no}</LangContext.Consumer>
        ),
        dataIndex: "rowNumber",
        key: "rownumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.l.logisticLogo}
          </LangContext.Consumer>
        ),
        dataIndex: "path_logo",
        key: "path_logo",
        sorter: false,
        // width: 230,
        // sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <img src={`${record.path_logo}`} width="45" height="45" />
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.l.logisticGroup}
          </LangContext.Consumer>
        ),
        dataIndex: "groups_name",
        key: "groups_name",
        sorter: true,
        // width: 230,
        // sorter: true,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.d.detail}</LangContext.Consumer>
        ),
        dataIndex: "detail",
        key: "detail",
        // width: 230,
        // className: "column-money",
        render(text, record, index) {
          console.log("Index -> render -> text", text)
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: 90,
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "logisticgroup",
                "can_view"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.detail}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                >
                  <Link
                    data-cy={`btn-view-${record.logistic_groups_id}`}
                    to={`/logisticgroup/detail/${record.logistic_groups_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="file-text" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "logisticgroup",
                "can_edit"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                >
                  <Link
                    data-cy={`btn-edit-${record.logistic_groups_id}`}
                    to={`/logisticgroup/edit/${record.logistic_groups_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "logisticgroup",
                "can_delete"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.delete}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                >
                  <Popconfirm
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                      </LangContext.Consumer>
                    }
                    onConfirm={() => {
                      let self = this;
                      self.deleteLogisticGroup(
                        record.logistic_groups_id,
                        this.props.auth.accessToken
                      );
                    }}
                  >
                    <a
                      data-cy={`btn-del-${record.logistic_groups_id}`}
                      href="javascript:;"
                      style={{ padding: "0px 5px 0px 5px" }}
                    >
                      <Icon type="delete" />
                    </a>
                  </Popconfirm>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
  }

  // Modal Add Vehicle Group

  state = {
    ModalText: "Content of the modal",
    visible: false,
    confirmLoading: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: "เพิ่มกลุ่มขนส่ง",
      confirmLoading: true,
    });

    this.createVeticle(true);

    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });

      this.loadData(true);
    }, 1200);
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  deleteLogisticGroup = (id, accessToken) => {
    let self = this;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deleteLogisticGroup/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(function(response) {
        self.loadData(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  // End Modal Add Vehicle Group
  createLogistic = () => {
    this.props.createLogisticGroup({
      companyID: this.props.auth.profile.company_id,
      logisticgroupname: this.state.logisticgroupname,
      selected: this.state.selected,
      // mockData: this.state.mockData,
      accessToken: this.props.auth.accessToken,
    });
    this.componentDidMount();
  };
  //   Transfer
  getMock = (logisticgroupname) => {
    for (let i = 0; i < logisticgroupname.length; i++) {
      const data = {
        key: logisticgroupname[i].key,
        title: logisticgroupname[i].plate_no,
      };

      if (data.chosen) {
        this.state.selected.push(data.key);
      }
      this.state.unselected.push(data);
    }

    // this.setState({ unselected, selected });
  };

  // filterOption = (inputValue, option) =>
  //   option.description.indexOf(inputValue) > -1;

  handleChangeTransfer = (selected) => {
    this.setState({ selected });
  };

  handleSearchTransfer = (dir, value) => {
    console.log("search:", dir, value);
  };

  // End Transfer

  componentDidMount() {
    this.loadData(true);
    let self = this;
    let id = this.props.auth.profile.company_id;

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/logisticgroup`,
        {
          id,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let logisticgroupname = response.data.data.map((logistic) => {
            return { key: logistic.company_id, name: logistic.company_name };
          });

          self.getMock(logisticgroupname);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";
    // console.log(sorter.columnKey)

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "logistic_groups_id",
        orderType: "asc",
      });
      orderBy = "logistic_groups_id";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadLogisticGroup(
      {
        companyID: this.props.auth.profile.company_id,
        searchName: this.state.searchName,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        orderBy: orderBy,
        orderType: orderType,

        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  loadData = (loading) => {
    this.props.loadLogisticGroup(
      {
        companyID: this.props.auth.profile.company_id,
        searchName: this.state.searchName,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,

        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      loading
    );
  };

  handleSearch = (value) => {
    if (value != this.state.searchName) {
      this.props.loadLogisticGroup(
        {
          companyID: this.props.auth.profile.company_id,
          searchName: value,
          page: this.state.currentPage,
          pageSize: this.state.pageSize,
          orderBy: this.state.orderBy,
          orderType: this.state.orderType,

          accessToken: this.props.auth.accessToken,
          filterObj: this.state.filterObj,
        },
        true
      );
      this.setState({
        searchName: value,
        currentPage: 1,
      });
    }
  };

  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadLogisticGroup(
      {
        companyID: this.props.auth.profile.company_id,
        searchName: this.state.searchName,
        page: page,
        pageSize: pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,

        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  handleFilterObjLogisticGroup = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        filterObjLogisticGroupName: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  hideModel = () => {
    this.setState({
      visible: false,
    });
  };

  showModel = () => {
    this.setState({
      visible: true,
    });
  };

  handleLogisticGroupName(event) {
    this.setState({
      logisticgroupname: event.target.value,
    });
  }

  // Filter
  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };
  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  onFilterButtonClick = (e) => {
    this.loadData(true);
  };

  handleFilterVehicleGroup = (e) => {
    this.setState({
      searchName: e.target.value,
    });
  };

  // Filter
  render() {
    const { total, loading } = this.props.dataLogisticGroup;
    const columns = this.columns.map((col) => col);

    const { visible, confirmLoading, ModalText } = this.state;

    return (
      <AuthorizeComponent matching_name="logisticgroup">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
              <Col span={24}>
                <Divider orientation="left">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.logisticGroup}
                    </LangContext.Consumer>
                </Divider>
              </Col>
            </Row>
          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={18}>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "logisticgroup",
                "can_add"
              ) == true ? (
                <Link to="/logisticgroup/create">
                  <Button type="primary" icon="plus" size="small" data-cy="btn-create">
                    <span></span>
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.new}
                    </LangContext.Consumer>
                  </Button>
                </Link>
              ) : (
                ""
              )}
            </Col>
            <Col span={4} />
            <Col span={2}>
              <Badge dot={this.state.isFilter} align="right">
                <Button
                  data-cy="btn-filter"
                  icon="filter"
                  size="small"
                  align="right"
                  onClick={this.showFilterDrawer}
                >
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Badge>

              <IndexFilterDrawer
                onClose={this.onFilterDrawerClose}
                visible={this.state.filterVisible}
                onFilterButtonClick={this.onFilterButtonClick}
                handleFilterLogisticGroup={this.handleFilterObjLogisticGroup}
                // handleFilterObjNumber={this.handleFilterObjNumber}
              />
            </Col>
          </Row>
          <Row>
            <Spin spinning={loading}>
              <Table
                data-cy="data-table"
                bordered
                rowKey="logistic_groups_id"
                columns={columns}
                dataSource={this.props.dataLogisticGroup.lists}
                // size="small"
                pagination={false}
                onChange={this.handleTableChange}
              />
            </Spin>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col pan={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={total}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ dataLogisticGroup, auth }) => ({ dataLogisticGroup, auth }),
  {
    loadLogisticGroup: loadLogisticGroup.request,
    createLogisticGroup: createLogisticGroup.request,
    removeLists,
  }
)(Index);

import {
  LOAD_VEHICLES_VERIFY,
  LOAD_DETAILS_VEHICLES_VERIFY,
  VERIFY_VEHICLE,
} from "./actions";

const initialState = {
  lists: [],
  total: 1,
  loading: false,
  draw: -1,
  loadingvehicle: false,
  check1: true,
  detailsVehicleVerify: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VEHICLES_VERIFY.REQUEST:
      const { loading } = action.payload.data;
      return {
        ...state,
        loading: loading,
      };

    case LOAD_VEHICLES_VERIFY.SUCCESS:
      const { page } = action.payload;

      for (let i = 0; i < action.payload.lists.data.length; i++) {
        action.payload.lists.data[i].rowNumber = i + 1;
        if (page > 1)
          action.payload.lists.data[i].rowNumber =
            action.payload.lists.data[i].rowNumber + (page - 1) * 10;
      }
      return {
        ...state,
        lists: action.payload.lists.data,
        total:
          action.payload.lists.total - 1 <= 0
            ? 1
            : action.payload.lists.total <= 0
            ? 1
            : action.payload.lists.total,
        loading: false,
        draw: Math.floor(Math.random() * 999 + 1),
      };

      case LOAD_DETAILS_VEHICLES_VERIFY.REQUEST:
        return {
          ...state,
          loadingvehicle: action.payload.loading,
        };
  
      case LOAD_DETAILS_VEHICLES_VERIFY.SUCCESS:
        console.log("success");
        return {
          ...state,
          detailsVehicleVerify: action.payload.listsVehicle.data[0],
          loadingvehicle: false,
          check1: false,
        };

    case VERIFY_VEHICLE.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router-dom";
import {
  Divider,
  Row,
  Col,
  Table,
  Button,
  Spin,
  Pagination,
  Rate,
  Badge,
  message, 
  Icon,
  Tooltip
} from "antd";

import { i18n } from "modules/shared/context/langContext";
import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import IndexFilterDrawer from "./IndexFilterDrawer";
import AddGroupModel from "./AddGroupModel"

import axios from "axios";
import "../../../../../../styled/transport-dashboard.css";
import { translate, checkEmptyJson } from "../../../../../../lib/helper";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "n",
    keyLang: "no",
    dataKey: "#",
    sorter: true,
    render: "no",
    width: "5%",
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "company",
    dataKey: "name",
    sorter: true,
    render: "company",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "a",
    keyLang: "address",
    dataKey: "address",
    sorter: false,
    render: "address",
    width: "18%",
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "contact",
    dataKey: "destination",
    sorter: false,
    render: "contact",
    width: "33%",
    cls: "vertical-top",
  },
  {
    idxLang: "r",
    keyLang: "rateServiceQuality",
    dataKey: "rating",
    sorter: false,
    render: "rating",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "a",
    keyLang: "action",
    dataKey: "company_id",
    sorter: false,
    render: "action",
    width: "9%",
    cls: "vertical-top",
  },
];

const renderRating = (val) => {
  let rate = val
  if(rate % 1 >= 0.5) {
    rate = Math.floor(val) + 0.5
  } 
  return (
    <Fragment>
      <div className="algin-center">
        <Rate disabled allowHalf defaultValue={rate} />
        <br />({val})
      </div>
    </Fragment>
  );
};

const renderNormalTxt = (val) => <div>{val}</div>;

export class Layout extends Component {
  state = {
    isFilter: false,
    filterVisible: false,
    expand: false,
    page: 1,
    currentPage: 1,
    pageSize: 10,
    orderBy: "companies.id",
    orderType: "desc",
    province: [],
    vehicleType: [],
    openGroup: false,
    addCompanyId: null,
    filters: {
      company_name: "",
      vehicle_type: "",
      area_1: "",
      area_2: "",
      rating: ""
    },
  };

  renderCompanyDetail = (val) => {
    return (
      <Fragment>
        {typeof val.contact !== "undefined" &&
          val.contact.map((v) => {
            return (
              <Fragment>
                { v.firstname !== "" && 
                <p>
                  {translate("name", "n", this.props.langBtn)} :{" "}
                  {translate(v.title_name, "m")} { v.firstname + " " + v.lastname}
                </p>
                }
                <p>
                  {translate("phone", "p", this.props.langBtn)} : {v.phone}, {translate("email", "e")} :{" "}
                  {v.email}
                </p>
              </Fragment>
            );
          })}
      </Fragment>
    );
  };

  genTableHeader = () => {
    const columns = [];
    tableHeader.map((v) => {
      columns.push({
        title: translate(v.keyLang, v.idxLang),
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderComponent(v.render, record, v.dataKey, index),
          };
        },
      });
    });
  
    return columns;
  };

  renderAddrTxt = (val) => {
    return <div>{this.props.langBtn == "th" ? val.address_th : val.address_en}</div>
  };
  
  
  renderComponent = (type, val, key, index) => {
    switch (type) {
      case "no":
        return index + 1;
      case "rating":
        return renderRating(val[key]);
      case "action":
        return this.renderActionbtn(val.id);
      case "contact":
        return this.renderCompanyDetail(val);
      case "address" :
        return this.renderAddrTxt(val)
      default:
        return renderNormalTxt(val[key]);
    }
  };

  addToLogisticGroup = (id) => () => {
    this.setState({openGroup: true, addCompanyId: id })
  }
  
  renderActionbtn = (val) => {
    return (
      <Fragment>
        <Tooltip
          placement="left"
          title={translate("description", "d", this.props.langBtn)}
          arrowPointAtCenter
          style={{
            padding: "0px 1px 0px 0px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          <Link 
          data-cy={`btn-view-${val}`}
          to={`/productowner/addcompanygroup/view/${val}`}
          style={{ padding: "0px 5px 0px 5px" }}
          >
            <Icon type="file-text" style={{ fontSize: "15px" }}/>
          </Link>
        </Tooltip>

        <Tooltip
          placement="left"
          title={translate("addGroup", "a", this.props.langBtn)}
          arrowPointAtCenter
          style={{
            padding: "0px 1px 0px 0px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          <Button 
          type="link"
          data-cy={`btn-addgroup-${val}`}
          onClick={this.addToLogisticGroup(val)}
          style={{ padding: "0px 5px 0px 5px" }}
          >
            <Icon type="user-add" style={{ fontSize: "15px" }}/>
          </Button>
        </Tooltip>

        {/* <Button type="primary" onClick={this.addToLogisticGroup(val)}>
          +{" "}
          {translate("addGroup", "a", this.props.langBtn)}
        </Button>
        <br />
        <Link to={`/productowner/addcompanygroup/view/${val}`}>
          <Button className="btn_status">
            {translate("description", "d", this.props.langBtn)}
          </Button>
        </Link> */}
      </Fragment>
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.props.loadTransportCompanies({
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
    });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadTransportCompanies({
      page: page,
      pageSize: pageSize,
    });
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false
    });
  };

  onFilterButtonClick = () => {
    this.loadData()
  };

  filterChange = (name, value) => {
    const { filters } = this.state
    filters[name] = value
    this.setState({
      filters,
      isFilter: !checkEmptyJson(filters) && value !== ""
    })
  };


  loadProvince = () => {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovince`,
        {},
        { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
      )
      .then((res) => {
        this.setState({ province: res.data.data });
      });
  };

  loadVehicleyType = () => {
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicletype`,
        {}
      )
      .then((response) => {
        if (typeof response.data.data !== "undefined") {
          let vehicleType = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name_key,
            };
          });
          this.setState({ vehicleType });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  loadData = () => {
    const { page, pageSize, orderBy, orderType, filters } = this.state;
    this.props.loadTransportCompanies(
      page,
      pageSize,
      orderBy,
      orderType,
      filters
    );
  }
  
  handleAddGroup = (company_id, group_id) => {
    this.props.addTransportCompany(company_id, group_id)
    this.setState({ openGroup: false })
  }

  handleCloseModel = () => {
    this.setState({ openGroup: false })
  }

  componentDidMount() {
    this.loadData()
    this.loadVehicleyType();
    this.loadProvince();
  }

  componentDidUpdate(prevProps, prevState) {
      if (this.props.success === true && prevProps.success !== this.props.success) {
        message.success(i18n[this.props.langBtn].s.success);
      }
  
      if (this.props.success === false) {
        message.error(i18n[this.props.langBtn].f.fail_save);
      }
  }

  render() {
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="company_group_dashboard">
        <GeneralStyledContent>

          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                {translate("md_companygroup", "m")}
              </Divider>
            </Col>
          </Row>

          <Row gutter={24} justify="end" style={{ height: "38px" }}>
            <Col span={2} offset={22}>
              <Badge dot={this.state.isFilter} align="right">
                <Button
                  data-cy="btn-filter"
                  icon="filter"
                  size="small"
                  align="right"
                  onClick={this.showFilterDrawer}
                >
                  <span></span>
                  {translate("filter", "f")}
                </Button>
              </Badge>

              <IndexFilterDrawer
                onClose={this.onFilterDrawerClose}
                visible={this.state.filterVisible}
                onChange={this.filterChange}
                vehicleType={this.state.vehicleType}
                province={this.state.province}
                value={this.state.filters}
                onFilterButtonClick={this.onFilterButtonClick}
                langBtn={this.props.langBtn}
              />
              <AddGroupModel 
                visible={this.state.openGroup}
                addCompanyId={this.state.addCompanyId}
                onOk={this.handleAddGroup}
                onCancel={this.handleCloseModel}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                  expandedRowRender={this.renderExpandable}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ productOwnerCompanygroup, ui: {langBtn} }) => ({
  data: productOwnerCompanygroup.lists,
  totalRows: productOwnerCompanygroup.totalRows,
  loading: productOwnerCompanygroup.loading,
  success: productOwnerCompanygroup.success,
  langBtn,
});

const mapDispatchToProps = {
  loadTransportCompanies: actions.loadTransportCompanies.request,
  addTransportCompany: actions.addTransportCompany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import {
  CREATE_TRANSPORTATION_RATING,
} from "./actions";

const initialState = {
  lists: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRANSPORTATION_RATING.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Alert, Spin, Modal, Button } from "antd";
import { BrowserRouter as Router,Route, Switch } from "react-router-dom";

import {
  StyledLogo,
  StyledDiv,
  StyledSignUp,
  StyleBackgroundImage,
} from "../../../styled/common-styled";

import Signup from "../../../modules/auth/components/Signup";
import Usertype from "../../../modules/auth/components/Usertype";
import Verify from "../../../modules/auth/components/Verify";

class Layoutsignup extends Component {



  render() {
    return (<Router >
            <StyledDiv >
              <Fragment>
                <StyleBackgroundImage/> 
                  {/* <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}/> */}
                  <Col span={24}>
                    <StyledSignUp>
                      <Switch>
                        

                        <Route
                          exact
                          path="/signup/verify/:usertype/:by"
                          component={Verify}
                          {...this.props}
                        />

                       

                        <Route
                          exact
                          path="/signup/:usertype/:by"
                          component={Signup}
                          {...this.props}
                        />

                        <Route exact path="/signup" component={Usertype}  {...this.props}/>
                      </Switch>
                    </StyledSignUp>
                  </Col>
              </Fragment>
            </StyledDiv>
            </Router>
    )
  }
}

export default Layoutsignup;

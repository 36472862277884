import { CALL_API } from "middleware/api";
import history from "lib/history";
import { createTypes, createAction } from "lib/action";

const CHECK_LOGIN = createTypes("login", "check");
const CHECK_LOGIN_NOTIFICATION = createTypes("checkLoginNotification", "check");

const BASE_ACTION = "app/auth";

const LOAD_CREDENTIALS = `${BASE_ACTION}/credentials/load`;
const REMOVE_CREDENTIALS = `${BASE_ACTION}/credentials/remove`;
const CREATE_USER_REQUEST = `${BASE_ACTION}/register/request`;
const CREATE_USER_SUCCESS = `CREATE_USER_SUCCESS`;
const CREATE_USER_FAILURE = `/register/failure`;
const SET_CONFIG_TRACKING_COLUMNS = `${BASE_ACTION}/config/set/trackingcol`;
const SET_CONFIG_HOME_DASHBOARD_WIDGET = `${BASE_ACTION}/config/set/homedashboard/widget/position`;
const SET_LOADING = createTypes("loading", "set");
const LOAD_PROVINCE = createTypes("loadprovince", "load");
const LOAD_AMPHUR = createTypes("loadamphur", "load");
const LOAD_SUBAMPHUR = createTypes("loadsubamphur", "load");
const CHECK_CONTACT_SIGNUP = createTypes("contactsignup", "check");

const loadProvince = {
  request: () => createAction(LOAD_PROVINCE.REQUEST, {}),
  success: (ProvinceLists) =>
    createAction(LOAD_PROVINCE.SUCCESS, { ProvinceLists }),
  failure: () => createAction(LOAD_PROVINCE.FAITLURE),
};

const loadAmphur = {
  request: (data) => createAction(LOAD_AMPHUR.REQUEST, { data }),
  success: (AmphurLists) => createAction(LOAD_AMPHUR.SUCCESS, { AmphurLists }),
  failure: () => createAction(LOAD_AMPHUR.FAITLURE),
};

const loadSubAmphur = {
  request: (data) => createAction(LOAD_SUBAMPHUR.REQUEST, { data }),
  success: (SubAmphurLists) =>
    createAction(LOAD_SUBAMPHUR.SUCCESS, { SubAmphurLists }),
  failure: () => createAction(LOAD_SUBAMPHUR.FAITLURE),
};

const asyncLocalStorage = {
  setItem: function(key, value) {
    return Promise.resolve().then(function() {
      localStorage.setItem(key, value);
    });
  },
  getItem: function(key) {
    return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    });
  },
};

const checkLoginNotification = {
  request: (data) => createAction(CHECK_LOGIN_NOTIFICATION.REQUEST, { data }),
  success: (lists) => createAction(CHECK_LOGIN_NOTIFICATION.SUCCESS, { lists }),
  failure: () => createAction(CHECK_LOGIN_NOTIFICATION.FAITLURE),
};

function loadCredentials() {
  const token = localStorage.getItem("access-token");
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");

  let currentTime = null;
  let tokenExp = null;
  let done = false;
  let msg = "";

  if (JSON.stringify(profile) !== "{}") {
    done = true;
    tokenExp = profile.exp;

    currentTime = Math.floor(Date.now() / 1000);
    if (currentTime > tokenExp) {
      done = false;
      msg = "Token Expired";
    }
  }

  return {
    type: LOAD_CREDENTIALS,
    payload: { token, tokenExp, profile, done, msg },
  };
}

function removeCredentials() {
  console.log("removeCredentials");
  //localStorage.removeItem("access-token");
  localStorage.clear();
  //localStorage.removeItem("profile");
  return {
    type: REMOVE_CREDENTIALS,
  };
}

function storeCredentials(
  token,
  profile,
  permissions,
  vehicle_list,
  location_list,
  vehicle_group_list
) {
  return asyncLocalStorage.setItem("access-token", token).then(function() {
    profile = {
      ...profile,
      tokenExp: profile.exp,
      permissions,
      vehicle_visibility: vehicle_list,
      location_visibility: location_list,
      vehicle_group_visibility: vehicle_group_list,
    };

    return asyncLocalStorage
      .setItem("profile", JSON.stringify(profile))
      .then(function(value) {
        return loadCredentials();
      });
  });
}

function register(credentials) {
  return {
    [CALL_API]: {
      endpoint: "/users",
      method: "POST",
      body: credentials,
      types: [
        CREATE_USER_REQUEST,
        {
          type: CREATE_USER_SUCCESS,
          payload: async (_action, _state, res) => {
            const token = res.headers.get("Authorization");
            const { user: profile } = await res.json();

            storeCredentials(token, profile);
            history.push("/");

            return {
              token,
              profile,
            };
          },
        },
        CREATE_USER_FAILURE,
      ],
    },
  };
}

const checkLogin = {
  request: (credentials, loading) =>
    createAction(CHECK_LOGIN.REQUEST, { credentials, loading }),
  success: (
    token,
    tokenPayload,
    permissions,
    vehicle_list,
    location_list,
    vehicle_group_list
  ) =>
    createAction(CHECK_LOGIN.SUCCESS, {
      token,
      tokenPayload,
      permissions,
      vehicle_list,
      location_list,
      vehicle_group_list,
    }),
  failure: (status, msg, path_signup) =>
    createAction(CHECK_LOGIN.FAILURE, { status, msg, path_signup }),
};

// function changeConfigTrackingColumns(columns) {
//   return {
//     type: SET_CONFIG_TRACKING_COLUMNS,
//     payload: columns
//   };
// }

function changeConfigTrackingColumns(profile, columns) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({
        ...profile.config,
        menu: { tracking: { ...profile.config.menu.tracking, columns } },
      }),
    };

    localStorage.setItem("profile", JSON.stringify(profile));

    return {
      type: SET_CONFIG_TRACKING_COLUMNS,
      payload: columns,
    };
  }
  return {
    type: SET_CONFIG_TRACKING_COLUMNS,
    payload: [],
  };
}

function changeConfigHomeDashboardLayout(layout) {
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: layout,
  };
}

function changeLocalStorageConfigHomeDashboardLayout(profile, layout) {
  const token = localStorage.getItem("access-token");
  if (profile != null && token != null) {
    profile = {
      ...profile,
      permissions: JSON.stringify(profile.permissions),
      config: JSON.stringify({ ...profile.config, home_dashboard: { layout } }),
    };

    localStorage.setItem("profile", JSON.stringify(profile));

    return {
      type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
      payload: layout,
    };
  }
  return {
    type: SET_CONFIG_HOME_DASHBOARD_WIDGET,
    payload: [],
  };
}

const setLoading = (loading) => {
  console.log("action:", loading);
  return {
    type: SET_LOADING,
    payload: { loading },
  };
};

const checkContact = (value) => {
  return {
    type: CHECK_CONTACT_SIGNUP,
    payload: { value },
  };
};

export {
  LOAD_CREDENTIALS,
  REMOVE_CREDENTIALS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  SET_LOADING,
  setLoading,
  loadCredentials,
  storeCredentials,
  removeCredentials,
  register,
  CHECK_LOGIN,
  checkLogin,
  CHECK_LOGIN_NOTIFICATION,
  checkLoginNotification,
  SET_CONFIG_TRACKING_COLUMNS,
  changeConfigTrackingColumns,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  changeConfigHomeDashboardLayout,
  changeLocalStorageConfigHomeDashboardLayout,
  LOAD_PROVINCE,
  loadProvince,
  LOAD_AMPHUR,
  loadAmphur,
  LOAD_SUBAMPHUR,
  loadSubAmphur,
  CHECK_CONTACT_SIGNUP,
  checkContact,
};

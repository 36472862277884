import React from "react";
import {
  Button,
  Form,
  Input,
  Drawer,
} from "antd";
import LangContext from "modules/shared/context/langContext";

const IndexFilterDrawer = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
    }
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "calc(100% - 55px)",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.l.logisticGroup}
          </LangContext.Consumer>
        }
      >
        <Input data-cy="logistic_group" onChange={props.handleFilterLogisticGroup} />
      </Form.Item>

      <Button
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
        data-cy="btn-filter-close"
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>

      <Button data-cy="btn-filter-submit" type="primary" onClick={props.onFilterButtonClick}>
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);
export default IndexFilterDrawer;

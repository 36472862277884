// etc
import watchUIState from "modules/ui/sagas";
import watchAuthState from "modules/auth/sagas";

//admin-system
import watchCompanyVerify from "modules/admin-system/companyverify/sagas";
import watchVehicleVerify from "modules/admin-system/vehicleverify/sagas";

// master data
import watchUsergroupMasterDataState from "modules/master-data/usergroup/sagas";
import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchDatavisibilityMasterDataState from "modules/master-data/datavisibility/sagas";
import watchPlaceMasterData from "modules/master-data/facility/sagas";
import watchUserMasterData from "modules/master-data/user/sagas";
import watchDataLogisticGroup from "modules/master-data/logisticGroup/sagas";
import watchRouteMasterData from "modules/master-data/route/sagas";
import watchVehicle from "modules/master-data/vehicle/sagas";
import watchDeviceUser from "modules/master-data/deviceuser/sagas";
import watchTransportationRating from "modules/master-data/transportationrating/sagas";
import watchCompanyProfile from "modules/master-data/companyprofile/sagas";

import watchCompany from "modules/master-data/company/sagas";
import watchGallery from "modules/master-data/gallery/sagas";
import watchExpense from "modules/master-data/expense/sagas";

import watchMonitoringState from "modules/monitoring/sagas";

import { watchDashboardTransport } from "modules/dashboard/transport/sagas";
import { watchDashboardProductOwner } from "modules/dashboard/product-owner/sagas";

import { fork, all } from "redux-saga/effects";

export default function* rootSagas() {
  yield all([
    fork(watchUIState),
    fork(watchAuthState),
    fork(watchUsergroupMasterDataState),
    fork(watchPermissionMasterDataState),
    fork(watchDatavisibilityMasterDataState),
    fork(watchPlaceMasterData),
    fork(watchUserMasterData),
    fork(watchRouteMasterData),
    fork(watchVehicle),
    fork(watchDeviceUser),
    fork(watchCompany),
    fork(watchGallery),
    fork(watchDataLogisticGroup),
    fork(watchTransportationRating),
    fork(watchExpense),
    fork(watchMonitoringState),
    fork(watchCompanyProfile),
    fork(watchCompanyVerify),
    fork(watchVehicleVerify),
    ...watchDashboardTransport,
    ...watchDashboardProductOwner
  ]);
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal } from "antd";

import * as actions from "../actions";

import LangContext, { i18n } from "modules/shared/context/langContext";
import Form from "./Forms";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import {
  GeneralStyledContent,
  PageTitleStyled,
} from "../../../../styled/backhaul-styled";
const { confirm } = Modal;

class Edit extends Component {
  componentDidMount() {
    this.props.getExpense(this.props.match.params.id);
  }

  showConfirm = (values, loading) => {
    let self = this;
    confirm({
      title: i18n[this.props.langBtn].d.doYouWantToEditTheseItems,
      content: i18n[this.props.langBtn].c.confirmToEditThisItem,
      okText: i18n[this.props.langBtn].o.ok,
      cancelText: i18n[this.props.langBtn].c.cancel,
      onOk() {
        self.edit(values, loading);
      },
      onCancel() {},
    });
  };

  edit = (values, loading) => {
    this.props.updateExpense(
      values,
      this.props.match.params.id,
      this.props.history,
      loading
    );
  };

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <PageTitleStyled>
          <LangContext.Consumer>
            {(i18n) => i18n.expense.edit}
          </LangContext.Consumer>
        </PageTitleStyled>
        <Form
          onSubmit={this.showConfirm}
          expense={this.props.expense}
          id={this.props.match.params.id}
          auth={this.props.auth}
        />
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = ({ expense, auth, ui: { langBtn } }) => ({
  expense,
  auth,
  langBtn,
});

const mapDispatchToProps = {
  updateExpense: actions.updateExpense.request,
  getExpense: actions.getExpenseByID.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

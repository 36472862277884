import styled from "styled-components";
import { Layout, Typography, Divider, Button } from "antd";

const { Text } = Typography;

const StyledSearchForm = styled.div`
  margin: 15px 0px 26px 0px;
  padding: 40px 40px 40px 80px;
  background: #f6f6f6;
  border-radius: 6px;
  font-size: 18px;
`;

const GeneralStyledContent = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  smin-height: ${window.innerHeight - 55}px;
`;

const PageTitleStyled = styled.span`
  font-size: 20px;
  color: #3c69dc;
  font-weight: 600;
`;

const DocumentNoStyled = styled.span`
  font-size: 18px;
  line-height: 20px;
  color: #3c69dc;
  font-weight: 600;
`;

const TitleText = styled.span`
  font-size: 18px;
  color: #3c69dc;
  font-weight: 600;
`;

const SubTitleText = styled.span`
font-size: 14px;
color: #727272;
font-weight: 600;
line-height: 46px;
`;

const NormalText = styled(Text)`
  font-size: 14px;
  color: #727272;
  line-height: 24px;
`;

const DividerStyled = styled(Divider)`
  margin: 35px 0;
  height: 2px;
`;

const ButtonBackhual = styled(Button)`
height: 35px;
padding: 0 15px;
font-size: 16px;
`;

export {
  StyledSearchForm,
  GeneralStyledContent,
  DocumentNoStyled,
  TitleText,
  SubTitleText,
  PageTitleStyled,
  NormalText,
  DividerStyled,
  ButtonBackhual
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal } from "antd";

import * as actions from "../actions";

import LangContext, { i18n } from "modules/shared/context/langContext";
import Forms from "./Forms";
import Breadcrumb from "./../../../shared/components/Breadcrumb";

import {
  GeneralStyledContent,
  PageTitleStyled,
} from "../../../../styled/backhaul-styled";

const { confirm } = Modal;

class Add extends Component {
  componentDidMount() {
    this.props.clearExpense();
  }

  showConfirm = (values, loading) => {
    let self = this;
    confirm({
      title: i18n[this.props.langBtn].d.doYouWantToAddTheseItems,
      content: i18n[this.props.langBtn].c.confirmToAddThisItem,
      okText: i18n[this.props.langBtn].o.ok,
      cancelText: i18n[this.props.langBtn].c.cancel,
      onOk() {
        self.create(values, loading);
      },
      onCancel() {},
    });
  };

  create = (values, loading) => {
    this.props.createExpense(values, loading, this.props.history, i18n);
  };

  render() {
    return (
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <PageTitleStyled>
          <LangContext.Consumer>
            {(i18n) => i18n.expense.create}
          </LangContext.Consumer>
        </PageTitleStyled>
        <Forms
          onSubmit={this.showConfirm}
          expense={this.props.expense}
          id={""}
          auth={this.props.auth}
        />
      </GeneralStyledContent>
    );
  }
}

const mapStateToProps = ({ expense, auth, ui: { langBtn } }) => ({
  expense,
  auth,
  langBtn,
});

const mapDispatchToProps = {
  createExpense: actions.createExpense.request,
  clearExpense: actions.clearExpense,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);

import productOwnerCompanygroup from './components/companygroup/reducer'
import productOwnerAllwork from './components/mywork/reducer'

const dashboardProductOwner = { 
    productOwnerCompanygroup, 
    productOwnerAllwork
}

export {
    dashboardProductOwner
}
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { StyledSearchForm, StyledSearchFormMark } from "../../../../styled/common-styled";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";
import FormDivider from "../../../shared/components/FormDivider";
import {
    loadVehiclePart,
    removeLists
} from "../actions";
import {
    Button,
    Table,
    Row,
    Col,
    Pagination,
    Spin,
    Popconfirm,
    Tooltip,
    InputNumber,
    Divider,
    Modal,
    Input,
    Form,
    Icon,
    Select,
    message
} from "antd";

const { confirm } = Modal;
const { Option } = Select;
const { Item } = Form;
const FormItem = Item;
const { TextArea } = Input;
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class FormAddPartGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add_part_type: {
                part_types_name: "",
                part_type: "",
                name: "",
            },
            edit_row: "",
            first_selected: [
                {
                    first_rule_selected: "distance",
                    first_condition: ">=",
                    first_value: "0",
                    first_opt: "AND"
                }
            ],
            finaly_selected: [
                {
                    finaly_rule_selected: "distance",
                    finaly_condition: ">=",
                    finaly_value: "0",
                    finaly_opt: "AND"
                }
            ],
            vahicle_id: "",
            vahicle_plateNo: "",
            vehicle_type_id: "",
            vehicle_type_name: "",
            PartType: [],
            VehicleType: [],
            first_rule: "",
            rule: "",
            check_first_rule: "",
            check_rule: "",

            currentPage: 1,
            page: 1,
            pageSize: 10,
            searchName: "",
            orderBy: "part_types_id",
            orderType: "desc",
            vehicle_type_name: "",
            vehicle_type_description: "",
            filterObj: {
                FilterObjVehicleTypeName: "",
            },
            part_type: {
                currentPage: 1,
                page: 1,
                pageSize: 3,
                orderBy: "parts.id",
                orderType: "asc",
            },
            display_add: "block",
            display_edit: "none",
            display_add_edit: "block",
            selected: null
        };
        this.goBack = this.goBack.bind(this); 

        this.columns = [
            {
                title: <LangContext.Consumer>{i18n => i18n.n.no}</LangContext.Consumer>,
                // "No.",
                dataIndex: "rowNumber",
                key: "rowNumber",
                width: 10,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: (
                    <LangContext.Consumer>{i18n => i18n.p.partType}</LangContext.Consumer>
                ),
                dataIndex: "part_type_name",
                key: "part_type_name",
                width: 150,
                sorter: true,
                render(text, record, index) {
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: (
                    <LangContext.Consumer>{i18n => i18n.f.firstMaintenanceConditions}</LangContext.Consumer>
                ),
                dataIndex: "first_time_rules",
                key: "first_time_rules",
                width: 350,
                render(text, record, index) {
                    let word = "";
                    let str = "";
                    let value = text.split(" ")
                    for (let i = 0; i < value.length; i++) {
                        if (value[i] == "distance") {
                            word = word + " " + "ระยะทาง";
                            str = "กม.";
                        }
                        else if (value[i] == "pto_on_dur") {
                            word = word + " " + "ระยะเวลาเปิด PTO/ประตู1";
                            str = "ชั่วโมง";
                        }
                        else if (value[i] == "drum_rotation") {
                            word = word + " " + "จำนวนรอบการหมุนของโม่";
                            str = "รอบ";
                        }
                        else if (value[i] == "duration") {
                            word = word + " " + "ระยะเวลา";
                            str = "วัน";
                        }
                        else if (value[i] == "engine_on_dur") {
                            word = word + " " + "ระยะเวลาติดเครื่องยนต์";
                            str = "ชั่วโมง";
                        }
                        else if (value[i] == ">=") {
                            word = word + " " + "มากกว่าเท่ากับ";
                        }
                        else if (value[i] == "<=") {
                            word = word + " " + "น้อยกว่าเท่ากับ";
                        } else if (value[i] == "AND") {
                            word = word + " " + "เเละ";
                        } else if (value[i] == "OR") {
                            word = word + " " + "หรือ";
                        } else {
                            word = word + " " + value[i] + " " + str;
                        }
                    }
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{word}</div>
                    };
                }


            },
            {
                title: (
                    <LangContext.Consumer>{i18n => i18n.n.nextMaintenanceConditions}</LangContext.Consumer>
                ),
                dataIndex: "rules",
                key: "rules",
                width: 350,
                render(text, record, index) {
                    let word = "";
                    let str = "";
                    let value = text.split(" ")
                    for (let i = 0; i < value.length; i++) {
                        if (value[i] == "distance") {
                            word = word + " " + "ระยะทาง";
                            str = "กม.";
                        }
                        else if (value[i] == "pto_on_dur") {
                            word = word + " " + "ระยะเวลาเปิด PTO/ประตู1";
                            str = "ชั่วโมง";
                        }
                        else if (value[i] == "drum_rotation") {
                            word = word + " " + "จำนวนรอบการหมุนของโม่";
                            str = "รอบ";
                        }
                        else if (value[i] == "duration") {
                            word = word + " " + "ระยะเวลา";
                            str = "วัน";
                        }
                        else if (value[i] == "engine_on_dur") {
                            word = word + " " + "ระยะเวลาติดเครื่องยนต์";
                            str = "ชั่วโมง";
                        }
                        else if (value[i] == ">=") {
                            word = word + " " + "มากว่าเท่ากับ";
                        }
                        else if (value[i] == "<=") {
                            word = word + " " + "น้อยกว่าเท่ากับ";
                        } else if (value[i] == "AND") {
                            word = word + " " + "เเละ";
                        } else if (value[i] == "OR") {
                            word = word + " " + "หรือ";
                        } else {
                            word = word + " " + value[i] + " " + str;
                        }
                    }
                    return {
                        props: {
                            style: columnStyle
                        },
                        children: <div>{word}</div>
                    };
                }
            },
            {
                title: (
                    <LangContext.Consumer>{i18n => i18n.a.action}</LangContext.Consumer>
                ),
                dataIndex: "action",
                key: "Action_btn",
                width: 50,
                render: (text, record, index) => {
                    return (
                        <div style={{display: this.state.display_add_edit}}>
                            <Tooltip
                                placement="left"
                                title="Edit"
                                arrowPointAtCenter
                                style={{
                                    padding: "0px 1px 0px 0px",
                                    marginLeft: "5px",
                                    marginRight: "5px"
                                }}
                                >
                                <Link
                                    to={`#`}
                                    style={{ padding: "0px 5px 0px 5px"}}
                                >
                                    <Icon 
                                        type="edit" 
                                        onClick={() => {
                                            let self = this;
                                            self.convertPartType(record);
                                            self.convertFirstRule(record.first_time_rules);
                                            self.convertRute(record.rules);
                                            self.setState({
                                                edit_row: record.part_id,
                                                display_add: "none",
                                                display_edit: "block",
                                                selected: record,
                                                display_add_edit: "none",
                                            });
                                        }}
                                    />
                                </Link>
                            </Tooltip>
                            <Tooltip
                                placement="left"
                                title="Delete"
                                arrowPointAtCenter
                            >
                                <Popconfirm
                                    title="Do you want to delete?"
                                    onConfirm={() => {
                                        let self = this;
                                        self.deletePartGroupDetail(
                                            record.part_id,
                                            this.props.auth.accessToken
                                        );
                                    }}
                                >
                                    <a
                                        href="javascript:;"
                                        style={{ padding: "0px 5px 0px 5px"}}
                                    >
                                        <Icon type="delete" />
                                    </a>
                                </Popconfirm>
                            </Tooltip>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        this.loadVehiclePart();
        let self = this;
        let id = this.props.match.params.id;
        let companyID = this.props.match.params.vehicle_companies_id;
  
        axios
        .get(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleforedit/${id}`,
            {
                headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
            }
        )
        .then(function (response) {
            if (typeof response.data.data !== "undefined") {
                self.setState({
                    vahicle_plateNo: response.data.data[0].plate_no,
                    vahicle_id:  response.data.data[0].id,
                    vehicle_type_id: response.data.data[0].vehicle_type_id,
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    // Load Part Type
        axios
            .post(
                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/partgroup/getparttype`,
                {
                    companyID
                },
                {
                    headers: { Authorization: `Bearer ${this.props.auth.accessToken}` }
                }
            )
            .then(function (response) {
                if (typeof response.data.data !== "undefined") {
                    let parttypeAPI = response.data.data.map(parttype => { return { key: parttype.part_type_id, value: parttype.part_type_name } })
                    self.setState({ PartType: parttypeAPI });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    // End Load Part Type
    }

    componentWillUnmount() {
        this.onRemoveLists();
    };

    onRemoveLists = () => {
        this.props.removeLists();
    };

    convertPartType = (data) => {
        this.setState({
            add_part_type: {
                ...this.state.add_part_type,
                part_types_name: data.part_types_name,
                part_type:  data.part_types_id,
                name:       data.name,
            }
        });

    }

    convertFirstRule = (FirstRules) => {
        
        let data = FirstRules.split(" ");
        this.state.first_selected = [];
        for(let i = 0; i < data.length; i+=4)
        {
            if(data.length > 3)
            {
                this.state.first_selected.push({
                    first_rule_selected:    data[i],
                    first_condition:        data[i+1],
                    first_value:            data[i+2],
                    first_opt:              data[i+3] == undefined ? "AND" : data[i+3]
                });

            }else {

                // this.state.first_selected = [];
                this.state.first_selected.push({
                    first_rule_selected:    data[i],
                    first_condition:        data[i+1],
                    first_value:            data[i+2],
                    first_opt:              "AND"
                });
            } 
        }
    }

    convertRute = (Rules) => {
  
        let data = Rules.split(" ");
        this.state.finaly_selected = [];
        for(let i = 0; i < data.length; i+=4)
        {
            if(data.length > 3)
            {
                this.state.finaly_selected.push({
                    finaly_rule_selected:    data[i],
                    finaly_condition:        data[i+1],
                    finaly_value:            data[i+2],
                    finaly_opt:              data[i+3] == undefined ? "AND" : data[i+3]
                });

            }else {

                // this.state.finaly_selected = [];
                this.state.finaly_selected.push({
                    finaly_rule_selected:    data[i],
                    finaly_condition:        data[i+1],
                    finaly_value:            data[i+2],
                    finaly_opt:              "AND"
                });
            } 
        }
    }

    handleCancelEdit = () =>{
        this.setState({
            add_part_type: {
                ...this.state.add_part_type,
                part_types_name: "",
                part_type: "",
                name: "",
            },
            selected: null,
            edit_row: "",
            display_add: "block",
            display_edit: "none",
            display_add_edit: "block",
        });

        this.state.first_selected = [];

        this.state.first_selected.push({
                first_rule_selected: "distance",
                first_condition: ">=",
                first_value: "0",
                first_opt: "AND"
        });

        this.state.finaly_selected = [];

        this.state.finaly_selected.push({
                finaly_rule_selected: "distance",
                finaly_condition: ">=",
                finaly_value: "0",
                finaly_opt: "AND"
        });
    }

    loadVehiclePart =()=> {
        this.props.loadVehiclePart(
            {
                vehicle_id: this.props.match.params.id,
                page: this.state.part_type.currentPage,
                pageSize: this.state.part_type.pageSize,
                orderBy: this.state.part_type.orderBy,
                orderType: this.state.part_type.orderType,
                accessToken: this.props.auth.accessToken,
            },
            true
        );
    }

    handlePartChange = (page, pageSize) => {
        this.setState({ 
            part_type: {
                ...this.state.part_type,
                page: page, 
                currentPage: page 
            }
        });
        this.props.loadVehiclePart(
          {
            vehicle_id: this.props.match.params.id,
            page: page,
            pageSize: pageSize,
            orderBy: this.state.part_type.orderBy,
            orderType: this.state.part_type.orderType,
            accessToken: this.props.auth.accessToken,
          },
          true
        );
    };

    handlePartTableChange = (pagination, filters, sorter) => {
        let orderBy = "";
        let orderType = "";
    
        if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
          this.setState({
            part_type: {
                ...this.state.part_type,
                orderBy: "drivers_history_id",
                orderType: "asc"
            }
          });
          orderBy = "drivers_history_id";
          orderType = "asc";
        } else {
          this.setState({
            part_type: {
                ...this.state.part_type,
                orderBy: sorter.columnKey,
                orderType: sorter.order == "descend" ? "desc" : "asc"
            }
          });
          orderBy = sorter.columnKey;
          orderType = sorter.order == "descend" ? "desc" : "asc";
        }
    
        this.props.loadVehiclePart(
            {
                vehicle_id: this.props.match.params.id,
                page: this.state.part_type.currentPage,
                pageSize: this.state.part_type.pageSize,
                orderBy: this.state.part_type.orderBy,
                orderType: this.state.part_type.orderType,
                accessToken: this.props.auth.accessToken,
            },
            true
          );
    }

    deletePartGroupDetail = (id, accessToken) => {
        let self = this;
        axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/vehicle/part/delate/${id}`, {
                headers: { Authorization: `Bearer ${accessToken}` }
            })
            .then(function (response) {
                self.loadVehiclePart(true);
                self.handleCancelEdit(); 
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    showConfirm = (values) => {

        let self = this;
        confirm({
            title: <LangContext.Consumer>{i18n => i18n.d.doYouWantToAddTheseItems}</LangContext.Consumer>,
            content: <LangContext.Consumer>{i18n => i18n.c.confirmToAddThisItem}</LangContext.Consumer>,
            okText: <LangContext.Consumer>{i18n => i18n.o.ok}</LangContext.Consumer>,
            cancelText: <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>,
            onOk() {
                self.handleSubmit(values);
            },
            onCancel() {
            },
        });
    };

    showConfirmEdit = (values) => {

        let self = this;
        confirm({
            title: <LangContext.Consumer>{i18n => i18n.d.doYouWantToEditTheseItems}</LangContext.Consumer>,
            content: <LangContext.Consumer>{i18n => i18n.c.confirmToEditThisItem}</LangContext.Consumer>,
            okText: <LangContext.Consumer>{i18n => i18n.o.ok}</LangContext.Consumer>,
            cancelText: <LangContext.Consumer>{i18n => i18n.c.cancel}</LangContext.Consumer>,
            onOk() {
                self.handleSubmit(values);
            },
            onCancel() {
            },
        });
    };


    handleSubmit = (values) => {

        let first_rule = "";
        let check_first_rule = "";

        this.state.first_selected.map((value, i) => {

            if (this.state.first_selected.length == 1) {
                first_rule = value.first_rule_selected + " " + value.first_condition + " " + value.first_value;
                check_first_rule = value.first_rule_selected;
            }

            if ((this.state.first_selected.length > 1) && (i == 0)) { // รอบเเรก กรณีมีเงื่อนไขมากกว่า 1

                first_rule = value.first_rule_selected + " " + value.first_condition + " " + value.first_value + " " + value.first_opt;
            }

            if ((this.state.first_selected.length > 1) && (i > 0) && (i + 1 == this.state.first_selected.length)) { // รอบสุดท้าย กรณีมีเงื่อนไขมากกว่า 1

                first_rule = first_rule + " " + value.first_rule_selected + " " + value.first_condition + " " + value.first_value;
                check_first_rule = value.first_rule_selected;

            }

            if ((this.state.first_selected.length > 1) && (i > 0) && (i + 1 != this.state.first_selected.length)) { // รอบ n เเต่ไม่ใช่รอบสุดท้าย กรณีมีเงื่อนไขมากกว่า 1

                first_rule = first_rule + " " + value.first_rule_selected + " " + value.first_condition + " " + value.first_value + " " + value.first_opt;
            }
        })

        if (first_rule != "") {
            this.checkFirstRule(first_rule, values);
        }

    };

    checkFirstRule = (first_rule, values) => {

        let rule = "";
        let check_rule = "";

        this.state.finaly_selected.map((value, i) => {
            if (this.state.finaly_selected.length == 1) {
                rule = value.finaly_rule_selected + " " + value.finaly_condition + " " + value.finaly_value;
                check_rule = value.finaly_rule_selected;
            }

            if ((this.state.finaly_selected.length > 1) && (i == 0)) { // รอบเเรก กรณีมีเงื่อนไขมากกว่า 1

                rule = value.finaly_rule_selected + " " + value.finaly_condition + " " + value.finaly_value + " " + value.finaly_opt;
            }

            if ((this.state.finaly_selected.length > 1) && (i > 0) && (i + 1 == this.state.finaly_selected.length)) { // รอบสุดท้าย กรณีมีเงื่อนไขมากกว่า 1

                rule = rule + " " + value.finaly_rule_selected + " " + value.finaly_condition + " " + value.finaly_value;
                check_rule = value.finaly_rule_selected;
            }

            if ((this.state.finaly_selected.length > 1) && (i > 0) && (i + 1 != this.state.finaly_selected.length)) { // รอบ n เเต่ไม่ใช่รอบสุดท้าย กรณีมีเงื่อนไขมากกว่า 1

                rule = rule + " " + value.finaly_rule_selected + " " + value.finaly_condition + " " + value.finaly_value + " " + value.finaly_opt;
            }

        });

        if (rule != "" && first_rule != "") {
            
            if(this.state.edit_row != "")
            {
                this.editRute(first_rule, rule, values);
            }else{
                this.addRule(first_rule, rule, values);
            }
        }
    }

    addRule = (first_rule, rule, values) => {

        let companyID = this.props.auth.profile.company_id;
        let vahicle_id = this.state.vahicle_id;
        let name = values.name;
        let type = values.part_type;
        let first_rules = first_rule;
        let rules = rule;
        let accessToken = this.props.auth.accessToken;
        let self = this;

        axios
            .post(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/createrule`,
                {
                    companyID,
                    vahicle_id,
                    name,
                    type,
                    first_rules,
                    rules
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
            .then(function (response) {
                    if (response.data.status == "fail") {
                        message.error(<LangContext.Consumer>{i18n => i18n.c.create_fail}</LangContext.Consumer>);
          
                    }else if(response.data.status == "nodata"){

                        message.error("รถไม่ติด gps");
                    }else {
                        message.success(<LangContext.Consumer>{i18n => i18n.c.create_success}</LangContext.Consumer>);

                        self.loadVehiclePart();  
                        self.handleCancelEdit();
                    }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    editRute = (first_rule, rule, values) =>{
        let part_id     = this.state.edit_row;
        let name        = values.name;
        let type        = values.part_type;
        let first_rules = first_rule;
        let rules       = rule;
        let accessToken = this.props.auth.accessToken;
        let self = this;

        axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/vehicle/updaterule`,
            {
                part_id,
                name,
                type,
                first_rules,
                rules
            },
            {
                headers: { Authorization: `Bearer ${accessToken}` }
            })
        .then(function (response) {
            if (response.data.status == "fail") {
                    message.error("update fail");
    
            }else {
                    message.success("update success");

                    self.loadVehiclePart();  
                    self.handleCancelEdit();                  
                }
        })
        .catch(function (error) {
            console.log(error);
        });
    }


// Add Row
    handleFirstAddRow = () => {
        this.setState(prevState => ({
            first_selected: [
                ...prevState.first_selected,
                {
                    first_rule_selected: "distance",
                    first_condition: ">=",
                    first_value: "0",
                    first_opt: "AND"
                }
            ]
        }));
    }
    
    handleFinalyAddRow = () => {
        this.setState(prevState => ({
            finaly_selected: [
                ...prevState.finaly_selected,
                {
                    finaly_rule_selected: "distance",
                    finaly_condition: ">=",
                    finaly_value: "0",
                    finaly_opt: "AND"
                }
            ]
        }));
    }
// End Add Row

// RemoveRow
    handleFirstRemoveRow = i => {
        let first_selected = [...this.state.first_selected];
        first_selected.splice(i, 1);
        this.setState({ first_selected });
    }

    handleFinalyRemoveRow = i => {
        let finaly_selected = [...this.state.finaly_selected];
        finaly_selected.splice(i, 1);
        this.setState({ finaly_selected });
    }
//End RemoveRow

    goBack(){
        this.props.history.goBack();
    }

    render() {
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 }
            }
        };
        const columns = this.columns.map(col => col);
        const {      
            part_lists,
            part_total,
            part_loading,
        } = this.props.vehicle;
        return (
            <AuthorizeComponent matching_name="vehicle">
                <GeneralStyledContent>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Breadcrumb
                                match={this.props.match}
                                style={{ margin: "0px 0px 14px 10px" }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24} md={24} lg={24} xl={24}>
                            <StyledSearchForm>
                                <Formik 
                                    enableReinitialize={true}
                                    initialValues={this.state.add_part_type}
                                    validationSchema={yup.object().shape({
                                        part_type: yup.string().nullable().required(' Frist Name is Required'),
                                    })}
                                    onSubmit={(values, actions) => {
                                        if(this.state.edit_row != "")
                                        {
                                            this.showConfirmEdit(values);
                                        }else{
                                            this.showConfirm(values);
                                        }
                                        
                                    }}

                                    render={({ values, errors, status, touched, handleBlur, setFieldValue, handleSubmit }) => (
                                        <div>
                                            <Form onSubmit={handleSubmit} style={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px", marginBottom: "10px" }}>
                                                <Row gutter={24}>
                                                    <FormDivider>
                                                        <LangContext.Consumer>
                                                            {i18n => i18n.p.partgroup}
                                                        </LangContext.Consumer>
                                                    </FormDivider>
                                                </Row>

                                                {/* <Row gutter={24}>
                                                    <Col sm={24} md={2} lg={2} xl={2}></Col>
                                                    <Col sm={24} md={11} lg={11} xl={11}>
                                                        <Row style={{ marginTop: "10px" }}>
                                                            <Col span={10} style={{ padding: "12px" }} align="right"><p>{<LangContext.Consumer>{i18n => i18n.p.plateNo}</LangContext.Consumer>}</p></Col>
                                                            <Col span={14} style={{ padding: "5px" }}>
                                                                <Item
                                                                    style={{ margin: "0px" }}
                                                                >
                                                                    <p style={{ padding: "0px" }}>{this.state.vahicle_plateNo}</p>
                                                                </Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={24} md={11} lg={11} xl={11}></Col>
                                                </Row> */}

                                                <Row>
                                                  <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                                    <Row style={{ marginTop: "10px" }}>
                                                      <Col
                                                        span={10}
                                                        style={{ padding: "12px" }}
                                                        align="right"
                                                      >
                                                        <p>
                                                          {
                                                            <LangContext.Consumer>
                                                              {i18n => i18n.p.plateNo}
                                                            </LangContext.Consumer>
                                                          }
                                                        </p>
                                                      </Col>
                                                      <Col span={14} style={{ padding: "5px" }}>
                                                        <Item style={{ margin: "0px" }}>
                                                          <p style={{ padding: "0px" }}>
                                                            {this.state.vahicle_plateNo}
                                                          </p>
                                                        </Item>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>

                                                <FormDivider>
                                                    <LangContext.Consumer>
                                                        {i18n => i18n.p.partType}
                                                    </LangContext.Consumer>
                                                </FormDivider>

                                                <Row>
                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                        <Item
                                                            label={
                                                                <LangContext.Consumer>
                                                                    {i18n => i18n.p.partType}
                                                                </LangContext.Consumer>
                                                            }
                                                            required={true}
                                                            validateStatus={
                                                                touched.part_type && errors.part_type ? "error" : undefined
                                                            }
                                                            help={
                                                                touched.part_type && errors.part_type
                                                                ? errors.part_type.substr(errors.part_type.indexOf(" ") + 1)
                                                                : undefined
                                                            }
                                                        >
                                                            <Select
                                                                name="user"
                                                                onSelect={(e, value) =>{
                                                                    setFieldValue("part_type", e);
                                                                    setFieldValue("part_types_name", value.props.children);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.part_types_name}
                                                            >
                                                                {
                                                                    this.state.PartType && this.state.PartType.map((data, i) =>
                                                                        <Option key={data.key} value={data.key}>
                                                                            {data.value}
                                                                        </Option>
                                                                    )
                                                                }
                                                            </Select>
                                                        </Item>
                                                    </Col>
                                                    <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                        <Item
                                                            label={
                                                                <LangContext.Consumer>
                                                                    {i18n => i18n.d.description}
                                                                </LangContext.Consumer>
                                                            }
                                                        >
                                                            <TextArea rows={3}
                                                                name="name"
                                                                onChange={e => {
                                                                    setFieldValue("name", e.target.value);
                                                                }}
                                                                autoComplete="off"
                                                                value={values.name}
                                                            />
                                                        </Item>
                                                    </Col>
                                                </Row>
                                                
                                                <FormDivider>
                                                    <LangContext.Consumer>
                                                        {i18n => i18n.m.maintenanceConditions}
                                                    </LangContext.Consumer>
                                                </FormDivider>

                                                <Row gutter={24}>
                                                    <Col sm={24} md={22} lg={22} xl={22}>
                                                        <Row style={{ marginTop: "10px" }}>
                                                            <Col span={19} style={{ padding: "5px" }}>
                                                                <Item
                                                                    label={
                                                                        <LangContext.Consumer>
                                                                            {i18n =>
                                                                                i18n.f.firstMaintenanceConditions
                                                                        }
                                                                        </LangContext.Consumer>
                                                                    }
                                                                    required={true}
                                                                >
                                                                    {
                                                                        this.state.first_selected.map((el, i) =>(
                                                                        <div key={i}>
                                                                            <Row gutter={24}>
                                                                                <Col sm={24} md={8} lg={8} xl={8}>
                                                                                    <Item
                                                                                        style={{ margin: "0px" }}
                                                                                    >
                                                                                        <Select
                                                                                            style={{ width: "89%" }}
                                                                                            onChange={(value) => {
                                                                                                let first_selected = [...this.state.first_selected];
                                                                                                first_selected[i].first_rule_selected = value;
                                                                                                this.setState({ first_selected });
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                            value={el.first_rule_selected || ""}
                                                                                        >
                                                                                            <Option value="distance">
                                                                                                <LangContext.Consumer>{i18n => i18n.d.distance}</LangContext.Consumer>
                                                                                            </Option>
                                                                                            <Option value="pto_on_dur">
                                                                                                <LangContext.Consumer>{i18n => i18n.d.durationOfPTOGate1On}</LangContext.Consumer>
                                                                                            </Option>
                                                                                            <Option value="drum_rotation">
                                                                                                <LangContext.Consumer>{i18n => i18n.c.cycleOfDrumRotation}</LangContext.Consumer>
                                                                                            </Option>
                                                                                            <Option value="duration">
                                                                                                <LangContext.Consumer>{i18n => i18n.d.duration}</LangContext.Consumer>
                                                                                            </Option>
                                                                                            <Option value="engine_on_dur">
                                                                                                <LangContext.Consumer>{i18n => i18n.e.engineOnDuration}</LangContext.Consumer>
                                                                                            </Option>
                                                                                        </Select>
                                                                                    </Item>
                                                                                </Col>
                                                                                <Col sm={24} md={5} lg={5} xl={5}>
                                                                                    <Item
                                                                                        style={{ margin: "0px" }}
                                                                                    >
                                                                                        {/* <Select
                                                                                            name="user"
                                                                                            style={{ width: "89%" }}
                                                                                            onChange={(value) => {
                                                                                                let first_selected = [...this.state.first_selected];
                                                                                                first_selected[i].first_condition = value;
                                                                                                this.setState({ first_selected });
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                            value={el.first_condition || ""}
                                                                                        >
                                                                                            <Option value=">=">
                                                                                                <LangContext.Consumer>{i18n => i18n.g.greaterOrEqual}</LangContext.Consumer>
                                                                                            </Option>
                                                                                            <Option value="<=">
                                                                                                <LangContext.Consumer>{i18n => i18n.l.lessOrEqual}</LangContext.Consumer>
                                                                                            </Option>
                                                                                        </Select> */}
                                                                                        {
                                                                                          el.first_condition == ">="
                                                                                          ? <h3> <LangContext.Consumer>{i18n =>i18n.g.greaterOrEqual}</LangContext.Consumer></h3>
                                                                                          : el.first_condition == "<="
                                                                                          ? <h3> <LangContext.Consumer>{i18n => i18n.l.lessOrEqual}</LangContext.Consumer></h3>
                                                                                          : ""
                                                                                        }
                                                                                    </Item>
                                                                                </Col>

                                                                                <Col sm={24} md={5} lg={5} xl={5}>
                                                                                    <Item
                                                                                        style={{ margin: "0px", marginLeft: "5px" }}
                                                                                    >
                                                                                        <InputNumber
                                                                                            min={0}
                                                                                            type="number"
                                                                                            name="RouteRadius"
                                                                                            style={{ width: "50%", marginRight: "5px" }}
                                                                                            onChange={e => {
                                                                                                let first_selected = [...this.state.first_selected];
                                                                                                first_selected[i].first_value = e;
                                                                                                this.setState({ first_selected });
                                                                                            }}
                                                                                            autoComplete="off"
                                                                                            value={el.first_value || ""}
                                                                                        />

                                                                                            {
                                                                                                el.first_rule_selected == "distance" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.k.km}</LangContext.Consumer>
                                                                                                ) : el.first_rule_selected == "pto_on_dur" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.h.hours}</LangContext.Consumer>
                                                                                                ) : el.first_rule_selected == "drum_rotation" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.r.round}</LangContext.Consumer>
                                                                                                ) : el.first_rule_selected == "duration" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.d.day}</LangContext.Consumer>   
                                                                                                ) : el.first_rule_selected == "engine_on_dur" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.h.hours}</LangContext.Consumer>
                                                                                                ) : null                                   
                                                                                            }

                                                                                    </Item>
                                                                                </Col>

                                                                                {/* <Col sm={24} md={3} lg={3} xl={3}>
                                                                                    <Item
                                                                                        style={{ margin: "0px", marginLeft: "15px" }}
                                                                                    >
                                                                                        {
                                                                                            i > 0 && (i + 1) == this.state.first_selected.length ? (
                                                                                                <Button
                                                                                                    type="primary"
                                                                                                    shape="circle"
                                                                                                    icon="close"
                                                                                                    onClick={() => this.handleFirstRemoveRow(i)}
                                                                                                />
                                                                                            ) : null}
                                                                                    </Item>
                                                                                </Col> */}

                                                                                {
                                                                                  i > 0 && (i + 1) == this.state.first_selected.length ? 
                                                                                  (
                                                                                    <Col sm={24} md={3} lg={3} xl={3}>
                                                                                      <Item
                                                                                        style={{ margin: "0px", marginLeft: "15px" }}
                                                                                      >
                                                                                        <Button
                                                                                          type="primary"
                                                                                          shape="circle"
                                                                                          icon="close"
                                                                                          onClick={() => this.handleFirstRemoveRow(i)}
                                                                                        />
                                                                                      </Item>
                                                                                    </Col>
                                                                                  ) : ""
                                                                                }

                                                                                <Col sm={24} md={3} lg={3} xl={3}>
                                                                                    <Item
                                                                                        style={{ margin: "0px" }}
                                                                                    >
                                                                                        {
                                                                                            this.state.first_selected.length > 1 ?
                                                                                                (i + 1) == this.state.first_selected.length ? null : (
                                                                                                    <Select
                                                                                                        style={{ width: "100%" }}
                                                                                                        onChange={(value) => {
                                                                                                            let first_selected = [...this.state.first_selected];
                                                                                                            first_selected[i].first_opt = value;
                                                                                                            this.setState({ first_selected });
                                                                                                        }}
                                                                                                        onBlur={handleBlur}
                                                                                                        value={el.first_opt || ""}
                                                                                                    >
                                                                                                        <Option value="AND">
                                                                                                            <LangContext.Consumer>{i18n => i18n.a.and}</LangContext.Consumer>
                                                                                                        </Option>
                                                                                                        <Option value="OR">
                                                                                                            <LangContext.Consumer>{i18n => i18n.o.or}</LangContext.Consumer>
                                                                                                        </Option>
                                                                                                    </Select>
                                                                                                )
                                                                                                : null
                                                                                            }
                                                                                    </Item>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    ))}
                                                                </Item>
                                                                <Item
                                                                    {...formItemLayoutWithOutLabel}
                                                                    style={{ margin: "0px", marginTop: "20px", align: "left" }}
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        onClick={() => this.handleFirstAddRow()}
                                                                        style={{ width: "30%" }}
                                                                    >
                                                                        <Icon type="plus" />{" "}
                                                                        <LangContext.Consumer>
                                                                            {i18n => i18n.a.add}
                                                                        </LangContext.Consumer>
                                                                    </Button>
                                                                </Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                    <Row gutter={24} style={{ marginTop: "15px" }}>
                                                        <Col sm={24} md={22} lg={22} xl={22}>
                                                            <Row style={{ marginTop: "10px" }}>
                                                                <Col span={19} style={{ padding: "5px" }}>
                                                                    <Form.Item
                                                                        label={
                                                                            <LangContext.Consumer>
                                                                            {i18n => i18n.n.nextMaintenanceConditions}
                                                                            </LangContext.Consumer>
                                                                        }
                                                                        required={true}
                                                                    >
                                                                        {
                                                                            this.state.finaly_selected.map((el, i) =>(
                                                                            <div key={i}>
                                                                                <Row gutter={24}>
                                                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                                                        <Item
                                                                                            style={{ margin: "0px" }}
                                                                                        >
                                                                                            <Select
                                                                                                name="user"
                                                                                                style={{ width: "89%" }}
                                                                                                onChange={(value) => {
                                                                                                    let finaly_selected = [...this.state.finaly_selected];
                                                                                                    finaly_selected[i].finaly_rule_selected = value;
                                                                                                    this.setState({ finaly_selected });
                                                                                                }}
                                                                                                onBlur={handleBlur}
                                                                                                value={el.finaly_rule_selected || ""}
                                                                                            >
                                                                                                <Option value="distance">
                                                                                                    <LangContext.Consumer>{i18n => i18n.d.distance}</LangContext.Consumer>
                                                                                                </Option>
                                                                                                <Option value="pto_on_dur">
                                                                                                    <LangContext.Consumer>{i18n => i18n.d.durationOfPTOGate1On}</LangContext.Consumer>
                                                                                                </Option>
                                                                                                <Option value="drum_rotation">
                                                                                                    <LangContext.Consumer>{i18n => i18n.c.cycleOfDrumRotation}</LangContext.Consumer>
                                                                                                </Option>
                                                                                                <Option value="duration">
                                                                                                    <LangContext.Consumer>{i18n => i18n.d.duration}</LangContext.Consumer>
                                                                                                </Option>
                                                                                                <Option value="engine_on_dur">
                                                                                                    <LangContext.Consumer>{i18n => i18n.e.engineOnDuration}</LangContext.Consumer>
                                                                                                </Option>
                                                                                            </Select>
                                                                                        </Item>
                                                                                    </Col>

                                                                                    <Col sm={24} md={5} lg={5} xl={5}>
                                                                                        <Item
                                                                                            style={{ margin: "0px" }}
                                                                                        >
                                                                                            {/* <Select
                                                                                                name="user"
                                                                                                style={{ width: "89%" }}
                                                                                                onChange={(value) => {
                                                                                                    let finaly_selected = [...this.state.finaly_selected];
                                                                                                    finaly_selected[i].finaly_condition = value;
                                                                                                    this.setState({ finaly_selected });
                                                                                                }}
                                                                                                onBlur={handleBlur}
                                                                                                value={el.finaly_condition || ""}
                                                                                            >
                                                                                                <Option value=">=">
                                                                                                    <LangContext.Consumer>{i18n => i18n.g.greaterOrEqual}</LangContext.Consumer>
                                                                                                </Option>
                                                                                                <Option value="<=">
                                                                                                    <LangContext.Consumer>{i18n => i18n.l.lessOrEqual}</LangContext.Consumer>
                                                                                                </Option>
                                                                                            </Select> */}
                                                                                            {
                                                                                              el.finaly_condition == ">="
                                                                                              ? <h3> <LangContext.Consumer>{i18n =>i18n.g.greaterOrEqual}</LangContext.Consumer></h3>
                                                                                              : el.finaly_condition == "<="
                                                                                              ? <h3> <LangContext.Consumer>{i18n => i18n.l.lessOrEqual}</LangContext.Consumer></h3>
                                                                                              : ""
                                                                                            }
                                                                                        </Item>
                                                                                    </Col>

                                                                                    <Col sm={24} md={5} lg={5} xl={5}>
                                                                                        <Item
                                                                                            style={{ margin: "0px", marginLeft: "5px" }}
                                                                                        >
                                                                                            <InputNumber
                                                                                                min={0}
                                                                                                type="number"
                                                                                                name="RouteRadius"
                                                                                                style={{ width: "50%", marginRight: "5px" }}
                                                                                                onChange={e => {
                                                                                                    let finaly_selected = [...this.state.finaly_selected];
                                                                                                    finaly_selected[i].finaly_value = e;
                                                                                                    this.setState({ finaly_selected });
                                                                                                }}
                                                                                                autoComplete="off"
                                                                                                value={el.finaly_value || ""}
                                                                                            />

                                                                                            {
                                                                                                el.finaly_rule_selected == "distance" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.k.km}</LangContext.Consumer>
                                                                                                ) : el.finaly_rule_selected == "pto_on_dur" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.h.hours}</LangContext.Consumer>
                                                                                                ) : el.finaly_rule_selected == "drum_rotation" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.r.round}</LangContext.Consumer>
                                                                                                ) : el.finaly_rule_selected == "duration" ? (
                                                                                                    <LangContext.Consumer>{i18n => i18n.d.day}</LangContext.Consumer>        
                                                                                                ) : el.finaly_rule_selected == "engine_on_dur" ? (             
                                                                                                    <LangContext.Consumer>{i18n => i18n.h.hours}</LangContext.Consumer>        
                                                                                                ) : null                              
                                                                                            }
                                                                                        </Item>
                                                                                    </Col>

                                                                                    {/* <Col sm={24} md={3} lg={3} xl={3}>
                                                                                        <Item
                                                                                            style={{ margin: "0px", marginLeft: "15px" }}
                                                                                        >
                                                                                            {
                                                                                                i > 0 && (i + 1) == this.state.finaly_selected.length ? (
                                                                                                    <Button
                                                                                                        type="primary"
                                                                                                        shape="circle"
                                                                                                        icon="close"
                                                                                                        onClick={() => this.handleFinalyRemoveRow(i)}
                                                                                                    />
                                                                                                ) : null}
                                                                                        </Item>
                                                                                    </Col> */}
                                                                                    {
                                                                                      i > 0 && (i + 1) == this.state.finaly_selected.length ? (
                                                                                        <Col sm={24} md={3} lg={3} xl={3}>
                                                                                          <Item
                                                                                            style={{ margin: "0px", marginLeft: "15px" }}
                                                                                          >
                                                                                            <Button
                                                                                              type="primary"
                                                                                              shape="circle"
                                                                                              icon="close"
                                                                                              onClick={() => this.handleFinalyRemoveRow(i)}
                                                                                            />
                                                                                          </Item>
                                                                                        </Col>
                                                                                      ) : ""
                                                                                    }
                                                                                    <Col sm={24} md={3} lg={3} xl={3}>
                                                                                        <Item
                                                                                            style={{ margin: "0px" }}
                                                                                        >
                                                                                            {
                                                                                                this.state.finaly_selected.length > 1 ?
                                                                                                    (i + 1) == this.state.finaly_selected.length ? null : (
                                                                                                        <Select
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={(value) => {
                                                                                                                let finaly_selected = [...this.state.finaly_selected];
                                                                                                                finaly_selected[i].finaly_opt = value;
                                                                                                                this.setState({ finaly_selected });
                                                                                                            }}
                                                                                                            onBlur={handleBlur}
                                                                                                            value={el.finaly_opt || ""}
                                                                                                        >
                                                                                                            <Option value="AND">
                                                                                                                <LangContext.Consumer>{i18n => i18n.a.and}</LangContext.Consumer>
                                                                                                            </Option>
                                                                                                            <Option value="OR">
                                                                                                                <LangContext.Consumer>{i18n => i18n.o.or}</LangContext.Consumer>
                                                                                                            </Option>
                                                                                                        </Select>
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                        </Item>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                    </Form.Item>
                                                                    <Item
                                                                        {...formItemLayoutWithOutLabel}
                                                                        style={{ margin: "0px", marginTop: "30px", align: "left" }}
                                                                    >
                                                                        <Button
                                                                            // type="dashed"
                                                                            type="primary"
                                                                            onClick={() => this.handleFinalyAddRow()}
                                                                            style={{ width: "30%" }}
                                                                        >
                                                                             <Icon type="plus" />{" "}
                                                                            <LangContext.Consumer>
                                                                                {i18n => i18n.a.add}
                                                                            </LangContext.Consumer>
                                                                        </Button>
                                                                    </Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                <Row style={{ marginTop: "45px", marginBottom: "5px", display: this.state.display_add }}>
                                                    <Col sm={24} md={5} lg={5} xl={5}>
                                                        <LangContext.Consumer>
                                                        {i18n => (
                                                            <Form.Item>
                                                                <Button type="default" onClick={this.goBack} style={{ marginLeft: "5px", marginRight: "10px" }}>
                                                                    <LangContext.Consumer>
                                                                        {i18n => i18n.b.back}
                                                                    </LangContext.Consumer>
                                                                </Button>
                                                                <Button type="primary" htmlType="submit">
                                                                    {i18n.s.save}
                                                                </Button>
                                                            </Form.Item>
                                                        )}
                                                        </LangContext.Consumer>
                                                    </Col>
                                                </Row>


                                                <Row style={{ marginTop: "45px", marginBottom: "5px", display: this.state.display_edit}}>
                                                    <Col sm={24} md={5} lg={5} xl={5}>
                                                        <LangContext.Consumer>
                                                        {i18n => (
                                                            <Form.Item>
                                                                <Button type="default" onClick={this.handleCancelEdit} style={{ marginLeft: "5px", marginRight: "10px" }}>
                                                                    <LangContext.Consumer>
                                                                        {i18n => i18n.c.cancel}
                                                                    </LangContext.Consumer>
                                                                </Button>

                                                                <Button type="primary" htmlType="submit" >
                                                                    {i18n.s.save}
                                                                </Button>
                                                            </Form.Item>
                                                        )}
                                                        </LangContext.Consumer>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    )}
                                />

                                <StyledSearchFormMark>
                                        <Row>
                                            <Spin spinning={part_loading} size="large">
                                                <Table
                                                    bordered
                                                    rowKey="part_id"
                                                    columns={columns}
                                                    dataSource={this.props.vehicle.part_lists}
                                                    // size="small"
                                                    pagination={false}
                                                    onChange={this.handlePartTableChange}
                                                    onRow={(selectedRowKeys,selectedRows) => ({ 
                                                        style: {
                                                        background: selectedRowKeys === this.state.selected ? '#00afec' : 'white',
                                                        color: selectedRowKeys === this.state.selected ? 'white' : 'black',
                                                        }
                                                    })}
                                                />
                                            </Spin>
                                        </Row>
                                        <Row type="flex" justify="end" style={{ padding: "15px" }}>
                                            <Pagination
                                                defaultPageSize={this.state.part_type.pageSize}
                                                size="small"
                                                current={this.state.part_type.currentPage}
                                                total={part_total}
                                                onChange={this.handlePartChange}
                                            />
                                        </Row>
                                </StyledSearchFormMark>
                            </StyledSearchForm>
                        </Col>
                    </Row>
                </GeneralStyledContent> 
            </AuthorizeComponent>
        );
    }

    
}
export default connect(
    ({ vehicle, auth }) => ({ vehicle, auth }),
    {
        loadVehiclePart: loadVehiclePart.request,
        removeLists
    }
)(FormAddPartGroup);
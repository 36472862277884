import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../../transport/components/inprogress/actions";
import { Row, Col, Spin, Descriptions, Card, Button } from "antd";
import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import {
  StyledSearchForm,
  GeneralStyledContent,
  PageTitleStyled,
  NormalText as Text,
  TitleText,
  DividerStyled,
} from "../../../../../../styled/backhaul-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import PreviewImg from "../../../../product-owner/components/workinprogress/components/PreviewImg";
import { translate } from "../../../../../../lib/helper";

export class Detail extends Component {
  getImage = (val) => {
    let images = [];
    if (val !== "" && typeof val !== "undefined") {
      let img = decodeURIComponent(val).split(",");
      if (img.length > 0) {
        return img;
      } else {
        return images.push(val);
      }
    }
    return [];
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.loadWorkinProgressDetail(id);
  }

  getDeliveryStatus = (data) => {
    let status = "m_status_1";

    if (typeof data.confirm_receive_at === "undefined") return status;
    if (
      this.checkEmptyValue(data.confirm_receive_at) == "" &&
      this.checkEmptyValue(data.confirm_deliver_at) == "" &&
      this.checkEmptyValue(data.cause_reject_id) == ""
    ) {
      status = "m_status_1"; //เข้ารับสินค้า
    }

    if (this.checkEmptyValue(data.confirm_receive_at) !== "" && this.checkEmptyValue(data.confirm_deliver_at) == "") {
      status = "m_status_2"; //อยู่ระหว่างการจัดส่ง
    }

    if (this.checkEmptyValue(data.confirm_deliver_at) !== "" && this.checkEmptyValue(data.cause_reject_id) == "") {
      status = "m_status_3"; //ส่งสินค้าแล้ว
    }

    if (this.checkEmptyValue(data.confirm_deliver_at) !== "" && this.checkEmptyValue(data.cause_reject_id) !== "") {
      status = "m_status_4"; //ปฏิเสธ
    }

    if (this.checkEmptyValue(data.confirm_deliver_at) !== "" && this.checkEmptyValue(data.cause_some_part_id) !== "") {
      status = "m_status_5"; //ส่งสินค้าไม่ครบจำนวน
    }

    return status;
  };

  checkEmptyValue = (val) => {
    return val !== "" && val !== null && val !== "null" ? val : ""  
  }

  render() {
    const { data } = this.props;
    return (
      <AuthorizeComponent matching_name="work_in_process_dashboard">
        <GeneralStyledContent>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Breadcrumb
                customeurl={[null, "/dashboard/work-inprogress", null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <PageTitleStyled>
              {translate("md_job", "m")}
              &nbsp;&nbsp;{data.document_number}
            </PageTitleStyled>
            <Spin spinning={false}>
              <StyledSearchForm>
                <Row gutter={[24, 16]}>
                  <Col span={24}>
                    <TitleText>
                      {translate("contact_origin_info", "worksheet")}
                    </TitleText>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Card style={{ width: "100%", padding: "24px" }}>
                      <Row gutter={[24, 16]}>
                        <Col span={12}>
                          <Row gutter={[24, 16]}>
                            <Col span={5}>
                              <Text>{translate("md_receiver", "m")}</Text>
                            </Col>
                            <Col span={19}>
                              <Text>
                                {translate(data.title_contact_load, "m")}{" "}
                                {data.firstname_contact_load}{" "}
                                {data.lastname_contact_load}
                              </Text>
                            </Col>
                          </Row>
                          <Row gutter={[24, 16]}>
                            <Col span={5}>
                              <Text>{translate("phone", "p")}</Text>
                            </Col>
                            <Col span={19}>
                              <Text>{data.phone_contact_load}</Text>
                            </Col>
                          </Row>
                          <Row gutter={[24, 16]}>
                            <Col span={5}>
                              <Text>{translate("md_sub_receiver", "m")}</Text>
                            </Col>
                            <Col span={19}>
                              <Text>{data.contact_name_instead}</Text>
                            </Col>
                          </Row>
                          <Row gutter={[24, 16]}>
                            <Col span={5}>
                              <Text>{translate("phone", "p")}</Text>
                            </Col>
                            <Col span={19}>
                              <Text>{data.contact_phone_instead}</Text>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <Card style={{ width: "100%", textAlign: "center" }}>
                            {data.receive_signature_img_path !== null && (
                              <PreviewImg
                                imageSrc={data.receive_signature_img_path}
                                styleImg={{
                                  width: "100%",
                                  height: "100%",
                                  paddingBottom: "10px",
                                }}
                              />
                            )}
                            {translate("signature", "s")}
                          </Card>
                        </Col>
                      </Row>

                      <DividerStyled />

                      <Row>
                        <Col span={24}>
                          <TitleText>{translate("job_doc_pic", "j")}</TitleText>
                        </Col>
                      </Row>
                      <Row gutter={[24, 24]} style={{ marginBottom: "50px" }}>
                        {data.receive_document_img_path !== null &&
                          this.getImage(data.receive_document_img_path).map(
                            (data, i) => {
                              let img = data.replace(
                                "https://terminus-master.sgp1.digitaloceanspaces.com/",
                                ""
                              );
                              img = img.replace("'", "");
                              return (
                                <Col span={4} key={i}>
                                  <div
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      padding: "20px",
                                    }}
                                  >
                                    <PreviewImg
                                      imageSrc={`https://terminus-master.sgp1.digitaloceanspaces.com/${img}`}
                                      styleImg={{
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </Col>
                              );
                            }
                          )}
                      </Row>

                      <DividerStyled />

                      <Row>
                        <Col span={24}>
                          <TitleText>{translate("job_img_prd", "j")}</TitleText>
                        </Col>
                      </Row>

                      <Row gutter={[24, 24]} style={{ marginBottom: "50px" }}>
                        {data.receive_product_img_path !== null &&
                          this.getImage(data.receive_product_img_path).map(
                            (data, i) => {
                              let img = data.replace(
                                "https://terminus-master.sgp1.digitaloceanspaces.com/",
                                ""
                              );
                              return (
                                <Col span={4} key={i}>
                                  <div
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      padding: "20px",
                                    }}
                                  >
                                    <PreviewImg
                                      imageSrc={`https://terminus-master.sgp1.digitaloceanspaces.com/${img}`}
                                      styleImg={{
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </Col>
                              );
                            }
                          )}
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <DividerStyled />

                <Row gutter={[24, 16]}>
                  <Col span={24}>
                    <TitleText>
                      {translate("contact_destination_info", "worksheet")}
                    </TitleText>
                  </Col>
                </Row>

                {typeof data.detail !== "undefined" &&
                  data.detail.map((val, i) => {
                    return (
                      <Row key={i} gutter={[24, 24]}>
                        <Col span={24}>
                          <Card style={{ width: "100%", padding: "24px" }}>
                            <Row gutter={[24, 16]}>
                              <Col span={24}>
                                <TitleText>
                                  {translate("md_receiver", "m")}
                                </TitleText>
                              </Col>
                            </Row>

                            <Row gutter={[24, 16]}>
                              <Col span={12}>
                                <Row gutter={[24, 16]}>
                                  <Col span={5}>
                                    <Text>{translate("md_receiver", "m")}</Text>
                                  </Col>
                                  <Col span={19}>
                                    <Text>
                                      {translate(val.contact_titlename, "m")}{" "}
                                      {val.contact_firstname}{" "}
                                      {val.contact_lastname}
                                    </Text>
                                  </Col>
                                </Row>
                                <Row gutter={[24, 16]}>
                                  <Col span={5}>
                                    <Text>
                                      {translate("md_sub_receiver", "m")}
                                    </Text>
                                  </Col>
                                  <Col span={19}>
                                    <Text>{val.contact_name_instead}</Text>
                                  </Col>
                                </Row>
                                <Row gutter={[24, 16]}>
                                  <Col span={5}>
                                    <Text>{translate("phone", "p")}</Text>
                                  </Col>
                                  <Col span={19}>
                                    <Text>{val.contact_phone_instead}</Text>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={6}>
                                <Card
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  {val.signature_img_path !== null &&
                                  <PreviewImg
                                    imageSrc={val.signature_img_path}
                                    styleImg={{
                                      width: "100%",
                                      height: "100%",
                                      paddingBottom: "10px",
                                    }}
                                  />}
                                  {translate("signature", "s")}
                                </Card>
                              </Col>
                            </Row>

                            <DividerStyled />

                            <Row>
                              <Col span={24}>
                                <TitleText>
                                  {translate("description", "d")}
                                </TitleText>
                              </Col>
                            </Row>

                            <Row gutter={[24, 24]}>
                              <Col span={24}>
                                <Descriptions>
                                  <Descriptions.Item
                                    label={translate("plateNo", "p")}
                                  >
                                    {data.plate_no}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={translate("md_departure", "m")}
                                  >
                                    {data.origin}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={translate(
                                      "destination_location",
                                      "expense"
                                    )}
                                  >
                                    {val.destination}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={translate("md_delivery_status", "m")}
                                  >
                                    {translate(this.getDeliveryStatus(val), "worksheet")}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={translate("md_sending_qty", "m")}
                                  >
                                    {val.quantity}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={translate("md_qty", "m")}
                                  >
                                    {val.complete_quantity}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={translate("md_reson", "m")}
                                  >
                                    {val.reason}
                                  </Descriptions.Item>
                                </Descriptions>
                              </Col>
                            </Row>

                            <DividerStyled />

                            <Row>
                              <Col span={24}>
                                <TitleText>
                                  {translate("job_doc_pic", "j")}
                                </TitleText>
                              </Col>
                            </Row>
                            <Row
                              gutter={[24, 24]}
                              style={{ marginBottom: "50px" }}
                            >
                              {val.document_img_path !== null &&
                                this.getImage(val.document_img_path).map(
                                  (val, i) => {
                                    let img = val.replace(
                                      "https://terminus-master.sgp1.digitaloceanspaces.com/",
                                      ""
                                    );
                                    img = img.replace("'", "");
                                    return (
                                      <Col span={4} key={i}>
                                        <div
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            padding: "20px",
                                          }}
                                        >
                                          <PreviewImg
                                            imageSrc={`https://terminus-master.sgp1.digitaloceanspaces.com/${img}`}
                                            styleImg={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}
                            </Row>

                            <DividerStyled />

                            <Row>
                              <Col span={24}>
                                <TitleText>
                                  {translate("job_img_prd", "j")}
                                </TitleText>
                              </Col>
                            </Row>

                            <Row
                              gutter={[24, 24]}
                              style={{ marginBottom: "50px" }}
                            >
                              {val.product_img_path !== null &&
                                this.getImage(val.product_img_path).map(
                                  (val, i) => {
                                    let img = val.replace(
                                      "https://terminus-master.sgp1.digitaloceanspaces.com/",
                                      ""
                                    );
                                    return (
                                      <Col span={4} key={i}>
                                        <div
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            padding: "20px",
                                          }}
                                        >
                                          <PreviewImg
                                            imageSrc={`https://terminus-master.sgp1.digitaloceanspaces.com/${img}`}
                                            styleImg={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    );
                                  }
                                )}
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
              </StyledSearchForm>
            </Spin>
          </Row>
          <Row style={{ margin: "0px 0px 100px 0px" }}>
            <Col>
              <Link to={`/dashboard/work-inprogress`}>
                <Button
                  data-cy="btn-go-back"
                  type="default"
                  style={{
                    marginLeft: "5px",
                    marginRight: "10px",
                  }}
                >
                  {translate("back", "b")}
                </Button>
              </Link>
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportworkinprogress }) => ({
  data: transportworkinprogress.data,
  loading: transportworkinprogress.loading,
});

const mapDispatchToProps = {
  loadWorkinProgressDetail: actions.loadWorkinProgressDetail.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../transport/components/inprogress/actions";
import { Link } from "react-router-dom";
import {
  Divider,
  Row,
  Col,
  Table,
  Spin,
  Pagination,
  Icon,
  message,
  Tooltip,
  Popconfirm,
} from "antd";

import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { numberFormat, translate } from "../../../../../../lib/helper";

import "../../../../../../styled/transport-dashboard.css";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "m",
    keyLang: "md_job",
    dataKey: "document_number",
    sorter: false,
    render: "normal",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: false,
    render: "address",
    width: "17%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "address",
    width: "17%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_delivery_status",
    dataKey: "status",
    sorter: false,
    render: "status",
    width: "11%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_receiver",
    dataKey: "received_name",
    sorter: false,
    render: "received_name",
    width: "17%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_delivery_time",
    dataKey: "receive_date",
    sorter: false,
    render: "normal",
    width: "12%",
    cls: "vertical-top",
  },
  {
    idxLang: "a",
    keyLang: "action",
    dataKey: "comment",
    sorter: false,
    render: "action",
    width: "9%",
    cls: "vertical-top algin-center",
  },
];

const renderStatus = (status_id, val) => {
  let img = "/img/red.png";
  if (status_id == 2) {
    img = "/img/yellow.png";
  }

  if (status_id == 3 || status_id == 4) {
    img = "/img/green.png";
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div>
        {translate(val, "worksheet")}
        <br />
        <img src={img} style={{ padding: "10px" }} />
      </div>
    </div>
  );
};

const renderReceivedName = (items) => {
  return items.detail.map((val, i) => {
    let line = i + 1;
    return (
      <p>
        {line} {translate(val.contact_titlename, "m")} {val.receiver}
      </p>
    );
  });
};

const renderNormalTxt = (val) => {
  return <div dangerouslySetInnerHTML={{ __html: val }} />;
};

const renderAddress = (val, key) => {if(typeof val === "undefined") return ""
  let items = val.split(",")
  let line = 1;
  let withLine = key === "origin" ? false : true

  if(val === "") {
    return <p></p>
  }

  return items.map((item, i) => {
    let txt = item.split(" (")
    if(withLine)
  return <p key={i}>{line+i}. {txt[0]} <br/> ({txt[1]}</p>
    else 
      return <p key={i}>{txt[0]} <br/> ({txt[1]}</p>
  })
}

export class Layout extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
  };

  genTableHeader = () => {
    const columns = [];
    tableHeader.map((v) => {
      columns.push({
        title: translate(v.keyLang, v.idxLang),
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderComponent(v.render, record, v.dataKey),
          };
        },
      });
    });

    return columns;
  };

  renderComponent = (type, val, key) => {
    switch (type) {
      case "received_name":
        return renderReceivedName(val);
      case "address":
          return renderAddress(val[key], key)
      case "status":
        return renderStatus(val.status_id, val[key]);
      case "action":
        return this.renderActionbtn(val, val.status_id);
      default:
        return renderNormalTxt(val[key]);
    }
  };

  handleCopyWorksheet = (id) => () => {
    window.open(`${window.location.href}/onetime/${id}`, "_blank");
  };

  renderActionbtn = (val, status) => {
    return (
      <Fragment>
        <Tooltip
          placement="left"
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.d.description}
            </LangContext.Consumer>
          }
          arrowPointAtCenter
          style={{
            padding: "0px 1px 0px 0px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          <Link 
          data-cy={`btn-view-${val.id}`}
          to={`/productowner/work-inprogress/view/${val.id}`}
          style={{ padding: "0px 5px 0px 5px" }}
          >
            <Icon type="file-text" style={{ fontSize: "15px" }}/>
          </Link>
        </Tooltip>

        <Tooltip
          placement="left"
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.j.job_quotation_r}
            </LangContext.Consumer>
          }
          arrowPointAtCenter
          style={{
            padding: "0px 1px 0px 0px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          <Link
            data-cy={`btn-reqt-${val.id}`}
            to={`/productowner/work-inprogress/onetime/${val.id}`}
            disabled={![5, 6].includes(parseInt(status)) || val.reqt === true}
            style={{ padding: "0px 5px 0px 5px" }}
          >
            <Icon type="diff" style={{ fontSize: "15px" }}/>
          </Link>
        </Tooltip>

        <Tooltip
          placement="left"
          title={
            <LangContext.Consumer>
              {(i18n) => i18n.d.delete}
            </LangContext.Consumer>
          }
          arrowPointAtCenter
          style={{
            padding: "0px 1px 0px 0px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          <Popconfirm
            title={
              <LangContext.Consumer>
                {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
              </LangContext.Consumer>
            }
            onConfirm={() => {
              this.props.removeWorksheet(val.id);
            }}
            disabled={![5, 6].includes(parseInt(status))}
          >
            <a data-cy={`btn-del-${val.id}`} href="javascript:;" style={{ padding: "0px 5px 0px 5px" }} 
            disabled={![5, 6].includes(parseInt(status))}>
              <Icon
                type="delete"
                style={{ fontSize: "15px" }}
              />
            </a>
          </Popconfirm>
        </Tooltip>
      </Fragment>
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.props.loadWorkinProgress({
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
      types: "productowner",
    });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadWorkinProgress({
      page: page,
      pageSize: pageSize,
      types: "productowner",
    });
  };

  getDeliveryStatus = (val, data) => {
    let status = "";
    if (typeof data.confirm_receive_at === "undefined") return status;
    if (
      data.confirm_receive_at === null &&
      data.confirm_deliver_at === null &&
      data.cause_reject_id === null
    ) {
      status = translate("m_status_1", "worksheet");
    }

    if (data.confirm_receive_at !== null && data.confirm_deliver_at === null) {
      status = translate("m_status_2", "worksheet");
    }

    if (data.confirm_deliver_at !== null && data.cause_reject_id === null) {
      status = translate("m_status_3", "worksheet");
    }

    if (data.confirm_deliver_at !== null && data.cause_reject_id !== null) {
      status = translate("m_status_4", "worksheet");
    }

    if (data.confirm_deliver_at !== null && data.cause_some_part_id !== null) {
      status = translate("m_status_5", "worksheet");
    }

    return status;
  };

  renderExpandable = (record, index, indent, expanded) => {
    const columnsA = [
      {
        title: translate("md_destination", "m"),
        dataIndex: "destination",
        key: "destination",
      },
      {
        title: translate("md_receiver", "m"),
        dataIndex: "receiver",
        key: "receiver",
        render: (val, rec) => (
          <span>
            {translate(rec.contact_titlename, "m")} {val}
          </span>
        ),
      },
      {
        title: translate("phone", "p"),
        dataIndex: "contact_phone",
        key: "contact_phone",
      },
      {
        title: translate("md_sending_qty", "m"),
        dataIndex: "quantity",
        key: "quantity",
        render: (val) => numberFormat(val, 0),
      },
      {
        title: translate("md_qty", "m"),
        dataIndex: "complete_quantity",
        key: "complete_quantity",
        render: (val) => numberFormat(val, 0),
      },
      {
        title: translate("md_delivery_status", "m"),
        dataIndex: "status",
        key: "status",
        render: (val, rec) => this.getDeliveryStatus(val, rec),
      },
    ];
    const data =
      typeof this.props.data[index] !== "undefined"
        ? this.props.data[index].detail
        : [];
    return <Table columns={columnsA} dataSource={data} pagination={false} className="sub-table"/>;
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.props.loadWorkinProgress(page, pageSize, "productowner");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.success === true && prevProps.success !== this.props.success) {
      message.success(i18n[this.props.langBtn]["s"]["success"]);
      const { page, pageSize } = this.state;
      this.props.loadWorkinProgress(page, pageSize, "productowner");
    }

    if (this.props.success === false && prevProps.success !== this.props.success) {
      message.error(i18n[this.props.langBtn]["f"]["fail_save"]);
    }
  }

  render() {
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="workinprogress_dashboard_prd">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                <LangContext.Consumer>
                  {(i18n) => i18n.d.dashboard_work_process}
                </LangContext.Consumer>
              </Divider>
            </Col>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                  expandedRowRender={this.renderExpandable}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportworkinprogress, ui: { langBtn } }) => ({
  data: transportworkinprogress.lists,
  totalRows: transportworkinprogress.totalRows,
  loading: transportworkinprogress.loading,
  success: transportworkinprogress.success,
  langBtn
});

const mapDispatchToProps = {
  loadWorkinProgress: actions.loadWorkinProgress.request,
  removeWorksheet: actions.removeWorksheet.request,
  copyWorksheet: actions.copyWorksheet.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID
} from "../../../../../constants/local_storage";
import * as actions from "./actions";

function* loadTransportCompanies(action) {

  const { page, pageSize, orderBy, orderType, filters } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/loadTransportCompany`,
      {
        page,
        pageSize,
        orderBy, 
        orderType, 
        filters,
        company_id: COMPANY_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadTransportCompanies.success(res.data));
  } catch (err) {
    yield put(actions.loadTransportCompanies.failure());
  }
}

function* showTransportCompany(action) {

  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/showTransportCompany/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` }}
    );

    yield put(actions.showTransportCompany.success(res.data));
  } catch (err) {
    yield put(actions.showTransportCompany.failure());
  }
}

function* addTransportCompany(action) {
  const { company_id, group_id } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/addGroupCompany`,
      {
        company_id,
        group_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` }}
    );

    yield put(actions.addTransportCompany.success(res.data));
  } catch (err) {
    yield put(actions.addTransportCompany.failure());
  }
}

export default function* productOwnerCompanygroup() {
  yield all([
    takeEvery(actions.LOAD_TRANSPORT_COMPANIES.REQUEST, loadTransportCompanies),
    takeEvery(actions.SHOW_TRANSPORT_COMPANY.REQUEST, showTransportCompany),
    takeEvery(actions.ADD_TRANSPORT_COMPANY.REQUEST, addTransportCompany)
  ]);
}

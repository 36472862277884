import React, { Component } from "react";
import { Modal, Spin } from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  StyledDiv,
  StyledRow,
  StyledColRegister,
} from "../../../styled/common-styled";
import { firebase } from "../../../lib/fcm/fcm_init";
import ForgotpasswordForm from "./ForgotpasswordForm";
import { checkLogin, setLoading } from "../actions";
import LangContext, { i18n } from "modules/shared/context/langContext";

class Forgetpassword extends Component {
  state = {
    valueForgot: {
      email: "",
      phone: "",
      password: "",
      trypassword: "",
    },
  };

  componentDidMount() {
    // this.props.setLoading(true);
  }

  onSubmit = (values) => {
    let self = this;
    this.props.setLoading(true);
    if (/^\d+$/.test(values.email)) {
      //เบอร์โทร
      self.getUserInfor("phone", values.email);
    } else {
      self.getUserInfor("email", values.email);
    }
  };

  getUserInfor = (by, email) => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getuserinfo`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        by,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.props.setLoading(false);
        if(res.status==="fail"){
           Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.no_user_in_system}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
        }else{
          //console.log(res.email,res.phone);
          this.props.history.push({
            pathname: "/signup/verify/" + res.user_type + "/forgotpassword",
            state: { values: { email: res.email, phone: res.phone } },
          });
        }

      
      });
  };
  render() {
    return (
      <StyledDiv>
        <StyledRow style={{margin: "auto",width:"80%",paddingTop:"10%",paddingLeft:"20%"}}>
          <ForgotpasswordForm onSubmit={this.onSubmit} />
        </StyledRow>
      </StyledDiv>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    checkLogin: checkLogin.request,
    setLoading: setLoading,
  }
)(Forgetpassword);

import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Button, Form, Input, Select, Col, Row, Spin } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import axios from "axios";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Contactform extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {}

  handleClick = (value, contactHandleSubmit) => {
    contactHandleSubmit();
  };

  render() {
    const { onSubmit, cancelContact, contact } = this.props;
    return (
      <div>
        <LangContext.Consumer>
          {(i18n) => (
            <Formik
              enableReinitialize={true}
              initialValues={{
                title_name: contact.title_name,
                firstname: contact.firstname,
                lastname: contact.lastname,
                position: contact.position,
                phone: contact.phone,
                email: contact.email,
              }}
              validate={(values) => {
                let errors = {};
                if (/^\d+$/.test(values.phone) === false) {
                  errors.phone = i18n.p.phone_invalid;
                }

                if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = i18n.e.email_invalid;
                }
                return errors;
              }}
              validationSchema={yup.object().shape({
                firstname: yup.string().required(i18n.addr.firstname_require),
                lastname: yup.string().required(i18n.addr.lastname_require),
                position: yup.string().required(i18n.p.position_require),
                phone: yup.string().required(i18n.p.phone_require),
                email: yup.string().required(i18n.e.email_require),
              })}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit: contactHandleSubmit,
                handleFocus,
                setFieldValue,
              }) => (
                <Form onSubmit={contactHandleSubmit}>
                  <Row>
                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                      <FormItem
                        required={true}
                        label={i18n.f.firstName}
                        validateStatus={
                          touched.firstname && errors.firstname && "error"
                        }
                        help={touched.firstname && errors.firstname}
                      >
                        <InputGroup compact>
                          <Select
                            data-cy="title_name"
                            style={{ width: "30%" }}
                            onChange={(value) =>
                              setFieldValue("title_name", value)
                            }
                            value={values.title_name}
                          >
                            <Option value="mr">{i18n.m.mr}</Option>
                            <Option value="mrs">{i18n.m.mrs}</Option>
                            <Option value="miss">{i18n.m.miss}</Option>
                          </Select>
                          <Input
                            data-cy="firstname"
                            style={{ width: "70%" }}
                            type="text"
                            name="firstname"
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              touched.firstname && errors.firstname
                                ? "invalid"
                                : undefined
                            }
                          />
                        </InputGroup>
                      </FormItem>
                    </Col>
                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                      <Form.Item
                        label={i18n.l.lastName}
                        required={true}
                        validateStatus={
                          touched.lastname && errors.lastname && "error"
                        }
                        help={touched.lastname && errors.lastname}
                      >
                        <Input
                          data-cy="lastname"
                          type="text"
                          name="lastname"
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.lastname && errors.lastname
                              ? "invalid"
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                      <Form.Item
                        required={true}
                        label={i18n.p.position}
                        validateStatus={
                          touched.position && errors.position && "error"
                        }
                        help={touched.position && errors.position}
                      >
                        <Input
                          data-cy="position"
                          type="text"
                          name="position"
                          value={values.position}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.position && errors.position
                              ? "invalid"
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                      <Form.Item
                        required={true}
                        label={i18n.p.phone}
                        validateStatus={
                          touched.phone && errors.phone && "error"
                        }
                        help={touched.phone && errors.phone}
                      >
                        <Input
                          data-cy="phone"
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.phone && errors.phone
                              ? "invalid"
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                      <Form.Item
                        required={true}
                        label={i18n.e.email}
                        validateStatus={
                          touched.email && errors.email && "error"
                        }
                        help={touched.email && errors.email}
                      >
                        <Input
                          data-cy="email"
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={
                            touched.email && errors.email
                              ? "invalid"
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Button
                    data-cy="btn-go-back"
                    type="default"
                    style={{
                      width: "20%",
                      margin: "50px 20px 0px 0px",
                    }}
                    onClick={cancelContact}
                  >
                    {i18n.c.cancel}
                  </Button>

                  <Button
                    // htmlType="submit"
                    data-cy="btn-submit"
                    onClick={(value) =>
                      this.handleClick(value, contactHandleSubmit)
                    }
                    type="primary"
                    style={{
                      width: "20%",
                      backgroundColor: "#F15B5B",
                      borderColor: "#F15B5B",
                      margin: "50px 0px 0px 0px",
                    }}
                  >
                    {i18n.c.confirm}
                  </Button>
                </Form>
              )}
            />
          )}
        </LangContext.Consumer>
      </div>
    );
  }
}

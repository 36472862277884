import React from "react";
import * as moment from "moment";
import history from "./history";
import LangContext, { i18n } from "modules/shared/context/langContext";

export const findLatLngDiff = (lat, lng, lat2, lng2) => {
  let value = Math.abs(lat - lat2) + Math.abs(lng - lng2);

  return value;
};

export const checkUnauthorized = (fn, action) => {
  console.log(action);
  history.push("/");
  return fn;
};

export const autoGenKey = (perfix) =>
  `${perfix}-${Math.random()
    .toString(36)
    .substring(7)}-${Math.ceil(Math.random() * 1000)}}`;

export const helper_convertDateformat = (
  dateStr,
  sourceFormat,
  resultFormat
) => {
  let result = "";
  if (dateStr === null) return dateStr;
  if (dateStr.length > 4 && dateStr !== "")
    result = moment(dateStr, sourceFormat).format(resultFormat);
  else if (dateStr === "now") result = "now";
  return result;
};

export const helper_changeNumberStatusToTextStatus = (
  number,
  arrKeyWithValue
) => arrKeyWithValue[number.toString()];

export const vecAdd = (v, a) => ({ x: v.x + a.x, y: v.y + a.y });
export const vecMul = (v, a) => ({ x: v.x * a, y: v.y * a });

export function latLng2World({ lat, lng }) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y =
    y < 0 // eslint-disable-line
      ? 0
      : y > 1
      ? 1
      : y;
  return { x, y };
}

export function tile2LatLng({ x, y }, zoom) {
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, zoom);

  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: (x / Math.pow(2, zoom)) * 360 - 180,
  };
}

export function latLng2Scaled({ lat, lng }, zoom) {
  const worldCoords = latLng2World({ lat, lng });
  const scale = Math.pow(2, zoom);

  return {
    x: worldCoords.x * scale,
    y: worldCoords.y * scale,
  };
}

export function latLng2Tile({ lat, lng }, zoom) {
  const { x, y } = latLng2Scaled({ lat, lng }, zoom);

  return {
    x: Math.floor(x),
    y: Math.floor(y),
  };
}

export function getTileBounds({ bounds, zoom, tileExpand = 0 }) {
  const { nw, se } = bounds;
  const from = vecAdd(latLng2Tile(nw, zoom), {
    x: -tileExpand,
    y: -tileExpand,
  });
  const to = vecAdd(latLng2Tile(se, zoom), { x: tileExpand, y: tileExpand });
  return [from, to];
}

export function getTilesIds({ bounds, zoom, tileExpand }) {
  const [from, to] = getTileBounds({ bounds, zoom, tileExpand });
  const scale = Math.pow(2, zoom);
  const ids = [];

  for (let x = from.x; x !== (to.x + 1) % scale; x = (x + 1) % scale) {
    for (let y = from.y; y !== (to.y + 1) % scale; y = (y + 1) % scale) {
      ids.push({ zoom, x, y });
    }
  }

  return ids;
}

export function bboxIntersects(bbox1, bbox2) {
  const [{ x: x0, y: y0 }, { x: x1, y: y1 }] = bbox1;
  const [{ x: a0, y: b0 }, { x: a1, y: b1 }] = bbox2;

  return !((y1 < b0 || y0 > b1) && (x1 < a0 || x0 > a1));
}

export function distance({ x: x0, y: y0 }, { x: x1, y: y1 }) {
  return Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
}

export function convertLatLngToXy(lat, lng) {
  var y = (-1 * lat + 90) * (window.innerHeight - 45 / 180);
  var x = (lng + 180) * (882 / 360);
  return { x: x, y: y };
}

export const findNewLatLng = (lat, lng) => {
  let sdVal = 70; // more go right ,less go left
  let earth = 6378.137;
  let pi = Math.PI;
  let m = 1 / (((2 * pi) / sdVal) * earth) / 1000;

  let new_latitude = lat + 1 * m;

  pi = Math.PI;
  let cos = Math.cos;
  m = 1 / (((2 * pi) / sdVal) * earth) / 1000;

  let new_longitude = lng + (-510 * m) / cos(lat * (pi / 180));

  return { lat: new_latitude, lng: new_longitude };
};

export const checkInsidePolygon = (point, vs) => {
  let x = point.lat,
    y = point.lng;

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i].lat,
      yi = vs[i].lng;
    let xj = vs[j].lat,
      yj = vs[j].lng;

    let intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const Deg2Rad = (deg) => {
  return (deg * Math.PI) / 180;
};

export const getDistanceFromPointToPoint = (lat1, lng1, lat2, lng2, radius) => {
  lat1 = Deg2Rad(lat1);
  lat2 = Deg2Rad(lat2);
  lng1 = Deg2Rad(lng1);
  lng2 = Deg2Rad(lng2);

  let lngDiff = lng2 - lng1;
  let R = 6371000; // metres

  let dist =
    Math.acos(
      Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(lngDiff)
    ) * R;

  return dist <= radius;
};

export const checkPermissions = (data, matching_name, actions) => {
  let action = true;

  let permissions = data.filter(function(ele) {
    return ele.matching_name == matching_name && ele[actions] == 1;
  });

  if (permissions.length == 0) {
    action = false;
  } else {
    action = true;
  }

  return action;
};

export const hasPermission = (permissions, matchingName, actionName) => {
  let canAccess = permissions.find((ele) => {
    return ele.matching_name == matchingName && ele[actionName] == 1;
  });

  if (matchingName == "") {
    canAccess = true;
  } else if (typeof canAccess == "undefined") {
    canAccess = false;
  } else {
    canAccess = true;
  }

  return canAccess;
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const numberFormat = (number, decimal = 2) => {
  if (typeof number !== "string" && typeof number !== "number") return;
  if (number === "" || isNaN(number) ) number = 0;
  return parseFloat(removeComma(number))
    .toFixed(decimal)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const removeComma = (number) => {
  return number.toString().replace(/,/g, "");
};

export const translate = (key, cateKey, lang) => {
  if (typeof lang !== "undefined") {
    return i18n[lang][cateKey][key]

  } else {
    return <LangContext.Consumer>
    {(val) => val[cateKey][key]}
  </LangContext.Consumer>
  }
};

export const checkEmptyJson = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

import { combineReducers } from "redux";

// etc
import auth from "modules/auth/reducer";
import ui from "modules/ui/reducer";

//admin-system
import companyVerify from "modules/admin-system/companyverify/reducer";
import vehicleVerify from "modules/admin-system/vehicleverify/reducer";

// master data
import usergroupMasterDataState from "modules/master-data/usergroup/reducer";
import permissionMasterDataState from "modules/master-data/permission/reducer";
import datavisibilityMasterDataState from "modules/master-data/datavisibility/reducer";
import placeMasterData from "modules/master-data/facility/reducer";
import userMaster from "modules/master-data/user/reducer";
import dataLogisticGroup from "modules/master-data/logisticGroup/reducer";
import routeMasterData from "modules/master-data/route/reducer";
import vehicle from "modules/master-data/vehicle/reducer";
import deviceUser from "modules/master-data/deviceuser/reducer";
import transportationRating from "modules/master-data/transportationrating/reducer";
import companyProfile from "modules/master-data/companyprofile/reducer";
import locationgroup from "modules/master-data/locationgroup/reducer";
import company from "modules/master-data/company/reducer";
import gallery from "modules/master-data/gallery/reducer";
import expense from "modules/master-data/expense/reducer";

import monitoringState from "modules/monitoring/reducer";

import { dashboardTransport } from "modules/dashboard/transport/reducer"
import { dashboardProductOwner } from "modules/dashboard/product-owner/reducer"

export default combineReducers({
  auth,
  ui,
  usergroupMasterDataState,
  datavisibilityMasterDataState,
  placeMasterData,
  userMaster,
  routeMasterData,
  vehicle,
  permissionMasterDataState,
  deviceUser,
  locationgroup,
  company,
  gallery,
  dataLogisticGroup,
  transportationRating,
  expense,
  companyProfile,
  companyVerify,
  vehicleVerify,
  monitoringState,
  ...dashboardTransport,
  ...dashboardProductOwner
});

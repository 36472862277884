import React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import Detail from "./Detail"
import Worksheet from "../../../../../worksheets/worksheet/components/Edit";

export default () => (
    <Switch>
        <Route exact path="/productowner/work-inprogress" component={Layout} />
        <Route exact path="/productowner/work-inprogress/view/:id" component={Detail} />
        <Route exact path="/productowner/work-inprogress/:type/:id" component={Worksheet} />
    </Switch>
);
import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  Divider,
  Spin,
  Upload,
  Icon,
  message,
  Tooltip,
  Steps,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import axios from "axios";
import Contact from "./Contact";

const FormItem = Form.Item;
const Option = Select.Option;

export default class Productownerform extends Component {
  state = {
    fileIdCard: [],
    fileDrivingCard: [],
    loading: false,
    companytypeLists: [],
  };

  componentDidMount() {
    const { loadProvince } = this.props;
    loadProvince();
    this.companyType();
  }

  onChangeProvince = (value, option, setFieldValue) => {
    const { loadAmphur } = this.props;
    setFieldValue("province", value, false);
    setFieldValue("district", "");
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadAmphur(value);
  };

  onChangeDistrict = (value, option, setFieldValue) => {
    const { loadSubAmphur } = this.props;
    setFieldValue("district", value, false);
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadSubAmphur(value);
  };

  onChangeSubDistrict = (value, option, setFieldValue) => {
    setFieldValue("subdistrict", value, false);
    setFieldValue("postalcode", option.props.code, false);
  };

  companyType = () => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanytype`, {})
      .then(function(response) {
        //console.log("responsecom", response.data);
        self.setState({ companytypeLists: response.data.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const {
      onSubmit,
      provinceLists,
      amphurLists,
      subamphurLists,
      loading,
      step
    } = this.props;
    const {Step} =Steps

    const styleForm = {
      backgroundColor:"#fafbfb", 
      minHeight: "calc( 100vh - 420px)",
      borderRadius: "15px",
      padding:"5% 10% ",
      boxShadow: "10px 16px 23px -12px rgba(0, 0, 0, 0.13)",
    }
    const styleFormLabel = {
      fontSize:"13px",
      color:"#2f67dc",
    }

  const getStep = (step) => {
    let stepJSX = []
      for(let loopIndex = 1 ; loopIndex <= step ; loopIndex++)
        stepJSX = [...stepJSX , loopIndex]
    return(
      <Steps current={2} >   
        {stepJSX.map(() =>(
          <Step title="" description="" />
        ))}  
      </Steps>
    )
  }

    //console.log("province:", provinceLists);
    //console.log("com:", this.state.companytypeLists);
    return (
      <div>
        <Row>
          <Col xs={4} sm={4} md={4} lg={6} xl={7} xxl={7}/>
          <Col xs={16} sm={16} md={16} lg={14} xl={10} xxl={10}>
            <div style={styleForm}>
              <LangContext.Consumer>
                {(i18n) => (
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    company_name: "",
                    company_type: "",
                    juristic_id: "",
                    province_head_offfice: "",
                      founded_year: "",
                      number_employee: "",
                      buildingname: "",
                      roomnumber: "",
                      floor: "",
                      village: "",
                      houseno: "",
                      villageno: "",
                      soi: "",
                      province: "",
                      district: "",
                      subdistrict: "",
                      postalcode: "",
                      certificate_file: [],
                      product_file: [],
                      logo_file: [],
                      vat_20_file: [],
                    }}
                    validate={(values) => {
                      let errors = {};
                      
                      if (/^\d+$/.test(values.founded_year) === false) {
                        errors.founded_year = i18n.business.founded_year_invalid;
                      }
                      
                      if (/^\d+$/.test(values.number_employee) === false) {
                        errors.number_employee =
                        i18n.business.number_employee_invalid;
                      }
                      
                      if (values.certificate_file.length == 0) {
                        errors.certificate_file =
                        i18n.business.certificate_file_require;
                      }
                      
                      if (values.product_file.length == 0) {
                        errors.product_file = i18n.business.product_file_require;
                      }
                      
                      if (values.logo_file.length == 0) {
                        errors.logo_file = i18n.business.logo_file_require;
                      }
                      
                      if (values.vat_20_file.length == 0) {
                        errors.vat_20_file = i18n.business.vat_20_file_require;
                      }
                      
                      return errors;
                    }}
                    validationSchema={yup.object().shape({
                      company_name: yup
                      .string()
                      .required(i18n.business.company_name_require),
                      company_type: yup
                      .string()
                      .required(i18n.business.company_type_require),
                      juristic_id: yup
                      .string()
                      .required(i18n.business.juristic_id_require),
                      province_head_offfice: yup
                      .string()
                      .required(i18n.business.province_head_offfice_require),
                      founded_year: yup
                      .string()
                      .required(i18n.business.founded_year_require),
                      number_employee: yup
                      .string()
                      .required(i18n.business.number_employee_require),
                    })}
                    onSubmit={(values) => {
                      onSubmit(values);
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleFocus,
                      setFieldValue,
                    }) => (
                      <Spin spinning={loading}>
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"center"}}>
                              <h2 style={{color:"#2f67dc",fontWeight:"bold"}}> สร้างบัญชี</h2>
                              <h2 style={{color:"#2f67dc"}}>กรอกรายละเอียด</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={10} sm={0} md={0} lg={0} xl={0}/>
                            <Col  xs={12} sm={24} md={24} lg={24} xl={24} style={{padding:"20px 0px"}}>
                              {getStep(step)}
                            </Col>
                          </Row>
                          {/* <Divider dashed orientation="left">
                            <h2 style={{
                                color:"#2f67dc",
                                fontWeight:"bold",
                                fontSize:"16px"
                              }}>
                              {i18n.business.business_information}
                            </h2>
                          </Divider> */}

                          <Row>
                            <Col xs={24} sm={11} md={11} lg={11} xl={11} xxl={11}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.business.company_name}
                                  </span>
                                }
                                validateStatus={
                                  touched.company_name &&
                                  errors.company_name &&
                                  "error"
                                }
                                help={touched.company_name && errors.company_name}
                                >
                                <Input
                                  data-cy="company_name"
                                  type="text"
                                  name="company_name"
                                  value={values.company_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.company_name && errors.company_name
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            
                            {/* <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <FormItem
                                required={true}
                                label={
                                  <span style={styleFormLabel}>
                                      {i18n.business.company_type}
                                  </span>
                                }
                                colon={false}
                                validateStatus={
                                  touched.company_type &&
                                  errors.company_type &&
                                  "error"
                                }
                                help={touched.company_type && errors.company_type}
                                >
                                <Select
                                  data-cy="company_type"
                                  onChange={(value) =>
                                    setFieldValue("company_type", value)
                                  }
                                  value={values.company_type}
                                  //style={{ width: "83%" }}
                                  >
                                  <Option value="">{i18n.p.pleaseSelect}</Option>
                                  {this.state.companytypeLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                      {v.name}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </Col> */}
                            <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={12} xxl={5}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <Fragment>
                                    <span style={styleFormLabel}>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.business.logo_file}
                                      </LangContext.Consumer>
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      title={i18n.business.info_one_image}
                                      >
                                      <Icon
                                        type="info-circle"
                                        theme="outlined"
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: "5px",
                                          fontSize: "14px",
                                          color: "#3B68D9",
                                        }}
                                        />
                                    </Tooltip>
                                  </Fragment>
                                }
                                validateStatus={
                                  touched.logo_file && errors.logo_file && "error"
                                }
                                help={touched.logo_file && errors.logo_file}
                                >
                                <Upload
                                  className="test-logo-file"
                                  name="logo_file"
                                  fileList={values.logo_file}
                                  beforeUpload={(file) => {
                                    const isCheckTypeFile =
                                    file.type === "image/jpeg" ||
                                    file.type === "image/png";
                                    if (!isCheckTypeFile) {
                                      message.error(
                                        i18n.worksheet.upload_image_support
                                        );
                                      }
                                      // const isLt2M = file.size / 1024 / 1024 < 1;
                                      const checkSizeFile = file.size / 1024 <= 5120;
                                      if (!checkSizeFile) {
                                        message.error(
                                          i18n.worksheet.upload_size_support
                                          );
                                        }
                                        
                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("logo_file", [file]);
                                        }
                                        
                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        if (fileList.length === 0) {
                                          setFieldValue("logo_file", []);
                                        }
                                      }}
                                      >
                                  <Button
                                      data-cy="" data-cy="btn-upload-logo"
                                      type="primary"
                                    >
                                    <Icon type="upload" /> {i18n.s.select_file}
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={0} xxl={0}></Col>
                            <Col xs={24} sm={5} md={7} lg={5} xl={5} xxl={5}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.business.juristic_id}
                                  </span>
                                }
                                validateStatus={
                                  touched.juristic_id && errors.juristic_id && "error"
                                }
                                help={touched.juristic_id && errors.juristic_id}
                                >
                                <Input
                                  data-cy="juristic_id"
                                  type="text"
                                  name="juristic_id"
                                  value={values.juristic_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.juristic_id && errors.juristic_id
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={7} md={7} lg={5} xl={5}>
                              <FormItem
                                colon={false}
                                required={true}
                                label={
                                  <span style={styleFormLabel}>
                                      {i18n.business.province_head_offfice}
                                  </span>
                                }
                                validateStatus={
                                  touched.province_head_offfice &&
                                  errors.province_head_offfice &&
                                  "error"
                                }
                                help={
                                  touched.province_head_offfice &&
                                  errors.province_head_offfice
                                }
                                >
                                <Select
                                  data-cy="province_head_offfice"
                                  onChange={(value) =>
                                    setFieldValue("province_head_offfice", value)
                                  }
                                  value={values.province_head_offfice}
                                  //style={{ width: "83%" }}
                                  >
                                  <Option value="">{i18n.p.pleaseSelect}</Option>
                                  {provinceLists !== undefined
                                    ? provinceLists.map((v) => (
                                      <Option key={v.id} value={v.id}>
                                          {v.name}
                                        </Option>
                                      ))
                                      : ""}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={7} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.business.founded_year}
                                  </span>
                                }
                                validateStatus={
                                  touched.founded_year &&
                                  errors.founded_year &&
                                  "error"
                                }
                                help={touched.founded_year && errors.founded_year}
                                >
                                <Input
                                  data-cy="founded_year"
                                  type="text"
                                  name="founded_year"
                                  value={values.founded_year}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.founded_year && errors.founded_year
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.business.number_employee}
                                  </span>
                                }
                                validateStatus={
                                  touched.number_employee &&
                                  errors.number_employee &&
                                  "error"
                                }
                                help={
                                  touched.number_employee && errors.number_employee
                                }
                                >
                                <Input
                                  data-cy="number_employee"
                                  type="text"
                                  name="number_employee"
                                  value={values.number_employee}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.number_employee && errors.number_employee
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>

                            </Row>
                            <Row>
                            <Col xs={24} sm={5} md={8} lg={8} xl={8} xxl={6}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <Fragment>
                                    <span style={styleFormLabel}>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.business.certificate_file}
                                      </LangContext.Consumer>
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      title={i18n.business.info_one_file}
                                      >
                                      <Icon
                                        type="info-circle"
                                        theme="outlined"
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: "5px",
                                          fontSize: "14px",
                                          color: "#3B68D9",
                                        }}
                                        />
                                    </Tooltip>
                                  </Fragment>
                                }
                                validateStatus={
                                  touched.certificate_file &&
                                  errors.certificate_file &&
                                  "error"
                                }
                                help={
                                  touched.certificate_file && errors.certificate_file
                                }
                                >
                                <Upload
                                  className="test-upload-certificate-file"
                                  name="certificate_file"
                                  // listType="picture-card"
                                  fileList={values.certificate_file}
                                  // customRequest={dummyRequest}
                                  beforeUpload={(file) => {
                                    const isCheckTypeFile =
                                    file.type === "image/jpeg" ||
                                    file.type === "image/png" ||
                                    file.type === "application/pdf";
                                    if (!isCheckTypeFile) {
                                      message.error(
                                        i18n.worksheet.upload_file_support
                                        );
                                      }
                                      // const isLt2M = file.size / 1024 / 1024 < 1;
                                      const checkSizeFile = file.size / 1024 <= 5120;
                                      if (!checkSizeFile) {
                                        message.error(
                                          i18n.worksheet.upload_size_support
                                          );
                                        }
                                        
                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("certificate_file", [file]);
                                        }
                                        
                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        if (fileList.length === 0) {
                                          setFieldValue("certificate_file", []);
                                        }
                                      }}
                                      >
                                  <Button
                                      data-cy="" data-cy="btn-upload-certificate-file"
                                      type="primary"
                                    >
                                    <Icon type="upload" /> {i18n.s.select_file}
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={8} lg={6} xl={6} xxl={5}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <Fragment>
                                    <span style={styleFormLabel}>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.business.vat_20_file}
                                      </LangContext.Consumer>
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      title={i18n.business.info_one_file}
                                      >
                                      <Icon
                                        type="info-circle"
                                        theme="outlined"
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: "5px",
                                          fontSize: "14px",
                                          color: "#3B68D9",
                                        }}
                                        />
                                    </Tooltip>
                                  </Fragment>
                                }
                                validateStatus={
                                  touched.vat_20_file && errors.vat_20_file && "error"
                                }
                                help={touched.vat_20_file && errors.vat_20_file}
                                >
                                <Upload
                                  className="test-upload-vat-20-file"
                                  name="vat_20_file"
                                  fileList={values.vat_20_file}
                                  beforeUpload={(file) => {
                                    const isCheckTypeFile =
                                    file.type === "image/jpeg" ||
                                    file.type === "image/png" ||
                                    file.type === "application/pdf";
                                    if (!isCheckTypeFile) {
                                      message.error(
                                        i18n.worksheet.upload_file_support
                                        );
                                      }
                                      // const isLt2M = file.size / 1024 / 1024 < 1;
                                      const checkSizeFile = file.size / 1024 <= 5120;
                                      if (!checkSizeFile) {
                                        message.error(
                                          i18n.worksheet.upload_size_support
                                          );
                                        }
                                        
                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("vat_20_file", [file]);
                                        }
                                        
                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        if (fileList.length === 0) {
                                          setFieldValue("vat_20_file", []);
                                        }
                                  }}
                                  >
                                  <Button
                                      data-cy="" data-cy="btn-upload-vat-20-file"
                                      type="primary"
                                    >
                                    <Icon type="upload" /> {i18n.s.select_file}
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                required={true}
                                label={
                                  <Fragment>
                                    <span style={styleFormLabel}>
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.business.product_file}
                                      </LangContext.Consumer>
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      title={i18n.business.info_one_image}
                                      >
                                      <Icon
                                        type="info-circle"
                                        theme="outlined"
                                        style={{
                                          paddingTop: "8px",
                                          paddingLeft: "5px",
                                          fontSize: "14px",
                                          color: "#3B68D9",
                                        }}
                                        />
                                    </Tooltip>
                                  </Fragment>
                                }
                                validateStatus={
                                  touched.product_file &&
                                  errors.product_file &&
                                  "error"
                                }
                                help={touched.product_file && errors.product_file}
                                >
                                <Upload
                                  className="test-upload-product-file"
                                  name="product_file"
                                  fileList={values.product_file}
                                  beforeUpload={(file) => {
                                    const isCheckTypeFile =
                                    file.type === "image/jpeg" ||
                                    file.type === "image/png";
                                    if (!isCheckTypeFile) {
                                      message.error(
                                        i18n.worksheet.upload_image_support
                                        );
                                      }
                                      // const isLt2M = file.size / 1024 / 1024 < 1;
                                      const checkSizeFile = file.size / 1024 <= 5120;
                                      if (!checkSizeFile) {
                                        message.error(
                                          i18n.worksheet.upload_size_support
                                          );
                                        }
                                        
                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("product_file", [file]);
                                        }
                                        
                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        if (fileList.length === 0) {
                                      setFieldValue("product_file", []);
                                    }
                                  }}
                                  >
                                  <Button
                                      data-cy="" data-cy="btn-upload-product-file"
                                      type="primary"
                                    >
                                    <Icon type="upload" /> {i18n.s.select_file}
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Divider dashed orientation="left">
                            <h2 style={{
                                color:"#2f67dc",
                                fontWeight:"bold",
                                fontSize:"16px"
                              }}>
                              {i18n.business.addresss_business}
                            </h2>
                          </Divider>
                          <Row>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.buildingName}
                                  </span>
                                }
                                validateStatus={
                                  touched.buildingname &&
                                  errors.buildingname &&
                                  "error"
                                }
                                help={touched.buildingname && errors.buildingname}
                                >
                                <Input
                                  data-cy="buildingname"
                                  type="text"
                                  name="buildingname"
                                  value={values.buildingname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.buildingname && errors.buildingname
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.roomNumber}
                                  </span>
                                }
                                validateStatus={
                                  touched.roomnumber && errors.roomnumber && "error"
                                }
                                help={touched.roomnumber && errors.roomnumber}
                                >
                                <Input
                                  data-cy="roomnumber"
                                  type="text"
                                  name="roomnumber"
                                  value={values.roomnumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.roomnumber && errors.roomnumber
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.floor}
                                  </span>
                                }
                                validateStatus={
                                  touched.floor && errors.floor && "error"
                                }
                                help={touched.floor && errors.floor}
                                >
                                <Input
                                  data-cy="floor"
                                  type="text"
                                  name="floor"
                                  value={values.floor}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.floor && errors.floor
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.village}
                                  </span>
                                }
                                validateStatus={
                                  touched.village && errors.village && "error"
                                }
                                help={touched.village && errors.village}
                                >
                                <Input
                                  data-cy="village"
                                  type="text"
                                  name="village"
                                  value={values.village}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.village && errors.village
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.houseNo}
                                  </span>
                                }
                                validateStatus={
                                  touched.houseno && errors.houseno && "error"
                                }
                                help={touched.houseno && errors.houseno}
                                >
                                <Input
                                  data-cy="houseno"
                                  type="text"
                                  name="houseno"
                                  value={values.houseno}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.houseno && errors.houseno
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.villageNo}
                                  </span>
                                }
                                validateStatus={
                                  touched.villageno && errors.villageno && "error"
                                }
                                help={touched.villageno && errors.villageno}
                                >
                                <Input
                                  data-cy="villageno"
                                  type="text"
                                  name="villageno"
                                  value={values.villageno}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.villageno && errors.villageno
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.soi}
                                  </span>
                                }
                                validateStatus={touched.soi && errors.soi && "error"}
                                help={touched.soi && errors.soi}
                                >
                                <Input
                                  data-cy="soi"
                                  type="text"
                                  name="soi"
                                  value={values.soi}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.soi && errors.soi ? "invalid" : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <FormItem
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.province}
                                  </span>
                                }
                                validateStatus={
                                  touched.province && errors.province && "error"
                                }
                                help={touched.province && errors.province}
                                >
                                <Select
                                  data-cy="province"
                                  onChange={(value, option) =>
                                    this.onChangeProvince(
                                      value,
                                      option,
                                      setFieldValue
                                      )
                                    }
                                    value={values.province}
                                    //style={{ width: "83%" }}
                                    >
                                  <Option value="">{i18n.p.pleaseSelect}</Option>
                                  {provinceLists !== undefined
                                    ? provinceLists.map((v) => (
                                      <Option key={v.id} value={v.id}>
                                          {v.name}
                                        </Option>
                                      ))
                                      : ""}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <FormItem
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.district}
                                  </span>
                                }
                                validateStatus={
                                  touched.district && errors.district && "error"
                                }
                                help={touched.district && errors.district}
                                >
                                <Select
                                  data-cy="district"
                                  onChange={(value, option) =>
                                    this.onChangeDistrict(
                                      value,
                                      option,
                                      setFieldValue
                                      )
                                    }
                                    value={values.district}
                                    //style={{ width: "83%" }}
                                    >
                                  <Option value="">{i18n.p.pleaseSelect}</Option>
                                  {amphurLists !== undefined
                                    ? amphurLists.map((v) => (
                                      <Option key={v.id} value={v.id}>
                                          {v.name}
                                        </Option>
                                      ))
                                      : ""}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <FormItem
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.subdistrict}
                                  </span>
                                }
                                validateStatus={
                                  touched.subdistrict && errors.subdistrict && "error"
                                }
                                help={touched.subdistrict && errors.subdistrict}
                                >
                                <Select
                                  data-cy="subdistrict"
                                  onChange={(value, option) => {
                                    this.onChangeSubDistrict(
                                      value,
                                      option,
                                      setFieldValue
                                      );
                                    }}
                                    // onChange={(value) =>
                                    //   setFieldValue("subdistrict", value)
                                    // }
                                    value={values.subdistrict}
                                    //style={{ width: "83%" }}
                                    >
                                  <Option value="">{i18n.p.pleaseSelect}</Option>
                                  {subamphurLists !== undefined
                                    ? subamphurLists.map((v) => (
                                      <Option
                                      key={v.id}
                                      value={v.id}
                                      code={v.postalcode}
                                      >
                                          {v.name}
                                        </Option>
                                      ))
                                      : ""}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                              <Form.Item
                                colon={false}
                                label={
                                  <span style={styleFormLabel}>
                                    {i18n.addr.postalCode}
                                  </span>
                                }
                                validateStatus={
                                  touched.postalcode && errors.postalcode && "error"
                                }
                                help={touched.postalcode && errors.postalcode}
                                >
                                <Input
                                  data-cy="postalcode"
                                  type="text"
                                  name="postalcode"
                                  value={values.postalcode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.postalcode && errors.postalcode
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Divider dashed orientation="left">
                            <h2 style={{
                                color:"#2f67dc",
                                fontWeight:"bold",
                                fontSize:"16px"
                              }}>
                              <span style={{ color: "red" }}>*</span>
                              {i18n.business.contact_business}
                            </h2>
                          </Divider>
                          <Contact id={this.props.id}></Contact>
                          <Row style={{padding:"20px 0px"}}>
                            <Col xs={3} sm={4} md={4} lg={5} xl={5} xxl={7}/>
                            <Col  xs={21} sm={8} md={8} lg={5} xl={4} >
                              <Button
                                data-cy="btn-cancel"
                                type="default"
                                style={{
                                  width:"120px",
                                }}
                                onClick={() => (window.location.href = "/")}
                                >
                                {i18n.c.cancel}
                              </Button>
                            </Col>
                            <Col xs={3} sm={0} md={1} lg={2} xl={3} xxl={2}/>
                            <Col  xs={21} sm={8} md={8} lg={5} xl={4} >
                              <Button
                                data-cy="btn-submit"
                                htmlType="submit"
                                type="primary"
                                style={{
                                  width:"120px",
                                }}
                                >
                                {i18n.c.confirm}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Spin>
                    )}
                    />
                    )}
              </LangContext.Consumer>
              </div>  
            </Col>
          </Row>
      </div>
    );
  }
}

import { createTypes, createAction } from "lib/action";

const CREATE_TRANSPORTATION_RATING = createTypes("transportationRating", "cerate");

const createTransportationrating = {
  request: (data, loading) =>
      createAction(CREATE_TRANSPORTATION_RATING.REQUEST, { data, loading}),
  success: res => createAction(CREATE_TRANSPORTATION_RATING.SUCCESS, { res }),
      failure: () => createAction(CREATE_TRANSPORTATION_RATING.FAILURE)
};

export{
    createTransportationrating,
    CREATE_TRANSPORTATION_RATING,
};
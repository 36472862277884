import React, { Fragment } from "react";
import { Button, Form, Input, Select, Drawer } from "antd";
import { translate } from "../../../../../../lib/helper";

const { Option } = Select;

const IndexFilterDrawer = (props) => {
  const onInputChange = () => (e) => {
    props.onChange(e.target.name, e.target.value);
  };

  const onSelectChange = (name) => (value) => {
    props.onChange(name, value);
  };

  const { value } = props;

  return (
    <Drawer
      title={translate("filter", "f")}
      width={520}
      placement="right"
      onClose={props.onClose}
      maskClosable={true}
      visible={props.visible}
      style={{
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: 53,
      }}
    >
      <Form>
        <Form.Item label={translate("company_name", "worksheet")}>
          <Input
          data-cy="company_name"
            name="company_name"
            value={value.company_name}
            onChange={onInputChange()}
          />
        </Form.Item>

        <Form.Item label={translate("md_vehicle_type", "m")}>
          <Select
            data-cy="vehicle_type"
            name="vehicle_type"
            value={value.vehicle_type}
            onChange={onSelectChange("vehicle_type")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.vehicleType.map((val) => (
              <Option value={val.id} key={val.id}>
                {translate(val.name, "vehicle_type")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Fragment>
              {translate("service_area", "veh")}
              &nbsp;1
            </Fragment>
          }
        >
          <Select
            data-cy="area_1"
            name="area_1"
            value={value.area_1}
            onChange={onSelectChange("area_1")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.province.map((val) => (
              <Option value={val.id} key={val.id}>
                {props.langBtn == "th" ? val.name : val.name_eng}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Fragment>
              {translate("service_area", "veh")}
              &nbsp;2
            </Fragment>
          }
        >
          <Select
            data-cy="area_2"
            name="area_2"
            value={value.area_2}
            onChange={onSelectChange("area_2")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.province.map((val) => (
              <Option value={val.id} key={val.id}>
                {props.langBtn == "th" ? val.name : val.name_eng}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={translate("rateServiceQuality", "r")}>
          <Select
            data-cy="rating"
            name="rating"
            value={value.rating}
            onChange={onSelectChange("rating")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {[1, 2, 3, 4, 5].map((val) => (
              <Option value={val} key={val}>
                {val} {translate("star", "s")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button
          data-cy="btn-go-back"
          style={{
            marginRight: 8,
          }}
          onClick={props.onClose}
        >
          {translate("cancel", "c")}
        </Button>

        <Button type="primary" onClick={props.onFilterButtonClick} data-cy="btn-submit">
          {translate("apply", "a")}
        </Button>
      </Form>
    </Drawer>
  );
};

export default IndexFilterDrawer;

import React from "react";
import { Row, Col, Tooltip } from "antd";

const IconMarker = ({ title, lat, lng, zoomLevel, image, isshowetitle }) => (
  <Row>
    <Col span={24}>
      {
        isshowetitle ?
        (
          <Tooltip
            title={title}
            placement="top"
            arrowPointAtCenter
          >
              <div
                  style={{
                    position: "relative",
                    alignItems: "center",
                    zIndex: -2,
                    display: "inline-flex",
                    transform: "translate(-50%, -100%)",
                  }}
              >
                <img 
                  src={image}
                  style={{
                    zIndex: -2,
                  }}
                />
              </div>
            </Tooltip>
        ) : (
          <div
              style={{
                position: "relative",
                alignItems: "center",
                zIndex: -2,
                display: "inline-flex",
                transform: "translate(-50%, -100%)",
              }}
          >
            <img 
              src={image}
              style={{
                zIndex: -2,
              }}
            />
          </div>
        )
      }
    </Col>
  </Row>
);

export default IconMarker;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Alert, Spin, Modal, Button } from "antd";
import LangContext from "modules/shared/context/langContext";
import { messaging, firebase } from "../../../lib/fcm/fcm_init";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeDashboard from "modules/master-data/companyprofile/components/Index";
import {
  PureBackgroundStyledContent,
  StyledLogo,
  StyledDiv,
  StyledBarDiv,
  StyledSignUp,
} from "../../../styled/common-styled";
import { checkLogin, checkLoginNotification } from "../actions";
import Form from "./Form";
import Signup from "./SignupForm";
import Forgotpassword from "./Forgotpassword";
import Usertype from "./Usertype";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      token: "",
      notifications: [],
    };
  }

  componentDidMount() {
    // if (messaging) {
    //   // รับ Token Notification
    //   messaging
    //     .getToken()
    //     .then((token) => {
    //       console.log("Token refreshed.");
    //       console.log(token);
    //       this.setToken(token);
    //     })
    //     .catch((err) => {
    //       console.log("Unable to retrieve refreshed token ", err);
    //       // showToken("Unable to retrieve refreshed token ", err);
    //     });
    // }
  }

  onSubmit = (values) => {
    //console.log("dddddd");
    let self = this;
    let pathhost =
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port;
    if (/^\d+$/.test(values.email)) {
      //เบอร์โทร
      self.setState({ valueSignUp: values });
      self.showModal();
    } else {
      self.setState({ loading: true });
      firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then((u) => {
          //console.log(u);
          var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url:
              pathhost +
              "/verifyemail/" +
              firebase.auth().currentUser.email +
              "/" +
              values.password +
              "/" +
              values.company,
            // This must be true.
            handleCodeInApp: true,
          };

          firebase
            .auth()
            .currentUser.sendEmailVerification(actionCodeSettings)
            .then(function() {
              self.setState({
                loading: false,
              });
              Modal.info({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.send_verify_email}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            })
            .catch(function(error) {
              let message = "";
              // if (error.code === "auth/email-already-in-use") {
              //   message = "Email address have already exist";
              // } else if (error.code === "auth/weak-password") {
              //   message = "Password is not strong enough";
              // } else {
              message = error.code;
              //}

              Modal.error({
                content: message,
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            });
        })
        .catch((error) => {
          let message = "";
          // if (error.code === "auth/email-already-in-use") {
          //   message = "Email address have already exist";
          // } else if (error.code === "auth/weak-password") {
          //   message = "Password is not strong enough";
          // } else {
          message = error.code;
          //}

          Modal.error({
            content: message,
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
        });
    }
  };

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  checkLogin = (values) => {
    let self = this;
    this.setState({
      username: values.username,
    });

    this.props.checkLogin(values, function(res) {
      // if (res.status == "success") {
      //   self.props.checkLoginNotification({
      //     username: values.username,
      //     password: "",
      //     token: self.state.token,
      //   });
      // }
    });
  };
  render() {
    console.log("window.innerWidth", window.innerWidth)
    let alertMsg = "";
    if (typeof this.props.location !== "undefined") {
      if (
        typeof this.props.location.data != "undefined" &&
        typeof this.props.location.data.msg != ""
      ) {
        alertMsg = (
          <Alert
            message="เรียนผู้ใช้บริการ"
            description={this.props.location.data.msg}
            type="error"
            showIcon
            closable
            style={{ margin: "0px 0px 15px 0px" }}
          />
        );
      }
    }

    return this.props.auth.done ? (
      <PureBackgroundStyledContent>
        <HomeDashboard />
      </PureBackgroundStyledContent>
    ) : (
      <Router>
        <Fragment>
          <Spin
            spinning={this.props.auth.loading}
            size="large"
            style={{ height: "100%" }}
          >
            <Spin
              spinning={this.state.loading}
              size="large"
              style={{ height: "100%" }}
            >
              <StyledBarDiv>
                { window.innerWidth <= 500 ? 
                  <Button type="primary">เข้าสู่ระบบ</Button>
                : <Form
                  auth={this.props.auth}
                  onSubmit={this.checkLogin}
                  firebase={firebase}
                />
                }
              </StyledBarDiv>
              <StyledDiv>
                <Col xs={3} sm={2} md={2} lg={2} xl={2} />
                <Col xs={21} sm={10} md={10} lg={10} xl={10}>
                  <StyledLogo src="/img/backhaul/bg.png" />

                </Col>
                <Col xs={3} sm={2} md={2} lg={2} xl={2} />
                <Col xs={21} sm={10} md={10} lg={10} xl={10}>
                  <StyledSignUp>
                    <Signup
                      auth={this.props.auth}
                      onSubmit={this.onSubmit}
                      firebase={firebase}
                      valuesignup={this.state.valueSignUp}
                    />
                    <Route
                      exact
                      path="/forgotpassword"
                      component={Forgotpassword}
                    />

                    <Route exact path="/usertype" component={Usertype} />
                  </StyledSignUp>
                </Col>
              </StyledDiv>
            </Spin>
          </Spin>
        </Fragment>
      </Router>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    checkLogin: checkLogin.request,
    checkLoginNotification: checkLoginNotification.request,
  }
)(Login);

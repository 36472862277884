import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import {
  Button,
  Table,
  Row,
  Col,
  Pagination,
  Spin,
  Badge,
  Popconfirm,
  Tooltip,
  Icon,
  Menu,
  Dropdown,
  Modal,
  Divider,
} from "antd";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import axios from "axios";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import IndexFilterDrawer from "./IndexFilterDrawer";
import LangContext from "modules/shared/context/langContext";
import { loadMasterDataPlace, removeLists } from "../actions";
import {
  checkInsidePolygon,
} from "../../../../lib/helper";
import LocationInfoMarker from "../../../shared/components/map-assets/Marker/LocationInfoMarker";
import { CustomMap } from "./CustomMap";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const ButtonGroup = Button.Group;
const { confirm } = Modal;

class Index extends Component {
  constructor(props) {
    super(props);
    this.defaultMapValue = {
      center: {
          lat: 13.729852,
          lng: 100.559484
      },
      zoom: 5
  };
    this.state = {
      isFilter: false,
      filterVisible: false,
      expand: false,
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      companyID: "",
      visible: false,
      orderBy: "locations.id",
      orderType: "desc",
      filterObj: {
        FilterObjFacilityName: "",
        FilterObjLocationCode: "",
        FilterObjLatitude: "",
        FilterObjLongitude: "",
        FilterObjDescription: "",
        FilterObjAddress: "",
        FilteObjTypeLocation: ""
      },
      panelWidth: 575,
      latest_config_change: new Date().getTime(),
      map: null,
      maps: null,
      mapAPI: { trafficLayer: null, locationLayer: null },
      showLocationLayer: false,
      showTrafficLayer: false,
      showPOI: false,
      showDistance: false,
      showArea: false,
      width: "0px",
      filterVisible: false,
      distanceMarker: [],
      distancePolyline: [],
      areaPolyline: [],
      placeMarker: [],
      poiMarker: null,
      circle: null,
      areaCenterMarker: null,
      descriptionBoxHeader: "",
      descriptionBoxDetail: "",
      descriptionBoxDrawerWidth: "0px",
    };

    this.columns = [
      // {
      //   title: <LangContext.Consumer>{i18n => i18n.n.no}</LangContext.Consumer>,
      //   dataIndex: "rowNumber",
      //   width: 60,
      //   render(text, record, index) {
      //     return {
      //       props: {
      //         style: columnStyle
      //       },
      //       children: <div>{text}</div>
      //     };
      //   }
      // },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.f.facilityName}
          </LangContext.Consumer>
        ),
        dataIndex: "location_name",
        key: "location_name",
        sorter: true,
        width: 180,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      // {
      //   title: (
      //     <LangContext.Consumer>
      //       {i18n => i18n.l.locationCode}
      //     </LangContext.Consumer>
      //   ),
      //   dataIndex: "location_code",
      //   key: "location_code",
      //   width: 180,
      //   sorter: true,
      //   render(text, record, index) {
      //     return {
      //       props: {
      //         style: columnStyle
      //       },
      //       children: <div>{text}</div>
      //     };
      //   }
      // },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.a.address}
          </LangContext.Consumer>
        ),
        dataIndex: "location_address",
        key: "location_address",
        sorter: true,
        ellipsis: true,

        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.descrip}
          </LangContext.Consumer>
        ),
        dataIndex: "location_description",
        key: "location_description",
        ellipsis: true,
        sorter: true,

        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "Action_btn",
        width: 90,
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "location",
                "can_view"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.detail}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                    data-cy={`btn-view-${record.location_id}`}
                    to={`/location/detail/${record.location_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="file-text" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "location",
                "can_edit"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                    data-cy={`btn-edit-${record.location_id}`}
                    to={`/location/edit/${record.location_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "location",
                "can_delete"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.delete}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Popconfirm
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                      </LangContext.Consumer>
                    }
                    okText={
                      <LangContext.Consumer>
                        {i18n => i18n.o.okay}
                      </LangContext.Consumer>
                    }
                    cancelText={
                      <LangContext.Consumer>
                        {i18n => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                    onConfirm={() => {
                      let self = this;
                      self.deleteLocation(
                        record.location_id,
                        this.props.auth.accessToken
                      );
                    }}
                  >
                    <a
                      data-cy={`btn-del-${record.location_id}`}
                      href="javascript:;"
                      style={{ padding: "0px 5px 0px 5px" }}
                    >
                      <Icon type="delete" />
                    </a>
                  </Popconfirm>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.loadData(true);
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  loadData = (loading) => {
    this.props.loadMasterDataPlace(
      {
        companyID: this.props.auth.profile.company_id,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      loading
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "locations.id",
        orderType: "asc",
      });
      orderBy = "locations.id";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadMasterDataPlace(
      {
        companyID: this.props.auth.profile.company_id,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: orderBy,
        orderType: orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadMasterDataPlace(
      {
        companyID: this.props.auth.profile.company_id,
        searchName: this.state.searchName,
        page: page,
        pageSize: pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
      },
      true
    );
  };

  onFilterButtonClick = (e) => {
    this.loadData(true);
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  handleFilterObjFacilityName = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjFacilityName: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjLocationCode = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjLocationCode: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjLatitude = (e) => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjLatitude: e.target.value },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjLongitude = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjLongitude: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjDescription = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjDescription: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjAddress = (e) => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjAddress: e.target.value },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilteObjTypeLocation = (val) => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilteObjTypeLocation: val },
      isFilter: val != null && val != "-",
    });
  };

  deleteLocation = (id, accessToken) => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/location/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(function(response) {
        self.loadData(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleApiLoaded = (map, maps) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();

    this.setState({
      map: map,
      maps: maps,
      mapAPI: { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() }
    });
  };

  handleClearAllToolDrawing = () => {
    this.clearPOIMarker();
    this.clearDistanceMarker();
    this.clearAreaMarker();
    this.clearPlaceMarker();
  };

  clearPlaceMarker = () => {
    this.setState({
      placeMarker: []
    });
  };

  clearPOIMarker = () => {
    if (this.state.poiMarker != null) {
      this.state.poiMarker.setMap(null);
      this.handleDescriptionBoxDrawerClose();
    }
    this.setState({
      showPOI: false,
      poiMarker: null,
      descriptionBoxHeader: "",
      descriptionBoxDetail: ""
    });
    this.state.maps.event.clearListeners(this.state.map, "click");
  };

  clearDistanceMarker = () => {
    if (this.state.distanceMarker != []) {
      this.state.distanceMarker.map((value, index) => {
        value.setMap(null);
      });
    }

    if (this.state.distancePolyline != []) {
      this.state.distancePolyline.map((value, index) => {
        value.setMap(null);
      });
    }

    this.handleDescriptionBoxDrawerClose();
    this.state.maps.event.clearListeners(this.state.map, "click");

    this.setState({
      showDistance: false,
      distanceMarker: [],
      distancePolyline: [],
      descriptionBoxHeader: "",
      descriptionBoxDetail: ""
    });
  };

  clearAreaMarker = () => {
    if (this.state.areaPolyline != []) {
      this.state.areaPolyline.map((value, index) => {
        value.setMap(null);
      });
    }

    this.handleDescriptionBoxDrawerClose();
    this.state.maps.event.clearListeners(this.state.map, "click");

    this.setState({
      showArea: false,
      areaPolyline: [],
      descriptionBoxHeader: "",
      descriptionBoxDetail: ""
    });
  };

  handlePinPointOfInterest = e => {
    let self = this;
    let poiMarker = null;
    if (this.state.showPOI) {
      this.clearPOIMarker();
    } else {
      this.setState({ showPOI: true, showDistance: false });
      this.clearDistanceMarker();
      this.clearAreaMarker();
    }

    this.state.maps.event.addListener(this.state.map, "click", function(event) {
      if (self.state.showPOI) {
        if (self.state.poiMarker != null) self.state.poiMarker.setMap(null);

        poiMarker = new self.state.maps.Marker({
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==",
          position: event.latLng,
          map: self.state.map
        });

        self.setState({ poiMarker: poiMarker });

        let lat = event.latLng.lat();
        let lng = event.latLng.lng();

        fetch(
          `https://api.longdo.com/map/services/address?lon=${lng}&lat=${lat}&nopostcode=1&noelevation=1&noroad=1&noaoi=1&nowater=1&key=823962a623a584cb9f7e3475fe3c90b2`
        )
          .then(response => response.json())
          .then(data => {
            try {
              let { subdistrict, district, province } = data;
              self.setState({
                descriptionBoxDrawerWidth: "300px",
                descriptionBoxHeader: `${subdistrict} ${district} ${province}`
              });
            } catch (ex) {
              self.setState({
                descriptionBoxDrawerWidth: "300px",
                descriptionBoxHeader: `Cannot get location Infomation`
              });
              console.log(ex);
            }
          });
      }
    });
  };

  handleFindDistance = e => {
    if (this.state.showDistance) {
      this.clearDistanceMarker();
    } else {
      this.setState({ showDistance: true, showPOI: false });
      this.clearPOIMarker();
      this.clearAreaMarker();
    }

    let poly = new this.state.maps.Polyline({
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 4
          },
          offset: "0",
          repeat: "20px"
        }
      ]
    });
    poly.setMap(this.state.map);

    let self = this;

    this.state.maps.event.addListener(this.state.map, "click", function(event) {
      if (self.state.showDistance) {
        let path = poly.getPath();
        path.push(event.latLng);

        let lengthInMeters = self.state.maps.geometry.spherical.computeLength(
          path
        );

        let marker = new self.state.maps.Marker({
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADMUlEQVRoge2Zv2sUURDHP3McwUpEDsuwlVhYRNNYb+wtrFLmf7gUIX+Cy5G/wb9AJGDldso1IlgpiCxBrUKwEglhx+Leu3v3Y293dt+RFBl4vO/dvZ03s/N9M7N7glGS99lzRF8CfRBA3S91WNznSlyi8q7YG76x2CP1SwLj8+wZ8AHV3uRKAVVqsU1eFOnwbdPFPaPyFOghwtSwOqwAOpmbYNizGGRzQOmh6jGNsF1MNvVNqkNKiBrxop4a3FCsEXBzYFgd1uDaJtgoxgi4HaZ3SkP8FeELSjm1xPuznHgege7M6fRYbWGwOaChFQTW8RuR3SId/m2iJsmzPiqfER4vZ1tbGGwUCpLMAj5rajxAkQ6vEL6v/HGjEaCCQm2yja8Ti4f4WijUIntM6sSyquuhUEwxRsBaid1dkln6E5kVrlbK/LxIz2ZipJDOQj/H3Rah8LrmiiPmm2GkUETOSHD6lRk27tGOQn6eq8wtJTR+45X4lkKLum4MhXiY5NmDpiqSPLuLsjPTd+0U4j7wLcmzHxUXThc6vI0wgO4UMnaja8N7D3i69O20E5UVXWnQcnt8TRRa09+v4PlabJNYFFqDxYZvXBayRsG4h7Gdpl3R0gDU4Y32QvCzWy1o9DT/y6LR+lD/Gjg1XjOb6zA6BkYW9ebbmeTZANVPwLbtytrXkBcIT4r08Myi1ZxGi3R4jsg+IpeTXqbpYB0uETmwGt/KAefER+C40eJmteIEaPw+NJRWDjgZAaeosnbgZ1ZjYQxyVKTDVkZ0ai+T/NUApMV58KIXILtFOiza2tAlAhTp4TmwD1xVLqqkDSXIQRfjoaMDMD0PR3YKcWL5H6BKOjvgZITIqSELjYGjGBtHe8RK8mwALJ8H30JP03533ocSKwKT+gD7qF6uoVAJdOZ9KNEcAHceRI4rKYScgHTmfShRHQBAXX2YffZgjNA631fJJt5uruqXLtz/B0XsveJHgKV+qUTi8j6UjTgAYb+k0Xkfiv2JzCYjkF5s3t/KTZJYWWjLjb4b/mz5uQzmKzcu3egkMRy4w+StnDUhlMAf4F+XzWPWgS0mTtRFwEeh890H+A8e4J/aNz46/gAAAABJRU5ErkJggg==",
          position: event.latLng,
          title: "#" + path.getLength(),
          map: self.state.map
        });

        self.setState({
          distanceMarker: [...self.state.distanceMarker, marker],
          distancePolyline: [...self.state.distancePolyline, poly],
          descriptionBoxDrawerWidth: "300px",
          descriptionBoxHeader: `ระยะทาง : ${Number(
            lengthInMeters / 1000
          ).toFixed(2)} Km.`
        });
      }
    });
  };

  handleFindArea = e => {
    if (this.state.showArea) {
      this.clearAreaMarker();
    } else {
      this.setState({ showArea: true, showPOI: false });
      this.clearPOIMarker();
      this.clearDistanceMarker();
    }

    new this.state.maps.Polyline();

    let self = this;
    let polygonCoords = [];
    let polygonLayer = null;

    this.state.maps.event.addListener(this.state.map, "click", function(event) {
      if (self.state.showArea) {
        polygonCoords.push({
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        });

        // if (boundListpolygonCoords > 2) {
        if (polygonLayer != null) polygonLayer.setMap(null);
        polygonLayer = new self.state.maps.Polygon({
          path: polygonCoords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          editable: true,
          geodesic: true
        });

        self.state.maps.event.addListener(
          polygonLayer.getPath(),
          "set_at",
          function() {
            subFnFindArea();
          }
        );

        subFnFindArea();
        function subFnFindArea() {
          let lengthInMeters = self.state.maps.geometry.spherical.computeArea(
            polygonLayer.getPath()
          );
          lengthInMeters > 0
            ? (lengthInMeters = (lengthInMeters / 1000).toFixed(2))
            : (lengthInMeters = lengthInMeters);

          polygonLayer.setMap(self.state.map);
          let vehicleAmount = 0;
          for (
            let i = 0;
              i < self.props.placeMasterData.lists.length;
            i++
          ) {
            if (
              checkInsidePolygon(
                {
                  lat: self.props.placeMasterData.lists[i].location_lat,
                  lng: self.props.placeMasterData.lists[i].location_lng
                },
                polygonCoords
              )
            ) {
              vehicleAmount++;
            }
          }

          self.setState({
            areaPolyline: [...self.state.areaPolyline, polygonLayer],
            descriptionBoxDrawerWidth: "300px",
            descriptionBoxHeader: `พื้นที่ : ${Number(
              lengthInMeters / 1000
            ).toFixed(2)} Square km.`,
            descriptionBoxDetail: `จำนวนสถานที่ : ${vehicleAmount} ที่`
          });
        }
      }
    });
  };

  handleMapTypeSatellite = e => {
    this.state.map.setMapTypeId("satellite");
  };

  handleMapTypeTerrain = e => {
    this.state.map.setMapTypeId("terrain");
  };

  handelPoiDrawerOpen = () => {
    this.setState({
      descriptionBoxDrawerWidth: "300px"
    });
  };

  handleDescriptionBoxDrawerClose = () => {
    this.setState({
      descriptionBoxDrawerWidth: "0px"
    });
  };

  // เเสดงการจราจร
  handleShowTrafficLayer = e => {
      if (this.state.showTrafficLayer) {
        this.state.mapAPI.trafficLayer.setMap(null);
        this.setState({
          showTrafficLayer: false
        });
      } else {
        this.state.mapAPI.trafficLayer.setMap(this.state.map);
        this.setState({
          showTrafficLayer: true
        });
      }
  };
  // เเสดงการจราจร

  handleZoomFocusIncrease = e => {
      let zoomLevel = this.state.map.getZoom();
      zoomLevel++;
      this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handleZoomFocusDecrease = e => {
      let zoomLevel = this.state.map.getZoom();
      zoomLevel--;
      this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handleCenterChange = (lat, lng) => {
    this.state.map.setCenter({
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      });
    this.state.map.setZoom(20);
  }

  render() {
    const { total, loading } = this.props.placeMasterData;
    const columns = this.columns.map(col => col);

    const mapTools = (
      <Menu data-cy="menu-map-tool">
        <Menu.Item key="3" onClick={this.handlePinPointOfInterest}>
          <Icon type="pushpin" />
          <LangContext.Consumer>{i18n => i18n.p.pinDown}</LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="4" onClick={this.handleFindDistance}>
          <Icon type="highlight" />
          <LangContext.Consumer>
            {i18n => i18n.f.findDistance}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="5" onClick={this.handleFindArea}>
          <Icon type="border" />
          <LangContext.Consumer>
            {i18n => i18n.f.findSpace}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="6" onClick={this.handleClearAllToolDrawing}>
          <Icon type="close" />
          <LangContext.Consumer>{i18n => i18n.c.clearUp}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    const mapType = (
      <Menu data-cy="menu-map-type">
        <Menu.Item key="1" onClick={this.handleMapTypeSatellite}>
          <LangContext.Consumer>
            {i18n => i18n.s.satellite}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item key="2" onClick={this.handleMapTypeTerrain}>
          <LangContext.Consumer>{i18n => i18n.m.map}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    return (
      <AuthorizeComponent matching_name="location">

        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
              <Col span={24}>
                <Divider orientation="left">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.location}
                    </LangContext.Consumer>
                </Divider>
              </Col>
            </Row>

          <Row gutter={24}>
            <Col span={10}>
              <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
                <Col sm={12} md={17} lg={17} xl={19}>
                  <Link to="/location/create" data-cy="btn-create">
                    <Button type="primary" icon="plus" size="small">
                      <span></span>
                      <LangContext.Consumer>
                        {i18n => i18n.n.new}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                </Col>
                <Col sm={12} md={7} lg={7} xl={5}>
                  <Badge dot={this.state.isFilter} align="right">
                    <Button
                      data-cy="btn-filter"
                      icon="filter"
                      size="small"
                      align="right"
                      onClick={this.showFilterDrawer}
                    >
                      <span></span>
                      <LangContext.Consumer>
                        {i18n => i18n.f.filter}
                      </LangContext.Consumer>
                    </Button>
                  </Badge>

                  <IndexFilterDrawer
                    onClose={this.onFilterDrawerClose}
                    visible={this.state.filterVisible}
                    onFilterButtonClick={this.onFilterButtonClick}
                    handleFilterObjFacilityName={this.handleFilterObjFacilityName}
                    handleFilterObjLocationCode={this.handleFilterObjLocationCode}
                    handleFilterObjLatitude={this.handleFilterObjLatitude}
                    handleFilterObjLongitude={this.handleFilterObjLongitude}
                    handleFilterObjDescription={this.handleFilterObjDescription}
                    handleFilterObjAddress={this.handleFilterObjAddress}
                    handleFilteObjTypeLocation={this.handleFilteObjTypeLocation}
                  />
                </Col>
              </Row>

              <Row>
                <Spin spinning={loading}>
                  <Table
                    data-cy="data-table"
                    bordered
                    scroll={{ x: 500 }}
                    rowKey="location_id"
                    columns={columns}
                    dataSource={this.props.placeMasterData.lists}
                    // size="small"
                    pagination={false}
                    onChange={this.handleTableChange}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                          this.handleCenterChange(
                            record.location_lat,
                            record.location_lng
                          );
                        }
                      };
                    }}
                  />
                </Spin>
              </Row>
              <Row type="flex" justify="end" style={{ padding: "15px" }}>
                <Col span={24} align="right">
                  <Pagination
                    data-cy="pagination"
                    defaultPageSize={this.state.pageSize}
                    size="small"
                    current={this.state.currentPage}
                    total={total}
                    onChange={this.handlePageChange}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={14}>
                <div
                    style={{
                        position: "absolute",
                        marginTop: "4px",
                        top: "0px",
                        right: "0px",
                        zIndex: "12"
                    }}
                >
                  <ButtonGroup
                    data-cy="btn-group-map-tool"
                    style={{
                      marginRight: "4px"
                    }}
                  >
                    <Dropdown className="edge-element" overlay={mapTools}>
                      <Button data-cy="btn-tool">
                        <LangContext.Consumer>
                          {i18n => i18n.t.tools}
                        </LangContext.Consumer>{" "}
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                    <Dropdown className="edge-element" overlay={mapType}>
                      <Button data-cy="btn-map-view">
                        <LangContext.Consumer>
                          {i18n => i18n.m.mapView}
                        </LangContext.Consumer>{" "}
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </ButtonGroup>

                    <div
                        className="edge-btn-group"
                        style={{
                            position: "absolute",
                            marginTop: "20%",
                            right: "0px",
                            zIndex: "12"
                        }}
                    >
                        <Tooltip placement="left" title={<LangContext.Consumer>{i18n=>i18n.s.showTraffic}</LangContext.Consumer>}>
                            <button data-cy="btn-show-traffic" onClick={this.handleShowTrafficLayer}>
                                <Icon type="swap" />
                            </button>
                        </Tooltip>

                        <Tooltip placement="left" title={<LangContext.Consumer>{i18n=>i18n.z.zoomIn}</LangContext.Consumer>}>
                            <button data-cy="btn-zoom-in" onClick={this.handleZoomFocusIncrease}>
                                <Icon type="zoom-in" />
                            </button>
                        </Tooltip>

                        <Tooltip placement="left" title={<LangContext.Consumer>{i18n=>i18n.z.zoomOut}</LangContext.Consumer>}>
                            <button data-cy="btn-zoom-out" onClick={this.handleZoomFocusDecrease}>
                                <Icon type="zoom-out" />
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <div
                  style={{
                    backgroundColor: "white",
                    width: this.state.descriptionBoxDrawerWidth,
                    right: 0,
                    top: 0,
                    height: "250px",
                    padding: "10px 0px 0px 0px",
                    position: "fixed",
                    zIndex: "1",
                    marginLeft: "775px",
                    marginTop: "49vh",
                    overflowX: "hidden",
                    transition: "0.5s"
                  }}
                >
                  <Row>
                    <Col span={24} align="left">
                      <a
                        data-cy="btn-close"
                        href="javascript:;"
                        onClick={this.handleDescriptionBoxDrawerClose}
                        style={{
                          margin: "0px 12px 0px 12px",
                          zIndex: "1"
                        }}
                      >
                        <Icon type="close" />
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} align="center">
                      <img
                        style={{
                          height: "60px",
                          padding: "10px 15px 0px 15px"
                        }}
                        src={
                          this.state.showDistance
                            ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADMUlEQVRoge2Zv2sUURDHP3McwUpEDsuwlVhYRNNYb+wtrFLmf7gUIX+Cy5G/wb9AJGDldso1IlgpiCxBrUKwEglhx+Leu3v3Y293dt+RFBl4vO/dvZ03s/N9M7N7glGS99lzRF8CfRBA3S91WNznSlyi8q7YG76x2CP1SwLj8+wZ8AHV3uRKAVVqsU1eFOnwbdPFPaPyFOghwtSwOqwAOpmbYNizGGRzQOmh6jGNsF1MNvVNqkNKiBrxop4a3FCsEXBzYFgd1uDaJtgoxgi4HaZ3SkP8FeELSjm1xPuznHgege7M6fRYbWGwOaChFQTW8RuR3SId/m2iJsmzPiqfER4vZ1tbGGwUCpLMAj5rajxAkQ6vEL6v/HGjEaCCQm2yja8Ti4f4WijUIntM6sSyquuhUEwxRsBaid1dkln6E5kVrlbK/LxIz2ZipJDOQj/H3Rah8LrmiiPmm2GkUETOSHD6lRk27tGOQn6eq8wtJTR+45X4lkKLum4MhXiY5NmDpiqSPLuLsjPTd+0U4j7wLcmzHxUXThc6vI0wgO4UMnaja8N7D3i69O20E5UVXWnQcnt8TRRa09+v4PlabJNYFFqDxYZvXBayRsG4h7Gdpl3R0gDU4Y32QvCzWy1o9DT/y6LR+lD/Gjg1XjOb6zA6BkYW9ebbmeTZANVPwLbtytrXkBcIT4r08Myi1ZxGi3R4jsg+IpeTXqbpYB0uETmwGt/KAefER+C40eJmteIEaPw+NJRWDjgZAaeosnbgZ1ZjYQxyVKTDVkZ0ai+T/NUApMV58KIXILtFOiza2tAlAhTp4TmwD1xVLqqkDSXIQRfjoaMDMD0PR3YKcWL5H6BKOjvgZITIqSELjYGjGBtHe8RK8mwALJ8H30JP03533ocSKwKT+gD7qF6uoVAJdOZ9KNEcAHceRI4rKYScgHTmfShRHQBAXX2YffZgjNA631fJJt5uruqXLtz/B0XsveJHgKV+qUTi8j6UjTgAYb+k0Xkfiv2JzCYjkF5s3t/KTZJYWWjLjb4b/mz5uQzmKzcu3egkMRy4w+StnDUhlMAf4F+XzWPWgS0mTtRFwEeh890H+A8e4J/aNz46/gAAAABJRU5ErkJggg=="
                            : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg=="
                        }
                      />
                    </Col>
                    <Col
                      span={24}
                      align="center"
                      style={{
                        padding: "0px 6px 0px 6px"
                      }}
                    >
                      <Divider />
                      <h2>{this.state.descriptionBoxHeader}</h2>
                      <h2>{this.state.descriptionBoxDetail}</h2>
                    </Col>
                  </Row>
                </div>

                <CustomMap
                    // loadedDateTime={this.props.monitoringState.loadedDateTime}
                    bootstrapURLKeys={{
                        key: this.props.auth.profile.google_map_key,
                        libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                        ","
                        ),
                        region: "thailand",
                        language: "th"
                    }}
                    defaultCenter={this.defaultMapValue.center}
                    defaultZoom={this.defaultMapValue.zoom}
                    style={{
                        width: "100%",
                        minHeight: `${window.innerHeight - 45}px`,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                        this.handleApiLoaded(map, maps)
                    }
                    options={{
                        mapTypeControl: false,
                        fullscreenControl: false,
                        // gestureHandling: 'none',
                        zoomControl: false,
                        maxZoom: 19
                    }}
                    onChange={this.handleMapChange}
                >
                    {
                        this.props.placeMasterData.lists &&
                        this.props.placeMasterData.lists.map(value => (
                            value.location_lat != null && value.location_lng != null?
                            <LocationInfoMarker
                                key={value.location_id}
                                lat={value.location_lat}
                                lng={value.location_lng}
                                title={value.location_name}
                            />
                            :""
                        ))
                    }
                </CustomMap>

            </Col>

          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ placeMasterData, auth }) => ({ placeMasterData, auth }),
  {
    loadMasterDataPlace: loadMasterDataPlace.request,
    removeLists,
  }
)(Index);

import { all, put, call, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";
import { findLatLngDiff } from "./../../lib/helper";

function* loadDashcamera(action) {
  const {
    data: {
      token,
      page,
      pageSize,
      searchName,
      meterStatus,
      engineStatus,
      emergencyStatus,
      speedRange,
      companyID,
      vehicleVisibility,
      cb
    }
  } = action.payload;
  // const company_id =
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefordashcam`,
      {
        searchName,
        page,
        pageSize,
        meterStatus,
        engineStatus,
        emergencyStatus,
        speedRange,
        companyID,
        vehicleVisibility,
        cb
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb();
    yield put(actions.loadDashcamera.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDashcamera.failure());
  }
}

function* loadEmergencyCase(action) {
  const {
    data: { companyID, token, vehicleVisibility }
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getemergencycase`,
      {
        companyID,
        vehicleVisibility
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadEmergencyCase.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadEmergencyCase.failure());
  }
}

function* loadEarningWidget(action) {
  const {
    data: { companyID, token, start_at, end_at }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getearningwidgetinfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadEarningWidget.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadEarningWidget.failure());
  }
}

function* loadAccEarning(action) {
  const {
    data: { companyID, token, start_at, end_at }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccumulateearninginfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadAccEarning.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAccEarning.failure());
  }
}

function* loadBookingWidget(action) {
  const {
    data: { companyID, token, start_at, end_at }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getbookingwidgetinfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadBookingWidget.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadBookingWidget.failure());
  }
}

function* loadAccBooking(action) {
  const {
    data: { companyID, token, start_at, end_at }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getaccumulatebookinginfo?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadAccBooking.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAccBooking.failure());
  }
}

function* loadTopBookingData(action) {
  const {
    data: { companyID, token, start_at, end_at }
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/gettopinterestingbooking?company_id=${companyID}&start_at=${start_at}&end_at=${end_at}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadTopBookingData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadTopBookingData.failure());
  }
}

function* loadTrackingInitData(action) {
  const {
    searchName,
    selectedTrackingColumns,
    vehicleStatus,
    engineStatus,
    speedRange,
    timeRange,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    cb,
    previousState
  } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefortrackingmap`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        selectedTrackingColumns,
        vehicleStatus,
        engineStatus,
        speedRange,
        timeRange,
        dataTreeFilter
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    cb(res.data.markers);
    yield put(
      actions.loadTrackingInitData.success({
        markers: res.data.markers.map(ele => {
          let diff = 1;

          if (ele.current_location != null)
            diff = ele.current_location.length > 0 ? 0 : 1;
          return { ...ele, accLatLngDiff: diff };
        })
      })
    );
  } catch (err) {
    console.log(err.message);
    // console.log(err.stack);
    yield put(actions.loadTrackingInitData.failure());
  }
}

function* loadTracking(action) {
  const diffthreshold = 0.09;
  const {
    searchName,
    selectedTrackingColumns,
    vehicleStatus,
    engineStatus,
    speedRange,
    timeRange,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    cb,
    previousState
  } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclefortrackingmap`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        selectedTrackingColumns,
        vehicleStatus,
        engineStatus,
        speedRange,
        timeRange,
        dataTreeFilter
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    ///////////////////////////////////////////////////////////////////

    let responseData = res.data.markers;

    let haveCurrentLocationColumn = selectedTrackingColumns.find(
      ele => ele == "current_location"
    );

    if (typeof haveCurrentLocationColumn != "undefined") {
      let location = yield call(
        axios.post,
        // `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty?lat=${val.latest_lat}&lng=${val.latest_lng}`,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationfromthirdparty`,
        {
          companyID: companyID,
          vehicleList: responseData
            .filter(ele => {
              let previousVal = previousState.find(
                ele2 => ele2.device_id == ele.device_id
              );

              if (typeof previousVal != "undefined") {
                // let diffVal = findLatLngDiff(
                //   previousVal.latest_lat,
                //   previousVal.latest_lng,
                //   ele.latest_lat,
                //   ele.latest_lng
                // );

                if (previousVal.accLatLngDiff < diffthreshold)
                  // if (
                  //   previousVal.latest_lat == ele.latest_lat &&
                  //   previousVal.latest_lng == ele.latest_lng
                  // )
                  return false;
              } else {
                return ele.current_location.length == 0;
              }
              return ele.current_location.length == 0;
            })
            .map(ele => {
              return {
                device_id: ele.device_id,
                lat: ele.latest_lat,
                lng: ele.latest_lng
              };
            })
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      for (let index = 0; index < responseData.length; index++) {
        let val = responseData[index];
        let previousVal = previousState.find(
          ele2 => ele2.device_id == val.device_id
        );

        if (typeof previousVal != "undefined") {
          let diffVal = findLatLngDiff(
            previousVal.latest_lat,
            previousVal.latest_lng,
            val.latest_lat,
            val.latest_lng
          );
          if (previousVal.accLatLngDiff < diffthreshold) {
            responseData[index] = {
              ...val,
              current_location: previousVal.current_location,
              accLatLngDiff: previousVal.accLatLngDiff + diffVal
            };
          } else {
            if (val.current_location.length == 0) {
              let findVal = location.data.find(
                ele => ele.device_id == val.device_id
              );

              if (typeof findVal != "undefined")
                responseData[index] = {
                  ...val,
                  current_location: findVal.current_location,
                  accLatLngDiff: 0
                };
              else
                responseData[index] = {
                  ...val,
                  current_location: previousVal.current_location,
                  accLatLngDiff: previousVal.accLatLngDiff + diffVal
                };
            }
          }
        } else {
          if (val.current_location.length == 0) {
            let findVal = location.data.find(
              ele => ele.device_id == val.device_id
            );
            if (typeof findVal.current_location != "undefined")
              responseData[index] = {
                ...val,
                current_location: findVal.current_location,
                accLatLngDiff: 0
              };
            else
              responseData[index] = {
                ...val,
                current_location: [],
                accLatLngDiff: 1
              };
          }
        }
      }
    }

    ///////////////////////////////////////////////////////////////////
    cb(responseData);
    yield put(actions.loadTracking.success({ markers: responseData }));
  } catch (err) {
    console.log(err.message);
    console.log(err.stack);
    yield put(actions.loadTracking.failure());
  }
}

function* loadVehicleTypeMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicletype?vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadVehicleTypeMasterData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleTypeMasterData.failure());
  }
}

function* loadVehicleGroupMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclegroup?company_id=[${companyID}]&vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadVehicleGroupMasterData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleGroupMasterData.failure());
  }
}

function* loadVehicleMasterData(action) {
  const { companyID, vehicle_visibility, token } = action.payload.data;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicle?company_id=[${companyID}]&vehicle_visibility=[${vehicle_visibility}]`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadVehicleMasterData.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleMasterData.failure());
  }
}

function* loadAlertAlarm(action) {
  const {
    searchName,
    warningType,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token,
    isSearch,
    cb
  } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclealertalarm`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        warningType,
        dataTreeFilter,
        cb
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb();
    yield put(actions.loadAlertAlarm.success(res.data, isSearch));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAlertAlarm.failure());
  }
}

function* loadHeatMap(action) {
  const {
    searchName,
    warningType,
    eventType,
    startDateTime,
    endDateTime,
    dataTreeFilter,
    companyID,
    vehicle_visibility,
    token
  } = action.payload.data;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleheatmap`,
      {
        company_id: companyID,
        vehicle_visibility,
        searchName,
        warningType,
        eventType,
        startDateTime,
        endDateTime,
        dataTreeFilter
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadHeatMap.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadHeatMap.failure());
  }
}

export default function* watcMonitoringState() {
  yield all([
    takeLatest(actions.LOAD_DASHCAMERA.REQUEST, loadDashcamera),
    takeLatest(actions.LOAD_EMERGENCYCASE.REQUEST, loadEmergencyCase),
    takeEvery(actions.LOAD_EARNINGWIDGET.REQUEST, loadEarningWidget),
    takeEvery(actions.LOAD_ACCEARNING.REQUEST, loadAccEarning),
    takeEvery(actions.LOAD_BOOKINGWIDGET.REQUEST, loadBookingWidget),
    takeEvery(actions.LOAD_ACCBOOKING.REQUEST, loadAccBooking),
    takeEvery(actions.LOAD_TOPBOOKINGDATA.REQUEST, loadTopBookingData),
    takeEvery(actions.LOAD_TRACKING_INIT_DATA.REQUEST, loadTrackingInitData),
    takeEvery(actions.LOAD_TRACKING.REQUEST, loadTracking),
    takeEvery(actions.LOAD_ALERT_ALARM.REQUEST, loadAlertAlarm),
    takeLatest(
      actions.LOAD_VEHICLE_TYPE_MASTER_DATA_FILTER.REQUEST,
      loadVehicleTypeMasterData
    ),
    takeLatest(
      actions.LOAD_VEHICLE_GROUP_MASTER_DATA_FILTER.REQUEST,
      loadVehicleGroupMasterData
    ),
    takeLatest(
      actions.LOAD_VEHICLE_MASTER_DATA_FILTER.REQUEST,
      loadVehicleMasterData
    ),
    takeEvery(actions.LOAD_HEAT_MAP.REQUEST, loadHeatMap)
  ]);
}

export {
  loadDashcamera,
  loadEmergencyCase,
  loadEarningWidget,
  loadAccEarning,
  loadBookingWidget,
  loadAccBooking,
  loadTopBookingData,
  loadTracking,
  loadTrackingInitData,
  loadVehicleTypeMasterData,
  loadVehicleGroupMasterData,
  loadAlertAlarm,
  loadVehicleMasterData,
  loadHeatMap
};

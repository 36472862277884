import React from "react";
import { Button, Select, Form, Input, Drawer, DatePicker } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const { RangePicker } = DatePicker;
const { Option } = Select;
const IndexFilterDrawer = (props) => (
  <Drawer
    title={
      <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
    }
    // title="Filter"
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "calc(100% - 55px)",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.business.company_type}
          </LangContext.Consumer>
        }
      >
        <Select data-cy="company_type" defaultValue="all" onChange={props.handleFilterObjCompanyType}>
          <Option value="all">
            <LangContext.Consumer>
              {(i18n) => i18n.a.all}
            </LangContext.Consumer>
          </Option>
          <Option value="productowner">
            <LangContext.Consumer>
              {(i18n) => i18n.companyDetails.productowner}
            </LangContext.Consumer>
          </Option>
          <Option value="transporter">
            <LangContext.Consumer>
              {(i18n) => i18n.companyDetails.transporter}
            </LangContext.Consumer>
          </Option>
          <Option value="personal">
            <LangContext.Consumer>
              {(i18n) => i18n.companyDetails.personal}
            </LangContext.Consumer>
          </Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.verify_status}
          </LangContext.Consumer>
        }
      >
        <Select data-cy="verify" defaultValue="all" onChange={props.handleFilterObjCompanyVerify}>
          <Option value="all">
            <LangContext.Consumer>
              {(i18n) => i18n.a.all}
            </LangContext.Consumer>
          </Option>
          <Option value="false">
            <LangContext.Consumer>
              {(i18n) => i18n.v.verify_no}
            </LangContext.Consumer>
          </Option>
          <Option value="true">
            <LangContext.Consumer>
              {(i18n) => i18n.v.verify_yes}
            </LangContext.Consumer>
          </Option>
        </Select>
      </Form.Item>

      <Button
      data-cy="btn-filter-close"
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>

      <Button type="primary" onClick={props.onFilterButtonClick} data-cy="btn-filter-submit">
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);
export default IndexFilterDrawer;

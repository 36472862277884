import React from "react";
import { Switch, Route } from "react-router-dom";
import IndexVehicleVerify from "./Index";
import VehicleVerifyDetail from "./VehicleVerifyDetail";

export default () => (
  <Switch>
    <Route exact path="/vehicleverify" component={IndexVehicleVerify} />
    <Route exact path="/vehicleverify/detail/:id" component={VehicleVerifyDetail}/>
  </Switch>
);

import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { Layout as AppLayout, message } from "antd";
import loadable from "@loadable/component";
import axios from "axios";
import history from "lib/history";
import "../../../styled/common.css";
import * as authActions from "modules/auth/actions";
import * as uiActions from "modules/ui/actions";
import PrivateRoute from "./PrivateRoute";
import Header from "./Header";
import LangContext, { i18n } from "modules/shared/context/langContext";
import SliderBar from "./SliderBar";
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////                      Site Page                           ////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
import Layoutauthen from "modules/ui/components/Layoutauthen";

import ImportWorksheet from "modules/worksheets/import/components/Import";
import DownloadWorksheet from "modules/worksheets/download/components/Download";
import Worksheet from "modules/worksheets/worksheet/components/Worksheet";
import Expense from "modules/master-data/expense/components/Expense";
import LogisticGroupOwerview from "modules/master-data/logisticGroup/components/LogisticGroupOwerview";
import Vehicle from "modules/master-data/vehicle/components/VehicleOverview";
import Transportationrating from "modules/master-data/transportationrating/components/TransportationRatingOverview";
import CompanyProfile from "modules/master-data/companyprofile/components/CompanyProfileOverview";
import CompanyVerify from "modules/admin-system/companyverify/components/CompanyVerifyOwerview";
import VehicleVerify from "modules/admin-system/vehicleverify/components/VehicleVerifyOwerview";

import HomeDashboard from "modules/master-data/companyprofile/components/CompanyProfileOverview";

import {
  MyworkDashboard,
  TransportMarketDashboard,
  QuotationDashboard,
  InProgressDashboard,
} from "modules/dashboard/transport/components";

import {
  CompanyGroupDashboardProductOwner,
  InProgressDashboardProductOwner,
  AllWorkDashboardProductOwner,
} from "modules/dashboard/product-owner/components";

import Facility from "modules/master-data/facility/components/FacilityOverviwe"

// notification
const NotificationCenter = loadable(() =>
  import("modules/ui/components/NotificationCenter")
);

// master data
const Usergroup = loadable(() =>
  import("modules/master-data/usergroup/components/Usergroup")
);

const Permission = loadable(() =>
  import("modules/master-data/permission/components/Permission")
);
const Datavisibility = loadable(() =>
  import("modules/master-data/datavisibility/components/Datavisibility")
);

const RouteOverview = loadable(() =>
  import("modules/master-data/route/components/RouteOverview")
);

// const Facility = loadable(() =>
//   import("modules/master-data/facility/components/FacilityOverviwe")
// );

const DriverUser = loadable(() =>
  import("modules/master-data/deviceuser/components/DeviceUserOverview")
);

const User = loadable(() =>
  import("modules/master-data/user/components/UserOverview")
);

const Company = loadable(() =>
  import("modules/master-data/company/components/CompanyOverview")
);

const Gellery = loadable(() =>
  import("modules/master-data/gallery/components/OverView")
);

class Layout extends Component {
  state = {
    collapsed: true,
    pageReload: false,
    notiCount: 0,
    haveNewNoti: false,
    i18n: i18n.th,
    langBtn: "th",
    checkSelectLang: "",
    url: "",
    VerifyEmailModalVisible: true,
    VerifyLoading: false,
    windowHeight: "",
    VerifyEmail: {
      m_user_id: "",
      email: "",
    },
  };

  changeLang = (inputLng) => {
    this.setState({
      i18n: i18n[inputLng],
      langBtn: inputLng,
    });
    this.props.changeLanguage(inputLng);
  };

  checkPageReload() {
    if (window.performance) {
      if (
        window.performance.navigation.type == 1 &&
        history.action == "POP" &&
        history.location.pathname != "/" &&
        !history.location.pathname.includes("/center_pool/detail")
      ) {
        this.setState({ pageReload: true });
      }
    }
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  componentWillUnmount() {
    //window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    this.props.loadCredentials();
    this.checkPageReload();

    const url = new URL(window.location.href);

    this.setState({
      url: url.pathname.substring(18, -1),
    });

    //window.addEventListener("resize", this.handleResize);
  }

  setMsg = (title, msg) => this.props.setFlashMessage(title, msg);

  onRemoveCredentials = () => {
    this.props.removeCredentials();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleVerifyEmailCancel = () => {
    this.setState({
      VerifyEmailModalVisible: false,
    });
  };

  handleAddEmailWichVerifyEmail = (values) => {
    let self = this;
    let full_name =
      this.props.profile.firstname + " " + this.props.profile.lastname;

    this.setState({
      VerifyLoading: true,
    });

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/UserVerifyMail`,
        {
          m_user_id: this.props.profile.id,
          full_name: full_name,
          email: values.email,
        },
        {
          headers: { Authorization: `Bearer ${this.props.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          const { data } = response;
          if (data.status == "duplicate_email") {
            message.error("This email already exists in the system.");
            self.setState({
              VerifyLoading: false,
            });
          } else {
            message.success("create success");

            self.setState({
              VerifyEmailModalVisible: false,
              VerifyLoading: false,
            });
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const pathname = window.location.pathname;
    return (
      <Router>
        <Fragment>
          {!this.props.done ||
          pathname.indexOf("form/personal") > -1 ||
          pathname.indexOf("form/productowner") > -1 ||
          pathname.indexOf("form/transporter") > -1 ||
          pathname.indexOf("verifyemail") > -1 ? (
            <Fragment> 
              <Layoutauthen {...this.props} />
            </Fragment>
          ) : (
            <LangContext.Provider value={this.state.i18n}>
              <AppLayout>
                <SliderBar
                  collapsed={this.state.collapsed}
                  toggle={this.toggle}
                />

                <AppLayout
                  style={{
                    minHeight: `${this.state.windowHeight + 800}px`,
                  }}
                >
                  <Header
                    collapsed={this.state.collapsed}
                    toggle={this.toggle}
                    langBtn={this.state.langBtn}
                    changeLang={this.changeLang}
                    checkSelectLang={this.state.checkSelectLang}
                  />
                  <PrivateRoute
                    key="/notification"
                    path="/notification"
                    component={(props) => <NotificationCenter {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/usergroup"
                    path="/usergroup"
                    component={(props) => <Usergroup {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/location"
                    path="/location"
                    component={(props) => <Facility {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/user"
                    path="/user"
                    component={(props) => <User {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/logisticgroup"
                    path="/logisticgroup"
                    component={(props) => <LogisticGroupOwerview {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/route"
                    path="/route"
                    component={(props) => <RouteOverview {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/transportationrating/:worksheetID"
                    path="/transportationrating/:worksheetID"
                    component={(props) => <Transportationrating {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/companyprofile"
                    path="/companyprofile"
                    component={(props) => <CompanyProfile {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/companyverify"
                    path="/companyverify"
                    component={(props) => <CompanyVerify {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/vehicleverify"
                    path="/vehicleverify"
                    component={(props) => <VehicleVerify {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/vehicle"
                    path="/vehicle"
                    component={(props) => <Vehicle {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/permission"
                    path="/permission"
                    component={(props) => <Permission {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/datavisibility"
                    path="/datavisibility"
                    component={(props) => <Datavisibility {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/deviceuser"
                    path="/deviceuser"
                    component={(props) => <DriverUser {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/company"
                    path="/company"
                    component={(props) => <Company {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/gallery"
                    path="/gallery"
                    component={(props) => <Gellery {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/dashboard/mywork"
                    path="/dashboard/mywork"
                    component={(props) => <MyworkDashboard {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/dashboard/transport-market"
                    path="/dashboard/transport-market"
                    component={(props) => (
                      <TransportMarketDashboard {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/dashboard/work-quotation"
                    path="/dashboard/work-quotation"
                    component={(props) => <QuotationDashboard {...props} />}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/dashboard/work-inprogress"
                    path="/dashboard/work-inprogress"
                    component={(props) => <InProgressDashboard {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/productowner/addcompanygroup"
                    path="/productowner/addcompanygroup"
                    component={(props) => (
                      <CompanyGroupDashboardProductOwner {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/productowner/allwork"
                    path="/productowner/allwork"
                    component={(props) => (
                      <AllWorkDashboardProductOwner {...props} />
                    )}
                    done={this.props.done}
                  />
                  <PrivateRoute
                    key="/productowner/work-inprogress"
                    path="/productowner/work-inprogress"
                    component={(props) => (
                      <InProgressDashboardProductOwner {...props} />
                    )}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/route-expense"
                    path="/route-expense"
                    component={(props) => <Expense {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/worksheet"
                    path="/worksheet"
                    component={(props) => <Worksheet {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/downloadworksheet"
                    path="/downloadworksheet"
                    component={(props) => <DownloadWorksheet {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/importworksheet"
                    path="/importworksheet"
                    component={(props) => <ImportWorksheet {...props} />}
                    done={this.props.done}
                  />

                  <PrivateRoute
                    key="/"
                    path="/"
                    component={(props) => <HomeDashboard {...props} />}
                  />
                </AppLayout>
              </AppLayout>
            </LangContext.Provider>
            //</Connector>
          )}
        </Fragment>
      </Router>
    );
  }
}
//export default Layout;

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, langBtn },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  tokenExp,
  langBtn,
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: uiActions.clearFlashMessage,
  setFlashMessage: uiActions.setFlashMessage,
  changeLanguage: uiActions.changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

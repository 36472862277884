import React, { Component, Fragment } from "react";
import { Button, Form, Row, Col, Input, Radio, Spin, Steps, Icon } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";

import LangContext, { i18n } from "modules/shared/context/langContext";

class VerifyForm extends Component {
  state = { button_type: "", verify_type: "phone", txt_verifycode: true };

  componentDidMount() {
    const { firebase, setRecapcha } = this.props;
    firebase.auth().languageCode = "th";
    setRecapcha();
  }

  handleConfirmClick = (e, setFieldValue, handleSubmit) => {
    setFieldValue("button_type", "confirm", false);
    this.setState({ button_type: "confirm" }, () => handleSubmit());
  };

  handleGetVerifyCode = (e, setFieldValue, handleSubmit) => {
    setFieldValue("button_type", "getverifycode", false);
    this.setState({ button_type: "getverifycode", txt_verifycode: false }, () =>
      handleSubmit()
    );
  };

  changeVerifyType = (e, setFieldValue) => {
    const { setRecapcha } = this.props;
    setFieldValue("verifytype", e.target.value, false);
    this.setState({ verify_type: e.target.value });
    if (e.target.value === "email") {
      window.recaptchaVerifier.clear();
    } else {
      setRecapcha();
    }
  };

  render() {
    const { onSubmit, recaptcha_solve, by, phone, user_type, step } = this.props;
    const { Step } = Steps
    console.log("phone:", phone);
    const styleForm = {
      backgroundColor:"#fafbfb", 
      minHeight: "calc( 100vh - 420px)",
      borderRadius: "15px",
      padding:"5% 10% ",
      boxShadow: "10px 16px 23px -12px rgba(0, 0, 0, 0.13)",
    }
    const styleFormLabel = {
      fontSize:"12px",
      color:"#2f67dc",
    }

  const getStep = (step) => {
    let stepJSX = []
      for(let loopIndex = 1 ; loopIndex <= step ; loopIndex++)
        stepJSX = [...stepJSX , loopIndex]
    return(
      <Steps current={1} >   
        {stepJSX.map(() =>(
          <Step title="" description="" />
        ))}  
      </Steps>
    )
  }
    return (
      <div>
        <div>
          <Row>
            <Col xs={4} sm={4} md={4} lg={6} xl={7} xxl={7}/>
            <Col xs={16} sm={16} md={16} lg={14} xl={10} xxl={10}>
              <div style={styleForm}>
                <LangContext.Consumer>
                  {(i18n) => (
                    <Formik
                      //  enableReinitialize={true}
                      initialValues={{
                        verifycode: "",
                        password: "",
                        verifytype: this.state.verify_type,
                        //phone,
                      }}
                      validate={(values) => {
                        let errors = {};
                        // console.log("validate:", this.state.button_type);
                        if (this.state.button_type === "confirm") {
                          if (this.state.verify_type === "phone") {
                            if (values.verifycode === "") {
                              errors.verifycode = (
                                <LangContext.Consumer>
                                  {(i18n) => i18n.v.verify_code_require}
                                </LangContext.Consumer>
                              );
                            }
                          }

                          if (this.state.verify_type === "phone" && by !== "signup") {
                            if (values.password === "") {
                              errors.password = (
                                <LangContext.Consumer>
                                  {(i18n) => i18n.p.password_new}
                                </LangContext.Consumer>
                              );
                            }

                            if (values.password.length < 6) {
                              errors.password = (
                                <LangContext.Consumer>
                                  {(i18n) => i18n.p.please_less_than_6}
                                </LangContext.Consumer>
                              );
                            }
                          }
                        }

                        return errors;
                      }}
                      validationSchema={yup.object().shape({})}
                      onSubmit={(values) => {
                        //console.log("by:", this.props.by);
                        // console.log("submit:", values);
                        onSubmit(values);
                      }}
                      render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleFocus,
                        setFieldValue,
                        isValidating,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"center"}}>
                              <h2 style={{color:"#2f67dc",fontWeight:"bold"}}> ยืนยันตัวตน</h2>
                              <h2 style={{color:"#2f67dc"}}>กรอกเบอร์โทรศัพท์หรืออีเมล์เพื่อรับรหัส OTP</h2>
                              {/* <h1 style={{color:"#2f67dc"}}>
                                <LangContext.Consumer>
                                  {(i18n) => i18n.v.verify_signup}
                                </LangContext.Consumer>
                              </h1> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={10} sm={0} md={0} lg={0} xl={0}/>
                            <Col  xs={12} sm={24} md={24} lg={24} xl={24} style={{padding:"20px 0px"}}>
                              {getStep(step)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                            <Col xs={22} sm={18} md={18} lg={18} xl={18} style={{...styleFormLabel,padding:"20px 0px"}}>
                              <span style={{ color: "red" }}>* </span>
                              <LangContext.Consumer >
                                {(i18n) => i18n.i.info_verify_code}
                              </LangContext.Consumer>

                              <div id="recaptcha-container"></div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                            <Col xs={22} sm={12} md={12} lg={12} xl={12}>
                              <Form.Item
                                validateStatus={
                                  touched.verifytype && errors.verifytype && "error"
                                }
                                help={touched.verifytype && errors.verifytype}
                              >
                                <Radio.Group
                                  style={{ width: "100%" }}
                                  value={values.verifytype}
                                  onChange={(value) =>
                                    this.changeVerifyType(value, setFieldValue)
                                  }
                                  buttonStyle="solid"
                                >
                                  <Radio.Button
                                    data-cy="phone"
                                    value="phone"
                                    style={{ padding:"0px 10px", borderRadius: "4px" }}
                                  >
                                    <Icon type="phone" style={{marginRight:"10px"}} />
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.c.cell_phone}
                                    </LangContext.Consumer>
                                  </Radio.Button>
                                  <Radio.Button
                                    data-cy="email"
                                    value="email"
                                    style={{ padding:"0px 10px",marginLeft:"10%" }}
                                  >
                                    <Icon type="mail" style={{marginRight:"10px"}}/>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.e.email}
                                    </LangContext.Consumer>
                                  </Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                            <Col xs={16} sm={12} md={12} lg={12} xl={12} style={{padding:"10px 0px"}}>
                              {this.state.verify_type === "phone" ? (
                                <Fragment>
                                {/* <div style={{ fontSize: "12px" }}>
                                  {i18n.v.verify_code}
                                </div> */}
                                <div style={styleFormLabel}>
                                  รหัส OTP มีอายุการใช้งาน 2 นาที
                                </div>

                                <Form.Item
                                  //label={i18n.v.verify_code}
                                  validateStatus={
                                    touched.verifycode &&
                                    errors.verifycode &&
                                    "error"
                                  }
                                  help={touched.verifycode && errors.verifycode}
                                >
                                  <Input
                                    data-cy="verifycode"
                                    autoComplete="new-password"
                                    type="text"
                                    name="verifycode"
                                    values={values.verifycode}
                                    placeholder={i18n.v.verify_code_digit}
                                    disabled={this.state.txt_verifycode}
                                    // onChange={(e) =>
                                    //   setFieldValue("verifycode", e.target.value)
                                    // }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.verifycode && errors.verifycode
                                        ? "invalid"
                                        : undefined
                                    }
                                  />
                                </Form.Item>
                              </Fragment>

                                // <Form.Item
                                //   validateStatus={
                                //     touched.phone && errors.phone && "error"
                                //   }
                                //   help={touched.phone && errors.phone}
                                // >
                                //   <Input
                                //     data-cy="phone"
                                //     type="text"
                                //     name="phone"
                                //     // autocomplete="off"
                                //     placeholder={i18n.p.phone}
                                //     disabled={true}
                                //     value={phone}
                                //     onChange={handleChange}
                                //     onBlur={handleBlur}
                                //     invalid={
                                //       touched.phone && errors.phone
                                //         ? "invalid"
                                //         : undefined
                                //     }
                                //     // style={{ margin: "0px 0px 5px 0px" }}
                                //   />
                                // </Form.Item>
                              ) : (
                                ""
                              )}
                            </Col>

                            <Col xs={1} sm={1} md={1} lg={1} xl={1}/>
                            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                              {this.state.verify_type === "phone" ? (
                                <Button
                                  data-cy="btn-verify-phone"
                                  type="primary"
                                  onClick={(value) =>
                                    this.handleGetVerifyCode(
                                      value,
                                      setFieldValue,
                                      handleSubmit
                                    )
                                  }
                                  // block
                                  style={{
                                    margin: "30px 0px 0px",
                                  }}
                                  disabled={recaptcha_solve ? "" : "disabled"}
                                >
                                  {i18n.g.get_verify_code}
                                </Button>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col xs={22} sm={12} md={12} lg={12} xl={12}>
                              {this.state.verify_type === "phone" ? (
                                <Fragment>
                                  <div style={{ fontSize: "12px" }}>
                                    {i18n.v.verify_code}
                                  </div>
                                  <Form.Item
                                    //label={i18n.v.verify_code}
                                    validateStatus={
                                      touched.verifycode &&
                                      errors.verifycode &&
                                      "error"
                                    }
                                    help={touched.verifycode && errors.verifycode}
                                  >
                                    <Input
                                      data-cy="verifycode"
                                      autoComplete="new-password"
                                      type="text"
                                      name="verifycode"
                                      values={values.verifycode}
                                      placeholder={i18n.v.verify_code_digit}
                                      disabled={this.state.txt_verifycode}
                                      // onChange={(e) =>
                                      //   setFieldValue("verifycode", e.target.value)
                                      // }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.verifycode && errors.verifycode
                                          ? "invalid"
                                          : undefined
                                      }
                                    />
                                  </Form.Item>
                                </Fragment>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row> */}
                          <Row>
                            <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                            <Col xs={22} sm={12} md={12} lg={12} xl={12}>
                              {by !== "signup" ? (
                                this.state.verify_type === "phone" ? (
                                  <div>
                                    <h3 style={{color:"#2f67dc"}}>{i18n.p.password_new}</h3>
                                    <Form.Item
                                      required={true}
                                      validateStatus={
                                        touched.password && errors.password && "error"
                                      }
                                      help={touched.password && errors.password}
                                    >
                                      <Input
                                        data-cy="password"
                                        autoComplete="new-password"
                                        type="password"
                                        name="password"
                                        placeholder={i18n.p.password_new}
                                        values={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={
                                          touched.password && errors.password
                                            ? "invalid"
                                            : undefined
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                            <Col  xs={24} sm={8} md={8} lg={5} xl={4} >
                              <Button
                                data-cy="btn-signup"
                                type="default"
                                style={{
                                  width: "120px",
                                }}
                              >
                                {by === "signup" ? (
                                  <Link to={"/signup/" + user_type + "/" + by}>
                                    {i18n.b.back}
                                  </Link>
                                ) : (
                                  <Link to="/forgotpassword">{i18n.b.back}</Link>
                                )}
                              </Button>
                            </Col>
                            <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2}/>
                            <Col  xs={24} sm={8} md={8} lg={5} xl={4} >
                              <Button
                                data-cy="btn-confirm"
                                // htmlType="submit"
                                type="primary"
                                style={{
                                  width: "120px",
                                }}
                                onClick={(value) =>
                                  this.handleConfirmClick(
                                    value,
                                    setFieldValue,
                                    handleSubmit
                                  )
                                }
                                // block
                                // style={{
                                //   // width: "20%",
                                //   margin: "0px 0px 15px 0px",
                                //   backgroundColor: "#F15B5B",
                                //   borderColor: "#F15B5B",
                                // }}
                              >
                                {i18n.c.confirm}
                              </Button>
                            </Col>

                          </Row>

                        </Form>
                      )}
                    />
                  )}
                </LangContext.Consumer></div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default VerifyForm;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Alert, Spin, Modal, Button } from "antd";
import { messaging, firebase } from "../../../lib/fcm/fcm_init";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeDashboard from "modules/master-data/companyprofile/components/Index";
import {
  PureBackgroundStyledContent,
  StyledLogo,
  StyledDiv,
  StyledBarDiv,
  StyledSignUp,
  StyledBarDiv2,
} from "../../../styled/common-styled";
import {
  checkLogin,
  checkLoginNotification,
} from "../../../modules/auth/actions";
import Form from "../../../modules/auth/components/Form";
import Verifyemailfirebase from "../../../modules/auth/components/Verifyemail";
import Forgotpassword from "../../../modules/auth/components/Forgotpassword";
import Landing from "../../../modules/auth/components/Landing";
import PrivacyPolicy from "../../../modules/auth/components/PrivacyPolicy";
import Transporter from "../../../modules/auth/components/Transporter";
import Productowner from "../../../modules/auth/components/Productowner";
import Personal from "../../../modules/auth/components/Personal";
import LangContext, { i18n } from "modules/shared/context/langContext";
import Layoutsignup from "./Layoutsignup";
import { Link } from "react-router-dom";
const { confirm } = Modal;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      token: "",
      notifications: [],
      visibleLogin: false,
    };
  }

  componentDidMount() {
    const pathname = window.location.pathname;
    console.log("componetdid", pathname);
    if (
      (this.props.auth.done && pathname.indexOf("form/personal") > -1) ||
      pathname.indexOf("form/productowner") > -1 ||
      pathname.indexOf("form/transporter") > -1 ||
      pathname.indexOf("verifyemail") > -1
    ) {
      localStorage.removeItem("access-token");
      localStorage.removeItem("profile");
    }
  }

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  checkLogin = (values) => {
    const { auth } = this.props;
    this.setState({
      username: values.username,
    });
    // console.log("ddddttt");
    this.props.checkLogin(values, function(res) {
      //console.log("res:", res);
      if (res.status === "success") {
        // self.props.checkLoginNotification({
        //   // username: self.state.username,
        //   username: values.username,
        //   password: "",
        //   token: self.state.token,
        // });
      } else {
        res.path_signup !== "" &&
          confirm({
            title: (
              <LangContext.Consumer>
                {(i18n) => i18n.signup.signup_not_complete}
              </LangContext.Consumer>
            ),
            okText: (
              <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
            ),
            cancelText: (
              <LangContext.Consumer>
                {(i18n) => i18n.c.cancel}
              </LangContext.Consumer>
            ),
            content: "",
            onOk() {
              window.location = res.path_signup;
            },
            onCancel() {
              console.log("Cancel");
            },
          });
      }
    });
  };

  handleOpenLogin = () => {
    this.setState({ visibleLogin: true });
  };

  handleCloseModal = () => {
    this.setState({ visibleLogin: false });
  };

  render() {
    let alertMsg = "";
    if (typeof this.props.location !== "undefined") {
      if (
        typeof this.props.location.data != "undefined" &&
        typeof this.props.location.data.msg != ""
      ) {
        alertMsg = (
          <Alert
            message="เรียนผู้ใช้บริการ"
            description={this.props.location.data.msg}
            type="warning"
            showIcon
            closable
            style={{ margin: "0px 0px 15px 0px" }}
          />
        );
      }
    }
    const pathname = window.location.pathname;
    return this.props.auth.done &&
      pathname.indexOf("form/personal") === -1 &&
      pathname.indexOf("form/productowner") === -1 &&
      pathname.indexOf("form/transporter") === -1 &&
      pathname.indexOf("verifyemail") === -1 ? (
      <PureBackgroundStyledContent>
        <HomeDashboard />
      </PureBackgroundStyledContent>
    ) : (
      <Router>
        <Fragment>
          <Modal
          className="test-modal-login"
            title="เข้าสู่ระบบ"
            visible={this.state.visibleLogin}
            footer={null}
            onCancel={this.handleCloseModal}
          >
          
            <Form
              auth={this.props.auth}
              onSubmit={this.checkLogin}
              firebase={firebase}
            />
          </Modal>
          <Spin
            spinning={this.props.auth.loading}
            size="large"
            style={{ height: "100%" }}
          >
            <StyledDiv>
              {window.location.pathname.indexOf("form") > -1 ||
              window.location.pathname.indexOf("verifyemail") > -1 ? (
                <Fragment>
                  <StyledBarDiv2>
                    <Button
                      type="primary"
                      style={{
                        width: "10%",
                        margin: "18px 10px 0px 0px",
                        float: "right",
                      }}
                      onClick={() => (window.location.href = "/")}
                    >
                      <LangContext.Consumer>
                        {(i18n) => i18n.l.login}
                      </LangContext.Consumer>
                    </Button>
                  </StyledBarDiv2>
                  <Switch>
                    <Route
                      exact
                      path="/form/personal/:id"
                      component={Personal}
                      {...this.props}
                    />
                    <Route
                      exact
                      path="/verifyemail/:email/:phone/:password/:usertype/:company_type"
                      component={Verifyemailfirebase}
                      {...this.props}
                    />
                    <Route
                      exact
                      path="/form/transporter/:id"
                      component={Transporter}
                      {...this.props}
                    />
                    <Route
                      exact
                      path="/form/productowner/:id"
                      component={Productowner}
                      {...this.props}
                    />
                  </Switch>
                </Fragment>
              ) : (
                <Fragment>
                  <StyledBarDiv>
                  {/* <img src="/img/backhaul/logo-web.png" /> */}
                    {window.innerWidth <= 800 ? (
                      <Button type="primary" onClick={this.handleOpenLogin}>
                        เข้าสู่ระบบ
                      </Button>
                    ) : (
                      <Form
                        auth={this.props.auth}
                        onSubmit={this.checkLogin}
                        firebase={firebase}
                      />
                    )}
                  </StyledBarDiv>
                  <Switch>
                   
                    <Route
                          exact
                          path="/forgotpassword"
                          component={Forgotpassword}
                          {...this.props}
                        />
                    <Route            
                      path="/signup"
                      component={Layoutsignup}
                      {...this.props}
                    />

                  <Route            
                      path="/privacypolicy"
                      component={PrivacyPolicy}
                      {...this.props}
                  />

                  <Route path="/" component={Landing} {...this.props} />

                  </Switch>
                  {/* <Layoutsignup/> */}
                </Fragment>
              )}
            </StyledDiv>
          </Spin>
        </Fragment>
      </Router>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    checkLogin: checkLogin.request,
    checkLoginNotification: checkLoginNotification.request,
  }
)(Login);

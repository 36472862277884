import { createTypes, createAction } from "lib/action";

const LOAD_LOGISTICGROUP = createTypes("logisticgroup", "load");
const LOAD_LOGISTICGROUP_DETAIL = createTypes("logisticgroupdetail", "load");
const CREATE_LOGISTICGROUP = createTypes("logisticgroup", "create");
const UPDATE_LOGISTICGROUP = createTypes("logisticgroup", "update");

const REMOVE_LISTS = `REMOVE_LISTS`;

function removeLists() {
  return {
    type: REMOVE_LISTS,
  };
}

const loadLogisticGroup = {
  request: (data, loading) =>
    createAction(LOAD_LOGISTICGROUP.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_LOGISTICGROUP.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_LOGISTICGROUP.FAITLURE),
};

const createLogisticGroup = {
  request: (data, cb) =>
    createAction(CREATE_LOGISTICGROUP.REQUEST, { data, cb }),
  success: (res) => createAction(CREATE_LOGISTICGROUP.SUCCESS, { res }),
  failure: () => createAction(CREATE_LOGISTICGROUP.FAILURE),
};

const loadlogisticGroupDetail = {
  request: (data, loading) =>
    createAction(LOAD_LOGISTICGROUP_DETAIL.REQUEST, { data, loading }),
  success: (lists, page) =>
    createAction(LOAD_LOGISTICGROUP_DETAIL.SUCCESS, { lists, page }),
  failure: () => createAction(LOAD_LOGISTICGROUP_DETAIL.FAITLURE),
};

const upDateLogisticGroup = {
  request: (data, loading) =>
    createAction(UPDATE_LOGISTICGROUP.REQUEST, { data, loading }),
  success: (res) => createAction(UPDATE_LOGISTICGROUP.SUCCESS, { res }),
  failure: () => createAction(UPDATE_LOGISTICGROUP.FAILURE),
};

export {
  loadLogisticGroup,
  LOAD_LOGISTICGROUP,
  loadlogisticGroupDetail,
  LOAD_LOGISTICGROUP_DETAIL,
  createLogisticGroup,
  CREATE_LOGISTICGROUP,
  upDateLogisticGroup,
  UPDATE_LOGISTICGROUP,
  REMOVE_LISTS,
  removeLists,
};

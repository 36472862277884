import React, { Component, Fragment } from "react";
import { Modal, Checkbox, Row, Col } from "antd";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID,
} from "../../../../../../constants/local_storage";
import { translate } from "../../../../../../lib/helper";
import LogisticGroupAdd from "../../../../../master-data/logisticGroup/components/LogisticGroupAdd";
export default class AddGroupModel extends Component {
  state = {
    logisticgroupLists: [],
    group_id: [],
    visible_new_group: false
  };

  loadCompanyGroup = () => {
    let self = this;
    let companyId = this.props.addCompanyId;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/companyhaslogisticgroup/${companyId}`,
        { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
      )
      .then((response) => {
        if (typeof response.data.data !== "undefined") {
          self.setState({ group_id: response.data.data });
        }
      });
  };

  loadData = (id) => {
    let self = this;
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlogisticgroupbycompany`,
        {
          company_id: COMPANY_ID,
        },
        { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
      )
      .then((response) => {
        if (typeof response.data.data !== "undefined") {
          let logisticgroupLists = response.data.data.map((d) => {
            return {
              key: d.id,
              name: d.name,
            };
          });
          self.setState({ logisticgroupLists });
        }
      });
  };

  onChange = (checkedValues, e) => {
    this.setState({ group_id: checkedValues });
  };

  onOk = () => {
    if(this.state.logisticgroupLists.length > 0) {
      this.props.onOk(this.props.addCompanyId, this.state.group_id);
    } else {
      this.setState({ visible_new_group: true })
    }
  };

  handleClose = () => {
    this.setState({ visible_new_group: false }, this.loadData)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.addCompanyId !== prevProps.addCompanyId) {
      this.loadData(this.props.addCompanyId);
      this.loadCompanyGroup();
    }
  }

  render() {
    return (
      <Fragment>
        <Modal
          className="test-modal-add-logisticgroup"
          title={translate("logisticGroup", "l")}
          visible={this.state.visible_new_group}
          onOk={this.onOk}
          onCancel={this.handleClose}
          footer={null}
          width={800}
          destroyOnClose={true}
          okButtonProps={{
            "data-cy": "btn-modal-ok"
          }}
          cancelButtonProps={{
            "data-cy": "btn-modal-cancel"
          }}
        >
          <LogisticGroupAdd closeAfterSave={this.handleClose}/>
        </Modal>
        <Modal
          className="test-modal-add-location"
          title={translate("logisticGroup", "l")}
          visible={this.props.visible}
          onOk={this.onOk}
          onCancel={this.props.onCancel}
          okText={translate("ok", "o")}
          cancelText={translate("cancel", "c")}
          destroyOnClose={true}
          okButtonProps={{
            "data-cy": "btn-modal-ok"
          }}
          cancelButtonProps={{
            "data-cy": "btn-modal-cancel"
          }}
        >
          {this.state.logisticgroupLists.length > 0 ? (
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={this.state.group_id}
            >
              {this.state.logisticgroupLists.map((val, i) => {
                return (
                  <Row key={i}>
                    <Col span={8}>
                      <Checkbox data-cy={`checkbox-${val.key}`} value={val.key}>{val.name}</Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </Checkbox.Group>
          ) : (
            translate("logistic_group_add", "l")
          )}
        </Modal>
      </Fragment>
    );
  }
}

import React, { Component } from "react";
import { Modal, Form, Row, Col, Transfer, Select, Radio } from "antd";
import { Formik } from "formik";
import LangContext from "modules/shared/context/langContext";

const FormItem = Form.Item;
const Option = Select.Option;
class Directjob extends Component {
  state = {
    logisticgroupSelect: [],
    selectedKeys: [],
    direct_type: "logisticgroup",
  };

  componentDidMount() {}

  tranferChange = (targetKeys, setFieldValue) => {
    setFieldValue("list_logistic_id", targetKeys);
    this.setState({ logisticgroupSelect: targetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  handlePreviewClick = (e, setFieldValue, handleSubmit) => {
    handleSubmit();
  };

  onDirectType = (e, setFieldValue) => {
    setFieldValue("direct_type", e);
    this.setState({ direct_type: e });
  };

  render() {
    const {
      visible,
      onOk,
      onCancel,
      logisticgroupLists,
      transporterLists,
    } = this.props;

    return (
      <div>
        <div>
          <LangContext.Consumer>
            {(i18n) => (
              <Formik
                initialValues={{
                  list_logistic_id: [],
                  transporter_id: "",
                  direct_type: "logisticgroup",
                }}
                validate={(values) => {
                  let errors = {};

                  if (values.direct_type === "logisticgroup") {
                    if (values.list_logistic_id.length === 0) {
                      errors.list_logistic_id = i18n.worksheet.logistic_require;
                    }
                  }

                  if (values.direct_type === "transporter") {
                    if (values.transporter_id === "") {
                      errors.transporter_id =
                        i18n.worksheet.transporter_id_require;
                    }
                  }

                  return errors;
                }}
                onSubmit={(values) => {
                  onOk(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                  isValidating,
                }) => (
                  <Modal
                    className="test-modal-direct"
                    style={{ top: 20 }}
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.worksheet.direct_type}
                      </LangContext.Consumer>
                    }
                    visible={visible}
                    onOk={(value) =>
                      this.handlePreviewClick(
                        value,
                        setFieldValue,
                        handleSubmit
                      )
                    }
                    onCancel={onCancel}
                    okText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.confirm}
                      </LangContext.Consumer>
                    }
                    cancelText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                    width={700}
                    okButtonProps={{
                      "data-cy": "btn-modal-ok"
                    }}
                    cancelButtonProps={{
                      "data-cy": "btn-modal-cancel"
                    }}
                  >
                    <Row type="flex" justify="center">
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <FormItem
                              label={
                                <LangContext.Consumer>
                                  {(i18n) => i18n.worksheet.direct_type}
                                </LangContext.Consumer>
                              }
                              touched={touched.direct_type}
                              errors={errors.direct_type}
                            >
                              <Radio.Group
                                value={values.direct_type}
                                onChange={(e, option) =>
                                  this.onDirectType(
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                              >
                                <Radio
                                  data-cy="transporter"
                                  value="transporter"
                                >
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.worksheet.transporterType}
                                  </LangContext.Consumer>
                                </Radio>
                                <Radio
                                  data-cy="logisticgroup"
                                  value="logisticgroup"
                                >
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.worksheet.logisticGroupType}
                                  </LangContext.Consumer>
                                </Radio>
                              </Radio.Group>
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.direct_type === "transporter" ? (
                              <FormItem
                                label={
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.worksheet.transporterType}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.transporter_id &&
                                  errors.transporter_id &&
                                  "error"
                                }
                                help={
                                  touched.transporter_id &&
                                  errors.transporter_id
                                }
                                required={true}
                              >
                                <Select
                                  data-cy="transporter_id"
                                  onChange={(value) =>
                                    setFieldValue("transporter_id", value)
                                  }
                                  value={values.transporter_id}
                                >
                                  <Option value="">
                                    {i18n.p.pleaseSelect}
                                  </Option>
                                  {transporterLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                      {v.name}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.direct_type === "logisticgroup" ? (
                              <FormItem
                                label={
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.worksheet.logisticGroupType}
                                  </LangContext.Consumer>
                                }
                                validateStatus={
                                  touched.list_logistic_id &&
                                  errors.list_logistic_id &&
                                  "error"
                                }
                                help={
                                  touched.list_logistic_id &&
                                  errors.list_logistic_id
                                }
                                required={true}
                              >
                                <Transfer
                                  className="test-transfer-group"
                                  titles={[
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.i.itemLeft}
                                    </LangContext.Consumer>,
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.i.itemSelected}
                                    </LangContext.Consumer>,
                                  ]}
                                  dataSource={logisticgroupLists}
                                  showSearch
                                  listStyle={{
                                    width: 250,
                                    height: 300,
                                  }}
                                  selectedKeys={this.state.selectedKeys}
                                  operations={[">>", "<<"]}
                                  targetKeys={this.state.logisticgroupSelect}
                                  onSelectChange={this.handleSelectChange}
                                  onChange={(value) =>
                                    this.tranferChange(value, setFieldValue)
                                  }
                                  render={(item) => `${item.name}`}
                                  footer={this.renderFooter}
                                />
                              </FormItem>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </Modal>
                )}
              />
            )}
          </LangContext.Consumer>
        </div>
      </div>
    );
  }
}

export default Directjob;

import {
  LOAD_PRODUCTOWNER_WORKSHEETS,
  LOAD_PRODUCTOWNER_QUOTATIONS,
  SHOW_PRODUCTOWNER_WORKSHEET,
  UPDATE_PRODUCTOWNER_QUOTATIONS
} from "./actions";

const initialState = {
  lists: [],
  totalRows: 0,
  loading: false,
  lists_qt: [],
  totalRowsQt: 0,
  loadingQt : false,
  data: {},
  success: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRODUCTOWNER_WORKSHEETS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PRODUCTOWNER_WORKSHEETS.SUCCESS:
      return {
        lists: action.payload.lists.data,
        totalRows: action.payload.lists.total,
        loading: false,
      };
    case LOAD_PRODUCTOWNER_WORKSHEETS.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case LOAD_PRODUCTOWNER_QUOTATIONS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PRODUCTOWNER_QUOTATIONS.SUCCESS:
      return {
        lists_qt: action.payload.listsQt.data,
        totalRows: action.payload.listsQt.total,
        loading: false,
      };
    case LOAD_PRODUCTOWNER_QUOTATIONS.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SHOW_PRODUCTOWNER_WORKSHEET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHOW_PRODUCTOWNER_WORKSHEET.SUCCESS:
      return {
        data: action.payload.data.data,
        loading: false,
      };
    case SHOW_PRODUCTOWNER_WORKSHEET.FAILURE:
      return {
        ...state,
        loading: false,
      };

      case UPDATE_PRODUCTOWNER_QUOTATIONS.REQUEST:
        return {
          ...state,
          success: null,
          loading: true,
        };
      case UPDATE_PRODUCTOWNER_QUOTATIONS.SUCCESS:
        return {
          data: action.payload.data.data,
          success: true,
          loading: false,
        };
      case UPDATE_PRODUCTOWNER_QUOTATIONS.FAILURE:
        return {
          ...state,
          success: false,
          loading: false,
        };
    default:
      return state;
  }
};

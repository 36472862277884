import { fork } from "redux-saga/effects";

import watchProductOwnerCompany from "./components/companygroup/sagas";
import watchProductOwnerAllWork from "./components/mywork/sagas";

const watchDashboardProductOwner = [
  fork(watchProductOwnerCompany),
  fork(watchProductOwnerAllWork),
];

export { watchDashboardProductOwner };

import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge, Col } from "antd";

class CustomBadge extends Component {
  _isMounted = false;
  channel = "/news/" + this.props.companyID;

  componentDidMount = () => {
    this._isMounted = true;
    //this.props.loadNotificationCount("count", this.props.userID, 0, 0, 0);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    // let haveNewNoti =
    //   nextProps.mqttData.length > 0 &&
    //   this.props.mqttData.length != nextProps.mqttData.length &&
    //   typeof nextProps.mqttData[0] == "object" &&
    //   nextProps.mqttData[0].topic + "/" + nextProps.mqttData[0].company_id ==
    //     this.channel;
    // if (haveNewNoti) {
    //   this.props.onHaveNewNotiChange(haveNewNoti);
    // }
    // return !haveNewNoti;

    return true;
  };

  render() {
    let doc_title = document.title;

    doc_title.indexOf(")") !== -1 && doc_title.split(")").length >= 1
      ? (doc_title = doc_title.split(")")[1])
      : (doc_title = doc_title);

    // if (this.props.notificationCount <= 0) {
    //   document.title = doc_title;
    //   let link =
    //     document.querySelector("link[rel*='icon']") ||
    //     document.createElement("link");
    //   link.type = "image/x-icon";
    //   link.rel = "shortcut icon";
    //   //link.href = "/favicon.ico";
    //   document.getElementsByTagName("head")[0].appendChild(link);
    // } else {
    //   document.title = `(${this.props.notificationCount}) ` + doc_title;
    //   let link =
    //     document.querySelector("link[rel*='icon']") ||
    //     document.createElement("link");
    //   link.type = "image/x-icon";
    //   link.rel = "shortcut icon";
    //   // link.href = "/favicon_with_noti.ico";
    //   document.getElementsByTagName("head")[0].appendChild(link);
    // }
    return (
      <Col
        // xs={{ span: 0 }}
        sm={2}
        md={2}
        lg={3}
        xl={3}
        style={{ margin: "0px 10px 0px 0px" }}
      >
        <Badge count={0}>{this.props.children}</Badge>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationCount: state.ui.notificationCount,
});

export default connect(mapStateToProps, null)(CustomBadge);

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadLogisticGroup(action) {
  const {
    data: {
      page,
      pageSize,
      orderBy,
      orderType,
      accessToken,
      searchName,
      companyID,
      filterObj,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterDataLogisticGroup`,
      //  `http://booking-api/api/vehicleGroup/${id}`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        searchName,
        companyID,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadLogisticGroup.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadLogisticGroup.failure());
  }
}

function* createLogisticGroup(action) {
  const {
    data: {
      companyID,
      user_id,
      selected,
      groupname,
      detail,
      file_logo,
      accessToken,
    },

    cb,
  } = action.payload;

  const formData = new FormData();

  file_logo.forEach((File) => {
    formData.append("file_logo", File);
  });
  formData.append("user_id", user_id);
  formData.append("company_id", companyID);
  formData.append("groupname", groupname);
  formData.append("detail", detail);
  formData.append("selected", selected);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createLogisticGroup`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    cb(res.data);
    yield put(actions.createLogisticGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createLogisticGroup.failure());
  }
}

function* loadlogisticGroupDetail(action) {
  const {
    data: { id, page, pageSize, orderBy, orderType, searchName, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/logisticgroupname`,
      {
        id,
        page,
        pageSize,
        orderBy,
        orderType,
        searchName,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadlogisticGroupDetail.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadlogisticGroupDetail.failure());
  }
}

function* upDateLogisticGroup(action) {
  const {
    data: {
      logistic_groups_id,
      companyId,
      groupname,
      user_id,
      detail,
      file_logo,
      selected,
      accessToken,
      change_logo,
    },
    loading,
  } = action.payload;

  const formData = new FormData();

  if (change_logo === true) {
    file_logo.forEach((File) => {
      formData.append("file_logo", File);
    });
  } else {
    formData.append("file_logo", null);
  }

  formData.append("logistic_groups_id", logistic_groups_id);
  formData.append("user_id", user_id);
  formData.append("company_id", companyId);
  formData.append("groupname", groupname);
  formData.append("detail", detail);
  formData.append("selected", selected);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editLogisticGroup`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.upDateLogisticGroup.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.upDateLogisticGroup.failure());
  }
}

export default function* watchDataLogisticGroup() {
  yield all([
    takeEvery(actions.LOAD_LOGISTICGROUP.REQUEST, loadLogisticGroup),
    takeEvery(actions.CREATE_LOGISTICGROUP.REQUEST, createLogisticGroup),
    takeEvery(
      actions.LOAD_LOGISTICGROUP_DETAIL.REQUEST,
      loadlogisticGroupDetail
    ),
    takeEvery(actions.UPDATE_LOGISTICGROUP.REQUEST, upDateLogisticGroup),
  ]);
}

export {
  loadLogisticGroup,
  createLogisticGroup,
  loadlogisticGroupDetail,
  upDateLogisticGroup,
};

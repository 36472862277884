import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID
} from "../../../../../constants/local_storage";
import * as actions from "./actions";

function* loadWorksheetCompany(action) {
  const { page, pageSize, orderBy, orderType, filters, type } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/loadworksheet`,
      {
        page,
        pageSize,
        company_id: COMPANY_ID,
        orderBy, 
        orderType, 
        filters,
        worksheet_type: type
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadWorksheetCompany.success(res.data));
  } catch (err) {
    yield put(actions.loadWorksheetCompany.failure());
  }
}

function* loadQuotations(action) {
  const { page, pageSize, orderBy, orderType, filters, worksheet_id } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/loadquotations`,
      {
        page,
        pageSize,
        company_id: COMPANY_ID,
        orderBy, 
        orderType, 
        filters,
        worksheet_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadQuotations.success(res.data));
  } catch (err) {
    yield put(actions.loadQuotations.failure());
  }
}

function* showWorksheet(action) {
  const { id, requotation } = action.payload;
  try {
    let url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`
    url = (requotation) ? `${url}/productowner/loadworksheet/requotation/${id}` : `${url}/productowner/loadworksheet/${id}`
    const res = yield call(
      axios.get,
      `${url}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.showWorksheet.success(res.data));
  } catch (err) {
    yield put(actions.showWorksheet.failure());
  }
}

function* updateStatusQt(action) {
  const { worksheet_id, quotation_id } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/productowner/confirmqt`,
      {
        worksheet_id,
        quotation_id
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.updateStatusQt.success(res.data));
  } catch (err) {
    yield put(actions.updateStatusQt.failure());
  }
}

export default function* productOwnerAllwork() {
  yield all([
    takeEvery(actions.LOAD_PRODUCTOWNER_WORKSHEETS.REQUEST, loadWorksheetCompany),
    takeEvery(actions.LOAD_PRODUCTOWNER_QUOTATIONS.REQUEST, loadQuotations),
    takeEvery(actions.SHOW_PRODUCTOWNER_WORKSHEET.REQUEST, showWorksheet),
    takeEvery(actions.UPDATE_PRODUCTOWNER_QUOTATIONS.REQUEST, updateStatusQt),
  ]);
}

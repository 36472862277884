import React from "react";
import { Switch, Route } from "react-router-dom";
import Myworklist from "./Myworklist";
import QuotationForm from "../../market/components/QuotationForm"

export default () => (
    <Switch>
        <Route exact path="/dashboard/mywork" component={Myworklist} />
        <Route exact path="/dashboard/mywork/view/:id" component={QuotationForm} />
    </Switch>
);
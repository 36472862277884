import React, { PureComponent } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  AutoComplete,
  DatePicker,
  InputNumber,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import moment from "moment";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const OptionAuto = AutoComplete.Option;

export default class Contactform extends PureComponent {
  state = {
    loading: false,
    firstname: "",
  };

  selectDestinationLocation = (value, option, setFieldValue) => {
    const { setDestination, setContactValue } = this.props;

    let id = "",
      name = option.props.children;

    if (parseInt(value) > 0) {
      id = value;
      setContactValue(
        ["destination_location_id", "destination_location_name"],
        [id, name]
      );
      setFieldValue("destination_location_id", id);
      setFieldValue("destination_location_name", name);
    } else {
      let idx = this.props.destinationlocationLists.findIndex(
        (x) => x.name === value
      );
      if (idx > -1) {
        let values = this.props.destinationlocationLists[idx];
        id = values.id;
        name = values.name;
        setContactValue(
          ["destination_location_id", "destination_location_name"],
          [id, name]
        );
        setFieldValue("destination_location_id", id);
        setFieldValue("destination_location_name", name);
      }
    }

    setDestination(id, name);
  };

  onSearchDestinationLocation = (searchText) => {
    const { setDestination } = this.props;
    if (searchText != "") {
      this.props.loadDestinationLocation(searchText);
    }
    //setDestination("", searchText);
  };

  handleClick = (value, setFieldValue, contactHandleSubmit) => {
    this.props.setErrors({ ...this.props.formErrors, contactlist: "" });
    setFieldValue(
      "destination_location_name",
      this.props.contact.destination_location_name,
      false
    );
    contactHandleSubmit();
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment()
          .add(-1, "days")
          .endOf("day")
    );
  };

  render() {
    const {
      onSubmit,
      cancelContact,
      showModal,
      destinationlocationLists,
      contact,
    } = this.props;

    // const { destination_location_name } = this.state;

    const destinationlists = destinationlocationLists.map((v) => (
      <OptionAuto key={v.id}>{v.name}</OptionAuto>
    ));

    return (
      <div>
        <LangContext.Consumer>
          {(i18n) => (
            <Formik
              enableReinitialize={true}
              initialValues={{
                title_name: contact.title_name,
                firstname: contact.firstname,
                lastname: contact.lastname,
                destination_receive_date: contact.destination_receive_date,
                destination_location_id: contact.destination_location_id,
                // destination_location_name: contact.destination_location_name,
                phone: contact.phone,
                quantity: contact.quantity,
                unit: contact.unit,
              }}
              validate={(values) => {
                let errors = {};
                if (/^\d+$/.test(values.phone) === false) {
                  errors.phone = i18n.p.phone_invalid;
                }

                return errors;
              }}
              validationSchema={yup.object().shape({
                firstname: yup.string().required(i18n.addr.firstname_require),
                lastname: yup.string().required(i18n.addr.lastname_require),
                phone: yup
                  .string()
                  .required(i18n.p.phone_require)
                  .matches(/^(\d{10})$/, i18n.v.validate_phone),
                destination_receive_date: yup
                  .string()
                  .required(i18n.worksheet.destination_receive_date_require),
                destination_location_id: yup
                  .string()
                  .required(i18n.expense.destination_location_require),
                quantity: yup
                  .string()
                  .required(i18n.worksheet.quantity_require),
                unit: yup.string().required(i18n.worksheet.unit_require),
              })}
              onSubmit={(values) => {
                //console.log("subcon:", values);
                onSubmit(values);
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit: contactHandleSubmit,
                handleFocus,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={contactHandleSubmit}>
                    <Row>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <FormItem
                          label={i18n.expense.destination_location}
                          required={true}
                          validateStatus={
                            touched.destination_location_id &&
                            errors.destination_location_id &&
                            "error"
                          }
                          help={
                            touched.destination_location_id &&
                            errors.destination_location_id
                          }
                        >
                          <InputGroup compact>
                            <AutoComplete
                              className="autocompelete-contact-destination"
                              dataSource={destinationlists}
                              style={{ width: "83%" }}
                              optionLabelProp="children"
                              filterOption={true}
                              optionFilterProp="children"
                              value={contact.destination_location_name}
                              onChange={(value, option) =>
                                this.selectDestinationLocation(
                                  value,
                                  option,
                                  setFieldValue
                                )
                              }
                              onSelect={(value, option) =>
                                this.selectDestinationLocation(
                                  value,
                                  option,
                                  setFieldValue
                                )
                              }
                              onSearch={this.onSearchDestinationLocation}
                            />
                            <Button
                              data-cy="btn-toggle-modal-contact-destination"
                              icon="plus"
                              style={{
                                width: "17%",
                                padding: "0px 2px 0px 0px",
                              }}
                              onClick={() => showModal("destination")}
                            >
                              <LangContext.Consumer>
                                {(i18n) => i18n.a.add}
                              </LangContext.Consumer>
                            </Button>
                          </InputGroup>
                        </FormItem>
                      </Col>
                      <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <Form.Item
                          required={true}
                          label={i18n.worksheet.destination_receive_date}
                          validateStatus={
                            touched.destination_receive_date &&
                            errors.destination_receive_date &&
                            "error"
                          }
                          help={
                            touched.destination_receive_date &&
                            errors.destination_receive_date
                          }
                        >
                          <DatePicker
                            data-cy="destination_receive_date"
                            showTime
                            disabledDate={this.disabledDate}
                            onChange={(e) => {
                              this.props.setContactValue(
                                "destination_receive_date",
                                e
                              );
                            }}
                            style={{ width: "100%" }}
                            value={values.destination_receive_date}
                            format="DD/MM/YYYY HH:mm"
                            showTime={{ format: "HH:mm" }}
                            dateRender={(current) => {
                              const style = {};
                              if (current.date() === 1) {
                                style.border = "1px solid #1890ff";
                                style.borderRadius = "50%";
                              }
                              return (
                                <div
                                  className="ant-calendar-date"
                                  style={style}
                                >
                                  {current.date()}
                                </div>
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <FormItem
                          label={i18n.worksheet.quantity}
                          required={true}
                          validateStatus={
                            touched.quantity && errors.quantity && "error"
                          }
                          help={touched.quantity && errors.quantity}
                        >
                          <InputNumber
                            data-cy="quantity"
                            style={{ width: "100%" }}
                            value={values.quantity}
                            min={0}
                            max={9999999}
                            step={1}
                            onChange={(value) =>
                              setFieldValue("quantity", value)
                            }
                            onBlur={(e) => {
                              this.props.setContactValue(
                                "quantity",
                                e.target.value
                              );
                            }}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <FormItem
                          required={true}
                          label={i18n.worksheet.unit}
                          validateStatus={
                            touched.unit && errors.unit && "error"
                          }
                          help={touched.unit && errors.unit}
                        >
                          <Input
                            type="text"
                            data-cy="unit"
                            name="unit"
                            value={values.unit}
                            //disabled={true}
                            onChange={handleChange}
                            onBlur={(e) => {
                              this.props.setContactValue(
                                "unit",
                                e.target.value
                              );
                            }}
                            invalid={
                              touched.unit && errors.unit
                                ? "invalid"
                                : undefined
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <FormItem
                          required={true}
                          label={i18n.f.firstName}
                          validateStatus={
                            touched.firstname && errors.firstname && "error"
                          }
                          help={touched.firstname && errors.firstname}
                        >
                          <InputGroup compact>
                            <Select
                              data-cy="title_name"
                              style={{ width: "30%" }}
                              onChange={(value) =>
                                setFieldValue("title_name", value)
                              }
                              onBlur={(e) => {
                                this.props.setContactValue("title_name", e);
                              }}
                              title_name
                              value={values.title_name}
                            >
                              <Option value="mr">{i18n.m.mr}</Option>
                              <Option value="mrs">{i18n.m.mrs}</Option>
                              <Option value="miss">{i18n.m.miss}</Option>
                            </Select>
                            <Input
                              data-cy="firstname"
                              style={{ width: "70%" }}
                              type="text"
                              name="firstname"
                              value={values.firstname}
                              onChange={handleChange}
                              onBlur={(e) => {
                                this.props.setContactValue(
                                  "firstname",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.firstname && errors.firstname
                                  ? "invalid"
                                  : undefined
                              }
                              s
                            />
                          </InputGroup>
                        </FormItem>
                      </Col>
                      <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <Form.Item
                          label={i18n.l.lastName}
                          required={true}
                          validateStatus={
                            touched.lastname && errors.lastname && "error"
                          }
                          help={touched.lastname && errors.lastname}
                        >
                          <Input
                            data-cy="lastname"
                            type="text"
                            name="lastname"
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={(e) => {
                              this.props.setContactValue(
                                "lastname",
                                e.target.value
                              );
                            }}
                            invalid={
                              touched.lastname && errors.lastname
                                ? "invalid"
                                : undefined
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                      <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <Form.Item
                          required={true}
                          label={i18n.p.phone}
                          validateStatus={
                            touched.phone && errors.phone && "error"
                          }
                          help={touched.phone && errors.phone}
                        >
                          <Input
                            data-cy="phone"
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={(e) => {
                              this.props.setContactValue(
                                "phone",
                                e.target.value
                              );
                            }}
                            invalid={
                              touched.phone && errors.phone
                                ? "invalid"
                                : undefined
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Button
                      data-cy="btn-cancel-contact"
                      type="default"
                      style={{
                        width: "20%",
                        margin: "50px 20px 0px 0px",
                      }}
                      onClick={cancelContact}
                    >
                      {i18n.c.cancel}
                    </Button>

                    <Button
                      data-cy="btn-save-contact"
                      // htmlType="submit"
                      onClick={(value) =>
                        this.handleClick(
                          value,
                          setFieldValue,
                          contactHandleSubmit
                        )
                      }
                      type="primary"
                      style={{
                        width: "20%",
                        backgroundColor: "#F15B5B",
                        borderColor: "#F15B5B",
                        margin: "50px 0px 0px 0px",
                      }}
                    >
                      {i18n.c.confirm}
                    </Button>
                  </Form>
                );
              }}
            />
          )}
        </LangContext.Consumer>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  Spin,
  Upload,
  Icon,
  message,
  Modal,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import axios from "axios";
import Contact from "./Contact";
import FormDivider from "../../../shared/components/FormDivider";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
class FormEditProductowner extends Component {
  state = {
    disabled: false,

    fileIdCard: [],
    fileDrivingCard: [],
    loading: false,
    companytypeLists: [],
    check_have_state: false,

    certificateFileVisible: false,
    certificateFileImage: '',
    certificateFileTitle: '',
    certificateFileList: [],
    change_file_certificate: false,

    vat20FileVisible: false,
    vat20FileImage: '',
    vat20FileTitle: '',
    vat20FileList: [],
    change_file_vat20 : false,

    productFileVisible: false,
    productFileImage: '',
    productFileTitle: '',
    productFileList: [],
    change_file_product : false,

    logoFileVisible: false,
    logoFileImage: '',
    logoFileTitle: '',
    logoFileList: [],
    change_file_logo : false,

    data: {
      company_name: "",
      company_type: "",
      juristic_id: "",
      province_head_offfice: "",
      founded_year: "",
      number_employee: "",
      buildingname: "",
      roomnumber: "",
      floor: "",
      village: "",
      houseno: "",
      villageno: "",
      soi: "",
      province: "",
      district: "",
      subdistrict: "",
      postalcode: "",
      certificate_file: [],
      product_file: [],
      logo_file: [],
      vat_20_file: [],
    },
  };

  componentDidMount() {
    const { loadProvince } = this.props;
    loadProvince();
    this.companyType();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.companyProfile.lists.companyInformation && this.state.check_have_state === false){
      const province = nextProps.companyProfile.lists.companyInformation[0].province_id
      const district = nextProps.companyProfile.lists.companyInformation[0].district_id
      const subdistrict = nextProps.companyProfile.lists.companyInformation[0].subdistrict_id

      nextProps.loadAmphur(province);
      nextProps.loadSubAmphur(district);

      this.setState({
        disabled: nextProps.companyProfile.lists.companyInformation[0].verified === 1 ? true : false,
        certificateFileList: nextProps.companyProfile.lists.companyInformation[0].certificate_file !== '' && [
          {
              uid: "-1",
              name: "certificatefile",
              url: nextProps.companyProfile.lists.companyInformation[0].certificate_file
          }
        ],
        productFileList: nextProps.companyProfile.lists.companyInformation[0].product_file !== '' && [
          {
            uid: "-2",
            name: "productfile",
            url: nextProps.companyProfile.lists.companyInformation[0].product_file
          }
        ],
        logoFileList: nextProps.companyProfile.lists.companyInformation[0].logo_file !== '' && [
          {
            uid: "-3",
            name: "logofile",
            url: nextProps.companyProfile.lists.companyInformation[0].logo_file
          }
        ],
        vat20FileList: nextProps.companyProfile.lists.companyInformation[0].vat_20_file !== '' &&[
          {
            uid: "-4",
            name: "vat_20file",
            url: nextProps.companyProfile.lists.companyInformation[0].vat_20_file
          }
        ],
        data: {
          company_name: nextProps.companyProfile.lists.companyInformation[0].company_name,
          company_type: nextProps.companyProfile.lists.companyInformation[0].company_type_id,
          juristic_id: nextProps.companyProfile.lists.companyInformation[0].juristic_id,
          province_head_offfice: nextProps.companyProfile.lists.companyInformation[0].province_head_offfice_id,
          founded_year: nextProps.companyProfile.lists.companyInformation[0].founded_year,
          number_employee: nextProps.companyProfile.lists.companyInformation[0].number_employee,
          buildingname: nextProps.companyProfile.lists.companyInformation[0].building_name,
          roomnumber: nextProps.companyProfile.lists.companyInformation[0].room_number,
          floor: nextProps.companyProfile.lists.companyInformation[0].floor,
          village: nextProps.companyProfile.lists.companyInformation[0].village,
          houseno: nextProps.companyProfile.lists.companyInformation[0].house_no,
          villageno: nextProps.companyProfile.lists.companyInformation[0].village_no,
          soi: nextProps.companyProfile.lists.companyInformation[0].soi,
          province: province,
          district: district,
          subdistrict: subdistrict,
          postalcode: nextProps.companyProfile.lists.companyInformation[0].postcode,
          certificate_file: [
            {
              uid: "-1",
              name: "certificatefile",
              url: nextProps.companyProfile.lists.companyInformation[0].certificate_file
            }
          ],
          product_file: [
            {
              uid: "-2",
              name: "productfile",
              url: nextProps.companyProfile.lists.companyInformation[0].product_file
            }
          ],
          logo_file: [
            {
              uid: "-3",
              name: "logofile",
              url: nextProps.companyProfile.lists.companyInformation[0].logo_file
            }
          ],
          vat_20_file: [
            {
              uid: "-4",
              name: "vat_20file",
              url: nextProps.companyProfile.lists.companyInformation[0].vat_20_file
            }
          ],  
        },
        check_have_state: true,
      });
    }
  }

  onChangeProvince = (value, option, setFieldValue) => {
    const { loadAmphur } = this.props;
    setFieldValue("province", value, false);
    setFieldValue("district", "");
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadAmphur(value);
  };

  onChangeDistrict = (value, option, setFieldValue) => {
    const { loadSubAmphur } = this.props;
    setFieldValue("district", value, false);
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadSubAmphur(value);
  };

  onChangeSubDistrict = (value, option, setFieldValue) => {
    setFieldValue("subdistrict", value, false);
    setFieldValue("postalcode", option.props.code, false);
  };

  companyType = () => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanytype`, {})
      .then(function(response) {
        self.setState({ companytypeLists: response.data.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

   //Certificate File
   handleCertificateFilePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if(file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank")
    } else {
      this.setState({
        certificateFileImage: file.url || file.preview,
        certificateFileVisible: true,
      });
    }
  };
  handleCertificateFileCancel = () => this.setState({ certificateFileVisible: false });

  //Vat 20 File
  handleVat20FilePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if(file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank")
    } else {
      this.setState({
        vat20FileImage: file.url || file.preview,
        vat20FileVisible: true,
      });
    }
  };
  handleVat20FileCancel = () => this.setState({ vat20FileVisible: false });

  //Logo File
  handleLogoFilePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      logoFileImage: file.url || file.preview,
      logoFileVisible: true,
      logoFileTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleLogoFileCancel = () => this.setState({ logoFileVisible: false });

  //Product File
  handleProductFilePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if(file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank")
    } else {
      this.setState({
        productFileImage: file.url || file.preview,
        productFileVisible: true,
      });
    }
  };
  handleProductFileCancel = () => this.setState({ productFileVisible: false });

  onSubmit = (values, i18n) => {
    const {
      company_name,
      company_type,
      juristic_id,
      province_head_offfice,
      founded_year,
      number_employee,
      buildingname,
      roomnumber,
      floor,
      village,
      houseno,
      villageno,
      soi,
      province,
      district,
      subdistrict,
      postalcode,
      certificate_file,
      product_file,
      logo_file,
      vat_20_file,
    } = values;
    let self = this;

    this.setState({ loading: true });

    const formData = new FormData();

    certificate_file.forEach((File) => {
      formData.append("certificate_file", File);
    });

    product_file.forEach((File) => {
      formData.append("product_file", File);
    });

    logo_file.forEach((File) => {
      formData.append("logo_file", File);
    });

    vat_20_file.forEach((File) => {
      formData.append("vat_20_file", File);
    });

    formData.append("id", this.props.id);

    formData.append("company_name", company_name);
    formData.append("company_type", company_type);
    formData.append("juristic_id", juristic_id);
    formData.append("province_head_offfice", province_head_offfice);
    formData.append("founded_year", founded_year);
    formData.append("number_employee", number_employee);
    formData.append("buildingname", buildingname);
    formData.append("roomnumber", roomnumber);
    formData.append("floor", floor);
    formData.append("village", village);
    formData.append("houseno", houseno);
    formData.append("villageno", villageno);
    formData.append("soi", soi);
    formData.append("province", province);
    formData.append("district", district);
    formData.append("subdistrict", subdistrict);
    formData.append("postalcode", postalcode);

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/editcompanyprofileproductowner`,
        formData
      )
      .then(function(response) {
        if (response.status === "fail" && response.errors) {
          Modal.error({
            content: i18n.f.fail_save,
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        } else {
          message.success(i18n.u.update_success);
          self.props.history.push(`/companyprofile`);
        }
      })
  };

  render() {
    const {
      onSubmit,
      provinceLists,
      amphurLists,
      subamphurLists,
      loading,
      langBtn,
    } = this.props;

    const { 
      certificateFileVisible,
      certificateFileImage,
      certificateFileTitle,
      certificateFileList,
      vat20FileVisible,
      vat20FileImage,
      vat20FileTitle,
      vat20FileList,
      productFileVisible,
      productFileImage,
      productFileTitle,
      productFileList,
      logoFileVisible,
      logoFileImage,
      logoFileTitle,
      logoFileList,
    } = this.state

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    
    console.log("vat_20_filetype : ",this.state.vat_20_filetype)
    return (
      <div>
        {this.props.companyProfile.lists.companyInformation[0] ?
          <LangContext.Consumer>
            {(i18n) => (
              <Formik
                enableReinitialize={true}
                initialValues={this.state.data}
                validate={(values) => {
                  let errors = {};

                  if (/^\d+$/.test(values.founded_year) === false) {
                    errors.founded_year = i18n.business.founded_year_invalid;
                  }

                  if (/^\d+$/.test(values.number_employee) === false) {
                    errors.number_employee =
                      i18n.business.number_employee_invalid;
                  }

                  if (values.certificate_file.length == 0) {
                    errors.certificate_file =
                      i18n.business.certificate_file_require;
                  }

                  if (values.product_file.length == 0) {
                    errors.product_file = i18n.business.product_file_require;
                  }

                  if (values.logo_file.length == 0) {
                    errors.logo_file = i18n.business.logo_file_require;
                  }

                  if (values.vat_20_file.length == 0) {
                    errors.vat_20_file = i18n.business.vat_20_file_require;
                  }

                  return errors;
                }}
                validationSchema={yup.object().shape({
                  company_name: yup
                    .string()
                    .required(i18n.business.company_name_require),
                  company_type: yup
                    .string()
                    .required(i18n.business.company_type_require),
                  juristic_id: yup
                    .string()
                    .required(i18n.business.juristic_id_require),
                  province_head_offfice: yup
                    .string()
                    .required(i18n.business.province_head_offfice_require),
                  founded_year: yup
                    .string()
                    .required(i18n.business.founded_year_require),
                  number_employee: yup
                    .string()
                    .required(i18n.business.number_employee_require),
                })}
                onSubmit={(values) => {
                  this.onSubmit(values, i18n);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleFocus,
                  setFieldValue,
                }) => (
                  <Spin spinning={this.state.loading}>
                    <Form onSubmit={handleSubmit}>
                      <FormDivider>
                        <h2>{i18n.business.business_information}</h2>
                      </FormDivider>

                      <Row>
                        <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                          <Form.Item
                            required={true}
                            label={i18n.business.company_name}
                            validateStatus={
                              touched.company_name &&
                              errors.company_name &&
                              "error"
                            }
                            help={touched.company_name && errors.company_name}
                          >
                            <Input
                              data-cy="company_name"
                              type="text"
                              name="company_name"
                              value={values.company_name}
                              disabled={this.state.disabled}
                              onChange={(e) => {
                                setFieldValue(
                                  "company_name",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.company_name && errors.company_name
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            required={true}
                            label={i18n.business.company_type}
                            validateStatus={
                              touched.company_type &&
                              errors.company_type &&
                              "error"
                            }
                            help={touched.company_type && errors.company_type}
                          >
                            <Select
                            data-cy="company_type"
                            disabled={this.state.disabled}
                            onChange={(e) => {
                              setFieldValue(
                                "company_type",e
                              );
                            }}
                              value={values.company_type}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {this.state.companytypeLists.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {v.name}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.logo_file}
                            validateStatus={
                              touched.logo_file && errors.logo_file && "error"
                            }
                            help={touched.logo_file && errors.logo_file}
                          >
                          <Upload
                            className="test-upload-logoFileList"
                            name="logo_file"
                            listType="picture-card"
                            fileList={logoFileList}
                            onPreview={this.handleLogoFilePreview}
                            disabled={this.state.disabled}
                            beforeUpload={(file) => {
                              const isCheckTypeFile =
                                file.type === "image/jpeg" ||
                                file.type === "image/png";
                              if (!isCheckTypeFile) {
                                message.error(
                                  i18n.y.youCanOnlyUploadJPGPNGPDFFile
                                );
                              }
                              // const isLt2M = file.size / 1024 / 1024 < 1;
                              const checkSizeFile = file.size / 1024 <= 5120;
                              if (!checkSizeFile) {
                                message.error(i18n.worksheet.upload_size_support);
                              }

                              if (isCheckTypeFile && checkSizeFile) {
                                setFieldValue("logo_file", [file]);
                              }

                              return false;
                            }}
                            onChange={({ file, fileList }) => {
                              const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png";
                              const checkSizeFile = file.size / 1024 <= 5120;

                              if(isCheckTypeFile && checkSizeFile || file.status === "removed") {
                                setFieldValue(
                                  "logo_file", file.status !== "removed" ? [file] : []
                                );
                                this.setState({
                                  logoFileList: fileList,
                                  change_file_logo: true,
                                });
                              }
                            }}
                          >
                              {logoFileList.length >= 1
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                              className="test-modal-logo-upload"
                              visible={logoFileVisible}
                              // title={logoFileTitle}
                              footer={null}
                              onCancel={this.handleLogoFileCancel}
                          >
                              <img alt="example" style={{ width: '100%' }} src={logoFileImage} />
                          </Modal>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.juristic_id}
                            validateStatus={
                              touched.juristic_id && errors.juristic_id && "error"
                            }
                            help={touched.juristic_id && errors.juristic_id}
                          >
                            <Input
                              data-cy="juristic_id"
                              type="text"
                              name="juristic_id"
                              value={values.juristic_id}
                              disabled={this.state.disabled}
                              onChange={(e) => {
                                setFieldValue(
                                  "juristic_id",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.juristic_id && errors.juristic_id
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            required={true}
                            label={i18n.business.province_head_offfice}
                            validateStatus={
                              touched.province_head_offfice &&
                              errors.province_head_offfice &&
                              "error"
                            }
                            help={
                              touched.province_head_offfice &&
                              errors.province_head_offfice
                            }
                          >
                            <Select
                            data-cy="province_head_offfice"
                            disabled={this.state.disabled}
                            onChange={(e) => {
                              setFieldValue(
                                "province_head_offfice",e
                              );
                              // this.setState({
                              //   data: {
                              //     ...this.state.data,
                              //     province_head_offfice: e,
                              //   },
                              // });
                              }}
                              value={values.province_head_offfice}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {provinceLists ? provinceLists.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                              )) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.founded_year}
                            validateStatus={
                              touched.founded_year &&
                              errors.founded_year &&
                              "error"
                            }
                            help={touched.founded_year && errors.founded_year}
                          >
                            <Input
                              data-cy="founded_year"
                              type="text"
                              name="founded_year"
                              value={values.founded_year}
                              disabled={this.state.disabled}
                              onChange={(e) => {
                                setFieldValue(
                                  "founded_year",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.founded_year && errors.founded_year
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.number_employee}
                            validateStatus={
                              touched.number_employee &&
                              errors.number_employee &&
                              "error"
                            }
                            help={
                              touched.number_employee && errors.number_employee
                            }
                          >
                            <Input
                              data-cy="number_employee"
                              type="text"
                              name="number_employee"
                              value={values.number_employee}
                              disabled={this.state.disabled}
                              onChange={(e) => {
                                setFieldValue(
                                  "number_employee",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.number_employee && errors.number_employee
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.product_file}
                            validateStatus={
                              touched.product_file &&
                              errors.product_file &&
                              "error"
                            }
                            help={touched.product_file && errors.product_file}
                          >
                          <Upload
                            className="test-upload-productFileList"
                            name="product_file"
                            listType="picture-card"
                            fileList={productFileList}
                            onPreview={this.handleProductFilePreview}
                            disabled={this.state.disabled}
                            beforeUpload={(file) => {
                              const isCheckTypeFile =
                                file.type === "image/jpeg" ||
                                file.type === "image/png";
                              if (!isCheckTypeFile) {
                                message.error(
                                  i18n.y.youCanOnlyUploadJPGPNGPDFFile
                                );
                              }
                              // const isLt2M = file.size / 1024 / 1024 < 1;
                              const checkSizeFile = file.size / 1024 <= 5120;
                              if (!checkSizeFile) {
                                message.error(i18n.worksheet.upload_size_support);
                              }

                              if (isCheckTypeFile && checkSizeFile) {
                                setFieldValue("product_file", [file]);
                              }

                              return false;
                            }}
                            onChange={({ file, fileList }) => {
                              const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png";
                              const checkSizeFile = file.size / 1024 <= 5120;

                              if(isCheckTypeFile && checkSizeFile || file.status === "removed") {
                                setFieldValue(
                                  "product_file", file.status !== "removed" ? [file] : []
                                );
                                this.setState({
                                  productFileList: fileList,
                                  change_file_product: true,
                                });
                              }
                            }}
                          >
                              {productFileList.length >= 1
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                              className="test-modal-product-upload"
                              visible={productFileVisible}
                              // title={productFileTitle}
                              footer={null}
                              onCancel={this.handleProductFileCancel}
                          >
                            <img alt="example" style={{ width: '100%' }} src={productFileImage} />
                          </Modal>
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.certificate_file}
                            validateStatus={
                              touched.certificate_file &&
                              errors.certificate_file &&
                              "error"
                            }
                            help={
                              touched.certificate_file && errors.certificate_file
                            }
                        >
                          <Upload
                            className="test-upload-certificate-file"
                            name="certificate_file"
                            listType="picture-card"
                            fileList={certificateFileList}
                            onPreview={this.handleCertificateFilePreview}
                            disabled={this.state.disabled}
                            beforeUpload={(file) => {
                              const isCheckTypeFile =
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "application/pdf";
                              if (!isCheckTypeFile) {
                                message.error(
                                  i18n.y.youCanOnlyUploadJPGPNGPDFFile
                                );
                              }
                              // const isLt2M = file.size / 1024 / 1024 < 1;
                              const checkSizeFile = file.size / 1024 <= 5120;
                              if (!checkSizeFile) {
                                message.error(i18n.worksheet.upload_size_support);
                              }

                              if (isCheckTypeFile && checkSizeFile) {
                                setFieldValue("certificate_file", [file]);
                              }

                              return false;
                            }}
                            onChange={({ file, fileList }) => {
                              const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png" ||
                              file.type === "application/pdf";
                              const checkSizeFile = file.size / 1024 <= 5120;

                              if(isCheckTypeFile && checkSizeFile || file.status === "removed") {
                                setFieldValue(
                                  "certificate_file", file.status !== "removed" ? [file] : []
                                );
                                this.setState({
                                  certificate_filetype: file.type === "image/jpeg" || file.type === "image/png" ? "image" : "file",
                                  certificateFileList: fileList,
                                  change_file_certificate: true,
                                });
                              }
                            }}
                          >
                              {certificateFileList.length >= 1
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                              className="test-modal-cert-upload"
                              visible={certificateFileVisible}
                              // title={certificateFileTitle}
                              footer={null}
                              onCancel={this.handleCertificateFileCancel}
                          >
                            <img alt="example" style={{ width: '100%' }} src={certificateFileImage} />
                          </Modal>
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            required={true}
                            label={i18n.business.vat_20_file}
                            validateStatus={
                              touched.vat_20_file && errors.vat_20_file && "error"
                            }
                            help={touched.vat_20_file && errors.vat_20_file}
                          >
                          <Upload
                            className="test-upload-vat20FileList"
                            name="vat_20_file"
                            listType="picture-card"
                            fileList={vat20FileList}
                            onPreview={this.handleVat20FilePreview}
                            disabled={this.state.disabled}
                            beforeUpload={(file) => {
                              const isCheckTypeFile =
                                file.type === "image/jpeg" ||
                                file.type === "image/png" ||
                                file.type === "application/pdf";
                              if (!isCheckTypeFile) {
                                message.error(
                                  i18n.y.youCanOnlyUploadJPGPNGPDFFile
                                );
                              }
                              // const isLt2M = file.size / 1024 / 1024 < 1;
                              const checkSizeFile = file.size / 1024 <= 5120;
                              if (!checkSizeFile) {
                                message.error(i18n.worksheet.upload_size_support);
                              }

                              if (isCheckTypeFile && checkSizeFile) {
                                setFieldValue("vat_20_file", [file]);
                              }

                              return false;
                            }}
                            onChange={({ file, fileList }) => {
                              const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png" ||
                              file.type === "application/pdf";
                              const checkSizeFile = file.size / 1024 <= 5120;

                              if(isCheckTypeFile && checkSizeFile || file.status === "removed") {
                                setFieldValue(
                                  "vat_20_file", file.status !== "removed" ? [file] : []
                                );
                                this.setState({
                                  vat_20_filetype: file.type === "image/jpeg" || file.type === "image/png" ? "image" : "file",
                                  vat20FileList: fileList, 
                                  change_file_vat20: true,
                                });
                              }
                            }}
                          >
                              {vat20FileList.length >= 1
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                              className="test-modal-vat-upload"
                              visible={vat20FileVisible}
                              // title={vat20FileTitle}
                              footer={null}
                              onCancel={this.handleVat20FileCancel}
                          >
                            <img alt="example" style={{ width: '100%' }} src={vat20FileImage} />
                          </Modal>
                          </Form.Item>
                        </Col>
                      </Row>

                      <FormDivider>
                        <h2>{i18n.business.addresss_business}</h2>
                      </FormDivider>
                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.buildingName}
                            validateStatus={
                              touched.buildingname &&
                              errors.buildingname &&
                              "error"
                            }
                            help={touched.buildingname && errors.buildingname}
                          >
                            <Input
                              data-cy="buildingname"
                              type="text"
                              name="buildingname"
                              value={values.buildingname}
                              onChange={(e) => {
                                setFieldValue(
                                  "buildingname",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.buildingname && errors.buildingname
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.roomNumber}
                            validateStatus={
                              touched.roomnumber && errors.roomnumber && "error"
                            }
                            help={touched.roomnumber && errors.roomnumber}
                          >
                            <Input
                              data-cy="roomnumber"
                              type="text"
                              name="roomnumber"
                              value={values.roomnumber}
                              onChange={(e) => {
                                setFieldValue(
                                  "roomnumber",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.roomnumber && errors.roomnumber
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.floor}
                            validateStatus={
                              touched.floor && errors.floor && "error"
                            }
                            help={touched.floor && errors.floor}
                          >
                            <Input
                              data-cy="floor"
                              type="text"
                              name="floor"
                              value={values.floor}
                              onChange={(e) => {
                                setFieldValue(
                                  "floor",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.floor && errors.floor
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.village}
                            validateStatus={
                              touched.village && errors.village && "error"
                            }
                            help={touched.village && errors.village}
                          >
                            <Input
                              data-cy="village"
                              type="text"
                              name="village"
                              value={values.village}
                              onChange={(e) => {
                                setFieldValue(
                                  "village",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.village && errors.village
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.houseNo}
                            validateStatus={
                              touched.houseno && errors.houseno && "error"
                            }
                            help={touched.houseno && errors.houseno}
                          >
                            <Input
                              data-cy="houseno"
                              type="text"
                              name="houseno"
                              value={values.houseno}
                              onChange={(e) => {
                                setFieldValue(
                                  "houseno",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.houseno && errors.houseno
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.villageNo}
                            validateStatus={
                              touched.villageno && errors.villageno && "error"
                            }
                            help={touched.villageno && errors.villageno}
                          >
                            <Input
                              data-cy="villageno"
                              type="text"
                              name="villageno"
                              value={values.villageno}
                              onChange={(e) => {
                                setFieldValue(
                                  "villageno",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.villageno && errors.villageno
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.soi}
                            validateStatus={touched.soi && errors.soi && "error"}
                            help={touched.soi && errors.soi}
                          >
                            <Input
                              data-cy="soi"
                              type="text"
                              name="soi"
                              value={values.soi}
                              onChange={(e) => {
                                setFieldValue(
                                  "soi",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.soi && errors.soi ? "invalid" : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                      <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            label={i18n.addr.province}
                            validateStatus={
                              touched.province && errors.province && "error"
                            }
                            help={touched.province && errors.province}
                          >
                            <Select
                            data-cy="province"
                              onChange={(value, option) =>{
                                this.onChangeProvince(
                                  value,
                                  option,
                                  setFieldValue
                                )}
                              }
                              value={provinceLists ? values.province : ""}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {provinceLists ? provinceLists.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                              )) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            label={i18n.addr.district}
                            validateStatus={
                              touched.district && errors.district && "error"
                            }
                            help={touched.district && errors.district}
                          >
                            <Select
                            data-cy="district"
                              onChange={(value, option) =>{
                                this.onChangeDistrict(
                                  value,
                                  option,
                                  setFieldValue
                                )}
                              }
                              value={amphurLists ? values.district : ""}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {amphurLists ? amphurLists.map((v) => (
                                <Option key={v.id} value={v.id}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                              )) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <FormItem
                            label={i18n.addr.subdistrict}
                            validateStatus={
                              touched.subdistrict && errors.subdistrict && "error"
                            }
                            help={touched.subdistrict && errors.subdistrict}
                          >
                            <Select
                            data-cy="subdistrict"
                              onChange={(value, option) =>{
                                this.onChangeSubDistrict(
                                  value,
                                  option,
                                  setFieldValue
                                )}
                              }
                              value={subamphurLists ? values.subdistrict : ""}
                              //style={{ width: "83%" }}
                            >
                              <Option value="">{i18n.p.pleaseSelect}</Option>
                              {subamphurLists ? subamphurLists.map((v) => {
                                return <Option key={v.id} value={v.id} code={v.postalcode}>
                                  {langBtn == "th" ? v.name : v.name_eng}
                                </Option>
                                }) : ""}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                          <Form.Item
                            label={i18n.addr.postalCode}
                            validateStatus={
                              touched.postalcode && errors.postalcode && "error"
                            }
                            help={touched.postalcode && errors.postalcode}
                          >
                            <Input
                              data-cy="postalcode"
                              type="text"
                              name="postalcode"
                              value={values.postalcode}
                              onChange={(e) => {
                                setFieldValue(
                                  "postalcode",
                                  e.target.value
                                );
                              }}
                              invalid={
                                touched.postalcode && errors.postalcode
                                  ? "invalid"
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <FormDivider>
                        <h2>{i18n.business.contact_business}</h2>
                      </FormDivider>
                      <Contact id={this.props.companyProfile.lists.m_user[0].user_id}></Contact>
                      <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                          <LangContext.Consumer>
                            {(i18n) => (
                              <Form.Item>
                                <Link to="/companyprofile">
                                  <Button
                                    data-cy="btn-go-back"
                                    type="default"
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  >
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.b.back}
                                    </LangContext.Consumer>
                                  </Button>
                                </Link>
                                <Button type="primary" htmlType="submit" data-cy="data-submit">
                                  {i18n.s.save}
                                </Button>
                              </Form.Item>
                            )}
                          </LangContext.Consumer>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                )}
              />
            )}
          </LangContext.Consumer>
        : "" }
      </div>
    );
  }
}

export default withRouter(FormEditProductowner)

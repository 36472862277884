import React from "react";
import { Button, Form, Input, Drawer } from "antd";
import LangContext from "modules/shared/context/langContext";

const IndexFilterDrawer = (props) => (
  // console.log(props.plant.length),
  <Drawer
    title={
      <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
    }
    // title="Filter"
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "calc(100% - 55px)",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjPlateno} data-cy={`filter_plant_no`}/>
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleType}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjVehicleType} data-cy={`filter_vehicle_type`}/>
      </Form.Item>

      <Button
        data-cy={`btn-filter-close`}
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>

      <Button type="primary" onClick={props.onFilterButtonClick} data-cy={`btn-filter-submit`}>
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);
export default IndexFilterDrawer;

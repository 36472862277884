import React, { Fragment } from "react";
import { Row, Col } from "antd";
import {
  DocumentNoStyled,
  TitleText,
  SubTitleText,
  NormalText as Text,
  DividerStyled,
} from "../../../../../../styled/backhaul-styled";
import { numberFormat, translate } from "../../../../../../lib/helper"

const getImage = (val) => {
  let images = [];
  if (val !== "" && typeof val !== "undefined") {
    let img = decodeURIComponent(val).split(",");
    if (img.length > 0) {
      return img;
    } else {
      return images.push(val);
    }
  }
  return [];
};

const JobDetail = ({ data }) => {
  return (
    <Fragment>
      <Row gutter={[24, 24]} type="flex" justify="end">
        <Col span={4}>
          <DocumentNoStyled>
            {translate("document_number", "worksheet")}
            <br />
            {data.document_number}
          </DocumentNoStyled>
        </Col>
      </Row>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <TitleText>{translate("company_name", "business")}</TitleText>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Text>{data.company_name}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Row>
            <Col span={10}>
              <SubTitleText>{translate("job_origin", "j")}</SubTitleText>
            </Col>
            <Col span={7}>
              <SubTitleText>{translate("job_receive_date", "j")}</SubTitleText>
            </Col>
            <Col span={7}>
              <SubTitleText>{translate("actionTime", "a")}</SubTitleText>
            </Col>
          </Row>

          <Row>
            <Col span={10}>
              <Text>{data.origin_location}</Text>
            </Col>
            <Col span={7}>
              <Text>{data.origin_receive_date}</Text>
            </Col>
            <Col span={7}>
              <Text>{data.origin_receive_date}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <DividerStyled />

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <TitleText>{translate("customerName", "c")}</TitleText>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Text>{`${data.customer}`}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={10}>
              <SubTitleText>{translate("md_destination", "m")}</SubTitleText>
            </Col>
            <Col span={7}>
              <SubTitleText>{translate("job_quantity", "j")}</SubTitleText>
            </Col>
            <Col span={7}>
              <SubTitleText>{translate("job_delivery_date", "j")}</SubTitleText>
            </Col>
          </Row>
          {typeof data.destination !== "undefined" &&
            data.destination.map((val, idx) => (
              <Fragment key={idx}>
                <Row  gutter={[8, 8]}>
                  <Col span={10}>
                    <Text>{`${val.destination}`}</Text>
                  </Col>
                  <Col span={7}>
                    <Text>{`${numberFormat(val.quantity, 0)} (${val.unit})`}</Text>
                  </Col>
                  <Col span={7}>
                    <Text>{val.receive_date}</Text>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Col>
      </Row>

      <DividerStyled />

      <Row gutter={[24, 24]} type="flex" align="bottom">
        <Col span={6}>
          <TitleText>{translate("md_product", "m")}</TitleText>
        </Col>
      </Row>

      <Row
        gutter={[24, 24]}
        type="flex"
        align="middle"
        style={{ marginBottom: "50px" }}
      >
        {data.path_product !== null && getImage(data.path_product).map((val, i) => {
          let img = val.replace(
            "https://terminus-master.sgp1.digitaloceanspaces.com/",
            ""
          );
          return (
            <Col span={4} key={i}>
              <div style={{ width: "150px", height: "150px", padding: "20px" }}>
                <img
                  alt="example"
                  src={`https://terminus-master.sgp1.digitaloceanspaces.com/${img}`}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </Col>
          );
        })}
      </Row>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Row>
            <Col span={10}>
              <SubTitleText>{translate("productType", "p")}</SubTitleText>
            </Col>
            <Col span={7}>
              <SubTitleText>{translate("job_price", "j")}</SubTitleText>
            </Col>
            <Col span={7}>
              <SubTitleText>{translate("unit", "u")}</SubTitleText>
            </Col>
          </Row>

          <Row>
            <Col span={10} style={{ paddingRight: "10px" }}>
            <Text>{translate(data.product_type, "m_product_type")}</Text>
            </Col>
            <Col span={7}>
              <Text>{numberFormat(data.price)}</Text>
            </Col>
            <Col span={7}>
              <Text>{data.unit_price}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col span={10}>
          <Row>
            <Col span={24}>
              <SubTitleText>{translate("job_vehicle_type", "j")}</SubTitleText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  width: "170px",
                  height: "90px",
                  margin: "20px 0px 0px 0px",
                  padding: "10px",
                }}
              >
                <img
                  alt="example"
                  src={`/img/vehicletype/${data.name_key}.png`}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
              <br />
              <Text>{translate(data.name_key, "vehicle_type")}</Text>
            </Col>
          </Row>
        </Col>

        <Col span={14}>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <SubTitleText>{translate("job_porter", "j")}</SubTitleText>
                </Col>
                <Col span={12}>
                  <SubTitleText>{translate("job_payment", "j")}</SubTitleText>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                <Text>{data.staff_lift === "true" ? translate("have_porter", "h") : translate("have_no_porter", "h")}</Text>
                </Col>
                <Col span={12}>
                  <Text>{translate(data.service_payment_type, "worksheet")}</Text>
                </Col>
              </Row>
              <Row style={{ marginTop: "70px" }}>
                <Col span={12}>
                  <SubTitleText>{translate("job_description", "j")}</SubTitleText>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Text>{data.comment}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <DividerStyled />

      {data.status === "A" && (
        <Row gutter={[24, 24]} type="flex" align="bottom">
          <Col span={6}>
            <TitleText>{translate("quotation", "q")}</TitleText>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default JobDetail;

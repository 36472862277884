import React from "react";
import { Switch, Route } from "react-router-dom";
import Myworklist from "./Myworklist";
import Detail from "./Detail";
import AllQuotations from "./AllQuotations"
export default () => (
    <Switch>
        <Route exact path="/productowner/allwork" component={Myworklist} />
        <Route exact path="/productowner/allwork/quotations/:id" component={AllQuotations} />
        <Route exact path="/productowner/allwork/view/:id" component={Detail} />
    </Switch>
);
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  Dropdown,
  TreeSelect,
  Card,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Badge,
  Divider,
  Spin,
  Collapse,
  Tooltip,
} from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";

export class PinPointOfInterest extends Component {
  render() {
    // lat this.props.pinLat)}

    return (
      <div
        style={{
          backgroundColor: "white",
          width: this.props.descriptionBoxDrawerWidth,
          right: 0,
          top: 0,
          height: "250px",
          padding: "10px 0px 0px 0px",
          position: "fixed",
          zIndex: "20",
          marginLeft: "775px",
          marginTop: "49vh",
          overflowX: "hidden",
          transition: "0.5s",
        }}
      >
        <Row>
          <Col span={24} align="left">
            <a
              href="javascript:;"
              onClick={this.props.handleDescriptionBoxDrawerClose}
              style={{
                margin: "0px 12px 0px 12px",
                zIndex: "1",
              }}
            >
              <Icon type="close" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col span={24} align="center">
            <img
              style={{
                height: "60px",
                padding: "10px 15px 0px 15px",
              }}
              src={
                this.props.showDistance
                  ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADMUlEQVRoge2Zv2sUURDHP3McwUpEDsuwlVhYRNNYb+wtrFLmf7gUIX+Cy5G/wb9AJGDldso1IlgpiCxBrUKwEglhx+Leu3v3Y293dt+RFBl4vO/dvZ03s/N9M7N7glGS99lzRF8CfRBA3S91WNznSlyi8q7YG76x2CP1SwLj8+wZ8AHV3uRKAVVqsU1eFOnwbdPFPaPyFOghwtSwOqwAOpmbYNizGGRzQOmh6jGNsF1MNvVNqkNKiBrxop4a3FCsEXBzYFgd1uDaJtgoxgi4HaZ3SkP8FeELSjm1xPuznHgege7M6fRYbWGwOaChFQTW8RuR3SId/m2iJsmzPiqfER4vZ1tbGGwUCpLMAj5rajxAkQ6vEL6v/HGjEaCCQm2yja8Ti4f4WijUIntM6sSyquuhUEwxRsBaid1dkln6E5kVrlbK/LxIz2ZipJDOQj/H3Rah8LrmiiPmm2GkUETOSHD6lRk27tGOQn6eq8wtJTR+45X4lkKLum4MhXiY5NmDpiqSPLuLsjPTd+0U4j7wLcmzHxUXThc6vI0wgO4UMnaja8N7D3i69O20E5UVXWnQcnt8TRRa09+v4PlabJNYFFqDxYZvXBayRsG4h7Gdpl3R0gDU4Y32QvCzWy1o9DT/y6LR+lD/Gjg1XjOb6zA6BkYW9ebbmeTZANVPwLbtytrXkBcIT4r08Myi1ZxGi3R4jsg+IpeTXqbpYB0uETmwGt/KAefER+C40eJmteIEaPw+NJRWDjgZAaeosnbgZ1ZjYQxyVKTDVkZ0ai+T/NUApMV58KIXILtFOiza2tAlAhTp4TmwD1xVLqqkDSXIQRfjoaMDMD0PR3YKcWL5H6BKOjvgZITIqSELjYGjGBtHe8RK8mwALJ8H30JP03533ocSKwKT+gD7qF6uoVAJdOZ9KNEcAHceRI4rKYScgHTmfShRHQBAXX2YffZgjNA631fJJt5uruqXLtz/B0XsveJHgKV+qUTi8j6UjTgAYb+k0Xkfiv2JzCYjkF5s3t/KTZJYWWjLjb4b/mz5uQzmKzcu3egkMRy4w+StnDUhlMAf4F+XzWPWgS0mTtRFwEeh890H+A8e4J/aNz46/gAAAABJRU5ErkJggg=="
                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg=="
              }
            />
          </Col>
          <Col
            span={24}
            align="center"
            style={{
              padding: "0px 6px 0px 6px",
            }}
          >
            <Divider />
            <h2>{this.props.descriptionBoxHeader}</h2>
            <h2>{this.props.descriptionBoxDetail}</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24} align="center">
            <Button type="primary">
              <a
                href={`http://www.google.com/maps?layer=c&cbll=${this.props.pinLat},${this.props.pinLng}`}
                target="_blank"
              >
                Google Street View
              </a>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PinPointOfInterest;

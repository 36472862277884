const th = {
  a: {
    allStatusDashCameras: "ทั้งหมด",
    amountPlantIsRequired: "กรุณากรอกจำนวนแพลนต์ใกล้เคียง",
    alarm: "Alarm",
    alert: "Alert",
    affiliation: "ต้นสังกัด",
    allowParkingInside: "อนุญาตให้จอดในสถานที่เท่านั้น",
    allowParkingOutside: "อนุญาตให้จอดนอกสถานที่เท่านั้น",
    allowPTODoorInside: "อนุญาตให้เปิด PTO/ประตู ในสถานที่เท่านั้น",
    allowPTODoorOutside: "อนุญาตให้เปิด PTO/ประตู นอกสถานที่เท่านั้น",
    allowBOOMInside: "อนุญาตให้เปิด BOOM ในสถานที่เท่านั้น",
    allowBOOMOutside: "อนุญาตให้เปิด BOOM นอกสถานที่เท่านั้น",
    after5min: "ผ่านไป 5 นาที",
    after30min: "ผ่านไป 30 นาที",
    after1hour: "ผ่านไป 1 ชั่วโมง",
    after4hour: "ผ่านไป 4 ชั่วโมง",
    after8hour: "ผ่านไป 8 ชั่วโมง",
    after1day: "ผ่านไป 1 วัน",
    alertNotiOnly: "แจ้งเตือนอย่างเดียว",
    actionRequired: "แจ้งเตือนพร้อมเปิดเคส",
    allowWorkingTime: "ช่วงเวลาที่อนุญาติทำงาน",
    apply: "ปรับใช้",
    action: "ดำเนินการ",
    amount: "ครั้ง",
    all: "ทั้งหมด",
    addVehicleGroup: "เพิ่มกลุ่มพาหนะ",
    addGroup: "เพิ่มเข้ากลุ่ม",
    actualTransitDistance: "ระยะทางที่ขนส่งจริง",
    adjustTheTable: "ปรับตาราง",
    african: "แอฟริกัน",
    arab: "อาหรับ",
    agency: "เอเจนซี่",
    assignTo: "ผู้รับมอบหมาย",
    account: "บัญชีผู้ใช้",
    address: "ที่อยู่",
    active: "Active",
    add: "เพิ่ม",
    administrator: "เห็นข้อมูลแบบผู้ดูแลระบบ",
    addInformation: "เพิ่มข้อมูล",
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      "รายงานแจ้ง Alarm และ Alert แยกตามทะเบียน รายปี",
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      "รายงานแจ้ง Alarm และ Alert แยกตามทะเบียน รายวัน",
    avgEarningJob: "Avg. Earning/Job",
    ACCUMULATEEARNINGSTATISTICS: "ACCUMULATE EARNING STATISTICS",
    addARow: "เพิ่มเเถว",
    alertAlarm: "ระบบการแจ้งเตือนการฝ่าฝืน",
    announcement: "วันที่ประกาศ",
    announcementBroadcast: "กระจายข่าว / เผยแพร่ข่าว ...",
    allowSharedPartnerEdit: "ผู้ว่าจ้างสามารถแก้ไข",
    addDrivinglicense: "ใบอนุญาตขับขี่อื่นๆ",
    amountPlant: "จำนวนแพลนต์ใกล้เคียง",
    accessory: "อุปกรณ์เสริม",
    and: "เเละ",
    appointment_date: "วัน-เวลานัดหมาย",
    appointment_place: "สถานที่นัด",
    amphur: "เขต/อำเภอ",
    aliasTableCompany: "ชื่อย่อ",
    actionTime: "เวลา",
    accountName: "ผู้ใช้งาน",
    appointmentLocation: "สถานที่นัดหมาย",
    allStatusDashCameras: "ทั้งหมด",
    activeStatusImage: "รูปภาพแสดงสถานะกำลังใช้งาน",
    attachment_type: "ประเภทไฟล์",
    alert_setting_filter: "กรุณาตั้งค่ารายงาน",
    available: "พร้อมใช้งาน",
    already_have_account: "มีบัญชีเข้าใช้งานแล้ว",
    add_contact: "เพิ่มผู้ติดต่อ",
    admin_system: "สำหรับผู้ดูแลระบบ",
    add_destination: "เพิ่มปลายทาง",
    add_location: "เพิ่มสถานที่",
  },

  b: {
    BOOM: "BOOM",
    behavior: "พฤติกรรม",
    BOOKINGSTATISTICS: "สถิติการจอง",
    booking: "งานจอง",
    baseTemplate: "Template พื้นฐาน",
    base: "พื้นฐาน",
    bookedDate: "วันที่จอง",
    busStopPoint: "ป้ายรถเมย์หลัก",
    busStop1: "ป้ายรถเมล์ 1",
    busStop2: "ป้ายรถเมล์ 2",
    boxNumber: "เบอร์กล่อง",
    boxID: "รหัสกล่อง",
    bookingRemark: "Booking Remark",
    bookingDate: "วันที่จอง",
    button: "ปุ่ม",
    back: "กลับ",
    bookingPool: "งานจอง",
    bookingOverview: "ภาพรวมงานจอง",
    brand: "ยี่ห้อ",
    behavior_report_menu: "พฤติกรรม",
    bodyNoOfInstall: "หมายเลขตัวรถที่ติดตั้ง",
    bodyNo: "หมายเลขตัวถังพาหนะ",
    body_email: "ข้อความเพิ่มเติม",
    boatDeliveryDashboard: "ระบบติดตามสถานะเรือ",
    boatDeliveryReport: "รายงานการเดินทางเรือ",
    broken: "เสีย",
  },

  c: {
    confirm_quotation_success: "ยืนยันใบเสนอราคาสำเร็จ",
    confirm_quotation_unsuccess: "ยืนยันใบเสนอราคาไม่สำเร็จ",
    confirm_quotation: "ยืนยันใบเสนอราคา",
    confirm_quotation_desc: "คุณต้องการยืนยันใบเสนอราคานี้",
    carNotOnline: "รถไม่ออนไลน์",
    connectionLost: "ขาดการติดต่อ",
    contactSupportForUserThisMenu:
      "เนื่องจากมีข้อมูลสถานที่เป็นจำนวนมาก กรุณาติดต่อซัพพอร์ทเพื่อใช้งานเมนูนี้",
    criteria: "เงื่อนไข",
    continuousDrive: "ทำงานต่อเนื่องได้ไม่เกิน",
    continuousDriveInADay: "ทำงานต่อเนื่องในหนึ่งวันได้ไม่เกิน",
    criticalLevel: "ระดับความรุนแรง",
    category: "หมวด",
    concreteDeliveryDashboard: "สถานะการส่งคอนกรีต",
    collapse: "ย่อ",
    close: "ปิด",
    clear: "ล้าง",
    customer: "ลูกค้า",
    customerDetail: "รายละเอียดลูกค้า",
    chineseKoreanJapanese: "จีน/ กาหลี/ญี่ปุ่น",
    communityArea: "เขตชุมชน",
    crossroad: "ในทางเเยก",
    customerName: "ชื่อลูกค้า",
    createdAt: "สร้างเมื่อ",
    customerType: "ประเภทลูกค้า",
    criticality: "ภาวะวิกฤต",
    comment: "รายละเอียด",
    channel: "ช่องทาง",
    closed: "ปิด",
    confirmToDeleteThisItem: "ยืนยัน ต้องการลบรายการนี้",
    carService: "ศูนย์บริการ",
    confirmToAddThisItem: "ยืนยัน ต้องการเพิ่มรายการนี้",
    confirmToEditThisItem: "ยืนยัน ต้องการเเก้ไขรายการนี้",
    changeLanguage: "เปลี่ยนภาษา",
    company: "บริษัท",
    confirmPassword: "ยืนยันรหัสผ่าน",
    cancelled: "ยกเลิก",
    cancel: "ยกเลิก",
    connection: "เชื่อมต่อสัญญาณ",
    currentAddress: "ที่อยู่ปัจจุบัน",
    chauffeur: "พนักงานขับขี่",
    clearUp: "ล้างค่า",
    cancelReason: "เหตุผลการยกเลิก",
    changeProduct: "เปลี่ยนสินค้า",
    confirm: "ยืนยัน",
    configuration: "ปรับค่า",
    custom: "ปรับแต่ง",
    contractor: "ผู้รับจ้าง",
    currentVehicle: "พาหนะปัจจุบัน",
    courseName: "ชื่อหลักสูตร",
    Color: "สี",
    companyBilling: "บริษัทที่เรียกเก็บเงิน",
    chasisSerialNumber: "หมายเลขตัวถัง",
    createdBy: "สร้างโดย",
    CompareVehicleSccoReport: "รายงาน เปรียบเทียบพาหนะ SCCO",
    cycleOfDrumRotation: " จำนวนรอบการหมุนของโม่",
    contact_name: "ชื่อบุคคลติดต่อ",
    contact_telno: "เบอร์ติดต่อ",
    confirm_appointment_place: "อาจมีการเปลี่ยนแปลงจังหวัด และอำเภอ",
    created_by: "สร้างโดย",
    confirm_type: "ยืนยันการส่งโดย",
    compulsory: "ประกันภัยรถยนต์ภาคบังคับ (พ.ร.บ.)",
    carTax: "ภาษีรถยนต์",
    customerID: "Customer ID",
    companyCode: "รหัสบริษัท",
    closeFixed: "เรียบร้อย-ปิดตั๋ว",
    closeNotAvailable: "รถไม่พร้อม-ปิดตั๋ว",
    contactName: "ชื่อบุคคลติดต่อ",
    contactTelno: "เบอร์ติดต่อ",
    contact: "ติดต่อ",
    Contactmanagement: "เบอร์โทรศัพท์พาหนะ",
    camera_position: "ตำแหน่งกล้อง",
    camera_back: "กล้องในรถ",
    cc_email: "สำเนา",
    create_trip: "สร้างทริป",
    company_require: "กรุณากรอกบริษัท",
    confirm_password: "ยืนยันรหัสผ่าน",
    captcha_require: "กรุณาตอบคำถามแคปต์ชา (Captcha)",
    cell_phone: "โทรศัพท์มือถือ",
    contactinfo: "ข้อมูลติดต่อ",
    copy_id_card: "สำเนาบัตรประชาชน",
    copy_driver_license:
      "สำเนาใบอนุญาตขับรถสาธารณะหรือส่วนบุคคลตั้งแต่ประเภท 2 ขึ้นไป",
    company_select_require: "กรุณาเลือกบริษัท",
    contactPerson: "ผู้ติดต่อ",
    companyProfile: "ข้อมูลบริษัท",
    company_verify: "ตรวจสอบบริษัท",
    confirmVerifyCompany: "คุณต้องการยืนยันการตรวจสอบบริษัทนี้ใช่หรือไม่?",
    confirmVerifyVehicle: "คุณต้องการยืนยันการตรวจสอบพาหนะนี้ใช่หรือไม่?",
    clickToViewFile: "คลิกเพื่อเปิดไฟล์",
    create_success: "เพิ่มข้อมูลสำเร็จ",
    create_fail: "เพิ่มข้อมูลไม่สำเร็จ",
    create_user: "สร้างผู้ใช้งาน",
    create_usergroup: "สร้างกลุ่มผู้ใช้งาน"
  },

  d: {
    dashboard_your_work: "งานของคุณ",
    dashboard_transport_market: "ตลาดงานขนส่ง",
    dashboard_work_qt: "งานที่เสนอราคา",
    dashboard_work_process: "งานที่กำลังดำเนินการ",

    dayTime: "ช่วงเวลากลางวัน",
    driving: "ขับพาหนะ",
    daily: "รายวัน",
    diff: "Diff",
    deliveryStatus: "สถานะการจัดส่ง",
    dailyCarStatusReport: "รายงาน แยกสถานะพาหนะ รายวัน",
    driveOverTheSpeedLimitInTheCrossroads: "ขับขี่ความเร็วเกินกำหนด ในทางแยก",
    dailyDrivingBehaviorReport: "รายงาน พฤติกรรมการขับขี่ รายวัน",
    DailyAlarmAndAlertReportByDriver:
      "รายงานแจ้ง Alarm และ Alert แยกตามพนักงานขับขี่ รายวัน",
    dateRange: "ช่วงวันที่",
    deletedAt: "ลบข้อมูลเมื่อ",
    deletedBy: "ลบข้อมูลโคย",
    deleteReason: "เหตุผลการลบ",
    draft: "draft",
    doYouWantToConfirmSend: "คุณต้องการยืนยันการส่งหรือไม่",
    deleteInformation: "ลบข้อมูล",
    delete: "ลบ",
    daYouWantToReActive: "คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่",
    draw: "วาด",
    data: "ข้อมูล",
    distance: "ระยะทาง",
    deliverPoint: "จุดส่งของ",
    doYouWantToCancel: "คุณต้องการยกเลิกหรือไม่",
    doYouWantToSendAnEmailToUsersToVerifyTheirIdentity:
      "คุณต้องการส่งอีเมลไปยังผู้ใช้งานเพื่อยืนยันตัวตนหรือไม่",
    doYouWantToDeleteTheseItems: "คุณต้องการลบรายการเหล่านี้หรือไม่",
    doYouWantToAddTheseItems: "คุณต้องการเพิ่มรายการเหล่านี้หรือไม่",
    doYouWantToEditTheseItems: "คุณต้องการเเก้ไขรายการเหล่านี้หรือไม่",
    dateTime: "วัน/เวลา",
    description: "รายละเอียด",
    description_onetime: "รายละเอียดงานเที่ยวเดียว",
    description_routine: "รายละเอียดงานประจำ",
    displayTime: "เวลาการเเสดงผล",
    dateOfSubmission: "วันที่ยื่นคำขอ",
    dateSubmit: "วันที่ส่งเอกสาร",
    deliveryAddress: "ที่อยู่สำหรับการจัดส่ง",
    detail: "รายละเอียด",
    driveTooFastInTheNormalRoute: "ขับขี่เร็วเกินกำหนด ในเส้นทางปกติ",
    driveTooFastInTheCommunity: "ขับเร็วเกินกำหนด ในเขตชุมชน",
    driveContinuouslyForMoreThan4Hours: "ขับต่อเนื่อง > 4 ชม.",
    driveContinuouslyForMoreThan10HoursDay: "ปฏิบัติงาน > 10 ชม./วัน",
    doNotParkBesideTheRoad: "ห้ามจอดไหล่ทาง",
    doNotSwipeCardsOrDamageCards: "ไม่รูดบัตรหรือบัตรเสียหายหนัก",
    drivingTime: "เวลาการขับขี่",
    doYouWanttoReActive: "คุณต้องการที่จะเปิดใช้งานอีกครั้งหรือไม่",
    destinationDetail: "รายละเอียดจุดหมาย",
    driverName: "ชื่อพนักงานขับขี่",
    driverCode: "รหัสพนักงานขับขี่",
    driverPhoneNumber: "เบอร์โทรพนักงานขับขี่",
    destinationLocation: "สถานที่ปลายทาง",
    destination: "จุดหมาย",
    done: "เสร็จสิ้น",
    drivingPeriodRange: "ช่วงระยะเวลาการขับขี่(นาที)",
    day: "วัน",
    descrip: "รายละเอียด",
    dashCamera: "ระบบเฝ้าดูผ่านแดชแคม",
    deliveryItemPool: "งานส่งอุปกรณ์ (EZY GPS)",
    deliveryItemDeleteHistoryPool: "ประวัติงานส่งอุปกรณ์ (EZY GPS)",
    deliveryItem: "การส่งอุปกรณ์",
    dataVisibility: "การมองเห็นข้อมูล",
    disconnected: "ขาดการติดต่อ",
    dateOfBirth: "วันเกิด",
    drivinglicense: "ใบอนุญาตขับขี่",
    drivingIicenseNumber: "หมายเลขใบขับขี่",
    drivingIiceneType: "ประเภทใบขับขี่",
    driverHistory: "ประวัติพนักงานขับขี่",
    date: "วันที่",
    detailOfConcreteTruck: "รายละเอียดสำหรับรถบรรทุกคอนกรีต",
    drumCapacity: "ความจุโม่",
    dischargeDenominator: "ค่าคำนวณปริมาณในการเท",
    dataForDLT: "Data For DLT",
    driver2: "พนักงานขับขี่ 2",
    driver1: "พนักงานขับขี่ 1",
    DrivingdistanceReport: "รายงานระยะทางการวิ่ง",
    DistfromlastmaintenanceReport: "รายงานพาหนะที่มีระยะทางวิ่งสะสมล่าสุด",
    duration: "ระยะเวลา",
    DriverinformationReport: "รายงานข้อมูลพนักงานขับขี่",
    distance_more_than: "ระยะทางที่ถือว่าทำงานต่อวัน",
    DrivinglicensescanlogReport: "รายงานการรูดใบขับขี่",
    DrivinglogReport: "รายงานบันทึกการขับขี่",
    DrivinglogspecialReport: "รายงานบันทึกการขับขี่ (ใหม่)",
    DozeoffriskReport: "รายงานเสี่ยงหลับใน",
    DeliveryReport: "รายงานการส่งสินค้า",
    DailyReport: "รายงานสรุปการวิ่งประจำวัน",
    distance_less_than: "ระยะทางน้อยกว่า",
    DrivinghourReport: "รายงานพฤติกรรมการขับขี่",
    deliver: "การขนส่ง",
    DrivingoverlimitReport: "รายงานพาหนะวิ่งงานเกินเวลาที่กำหนด",
    dangerous: "อันตราย",
    detailproblem: "วิธีการแก้ไข",
    doc_no: "เลขเอกสาร",
    document: "เอกสาร",
    durationOfPTOGate1On: " ระยะเวลาเปิด PTO/ประตู1",
    mdvrdownloadfile: "ดาวน์โหลดวีดีโอ MDVR",
    download: "ดาวน์โหลด",
    detail_problem: "อาการของปัญหา(อื่นๆ โปรดระบุ)",
    deliverytrip: "ทริปการส่งของ",
    Delivered: "ส่งของแล้ว",
    deliver_status: "สถานะส่งของ",
    destinationLocationcode: "รหัสสถานที่ปลายทาง",
    device: "อุปกรณ์",
    deliver_duration: "เวลาเริ่ม - เวลาสิ้นสุด",
    dataType: "ชนิดข้อมูล",
    daysBeforeNotification: "จำนวนวันก่อนแจ้งเตือน",
    defaultLang: "Default lang",
    defaultLatitude: "Default latitude",
    defaultLongitude: "Default longitude",
    detailProblemServiceMoveDevice: "อาการของปัญหาที่ได้รับแจ้ง",
    date_time: "วันที่ และเวลา",
    downloadcertificate: "ดาวน์โหลดใบรับรอง",
    default: "ค่าเริ่มต้น",
    devicePhone: "เบอร์โทรกล่อง",
    dashboard: "แดชบอร์ด",
    defaultImageBylocationType: "รูปค่าเริ่มต้น ตามชนิดสถานที่",
    DailyiconsiamReport: "รายงานการให้บริการเรือ / รถ แบบรายวัน",
    dashboard: "แดชบอร์ด",
    duration_time: "ช่วงเวลา",
    date_entry: "วันที่เข้า",
    date_out: "วันที่ออก",
    date_time_entry: "วันที่-เวลาเข้า",
    date_time_out: "วันที่-เวลาออก",
    donothaveaccount: "ยังไม่มีบัญชีผู้ใช้?",
    direct: "Direct",
    driver_required: "กรุณาเลือกพนักงานขับขี่",
    device_required: "กรุณาเลือกรถ"
  },

  e: {
    eventEnd: "จบเหตุการณ์",
    expand: "ขยาย",
    edit: "เเก้ไข",
    estTransitDuration: "ระยะเวลาการเดินทาง",
    estTransitDistance: "ระยะทางการเดินทาง",
    estFare: "ค่าโดยสาร",
    engine: "เครื่องยนต์",
    endLocationName: "ชื่อปลายทาง",
    endLocationCode: "รหัสปลายทาง",
    earning: "Earning",
    EarningReceivedSTATISTICS: "Earning/Received STATISTICS",
    earningType: "Earning Type",
    event: "เหตุการณ์",
    email: "อีเมล",
    eventAlarm: "Event Alarm",
    emergencyCase: "SOS",
    emergencyStatus: "สถานะฉุกเฉิน",
    endDate: "วันสิ้นสุด",
    etc: "อื่นๆ",
    europeAmerican: "ยุโรป/อเมริกัน",
    getVehicles: "แสดงพาหนะ",
    endDestinationCode: "รหัสปลายทาง",
    endDestinationName: "ชื่อปลายทาง",
    employeeID: "รหัสพนักงาน",
    engineStatus: "สถานะเครื่องยนต์",
    engineOn: "ติดเครื่อง",
    engineOnWithImg: "ติดเครื่องพร้อมข้อมูลรูปภาพ",
    engineOff: "ดับเครื่อง",
    engineOnDesc: "ตำแหน่งที่ส่งข้อมูล GPS ขณะติดเครื่อง",
    engineOnWithImgDesc:
      "ตำแหน่งที่ส่งข้อมูล GPS พร้อมรูปภาพจาก Dash camera ขณะติดเครื่อง",
    engineOffDesc: "ตำแหน่งที่ส่งข้อมูล GPS ขณะดับเครื่อง",
    earningOverview: "ภาพรวมรายได้",
    eventMap: "ภาพรวมพิกัดการฝ่าฝืน",
    expireDate: "วันที่หมดอายุ",
    endValidDate: "วันที่สิ้นสุดการใช้พาหนะ",
    emailVerificationStatus: "สถานะการยืนยันอีเมล์",
    emailVerification: "รอการยืนยันอีเมล",
    expense: "ค่าใช้จ่าย",
    excel: "Excel",
    EventReport: "รายงานเหตุการณ์พาหนะรายคัน",
    engine_on_time_end_at: "เวลาติดเครื่องสิ้นสุด",
    engine_on_time_start_at: "เวลาติดเเครื่องเริ่มต้น",
    engine_off_time_end_at: "เวลาดับเครื่องสิ้นสุด",
    engine_off_time_start_at: "เวลาดับเครื่องเริ่มต้น",
    efficiency_report_menu: "ประสิทธิภาพ",
    energy_report_menu: "พลังงาน",
    export_date_at: "วันที่ออก",
    engineOnDuration: "ระยะเวลาติดเครื่องยนต์",
    endAt: "เวลาสิ้นสุด",
    expenseBaht: "ค่าใช้จ่าย (บาท)",
    expiryDate: "วันหมดอายุ",
    employeeInformation: "ข้อมูลพนักงาน",
    emailAE: "อีเมล์ AE",
    editStatus: "สถานะ",
    emailVerificationSuccessful: "ยืนยันอีเมลแล้ว",
    email_subject: "หัวข้ออีเมล",
    emailorphone: "อีเมลหรือเบอร์โทรศัพท์",
    emailorphone_require: "กรุณากรอกอีเมลหรือเบอร์โทรศัพท์",
    emailorphone_invalid: "กรุณากรอกอีเมลหรือเบอร์โทรศัพท์ที่ถูกต้อง",
    email_require: "กรุณากรอกอีเมล",
    email_invalid: "อีเมลที่กรอกไม่ถูกต้อง",
    expenses: "เส้นทาง-ราคาขนส่ง",
    evaluationsSuccessfully: "ให้คะแนนเรียบร้อยแล้ว",
    edit_user: "แก้ไขผู้ใช้งาน",
    edit_usergroup: "แก้ไขกลุ่มผู้ใช้งาน"
  },

  f: {
    fillPosition: "ป้อนตำแหน่ง",
    finished: "เสร็จสิ้น",
    fuel: "เชื้อเพลิง",
    facilityName: "ชื่อสถานที่",
    facilityName2: "ชื่อสถานที่2",
    facilityName3: "ชื่อสถานที่3",

    findDistance: "หาระยะทาง",
    findSpace: "หาพื้นที่",
    findCustomer: "ค้นหาลูกค้า",
    fillInFrom: "Fill In From",
    firstName: "ชื่อ",
    finish: "เสร็จสิ้น",
    fillInfomation: "กรอกข้อมูล",
    filter: "กรอง",
    fare: "ค่าโดยสาร",
    file: "ไฟล์",
    fine: "ปรับ",
    firmwareVersion: "เวอร์ชันเฟิร์มแวร์",
    fuelType: "ประเภทเชื้อเพลิง",
    flateNumber: "Flate Number",
    fuelTankSize: "ความจุถังเชื้อเพลิง",
    fuelA: "Fuel A",
    fuelB: "Fuel B",
    fuelC: "Fuel C",
    fuelUsage: "อัตราสิ้นเปลืองเชื้อเพลิง",
    fuelRate: "Fuel Rate",
    fullTank: "เต็มถัง",
    forgot_password: "ลืมรหัสผ่าน",
    filter_type1: "ออกจากโรงงานครั้งแรกถึงเข้าโรงงานครั้งสุดท้าย",
    filter_type2: "ติดเครื่องครั้งแรกถึงดับเครื่องครั้งสุดท้าย",
    filter_type: "รูปแบบ",
    first_dest: "จุดเริ่มต้น",
    firstMaintenanceConditions: "เงื่อนไขการซ่อมบำรุงครั้งแรก",
    file_location: "สถานที่เก็บไฟล์",
    file_size: "ขนาดไฟล์",
    finishRefuel: "จบการเติมเชื้อเพลิง",
    fixed: "ซ่อม",
    Fullscreen: " ขยายเต็มจอ",
    fileapetition: "ยื่นคำร้อง",
    flatRate: "เหมา",
    fillInForm: " เติมข้อมูลลงในฟอร์ม",
    front: "กล้องนอกรถ",
    filenotreadydownload: "ไม่สามารถดาวน์โหลดไฟล์ได้ในขณะนี้",
    fail: "ไม่สำเร็จ",
    fuelremainrateReport: "รายงานน้ำมันคงเหลือ",
    filter_by: "กรองข้อมูลโดย",
    forgotpassword: "ลืมรหัสผ่าน",
    fail_save: "ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง",
  },

  g: {
    gpsNotFixed: "GPS ไม่เกาะ",
    gpsNotFixedDesc: "พิกัดไม่แม่นยำ อาจทำให้ตำแหน่งคลาดเคลื่อนจากความเป็นจริง",
    general: "ทั่วไป",
    generateMap: "Generate Map",
    gasStation: "ปั้มน้ำมัน",
    gate1: "ประตู 1",
    gate2: "ประตู 2",
    gate3: "ประตู 3",
    groupVehicleType: "กลุ่ม/ประเภทพาหนะ",
    groupByLocation: "จัดกลุ่มตามสถานที่",
    gpsCertification: "ใบรับรองการติดตั้ง",
    gpsCertRequest: "ขอใบรับรองการติดตั้ง (EZY GPS)",
    gpsCertRequestHistory: "ประวัติการขอใบรับรองการติดตั้ง (EZY GPS)",
    group: "กลุ่ม",
    generalLocation: "General Location",
    greater_than: "มากกว่า",
    GpsstatusReport: "รายงานพาหนะที่สัญญาณขาดหาย",
    group_by: "จัดกลุ่มโดย",
    group_idle: "เฉพาะจอดติดเครื่อง",
    group_speed: "เฉพาะความเร็วเกิน",
    general_report_menu: "ทั่วไป",
    greaterOrEqual: "มากกว่าเท่ากับ",
    gps_update: "ตำแหน่งล่าสุด",
    googleMapApiKey: "Google Map Api Key",
    group_data: "Group ข้อมูล",
    gallery_name: "ชื่อ",
    gallery: "แกลเลอรี่",
    goBackToUseImagesByLocationType: "กลับไปใช้รูปภาพตามประเภทสถานที่",
    get_verify_code: "ขอ OTP",
    group_name_require: "กรุณากรอกชื่อกลุ่ม",
  },

  h: {
    hour: "ชั่วโมง",
    hotel: "โรงเเรม",
    high: "สูง",
    home: "หน้าหลัก",
    header: "หัวข้อ",
    hardwareID: "รหัสฮาร์ดแวร์",
    horsePower: "เเรงม้า",
    height: "สูง",
    hours: "ชม.",
    help_menu: "ช่วยเหลือ",
    history_servicerepairdevice: "ประวัติ",
    honeytoastlivestream: "วีดีโอเรียลไทม์จากฮันนีโทสท์",
    honeytoastdownloadfile: "ดาวโหลดวีดีโอฮันนีโทสท์",
    honeytoastplayback: "วีดีโอย้อนหลัง Honey toast",
    have_porter: "มี",
    have_no_porter: "ไม่มี",
  },

  i: {
    indexFilterOption_All: "ทั้งหมด",
    indexFilterOption_Unassign: "ยังไม่มีพนักงานขับขี่",
    indexFilterOption_Waiting: "รอพนักงานขับขี่ยืนยัน",
    indexFilterOption_Confirm: "ยืนยัน",
    indexFilterOption_Cancel: "ยกเลิก",
    indexFilterOption_Finish: "เสร็จสิ้น",
    imagesNotUpdate: "รูปภาพไม่อัพเดท",
    inside: "ด้านใน",
    immediatelyAcess: "ทันที",
    itemSelected: "เลือก",
    itemLeft: "เหลือ",
    ignoredAlert: "ไม่แจ้งเตือน",
    iconDesc: "รายละเอียดไอคอน",
    imageMustSmallerThan5MB: "รูปภาพต้องมีขนาดเล็กกว่า 5MB",
    infomation: "ข้อมูล",
    indian: "อินเดีย",
    internalOperation: "การจัดการภายใน",
    issuedDate: "วันที่ออก",
    id: "ID",
    idleFuelRate: "Idle Fuel Rate",
    imageFromDashCamera: "ภาพจากกล้อง",
    InsuranceReport: "รายงานประกันภัยพาหนะ",
    insurance_report_menu: "ประกันภัยพาหนะ",
    invoice_number: "เลข Invoice",
    invoice_date: "วันที่ Invoice",
    insure_report_menu: "ประกันภัย",
    insuranceID: "หมายเลขกรมธรรม์",
    insurance: "ประกันภัยรถยนต์",
    install: "ติดตั้ง",
    installStatus: "สถานะการติดตั้ง",
    installDevice: "ติดตั้งอุปกรณ์",
    imageFromTheGallery: "รูปจากแกลเลอรี่",
    include_engine_off: "รวมข้อมูลดับเครื่อง",
    input_verify_code: "การยืนยันตัวตน",
    info_verify_code: "ยืนยันตัวตนทางโทรศัพท์มือถือหรืออีเมล",
    identification_documents: "เอกสารยืนยันตัวตน",
  },

  j: {
    jobId: "รหัสงาน",
    jobDetail: "รายละเอียดงาน",
    jobStatus: "สถานะงาน",
    jobType: "ประเภทงาน",
    jobTypeSTATISTICS: "สถิติประเภทของงาน",
    joinDate: "วันที่เริ่มงาน",
    jobStartAt: "เวลาเข้างาน",
    job_no: "หมายเลขใบสั่งงาน",
    job_origin: "ต้นทาง",
    job_receive_date: "วันที่รับสินค้า",
    job_receive_time: "เวลา",
    job_description: "รายละเอียดสินค้า",
    job_quantity: "จำนวนสินค้า",
    job_unit: "หน่วย",
    job_img_prd: "รูปเพิ่มเติม",
    job_doc_pic: "รูปเอกสาร ",
    job_price: "ราคา",
    job_price_per_times: "บาท/เที่ยว",
    job_payment: "การชำระค่าบริการ",
    job_porter: "พนักงานยกของ",
    job_vehicle_type: "ประเภทรถที่ต้องการใช้",
    job_description: "หมายเหตุ",
    job_contact_name: "ชื่อผู้ติดต่อ",
    job_contact_surname: "นามสกุล",
    job_phone: "เบอร์โทร",
    job_load_prd: "ติดต่อสำหรับโหลดสินค้า",
    job_receive_prd: "ติดต่อสำหรับรับสินค้า",
    job_upload_qt: "อัพโหลดใบเสนอราคา",
    job_delivery_date: "วันที่จัดส่ง",
    job_product_desc: "รายละเอียดสินค้า",
    job_vehicle_pic: "รูปประเภทรถ",
    job_quotation: "ใบเสนอราคา",
    job_quotation_r: "เสนอราคาใหม่",
  },

  k: {
    kpiEvent: "เหตุการณ์",
    kpiDateTime: "วันที่/เวลา",
    kind: "ชนิด",
    km: "กม.",
    kmH: "กม./ชม.",
    keywordAtleast3character: "คำหลักอย่างน้อย 2 ตัวอักษร",
  },

  l: {
    limitOpenDuration: "ห้ามเปิดเกิน",
    limitParkingDuration: "ห้ามจอดเกิน",
    limitParkingInsideLocation: "ห้ามจอดในสถานที่เกิน",
    limitParkingOutsideLocation: "ห้ามจอดนอกสถานที่เกิน",
    limitSuddenlyBreakThreshold: "เบรคแรงได้ไม่เกิน",
    limitAggesiveAccelerationThreshold: "เร่งแรงได้ไม่เกิน",
    limitDrivingAggesiveThreshold: "ขับพาหนะจี้ท้ายได้ไม่เกิน",
    limitSnoozeThreshold: "สุ่มเสี่ยงหลับในได้ไม่เกิน",
    limitUsingPhoneThreshold: "ใช้โทรศัพท์ขณะขับขี่ได้ไม่เกิน",
    limitParking: "ห้ามจอดเกิน",
    limitGoInsideArea: "ห้ามเข้าพื้นที่เกิน",
    limitGoOutsideArea: "ห้ามออกพื้นที่เกิน",
    load: "โหลด",
    loadStartAt: "โหลดเมื่อ",
    loadAmount: "จำนวนโหลด",
    lastName: "นามสกุล",
    location: "สถานที่",
    locationGroup: "กลุ่มสถานที่",
    lookingForSomething: "ป้อนสถานที่",
    lookingForPin: "ป้อนสถานที่เพื่อทำการปักหมุด",
    lostConnectio: "ขาดการติดต่อ",
    lastRequestOn: "คำขอล่าสุดเมื่อ",
    low: "ต่ำ",
    logout: "ออกจากระบบ",
    login: "เข้าสู่ระบบ",
    locationCode: "รหัสสถานที่",
    latitudeIsRequired: "กรุณากรอกละติจูด",
    longitudeIsRequired: "กรุณากรอกลองติจูด",
    latitude: "ละติจูด",
    longitude: "ลองติจูด",
    loadpoint: "จุดโหลดของ",
    locationTemporary: "สถานที่ชั่วคราว",
    lazada: "ลาซาด้า",
    latestDriver: "พนักงานขับขี่ล่าสุด",
    link: "Link",
    locationInformation: "ข้อมูลสถานที่",
    length: "ความยาว",
    limitDrivingHourTime: "จำกัดระยะเวลาวิ่งงานต่อเนื่อง (นาที)",
    locationGroupName: "ชื่อกลุ่มสถานที่",
    less_than: "น้อยกว่า",
    LoginhistoryReport: "รายงานประวัติการเข้าใช้งานระบบรายคน",
    login_success: "เข้าระบบสำเร็จ",
    login_fail: "เข้าระบบไม่สำเร็จ",
    LocationInSystem: "สถานที่ที่มีอยู่ในระบบ",
    Location: "สถานที่",
    locationShareTo: "สถานที่ที่เเชร์",
    locationShareFrom: "สถานที่ที่ถูกเเชร์",
    lessOrEqual: "น้อยกว่าเท่ากับ",
    lastest_signal: "สัญญาณล่าสุด",
    lastmaintenanceAt: "การซ่อมบำรุงครั้งล่าสุด",
    LocationOfVehicle: "สถานที่ต้นสังกัดของพาหนะ",
    LocationOfDeviceUser: "สถานที่ต้นสังกัดของพนักงานขับขี่",
    longdoMapApiKey: "Longdo Map Api Key",
    liveStream: "วีดีโอเรียลไทม์",
    locationAndRoute: "สถานที่และเส้นทาง",
    locationGroupNameIsRequired: "กรุณากรอกชื่อกลุ่มสถานที่",
    locationGroupTypeIsRequired: "กรุณาเลือกประเภทกลุ่มสถานที่",
    locationName1IsRequired: "กรุณากรอกชื่อสถานที่",
    locationCodeIsRequired: "กรุณากรอกรหัสสถานที่",
    latitudeIsRequiredAndCantBeEmpty: "กรุณากรอกละติจูด",
    LongitudeIsRequiredAndCantBeEmpty: "กรุณากรอกลองติจูด",
    logisticGroup: "กลุ่มขนส่ง",
    logisticGroupName: "ชื่อกลุ่มขนส่ง",
    logisticLogo: "โลโก้",
    logistic_group_add: "ยังไม่มีกลุ่มขนส่งต้องการสร้างกลุ่มขนส่งหรือไม่",
  },

  m: {
    md_companygroup: "ค้นหาบริษัทขนส่ง",
    md_sub_receiver: "ชื่อผู้ร้บแทน",
    md_sending_qty: "จำนวนที่จัดส่ง",
    md_qty: "จำนวนที่ส่งได้",
    md_reson: "เหตุผล",
    md_product: "สินค้า",
    md_departure: "ต้นทาง",
    md_destination: "ปลายทาง",
    md_porter: "พนักงานยกของ",
    md_payment_status: "การชำระค่าบริการ",
    md_vehicle_type: "ประเภทรถ",
    md_status: "สถานะ",
    md_process: "ดำเนินการ",
    md_job: "หมายเลขใบสั่งงาน",
    md_delivery_status: "สถานะการจัดส่ง",
    md_receiver: "ชื่อผู้รับ",
    md_delivery_time: "เวลาจัดส่งสินค้า",
    meterDashCameras: "มิเตอร์",
    monthly: "รายเดือน",
    moisture: "ความชื้น",
    month: "เดือน",
    miss: "นางสาว",
    mrs: "นาง",
    mr: "นาย",
    m: "เมตร",
    meter: "Meter",
    medium: "ปานกลาง",
    message: "ข้อความ",
    masterData: "ข้อมูลหลัก",
    min: "นาที",
    mixingDrum: "โม่ผสมปูน",
    map: "แผนที่",
    mapView: "มุมมองเเผนที่",
    meterStatus: "สถานะ Meter",
    monitoring: "ระบบติดตาม",
    match: "จับคู่",
    maxDrumCapacity: "ความจุโม่สูงสุด",
    mileNo: "เลขไมล์",
    model: "รุ่น",
    machineSerialNumber: "หมายเลขเครื่องยนต์",
    meterSN: "Meter S/N",
    MaintenanceReport: "รายงานพาหนะที่ต้องบำรุงรักษา",
    more_1_min: "มากกว่า 1 นาที",
    more_5_mins: "มากกว่า 5 นาที",
    more_10_mins: "มากกว่า 10 นาที",
    more_5_sec: "มากกว่า 5 วินาที",
    more_10_sec: "มากกว่า 10 วินาที",
    more_30_sec: "มากกว่า 30 วินาที",
    more_60_sec: "มากกว่า 60 วินาที",
    Minimal: "อย่างย่อ",
    managementdevice_report_menu: "การจัดการอุปกรณ์",
    MaintenancedeviceconcludeReport: "รายงานการซ่อม/ถอดอุปกรณ์",
    maintenance_status: "สถานะ",
    maintenanceConditions: "เงื่อนไขการซ่อมบำรุง",
    maintenance: "การบำรุงรักษา",
    minute: "นาที",
    maintenance_type: "ประเภทการแจ้ง",
    move_in: "เข้าจุด",
    move_out: "ออกจุด",
    maintenancedevice_report_menu: "การจัดการอุปกรณ์",
    manage_product: "จัดการสินค้า",
    mapType: "Map Type",
    moveDevice: "เเจ้งย้ายอุปกรณ์",
    move: "ย้าย",
    management_menu: "การจัดการ",
    meter: "มิเตอร์",
    m_january: "มกราคม",
    m_february: "กุมภาพันธ์",
    m_march: "มีนาคม",
    m_april: "เมษายน",
    m_may: "พฤษภาคม",
    m_june: "มิถุนายน",
    m_july: "กรกฎาคม",
    m_august: "สิงหาคม",
    m_september: "กันยายน",
    m_october: "ตุลาคม",
    m_november: "พฤศจิกายน",
    m_december: "ธันวาคม",
    max_speed: "ความเร็วสูงสุด",
    market: "Market",
  },

  n: {
    none: "ปกติ",
    now: "ปัจจุบัน",
    notAllowParking: "จอดในพื้นที่",
    noEntry: "ห้ามเข้า",
    noExit: "ห้ามออก",
    nameAndType: "ชื่อและประเภท",
    notification: "การแจ้งเตือน",
    notificationEndWhen: "จบแจ้งเตือนเมื่อ",
    nightTime: "ช่วงเวลากลางคืน",
    notReadyForWork: "ไม่พร้อมรับงาน",
    newUserGroup: "กลุ่มผู้ใช้บริการใหม่",
    numberOfExpirationDates: "จำนวนวันที่หมดอายุ",
    nameTitle: "คำนำหน้า",
    newRoute: "เส้นทางการวิ่งใหม่",
    newUser: "ผู้ใช้งานใหม่",
    nearPlants: "แพลนต์ใกล้ๆ",
    noParking: "จุดห้ามจอด",
    newLocation: "สถานที่ใหม่",
    newTicket: "Ticket ใหม่",
    nationalityZone: "โซนสัญชาติ",
    newCustomer: "ผู้ใช้บริการใหม่",
    noVehicleSelect: "ยังไม่เลือกพาหนะ",
    nationality: "สัญชาติ",
    name: "ชื่อ",
    namegroup: "ชื่อกลุ่มผู้ใช้งาน",
    namegroup_require: "กรุณากรอกชื่อกลุ่มผู้ใช้งาน",
    newJob: "งานใหม่",
    no: "#",
    number: "จำนวน",
    nounsCar: "คัน",
    new: "เพิ่ม",
    next: "ถัดไป",
    notificationType: "ประเภทการเเจ้งเตือน",
    node: "Node",
    nodeName: "ชื่อ Node",
    nextRegisterDate: "วันต่ออายุประกันภัยพาหนะ",
    nearByLocation: "สถานที่ใกล้เคียง",
    nearbyPlants: "เเพลนต์ใกล้สุด",
    NumberofdaysworkedReport: "รายงานจำนวนวันที่พาหนะวิ่งงาน",
    number_of_days: "จำนวนวัน",
    no_filter: "ไม่กรอง",
    numberVehiclegroup: "จำนวน (คัน)",
    nextMaintenanceConditions: "เงื่อนไขการซ่อมบำรุงครั้งต่อไป",
    nameTableCompany: "ชื่อ",
    nameAE: "ชื่อ AE",
    nofilevideo: "ไม่สามารถสตรีมวีดีโอในเวลาที่เลือกได้",
    normalStatusImage: "รูปภาพแสดงสถานะปกติ",
    notificationTracking: "การแจ้งเตือน ติดตามพาหนะ",
    notAvailable: "ไม่พร้อมใช้งาน",
  },

  o: {
    on: "ติด",
    off: "ดับ",
    onDevelop: "อยู่ระหว่างการพัฒนา",
    onFilter: "ติด",
    offFilter: "ดับ",
    onFilterMeter: "เปิด",
    offFilterMeter: "ปิด",
    outside: "ด้านนอก",
    onProcess: "อยู่ระหว่างการส่ง",
    originToDestination: "ต้นทาง-ปลายทาง",
    overview: "ภาพรวม",
    other: "อื่นๆ",
    obd: "OBD",
    openFilter: "เปิดการค้นหา",
    or: "หรือ",
    overSpeedInNormalRoutes: "ความเร็วเกินกำหนดในเส้นทางปกติ",
    overdueSpeedInTheCommunityArea: "ความเร็วเกินกำหนดในเขตชุมชน",
    overSpeedInTheJunction: "ความเร็วเกินกำหนดในทางเเยก",
    open: "เปิด",
    orderCode: "รหัสการสั่งซื้อ",
    ok: "ตกลง",
    operation: "Operation",
    ownerView: "เจ้าของพาหนะเห็นข้อมูล",
    operator: "ผู้ประกอบการ",
    online: "ออนไลน์",
    offline: "ออฟไลน์",
    vehicleOffline: "พาหนะสถานะออฟไลน์",
    okay: "ตกลง",
    over_hour: "พาหนะวิ่งเกิน(ชั่วโมง)",
    ordinal_numbers: "ครั้งที่",
    opened: "รับเรื่อง",
    onSite: "เเพลนท์ออนไซต์",
  },

  p: {
    pullvehiclesink: "ดึงรถจม",
    pleaseSelectPicture: "กรุณาเลือกรูปภาพ",
    position: "ตำแหน่ง",
    preventRePinLocationConfirm:
      "ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?",
    preventReDrawLocationConfirm:
      "ตำแหน่งสถานที่จะหายไปเมื่อกดปักหมุดใหม่ ต้องการยืนยัน?",
    parking: "จอดพาหนะ",
    policy: "นโยบาย",
    plantArrivingTime: "เวลาเข้าแพลนต์",
    plantCode: "รหัสแพลนต์",
    productType: "ประเภทสินค้า",
    phoneNumber: "เบอร์โทร 1",
    plateNo: "ทะเบียน",
    pickup: "จุดรับ",
    pickupData: "วันนัด",
    passenger: "จำนวนผู้โดยสาร",
    pickUpTime: "เวลานัด",
    pickUpLocation: "สถานที่รับ",
    pickUpDetail: "รายละเอียดจุดรับ",
    pickUpDateTime: "วัน/เวลานัด",
    previous: "ย้อนกลับ",
    pinDown: "ปักหมุด",
    partiallyDamagedCard: "บัตรเสียหายบางส่วน",
    pto: "PTO",
    personal: "บุคคล",
    partner: "ผู้เช่าพาหนะ",
    parkingLot: "ลานจอดพาหนะ",
    polyGonRadius: "รัศมี",
    pdf: "PDF",
    photo: "รูป",
    privilegegroup: "กลุ่มสิทธิ์",
    purchaseChannel: "ช่องทางการซื้อ",
    pending: "รอดำเนินการ",
    PONumber: "หมายเลข P/O",
    pdfFilesUpTo5MBInSize: "ไฟล์ PDF ขนาดไม่เกิน 5MB",
    po: "ใบ PO",
    pleaseSelect: "กรุณาเลือก",
    pictureUpdate: "อัพเดทรูปภาพ",
    plant: "เเพลนท์",
    picture: "รูปภาพ",
    password: "รหัสผ่าน",
    personalID: "หมายเลขบัตรประชาชน",
    plantFactory: "Plant/Factory",
    period: "ระยะเวลา",
    periodOfInsuranceYear: "ระยะเวลาประกันภัยพาหนะ (ปี)",
    province: "จังหวัด",
    part_type: "อะไหล่พาหนะ",
    ParkingReport: "รายงานจุดจอดพาหนะ",
    preview: "แสดงตัวอย่าง",
    partnerCar: "ผู้เช่าพาหนะ",
    publicKey: "รหัสบริษัท",
    partType: "ชนิดอะไหล่พาหนะ",
    partgroup: "กลุ่มอะไหล่พาหนะ",
    product_type: "ชนิดของผลิตภัณฑ์",
    product_name: "ชื่อสินค้า",
    plan_start_deliver_at: "เวลาเริ่มจัดส่งตามแผน",
    personalInformation: "ข้อมูลส่วนบุคคล",
    plateNoOfUninstall: "ทะเบียนรถที่แจ้งถอด",
    plateNoOfInstall: "ทะเบียนรถที่ติดตั้ง",
    personalInformation: "ข้อมูลส่วนบุคคล",
    partSetting: "ตั้งค่าอะไหล่",
    processing: "กำลังประมวลผล",
    preparePoint: "จุดเตรียมความพร้อม",
    password_require: "กรุณากรอกรหัสผ่าน",
    confirm_password_require: "กรุณากรอกยืนยันรหัสผ่าน",
    please_less_than_6: "กรุณากรอกอย่างน้อย 6 ตัวอักษรขึ้นไป",
    passwords_must_match: "รหัสผ่านไม่ตรงกัน",
    password_new: "รหัสผ่านใหม่",
    phone: "เบอร์โทรศัพท์",
    phone_require: "กรุณากรอกเบอร์โทรศัพท์",
    phone_invalid: "เบอร์โทรศัพท์ที่กรอกไม่ถูกต้อง",
    position_require: "กรุณากรอกตำแหน่ง",
    price: "ราคา",
    plateNoExist: "ทะเบียนนี้ซ้ำกับบริษัท",
  },

  q: {
    quantity_liter: "ปริมาณ (ลิตร)",
    quantity: "ปริมาณ",
    quotation: "เสนอราคา",
  },

  r: {
    rainingTime: "ช่วงฝนตก",
    replay: "เส้นทางเดินทางย้อนหลัง",
    replayDetail: "รายละเอียดเส้นทางเดินทางย้อนหลัง",
    received: "Received",
    reportSummarizingTheNumberOfOffSiteParking:
      "รายงานสรุป จำนวนครั้งการจอดพาหนะนอกพื้นที่",
    reportCardSwipeBehaviorBeforeDrivingDaily:
      "รายงาน พฤติกรรมการรูดบัตรก่อนขับขี่ รายวัน",
    reportType: "ประเภทรายงาน",
    reActivate: "เปิดใช้งานอีกครั้ง",
    reset: "รีเซ็ต",
    reject: "ปฏิเสธ",
    routeRadius: "รัศมีของเส้นทาง",
    remainingRequest: "คำขอคงค้าง",
    routeName: "ชื่อเส้นทาง",
    restArea: "จุดพักพาหนะ",
    radiuns: "รัศมี",
    radiunsM: "รัศมี (ม.)",
    require: "โปรดระบุ",
    remark: "ข้อคิดเห็น",
    rpm: "RPM",
    running: "วิ่ง",
    remark1: "หมายเหตุการจอง 1",
    remark2: "หมายเหตุการจอง 2",
    reminderData: "วันแจ้งเตือน",
    reminderTime: "เวลาแจ้งเตือน",
    remarkBooking: "หมายเหตุการจอง",
    requestList: "รายการคำขอ",
    report: "รายงาน",
    reportAlarmFrequencyClassifiedByDailyDriver:
      "รายงานความถี่ alarm แยกตามพนักงานขับขี่ รายวัน",
    route: "เส้นทางการวิ่ง",
    rfid: "RFID",
    refuelAt: "เติมเชื้อเพลิงเมื่อ",
    refuel: "เติมเชื้อเพลิง",
    reFuelTitle: "เชื้อเพลิง",
    RefuelReport: "รายงานการเติมเชื้อเพลิง",
    RiskbehaviourReport: "รายงานพฤติกรรมการขับขี่ไม่ปลอดภัย",
    risk: "เสี่ยง",
    routeRadiusM: "รัศมีของเส้นทาง (ม.)",
    round: "รอบ",
    renewalDate: "วันต่ออายุ",
    remainDay: "คงเหลือ (วัน)",
    remindDate: "วันแจ้งเตือน",
    RepairDevice: "เเจ้งย้าย",
    receiveDate: "วันที่ได้รับเเจ้ง",
    resendEmail: "ส่งอีเมลอีกครั้ง",
    report_daily: "รายวัน",
    report_weekly: "รายสัปดาห์",
    report_monthly: "รายเดือน",
    report_yearly: "รายปี",
    report_name: "ชื่อรายงาน",
    routemaster: "แผนการจัดส่ง",
    route_master_name: "ชื่อแผนการจัดส่ง",
    rateConfirmationPeriod: "ระยะเวลาในการยืนยันงาน",
    ratePunctuality: "ความตรงต่อเวลา",
    ratePersonality_driver: "บุคลิกภาพพนักงานขับรถ",
    rateGoodservice: "ความเต็มใจในการบริการ",
    rateServiceQuality: "คะแนนคุณภาพการบริการทั้งหมด",
    rateServiceQualityRecent: "คะแนนคุณภาพการบริการล่าสุด",
    row_number: "ลำดับ",
  },
  s: {
    searchDashCameras: "พิมพ์ค้นหาจากเลขทะเบียนรถ ...",
    speedKmPerHr: "ความเร็ว (กม./ชม.)",
    safety: "ความปลอดภัย",
    subType: "ประเภทย่อย",
    setting: "ตั้งค่า",
    shipTo: "ส่งไปยัง",
    siteCode: "รหัสไซต์",
    saveNext: "บันทึกเเละถัดไป",
    submit: "บันทึก",
    send: "ส่ง",
    save: "บันทึก",
    search: "ค้นหา",
    searchPlants: "ค้นหา",
    surplus: "เหลือ",
    selected: "เลือก",
    status: "สถานะ",
    secondaryPhoneNumber: "เบอร์โทร 2",
    selectedVehicleAlready: "พาหนะที่เลือกไว้",
    southeastAsia: "เอเชียตะวันออกเฉียงใต้",
    showTraffic: "แสดงการจราจร",
    searchTextFromPlateNo: "ค้นหาจากเลขทะเบียน ...",
    speedRange: "ช่วงความเร็ว (กม./ชม.)",
    speedRangeNoKm: "ช่วงความเร็ว",
    slidingCard: "รูดบัตร",
    slideWrongCardType: "รูดบัตรผิดประเภท",
    speed: "ความเร็ว",
    support: "สนับสนุน",
    searchText: "ค้นหาคำ",
    someparts: "บางส่วน..",
    speedLimited: "ความเร็วเกินกำหนด",
    startDate: "วันเริ่มต้น",
    sentBy: "ส่งโดย",
    sender: "ผู้ส่ง",
    sendAsAGroupOfVehicles: "ส่งเเบบกลุ่มพาหนะ",
    sendSelectedVehicles: "ส่งเเบบเลือกพาหนะ",
    sentSuccessfully: "ส่งเรียบร้อย",
    sendFailure: "ส่งผิดพลาด",
    selectPlateNo: "เลือกทะเบียน",
    selectAssignTo: "เลือกผู้รับมอบหมาย",
    selectCriticalityLevel: "เลือกระดับความรุนเเรง",
    selectStatus: "เลือกสถานะ",
    selectVehicleGroup: "เลือกกลุ่มพาหนะ",
    selectNotificationChannel: "เลือกช่องทางเเจ้งเตือน",
    smsNotifyTo: "ส่ง sms แจ้งเตือนไปที่หมายเลข",
    startLocationName: "ชื่อต้นทาง",
    startDestinationCode: "รหัสต้นทาง",
    startDestinationName: "ชื่อต้นทาง",
    setTheRoute: "กำหนดเส้นทาง",
    startLocationCode: "รหัสต้นทาง",
    specialPrivilege: "สิทธิพิเศษ",
    selectOne: "เลือก 1 รายการ",
    state: "สถานะภาพ",
    shopee: "ช้อปปี้",
    salesChannels: "ช่องทางการขาย",
    selectFile: "เลือกไฟล์",
    simNumber: "SIM Number",
    startTrip: "จุดเริ่มต้นทริป",
    sos: "sos",
    satellite: "ดาวเทียม",
    SCCOCarComparisonReport: "รายงาน เปรียบเทียบพาหนะของ SCCO",
    shareTo: "พาหนะที่แชร์",
    shareFrom: "พาหนะที่ถูกแชร์",
    sharedAt: "แชร์เมื่อ",
    speedOverLimit: "ความเร็วเกินกำหนด",
    speedOverLimitByVehicleGroup: "ความเร็วเกินกำหนดตามกลุ่มพาหนะ",
    speedOverLimitByVehicleType: "ความเร็วเกินกำหนดตามประเภทพาหนะ",
    speedOverLimitByDriver: "ความเร็วเกินกำหนดตามพนักงานขับขี่",
    stopWithEngineOn: "จอดไม่ดับเครื่อง",
    stopWithEngineOff: "จอดดับเครื่อง",
    setup: "ติดตั้ง",
    smallTruck: "รถบรรทุกขนาดเล็ก",
    shaft: "จำนวนเพลาล้อ",
    statusVehicleVideo: "สถานะ",
    status_moving: "พาหนะวิ่ง",
    status_parked: "จอดพาหนะ",
    status_engineoff: "ดับเครื่อง",
    status_vehicle: "สถานะพาหนะ",
    select_event: "เลือกเหตุการณ์",
    SpeedoverlimitsummaryReport: "รายงานสรุปความเร็วเกินกำหนด",
    SummaryKPIReport: "สรุปรายงาน KPI",
    SummaryKPIByDriverReport: "รายงาน KPI แยกตามพนักงานขับขี่",
    startDeliveryAt: "วัน/เวลาเข้าถึงหน้างาน",
    startRefuel: "เติมเชื้อเพลิงเมื่อ",
    start_daily_time: "เวลาเริ่มในแต่ละวัน",
    second_dest: "จุดสิ้นสุด",
    ship_to: "รหัสไซต์งาน",
    scan_date_at: "วันที่อัพโหลด",
    shareLocation: "แชร์สถานที่",
    startAt: "เวลาเริ่ม",
    send_email: " ส่งอีเมล์",
    Servicerepairdevice: "แจ้งซ่อม/ถอดอุปกรณ์",
    submit_name: "ชื่อผู้ยื่นเรื่อง",
    submit_telno: "เบอร์ติดต่อผู้ยื่นเรื่อง",
    start_deliver_at: "เวลาเริ่มทริป",
    seenAll: "เห็นข้อมูลทั้งหมด",
    salesMan: "พนักงานขาย",
    scheduled: "นัดช่าง",
    servicemovedevice: "ใบเเจ้งย้าย",
    submitName: "ชื่อผู้ยื่นเรื่อง",
    submitTelno: "เบอร์ติดต่อผู้ยื่นเรื่อง",
    serviceinstalldevice: "ใบแจ้งติดตั้ง",
    startValidDate: "วันที่เริ่มใช้พาหนะ",
    startAndEndValidDateDesc: "ช่วงวันที่อนุญาตให้ระบบนำข้อมูลพาหนะไปใช้งาน",
    sizeAndWeight: "ขนาดเเละน้ำหนัก",
    selectDate: "เลือกวันที่",
    selectTime: "เลือกเวลา",
    summaryInfo: "สรุปข้อมูล",
    status_download_file: "สถานะการดาวน์โหลดไฟล์",
    show: "เเสดง",
    SummaryiconsiamReport: "รายงานสรุปรอบการให้บริการเรือ / รถ แบบเฉลี่ยรายวัน",
    showAll: "เเสดงทั้งหมด",
    schedulereport: "แจ้งเตือนรายงาน",
    schedule_type: "ชนิดการแจ้งเตือน",
    setting_filter: " ตั้งค่ารายงาน",
    send_data_before_delivery: "ส่งข้อมูลก่อนหน้าวันส่ง",
    success: "สำเร็จ",
    searchDirections: "ค้นหาเส้นทาง",
    signup: "ลงทะเบียนข้อมูล",
    signup_button: "สมัคร",
    send_verify_code: "เราได้ส่งรหัสยืนยันไปที่โทรศัพท์",
    send_verify_email: "กรุณายืนยันตัวตนทางอีเมล",
    send_verify_email_error:
      "ระบบไม่สามารถส่งอีเมลยืนยันไปที่อีเมลได้ กรุณาลองใหม่อีกครั้ง",
    send_verify_code_error: "ยืนยันไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
    send_email_forgotpassword:
      "ระบบส่งอีเมลยืนยันสำหรับตั้งค่ารหัสผ่านใหม่ไปที่อีเมลของท่านเรียบร้อยแล้ว",
    send_email_forgotpassword_error:
      "ระบบไม่สามารถส่งอีเมลยืนยันสำหรับการตั้งค่ารหัสผ่านใหม่ไปที่อีเมลได้ กรุณาลองใหม่อีกครั้ง",
    select_user_type: "สมัครใช้งาน",
    select_file: "เลือกไฟล์",
    success_save: "สำเร็จ",
    satisfactionSurvey: "ประเมินความพึงพอใจ",
    signature: "ลายเซ็น",
    star: "ดาว",
  },

  t: {
    titleAccount: "บัญชีผู้ใช้",
    tokenExpired: "Token หมดอายุ",
    ticket: "ตั๋ว",
    title: "คำนำหน้า",
    today: "วันนี้",
    thisMonth: "เดือนนี้",
    thisYear: "ปีนี้",
    taxiMeter: "Taxi Meter",
    temperature: "อุณหภูมิ",
    temperature1: "อุณหภูมิ 1",
    temperature2: "อุณหภูมิ 2",
    TheUsualRoute: "เส้นทางปกติ",
    time: "เวลา",
    toggleHeatMap: "Toggle Heat Map",
    trackingNo: "หมายเลขการติดตาม",
    type: "ประเภท",
    total: "ทั้งหมด",
    totalEmergencyCase: "กรณีฉุกเฉินทั้งหมด",
    typeLocation: "ชนิดของสถานที่",
    theRoute: "เส้นทาง",
    toSiteDistance: "ระยะทางขาไป",
    timeThatCanBeUsed: "ระยะเวลาที่สามารถใช้งานระบบ",
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      "รายงาน alert 5 อันดับแรกของแต่ละประเภท แบ่งตามช่วงเวลา รายเดือน",
    theDriverIsIicenseReportHasExpired: "รายงาน การแจ้งเตือนใบขับขี่หมดอายุ",
    theDayThatTheCarPulled: "วันที่ดึงพาหนะจม",
    trip: "ทริป",
    tripStatus: "สถานะทริป",
    topCustomer: "Top Customer",
    topEarning: "Top Earning",
    tool: "เครื่องมือ",
    tools: "เครื่องมือ",
    tracking: "ติดตามยานพาหนะ",
    traininglist: "รายการฝึกอบรม",
    trainingDate: "วันที่อบรม",
    trainer: "ผู้ฝึกอบรม",
    tagID: "รหัสเเท็ก",
    truckCategory: "ประเภทรถบรรทุก",
    truckType: "ชนิดรถบรรทุก",
    tire: "จำนวนยาง",
    truckDriverMatching: "จับคู่พนักงานขับขี่",
    TruckusageReport: "รายงานการใช้งานพาหนะ",
    totalPrice_baht: "ราคารวม (บาท)",
    totalPrice: "ราคารวม",
    TemperatureReport: "รายงานอุณหภูมิ",
    time_15: "15 นาที",
    time_30: "30 นาที",
    time_60: "1 ชั่วโมง",
    time_180: "3 ชั่วโมง",
    time_300: "5 ชั่วโมง",
    time_420: "7 ชั่วโมง",
    time_720: "12 ชั่วโมง",
    time_1440: "24 ชั่วโมง",
    TrackingdataReport: "รายงานรายละเอียดพิกัดพาหนะ",
    type_report: "ประเภทรายงาน",
    type_trip: "รายทริป",
    type_person: "รายบุคคล",
    type_duration: "ตามช่วงเวลา",
    TriptemperatureReport: "รายงานสรุปอุณหภูมิแยกตามทริป",
    TripsumbyvehReport: "รายงานสรุปการวิ่งแยกตามพาหนะ",
    TruckengineonReport: "รายงานพาหนะติดเครื่อง",
    TruckengineoffReport: "รายงานพาหนะไม่ติดเครื่อง",
    TrucknotuseReport: "รายงานพาหนะไม่ได้วิ่งงาน",
    tripCode: "รหัสทริป",
    TripsummaryReport: "รายงานสรุปการวิ่งแยกตามทริป",
    TripsumbylocationReport: "รายงานสรุปจำนวนงานตามสถานที่",
    trip_report_menu: "ทริป",
    typeMaintenance: "ประเ��ท",
    technician: "ช่าง",
    TruckcurrentlocationReport: "รายงานที่อยู่ปัจจุบันของพาหนะ",
    time_period: "ช่วงระยะเวลา",
    type_problem: "อาการของปัญหา",
    trip_date: "ช่วงเวลาเริ่ม-สิ้นสุดทริป",
    trip_duration: "ช่วงเวลาเริ่ม-สิ้นสุดทริป",
    taxNo: "Tax No.",
    timeZone: "Time Zone",
    telnoAE: "เบอร์โทรศัพท์ AE",
    TripbyengineoffonReport:
      "รายงาน การเดินทางแยกประเภทตามการเปิด-ปิดเครื่องยนต์",
    totalDistance: "ระยะทางรวม (กิโลเมตร)",
    totalDuration: "ระยะเวลารวม (วัน:ชั่วโมง:นาที)",
    to_email: "ส่งถึง",
    to: " ถึง ",
    time_entry: "เวลาเข้า",
    time_out: "เวลาออก",
    time_use_in_location: "เวลาที่อยู่ในสถานี",
    time_travel: "เวลาเดินทาง",
    thisWorkSheetisAlreadyhaveRating: "แผนงานนี้ได้รับการให้คะแนนเรียบร้อยแล้ว",
  },

  u: {
    userClose: "ผู้ใช้งานปิดเคส",
    updatedAt: "อัพเดทเมื่อเวลา",
    userProfile: "ข้อมูลผู้ใช้",
    userGroupPremission: "กลุ่มผู้ใช้งาน",
    unknown: "ไม่ระบุ",
    userName: "ชื่อผู้ใช้งาน",
    updatePictures: "อัพเดทรูปภาพ",
    updateLocation: "อัพเดทตำแหน่ง",
    unassign: "Unassign",
    updatedBy: "เเก้ไขข้อมูลโดย",
    user: "ผู้ใช้งาน",
    userGroup: "กลุ่มผู้ใช้งาน",
    unMatch: "ยกเลิกการจับคู่",
    upload: "อัพโหลด",
    userExpire: "ระยะเวลาสิ้นสุดการใช้งาน",
    unitPrice: "หน่วยละ (บาท)",
    use_date_time: "วันเวลาที่ใช้",
    use_account_name: "ผู้ใช้งาน",
    uninstall: "ถอด",
    unBroken: "ไม่เสีย",
    unit: "หน่วย",
    update_success: "แก้ไขข้อมูลสำเร็จ",
    update_fail: "แก้ไขข้อมูลไม่สำเร็จ",
  },

  v: {
    vnCode: "รหัสพาหนะ",
    version: "เวอร์ชั่น",
    vehicleAndDriver: "พาหนะและพนักงานขับขี่",
    video: "MDVR Live Stream (วีดีโอเรียลไทม์จาก MDVR)",
    vehicleModel: "รุ่นพาหนะ",
    vehicleColor: "สีพาหนะ",
    vehicleType: "ประเภทพาหนะ",
    vehicle: "พาหนะ",
    vehicleID: "รหัสพาหนะ",
    vehicleStatus: "สถานะพาหนะ",
    view: "ดู",
    vehicleDetail: "ข้อมูลรายละเอียดพาหนะ",
    vehicleGroupName: "ชื่อกลุ่มพาหนะ",
    vehicleGroup: "กลุ่มพาหนะ",
    vehicleSharing: "แชร์พาหนะ",
    validDate: "Valid Date",
    vehicleRegisterType: "Vehicle Register Type",
    VelocitysummaryReport: "รายงานสรุปความเร็ว",
    vehicleCode: "รหัสพาหนะ",
    VehicleperformanceconcludeReport: "รายงานสรุปประสิทธิภาพพาหนะรายคัน",
    VehicleinareaReport: "รายงานพาหนะเข้าพื้นที่",
    video_type: "ประเภทวีดีโอ",
    vehicle_type: "ประเภทพาหนะ",
    voluntary: "ประกันภัยรถยนต์ภาคสมัครใจ",
    VehicleinformationdltReport: "รายงานข้อมูลการเดินรถ DLT",
    vendor: "Vendor",
    verifyYourEmailAddress: "ยืนยันที่อยู่อีเมลของคุณ",
    videoDownload: "ดาวน์โหลดวีดีโอ",
    vehicleAndDriver: "พาหนะและพนักงานขับขี่",
    verify_signup: "ยืนยันตัวตน",
    verify_code_require: "กรุณากรอกรหัสยืนยันตัวตน",
    verify_code_digit: "รหัส 6 หลัก",
    verify: "ตรวจสอบ",
    verify_yes: "ผ่านการตรวจสอบแล้ว",
    verify_no: "ยังไม่ผ่านการตรวจสอบ",
    vehicle_verify: "ตรวจสอบพาหนะ",
    verify_status: "สถานะการตรวจสอบ",
    validate_upload_file:
      "คุณสามารถอัพโหลดได้แค่ไฟล์นามสกุล JPG/PNG/PDF เท่านั้น",
    validate_file_size: "ขนาดไฟล์ต้องไม่เกิน 5MB",
    validate_id_card: "กรอกบัตรประชาชนไม่ถูกต้อง",
    validate_id_card_dup: "บัตรประชาชนมีอยู่ในระบบแล้ว",
    validate_id_card_length: "บัตรประชาชนต้องมี 13 หลัก",
    validate_code_dup: "รหัสพนักงานมีอยู่ในระบบแล้ว",
    validate_phone_dup: "เบอร์โทรศัพท์นี้มีอยู่ในระบบแล้ว",
    verify_code:
      "ระบุรหัส OTP ที่ได้รับทาง SMS เพื่อเข้าสู่ระบบ(รหัส OTP ของคุณมีอายุการใช้งาน 2 นาที)",
    validate_phone: "เบอร์โทรศัพท์ต้องมี 10 หลัก",
    validate_number: "เบอร์โทรศัพท์ต้องเป็นตัวเลขเท่านั้น",
    validate_required_data: "ข้อมูลไม่ครบถ้วน กรุณากรอก",
  },

  w: {
    whenEventOccurred: "ลักษณะการแจ้งเตือน",
    weekly: "รายสัปดาห์",
    week: "สัปดาห์",
    warrantyEnd: "สิ้นสุดการรับประกัน",
    workOverTime: "ปฏิบัติงานเกินช่วงเวลา",
    waitingConfirm: "รอยืนยัน",
    wigth: "กว้าง",
    wheel: "จำนวนวงล้อ",
    waitingForJob: "รองาน",
    waitAssessment: "รอประเมิน",
    whichMayHaveToContactTheAppointmentLocationAgain:
      "โดยอาจจะมีการติดต่อสถานที่นัดอีกครั้งได้",
    weWillContactYouAtTheTelephoneNumberProvided:
      "เราจะติดต่อคุณตามหมายเลขโทรศัพท์ที่แจ้งไว้",
    waitForEmailChecking: "รอการบืน Email",
    w_sunday: "อาทิตย์",
    w_monday: "จันทร์",
    w_tuesday: "อังคาร",
    w_wednesday: "พุธ",
    w_thursday: "พฤหัสบดี",
    w_friday: "ศุกร์",
    w_saturday: "เสาร์",
    workSheetIdNotExist: "ไม่พบแผนงานนี้",
  },

  x: {},

  y: {
    youCanOonlyUploadPdfFile: "คุณสามารถอัปโหลดไฟล์ PDF ได้เท่านั้น",
    yearly: "รายปี",
    year: "ปี",
    youCanOnlyUploadJPGPNGPDFFile: "คุณสามารถอัปโหลดไฟล์ JPG/PNG/PDF เท่านั้น!",
    youHaveNoRightToEvaluateThisWorksheet: "คุณไม่มีสิทธิ์ในการประเมินแผนงานนี้",
  },

  z: {
    zoomIn: "ขยายเข้า",
    zoomOut: "ขยายออก",
    zone: "พื้นที่",
    zoneName: "ชื่อพื้นที่",
    zoomInForMoreInfo: "ซูมเข้าเพื่อใช้งานฟีเจอร์",
    zoneNameIsRequired: "กรุณากรอกชื่อพื้นที่",
  },

  variable: {
    on: "เปิด",
    off: "ปิด",
    speed_limit: "ความเร็ว",
    speed_limit_area: "ความเร็วในพื้นที่",
    no_entry: "เข้าพื้นที่",
    no_exit: "ออกพื้นที่",
    stop_with_engine_off: "จอดรถดับเครื่อง",
    timeline_speed_over: "ความเร็วเกินกำหนด",
    timeline_vehicle_stop_with_engine_on: "จอดรถไม่ดับเครื่อง",
    timeline_aggressive_acceleration: "ออกตัวกระชาก",
    timeline_suddenly_break: "เบรคกะทันหัน",
    event: "เหตุการณ์",
    parking: "จอดพาหนะ",
    driving: "ขับพาหนะ",
    insurance: "ประกันภัย",
    maintenance_vehicle: "พาหนะ",
    safety: "ความปลอดภัย",
    sensor: "เซ็นเซอร์",
    sos: "SOS",
    pto_door: "เปิด PTO/ประตู",
    boom: "BOOM",
    temperature: "อุณภูมิ",
    fuel: "เชื้อเพลิง",
    taxi: "Taxi",
    prohibited_area: "พื้นที่",
    parking_area: "จุดจอด",
    abnormal_behavior: "พฤติกรรมการขับขี่ไม่ปลอดภัย",
    driving_hour: "ชั่วโมงการขับขี่",
    speed: "ความเร็ว",
    high: "สูง",
    medium: "กลาง",
    low: "ต่ำ",
    tire: "ยาง",
    engine_oil: "น้ำมันเครื่อง",
    suspension: "ช่วงล่าง",
    voluntary_motor_insurance: "ประกันภัยรถยนต์ภาคสมัครใจ",
    compulsory_motor_insurance: "ประกันภัยรถยนต์ภาคบังคับ",
  },

  worksheet: {
    worksheet: "สร้างใบงาน",
    importworksheet: "นำเข้าใบงาน",
    downloadworksheetonetime: "ดาวน์โหลดใบงานเที่ยวเดียว",
    downloadworksheetroutine: "ดาวน์โหลดใบงานประจำ",
    importworksheetonetime: "นำเข้าใบงานเที่ยวเดียว",
    importworksheetroutine: "นำเข้าใบงานประจำ",
    origin_receive_date: "วันที่รับสินค้า",
    destination_receive_date: "วันที่รับสินค้า",
    worksheetonetime: "งานเที่ยวเดียว",
    worksheetroutine: "ใบงานประจำ",
    company_name: "ชื่อบริษัท",
    product_detail: "รายละเอียดสินค้า",
    quantity: "จำนวนสินค้า",
    unit: "หน่วย",
    service_payment_type: "การชำระค่าบริการ",
    credit_term: "เงื่อนไขการชำระเงิน",
    staff_lift: "มีพนักงานยกของหรือไม่",
    have: "มี",
    nothave: "ไม่มี",
    frequency_routine: "ความถึ่",
    contract_term: "ระยะเวลาสัญญา",
    contact_origin_info: "รายละเอียดสำหรับโหลดสินค้าต้นทาง",
    contact_destination_info: "รายละเอียดสำหรับส่งสินค้าปลายทาง",
    company_name_require: "กรุณากรอกชื่อบริษัท",
    origin_receive_date_require: "กรุณาเลือกวันที่รับสินค้า",
    destination_receive_date_require: "กรุณาเลือกวันที่รับสินค้า",
    product_detail_require: "กรุณาเลือกรายละเอียดสินค้า",
    quantity_require: "กรุณากรอกจำนวนสินค้า",
    service_payment_type_require: "กรุณาเลือกการชำระค่าบริการ",
    credit_term_id_require: "กรุณาเลือกเงื่อนไขการชำระเงิน",
    frequency_routine_id_require: "กรุณาเลือกความถี่",
    contract_term_id_require: "กรุณาเลือกระยะเวลาสัญญา",
    firstname_require: "กรุณากรอกชื่อ",
    lastname_require: "กรุณากรอกนามสกุล",
    phone_require: "กรุณากรอกเบอร์โทรศัพท์",
    unit_require: "กรุณากรอกหน่วย",
    logistic_require: "กรุณาเลือกกลุ่มขนส่ง",
    transporter_id_require: "กรุณาเลือกบริษัทขนส่ง",
    cash: "เงินสด",
    credit: "เครดิต",
    expense: "เส้นทาง-ราคาขนส่ง",
    direct_type: "ประเภทการโพสงาน",
    transporterType: "บริษัทขนส่ง",
    logisticGroupType: "กลุ่มขนส่ง",
    document_number: "หมายเลขใบสั่งงาน",
    status_success_continue:
      "สร้างใบงานสำเร็จ คุณต้องการสร้างใบงานต่อ จากข้อมูลเดิมใช่หรือไม่",
    contact_destination_info_require:
      "กรุณาเพิ่มข้อมูลการติดต่อรับสินค้าปลายทาง",
    worksheet_file_require: "กรุณาเลือกไฟล์ที่ต้องการนำเข้าข้อมูล",
    upload_file_support: "รองรับไฟล์ JPG/PNG/PDF เท่านั้น",
    upload_image_support: "รองรับไฟล์ JPG/PNG เท่านั้น",
    upload_xlsx_support: "รองรับไฟล์ XLSX เท่านั้น",
    upload_size_support: "ไฟล์ต้องมีขนาดไม่เกิน 5 MB",
    upload_amount1_support: "อัพได้เพียงไฟล์เดียวเท่านั้น",
    upload_amount5_support: "อัพโหลดได้สูงสุด 5 รูป",
    m_status_1: "เข้ารับสินค้า",
    m_status_2: "อยู่ระหว่างการจัดส่ง",
    m_status_3: "ส่งสินค้าแล้ว",
    m_status_4: "ปฏิเสธ",
    m_status_5: "ส่งสินค้าไม่ครบจำนวน",
    status_1: "อยู่ระหว่างดำเนินการเข้ารับสินค้า",
    status_2: "อยู่ระหว่างดำเนินการขนส่งสินค้า",
    status_3: "ดำเนินการขนส่งสินค้า",
    status_4: "รับบางส่วน",
    status_5: "ปฏิเสธทั้งหมด",
    market: "ตลาดกลาง",
    direct: "ส่งงานตรง",
    validate_destination: "กรุณากรอกปลายทางอย่างน้อย 1 ปลายทาง",
    import_onetime: "งานนำเข้าเที่ยวเดียว",
    import_routine: "งานนำเข้าใบงานประจำ",
    name_contact_load: "ชื่อติดต่อคนโหลดสินค้า",
    phone_contact_load: "เบอร์ติดต่อคนโหลดสินค้า",
    post_to_market:
      "คุณต้องการโพสงานนำเข้าทั้งหมดในตารางเข้าตลาดกลางใช่หรือไม่",
    cancel_to_import: "คุณต้องยกเลิกการนำเข้างานทั้งหมดในตารางใช่หรือไม่",
    limit_destination: "ปลายทางต้องไม่เกิน 6 ปลายทาง",
    destination_info: "ต้องระบุปลายทางอย่างน้อย 1 ปลายทางแต่ไม่เกิน 6 ปลายทาง",
    worksheet_title: "ใบงาน",
    preview_img: "แสดงรูปสินค้า",
    not_found_img: "ใบงานนี้ไม่มีรูปสินค้า"
  },

  expense: {
    origin_location_require: "กรุณาเลือกต้นทางที่มีอยู่ในระบบ",
    destination_location_require: "กรุณาเลือกปลายทางที่มีอยู่ในระบบ",
    price_require: "กรุณากรอกราคา",
    price_unit_require: "กรุณาเลือกหน่วย",
    price_trip_require: "กรุณากรอกราคาเที่ยวเปล่า",
    origin_location: "ต้นทาง",
    destination_location: "ปลายทาง",
    price: "ราคา",
    price_unit: "หน่วย",
    price_trip: "ราคาเที่ยวเปล่า",
    distance: "ระยะทาง(กม.)",
    expenseinfo: "เส้นทาง-ราคาขนส่ง",
    create: "สร้างเส้นทาง-ราคาขนส่ง",
    edit: "แก้ไขเส้นทาง-ราคาขนส่ง"
  },

  addr: {
    buildingName: "ชื่ออาคาร",
    roomNumber: "ห้องเลขที่",
    floor: "ชั้นที่",
    village: "หมู่บ้าน",
    houseNo: "เลขที่",
    villageNo: "หมู่ที่",
    soi: "ตรอก/ซอย",
    province: "จังหวัด",
    district: "อำเภอ/เขต",
    subdistrict: "ตำบล/แขวง",
    postalCode: "รหัสไปรษณีย์",
    firstname_require: "กรุณากรอกชื่อ",
    lastname_require: "กรุณากรอกนามสกุล",
    houseno_require: "กรุณากรอกเลขที่",
    province_require: "กรุณาเลือกจังหวัด",
    district_require: "กรุณาเลือกอำเภอ/เขต",
    subdistrict_require: "กรุณาเลือกตำบล/แขวง",
    postalcode_require: "กรุณากรอกรหัสไปรษณีย์",
    fileIdCard_require: "กรุณาเลือกไฟล์สำเนาบัตรประชาชน",
    fileDrivingCard_require:
      "กรุณาเลือกไฟล์สำเนาอนุญาตขับรถสาธารณะหรือส่วนบุคคลตั้งแต่ประเภท 2 ขึ้นไป",
    firstname_invalid: "กรุณากรอกชื่อที่ถูกต้อง",
    lastname_invalid: "กรุณากรอกนามสกุลที่ถูกต้อง",
  },

  veh: {
    vehicle_type: "ประเภทพาหนะ",
    plate_no: "ทะเบียน",
    plate_no_province: "ทะเบียนจังหวัด",
    brand: "ยี่ห้อ",
    model: "รุ่น",
    year: "ปี(พ.ศ.)",
    color: "สี",
    expire_date: "วันที่ประกันหมดอายุ",
    expire_act_date: "วันที่ พ.ร.บ. หมดอายุ",
    filefrontvehicle: "สำเนาหน้าเล่มทะเบียนพาหนะ",
    image_vehicle: "ภาพถ่ายพาหนะ",
    file_copy_driver_license: "สำเนาใบขับขี่",
    start_province: "ต้นทาง",
    end_province: "ปลายทาง",
    start_location: "ต้นทาง",
    end_location: "ปลายทาง",
    contact_firstname: "ชื่อ",
    lastname: "นามสกุล",
    driver_code: "รหัสพนักงาน",
    id_card: "เลขบัตรประชาชน",
    id_card_expire_date: "วันหมดอายุ",
    id_driver_license: "เลขใบขับขี่",
    driver_license_expire_date: "วันหมดอายุ",
    vehicleinfo: "ข้อมูลพาหนะ",
    service_area: "พื้นที่ให้บริการ",
    driver_info: "ข้อมูลคนขับ",
    vehicle_type_require: "กรุณาเลือกประเภทพาหนะ",
    plate_no_require: "กรุณากรอกทะเบียนพาหนะ",
    plate_no_province_require: "กรุณาเลือกทะเบียนจังหวัด",
    brand_require: "กรุณากรอกยี่ห้อ",
    model_require: "กรุณากรอกรุ่น",
    year_require: "กรุณากรอกปี",
    color_require: "กรุณากรอกสี",
    expire_date_require: "กรุณาเลือกวันที่ประกันหมดอายุ",
    expire_act_date_require: "กรุณาเลือกวันที่ พรบ. หมดอายุ",
    start_province_require: "กรุณาเลือกต้นทาง",
    end_province_require: "กรุณาเลือกปลายทาง",
    contact_firstname_require: "กรุณากรอกชื่อ",
    contact_lastname_require: "กรุณากรอกนามสกุล",
    driver_code_require: "กรุณากรอกรหัสพนักงาน",
    id_card_require: "กรุณากรอกเลขบัตรประชาชน",
    id_card_expire_date_require: "กรุณาเลือกวันหมดอายุ",
    id_driver_license_require: "กรุณากรอกเลขใบขับขี่",
    driver_license_expire_date_require: "กรุณาเลือกวันหมดอายุ",
    filefrontvehicle_require: "กรุณาเลือกไฟล์สำเนาหน้าเล่มทะเบียนพาหนะ",
    image_vehicle_require: "กรุณาเลือกไฟล์ภาพถ่ายพาหนะ",
    file_copy_driver_license_require: "กรุณาเลือกไฟล์สำเนาใบขับขี่",
    id_card_invalid: "กรุณากรอกเลขบัตรประชาชนที่ถูกต้อง",
    year_invalid: "กรุณากรอกปีที่ถูกต้อง",
    firstname_driver_require: "กรุณากรอกชื่อพนักงานขับขี่",
    lastname_driver_require: "กรุณากรอกนามสกุลพนักงานขับขี่",
    phone_require: "กรุณากรอกเบอร์โทรศัพท์",
    create_veh: "สร้างพาหนะ",
    edit_veh: "แก้ไขพาหนะ",
    create_driver: "สร้างข้อมูลคนขับ",
    edit_driver: "แก้ไขข้อมูลคนขับ"
  },

  business: {
    business_information: "ข้อมูลธุรกิจ",
    company_name: "ชื่อบริษัท",
    company_type: "ประเภทบริษัท",
    juristic_id: "เลขทะเบียนนิติบุคคล",
    companyDetails: "รายละเอียดบริษัท",
    province_head_offfice: "ที่ตั้งสำนักงานใหญ่",
    founded_year: "ปีที่ก่อตั้ง(พ.ศ.)",
    number_employee: "จำนวนพนักงาน",
    certificate_file: "หนังสือรับรองบริษัท",
    license_transport_file: "ขส.บ.11/ขส.บ.12",
    logo_file: "โลโก้บริษัท",
    logistic_logo_file: "โลโก้",
    product_file: "รูปสินค้า",
    product_file_limit: "รูปสินค้า(จำกัดสูงสุด 5 รูป)",
    vat_20_file: "ภพ.20",
    addresss_business: "ที่อยู่ธุรกิจ",
    contact_business: "ข้อมูลติดต่อบริษัท",
    available_vehicle_type: "ประเภทรถที่มี",
    company_name_require: "กรุณากรอกชื่อบริษัท",
    company_type_require: "กรุณาเลือกประเภทบริษัท",
    juristic_id_require: "กรุณากรอกเลขทะเบียนนิติบุคคล",
    province_head_offfice_require: "กรุณาเลือกที่ตั้งสำนักงานใหญ่",
    founded_year_require: "กรุณากรอกปีที่ก่อตั้ง(พ.ศ.)",
    number_employee_require: "กรุณากรอกจำนวนพนักงาน",
    certificate_file_require: "กรุณาเลือกไฟล์หนังสือรับรองบริษัท",
    license_transport_file_require: "กรุณาเลือกไฟล์ขส.บ.11/ขส.บ.12",
    logo_file_require: "กรุณาเลือกไฟล์โลโก้บริษัท",
    logistic_logo_file_require: "กรุณาเลือกไฟล์โลโก้",
    product_file_require: "กรุณาเลือกไฟล์รูปสินค้า",
    vat_20_file_require: "กรุณาเลือกไฟล์ภพ.20",
    founded_year_invalid: "กรุณากรอกปีที่ก่อตั้ง(พ.ศ.)ที่ถูกต้อง",
    number_employee_invalid: "กรุณากรอกจำนวนพนักงานที่ถูกต้อง",
    productTypeProductImage: "ประเภทสินค้า/ รูปสินค้า",
    info_one_image: "รองรับ 1 รูป เฉพาะ .JPEG,.PNG ขนาดไม่เกิน 5 MB)",
    info_one_file: "รองรับ 1 ไฟล์ เฉพาะ .JPEG,.PNG,.PDF ขนาดไม่เกิน 5 MB)",
  },

  assign: {
    select_vehicle: "เลือกรถ",
    plate_no: "ทะเบียนรถ",
    plate_no_p: "ทะเบียนจังหวัด",
    area: "พื้นที่ให้บริการ",
    insurance: "วันที่ประกันหมดอายุ",
    act: "วันที่ พรบ. หมดอายุ",
    driver: "ชื่อคนขับ",
    vehicle_picture: "ภาพถ่ายรถ",
    return: "คืนงาน",
    assign_job: "มอบหมายงาน",
    assign_f: "เสร็จสิ้น",
    confirm: "ยืนยันแล้ว",
  },

  companyDetails: {
    personal: "บุคคลทั่วไป",
    transporter: "ผู้ให้บริการขนส่ง",
    productowner: "เจ้าของสินค้า",
  },

  companyFileList: {
    productfile: "รูปสินค้า",
    certificatefile: "หนังสือรับรองบริษัท",
    idcardfile: "สำเนาบัตรประชาชน",
    driverlicensefile:
      "สำเนาใบอนุญาตขับรถสาธารณะหรือส่วนบุคคลตั้งแต่ประเภท 2 ขึ้นไป",
    licensetransportfile: "ขส.บ.11/ขส.บ.12",
    vat20file: "ภพ.20",
  },

  signup: {
    invalid_verification_code: "คุณกรอกรหัสยืนยันตัวตนไม่ถูกต้อง",
    cannot_reset_password: "มีข้อผิดพลาดเกิดขึ้นไม่สามารถเปลี่ยนรหัสผ่านได้",
    cannot_signup_verify_by_phone:
      "มีข้อผิดพลาดเกิดขึ้นไม่สามารถยืนยันตัวตน สำหรับสมัครสมาชิกได้",
    email_already: "อีเมล์นี้มีอยู่แล้วในระบบ",
    update_password_success: "ระบบได้ทำการเปลี่ยนรหัสผ่านของคุณเรียบร้อยแล้ว",
    signup_success:
      "ท่านได้ลงทะเบียนใช้งานระบบ Khonkhong เรียบร้อยแล้ว! กรุณารอการยืนยันการตรวจสอบข้อมูล ภายใน 1 วันทำการ",
    cannot_send_otp: "ไม่สามารถส่ง OTP ไปที่หมายเลขโทรศัพท์ได้",
    phone_already: "เบอร์โทรศัพท์นี้มีอยู่แล้วในระบบ",
    otp_too_many_requests:
      "คุณร้องขอรหัส OTP เกินจำนวนครั้งที่กำหนด กรุณาลองใหม่อีกครั้งในภายหลัง",
    signup_not_complete:
      "คุณยังกรอกข้อมูลส่วนการสมัครใช้งานยังไม่ครบถ้วน ต้องการจะทำต่อหรือไม่",
    signup_not_complete2:
      "เบอร์โทรศัพท์หรืออีเมลนี้ได้ทำการสมัครไปแล้ว แต่ยังกรอกข้อมูลส่วนการสมัครใช้งานยังไม่ครบถ้วน ต้องการจะทำต่อหรือไม่",
    signup_duplicate:
      "เบอร์โทรศัพท์หรืออีเมลนี้ได้ทำการสมัครไปแล้ว กรุณาสมัครด้วยเบอร์โทรศัพท์หรืออีเมลใหม่",
    contact_require: "กรุณากรอกข้อมูลติดต่อบริษัท",
    no_user_in_system: "ไม่มีอีเมลหรือเบอร์โทรนี้ในระบบ",
  },

  login: {
    invalid_username_password: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง",
    can_not_verify:
      "ชื่อผู้ใช้งานของคุณยังไม่ได้รับการตรวจสอบข้อมูลการสมัครจาก ระบบ Khonkhong กรุณารออีเมลยืนยันการตรวจสอบข้อมูล",
    something_went_wrong: "ระบบมีปัญหาไม่สามารถเข้าใช้งานได้",
    data_not_complete: "ข้อมูลการสมัครของคุณยังไม่สมบูรณ์",
    popup_data_not_complete:
      "ข้อมูลการสมัครของคุณยังไม่สมบูรณ์ คุณต้องการที่จะกรอกข้อมูลต่อหรือไม่",
  },

  vehicle_type: {
    high_stall_4wheelers: "4 ล้อ คอกสูง",
    jumbo_solid_4wheelers: "4 ล้อ จัมโบ้ ตู้ทึบ",
    jumbo_stall_4wheelers: "4 ล้อ จัมโบ้ คอก",
    jumbo_freeze_4wheelers: "4 ล้อ จัมโบ้ ตู้เย็น",
    solid_4wheelers: "4 ล้อ ตู้ทึบ",
    freeze_4wheelers: "4 ล้อ ตู้เย็น",
    freeze_truck_6wheelers: "6 ล้อ ตู้เย็น",
    cargo_truck_6wheelers: "6 ล้อกระบะคาร์โก้",
    solid_truck_55_6wheelers: "6 ล้อตู้ทึบ 5.5 เมตร",
    high_stall_truck_55_6wheelers: "6 ล้อคอกสูง 5.5 เมตร",
    solid_truck_65_6wheelers: "6 ล้อตู้ทึบ 6.5 เมตร",
    high_stall_truck_65_6wheelers: "6 ล้อคอกสูง 6.5 เมตร",
    solid_truck_7_6wheelers: "6 ล้อตู้ทึบ 7 เมตร",
    high_stall_truck_7_6wheelers: "6 ล้อคอกสูง 7 เมตร",
    flat_truck_6wheelers: "6 ล้อพื้นเรียบ",
    crane_truck_6wheelers: "6 ล้อเครน",
    freeze_truck_10wheelers: "10 ล้อ ตู้เย็น",
    high_stall_truck_dump_10wheelers: "10 ล้อคอกสูงดัมพ์",
    cargo_truck_10wheelers: "10 ล้อกระบะคาร์โก้",
    solid_truck_10wheelers: "10 ล้อตู้ทึบ",
    low_stall_truck_10wheelers: "10 ล้อคอกตํ่า",
    tanger_truck_10wheelers: "10 ล้อแทงเกอร์",
    low_stall_truck_dump_10wheelers: "10 ล้อคอกตํ่าดัมพ์",
    crane_truck_10wheelers: "10 ล้อเครน",
    high_stall_truck_10wheelers: "10 ล้อคอกสูง",
    flat_floor_truck_10wheelers: "10 ล้อพื้นเรียบ",
    low_stall_trailer: "พ่วงคอกตํ่า",
    high_stall_dump_trailer: "พ่วงคอกสูงดัมพ์",
    low_stall_dump_trailer: "พ่วงคอกตํ่าดัมพ์",
    solid_trailer: "พ่วงตู้ทึบ",
    high_stall_trailer: "พ่วงคอกสูง",
    flat_floor_trailer: "พ่วงพื้นเรียบ",
    towing_head_truck: "หัวลาก",
    tail_truck: "หางก้าง",
    tail_stall_truck: "หางคอก",
    tail_stall_dump_truck: "หางคอกดั๊มพ์",
    freeze_tail_20ft_truck: "หางตู้เย็น 20ft",
    freeze_tail_40ft_truck: "หางตู้เย็น 40ft",
    tail_flat_truck: "หางพื้นเรียบ",
    tail_flat_low_truck: "หางพื้นเรียบชานต่ำ (Low Bed)",
    tail_side_truck: "หางม่านข้าง",
    tanger_truck: "หางแทงเกอร์",
  },

  m_product_type: {
    computer_accessories_officeEquipments_electricalMachines:
      "เครื่องคอมพิวเตอร์และอุปกรณ์ เครื่องใช้สำนักงาน เครื่องใช้ไฟฟ้า",
    craftsmanTool_lunber_metal_steel_constructionMaterial_baggedCement_readyMixedCement:
      "อุปกรณ์ช่าง ไม้แปรรูป โลหะ เหล็ก วัสดุอุปกรณ์ก่อสร้าง ปูนซีเมนต์บรรจุถุง ปูนซีเมนต์ผสมเสร็จ ยางมะตอยมีบรรจุภัณฑ์",
    autoParts_lubricantInBucketOrGallon:
      "อะไหล่รถยนต์ น้ำมันหล่อลื่นบรรจุถังหรือแกลลอน",
    furniture: "สินค้าตกแต่งบ้าน เฟอร์นิเจอร์ ",
    schoolSupplies: "อุปกรณ์การเรียนการสอน",
    medicine_medicalAppliance: "ยารักษาโรค อุปกรณ์เครื่องมือแพทย์",
    agriculturalProducts:
      "ผลิตผลทางการเกษตร พืชไร่ พืชสมุนไพรต่างๆ ผลิตผลแปรรูปทั้งหมดที่ผลิตจากพืชไร่ (เฉพาะที่มีบรรจุภัณฑ์ห่อหุ้มสินค้า) เช่นข้าวโพด ข้าวฟ่าง เมล็ดถั่ว ต้นถั่ว มันฝรั่ง มันเทศ มันสำปะหลัง พืชสมุนไพรอบแห้งต่างๆ ฝ้าย ปอ ป่าน นุ่น ถั่วเหลือง ละหุ่ง ทานตะวัน งา ปาล์ม ยางพารา ตาล ข้าวเปลือก ข้าวสาร ฯลฯ",
    cothAndAccessories_textiles_syntheticFabrics_leather:
      "เสื้อผ้า อุปกรณ์เครื่องแต่งกาย สิ่งทอ ใยสังเคราะห์ หนังสัตว์",
    consumerProducts_processedFood_groceryProducts:
      "สินค้าอุปโภค-บริโภค ผลิตภัณฑ์อาหารแปรรูป สินค้าโชห่วย (เครื่องอุปโภค-บริโภคที่ใช้ในชีวิตประจำวัน)",
    bulkCargo: "สินค้าเทกอง (สินค้าที่ไม่มีบรรจุภัณฑ์ห่อหุ้ม)",
    alcoholBeverages_soda_energyDrink_foodAndBeverageInGlassBottles:
      "เครื่องดื่มแอลกอฮอล์ โซดา เครื่องดื่มชูกำลัง อาหารและเครื่องดื่มบรรจุขวดแก้ว เครื่องแก้ว ขวดแก้ว บานกระจกทุกประเภท เซรามิค กระเบื้อง สินค้าแตกหักเสียหายง่าย ไข่สด",
    allTypesOfMachinesAndEngines_less1000kg:
      "เครื่องจักรทุกประเภท เครื่องยนต์ทุกประเภท (ที่มีน้ำหนักไม่เกินกว่า 1,000 กิโลกรัม ต่อยานพาหนะ)",
    carAndMotorcycle:
      "รถยนต์ รถจักรยานยนต์ รถยนต์ ยี่ห้อ MERCEDES-BENZ, TOYOTA, ISUZU, HONDA, MITSUBISHI, NISSAN, FORD, MAZDA, CHEVROLET, SUZUKI, MG, KIA, TATA",
    motorcycleBrand_kawasaki_suzuki_honda_yamaha:
      "รถจักรยานยนต์ ยี่ห้อ KAWASAKI, SUZUKI, HONDA,YAMAHA",
    allTypesOfGoodsLoadedOnFlatbedVehicle:
      "สินค้าทุกประเภทที่บรรทุกอยู่บนยานพาหนะพื้นเรียบ",
    allTypesOfMachinesAndEngines_over1000kg:
      "เครื่องจักรทุกประเภท เครื่องยนต์ทุกประเภท (ที่มีน้ำหนักเกินกว่า 1,000 กิโลกรัม ต่อยานพาหนะ)",
    agriculturalAndIndustrialVehiclesSuchAsNewTractors:
      "รถที่ใช้เพื่อการเกษตรและอุตสาหกรรม เช่น รถไถใหม่ รถเกี่ยวข้าวใหม่ รถแทรกเตอร์ใหม่ รถโฟล์คลิฟท์ใหม่ ฯลฯ",
    freshFood_freshSeafood: "อาหารสด อาหารทะเลสด ",
    animalLife_aquaticAnimals:
      "สิ่งมีชีวิตที่เป็นสัตว์เศรษฐกิจ (เฉพาะสัตว์น้ำ) เช่น  กุ้ง หอย ปู ปลา ฯลฯ",
    animalLife_terrestrialAnimal:
      "สิ่งมีชีวิตที่เป็นสัตว์เศรษฐกิจ (เฉพาะสัตว์บก) เช่น  โค กระบือ ไก่ สุกร ฯลฯ",
    freshVegetables_freshFruits_plants_flowers_grass:
      "ผักสด ผลไม้สด ต้นไม้ ดอกไม้ ต้นหญ้า",
    iceCream_frozenFood_chilledProducts:
      "ไอศกรีม อาหารแช่แข็ง หรือสินค้าแช่เย็น ",
    productIsInConditionThatRequiresTemperatureControl:
      "สินค้าที่อยู่ในสภาพต้องควบคุมอุณหภูมิ ฯลฯ",
    fuelInTank: "น้ำมันเชื้อเพลิงบรรจุแท็งก์",
    liquidInTank_excludingDangerouesGoodsAccordingToAnnouncement:
      "ของเหลวบรรจุแท็งก์ (ยกเว้นวัตถุอันตรายทุกชนิดตามประกาศกระทรวงอุตสาหกรรม)",
    gasInTank: "ก๊าซบรรจุแท็งก์ ",
  },

  m_credit_term: {
    days15: "15 วัน",
    days30: "30 วัน",
    days40: "45 วัน",
    after_finishing_work: "หลังเสร็จงาน",
    before_finishing_work: "ก่อนเริ่มงาน",
  },

  m_unit_price: {
    baht_trip: "บาท/เที่ยว",
    baht_ton: "บาท/ตัน",
    baht_km: "บาท/ก.ม.",
    baht_day: "บาท/วัน",
  },

  m_contract_term: {
    year1: "1 ปี",
    year2: "2 ปี",
    year3: "3 ปี",
  },

  m_frequency_routine: {
    everydays_7days_week: "ทุกวัน 7 วัน/สัปดาห์",
    everyWorkingDay_5days_week: "ทุกวันทำงาน 5 วัน/สัปดาห์",
    everyMonday_1days_week: "ทุกวันจันทร์ 1 วัน/สัปดาห์",
    everyTuesday_1days_week: "ทุกวันอังคาร 1 วัน/สัปดาห์",
    everyWednesday_1days_week: "ทุกวันพุธ 1 วัน/สัปดาห์",
    everyThursday_1days_week: "ทุกวันพฤหัส 1 วัน/สัปดาห์",
    everyFriday_1days_week: "ทุกวันศุกร์ 1 วัน/สัปดาห์",
    everySaturday_1days_week: "ทุกวันเสาร์ 1 วัน/สัปดาห์",
    everySunday_1days_week: "ทุกวันอาทิตย์ 1 วัน/สัปดาห์",
  },

  logistic: {
    logistic_create: "สร้างกลุ่มขนส่ง",
    logistic_edit: "แก้ไขกลุ่มขนส่ง"
  }
  
};

export { th };

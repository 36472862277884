import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

import { Link } from "react-router-dom";
import { Divider, Row, Col, Table, Button, Pagination, Spin, Badge, Tooltip } from "antd";

import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import * as moment from "moment";
import axios from "axios";
import { translate, checkEmptyJson } from "../../../../../../lib/helper";
import { i18n } from "modules/shared/context/langContext";

import IndexFilterDrawer from "./IndexFilterDrawer"

import "../../../../../../styled/transport-dashboard.css";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "m",
    keyLang: "md_job",
    dataKey: "document_number",
    sorter: false,
    render: "normal",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_product",
    dataKey: "product_type",
    sorter: true,
    render: "normal",
    width: "14%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: false,
    render: "address",
    width: "13%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "address",
    width: "13%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_porter",
    dataKey: "staff_lift",
    sorter: true,
    render: "staff_lift",
    width: "7%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_payment_status",
    dataKey: "service_payment_type",
    sorter: true,
    render: "payment_type",
    width: "8%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_vehicle_type",
    dataKey: "vehicle_type",
    sorter: true,
    render: "normal",
    width: "10%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_process",
    dataKey: "worksheet_id",
    sorter: false,
    render: "action",
    width: "15%",
    cls: "vertical-top algin-center",
  },
];

const renderAddress = (val, key) => {
  if(typeof val === "undefined") return ""
  let items = val.split(",")
  let line = 1;
  let withLine = key === "origin" ? false : true

  if(val === "") {
    return <p></p>
  }

  return items.map((item, i) => {
    let txt = item.split(" (")
    if(withLine)
  return <p key={i}>{line+i}. {txt[0]} <br/> ({txt[1]}</p>
    else 
      return <p key={i}>{txt[0]} <br/> ({txt[1]}</p>
  })
}


export class MarketList extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
    orderBy: "worksheets.created_at",
    orderType: "desc",
    filters: [],
    province: [],
    vehicleType: [],
    paymentType: [],
    productType: [],
    isFilter: false,
    filterVisible: false,
    filters: {
      vehicle_type: "",
      origin: "",
      destination: "",
      porter: "",
      payment_type: "",
      product_type: "",
      created_at: null
    },
  };

  genTableHeader() {
    const columns = [];
    tableHeader.map((v) => {
      columns.push({
        title: translate(`${v.keyLang}`, `${v.idxLang}`),
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderComponent(v.render, record, v.dataKey),
          };
        },
      });
    });
    return columns;
  }
  
  renderComponent = (type, val, key) => {
    switch (type) {
      case "action":
        return this.renderActionBtn(val);
      case "address":
        return renderAddress(val[key], key)
      case "staff_lift":
        let keyInx = val[key] === "มี" ? "have_porter" : "have_no_porter";
        return <div>{translate(keyInx, "h")}</div>;
      case "payment_type":
        return <div>{translate(val[key], "worksheet")}</div>;
      default:
        return this.renderNormalTxt(val[key], key);
    }
  };

  renderActionBtn = (val) => (
    <div>
      <Link to={`/dashboard/transport-market/view/${val.worksheet_id}`}>
        <Button className="btn_status" size="large" data-cy={`btn-view-${val.worksheet_id}`}>
          {translate("comment", "c")}
        </Button>
      </Link>
      <br />
      <span className="md_txt_announcement">
        {translate("announcement", "a")}
        &nbsp;&nbsp;{moment(val.announce_date).format("DD/MM/YYYY")}
      </span>
    </div>
  );

  renderNormalTxt = (val, key) =>  {
    let txt = ""
    switch(key) {
      case "vehicle_type" : txt = i18n[this.props.langBtn]["vehicle_type"][val]; break;
      case "product_type" : txt = i18n[this.props.langBtn]["m_product_type"][val]; break;
      default: txt = val; break;
    }
    
    if(typeof txt !== "undefined") {
      if (txt.length > 55)
      return  <Tooltip title={txt}><span>{txt.substr(0, 55) + "..."}</span></Tooltip>;
    else 
      return <span>{txt}</span>;
    } else {
      return <span>{val}</span>;
    }
  }
  

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = "worksheets.created_at";
      orderType = "asc";
    } else {
      orderBy = this.getRealKey(sorter.columnKey);
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.setState({
      orderBy,
      orderType,
    });

    this.props.loadTransportMarket(
      this.state.currentPage,
      this.state.pageSize,
      orderBy,
      orderType,
      this.state.filters
    );
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadTransportMarket(
      page,
      pageSize,
      this.state.orderBy,
      this.state.orderType,
      this.state.filters
    );
  };

  getRealKey = (key) => {
    switch (key) {
      case "product_type":
        return "m_product_type.name";
      case "origin":
        return "locations.name";
      case "vehicle_type":
        return "vehicle_types.name";
      case "status":
        return "worksheets.status";
      default:
        return key;
    }
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  onFilterButtonClick = () => {
    const { page, pageSize, orderBy, orderType, filters } = this.state;

    this.props.loadTransportMarket(page, pageSize, orderBy, orderType, filters);
  };

  filterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({
      filters,
      isFilter: !checkEmptyJson(filters) && (value !== "" && value !== null) ,
    });
  };

  loadProvince = () => {
    const ACCESSTOKEN = localStorage.getItem("access-token");
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getprovince`,
        {},
        { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
      )
      .then((res) => {
        this.setState({ province: res.data.data });
      });
  };

  loadVehicleyType = () => {
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicletype`,
        {}
      )
      .then((response) => {
        if (typeof response.data.data !== "undefined") {
          let vehicleType = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name_key,
            };
          });
          this.setState({ vehicleType });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  productDetail = () => {
    let self = this;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getproductdetail`,
        {},
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let product_detail = response.data.data.map((v) => {
            return {
              id: v.id,
              name: v.name_key,
            };
          });
          self.setState({ productType: product_detail });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  componentDidMount() {
    const { page, pageSize, orderBy, orderType, filters } = this.state;
    this.props.loadTransportMarket(page, pageSize, orderBy, orderType, filters);
    this.loadVehicleyType();
    this.loadProvince();
    this.productDetail();
  }

  render() {
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="transport_market_dashboard">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customerl={[null, "/dashboard/work-inprogress", null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                {translate("dashboard_transport_market", "d")}
              </Divider>
            </Col>
          </Row>
          <Row gutter={24} justify="end" style={{ height: "38px" }}>
            <Col span={2} offset={22}>
              <Badge dot={this.state.isFilter} align="right">
                <Button
                  data-cy="btn-filter"
                  icon="filter"
                  size="small"
                  align="right"
                  onClick={this.showFilterDrawer}
                >
                  <span></span>
                  {translate("filter", "f")}
                </Button>
              </Badge>

              <IndexFilterDrawer
                onClose={this.onFilterDrawerClose}
                visible={this.state.filterVisible}
                onChange={this.filterChange}
                vehicleType={this.state.vehicleType}
                paymentType={this.state.paymentType}
                productType={this.state.productType}
                province={this.state.province}
                value={this.state.filters}
                onFilterButtonClick={this.onFilterButtonClick}
                lang={this.props.langBtn}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="worksheet_id"
                  columns={columns}
                  dataSource={this.props.transportmarket}
                  bordered
                  pagination={false}
                  onChange={this.handleTableChange}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportmarket, ui: {langBtn}  }) => ({
  transportmarket: transportmarket.lists,
  totalRows: transportmarket.totalRows,
  loading: transportmarket.loading,
  success: transportmarket.success,
  langBtn
});

const mapDispatchToProps = {
  loadTransportMarket: actions.loadTransportMarket.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketList);

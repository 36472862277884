import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { loadlogisticGroupDetail, removeLists } from "../actions";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Link } from "react-router-dom";
import { StyledSearchForm } from "../../../../styled/common-styled";
import IndexFilterDrawer from "./DetailFilterDrawer";
import axios from "axios";
import {
  Button,
  Table,
  Row,
  Col,
  Pagination,
  Spin,
  Divider,
  Badge,
  Upload,
  Icon,
  Modal,
} from "antd";
const columnStyle = { fontSize: "12px", fontWeight: "400" };
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject();
  });
}
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_logo_list: [],
      file_logo_preview_visible: false,
      file_logo_preview: "",
      filterVisible: false,
      expand: false,
      visible: false,
      Discription: {
        key: "",
        value: "",
        group_name: "",
        detail: "",
        path_logo: "",
      },
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      orderBy: "logistic_groups.id",
      orderType: "desc",
    };
    this.columns = [
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.n.no}</LangContext.Consumer>
        ),
        // "No.",
        dataIndex: "rowNumber",
        key: "rowNumber",
        width: 60,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.company}
          </LangContext.Consumer>
        ),
        dataIndex: "company_name",
        key: "company_name",
        width: 250,
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <img
                  style={{ paddingRight: "10px" }}
                  width="80"
                  height="80"
                  src={record.logo_file}
                />
                {text}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.a.address}
          </LangContext.Consumer>
        ),
        dataIndex: "address",
        key: "address",
        width: 250,
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.contact}
          </LangContext.Consumer>
        ),
        dataIndex: "contact",
        key: "contact_company_information",
        width: 200,
        sorter: false,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                {record.contact_company_information !== undefined
                  ? record.contact_company_information.map((c, index) => (
                      <LangContext.Consumer>
                        {(i18n) => (
                          <Row>
                            <Row>
                              <Col>
                                {c.title_name != ""
                                  ? i18n.m[c.title_name] +
                                    " " +
                                    c.firstname +
                                    " " +
                                    c.lastname
                                  : ""}
                              </Col>
                            </Row>
                            <Row>
                              <Col>{i18n.p.phone + " : " + c.phone}</Col>
                            </Row>
                            <Row>
                              <Col>{i18n.e.email + " : " + c.email}</Col>
                            </Row>
                            {record.contact_company_information.length - 1 ==
                            index ? (
                              ""
                            ) : (
                              <Divider
                                style={{ margin: "10px 0px 10px 0px" }}
                              />
                            )}
                          </Row>
                        )}
                      </LangContext.Consumer>
                    ))
                  : ""}
              </div>
              // <div>
              //   <Row>
              //     <Col>
              //       <LangContext.Consumer>
              //         {(i18n) =>
              //           record.title_name != ""
              //             ? i18n.m[record.title_name]
              //             : "" + record.firstname + " " + record.lastname
              //         }
              //       </LangContext.Consumer>
              //     </Col>
              //   </Row>
              //   <Row>
              //     <Col>
              //       <LangContext.Consumer>
              //         {(i18n) => i18n.p.phone + ": " + record.phone}
              //       </LangContext.Consumer>
              //     </Col>
              //   </Row>
              //   <Row>
              //     <Col>
              //       <LangContext.Consumer>
              //         {(i18n) => i18n.e.email + ": " + record.email}
              //       </LangContext.Consumer>
              //     </Col>
              //   </Row>

              //   {text}
              // </div>
            ),
          };
        },
      },
    ];
  }
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      file_logo_preview: file.url || file.preview,
      file_logo_preview_visible: true,
    });
  };

  handleCancel = () => this.setState({ file_logo_preview_visible: false });
  componentDidMount() {
    const id = this.props.match.params.logistic_groups_id;
    let self = this;
    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/descripctionlogisticgroupname/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        console.log(response.data.data);
        if (typeof response.data.data !== "undefined") {
          let discriptionAPI = response.data.data.map((discrip) => {
            return {
              key: discrip.logistic_groups_id,
              group_name: discrip.groups_name,
              detail: discrip.detail,
              path_logo: discrip.path_logo,
            };
          });
          console.log(discriptionAPI);
          self.setState({
            file_logo_list: [
              {
                uid: "-1",
                name: "file_logo.jpg",
                url:
                  discriptionAPI.length > 0 ? discriptionAPI[0].path_logo : "",
              },
            ],
            Discription: discriptionAPI.length > 0 ? discriptionAPI[0] : {},
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

    this.loadData(true);
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  loadData = (loading) => {
    this.props.loadlogisticGroupDetail(
      {
        id: this.props.match.params.logistic_groups_id,
        searchName: this.state.searchName,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };

  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadvehicleGroupDetail(
      {
        page: page,
        pageSize: pageSize,
        id: this.props.match.params.logistic_groups_id,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
      },
      true
    );
  };

  handleSearch = (value) => {
    if (value != this.state.searchName) {
      this.props.loadlogisticGroupDetail(
        {
          searchName: value,
          id: this.props.match.params.logistic_groups_id,
          page: this.state.currentPage,
          pageSize: this.state.pageSize,
          orderBy: this.state.orderBy,
          orderType: this.state.orderType,
          accessToken: this.props.auth.accessToken,
        },
        true
      );
      this.setState({
        searchName: value,
        currentPage: 1,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "companies.name",
        orderType: "asc",
      });
      orderBy = "companies.name";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadlogisticGroupDetail(
      {
        id: this.props.match.params.logistic_groups_id,
        searchName: this.state.searchName,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        orderBy: orderBy,
        orderType: orderType,
        accessToken: this.props.auth.accessToken,
      },
      true
    );
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      file_logo_preview: file.url || file.preview,
      file_logo_preview_visible: true,
    });
  };

  handleCancel = () => this.setState({ file_logo_preview_visible: false });

  // Filter
  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  onFilterButtonClick = (e) => {
    this.loadData(true);
  };

  handleFilterLogisticGroup = (e) => {
    //  console.log("ser:", e.target.value);
    this.setState({
      searchName: e.target.value,
    });
  };

  render() {
    const { total, loading } = this.props.dataLogisticGroup;
    const columns = this.columns.map((col) => col);
    const logistic_groups_id = this.props.match.params.logistic_groups_id;
    const {
      file_logo_list,
      file_logo_preview,
      file_logo_preview_visible,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="upload" />
        <LangContext.Consumer>
          {(i18n) => i18n.s.select_file}
        </LangContext.Consumer>
      </div>
    );
    return (
      <AuthorizeComponent matching_name="logisticgroup">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <StyledSearchForm>
                <Row gutter={24}>
                  <Col sm={24} md={2} lg={2} xl={2}></Col>
                  <Col sm={24} md={11} lg={11} xl={11}>
                    <Row style={{ marginTop: "10px" }}>
                      <Col span={10} style={{ padding: "5px" }} align="right">
                        <p>
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.l.logisticGroupName}
                            </LangContext.Consumer>
                          }
                          :
                        </p>
                      </Col>
                      <Col span={14} style={{ padding: "5px" }}>
                        <p>{this.state.Discription.group_name}</p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "5px" }}>
                      <Col span={10} style={{ padding: "5px" }} align="right">
                        <p>
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.d.detail}
                            </LangContext.Consumer>
                          }
                          :
                        </p>
                      </Col>
                      <Col span={14} style={{ padding: "5px" }}>
                        <p>{this.state.Discription.detail}</p>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "5px" }}>
                      <Col span={10} style={{ padding: "5px" }} align="right">
                        <p>
                          {
                            <LangContext.Consumer>
                              {(i18n) => i18n.business.logistic_logo_file}
                            </LangContext.Consumer>
                          }
                          :
                        </p>
                      </Col>
                      <Col span={14} style={{ padding: "5px" }}>
                        <Upload
                          className="test-upload-file-logo-list"
                          name="avatar"
                          listType="picture-card"
                          fileList={file_logo_list}
                          onPreview={this.handlePreview}
                          showRemoveIcon={false}
                          onRemove={false}
                          disabled={true}
                        >
                          {file_logo_list.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal
                          className="test-modal-preview-logo"
                          visible={file_logo_preview_visible}
                          onCancel={this.handleCancel}
                          footer={null}
                        >
                          <img
                            alt="avatar"
                            style={{ width: "100%" }}
                            src={file_logo_preview}
                          />
                        </Modal>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledSearchForm>
            </Col>
          </Row>

          <Row gutter={12} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={18}>
              <Link to={`/logisticgroup/edit/${logistic_groups_id}`}>
                <Button
                  data-cy="btn-go-back"
                  align="left"
                  icon="edit"
                  size="small"
                  type="primary"
                  align="left"
                >
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                </Button>
              </Link>
              <br />
            </Col>

            <Col span={4} />
            <Col span={2}>
              <Badge dot={this.state.isFilter} align="right">
                <Button
                  data-cy="btn-filter"
                  icon="filter"
                  size="small"
                  align="right"
                  onClick={this.showFilterDrawer}
                >
                  <span></span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.filter}
                  </LangContext.Consumer>
                </Button>
              </Badge>

              <IndexFilterDrawer
                onClose={this.onFilterDrawerClose}
                visible={this.state.filterVisible}
                onFilterButtonClick={this.onFilterButtonClick}
                handleFilterLogisticGroup={this.handleFilterLogisticGroup}
                // handleFilterObjNumber={this.handleFilterObjNumber}
              />
            </Col>
          </Row>

          <Row>
            <Spin spinning={loading}>
              <Table
                data-cy="data-table"
                bordered
                rowKey="plate_no"
                columns={columns}
                dataSource={this.props.dataLogisticGroup.lists}
                // size="small"
                pagination={false}
                onChange={this.handleTableChange}
              />
            </Spin>
          </Row>

          <Row
            type="flex"
            justify="end"
            style={{ paddingBottom: "75px", paddingTop: "15px" }}
          >
            <Col sm={24} md={12} lg={12} xl={12} align="left">
              <Link to="/logisticgroup">
                <Button type="default" width="100px" data-cy="btn-go-back">
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.back}
                  </LangContext.Consumer>
                </Button>
              </Link>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={total}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ dataLogisticGroup, auth }) => ({ dataLogisticGroup, auth }),
  {
    loadlogisticGroupDetail: loadlogisticGroupDetail.request,
    removeLists,
  }
)(Index);

import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  Divider,
  Spin,
  Upload,
  Icon,
  message,
  Modal,
  Tooltip,
  Steps,
} from "antd";

import LangContext, { i18n } from "modules/shared/context/langContext";
import axios from "axios";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const {Step} = Steps
export default class Personalform extends Component {
  state = {
    fileIdCard: [],
    fileDrivingCard: [],
    loading: false,
  };

  

  componentDidMount() {
    const { loadProvince } = this.props;
    loadProvince();
    // console.log("props:", this.props);
  }

  // shouldComponentUpdate(nextProps) {
  //   console.log(this.props.current, nextProps.current);
  //   if (this.props.current !== nextProps.current) {
  //     console.log("false");
  //     return false;
  //   } else {
  //     console.log("true");
  //     return true;
  //   }
  // }

  onChangeProvince = (value, option, setFieldValue) => {
    const { loadAmphur } = this.props;
    setFieldValue("province", value, false);
    setFieldValue("district", "");
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadAmphur(value);
  };

  onChangeDistrict = (value, option, setFieldValue) => {
    const { loadSubAmphur } = this.props;
    setFieldValue("district", value, false);
    setFieldValue("subdistrict", "");
    setFieldValue("postalcode", "");
    loadSubAmphur(value);
  };

  onChangeSubDistrict = (value, option, setFieldValue) => {
    setFieldValue("subdistrict", value, false);
    setFieldValue("postalcode", option.props.code, false);
  };
  

   getStep = (step) => {
   console.log("🚀 ~ file: Personalform.js ~ line 79 ~ Personalform ~ step", step)
    let stepJSX = []
      for(let loopIndex = 1 ; loopIndex <= step ; loopIndex++)
        stepJSX = [...stepJSX , loopIndex]
    return(
      <Steps current={2} >   
        {stepJSX.map(() =>(
          <Step title="" description="" />
        ))}  
      </Steps>
    )
  }

  render() {
    //console.log("PersonalForm");
    const {
      onSubmit,
      provinceLists,
      amphurLists,
      subamphurLists,
      phone,
      email,
      loading,
      step
    } = this.props;

    const styleForm = {
      backgroundColor:"#fafbfb", 
      minHeight: "calc( 100vh - 420px)",
      borderRadius: "15px",
      padding:"5% 10% ",
      boxShadow: "10px 16px 23px -12px rgba(0, 0, 0, 0.13)",
    }
    const styleFormLabel = {
      fontSize:"13px",
      color:"#2f67dc",
    }


    return (
      <div>
      <Row>
        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={5}/>
        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={14}>
        <div style={styleForm}>
            <LangContext.Consumer>
              {(i18n) => (
                <Formik
                enableReinitialize={true}
                initialValues={{
                    title_name: "mr",
                    firstname: "",
                    lastname: "",
                    buildingname: "",
                    roomnumber: "",
                    floor: "",
                    village: "",
                    houseno: "",
                    villageno: "",
                    soi: "",
                    province: "",
                    district: "",
                    subdistrict: "",
                    postalcode: "",
                    phone: phone,
                    email: email,
                    fileidcard: [],
                    filedriverlicense: [],
                  }}
                  validate={(values) => {
                    let errors = {};
                    
                    if (values.fileidcard.length == 0) {
                      errors.fileidcard = i18n.addr.fileIdCard_require;
                    }
                    
                    if (values.filedriverlicense.length == 0) {
                      errors.filedriverlicense = i18n.addr.fileDrivingCard_require;
                    }
                    
                    if (/[^A-Za-zก-๙ะ-ไ ]+$/.test(values.firstname) === true) {
                      errors.firstname = i18n.addr.firstname_invalid;
                    }
                    
                    if (/[^A-Za-zก-๙ะ-ไ ]+$/.test(values.lastname) === true) {
                      errors.lastname = i18n.addr.lastname_invalid;
                    }
                    
                    return errors;
                  }}
                  validationSchema={yup.object().shape({
                    firstname: yup.string().required(i18n.addr.firstname_require),
                    lastname: yup.string().required(i18n.addr.lastname_require),
                    houseno: yup.string().required(i18n.addr.houseno_require),
                    province: yup.string().required(i18n.addr.province_require),
                    district: yup.string().required(i18n.addr.district_require),
                    subdistrict: yup
                      .string()
                      .required(i18n.addr.subdistrict_require),
                      postalcode: yup.string().required(i18n.addr.postalcode_require),
                    })}
                    onSubmit={(values) => {
                      onSubmit(values);
                    }}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleFocus,
                    setFieldValue,
                  }) => (
                    <Spin spinning={loading}>
                      <Form onSubmit={handleSubmit}>
                       <Row>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"center"}}>
                            <h2 style={{color:"#2f67dc",fontWeight:"bold"}}> สร้างบัญชี</h2>
                            <h2 style={{color:"#2f67dc"}}>กรอกรายละเอียด</h2>
                          </Col>
                        </Row>
                        {/* <Divider dashed orientation="left">
                          <h2>{i18n.c.contactinfo}</h2>
                        </Divider> */}
                        <Row>
                            <Col xs={10} sm={0} md={0} lg={0} xl={0}/>
                            <Col  xs={12} sm={24} md={24} lg={24} xl={24} style={{padding:"20px 0px"}}>
                              {this.getStep(step)}
                            </Col>
                          </Row>
                        <Row>
                          <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                            <FormItem
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.f.firstName}
                                </span>
                              }
                              validateStatus={
                                touched.firstname && errors.firstname && "error"
                              }
                              help={touched.firstname && errors.firstname}
                              >
                              <InputGroup compact>
                                <Select
                                  data-cy="title_name"
                                  style={{ width: "83px" }}
                                  onChange={(value) =>
                                    setFieldValue("title_name", value)
                                  }
                                  value={values.title_name}
                                  >
                                  <Option value="mr">{i18n.m.mr}</Option>
                                  <Option value="mrs">{i18n.m.mrs}</Option>
                                  <Option value="miss">{i18n.m.miss}</Option>
                                </Select>
                                <Input
                                  data-cy="firstname"
                                  type="text"
                                  name="firstname"
                                  value={values.firstname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ width: "calc( 100% - 83px )" }}
                                  invalid={
                                    touched.firstname && errors.firstname
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </InputGroup>
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.l.lastName}
                                </span>
                              }
                              required={true}
                              validateStatus={
                                touched.lastname && errors.lastname && "error"
                              }
                              help={touched.lastname && errors.lastname}
                              >
                              <Input
                                data-cy="lastname"
                                type="text"
                                name="lastname"
                                value={values.lastname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.lastname && errors.lastname
                                  ? "invalid"
                                    : undefined
                                  }
                                  />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.buildingName}
                                </span>
                              }
                              validateStatus={
                                touched.buildingname &&
                                errors.buildingname &&
                                "error"
                              }
                              help={touched.buildingname && errors.buildingname}
                              >
                              <Input
                                data-cy="buildingname"
                                type="text"
                                name="buildingname"
                                value={values.buildingname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.buildingname && errors.buildingname
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.roomNumber}
                                </span>
                              }
                              validateStatus={
                                touched.roomnumber && errors.roomnumber && "error"
                              }
                              help={touched.roomnumber && errors.roomnumber}
                              >
                              <Input
                                data-cy="roomnumber"
                                type="text"
                                name="roomnumber"
                                value={values.roomnumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.roomnumber && errors.roomnumber
                                  ? "invalid"
                                    : undefined
                                  }
                                  />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.floor}
                                </span>
                              }
                              validateStatus={
                                touched.floor && errors.floor && "error"
                              }
                              help={touched.floor && errors.floor}
                              >
                              <Input
                                data-cy="floor"
                                type="text"
                                name="floor"
                                value={values.floor}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.floor && errors.floor
                                    ? "invalid"
                                    : undefined
                                  }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.village}
                                </span>
                              }
                              validateStatus={
                                touched.village && errors.village && "error"
                              }
                              help={touched.village && errors.village}
                              >
                              <Input
                                data-cy="village"
                                type="text"
                                name="village"
                                value={values.village}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.village && errors.village
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.houseNo}
                                </span>
                              }
                              required={true}
                              validateStatus={
                                touched.houseno && errors.houseno && "error"
                              }
                              help={touched.houseno && errors.houseno}
                              >
                              <Input
                                data-cy="houseno"
                                type="text"
                                name="houseno"
                                value={values.houseno}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.houseno && errors.houseno
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.villageNo}
                                </span>
                              }
                              validateStatus={
                                touched.villageno && errors.villageno && "error"
                              }
                              help={touched.villageno && errors.villageno}
                              >
                              <Input
                                data-cy="villageno"
                                type="text"
                                name="villageno"
                                value={values.villageno}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.villageno && errors.villageno
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.soi}
                                </span>
                              }
                              validateStatus={touched.soi && errors.soi && "error"}
                              help={touched.soi && errors.soi}
                              >
                              <Input
                                data-cy="soi"
                                type="text"
                                name="soi"
                                value={values.soi}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.soi && errors.soi ? "invalid" : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.province}
                                </span>
                              }
                              required={true}
                              validateStatus={
                                touched.province && errors.province && "error"
                              }
                              help={touched.province && errors.province}
                              >
                              <Select
                                data-cy="province"
                                onChange={(value, option) =>
                                  this.onChangeProvince(
                                    value,
                                    option,
                                    setFieldValue
                                    )
                                }
                                value={values.province}
                                //style={{ width: "83%" }}
                                >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {provinceLists !== undefined
                                  ? provinceLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))
                                  : ""}
                              </Select>
                            </FormItem>
                          </Col>
                          </Row>
  
                          <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.district}
                                </span>
                              }
                              required={true}
                              validateStatus={
                                touched.district && errors.district && "error"
                              }
                              help={touched.district && errors.district}
                              >
                              <Select
                                data-cy="district"
                                onChange={(value, option) =>
                                  this.onChangeDistrict(
                                    value,
                                    option,
                                    setFieldValue
                                    )
                                  }
                                value={values.district}
                                //style={{ width: "83%" }}
                                >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {amphurLists !== undefined
                                  ? amphurLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))
                                  : ""}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.subdistrict}
                                </span>
                              }
                              required={true}
                              validateStatus={
                                touched.subdistrict && errors.subdistrict && "error"
                              }
                              help={touched.subdistrict && errors.subdistrict}
                            >
                              <Select
                                data-cy="subdistrict"
                                onChange={(value, option) => {
                                  this.onChangeSubDistrict(
                                    value,
                                    option,
                                    setFieldValue
                                    );
                                }}
                                value={values.subdistrict}
                                //style={{ width: "83%" }}
                              >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {subamphurLists !== undefined
                                  ? subamphurLists.map((v) => (
                                    <Option
                                        key={v.id}
                                        value={v.id}
                                        code={v.postalcode}
                                        >
                                        {v.name}
                                      </Option>
                                    ))
                                    : ""}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.addr.postalCode}
                                </span>
                              }
                              validateStatus={
                                touched.postalcode && errors.postalcode && "error"
                              }
                              help={touched.postalcode && errors.postalcode}
                              >
                              <Input
                                data-cy="postalcode"
                                type="text"
                                name="postalcode"
                                value={values.postalcode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.postalcode && errors.postalcode
                                  ? "invalid"
                                    : undefined
                                  }
                                  />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.p.phone}
                                </span>
                              }
                              validateStatus={
                                touched.phone && errors.phone && "error"
                              }
                              help={touched.phone && errors.phone}
                              >
                              <Input
                                data-cy="phone"
                                type="text"
                                name="phone"
                                value={values.phone}
                                disabled={true}
                                onChange={handleChange}
                                // onChange={(e) => {
                                  //   setFieldValue("phone", e.target.value);
                                // }}
                                onBlur={handleBlur}
                                invalid={
                                  touched.phone && errors.phone
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  {i18n.e.email}
                                </span>
                              }
                              validateStatus={
                                touched.email && errors.email && "error"
                              }
                              help={touched.email && errors.email}
                              >
                              <Input
                                data-cy="email"
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                invalid={
                                  touched.email && errors.email
                                  ? "invalid"
                                  : undefined
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider dashed orientation="left">
                            <h2 style={{
                                color:"#2f67dc",
                                fontWeight:"bold",
                                fontSize:"16px"
                              }}>
                                {i18n.i.identification_documents}
                              </h2>
                        </Divider>

                        <Row>
                          <Col xs={24} sm={5} md={7} lg={5} xl={5} xxl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <Fragment>
                                  <span style={styleFormLabel}>
                                    <LangContext.Consumer>
                                    {(i18n) => i18n.c.copy_id_card}
                                    </LangContext.Consumer>
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    title={i18n.business.info_one_file}
                                    >
                                    <Icon
                                      type="info-circle"
                                      theme="outlined"
                                      style={{
                                        paddingTop: "8px",
                                        paddingLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3B68D9",
                                      }}
                                      />
                                  </Tooltip>
                                </Fragment>
                              }
                              validateStatus={
                                touched.fileidcard && errors.fileidcard && "error"
                              }
                              help={touched.fileidcard && errors.fileidcard}
                            >
                              <Upload
                                className="test-upload-fileidcard"
                                name="fileidcard"
                                // listType="picture-card"
                                fileList={values.fileidcard}
                                // customRequest={dummyRequest}
                                beforeUpload={(file) => {
                                  const isCheckTypeFile =
                                  file.type === "image/jpeg" ||
                                    file.type === "image/png" ||
                                    file.type === "application/pdf";
                                    if (!isCheckTypeFile) {
                                      message.error(
                                        i18n.worksheet.upload_file_support
                                    );
                                  }
                                  // const isLt2M = file.size / 1024 / 1024 < 1;
                                  const checkSizeFile = file.size / 1024 <= 5120;
                                  if (!checkSizeFile) {
                                    message.error(
                                      i18n.worksheet.upload_size_support
                                    );
                                  }
                                  
                                  if (isCheckTypeFile && checkSizeFile) {
                                    setFieldValue("fileidcard", [file]);
                                  }
                                  
                                  return false;
                                }}
                                onChange={({ file, fileList }) => {
                                  if (fileList.length === 0) {
                                    setFieldValue("fileidcard", []);
                                  }
                                }}
                              >
                                <Button
                                  data-cy="btn-cancel"
                                  type="primary" 
                                  className="btn-upload-fileidcard"
                                >
                                  <Icon type="upload" /> {i18n.s.select_file}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5} xxl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <Fragment>
                                  <span style={styleFormLabel}>
                                    <LangContext.Consumer>
                                     {(i18n) => i18n.c.copy_driver_license}
                                    </LangContext.Consumer>
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    title={i18n.business.info_one_file}
                                    >
                                    <Icon
                                      type="info-circle"
                                      theme="outlined"
                                      style={{
                                        paddingTop: "8px",
                                        paddingLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3B68D9",
                                      }}
                                      />
                                  </Tooltip>
                                </Fragment>
                              }
                              validateStatus={
                                touched.filedriverlicense &&
                                errors.filedriverlicense &&
                                "error"
                              }
                              help={
                                touched.filedriverlicense &&
                                errors.filedriverlicense
                              }
                              >
                              <Upload
                                className="test-upload-filedriverlicense"
                                name="filedriverlicense"
                                // listType="picture-card"
                                fileList={values.filedriverlicense}
                                // customRequest={dummyRequest}
                                beforeUpload={(file) => {
                                  const isCheckTypeFile =
                                  file.type === "image/jpeg" ||
                                  file.type === "image/png" ||
                                  file.type === "application/pdf";
                                  if (!isCheckTypeFile) {
                                    message.error(
                                      i18n.worksheet.upload_file_support
                                      );
                                    }
                                  // const isLt2M = file.size / 1024 / 1024 < 1;
                                  const checkSizeFile = file.size / 1024 <= 5120;
                                  if (!checkSizeFile) {
                                    message.error(
                                      i18n.worksheet.upload_size_support
                                      );
                                  }
                                  
                                  if (isCheckTypeFile && checkSizeFile) {
                                    setFieldValue("filedriverlicense", [file]);
                                  }

                                  return false;
                                }}
                                onChange={({ file, fileList }) => {
                                  if (fileList.length === 0) {
                                    setFieldValue("filedriverlicense", []);
                                  }
                                }}
                              >
                                <Button
                                  data-cy="btn-cancel" 
                                  type="primary"
                                   className="btn-upload-filedriverlicense"
                                >
                                  <Icon type="upload" /> {i18n.s.select_file}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row style={{padding:"20px 0px"}}>
                          <Col xs={3} sm={4} md={4} lg={5} xl={6} xxl={8}/>
                          <Col  xs={21} sm={8} md={8} lg={5} xl={3} xxl={3}>
                            <Button
                              data-cy="btn-cancel"
                              type="default"
                              style={{
                                width:"120px",
                                margin:'10px 0px 0px 0px'  
                              }}
                              onClick={() => (window.location.href = "/")}
                              >
                              {i18n.c.cancel}
                            </Button>

                           </Col>
                          <Col xs={3} sm={1} md={1} lg={2} xl={3} xxl={2}/>
                          <Col  xs={21} sm={8} md={8} lg={5} xl={4} xxl={4}>
                            <Button
                              data-cy="btn-submit"
                              htmlType="submit"
                              type="primary"
                              style={{
                                width:"120px",
                                margin:'10px 0px 0px 0px'
                              }}
                              >
                              {i18n.c.confirm}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  )}
                  />
              )}
            </LangContext.Consumer>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

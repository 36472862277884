import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID
} from "../../../../../constants/local_storage";
import * as actions from "./actions";

function* loadWorksheetCompany(action) {

  const { page, pageSize, orderBy, orderType, filters } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loadworksheetcompany`,
      {
        page,
        pageSize,
        company_id: COMPANY_ID,
        orderBy, 
        orderType, 
        filters
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadWorksheetCompany.success(res.data));
  } catch (err) {
    yield put(actions.loadWorksheetCompany.failure());
  }
}

export default function* watchTransporWorkInprogressState() {
  yield all([
    takeEvery(actions.LOAD_TRANSPORT_WORKSHEET_COMPANY.REQUEST, loadWorksheetCompany),
  ]);
}

import {
  LOAD_EXPENSE,
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  CLEAR_EXPENSE,
  GET_EXPENSE,
  CLEAR_RESPONSE,
} from "./actions";

const initialState = {
  expenseLists: [],
  expenseLoading: false,
  expenseTotal: 0,
  dataResponse: { status: "", msg: "", id: "", action_type: "" },
  check_expense: true,
  dataExpense: {
    id: "",
    vehicle_type_id: "",
    origin_location_id: "",
    destination_location_id: "",
    distance: "",
    price_unit_id: "",
    price: "",
    price_trip: "",
    price_trip_unit_id: "",
    origin_location_name: "",
    destination_location_name: "",
    vehicle_type: "",
    price_unit: "",
    price_trip_unit: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EXPENSE.REQUEST:
      const { loading } = action.payload;
      return {
        ...state,
        expenseLoading: loading,
      };
    case LOAD_EXPENSE.SUCCESS:
      return {
        ...state,
        expenseLists: action.payload.data.data,
        expenseLoading: false,
        expenseTotal: action.payload.data.total,
      };
    case CREATE_EXPENSE.REQUEST:
      const { loading: loading2 } = action.payload;
      return {
        ...state,
        expenseLoading: loading2,
      };
    case CREATE_EXPENSE.SUCCESS:
      return {
        ...state,
        dataResponse: {
          status: action.payload.data.status,
          msg: action.payload.data.msg,
          action_type: action.payload.data.action_type,
        },
        expenseLoading: false,
      };
    case UPDATE_EXPENSE.REQUEST:
      const { loading: loading3 } = action.payload;
      return {
        ...state,
        expenseLoading: loading3,
      };
    case UPDATE_EXPENSE.SUCCESS:
      return {
        ...state,
        dataResponse: {
          status: action.payload.data.status,
          msg: action.payload.data.msg,
          action_type: action.payload.data.action_type,
        },
        expenseLoading: false,
      };

    case GET_EXPENSE.REQUEST:
      return {
        ...state,
        check_expense: true,
      };
    case GET_EXPENSE.SUCCESS:
      return {
        ...state,
        dataExpense: action.payload.data.data,
        expenseLoading: false,
        check_expense: false,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        dataResponse: { status: "", msg: "", id: "", action_type: "" },
      };
    case CLEAR_EXPENSE:
      return {
        ...state,
        dataExpense: {
          id: "",
        },
      };
    default:
      return state;
  }
};

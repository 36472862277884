import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router-dom";
import { Divider, Row, Col, Table, Pagination, Spin, Icon, Tooltip } from "antd";

import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { translate } from "../../../../../../lib/helper";
import { i18n } from "modules/shared/context/langContext";

import "../../../../../../styled/transport-dashboard.css";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "worksheet",
    keyLang: "document_number",
    dataKey: "document_number",
    sorter: true,
    render: "normal",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "customer",
    dataKey: "customer",
    sorter: true,
    render: "normal",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: true,
    render: "address",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "address",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_porter",
    dataKey: "staff_lift",
    sorter: true,
    render: "staff_lift",
    width: "7%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_payment_status",
    dataKey: "payment_type",
    sorter: true,
    render: "payment_type",
    width: "8%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_vehicle_type",
    dataKey: "vehicle_type",
    sorter: true,
    render: "normal",
    width: "10%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "q",
    keyLang: "quotation",
    dataKey: "quotation",
    sorter: false,
    render: "quotation",
    width: "5%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "c",
    keyLang: "comment",
    dataKey: "worksheet_id",
    sorter: false,
    render: "action",
    width: "5%",
    cls: "vertical-top algin-center",
  },
];

const renderStatus = (val) => <div className="job_open_status_txt">{val}</div>;

const renderAddress = (val, key) => {
  if(typeof val === "undefined") return ""
  let items = val.split(",")
  let line = 1;
  let withLine = key === "origin" ? false : true

  if(val === "") {
    return <p></p>
  }

  return items.map((item, i) => {
    let txt = item.split(" (")
    if(withLine)
  return <p key={i}>{line+i}. {txt[0]} <br/> ({txt[1]}</p>
    else 
      return <p key={i}>{txt[0]} <br/> ({txt[1]}</p>
  })
}

export class Layout extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
    orderBy: "w.created_at",
    orderType: "desc",
    filters: [],
  };

  genTableHeader = () => {
    const columns = [];
    tableHeader.map((v) => {
      columns.push({
        title: translate(`${v.keyLang}`, `${v.idxLang}`) ,
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render: (text, record, index) => {
          return {
            props: {
              style: columnStyle,
            },
            children: this.renderComponent(v.render, record, v.dataKey),
          };
        },
      });
    });
  
    return columns;
  };
  
  renderComponent = (type, val, key) => {
    switch (type) {
      case "status":
        return renderStatus(val[key]);
        case "address":
          return renderAddress(val[key], key)
      case "quotation":
        return this.renderQuotation(val);
      case "action":
        return this.renderActionBtn(val);
      case "staff_lift":
        let keyInx = val[key] === "มี" ? "have_porter" : "have_no_porter"
        return <div>{translate(keyInx, "h")}</div>;
      case "payment_type":
        return <div>{translate(val[key], "worksheet")}</div>;
      default:
        return this.renderNormalTxt(val[key], key);
    }
  };

  renderQuotation = (val) => {
    if (parseInt(val.quotation) > 0) {
      return (
        <Fragment>
          <Link data-cy={`btn-view-qt-${val.worksheet_id}`} to={`/productowner/allwork/quotations/${val.worksheet_id}`}>
            <Icon type="file-pdf" style={{ fontSize: "22px" }} />
            <br />({val.quotation})
          </Link>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Icon type="file-pdf" style={{ fontSize: "22px" }} />
          <br />({val.quotation})
        </Fragment>
      );
    }
  };
  
  renderActionBtn = (val) => (
    <div>
      <Link data-cy={`btn-view-${val.worksheet_id}`} to={`/productowner/allwork/view/${val.worksheet_id}`}>
        <Icon type="file-text" style={{ fontSize: "18px" }} />
      </Link>
    </div>
  );

  renderNormalTxt = (val, key) =>  {
    let txt = ""
    switch(key) {
      case "vehicle_type" : txt = i18n[this.props.langBtn]["vehicle_type"][val]; break;
      case "product_type" : txt = i18n[this.props.langBtn]["m_product_type"][val]; break;
      default: txt = val; break;
    }
    
    if(typeof txt !== "undefined") {
      if (txt.length > 55)
      return  <Tooltip title={txt}><span>{txt.substr(0, 55) + "..."}</span></Tooltip>;
    else 
      return <span>{txt}</span>;
    } else {
      return <span>{val}</span>;
    }
  }
  

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = "w.created_at";
      orderType = "asc";
    } else {
      orderBy = this.getRealKey(sorter.columnKey);
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.setState({
      orderBy,
      orderType,
    });

    this.props.loadWorksheetCompany(
      this.state.currentPage,
      this.state.pageSize,
      orderBy,
      orderType,
      this.state.filters
    );
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadWorksheetCompany(
      page,
      pageSize,
      this.state.orderBy,
      this.state.orderType,
      this.state.filters
    );
  };

  getRealKey = (key) => {
    switch (key) {
      case "product_type":
        return "m_product_type.name";
      case "origin":
        return "l.name";
      case "vehicle_type":
        return "vt.name";
      case "payment_type":
        return "w.service_payment_type";
      default:
        return key;
    }
  };

  componentDidMount() {
    const { page, pageSize, orderBy, orderType, filters } = this.state;
    this.props.loadWorksheetCompany(
      page,
      pageSize,
      orderBy,
      orderType,
      filters
    );
  }
  render() {
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="allwork_dashboard">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                  {translate("dashboard_your_work", "d")}
              </Divider>
            </Col>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ productOwnerAllwork, ui: {langBtn} }) => ({
  data: productOwnerAllwork.lists,
  totalRows: productOwnerAllwork.totalRows,
  loading: productOwnerAllwork.loading,
  langBtn
});

const mapDispatchToProps = {
  loadWorksheetCompany: actions.loadWorksheetCompany.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React from "react";
import { Button, Select, Form, Input, Drawer, DatePicker } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
const { RangePicker } = DatePicker;
const { Option } = Select;
const IndexFilterDrawer = (props) => (
  // console.log(props.plant.length),
  <Drawer
    title={
      <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
    }
    // title="Filter"
    width={520}
    placement="right"
    onClose={props.onClose}
    maskClosable={true}
    visible={props.visible}
    style={{
      height: "calc(100% - 55px)",
      overflow: "auto",
      paddingBottom: 53,
    }}
  >
    <Form>
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjPlateno} />
      </Form.Item>

      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleType}
          </LangContext.Consumer>
        }
      >
        <Input onChange={props.handleFilterObjVehicleType} />
      </Form.Item>
      
      <Form.Item
        label={
          <LangContext.Consumer>
            {(i18n) => i18n.v.verify_status}
          </LangContext.Consumer>
        }
      >
        <Select data-cy="verify" defaultValue="all" onChange={props.handleFilterObjVehicleVerified}>
          <Option value="all">
            <LangContext.Consumer>
              {(i18n) => i18n.a.all}
            </LangContext.Consumer>
          </Option>
          <Option value="false">
            <LangContext.Consumer>
              {(i18n) => i18n.v.verify_no}
            </LangContext.Consumer>
          </Option>
          <Option value="true">
            <LangContext.Consumer>
              {(i18n) => i18n.v.verify_yes}
            </LangContext.Consumer>
          </Option>
        </Select>
      </Form.Item>

      <Button
      data-cy="btn-filter-close"
        style={{
          marginRight: 8,
        }}
        onClick={props.onClose}
      >
        <LangContext.Consumer>{(i18n) => i18n.c.cancel}</LangContext.Consumer>
      </Button>

      <Button type="primary" data-cy="btn-filter-submit" onClick={props.onFilterButtonClick}>
        <LangContext.Consumer>{(i18n) => i18n.a.apply}</LangContext.Consumer>
      </Button>
    </Form>
  </Drawer>
);
export default IndexFilterDrawer;

import React, { Component, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  Divider,
  Spin,
  Upload,
  Icon,
  message,
  DatePicker,
  Modal,
  Tooltip,
  Steps,
} from "antd";
import { ACCESSTOKEN, COMPANY_ID } from "../../../constants/local_storage";
import LangContext, { i18n } from "modules/shared/context/langContext";
import axios from "axios";
import moment from "moment";
const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;

export default class Vehicledriverform extends Component {
  state = {
    fileFrontVehicle: [],
    fileVehicle: [],
    fileDriverLicense: [],
    loading: false,
  };

  componentDidMount() {
    const { loadProvince } = this.props;
    loadProvince();
  }

  onChangeProvince = (value, option, setFieldValue) => {
    setFieldValue("province", value, false);
  };

  onSubmit = (values) => {
    const { id } = this.props;
    const {
      vehicle_type,
      plate_no,
      plate_no_province,
      brand,
      model,
      year,
      color,
      expire_date,
      expire_act_date,
      start_province,
      end_province,
      title_name,
      contact_firstname,
      contact_lastname,
      phone,
      driver_code,
      id_card,
      id_card_expire_date,
      id_driver_license,
      driver_license_expire_date,
      filefrontvehicle,
      image_vehicle,
      file_copy_driver_license,
    } = values;
    let self = this;

    this.setState({ loading: true });

    const formData = new FormData();
    // let filefrontvehicle_new = null;
    // let image_vehicle_new = null;
    // let file_copy_driver_license_new = null;

    filefrontvehicle.forEach((File) => {
      formData.append("filefrontvehicle", File);
    });

    image_vehicle.forEach((File) => {
      formData.append("image_vehicle", File);
    });

    file_copy_driver_license.forEach((File) => {
      formData.append("file_copy_driver_license", File);
    });

    formData.append("id", id);
    formData.append("vehicle_type", vehicle_type);
    formData.append("plate_no", plate_no);
    formData.append("plate_no_province", plate_no_province);
    formData.append("brand", brand);
    formData.append("model", model);
    formData.append("year", year);
    formData.append("color", color);
    formData.append("expire_date", expire_date);
    formData.append("expire_act_date", expire_act_date);
    formData.append("start_province", start_province);
    formData.append("end_province", end_province);
    formData.append("title_name", title_name);
    formData.append("contact_firstname", contact_firstname);
    formData.append("contact_lastname", contact_lastname);
    formData.append("phone", phone);
    formData.append("driver_code", driver_code);
    formData.append("id_card", id_card);
    formData.append("id_card_expire_date", id_card_expire_date);
    formData.append("id_driver_license", id_driver_license);
    formData.append("driver_license_expire_date", driver_license_expire_date);

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicledriverregister`,
        formData
      )
      .then(function(response) {
        // next();
        if (response.status === "fail") {
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okText: (
              <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        } else {
          Modal.info({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.signup.signup_success}
              </LangContext.Consumer>
            ),
            okText: (
              <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
            ),
            onOk() {
              window.location = "/";
            },
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
        }
      })
      .catch(function(error) {
        console.log("error:", error);
        Modal.error({
          content: (
            <LangContext.Consumer>
              {(i18n) => i18n.f.fail_save}
            </LangContext.Consumer>
          ),
          okText: (
            <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
          ),
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
        self.setState({ loading: false });
      });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment()
          .add(-1, "days")
          .endOf("day")
    );
  };

  render() {
    const { provinceLists, vehicle_type, step } = this.props;
    const { loading } = this.state;
    const {Step} =Steps

    const styleForm = {
      backgroundColor:"#fafbfb", 
      minHeight: "calc( 100vh - 420px)",
      borderRadius: "15px",
      padding:"5% 10% ",
      boxShadow: "10px 16px 23px -12px rgba(0, 0, 0, 0.13)",
    }
    const styleFormLabel = {
      fontSize:"13px",
      color:"#2f67dc",
    }
    const getStep = (step) => {
      let stepJSX = []
        for(let loopIndex = 1 ; loopIndex <= step ; loopIndex++)
          stepJSX = [...stepJSX , loopIndex]
      return(
        <Steps current={3} >   
          {stepJSX.map(() =>(
            <Step title="" description="" />
          ))}  
        </Steps>
      )
    }
    return (
      <div>
      <Row>
        <Col xs={4} sm={4} md={4} lg={6} xl={7} xxl={7}/>
        <Col xs={16} sm={16} md={16} lg={14} xl={10} xxl={10}>
          <div style={styleForm}>
          <LangContext.Consumer>
            {(i18n) => (
              <div>
                <Formik
                  validateOnChange={false}
                  validateOnBlur={true}
                  initialValues={{
                    vehicle_type: "",
                    plate_no: "",
                    plate_no_province: "",
                    brand: "",
                    model: "",
                    year: "",
                    color: "",
                    expire_date: "",
                    expire_act_date: "",
                    start_province: "",
                    end_province: "",
                    title_name: "mr",
                    contact_firstname: "",
                    contact_lastname: "",
                    phone: "",
                    driver_code: "",
                    id_card: "",
                    id_card_expire_date: "",
                    id_driver_license: "",
                    driver_license_expire_date: "",
                    filefrontvehicle: [],
                    image_vehicle: [],
                    file_copy_driver_license: [],
                  }}
                  validate={(values) => {
                    let errors = {};
                    if (/^\d+$/.test(values.phone) === false) {
                      errors.phone = i18n.p.phone_invalid;
                    }
                    
                    if (
                      /^\d+$/.test(values.id_card) === false ||
                      values.id_card.length !== 13
                      ) {
                        errors.id_card = i18n.veh.id_card_invalid;
                      }
                      
                      if (/^\d+$/.test(values.year) === false) {
                        errors.year = i18n.veh.year_invalid;
                      }
                      if (values.filefrontvehicle.length == 0) {
                        errors.filefrontvehicle = i18n.veh.filefrontvehicle_require;
                      }
                      
                      if (values.image_vehicle.length == 0) {
                        errors.image_vehicle = i18n.veh.image_vehicle_require;
                      }
                      
                      if (values.file_copy_driver_license.length == 0) {
                        errors.file_copy_driver_license =
                        i18n.veh.file_copy_driver_license_require;
                      }
                      
                      if (
                        /[^A-Za-zก-๙ะ-ไ ]+$/.test(values.contact_firstname) === true
                        ) {
                          errors.contact_firstname = i18n.addr.firstname_invalid;
                        }
                        
                        if (
                          /[^A-Za-zก-๙ะ-ไ ]+$/.test(values.contact_lastname) === true
                          ) {
                            errors.contact_lastname = i18n.addr.lastname_invalid;
                          }
                          
                    return errors;
                  }}
                  validationSchema={yup.object().shape({
                    vehicle_type: yup
                    .string()
                    .required(i18n.veh.vehicle_type_require),
                    plate_no: yup.string().required(i18n.veh.plate_no_require),
                    plate_no_province: yup
                    .string()
                    .required(i18n.veh.plate_no_province_require),
                    brand: yup.string().required(i18n.veh.brand_require),
                    model: yup.string().required(i18n.veh.model_require),
                    year: yup.string().required(i18n.veh.year_require),
                    color: yup.string().required(i18n.veh.color_require),
                    expire_date: yup
                    .string()
                    .required(i18n.veh.expire_date_require),
                    expire_act_date: yup
                    .string()
                    .required(i18n.veh.expire_act_date_require),
                    start_province: yup
                    .string()
                    .required(i18n.veh.start_province_require),
                    end_province: yup
                    .string()
                    .required(i18n.veh.end_province_require),
                    contact_firstname: yup
                    .string()
                    .required(i18n.veh.contact_firstname_require),
                    contact_lastname: yup
                    .string()
                    .required(i18n.veh.contact_lastname_require),
                    // phone: yup.string().required(i18n.veh.phone_require),
                    phone: yup
                    .number()
                    .required(i18n.veh.phone_require)
                    .test(
                      "validateduplicatephone",
                      i18n.v.validate_phone_dup,
                      function(value) {
                        return new Promise((resolve, reject) => {
                          fetch(
                            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser/duplicatecode`,
                            {
                              method: "POST",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${ACCESSTOKEN}`,
                              },
                              body: JSON.stringify({
                                phone: value,
                                company_id: COMPANY_ID,
                              }),
                            }
                            )
                            .then((response) => response.json())
                            .then((res) => {
                              if (res.status === "false") {
                                resolve(false);
                              }
                              resolve(true);
                            });
                          });
                        }
                        ),
                        driver_code: yup
                        .string()
                        .required(i18n.veh.driver_code_require)
                        .test(
                          "validateduplicatecode",
                          i18n.v.validate_code_dup,
                          function(value) {
                            return new Promise((resolve, reject) => {
                              fetch(
                                `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser/duplicatecode`,
                                {
                                  method: "POST",
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${ACCESSTOKEN}`,
                                  },
                                  body: JSON.stringify({
                                    code: value,
                                    company_id: COMPANY_ID,
                                  }),
                                }
                                )
                                .then((response) => response.json())
                                .then((res) => {
                                  if (res.status === "false") {
                                    resolve(false);
                                  }
                                  resolve(true);
                                });
                              });
                            }
                            ),
                            id_card: yup
                            .string()
                            .required(i18n.veh.id_card_require)
                            .length(13, i18n.v.validate_id_card_length)
                            .test(
                              "validateduplicateidcard",
                              i18n.v.validate_id_card_dup,
                              function(value) {
                                return new Promise((resolve, reject) => {
                                  fetch(
                                    `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser/duplicatecode`,
                                    {
                                      method: "POST",
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${ACCESSTOKEN}`,
                                      },
                                      body: JSON.stringify({
                                        id_card: value,
                                        company_id: COMPANY_ID,
                                      }),
                                    }
                                    )
                                    .then((response) => response.json())
                                    .then((res) => {
                                      if (res.status === "false") {
                                        resolve(false);
                                      }
                                      resolve(true);
                                    });
                                  });
                                }
                                )
                                .test("validateidcard", i18n.v.validate_id_card, function(
                                  value
                                  ) {
                                    return new Promise((resolve, reject) => {
                                      fetch(
                                        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser/verifyidcard`,
                                        {
                                          method: "POST",
                                          headers: {
                                            Accept: "application/json",
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${ACCESSTOKEN}`,
                                          },
                                          body: JSON.stringify({
                                            id_card: value,
                                          }),
                                        }
                                        )
                                        .then((response) => response.json())
                                        .then((res) => {
                                          if (res.status === "false") {
                                            resolve(false);
                                          } else {
                                          }
                                          resolve(true);
                                        });
                                      });
                                    }),
                                    id_card_expire_date: yup
                                    .string()
                                    .required(i18n.veh.id_card_expire_date_require),
                                    id_driver_license: yup
                                    .string()
                                    .required(i18n.veh.id_driver_license_require),
                                    driver_license_expire_date: yup
                                    .string()
                                    .required(i18n.veh.driver_license_expire_date_require),
                                  })}
                                  onSubmit={(values) => {
                                    //console.log("submit");
                                    this.onSubmit(values);
                                  }}
                                  render={({
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                  }) => (
                    <Spin spinning={loading}>
                      <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"center"}}>
                              <h2 style={{color:"#2f67dc",fontWeight:"bold"}}> สร้างบัญชี</h2>
                              <h2 style={{color:"#2f67dc"}}>กรอกรายละเอียด</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={10} sm={0} md={0} lg={0} xl={0}/>
                            <Col  xs={12} sm={24} md={24} lg={24} xl={24} style={{padding:"20px 0px"}}>
                              {getStep(step)}
                            </Col>
                          </Row>
                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.vehicle_type}
                                  </span>
                                }
                              required={true}
                              validateStatus={
                                touched.vehicle_type &&
                                errors.vehicle_type &&
                                "error"
                              }
                              help={touched.vehicle_type && errors.vehicle_type}
                              >
                              <Select
                                data-cy="vehicle_type"
                                onChange={(value) =>
                                  setFieldValue("vehicle_type", value)
                                }
                                value={values.vehicle_type}
                                >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {vehicle_type.map((v) => (
                                  <Option key={v.id} value={v.id}>
                                    {v.name}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                                colon={false}
                                label={
                                    <span style={styleFormLabel}>
                                      {i18n.veh.plate_no}
                                    </span>
                                  }
                              required={true}
                              validateStatus={
                                touched.plate_no && errors.plate_no && "error"
                              }
                              help={touched.plate_no && errors.plate_no}
                              >
                              <Input
                                data-cy="plate_no"
                                type="text"
                                name="plate_no"
                                value={values.plate_no}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.plate_no && errors.plate_no
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.plate_no_province}
                                  </span>
                                }
                              required={true}
                              validateStatus={
                                touched.plate_no_province &&
                                errors.plate_no_province &&
                                "error"
                              }
                              help={
                                touched.plate_no_province &&
                                errors.plate_no_province
                              }
                              >
                              <Select
                                data-cy="plate_no_province"
                                onChange={(value) =>
                                  setFieldValue("plate_no_province", value)
                                }
                                value={values.plate_no_province}
                                >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {provinceLists !== undefined
                                  ? provinceLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))
                                    : ""}
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.brand}
                                  </span>
                                }
                              validateStatus={
                                touched.brand && errors.brand && "error"
                              }
                              help={touched.brand && errors.brand}
                              >
                              <Input
                                data-cy="brand"
                                type="text"
                                name="brand"
                                value={values.brand}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.brand && errors.brand
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.model}
                                  </span>
                                }
                              validateStatus={
                                touched.model && errors.model && "error"
                              }
                              help={touched.model && errors.model}
                              >
                              <Input
                                data-cy="model"
                                type="text"
                                name="model"
                                value={values.model}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.model && errors.model
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.year}
                                  </span>
                                }
                              validateStatus={
                                touched.year && errors.year && "error"
                              }
                              help={touched.year && errors.year}
                              >
                              <Input
                                data-cy="year"
                                type="text"
                                name="year"
                                value={values.year}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.year && errors.year
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.color}
                                  </span>
                                }
                              validateStatus={
                                touched.color && errors.color && "error"
                              }
                              help={touched.color && errors.color}
                              >
                              <Input
                                data-cy="color"
                                type="text"
                                name="color"
                                value={values.color}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.color && errors.color
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7} xxl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.expire_date}
                                    </LangContext.Consumer>
                                  </span>
                              }
                              validateStatus={
                                touched.expire_date &&
                                errors.expire_date &&
                                "error"
                              }
                              help={touched.expire_date && errors.expire_date}
                              >
                              <DatePicker
                                data-cy="expire_date"
                                disabledDate={this.disabledDate}
                                onChange={(value) =>
                                  setFieldValue("expire_date", value)
                                }
                                style={{ width: "100%" }}
                                value={values.expire_date}
                                format="DD/MM/YYYY"
                                dateRender={(current) => {
                                  const style = {};
                                  if (current.date() === 1) {
                                    style.border = "1px solid #1890ff";
                                    style.borderRadius = "50%";
                                  }
                                  return (
                                    <div
                                    className="ant-calendar-date"
                                    style={style}
                                    >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7} xxl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  <LangContext.Consumer>
                                  {(i18n) => i18n.veh.expire_act_date}
                                  </LangContext.Consumer>
                                </span>
                              }
                              validateStatus={
                                touched.expire_act_date &&
                                errors.expire_act_date &&
                                "error"
                              }
                              help={
                                touched.expire_act_date && errors.expire_act_date
                              }
                              >
                              <DatePicker
                                data-cy="expire_act_date"
                                disabledDate={this.disabledDate}
                                onChange={(value) =>
                                  setFieldValue("expire_act_date", value)
                                }
                                style={{ width: "100%" }}
                                value={values.expire_act_date}
                                format="DD/MM/YYYY"
                                dateRender={(current) => {
                                  const style = {};
                                  if (current.date() === 1) {
                                    style.border = "1px solid #1890ff";
                                    style.borderRadius = "50%";
                                  }
                                  return (
                                    <div
                                    className="ant-calendar-date"
                                    style={style}
                                    >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7} xxl={8}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <Fragment>
                                  <span style={styleFormLabel}>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.filefrontvehicle}
                                    </LangContext.Consumer>
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    title={i18n.business.info_one_file}
                                    >
                                    <Icon
                                      type="info-circle"
                                      theme="outlined"
                                      style={{
                                        paddingTop: "8px",
                                        paddingLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3B68D9",
                                      }}
                                      />
                                  </Tooltip>
                                </Fragment>
                              }
                              validateStatus={
                                touched.filefrontvehicle &&
                                errors.filefrontvehicle &&
                                "error"
                              }
                              help={
                                touched.filefrontvehicle &&
                                errors.filefrontvehicle
                              }
                              >
                              <Upload
                                className="test-upload-filefrontvehicle"
                                id="filefrontvehicle"
                                name="filefrontvehicle"
                                // listType="picture-card"
                                fileList={values.filefrontvehicle}
                                // customRequest={dummyRequest}
                                beforeUpload={(file) => {
                                  const isCheckTypeFile =
                                  file.type === "image/jpeg" ||
                                  file.type === "image/png" ||
                                    file.type === "application/pdf";
                                    if (!isCheckTypeFile) {
                                      message.error(
                                        i18n.worksheet.upload_file_support
                                        );
                                      }
                                      // const isLt2M = file.size / 1024 / 1024 < 1;
                                      const checkSizeFile = file.size / 1024 <= 5120;
                                      if (!checkSizeFile) {
                                        message.error(
                                          i18n.worksheet.upload_size_support
                                          );
                                        }
                                        
                                        if (isCheckTypeFile && checkSizeFile) {
                                          setFieldValue("filefrontvehicle", [file]);
                                        }
                                        
                                        return false;
                                      }}
                                      onChange={({ file, fileList }) => {
                                        if (fileList.length === 0) {
                                          setFieldValue("filefrontvehicle", []);
                                        }
                                      }}
                                      >
                                <Button
                                  data-cy="btn-cancel" data-cy="btn-upload-filefrontvehicle" type="primary">
                                  <Icon type="upload" /> {i18n.s.select_file}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7} xxl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <Fragment>
                                  <span style={styleFormLabel}>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.image_vehicle}
                                    </LangContext.Consumer>
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    title={i18n.business.info_one_image}
                                    >
                                    <Icon
                                      type="info-circle"
                                      theme="outlined"
                                      style={{
                                        paddingTop: "8px",
                                        paddingLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3B68D9",
                                      }}
                                      />
                                  </Tooltip>
                                </Fragment>
                              }
                              validateStatus={
                                touched.image_vehicle &&
                                errors.image_vehicle &&
                                "error"
                              }
                              help={touched.image_vehicle && errors.image_vehicle}
                              >
                              <Upload
                                className="test-upload-image-vehicle"
                                name="image_vehicle"
                                // listType="picture-card"
                                fileList={values.image_vehicle}
                                // customRequest={dummyRequest}
                                beforeUpload={(file) => {
                                  const isCheckTypeFile =
                                  file.type === "image/jpeg" ||
                                  file.type === "image/png";
                                  if (!isCheckTypeFile) {
                                    message.error(
                                      i18n.worksheet.upload_image_support
                                      );
                                    }
                                    // const isLt2M = file.size / 1024 / 1024 < 1;
                                    const checkSizeFile = file.size / 1024 <= 5120;
                                    if (!checkSizeFile) {
                                      message.error(
                                        i18n.worksheet.upload_size_support
                                        );
                                      }
                                      
                                      if (isCheckTypeFile && checkSizeFile) {
                                        setFieldValue("image_vehicle", [file]);
                                      }
                                      
                                      return false;
                                    }}
                                    onChange={({ file, fileList }) => {
                                      if (fileList.length === 0) {
                                        setFieldValue("image_vehicle", []);
                                      }
                                    }}
                                    >
                                <Button
                                  data-cy="btn-cancel" data-cy="btn-upload-image-vehicle" type="primary">
                                  <Icon type="upload" /> {i18n.s.select_file}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider dashed orientation="left">
                               <h2 style={{
                                color:"#2f67dc",
                                fontWeight:"bold",
                                fontSize:"16px"
                              }}>
                                {i18n.veh.service_area}
                              </h2>
                        </Divider>
                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.start_province}
                                  </span>
                                }
                              required={true}
                              validateStatus={
                                touched.start_province &&
                                errors.start_province &&
                                "error"
                              }
                              help={
                                touched.start_province && errors.start_province
                              }
                              >
                              <Select
                                data-cy="start_province"
                                onChange={(value) =>
                                  setFieldValue("start_province", value)
                                }
                                value={values.start_province}
                                //style={{ width: "83%" }}
                                >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {provinceLists !== undefined
                                  ? provinceLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))
                                    : ""}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.end_province}
                                  </span>
                                }
                                required={true}
                                validateStatus={
                                  touched.end_province &&
                                  errors.end_province &&
                                  "error"
                                }
                              help={touched.end_province && errors.end_province}
                              >
                              <Select
                                data-cy="end_province"
                                onChange={(value) =>
                                  setFieldValue("end_province", value)
                                }
                                value={values.end_province}
                                //style={{ width: "83%" }}
                                >
                                <Option value="">{i18n.p.pleaseSelect}</Option>
                                {provinceLists !== undefined
                                  ? provinceLists.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))
                                    : ""}
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>

                        <Divider dashed orientation="left">
                               <h2 style={{
                                color:"#2f67dc",
                                fontWeight:"bold",
                                fontSize:"16px"
                              }}>
                                {i18n.veh.driver_info}
                              </h2>
                        </Divider>
                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                    {i18n.veh.contact_firstname}
                                  </span>
                                }
                                validateStatus={
                                touched.contact_firstname &&
                                errors.contact_firstname &&
                                "error"
                              }
                              help={
                                touched.contact_firstname &&
                                errors.contact_firstname
                              }
                              >
                              <InputGroup compact>
                                <Select
                                  data-cy="title_name"
                                  style={{ width: "30%" }}
                                  onChange={(value) =>
                                    setFieldValue("title_name", value)
                                  }
                                  value={values.title_name}
                                  >
                                  <Option value="mr">{i18n.m.mr}</Option>
                                  <Option value="mrs">{i18n.m.mrs}</Option>
                                  <Option value="miss">{i18n.m.miss}</Option>
                                </Select>
                                <Input
                                  data-cy=""
                                  style={{ width: "70%" }}
                                  type="text"
                                  name="contact_firstname"
                                  value={values.contact_firstname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.contact_firstname &&
                                    errors.contact_firstname
                                    ? "invalid"
                                    : undefined
                                  }
                                  />
                              </InputGroup>
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                    {i18n.l.lastName}
                                  </span>
                                }
                                required={true}
                                validateStatus={
                                  touched.contact_lastname &&
                                  errors.contact_lastname &&
                                  "error"
                                }
                                help={
                                  touched.contact_lastname &&
                                  errors.contact_lastname
                                }
                                >
                              <Input
                                data-cy="contact_lastname"
                                type="text"
                                name="contact_lastname"
                                value={values.contact_lastname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.contact_lastname &&
                                  errors.contact_lastname
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>

                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                    {i18n.p.phone}
                                  </span>
                                }
                                validateStatus={
                                  touched.phone && errors.phone && "error"
                                }
                                help={touched.phone && errors.phone}
                                disabled={true}
                                >
                              <Input
                                data-cy="phone"
                                type="text"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.phone && errors.phone
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <FormItem
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                    {i18n.veh.driver_code}
                                  </span>
                                }
                                validateStatus={
                                  touched.driver_code &&
                                errors.driver_code &&
                                "error"
                              }
                              help={touched.driver_code && errors.driver_code}
                              >
                              <Input
                                data-cy="driver_code"
                                //style={{ width: "70%" }}
                                type="text"
                                name="driver_code"
                                value={values.driver_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.driver_code && errors.driver_code
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </FormItem>
                          </Col>
                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                    {i18n.veh.id_card}
                                  </span>
                                }
                                required={true}
                                validateStatus={
                                  touched.id_card && errors.id_card && "error"
                                }
                                help={touched.id_card && errors.id_card}
                                >
                              <Input
                                data-cy="id_card"
                                type="text"
                                name="id_card"
                                value={values.id_card}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.id_card && errors.id_card
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>

                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.veh.id_card_expire_date}
                                  </LangContext.Consumer>
                                </span>
                              }
                              validateStatus={
                                touched.id_card_expire_date &&
                                errors.id_card_expire_date &&
                                "error"
                              }
                              help={
                                touched.id_card_expire_date &&
                                errors.id_card_expire_date
                              }
                              >
                              <DatePicker
                                data-cy="id_card_expire_date"
                                disabledDate={this.disabledDate}
                                onChange={(value) =>
                                  setFieldValue("id_card_expire_date", value)
                                }
                                style={{ width: "100%" }}
                                value={values.id_card_expire_date}
                                format="DD/MM/YYYY"
                                dateRender={(current) => {
                                  const style = {};
                                  if (current.date() === 1) {
                                    style.border = "1px solid #1890ff";
                                    style.borderRadius = "50%";
                                  }
                                  return (
                                    <div
                                    className="ant-calendar-date"
                                    style={style}
                                    >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              colon={false}
                              label={
                                  <span style={styleFormLabel}>
                                    {i18n.veh.id_driver_license}
                                  </span>
                                }
                                required={true}
                                validateStatus={
                                  touched.id_driver_license &&
                                  errors.id_driver_license &&
                                  "error"
                                }
                                help={
                                  touched.id_driver_license &&
                                  errors.id_driver_license
                                }
                                >
                              <Input
                                data-cy="id_driver_license"
                                type="text"
                                name="id_driver_license"
                                value={values.id_driver_license}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.id_driver_license &&
                                  errors.id_driver_license
                                  ? "invalid"
                                  : undefined
                                }
                                />
                            </Form.Item>
                          </Col>

                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <span style={styleFormLabel}>
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.veh.driver_license_expire_date}
                                  </LangContext.Consumer>
                                </span>
                              }
                              validateStatus={
                                touched.driver_license_expire_date &&
                                errors.driver_license_expire_date &&
                                "error"
                              }
                              help={
                                touched.driver_license_expire_date &&
                                errors.driver_license_expire_date
                              }
                              >
                              <DatePicker
                                data-cy="driver_license_expire_date"
                                disabledDate={this.disabledDate}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  setFieldValue(
                                    "driver_license_expire_date",
                                    value
                                    )
                                  }
                                  value={values.driver_license_expire_date}
                                  format="DD/MM/YYYY"
                                  dateRender={(current) => {
                                    const style = {};
                                    if (current.date() === 1) {
                                      style.border = "1px solid #1890ff";
                                      style.borderRadius = "50%";
                                    }
                                    return (
                                      <div
                                      className="ant-calendar-date"
                                      style={style}
                                      >
                                      {current.date()}
                                    </div>
                                  );
                                }}
                                />
                            </Form.Item>
                          </Col>

                          <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                          <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={
                                <Fragment>
                                  <span style={styleFormLabel}>
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.file_copy_driver_license}
                                    </LangContext.Consumer>
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    title={i18n.business.info_one_file}
                                    >
                                    <Icon
                                      type="info-circle"
                                      theme="outlined"
                                      style={{
                                        paddingTop: "8px",
                                        paddingLeft: "5px",
                                        fontSize: "14px",
                                        color: "#3B68D9",
                                      }}
                                      />
                                  </Tooltip>
                                </Fragment>
                              }
                              validateStatus={
                                touched.file_copy_driver_license &&
                                errors.file_copy_driver_license &&
                                "error"
                              }
                              help={
                                touched.file_copy_driver_license &&
                                errors.file_copy_driver_license
                              }
                              >
                              <Upload
                                className="test-upload-file-copy-driver-license"
                                name="file_copy_driver_license"
                                // listType="picture-card"
                                fileList={values.file_copy_driver_license}
                                // customRequest={dummyRequest}
                                beforeUpload={(file) => {
                                  const isCheckTypeFile =
                                  file.type === "image/jpeg" ||
                                  file.type === "image/png" ||
                                  file.type === "application/pdf";
                                  if (!isCheckTypeFile) {
                                    message.error(
                                      i18n.worksheet.upload_file_support
                                      );
                                    }
                                    // const isLt2M = file.size / 1024 / 1024 < 1;
                                    const checkSizeFile = file.size / 1024 <= 5120;
                                    if (!checkSizeFile) {
                                      // alert(file.size);
                                      message.error(
                                        i18n.worksheet.upload_size_support
                                        );
                                      }
                                      
                                      if (isCheckTypeFile && checkSizeFile) {
                                        setFieldValue("file_copy_driver_license", [
                                          file,
                                        ]);
                                      }
                                      
                                      return false;
                                    }}
                                    onChange={({ file, fileList }) => {
                                      if (fileList.length === 0) {
                                        setFieldValue("file_copy_driver_license", []);
                                      }
                                    }}
                                    >
                                <Button
                                  data-cy="btn-cancel" data-cy="btn-upload-file-copy-driver-license" type="primary">
                                  <Icon type="upload" /> {i18n.s.select_file}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>    
                        
                        <Row style={{padding:"20px 0px"}}>
                          <Col xs={6} sm={7} md={3} lg={4} xl={4} xxl={6}/>
                          <Col  xs={18} sm={8} md={8} lg={5} xl={4} >    
                            <Button
                              data-cy="btn-cancel"
                              type="default"
                              style={{
                                width:"120px",
                              }}
                              onClick={() => (window.location.href = "/")}
                              >
                              {i18n.c.cancel}
                            </Button>
                          </Col>
                          <Col xs={6} sm={0} md={2} lg={2} xl={3} xxl={2}/>
                          <Col  xs={18} sm={8} md={8} lg={5} xl={4} >

                            <Button
                              data-cy="btn-submit"
                              htmlType="submit"
                              type="primary"
                              style={{
                                width:"120px",
                              }}
                              >
                              {i18n.c.confirm}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  )}
                  />
              </div>
            )}
          </LangContext.Consumer>
          </div>
        </Col>
      </Row>
      </div>
    );
  }
}

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID
} from "../../../../../constants/local_storage";
import * as actions from "./actions";

function* loadTransportMarket(action) {

  const { page, pageSize, orderBy, orderType, filters } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loadworksheet`,
      {
        page,
        pageSize,
        company_id: COMPANY_ID,
        orderBy, 
        orderType, 
        filters
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadTransportMarket.success(res.data));
  } catch (err) {
    yield put(actions.loadTransportMarket.failure());
  }
}

function* showTransportMarket(action) {
  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loadworksheet/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.showTransportMarket.success(res.data));
  } catch (err) {
    yield put(actions.showTransportMarket.failure());
  }
}

function* uploadQuotation(action) {
  const { id, file, price, document_number } = action.payload;

  const formData = new FormData();
  formData.append("files", file[0]);
  formData.append("worksheet_id", id);
  formData.append("company_id", COMPANY_ID);
  formData.append("user_id", USER_ID);
  formData.append("price", price);
  formData.append("document_number", document_number);


  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/uploadqt`,
      formData,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.uploadQuotation.success(res.data));
  } catch (err) {
    yield put(actions.uploadQuotation.failure());
  }
}

export default function* watchTransportMarketState() {
  yield all([
    takeEvery(actions.LOAD_TRANSPORT_MARKETS.REQUEST, loadTransportMarket),
    takeEvery(actions.SHOW_TRANSPORT_MARKET.REQUEST, showTransportMarket),
    takeEvery(actions.UPLOAD_QT_TRANSPORT_MARKET.REQUEST, uploadQuotation),
  ]);
}

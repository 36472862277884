// import * as firebase from "firebase/app";
// import "firebase/messaging";

import firebase from "firebase";

let messaging;
let initializedFirebaseApp;

if ("PushManager" in window) {
  initializedFirebaseApp = firebase.initializeApp(
    {
      apiKey: "AIzaSyBeStR8V0sfsDtS74JCy2DiPCRTHVnkkyE",
      projectId: "terminus-336ce",
      messagingSenderId: "874130675038",
      appId: "1:874130675038:web:db587390aa677ef7906bcf",
    },
    "secondary"
  );

  firebase.initializeApp({
    apiKey: "AIzaSyBS0iiPY3RQqizue3xcS132EjRpuXuPKHQ",
    authDomain: "backhaul-e39d8.firebaseapp.com",
    databaseURL: "https://backhaul-e39d8.firebaseio.com",
    projectId: "backhaul-e39d8",
    storageBucket: "backhaul-e39d8.appspot.com",
    messagingSenderId: "666811889309",
    appId: "1:666811889309:web:8509f91493d312d940c674",
    measurementId: "G-956K29GEGJ",
  });

  messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(
    "BKw2wtrbaY6Nm66rlRQaOvEqC2Ux48WhmvSpzE3bDez_ArTGie80uUO8Ba6oOIiJav27po3P4FwYoDbpV59a-RU"
  );
} else {
  firebase.initializeApp({
    apiKey: "AIzaSyBS0iiPY3RQqizue3xcS132EjRpuXuPKHQ",
    authDomain: "backhaul-e39d8.firebaseapp.com",
    databaseURL: "https://backhaul-e39d8.firebaseio.com",
    projectId: "backhaul-e39d8",
    storageBucket: "backhaul-e39d8.appspot.com",
    messagingSenderId: "666811889309",
    appId: "1:666811889309:web:8509f91493d312d940c674",
    measurementId: "G-956K29GEGJ",
  });
  messaging = null;
}

export { messaging, firebase, initializedFirebaseApp };

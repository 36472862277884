import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import axios from "axios";

import {
  loadMasterVehicle,
  loadDriverFormapping,
  loadVehicle,
  matchVehicle,
  unmatchVehicle,
  removeLists,
} from "../actions";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import IndexFilterDrawer from "./IndexFilterDrawer";
import LangContext from "modules/shared/context/langContext";

import {
  Button,
  Table,
  Row,
  Col,
  Pagination,
  Spin,
  Badge,
  Popconfirm,
  Divider,
  Tooltip,
  Icon,
} from "antd";
const columnStyle = { fontSize: "12px", fontWeight: "400" };


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      isFilter: false,
      filterVisible: false,
      expand: false,
      Plant: "",
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      companyID: "",
      visible: false,
      orderBy: "vehicles.id",
      orderType: "desc",
      filterObj: {
        FilterObjPlateno: "",
        FilterObjVehicleCode: "",
        FilterObjVehicleTypee: "",
      },
      PERMIS_ADD: false, // set default permissioon add
      PERMIS_EDIT: false, // set default permissioon edit
      PERMIS_SUPER: 0, // 1 เป็น super admin
    };

    this.columns = [
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.n.no}</LangContext.Consumer>
        ),
        dataIndex: "rowNumber",
        width: "5%",
        // fixed: "left",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.plateNo}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        key: "plate_no",
        width: "10%",
        sorter: true,
        // fixed: "left",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.veh.plate_no_province}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_province",
        key: "plate_province",
        sorter: true,
        // fixed: "left",
        width: "13%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.veh.service_area}
          </LangContext.Consumer>
        ),
        //dataIndex: "vehicle_type",
        //key: "vehicle_type",
        sorter: false,
        width: "15%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>{record.start_province + " - " + record.end_province}</div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleType}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_type",
        key: "vehicle_type",
        sorter: true,
        width: "15%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: (
              <div>
                <img
                  src={`/img/vehicletype/${record.link_image}`}
                  width="37"
                  height="20"
                />{" "}
                {text}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.b.brand}</LangContext.Consumer>
        ),
        dataIndex: "brand",
        key: "brand",
        sorter: true,
        width: "7%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.m.model}</LangContext.Consumer>
        ),
        dataIndex: "model",
        key: "model",
        sorter: true,
        width: "5%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.c.Color}</LangContext.Consumer>
        ),
        dataIndex: "color",
        key: "color",
        sorter: true,
        width: "5%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.y.year}</LangContext.Consumer>
        ),
        dataIndex: "year",
        key: "year",
        sorter: true,
        width: "5%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.v.verify_status}</LangContext.Consumer>
        ),
        dataIndex: "verified",
        key: "verified",
        sorter: true,
        width: "12%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <LangContext.Consumer>
                        {i18n => (
                          text === 1 ? i18n.v.verify_yes : i18n.v.verify_no
                        )}
                      </LangContext.Consumer>
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        width: "8%",
        //fixed: "right",
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "vehicle",
                "can_view"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.detail}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                    data-cy={`btn-view-${record.vehicles_id}`}
                    to={`/vehicle/detail/${record.vehicles_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="file-text" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "vehicle",
                "can_edit"
              ) == true ? (
                <Tooltip
                  data-cy={`btn-edit`}
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.edit}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Link
                    data-cy={`btn-edit-${record.vehicles_id}`}
                    to={`/vehicle/edit/${record.vehicles_id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="edit" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}
              {checkPermissions(
                this.props.auth.profile.permissions,
                "vehicle",
                "can_delete"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.delete}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                  style={{
                    padding: "0px 1px 0px 0px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  <Popconfirm
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                      </LangContext.Consumer>
                    }
                    okText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.o.okay}
                      </LangContext.Consumer>
                    }
                    cancelText={
                      <LangContext.Consumer>
                        {(i18n) => i18n.c.cancel}
                      </LangContext.Consumer>
                    }
                    onConfirm={() => {
                      let self = this;
                      self.deleteVehicle(
                        record.vehicles_id,
                        this.props.auth.accessToken
                      );
                    }}
                  >
                    <a
                      data-cy={`btn-del-${record.vehicles_id}`}
                      href="javascript:;"
                      style={{ padding: "0px 5px 0px 5px" }}
                    >
                      <Icon type="delete" />
                    </a>
                  </Popconfirm>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
    //table in index
  }

  componentDidMount() {
    let permis_add = checkPermissions(
      this.props.auth.profile.permissions,
      "vehicle",
      "can_add"
    );
    let permis_edit = checkPermissions(
      this.props.auth.profile.permissions,
      "vehicle",
      "can_edit"
    );

    if (permis_add == true) {
      this.setState(
        {
          PERMIS_ADD: true,
          PERMIS_SUPER: this.props.auth.profile.is_super,
        },
        () => this.loadData(true)
      );
    } else {
      this.setState(
        {
          PERMIS_SUPER: this.props.auth.profile.is_super,
        },
        () => this.loadData(true)
      );
    }

    const company_id = this.props.auth.profile.company_id;
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getplantformapping/${company_id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let plantAPI = response.data.data.map((plant, i) => {
            return { key: i, value: plant.plant };
          });
          let value = [{ key: "-1", value: "ทั้งหมด" }].concat(plantAPI);
          self.setState({ Plant: value });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  loadData = (loading) => {
    this.props.loadMasterVehicle(
      {
        companyID: this.props.auth.profile.company_id,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
        permis_super: this.state.PERMIS_SUPER,
        permis_add: this.state.PERMIS_ADD,
        permis_edit: this.state.PERMIS_EDIT,
      },
      loading
    );
  };

  deleteVehicle = (id, accessToken) => {
    let self = this;
    let compantd = this.props.auth.profile.company_id;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/delete/${id}/${compantd}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then(function(response) {
        self.loadData(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  showFilterDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFilterDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      this.setState({
        orderBy: "vehicles.id",
        orderType: "asc",
      });
      orderBy = "vehicles.id";
      orderType = "asc";
    } else {
      this.setState({
        orderBy: sorter.columnKey,
        orderType: sorter.order == "descend" ? "desc" : "asc",
      });
      orderBy = sorter.columnKey;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadMasterVehicle(
      {
        companyID: this.props.auth.profile.company_id,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        searchName: this.state.searchName,
        orderBy: orderBy,
        orderType: orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
        permis_super: this.state.PERMIS_SUPER,
        permis_add: this.state.PERMIS_ADD,
        permis_edit: this.state.PERMIS_EDIT,
      },
      true
    );
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadMasterVehicle(
      {
        companyID: this.props.auth.profile.company_id,
        vehicle_visibility: this.props.auth.profile.vehicle_visibility,
        page: page,
        pageSize: pageSize,
        searchName: this.state.searchName,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        accessToken: this.props.auth.accessToken,
        filterObj: this.state.filterObj,
        permis_super: this.state.PERMIS_SUPER,
        permis_add: this.state.PERMIS_ADD,
        permis_edit: this.state.PERMIS_EDIT,
      },
      true
    );
  };

  // Filter

  onFilterButtonClick = (e) => {
    this.loadData(true);
  };

  handleFilterObjPlateno = (e) => {
    this.setState({
      filterObj: { ...this.state.filterObj, FilterObjPlateno: e.target.value },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  handleFilterObjVehicleType = (e) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        FilterObjVehicleTypee: e.target.value,
      },
      isFilter: e.target.value != null && e.target.value != "",
    });
  };

  // End Filter

  render() {
    const { total, loading } = this.props.vehicle;
    const columns = this.columns.map((col) => col);

    return (
      <AuthorizeComponent matching_name="vehicle">
        <GeneralStyledContent>
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Divider orientation="left">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicle}
                    </LangContext.Consumer>
                </Divider>
              </Col>
            </Row>

            <Row
              gutter={24}
              justify="end"
              style={{ height: "38px" }}
            >
              <Col xs={15} sm={20} md={21} lg={22} xl={22}>
                {checkPermissions(
                  this.props.auth.profile.permissions,
                  "vehicle",
                  "can_add"
                ) == true ? (
                  <Link to="/vehicle/create" >
                    <Button type="primary" icon="plus" size="small" data-cy={`btn-create`}>
                      <span></span>
                      <LangContext.Consumer>
                        {(i18n) => i18n.n.new}
                      </LangContext.Consumer>
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Col>
              {/* <Col span={6}></Col> */}
              <Col xs={9} sm={4} md={3} lg={2} xl={2}>
                <Badge dot={this.state.isFilter} align="right">
                  <Tooltip
                    placement="right"
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.o.openFilter}
                      </LangContext.Consumer>
                    }
                  >
                    <Button size="small" data-cy={`btn-filter`} onClick={this.showFilterDrawer}>
                      <Icon type="filter" style={{ marginRight: "5px" }} />
                      <LangContext.Consumer>
                        {(i18n) => i18n.f.filter}
                      </LangContext.Consumer>
                    </Button>
                  </Tooltip>
                </Badge>

                <IndexFilterDrawer
                  onClose={this.onFilterDrawerClose}
                  visible={this.state.filterVisible}
                  onFilterButtonClick={this.onFilterButtonClick}
                  handleFilterObjPlateno={this.handleFilterObjPlateno}
                  handleFilterObjVehicleType={this.handleFilterObjVehicleType}
                />
              </Col>
            </Row>

            <Row>
              <Spin spinning={loading}>
                <Table
                  /// scroll={{ x: 1300 }}
                  data-cy={`data-table`}
                  bordered
                  rowKey="vehicles_id"
                  columns={columns}
                  dataSource={this.props.vehicle.lists}
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                  getTrProps={(state, rowNumber) => {
                    return {
                      style: {
                        background:
                          rowNumber === this.state.selected
                            ? "#00afec"
                            : "white",
                        color:
                          rowNumber === this.state.selected ? "white" : "black",
                      },
                    };
                  }}
                />
              </Spin>
            </Row>
            <Row type="flex" justify="end" style={{ padding: "15px" }}>
              <Col span={24} align="right">
                <Pagination
                  data-cy={`pagination`}
                  defaultPageSize={this.state.pageSize}
                  size="small"
                  current={this.state.currentPage}
                  total={total}
                  onChange={this.handlePageChange}
                />
              </Col>
            </Row>
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ vehicle, auth }) => ({
    vehicle,
    auth,
  }),
  {
    loadMasterVehicle: loadMasterVehicle.request,
    loadDriverFormapping: loadDriverFormapping.request,
    loadVehicle: loadVehicle.request,
    matchVehicle: matchVehicle.request,
    unmatchVehicle: unmatchVehicle.request,

    removeLists,
  }
)(Index);

import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Input, Alert, Icon, Col, Row, Select, Steps } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Link } from "react-router-dom";

export default ({ auth, onSubmit, firebase, step, companytypeLists }) => {

  const { Step } = Steps
  const { Option } = Select;
  
  const styleForm = {
    backgroundColor:"#fafbfb", 
    minHeight: "calc( 100vh - 420px)",
    borderRadius: "15px",
    padding:"5% 10% ",
    boxShadow: "10px 16px 23px -12px rgba(0, 0, 0, 0.13)",
  }
  const styleFormLabel = {
    fontSize:"14px",
    color:"#2f67dc",
  }
  const getStep = (step) => {
    let stepJSX = []
      for(let loopIndex = 1 ; loopIndex <= step ; loopIndex++)
        stepJSX = [...stepJSX , loopIndex]
    return(
      <Steps current={0} >   
        {stepJSX.map(() =>(
          <Step title="" description="" />
        ))}  
      </Steps>
    )
  };

  return (
    <>
      <Row>
        <Col xs={4} sm={4} md={4} lg={6} xl={7} xxl={7}/>
        <Col xs={16} sm={16} md={16} lg={14} xl={10} xxl={10}>
          <div style={styleForm}>
            <LangContext.Consumer>
              {(i18n) => (
                <div>
                  {/* <h1>{i18n.s.signup}</h1> */}
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:"center"}}>
                      <h2 style={{color:"#2f67dc",fontWeight:"bold"}}> สร้างบัญชี</h2>
                      <h2 style={{color:"#2f67dc"}}>กรอกรายละเอียด</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10} sm={0} md={0} lg={0} xl={0}/>
                    <Col  xs={12} sm={24} md={24} lg={24} xl={24} style={{padding:"20px 0px"}}>
                      {getStep(step)}
                    </Col>
                  </Row>

                  <Formik
                    initialValues={{
                      email: "",
                      phone: "",
                      password: "",
                      trypassword: "",
                      company_type: "",
                    }}
                    validate={(values) => {
                      let errors = {};
                      if (!values.email) {
                        errors.email = i18n.e.email_require;
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                        ) {
                          errors.email = i18n.e.email_invalid;
                        }
                        
                        if (!values.phone) {
                          errors.phone = i18n.p.phone_require;
                        } else if (/^\d+$/.test(values.phone) === false) {
                          errors.phone = i18n.p.phone_invalid;
                        }

                        if (values.company_type == "") {
                          errors.company_type = "กรุณาเลือกประเภทบริษัท"
                        }
                        
                        return errors;
                      }}
                      validationSchema={yup.object().shape({
                        company_type: yup
                        .string(), //ประเภทของข้อมูลคือ String
                        password: yup
                        .string() //ประเภทของข้อมูลคือ String
                        .min(6, i18n.p.please_less_than_6) //ต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป
                        .required(i18n.p.password_require), //ต้องกรอกช่องนี้
                        trypassword: yup
                        .string() //ประเภทของข้อมูลคือ String
                        .min(6, i18n.p.please_less_than_6) //ต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป
                        .required(i18n.p.confirm_password_require) //ต้องกรอกช่องนี้
                        // ตรวจสอบว่า password match หรือไม่
                        .test("passwords-match", i18n.p.passwords_must_match, function(
                          value
                          ) {
                            return this.parent.password === value;
                          }),
                        })}
                    onSubmit={(values) => onSubmit(values)}
                    render={({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit} >
                        <Row>
                          <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8} >
                            <Row>
                              <Col span={24} style={{textAlign:"left"}}>
                                <span style={styleFormLabel}>{i18n.p.phone}</span>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  required={true}
                                  validateStatus={touched.phone && errors.phone && "error"}
                                  help={touched.phone && errors.phone}
                                  >
                                  <Input
                                    data-cy="phone"
                                    type="text"
                                    name="phone"
                                    autocomplete="off"
                                    // placeholder={i18n.p.phone}
                                    values={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.phone && errors.phone ? "invalid" : undefined
                                    }
                                    style={{ margin: "0px 0px 5px 0px" }}
                                    />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          
                          <Col xs={0} sm={2} md={2} lg={2} xl={2}/>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                              <Col span={24} style={{textAlign:"left"}}>
                                <span style={styleFormLabel}>{i18n.e.email}</span>
                              </Col>
                              <Col span={24}>
                              </Col>
                                <Form.Item
                                  required={true}
                                  validateStatus={touched.email && errors.email && "error"}
                                  help={touched.email && errors.email}
                                  >
                                  <Input
                                    data-cy="email"
                                    type="text"
                                    name="email"
                                    // placeholder={i18n.e.email}
                                    autocomplete="off"
                                    values={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.email && errors.email ? "invalid" : undefined
                                    }
                                    style={{ margin: "0px 0px 5px 0px" }}
                                    />
                                </Form.Item>
                            </Row>
                          </Col>
                        </Row>


                        <Row>
                          <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8} >
                            <Row>
                              <Col span={24} style={{textAlign:"left"}}>
                                <span style={styleFormLabel}>{i18n.p.password}</span>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                    required={true}
                                    validateStatus={
                                      touched.password && errors.password && "error"
                                    }
                                    help={touched.password && errors.password}
                                    >
                                    <Input
                                      data-cy="password"
                                      type="password"
                                      name="password"
                                      autocomplete="off"
                                      // placeholder={i18n.p.password}
                                      values={values.password}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.password && errors.password
                                          ? "invalid"
                                          : undefined
                                        }
                                        style={{ margin: "0px 0px 5px 0px" }}
                                        />
                                  </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          
                          <Col xs={0} sm={2} md={2} lg={2} xl={2}/>
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Row>
                              <Col span={24} style={{textAlign:"left"}}>
                                <span style={styleFormLabel}>{i18n.c.confirm_password}</span>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                required={true}
                                validateStatus={
                                  touched.trypassword && errors.trypassword && "error"
                                }
                                help={touched.trypassword && errors.trypassword}
                                >
                                <Input
                                  data-cy="trypassword"
                                  type="password"
                                  name="trypassword"
                                  autocomplete="off"
                                  // placeholder={i18n.c.confirm_password}
                                  values={values.trypassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.trypassword && errors.trypassword
                                    ? "invalid"
                                    : undefined
                                  }
                                  style={{ margin: "0px 0px 5px 0px" }}
                                  />
                              </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                      <Row>
                          <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                          <Col xs={24} sm={18} md={18} lg={18} xl={18} >
                            <Row>
                              <Col span={24} style={{textAlign:"left"}}>
                                <span style={styleFormLabel}>โปรดระบุประเภทของบริษัทคุณ</span>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  required={true}
                                  validateStatus={
                                    touched.company_type && errors.company_type && "error"
                                  }
                                  help={touched.company_type && errors.company_type}
                                >
                                  <Select
                                    data-cy="company_type"
                                    values={values.company_type}
                                    onChange={(value) =>
                                      setFieldValue("company_type", value)
                                    }
                                    invalid={
                                      touched.company_type && errors.company_type
                                      ? "invalid"
                                      : undefined
                                    }
                                  >
                                  <Option value="">{i18n.p.pleaseSelect}</Option>
                                    {companytypeLists.slice(0,step).map((v) => (
                                      <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))}
                                </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={0} sm={3} md={3} lg={3} xl={3}/>
                          <Col xs={24} sm={18} md={18} lg={18} xl={18} style={{padding:"20px 0px"}}>
                            <div
                              style={{
                                backgroundColor: "white",
                                overflowY: "auto",
                                width: "100%",
                                height: "140px",
                                padding: "10px 0px",
                                borderStyle: "inset",
                                textAlign:"center"
                              }}
                              >
                              เงือนไขการให้บริการ
                            </div>

                            <div style={{ padding: "10px 0px 0px 0px",fontSize:"14px" }}>
                              <span>
                                เมื่อกด "สมัครใช้บริการ" แสดงว่าคุณยอมรับ 
                              </span>

                              <span style={{color:"#2f67dc"}}>
                                {` ข้อกำหนดในการให้บริการ `}
                              </span>
                              <span>
                                และ
                              </span>
                              <span style={{color:"#2f67dc"}}>
                                {` นโยบายความเป็นส่วนตัว `}
                              </span>
                              <span>
                               ของ KhonKhong
                              </span>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={3} sm={4} md={4} lg={5} xl={5} xxl={7}/>
                          <Col  xs={21} sm={8} md={8} lg={5} xl={4} >
                            <Button
                              data-cy="btn-go-back"
                              type="default"
                              style={{
                                width: "120px",
                              }}
                              >
                              <Link to="/signup">{i18n.b.back}</Link>
                            </Button>
                          </Col>
                          <Col xs={3} sm={0} md={1} lg={2} xl={3} xxl={2}/>
                          <Col  xs={21} sm={8} md={8} lg={5} xl={4} >
                            <Button
                              data-cy="btn-submit"
                              htmlType="submit"
                              type="primary"
                              style={{
                                width: "120px"
                              }}
                              >
                              {i18n.n.next}
                            </Button>
                          </Col>
                        </Row>

                      </Form>
                    )}
                    />
                </div>
              )}
            </LangContext.Consumer>
            
          </div>
        </Col>
      </Row>
  </>
)};

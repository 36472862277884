import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Formik } from "formik";

import { Row, Col, Spin, Form, message, Input } from "antd";
import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import {
  StyledSearchForm,
  GeneralStyledContent,
  PageTitleStyled,
  NormalText as Text,
  ButtonBackhual,
} from "../../../../../../styled/backhaul-styled";

import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";

import JobDetail from "./JobDetail";

export class Detail extends Component {
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.showWorksheet(id);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidUpdate() {
    if (this.props.success === true) {
      message.success("Upload success");
      this.props.history.goBack();
    }

    if (this.props.success === false) {
      message.error("Upload fail");
    }
  }

  render() {
    return (
      <AuthorizeComponent matching_name="allwork_dashboard">
        <GeneralStyledContent>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Breadcrumb
                customeurl={[null, "/productowner/allwork", null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <PageTitleStyled>
              <LangContext.Consumer>
                {(i18n) => i18n.worksheet.worksheet_title}
              </LangContext.Consumer>
            </PageTitleStyled>
            <Spin spinning={this.props.loading}>
              <StyledSearchForm>
                <Formik
                  initialValues={{
                    file: [],
                    price: "",
                  }}
                  enableReinitialize={true}
                  onSubmit={
                    (values) =>
                    this.props.uploadQuotation(
                      this.props.match.params.id,
                      values.file,
                      values.price,
                      this.props.data.document_number
                    )
                  }
                  render={({ values, setFieldValue, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off">
                      <Row gutter={16}>
                        <Col span={24}>
                          {typeof this.props.data !== "undefined" && (
                            <JobDetail data={this.props.data} />
                          )}
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col sm={24} md={5} lg={5} xl={5}>
                          <Form.Item>
                            <ButtonBackhual
                              data-cy="btn-go-back"
                              type="default"
                              style={{
                                marginRight: "10px",
                              }}
                              onClick={this.goBack}
                            >
                              <LangContext.Consumer>
                                {(i18n) => i18n.b.back}
                              </LangContext.Consumer>
                            </ButtonBackhual>

                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </StyledSearchForm>
            </Spin>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ productOwnerAllwork }) => ({
  data: productOwnerAllwork.data,
  loading: productOwnerAllwork.loading,
  success: productOwnerAllwork.success,
});

const mapDispatchToProps = {
  showWorksheet: actions.showWorksheet.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);

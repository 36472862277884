import React, { Component } from "react";
import { Modal, Spin, Col } from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { StyledDiv, StyledRow } from "../../../styled/common-styled";
import { firebase } from "../../../lib/fcm/fcm_init";
import { setLoading } from "../actions";
import LangContext from "modules/shared/context/langContext";

class Verifyemail extends Component {
  state = { loading: false };

  componentDidMount() {
    let self = this;
    this.setState({ loading: true });
    // self.props.setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(
        self.props.match.params.email,
        self.props.match.params.password
      )
      .then(function(result) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function(idToken) {
            // Send token to your backend via HTTPS
            self.createUserByEmail(idToken);
          })
          .catch(function(error) {
            Modal.error({
              content: error.message,
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
            this.setState({ loading: false });
          });
        // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
      })
      .catch(function(error) {
        console.log("error");
        Modal.error({
          content: error.message,
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
        this.setState({ loading: false });
        // Handle error.
      });
  }

  createUserByEmail = (token) => {
    let self = this;
    console.log("props:",this.props.match);
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/signup`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.props.match.params.email,
        password: this.props.match.params.password,
        phone: this.props.match.params.phone,
        user_type: this.props.match.params.company_type == 4 ? "personal" : this.props.match.params.usertype,
        company_type: this.props.match.params.company_type,
        token,
        by: "email",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ loading: false });
        if (res.status === "fail") {
          if (res.msg === "duplicate") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.signup.email_already}
                </LangContext.Consumer>
              ),
              onOk() {
                window.location = "/";
              },
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          } else {
            Modal.error({
              content: res.msg,
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          }
        } else {
          if (this.props.match.params.usertype === "personal") {
            window.location = "/form/personal/" + res.id;
            //self.props.history.push("/form/personal/" + res.id);
          } else if (this.props.match.params.usertype === "productowner") {
            window.location = "/form/productowner/" + res.id;
            // self.props.history.push("/form/productowner/" + res.id);
          } else {
            window.location = "/form/transporter/" + res.id;
            // self.props.history.push("/form/transporter/" + res.id);
          }
        }
      });
  };

  render() {
    return (
      <StyledDiv>
        <Spin spinning={this.state.loading} style={{ paddingTop: "48%" }}>
          <StyledRow></StyledRow>
        </Spin>
      </StyledDiv>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    setLoading: setLoading,
  }
)(Verifyemail);

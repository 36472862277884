import React, { Component, Fragment } from "react";
import { Button, Result } from "antd";

export class ErrorBoundary extends Component {
  state = { hasError: false, error: null, info: null };
  // componentDidCatch(error, info) {
  //   localStorage.removeItem("access-token");
  //   localStorage.removeItem("profile");
  //   // You can also log the error to an error reporting service
  //   // console.log(error, info);
  //   window.location.href = "/";
  // }
  render() {
    // if (this.state.hasError) {
    //   localStorage.removeItem("access-token");
    //   localStorage.removeItem("profile");
    //   return (
    //     <Result
    //       status="500"
    //       title="Something went wrong"
    //       subTitle="Sorry, the server is reporting an error."
    //       extra={
    //         <Button type="primary" onClick={() => (window.location.href = "/")}>
    //           Back to Home
    //         </Button>
    //       }
    //     ></Result>
    //   );
    // }

    return this.props.children;
  }
}
export default ErrorBoundary;

import React, { PureComponent, Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Button,
  Form,
  Row,
  Col,
  Spin,
  Upload,
  Icon,
  message,
  Modal,
} from "antd";

import { StyledSearchForm } from "../../../../styled/backhaul-styled";
import axios from "axios";
import parse from "html-react-parser";

const FormItem = Form.Item;

export default class Forms extends PureComponent {
  state = { loading: false, worksheet_file: [] };

  onSubmit = (values, resetForm) => {
    const { auth, page, pageSize, loadWorkSheet, langBtn } = this.props;

    const ACCESSTOKEN = auth.accessToken;
    const company_id = auth.profile.company_id;
    const user_id = auth.profile.id;
    const { worksheet_file } = values;
    let self = this;

    this.setState({ loading: true });
    const formData = new FormData();

    worksheet_file.forEach((File) => {
      //console.log("File:", File.originFileObj);
      formData.append("worksheet_file", File.originFileObj);
    });

    formData.append("company_id", company_id);
    formData.append("user_id", user_id);
    formData.append("worksheet_type", this.props.type);

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/worksheet/import`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${ACCESSTOKEN}`,
          },
        }
      )
      .then(function (response) {
        console.log("response:", response);
        if (response.data.status === "fail") {
          Modal.error({
            title: i18n[langBtn].v.validate_required_data,
            content: parse(response.data.msg),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
          self.setState({ loading: false });
        } else {
          Modal.success({
            content: i18n[langBtn].s.success,
            okText: i18n[langBtn].o.okay,
            okButtonProps: { "data-cy" : "btn-modal-ok" },
            onOk() {
              loadWorkSheet(page, pageSize);
              self.setState({ loading: false,worksheet_file: [] });
              resetForm();
            },
          });
        }
      })
      .catch(function (error) {
        // console.log("error:", error);
        Modal.error({
          content: i18n[langBtn].f.fail,
          okText: i18n[langBtn].o.okay,
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
        self.setState({ loading: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.setState({ worksheet_file: [] });
    }
  }


  render() {
    const { type } = this.props;
    console.log("sheet:", this.state.worksheet_file)
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Fragment>
        <Spin spinning={this.state.loading}>
          <Row>
            <LangContext.Consumer>
              {(i18n) => (
                <StyledSearchForm>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      worksheet_file: this.state.worksheet_file,
                    }}
                    validate={(values) => {
                      let errors = {};

                      if (values.worksheet_file.length === 0) {
                        errors.worksheet_file =
                          i18n.worksheet.worksheet_file_require;
                      }

                      return errors;
                    }}
                    validationSchema={yup.object().shape({})}
                    onSubmit={(values, { resetForm }) => {
                      this.onSubmit(values, resetForm);
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleFocus,
                      setFieldValue,
                      resetForm,
                    }) => (
                        <Row>
                          <LangContext.Consumer>
                            {(i18n) => (
                              <Form {...formItemLayout} onSubmit={handleSubmit}>

                                <Row>
                                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <FormItem
                                      required={true}
                                      label={i18n.worksheet.importworksheet}
                                      validateStatus={
                                        touched.worksheet_file &&
                                        errors.worksheet_file &&
                                        "error"
                                      }
                                      help={
                                        touched.worksheet_file &&
                                        errors.worksheet_file
                                      }
                                    >
                                      <Upload
                                        className="test-upload-worksheet-file"
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                        name="worksheet_file"
                                        fileList={this.state.worksheet_file}
                                        beforeUpload={(file, fileList) => {
                                          return false;
                                        }}
                                        onChange={({ file, fileList }) => {
                                          const isCheckTypeFile =
                                            file.type ===
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                                          if (!isCheckTypeFile) {
                                            message.error(
                                              i18n.worksheet.upload_xlsx_support
                                            );
                                          }

                                          if (fileList.length > 1) {
                                            message.error(
                                              i18n.worksheet
                                                .upload_amount1_support
                                            );
                                          }
                                          if (
                                            isCheckTypeFile &&
                                            fileList.length <= 1
                                          ) {
                                            setFieldValue(
                                              "worksheet_file",
                                              fileList
                                            );
                                            this.setState({ worksheet_file: fileList });
                                          }
                                        }}
                                      >
                                        <Button data-cy="btn-upload">
                                          <Icon type="upload" />
                                          {i18n.s.select_file}
                                        </Button>
                                      </Upload>
                                    </FormItem>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                    <Form.Item>
                                      <Button
                                      data-cy="btn-go-back"
                                        type="defualt"
                                        style={{
                                          marginRight: "10px",
                                        }}
                                        onClick={() =>
                                          (window.location.href =
                                            window.location.pathname)
                                        }
                                      >
                                        {i18n.c.cancel}
                                      </Button>

                                      <Button
                                      data-cy="btn-submit"
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        {i18n.u.upload}
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            )}
                          </LangContext.Consumer>
                        </Row>
                      )}
                  />
                </StyledSearchForm>
              )}
            </LangContext.Consumer>
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

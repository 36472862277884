import React, { Component, Fragment } from "react";
// import { connect } from "react-redux";
// import { Link, Redirect } from "react-router-dom";

// import FormDivider from "../../../shared/components/FormDivider";
import { GeneralStyledContent } from "../../../styled/common-styled";
// import LangContext, { i18n } from "modules/shared/context/langContext";
import { StyledSearchForm } from "../../../styled/common-styled";
import {
  Button,
  Row,
  Col,
  Select,
  Modal,
  Popconfirm,
  Input,
  Divider,
  Tooltip,
  Form,
  Icon,
  Rate,
} from "antd";
const columnStyle = { fontSize: "12px", fontWeight: "400" };
const columnStyle2 = { fontSize: "10px", fontWeight: "350" };

class PrivacyPolicy extends Component {

  render() {
    return (
        <div className="privacy-div">
            <div style={{width:"1000px"}}>
                <div>
                    <div style={{textAlign:"center", fontSize: "50px", marginBottom: "60px", fontWeight:"bold"}}>
                        PRIVACY POLICY
                    </div>
                    <div style={{textAlign:"left", fontSize: "20px"}}>
                        <div>
                            This Privacy Policy describes the ways in which we, Freewillfx Company Limited
                            collect, hold, use, share and protect information about individual
                            persons who visit the KhonKhong Platform located
                            at <a href="https://khonkhong.com" >https://khonkhong.com </a> 
                            and the associated mobile applications and how you can access your 
                            information and decide how it is used ,Freewillfx provides a service 
                            for matching and connecting shippers and carriers through the system. 
                            This Privacy Policy covers our activities online on any our websites, 
                            such as the system. It also covers our offline activities, such as collection 
                            of information through telephone, or in person.
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "50px"}}>
                    <div style={{textAlign:"left", fontSize: "20px"}}>
                        <div style={{marginBottom: "10px", fontWeight:"bold"}}>
                            LINKS TO OTHER SITES
                        </div>
                        <div>
                        This Privacy Policy applies only to this website, and not to
                        websites owned by third parties. We may provide links to other
                        websites which we believe may be of interest to our visitors.
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "50px"}}>
                    <div style={{textAlign:"left", fontSize: "20px"}}>
                        <div style={{marginBottom: "10px", fontWeight:"bold"}}>
                            PLEDGE ON PRIVACY OF PERSONAL DATA
                        </div>
                        <div>
                            Visiting our Platform’s homepage does not require any form of
                            registration, allowing you to visit our site without telling us who
                            you are. However, using the Service requires you to provide us with
                            Personal Data. In these situations, if you choose to withhold any
                            Personal Data requested by us, it may not be possible for you to
                            gain access to certain parts of the site and to use the Service.
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "50px"}}>
                    <div style={{textAlign:"left", fontSize: "20px"}}>
                        <div style={{marginBottom: "10px", fontWeight:"bold"}}>
                            USAGE INFORMATION.
                        </div>
                        <div>
                            We may use technologies that collect and store certain data about
                            the use of the Service on an aggregate and/or anonymous basis. This
                            information may be non-identifying or may be associated with you.
                            Whenever we associate usage information with your personal
                            information, we will treat it as personal information. In addition,
                            tracking information is collected as you navigate through our
                            Service, such as geographic areas. Vehicles with shipments being
                            transported through the Service are tracked via GPS coordinates
                            sent to our servers. We collect this information for various
                            purposes, including to provide you with support, to notify carriers
                            as soon as shipments are available nearby, to keep consignees
                            informed of arrival times, to help dispatchers or shippers know
                            where drivers are headed, to enhance our Service, and for our
                            internal business purposes.
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "50px"}}>
                    <div style={{textAlign:"left", fontSize: "20px"}}>
                        <div style={{marginBottom: "10px", fontWeight:"bold"}}>
                            USE OF PERSONAL DATA
                        </div>
                        <div>
                            Our primary goal in collecting information is to provide you with
                            an enhanced experience when using the Services.
                            We use and process your Personal Data for, without limitation, the
                            following general purposes
                        </div>
                        <div>
                            <ul>
                                <li>
                                    To process your registration with the Service, including
                                    verifying your information is active and valid;
                                </li>
                                <li>
                                    To enforce our Terms of Service;
                                </li>
                                <li>
                                    To provide you with the Service pursuant to the Terms of Service;
                                </li>
                                <li>
                                    To facilitate or enable any checks, approval as may be
                                    required pursuant to the Terms Service;
                                </li>
                                <li>
                                    To respond to questions, comments and feedback from you;
                                </li>
                                <li>
                                    To provide you with information, administrative notices and
                                    communications relevant to your use our products and services;
                                </li>
                                <li>
                                    To analyze Site usage, improve our products and the Service,
                                    and develop new products, services, features and
                                    functionality;
                                </li>
                                <li>
                                    To help diagnose problems with our computer server, and to
                                    administer our System;
                                </li>
                                <li>
                                    For distribution to carriers providing services through the
                                    System or shippers receiving services through the System;
                                </li>
                                <li>
                                    For purposes of detection, prevention and prosecution of
                                    crime;
                                </li>
                                <li>
                                    For purposes disclosed to you at the time you provide your
                                    information or as otherwise set forth in this Privacy Policy;
                                </li>
                                <li>
                                    For Identification and authentication, and fraud prevention;
                                </li>
                                <li>
                                    For internal business purposes such as auditing, data
                                    analysis, database records;
                                </li>
                                <li>
                                    For troubleshooting problems, market research, and detecting
                                    and protecting against error, fraud or other criminal
                                    activity.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: "50px"}}>
                    <div style={{textAlign:"left", fontSize: "20px", paddingBottom: "100px"}}>
                        <div style={{marginBottom: "10px", fontWeight:"bold"}}>
                            PERSONAL INFORMATION WE SHARE
                        </div>
                        <div>
                            We do not sell, share, or otherwise distribute your Personal Data
                            to third parties
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}
export default PrivacyPolicy;

import React, { Fragment } from "react";
import { Button, Form, DatePicker, Select, Drawer } from "antd";
import { translate } from "../../../../../../lib/helper";

const { Option } = Select;

const IndexFilterDrawer = (props) => {
  const onInputChange = (value) => {
    props.onChange("created_at", value);
  };

  const onSelectChange = (name) => (value) => {
    props.onChange(name, value);
  };

  const { value } = props;

  return (
    <Drawer
      title={translate("filter", "f")}
      width={520}
      placement="right"
      onClose={props.onClose}
      maskClosable={true}
      visible={props.visible}
      style={{
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: 53,
      }}
    >
      <Form>
        <Form.Item label={translate("announcement", "a")}>
          <DatePicker
            data-cy="create_date"
            placeholder={""}
            onChange={onInputChange}
            style={{ width: "100%" }}
            value={value.created_at}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item label={translate("product_type", "p")}>
          <Select
            data-cy="product_type"
            name="product_type"
            value={value.product_type}
            onChange={onSelectChange("product_type")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.productType.map((val) => (
              <Option value={val.id} key={val.id}>
                {translate(val.name, "m_product_type")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={translate("md_vehicle_type", "m")}>
          <Select
            data-cy="vehicle_type"
            name="vehicle_type"
            value={value.vehicle_type}
            onChange={onSelectChange("vehicle_type")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.vehicleType.map((val) => (
              <Option value={val.id} key={val.id}>
                {translate(val.name, "vehicle_type")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Fragment>
              {translate("md_departure", "m")}
            </Fragment>
          }
        >
          <Select
            data-cy="origin"
            name="origin"
            value={value.origin}
            onChange={onSelectChange("origin")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.province.map((val) => (
              <Option value={val.id} key={val.id}>
                {props.lang == "th" ? val.name : val.name_eng}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Fragment>
              {translate("md_destination", "m")}
            </Fragment>
          }
        >
          <Select
            data-cy="destination"
            name="destination"
            value={value.destination}
            onChange={onSelectChange("destination")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {props.province.map((val) => (
              <Option value={val.id} key={val.id}>
                {props.lang == "th" ? val.name : val.name_eng}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Fragment>
              {translate("md_porter", "m")}
            </Fragment>
          }
        >
          <Select
            data-cy="porter"
            name="porter"
            value={value.porter}
            onChange={onSelectChange("porter")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {[
              { value: "true", label: "have_porter" },
              { value: "false", label: "have_no_porter" },
            ].map((val) => (
              <Option value={val.value} key={val.value}>
                {translate(val.label, "h")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={translate("md_payment_status", "m")}>
          <Select
            data-cy="payment_type"
            name="payment_type"
            value={value.payment_type}
            onChange={onSelectChange("payment_type")}
          >
            <Option value="">{translate("all", "a")}</Option>
            {["cash", "credit"].map((val) => (
              <Option value={val} key={val}>
                {translate(val, "worksheet")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button
          data-cy="btn-filter-close"
          style={{
            marginRight: 8,
          }}
          onClick={props.onClose}
        >
          {translate("cancel", "c")}
        </Button>

        <Button type="primary" onClick={props.onFilterButtonClick} data-cy="btn-filter-submit">
          {translate("apply", "a")}
        </Button>
      </Form>
    </Drawer>
  );
};

export default IndexFilterDrawer;

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* createTransportationrating(action) {
  
  const {
    data: {
      confirmation_period,
      punctuality,
      personality_driver,
      good_service,
      totalRating,
      worksheetID,
      accessToken,
      history,
      message,
      word
    },
  } = action.payload;
  
  try {
    console.log("function*createTransportationrating -> message", message)

    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/transportationrating`,
      {
          confirmation_period,
          punctuality,
          personality_driver,
          good_service,
          totalRating,
          worksheetID,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.createTransportationrating.success(res.data));
    message.success(word);
    history.push("/");
  } catch (err) {
    yield put(actions.createTransportationrating.failure());
  }
}

export default function* watchTransportationRating() {
  yield all([
    takeEvery(actions.CREATE_TRANSPORTATION_RATING.REQUEST, createTransportationrating),
  ]);
}

export {
  createTransportationrating,
};

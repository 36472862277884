import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext from "modules/shared/context/langContext";
import { Formik } from "formik";
import { removeLists } from "../actions";
import * as yup from "yup";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import axios from "axios";
import supercluster from "points-cluster";
import { CustomMap } from "./CustomMap";
import PlaceMarker from "../../../shared/components/map-assets/Marker/PlaceMarker";
import { COMPANY_ID, ACCESSTOKEN } from "../../../../constants/local_storage";
import { TrackingStyledContent } from "../../../../styled/common-styled";

import {
  Menu,
  Dropdown,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Modal,
  Collapse,
  Tooltip,
  Form,
  Select,
  Spin,
  AutoComplete,
} from "antd";

const ButtonGroup = Button.Group;
const Panel = Collapse.Panel;
const { TextArea } = Input;

const customPanelStyle = {
  // background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 10,
  border: 0,
  overflow: "hidden",
  images: ["/img/no-image.jpg", "/img/no-image.jpg"],
};

export class AddFacilityVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapOptions: {
        center: {
          lat: 13.729852,
          lng: 100.559484,
        },
        zoom: 5, //14
      },
      loading: false,
      panelWidth: 575,
      latest_config_change: new Date().getTime(),
      map: null,
      maps: null,
      mapAPI: { trafficLayer: null, locationLayer: null },
      showLocationLayer: false,
      showTrafficLayer: false,
      showPOI: false,
      showDistance: false,
      showArea: false,
      width: "0px",
      filterVisible: false,
      distanceMarker: [],
      distancePolyline: [],
      areaPolyline: [],
      placeMarker: [],
      poiMarker: [],
      circle: null,
      areaCenterMarker: null,
      descriptionBoxDrawerWidth: "0px",
      rad: 100,
      resultLat: "",
      resultLng: "",
      facillityName1: "",
      facillityName2: "",
      facillityCode: "",
      address: "",
      description: "",
      sendSMS: "",
      geoFene: [],
      typeFacility: "",
      polygonCoords: [],
      visible: false,
      data: {
        facility_name1: "",
        facility_name2: "",
        facility_name3: "",
        facility_code: "",
        latitude: "",
        longitude: "",
        radius: "100",
        address: "",
        description: "",
        sendSMS: "",
        typeLocation: "",
        icon_default_normal_status_id: "",
        icon_default_normal_status_name: "",
        icon_default_normal_status_url: "",
        district: "",
        province: "",
        subdistrict: "",
      },
      icon_for_location_type: [],
      icon_for_location_type_selected: {
        icon_default_normal_status_id: "",
        icon_default_normal_status_name: "",
        icon_default_normal_status_url: "",
      },
      icon_for_select: [],
      icon_for_display_in_modal: [],
      lat_lng_from_tracking_create: {
        // create location with lat, lng from tracking
        lat: undefined, //this.props.match.params.lat,
        lng: undefined, //this.props.match.params.lng,
      },
      image: null,
      isShowTrafficClick: false,
      isMenuMapToolsCLick: "",
      isMenuMapTypeClick: "",
      suggestPlace: [],
      suggest_place: "",
      visibleSearchTooltip: false
    };
    this.goBack = this.goBack.bind(this);
    this.onPick = this.onPick.bind(this);
  }

  static defaultProps = {
    center: {
      lat: 13.729852,
      lng: 100.559484,
    },
    zoom: 14,
  };

  componentDidMount() {
    let self = this;
    let companyID = this.props.auth.profile.company_id;
    //select Image for location type
    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationtype`, {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      })
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({
            icon_for_location_type: response.data.data,
          });
          response.data.data.map((value, i) => {
            if (value.icon_name == "Default Location") {
              self.setState({
                data: {
                  ...self.state.data,
                  icon_default_normal_status_id: value.gallery_id,
                  icon_default_normal_status_name: value.icon_name,
                  icon_default_normal_status_url: value.image_base64,
                },
                icon_for_location_type_selected: {
                  ...self.state.icon_for_location_type_selected,
                  icon_default_normal_status_id: value.gallery_id,
                  icon_default_normal_status_name: value.icon_name,
                  icon_default_normal_status_url: value.image_base64,
                },
              });
            }
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    // End select Image for location type

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getImagefromgallery`,
        {
          companyID,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({
            icon_for_select: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  getClusters = (trackingList) => {
    const clusters = supercluster(trackingList, {
      minZoom: 0,
      maxZoom: 13,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  showFacilityDrawer = () => {
    this.setState({
      filterVisible: true,
    });
  };

  onFacilityDrawerClose = () => {
    this.setState({
      filterVisible: false,
    });
  };

  handleSuggestPlace = (keyword) => {
    this.setState({ suggest_place: keyword, visibleSearchTooltip: false });
    if(keyword === "") return
    axios
      .get(
        `https://search.longdo.com/mapsearch/json/suggest?key=${this.props.auth.profile.longdo_map_key}&keyword=${keyword}`
      )
      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`,
            {
              company_id: COMPANY_ID,
              name: keyword,
            },
            { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
          )
          .then((response) => {
            let items = [];

            if (res.data.data.length > 0) {
              items.push({ title: "Powered by longdo", children: [] });
              let idx = items.length - 1;
              res.data.data.map((v, i) => {
                items[idx]["children"].push({
                  id: i,
                  name: v.w,
                  lat: "",
                  lng: "",
                });
              });
            }

            if (response.data.data.length > 0) {
              items.push({ title: "Powered by backhaul", children: [] });
              let idx = items.length - 1;
              items[idx]["children"].push(...response.data.data);
            }

            this.setState({ suggestPlace: items });
          });
      });
  };

  //  Search Map
  handleSearchPlace = (value, options) => {
    this.setState({ suggest_place: value, visibleSearchTooltip: false });
    let placeMarker = [];
    if (options.props.lat !== "" && options.props.lng !== "") {
      placeMarker.push(
        <PlaceMarker
          key={0}
          img="/img/free-map-marker-icon-3.jpg"
          title={value}
          lat={options.props.lat}
          lng={options.props.lng}
        />
      );

      if (this.state.maps != null) {
        let bounds = new this.state.maps.LatLngBounds();
        let position = null;

        position = new this.state.maps.LatLng(
          options.props.lat,
          options.props.lng
        );

        bounds.extend(position);

        this.state.map.fitBounds(bounds);
      }

      this.setState({
        placeMarker: placeMarker,
      });
    } else {
      fetch(
        `https://search.longdo.com/mapsearch/json/search?key=${this.props.auth.profile.longdo_map_key}&keyword=${value}`
      )
        .then((response) => response.json())
        .then((data) => {
          
          if (data["data"].length > 0) {
            data["data"] = data["data"].filter((value, index) => {
              if (value.type == "poi" || value.type == "road") {
                placeMarker.push(
                  <PlaceMarker
                    key={index}
                    img="/img/free-map-marker-icon-3.jpg"
                    title={value.name}
                    lat={value.lat}
                    lng={value.lon}
                  />
                );
                return true;
              }
              return false;
            });
          }

          if (this.state.maps != null) {
            let bounds = new this.state.maps.LatLngBounds();
            let position = null;

            for (let i = 0; i < data["data"].length; i++) {
              position = new this.state.maps.LatLng(
                data["data"][i].lat,
                data["data"][i].lon
              );

              bounds.extend(position);
            }

            this.state.map.fitBounds(bounds);
          }

          this.setState({
            placeMarker: placeMarker,
          });
        });
    }
  };
  // END Search Map

  renderOptions = () => {
    return this.state.suggestPlace.map((group) => (
      <AutoComplete.OptGroup key={`${group.title}`} label={group.title}>
        {group.children &&
          group.children.map((val) => (
            <AutoComplete.Option
              title={val.name}
              key={val.name}
              lat={val.lat}
              lng={val.lng}
            >
              {val.name}
            </AutoComplete.Option>
          ))}
      </AutoComplete.OptGroup>
    ));
  };

  // Mark Point on Map

  handlePinPointFormTracking = () => {
    if (
      this.state.lat_lng_from_tracking_create.lat != undefined &&
      this.state.lat_lng_from_tracking_create.lng != undefined
    ) {
      this.setState({ showPOI: true, showDistance: false });

      if (this.state.poiMarker != [] && this.state.circle != null)
        this.state.circle.setMap(null);
      for (let i = 0; i < this.state.poiMarker.length; i++) {
        this.state.poiMarker[i].setMap(null);
      }
      this.state.poiMarker = [];
      console.log(this.state.maps);

      let myLatLng = new this.state.maps.LatLng(
        this.state.lat_lng_from_tracking_create.lat,
        this.state.lat_lng_from_tracking_create.lng
      );
      let bounds = new this.state.maps.LatLngBounds();

      let poiMarker = new this.state.maps.Marker({
        icon:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==",
        position: myLatLng,
        map: this.state.map,
      });

      let circle = new this.state.maps.Circle({
        strokeColor: "#8415FF",
        strokeOpacity: 1,
        strokeWeight: 0.8,
        fillColor: "#8415FF",
        fillOpacity: 0.2,
        radius: parseInt(this.state.data.radius),
        map: this.state.map,
      });
      let lat = this.state.lat_lng_from_tracking_create.lat;
      let lng = this.state.lat_lng_from_tracking_create.lng;

      this.setState({
        data: {
          ...this.state.data,
          latitude: parseFloat(lat),
          longitude: parseFloat(lng),
        },
      });

      let radius =
        parseInt(this.state.data.radius) + parseInt(this.state.data.radius) / 2;
      let scale = radius / 500;
      let zoomLevel = 16 - Math.log(scale) / Math.log(2);

      circle.bindTo("center", poiMarker, "position");
      bounds.extend(myLatLng);
      this.state.map.fitBounds(bounds);
      this.state.map.setZoom(zoomLevel);
      this.state.poiMarker.push(poiMarker);
      this.setState({ circle: circle });
    }
  };

  handlePinPointOfInterest = (e) => {
    this.handleClearAllToolDrawing();
    let self = this;
    let poiMarker = null;
    let circle = null;
    if (this.state.showPOI) {
      this.clearPOIMarker();
    } else {
      this.setState({ showPOI: true, showDistance: false });
      this.clearDistanceMarker();
      this.clearAreaMarker();
      if (self.state.areaCenterMarker != null) {
        self.state.areaCenterMarker.setMap(null);
      }
    }

    this.setState({
      isMenuMapToolsCLick: "pushpin",
    });

    this.state.maps.event.addListener(this.state.map, "click", function(event) {
      if (self.state.showPOI) {
        if (self.state.poiMarker != [] && self.state.circle != null)
          self.state.circle.setMap(null);
        for (let i = 0; i < self.state.poiMarker.length; i++) {
          self.state.poiMarker[i].setMap(null);
        }
        self.state.poiMarker = [];

        let poiMarker = new self.state.maps.Marker({
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==",
          position: event.latLng,
          map: self.state.map,
        });

        circle = new self.state.maps.Circle({
          strokeColor: "#8415FF",
          strokeOpacity: 1,
          strokeWeight: 0.8,
          fillColor: "#8415FF",
          fillOpacity: 0.2,
          radius: parseInt(self.state.data.radius),
          map: self.state.map,
        });

        circle.bindTo("center", poiMarker, "position");

        self.state.poiMarker.push(poiMarker);
        self.setState({ circle: circle });

        let lat = parseFloat(event.latLng.lat().toFixed(6));
        let lng = parseFloat(event.latLng.lng().toFixed(6));

        self.setState({
          data: {
            ...self.state.data,
            latitude: lat,
            longitude: lng,
          },
        }, self.getLocationAddress(lat, lng));
      }
    });
  };
  // END Mark Point on Map

  getLocationAddress = (lat, lng) => {
    let self = this;
    if (lat === "" || lng === "") {
      this.setState({
        data: {
          ...this.state.data,
          address: "",
        },
      });
      return false;
    }

    fetch(
      `https://api.longdo.com/map/services/address?lon=${lng}&lat=${lat}&nopostcode=1&noelevation=1&noroad=1&noaoi=1&nowater=1&key=${this.props.auth.profile.longdo_map_key}`
    )
      .then((response) => response.json())
      .then((data) => {
        try {
          let subdistrict =
            typeof data.subdistrict !== "undefined" ? data.subdistrict : "";
          let district =
            typeof data.district !== "undefined" ? data.district : "";
          let province =
            typeof data.province !== "undefined" ? data.province : "";
          let postcode =
            typeof data.postcode !== "undefined" ? data.postcode : "";

          let address = `${subdistrict} ${district} ${province} ${postcode} ${data.country}`;

          self.setState({
            data: {
              ...self.state.data,
              subdistrict,
              district,
              province,
              address: address,
            },
          });
        } catch (ex) {
          self.setState({
            descriptionBoxDrawerWidth: "300px",
            descriptionBoxHeader: `Cannot get location Infomation`,
          });
          console.log(ex);
        }
      });
  };

  // MAP POLYGON

  ModalWarning() {
    Modal.warning({
      title: "กรุณาเลือกตำเเหน่ง...",
      content: "กรุณาเลือกตำเเหน่ง โดยการปักหมุด",
      okButtonProps: { "data-cy" : "btn-modal-ok" }
    });
    // this.clearDistanceMarker();
  }

  handleFindArea = (e) => {
    this.handleClearAllToolDrawing();
    if (this.state.showArea) {
      if (this.state.circle != null) {
        this.state.circle.setMap(null);
      }

      this.clearAreaMarker();
    } else {
      this.setState({ showArea: true, showPOI: false, areaPolyline: [] });
      this.clearCircle();
      this.clearDistanceMarker();
    }
    this.setState({
      isMenuMapToolsCLick: "findArea",
    });
    new this.state.maps.Polyline();

    let self = this;
    let polygonCoords = [];
    let polygonLayer = null;

    // if((this.state.data.latitude == "" && this.state.data.latitude != null) && (this.state.data.longitude == "" && this.state.data.longitude != null)){
    //     this.ModalWarning();
    // }

    this.state.maps.event.addListener(this.state.map, "click", function(event) {
      console.log(self.state.showPOI, self.state.showArea);

      if (self.state.showArea) {
        polygonCoords.push({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });

        self.state.geoFene.push(event.latLng.lat(), event.latLng.lng());

        // if (boundListpolygonCoords > 2) {
        if (polygonLayer != null) polygonLayer.setMap(null);
        polygonLayer = new self.state.maps.Polygon({
          path: polygonCoords,
          strokeColor: "#8415FF",
          strokeOpacity: 1,
          strokeWeight: 0.8,
          fillColor: "#8415FF",
          fillOpacity: 0.2,
          editable: true,
          geodesic: true,
        });

        self.state.maps.event.addListener(
          polygonLayer.getPath(),
          "remove_at",
          function() {
            self.state.geoFene = [];

            var curLatLng;
            curLatLng = polygonLayer.getPath().getArray();
            for (let i = 0; i < curLatLng.length; i++) {
              self.state.geoFene.push(curLatLng[i].lat(), curLatLng[i].lng());
            }
          }
        );

        self.state.maps.event.addListener(
          polygonLayer.getPath(),
          "set_at",
          function() {
            self.state.geoFene = [];

            var curLatLng;
            curLatLng = polygonLayer.getPath().getArray();
            for (let i = 0; i < curLatLng.length; i++) {
              self.state.geoFene.push(curLatLng[i].lat(), curLatLng[i].lng());
            }
          }
        );

        self.state.maps.event.addListener(
          polygonLayer.getPath(),
          "insert_at",
          function() {
            self.state.geoFene = [];

            var curLatLng;
            curLatLng = polygonLayer.getPath().getArray();
            for (let i = 0; i < curLatLng.length; i++) {
              self.state.geoFene.push(curLatLng[i].lat(), curLatLng[i].lng());
            }
          }
        );

        self.state.maps.event.addListener(
          polygonLayer.getPath(),
          "set_at",
          function() {
            subFnFindArea();
          }
        );

        subFnFindArea();
        function subFnFindArea() {
          let lengthInMeters = self.state.maps.geometry.spherical.computeArea(
            polygonLayer.getPath()
          );
          lengthInMeters > 0
            ? (lengthInMeters = (lengthInMeters / 1000).toFixed(2))
            : (lengthInMeters = lengthInMeters);

          polygonLayer.setMap(self.state.map);
          let vehicleAmount = 0;
          for (
            let i = 0;
            i < self.props.monitoringState.trackingList.length;
            i++
          ) {
            if (
              self.checkInsidePolygon(
                {
                  lat: self.props.monitoringState.trackingList[i].latest_lat,
                  lng: self.props.monitoringState.trackingList[i].latest_lng,
                },
                polygonCoords
              )
            ) {
              vehicleAmount++;
            }
          }

          self.setState({
            areaPolyline: [...self.state.areaPolyline, polygonLayer],
            polygonCoords: polygonCoords,
          });

          let bounds = new self.state.maps.LatLngBounds();

          for (let i = 0; i < self.state.polygonCoords.length; i++) {
            bounds.extend(self.state.polygonCoords[i]);
          }

          if (self.state.areaCenterMarker != null) {
            self.state.areaCenterMarker.setMap(null);
          }

          let areaCenterMarker = new self.state.maps.Marker({
            icon:
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAElklEQVRoge2aT6gVZRjGf8/lIiIuRERcRNCcjXfIEImWIfeARIvCVhEFroKWLQxahASJRIRJRkZk5MZLixCzoptzkBZCFnETnbPxDtKihchFREQuh/O0mJl75pz778yfIy16Lx/zLM687/N83zPvzDdzRcORtMMnjWcx+yTtBvq27wB/SeoEUfxPk/XURJKkHQI8b/weZhaYEmANijiFPWBe6P0giq81Ubu2gMXZcJvEZ4YjBaID0gbJGBVxX3AaczToxMt16tcSkLTDHcDPwHO4kG18fBl4OYjih1U5VBaw2A6nsX+ROOiMmIB1caHgCL4odDiI4n4VHtNVBQjeMRzEazK/b/Sj5JtGU8A+zIuIbcDoSryEeBM4U5FH+Uja4W7jRcz2IUsAQmeQ3w2i7r3iOYvtcBdwEvv1NVLelXgqiLoPynKZKntCxvWI0HZJrPxJgI4h3holD9CK4rvAGxKfSOnvC2OX0atVuFQSIDhse8XLtgGuSv4giOJ1z2tFMZijwI3iuZmjDlfhUlpAMjszbbw/971xbsSTQdTd9EIMOt0ecAo7F57neTZpz5SlU/4itrRLsBUYuoIMV0qk+TW13FDsJM37qAyf8hZyKnrUQsJLJbIsjVoo41J6QksLEH5g6KP0zpp2T2G0p0SaPThrXIM8y5ScfaiyAtJ94YfCSNnAgF8YN4Xxofy8Qp47QRT3ytIpLSC9YyoxeetX1kV0NGmHWzc7f7Edbse8nV6/mYQU3yrLBareB+zrIPBKcWz22v48aYfr5kza4RbwN0hP5MLTEMCNKlwqCQB+H7VQijmC+SmZndmbPWLnxElmZ/ZjIsErK78vWMjwWxUilZ6FBFcHD2TCK2YSlg8BNwULSTuMnU7S00bPgLPfAzZSjkH46mMTgLQALGF2DnWSAZ4yHEAcwNnGJtvhFG2zguXE6HYVKpUsFERxT3B5tYXWwWyC0Xxrg0eQxgWk4R+KXWhdvLrbrMLA91VZVBZgdAmrlxMpdqQhzDq2GeD7QKcqj8oCWlG8JNGpbSFzKYji0nfg2gKyOF/XQuDzdQjUE2BfMH6Ys7azVlTEkLVZr4XvAPN1KNQSEHS692RdWGOHNTwKu7ZhzFzQ6dZ6rVLXQhi+Suc0n9URnG1a7GGcHvV13fq1BUi+Ar41sI0ZwmSCNIyNr0ks1K1fW0AQdftCX5a2EPpio/3zYxMAYHzW+FGKx7GQl4C5Jmo3IqAVde8K5sa1kKSzdV4nFqMRAQBGp8a0UA/4tKm6jQkALwCdMSz0XRDFfzdVtTEBragL8NGmFrI/bqomNLoCYDwv6foGFroiqZEPG3k0KqCVvpn7cAMLnWiidRajUQFZfItJVlkI/pBU67lnrWhcQBDFPUknVlvIx5uefZjMCgCcA24XLHQddHEShSYiIIjiZeD4wEIcCzrVPiFtFpNaAYBzkm5J+lMwkdmfeCTt8LWkHY79zrRKVP7IN2bMGSZinf/jvxKN/K8EsCUb09nIm0N+7BeOvWwsZ6NWNCFgK7CD8h2tD9yjwleZYjS1ApCuQP6da6MVyFeh9uwD/Asypo/TAASupgAAAABJRU5ErkJggg==",
            position: bounds.getCenter(),
            map: self.state.map,
          });
          self.setState({
            areaCenterMarker: areaCenterMarker,
            data: {
              ...self.state.data,
              latitude: parseFloat(
                bounds
                  .getCenter()
                  .lat()
                  .toFixed(6)
              ),
              longitude: parseFloat(
                bounds
                  .getCenter()
                  .lng()
                  .toFixed(6)
              ),
            },
          });
        }
      }
    });
  };
  // END MAP POLYGON

  // Clear Map
  handleClearAllToolDrawing = (clearPlaceMarker = false) => {
    this.setState({
      isMenuMapToolsCLick: "",
      visibleSearchTooltip: false
    });
    
    this.clearPOIMarker();
    this.clearDistanceMarker();
    this.clearAreaMarker();

    if (clearPlaceMarker === true) {
      this.clearPlaceMarker();
      this.setState({
        suggest_place: "",
      });
    }

    if (this.state.areaCenterMarker != null) {
      this.state.areaCenterMarker.setMap(null);
    }
  };

  clearCircle = () => {
    if (this.state.circle != null) {
      this.state.circle.setMap(null);
    }
  };

  clearPOIMarker = () => {
    if (this.state.poiMarker != []) {
      for (let i = 0; i < this.state.poiMarker.length; i++) {
        this.state.poiMarker[i].setMap(null);
      }
      this.state.poiMarker = [];

      this.handelDescriptionBoxDrawerClose();
    }

    if (this.state.circle != null) {
      this.state.circle.setMap(null);
    }

    this.setState({
      showPOI: false,
      circle: null,
      descriptionBoxHeader: "",
      descriptionBoxDetail: "",
      data: {
        ...this.state.data,
        latitude: "",
        longitude: "",
        radius: 100,
      },
    });
    this.state.maps.event.clearListeners(this.state.map, "click");
  };

  clearDistanceMarker = () => {
    if (this.state.distanceMarker != []) {
      this.state.distanceMarker.map((value, index) => {
        value.setMap(null);
      });
    }

    if (this.state.distancePolyline != []) {
      this.state.distancePolyline.map((value, index) => {
        value.setMap(null);
      });
    }

    this.handelDescriptionBoxDrawerClose();
    this.state.maps.event.clearListeners(this.state.map, "click");

    this.setState({
      showDistance: false,
      distanceMarker: [],
      distancePolyline: [],
      descriptionBoxHeader: "",
      descriptionBoxDetail: "",
    });
  };

  clearAreaMarker = () => {
    if (this.state.areaPolyline != []) {
      this.state.areaPolyline.map((value, index) => {
        value.setMap(null);
      });
    }

    this.handelDescriptionBoxDrawerClose();
    this.state.maps.event.clearListeners(this.state.map, "click");

    this.setState({
      showArea: false,
      areaPolyline: [],
      geoFene: [],
      descriptionBoxHeader: "",
      descriptionBoxDetail: "",
    });
  };

  clearPlaceMarker = () => {
    this.setState({
      placeMarker: [],
      geoFene: [],
    });
  };
  // END Clear Map

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState({
      mapOptions: {
        center,
        zoom,
        bounds,
      },
    });
  };

  getClusters = (trackingList) => {
    const clusters = supercluster(trackingList, {
      minZoom: 0,
      maxZoom: 13,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  handleApiLoaded = (map, maps) => {
    if (typeof maps == "undefined" || maps == null) window.location.reload();

    this.setState(
      {
        map: map,
        maps: maps,
        mapAPI: { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() },
      },
      this.handlePinPointFormTracking
    );
  };

  handelDescriptionBoxDrawerClose = () => {
    this.setState({
      descriptionBoxDrawerWidth: "0px",
    });
  };

  handleMapTypeSatellite = (e) => {
    this.setState({
      isMenuMapTypeClick: "satellite",
    });
    this.state.map.setMapTypeId("satellite");
  };

  handleMapTypeTerrain = (e) => {
    this.setState({
      isMenuMapTypeClick: "terrain",
    });
    this.state.map.setMapTypeId("terrain");
  };

  // เเสดงการจราจร
  handleShowTrafficLayer = (e) => {
    this.setState({
      isShowTrafficClick: !this.state.isShowTrafficClick,
    });
    if (this.state.showTrafficLayer) {
      this.state.mapAPI.trafficLayer.setMap(null);
      this.setState({
        showTrafficLayer: false,
      });
    } else {
      this.state.mapAPI.trafficLayer.setMap(this.state.map);
      this.setState({
        showTrafficLayer: true,
      });
    }
  };
  // เเสดงการจราจร

  handleZoomFocusIncrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel++;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handleZoomFocusDecrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel--;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  showConfirm = (values) => {
    let self = this;

    this.setState({
      loading: true,
    });
    self.addArea(values);
  };

  addArea = (values) => {
    let self = this;

    const ACCESSTOKEN = localStorage.getItem("access-token");
    let province = this.state.data.province.replace("จ.", "")
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getproviceidbyname`,
        {
          province: province,
        },
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.province_id !== "undefined") {
          self.setState({
            data: {
              latitude: "",
              longitude: "",
              address: "",
            },
            loading: false,
          });
          self.props.addArea(
            values.latitude,
            values.longitude,
            response.data.province_id
          );
          self.componentDidMount();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  goBack() {
    this.props.history.goBack();
  }

  onPick(image) {
    this.setState({ image });
  }

  // Modal
  handleBackToUseImagesByLocationType = () => {
    let record = this.state.icon_for_location_type.find((ele) => {
      return ele.type_name == this.state.data.typeLocation;
    });

    this.setState({
      data: {
        ...this.state.data,
        icon_default_normal_status_id: record.gallery_id,
        icon_default_normal_status_name: record.icon_name,
        icon_default_normal_status_url: record.image_base64,
      },
    });
  };

  handleOpenModal = () => {
    let value = [
      {
        id: this.state.icon_for_location_type_selected
          .icon_default_normal_status_id,
        image_base64: "",
        name: this.state.icon_for_location_type_selected
          .icon_default_normal_status_name,
        path: this.state.icon_for_location_type_selected
          .icon_default_normal_status_url,
      },
    ].concat(this.state.icon_for_select);

    this.setState({
      icon_for_display_in_modal: value,
      visible: true,
    });
  };

  handleCancelModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleSelectIcon = (value) => {
    this.setState({
      data: {
        ...this.state.data,
        icon_default_normal_status_id: value.id,
        icon_default_normal_status_name: value.name,
        icon_default_normal_status_url: value.path,
      },
      visible: false,
    });
  };
  // End Modal

  handleGoToSearch = () => {
    this.setState({ visibleSearchTooltip: true })
    this.inputElement.focus()
  }

  render() {
    const mapType = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={this.handleMapTypeSatellite}
          style={{
            color:
              this.state.isMenuMapTypeClick === "satellite"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <LangContext.Consumer>
            {(i18n) => i18n.s.satellite}
          </LangContext.Consumer>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={this.handleMapTypeTerrain}
          style={{
            color:
              this.state.isMenuMapTypeClick === "terrain"
                ? "rgb(22, 146, 255)"
                : "",
          }}
        >
          <LangContext.Consumer>{(i18n) => i18n.m.map}</LangContext.Consumer>
        </Menu.Item>
      </Menu>
    );

    const style = {
      width: "50vw",
      height: "75vh",
      marginLeft: "auto",
      marginRight: "auto",
    };

    let destinationPlaceArr = [];

    return (
      <AuthorizeComponent matching_name="vehicle">
        <TrackingStyledContent>
          <Spin spinning={this.state.loading}>
            <Row>
              <Col
                sm={24}
                md={11}
                lg={11}
                xl={9}
                style={{
                  margin: "0px 0px 0px 0px",
                  maxHeight: `${window.innerHeight - 54}px`,
                  overflowY: "auto",
                }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  onChange={(e) =>
                    this.setState({
                      detailHeight:
                        e.length == 1 && e[0] == "2"
                          ? window.innerHeight / 1.4
                          : window.innerHeight / 2.9,
                    })
                  }
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel
                    header={
                      <LangContext.Consumer>
                        {(i18n) => i18n.l.locationInformation}
                      </LangContext.Consumer>
                    }
                    key="1"
                    style={customPanelStyle}
                  >
                    <LangContext.Consumer>
                      {(i18n) => (
                        <Formik
                          enableReinitialize={true}
                          initialValues={this.state.data}
                          validationSchema={yup.object().shape({
                            latitude: yup
                              .string()
                              .required(
                                i18n.l.latitudeIsRequiredAndCantBeEmpty
                              ),
                            longitude: yup
                              .string()
                              .required(
                                i18n.l.LongitudeIsRequiredAndCantBeEmpty
                              ),
                          })}
                          onSubmit={(values, actions) => {
                            this.showConfirm(values);
                          }}
                          render={({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Row gutter={[16, 16]} type="flex" align="bottom">
                              <Col span={10}>
                                <Form.Item
                                  label={
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.l.latitude}
                                    </LangContext.Consumer>
                                  }
                                  style={{ marginBottom: "0px" }}
                                  required={true}
                                  validateStatus={
                                    touched.latitude && errors.latitude
                                      ? "error"
                                      : undefined
                                  }
                                  help={
                                    touched.latitude && errors.latitude
                                      ? errors.latitude.substr(
                                          errors.latitude.indexOf(" ") + 1
                                        )
                                      : undefined
                                  }
                                >
                                  <Input
                                    type="Text"
                                    name="latitude"
                                    value={values.latitude}
                                    // style={{ width: "100%"}}
                                    onChange={(e) => {
                                      setFieldValue("latitude", e.target.value);
                                      if (e.target.value == "") {
                                        this.setState({
                                          data: {
                                            ...this.state.data,
                                            latitude: "",
                                          },
                                        });
                                      }
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          latitude: e.target.value,
                                        },
                                      });
                                    }}
                                    autoComplete="off"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  label={
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.l.longitude}
                                    </LangContext.Consumer>
                                  }
                                  style={{ marginBottom: "0px" }}
                                  required={true}
                                  validateStatus={
                                    touched.longitude && errors.longitude
                                      ? "error"
                                      : undefined
                                  }
                                  help={
                                    touched.longitude && errors.longitude
                                      ? errors.longitude.substr(
                                          errors.longitude.indexOf(" ") + 1
                                        )
                                      : undefined
                                  }
                                >
                                  <Input
                                    type="Text"
                                    name="longitude"
                                    value={values.longitude}
                                    // style={{ width: "100%"}}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "longitude",
                                        e.target.value
                                      );
                                      if (e.target.value == "") {
                                        this.setState({
                                          data: {
                                            ...this.state.data,
                                            longitude: "",
                                          },
                                        });
                                      }
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          longitude: e.target.value,
                                        },
                                      });
                                    }}
                                    autoComplete="off"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={2} style={{ paddingBottom: "12px" }}>
                                <Button icon="pushpin" onClick={this.handleGoToSearch} />
                              </Col>
                            </Row>

                              <Form.Item
                                label={
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.a.address}
                                  </LangContext.Consumer>
                                }
                                style={{ marginBottom: "0px" }}
                              >
                                <TextArea
                                  rows={3}
                                  name="address"
                                  onChange={(e) => {
                                    setFieldValue("address", e.target.value);
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        address: e.target.value,
                                      },
                                    });
                                  }}
                                  autoComplete="off"
                                  value={values.address}
                                />
                              </Form.Item>

                              <Form.Item style={{ marginTop: "10px" }}>
                                <Button type="primary" htmlType="submit">
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.s.save}
                                  </LangContext.Consumer>
                                </Button>
                              </Form.Item>
                            </Form>
                          )}
                        />
                      )}
                    </LangContext.Consumer>
                  </Panel>
                </Collapse>
              </Col>
              <Col sm={24} md={13} lg={13} xl={15}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: "4px",
                    top: "0px",
                    right: "0px",
                    zIndex: "12",
                  }}
                >
                <LangContext.Consumer>
                  {(i18n) => {
                    return (
                      <Tooltip
                      placement="left" 
                      className={this.state.visibleSearchTooltip && "shake-vertical"}
                      title={i18n.l.lookingForPin} 
                      visible={this.state.visibleSearchTooltip}>
                        <AutoComplete
                          value={this.state.suggest_place}
                          className="edge-element"
                          style={{
                            width: "200px",
                            margin: "0px 15px 0px 0px",
                          }}
                          placeholder={i18n.l.lookingForSomething}
                          onSelect={this.handleSearchPlace}
                          onSearch={this.handleSuggestPlace}
                          ref={(input) => (this.inputElement = input)}
                        >
                          {this.renderOptions()}
                        </AutoComplete>
                      </Tooltip>
                    );
                  }}
                </LangContext.Consumer>

                  <ButtonGroup
                    style={{
                      marginRight: "4px",
                    }}
                  >
                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.pinDown}
                        </LangContext.Consumer>
                      }
                    >
                      <Button
                        onClick={this.handlePinPointOfInterest}
                        style={{
                          backgroundColor:
                            this.state.isMenuMapToolsCLick === "pushpin"
                              ? "rgb(22, 146, 255)"
                              : "",
                          color:
                            this.state.isMenuMapToolsCLick === "pushpin"
                              ? "rgb(255, 255, 255)"
                              : "",
                        }}
                      >
                        <Icon type="pushpin" />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.draw}
                        </LangContext.Consumer>
                      }
                    >
                      <Button
                        onClick={this.handleFindArea}
                        style={{
                          backgroundColor:
                            this.state.isMenuMapToolsCLick === "findArea"
                              ? "rgb(22, 146, 255)"
                              : "",
                          color:
                            this.state.isMenuMapToolsCLick === "findArea"
                              ? "rgb(255, 255, 255)"
                              : "",
                        }}
                      >
                        <Icon type="yuque" />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.c.clearUp}
                        </LangContext.Consumer>
                      }
                    >
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => this.handleClearAllToolDrawing(true)}
                      >
                        <Icon type="close" />
                      </Button>
                    </Tooltip>

                    <Dropdown className="edge-element" overlay={mapType}>
                      <Button>
                        <LangContext.Consumer>
                          {(i18n) => i18n.m.mapView}
                        </LangContext.Consumer>
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </ButtonGroup>

                  <div
                    className="edge-btn-group"
                    style={{
                      position: "absolute",
                      marginTop: "20%",
                      right: "0px",
                      zIndex: "12",
                    }}
                  >
                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.showTraffic}
                        </LangContext.Consumer>
                      }
                    >
                      <button
                        onClick={this.handleShowTrafficLayer}
                        style={{
                          backgroundColor: this.state.isShowTrafficClick
                            ? "rgb(22, 146, 255)"
                            : "",
                          color: this.state.isShowTrafficClick
                            ? "rgb(255, 255, 255)"
                            : "",
                        }}
                      >
                        <Icon type="swap" />
                      </button>
                    </Tooltip>

                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.z.zoomIn}
                        </LangContext.Consumer>
                      }
                    >
                      <button onClick={this.handleZoomFocusIncrease}>
                        <Icon type="zoom-in" />
                      </button>
                    </Tooltip>

                    <Tooltip
                      placement="left"
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.z.zoomOut}
                        </LangContext.Consumer>
                      }
                    >
                      <button onClick={this.handleZoomFocusDecrease}>
                        <Icon type="zoom-out" />
                      </button>
                    </Tooltip>
                  </div>
                </div>

                <CustomMap
                  loadedDateTime={this.props.monitoringState.loadedDateTime}
                  bootstrapURLKeys={{
                    key: this.props.auth.profile.google_map_key,
                    libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                      ","
                    ),
                    region: "thailand",
                    language: "th",
                  }}
                  defaultCenter={this.state.mapOptions.center}
                  defaultZoom={this.state.mapOptions.zoom}
                  style={{
                    width: "100%",
                    minHeight: `${window.innerHeight - 50}px`,
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) =>
                    this.handleApiLoaded(map, maps)
                  }
                  options={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                    maxZoom: 19,
                  }}
                  onChange={this.handleMapChange}
                >
                  {this.state.placeMarker &&
                    this.state.placeMarker.map((value) => value)}
                </CustomMap>
              </Col>
            </Row>
          </Spin>
        </TrackingStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ monitoringState, auth, auth: { profile } }) => ({
    monitoringState,
    auth,
    profile,
  }), {
    removeLists
  }
)(AddFacilityVehicle);

import {
  REMOVE_CREDENTIALS,
  CHECK_LOGIN,
  removeCredentials,
  LOAD_CREDENTIALS,
  loadCredentials,
  SET_CONFIG_TRACKING_COLUMNS,
  SET_CONFIG_HOME_DASHBOARD_WIDGET,
  SET_LOADING,
  LOAD_PROVINCE,
  LOAD_AMPHUR,
  LOAD_SUBAMPHUR,
  CHECK_CONTACT_SIGNUP,
} from "./actions";

const initialState = {
  tokenNoti: null,
  tokenExp: null,
  accessToken: null,
  profile: null,
  done: false,
  status: "",
  msg: "",
  loading: false,
  provinceLists: [],
  amphurLists: [],
  subamphurLists: [],
  path_signup: "",
  check_contact: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CREDENTIALS:
      const {
        payload: { token, tokenExp, profile, done, msg },
      } = action;
      let {
        company_id,
        company_name,
        id,
        is_admin,
        is_super,
        username,
        firstname,
        lastname,
        phone,
        vehicle_visibility,
        location_visibility,
        vehicle_group_visibility,
        permissions,
        config,
        google_map_key,
        longdo_map_key,
      } = profile;

      permissions = JSON.parse(permissions || "{}");
      config = JSON.parse(config || "{}");

      return {
        ...initialState,
        tokenExp: tokenExp,
        accessToken: token,
        profile: {
          ...profile,
          permissions,
          config,
        },
        done: done,
        msg: msg,
      };
    case REMOVE_CREDENTIALS:
      removeCredentials();
      return initialState;
    case CHECK_LOGIN.REQUEST: {
      return {
        loading: true,
      };
    }
    case CHECK_LOGIN.SUCCESS: {
      let {
        payload: {
          token,
          tokenPayload,
          permissions,
          vehicle_list,
          location_list,
          vehicle_group_list,
          status,
          msg,
        },
      } = action;

      let {
        exp,
        company_id,
        company_name,
        id,
        is_admin,
        is_super,
        username,
        firstname,
        lastname,
        phone,
        // vehicle_visibility,
        // location_visibility,
        // permissions,
        config,
        google_map_key,
        longdo_map_key,
      } = tokenPayload;

      permissions = JSON.parse(permissions || "{}");
      config = JSON.parse(config || "{}");
      return {
        accessToken: token,
        tokenExp: exp,
        profile: {
          ...tokenPayload,
          vehicle_visibility: vehicle_list,
          location_visibility: location_list,
          vehicle_group_visibility: vehicle_group_list,
          permissions,
          config,
        },
        done: true,
        msg: msg,
        status: status,
        loading: false,
      };
    }
    case CHECK_LOGIN.FAILURE: {
      const {
        payload: { status, msg, path_signup },
      } = action;

      return {
        ...initialState,
        msg: msg,
        status: status,
        loading: false,
        path_signup,
      };
    }
    case SET_LOADING: {
      const {
        payload: { loading },
      } = action;
      return {
        ...state,
        loading,
      };
    }
    case CHECK_CONTACT_SIGNUP: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        check_contact: value,
      };
    }
    case SET_CONFIG_TRACKING_COLUMNS:
      const { payload: columns } = action;
      console.log(columns);
      config = JSON.parse(config || "{}");

      return {
        ...state,
        profile: {
          ...state.profile,
          config: {
            ...state.profile.config,
            menu: {
              ...state.profile.config.menu,
              tracking: {
                ...state.profile.config.menu.tracking,
                columns,
              },
            },
          },
        },
      };

    case SET_CONFIG_HOME_DASHBOARD_WIDGET:
      const { payload: layout } = action;
      config = JSON.parse(config || "{}");

      if (state.done)
        return {
          ...state,
          profile: {
            ...state.profile,
            config: {
              ...state.profile.config,
              home_dashboard: {
                ...state.profile.config.home_dashboard,
                layout,
              },
            },
          },
        };
      else return initialState;
    case LOAD_PROVINCE.SUCCESS:
      return {
        ...state,
        provinceLists: action.payload.ProvinceLists.data,
      };

    case LOAD_AMPHUR.SUCCESS:
      return {
        ...state,
        amphurLists: action.payload.AmphurLists.data,
      };
    case LOAD_SUBAMPHUR.SUCCESS:
      return {
        ...state,
        subamphurLists: action.payload.SubAmphurLists.data,
      };
    default:
      return state;
  }
};

import {
  LOAD_TRANSPORT_WORKSHEET_COMPANY,
} from "./actions";

const initialState = {
  lists: [],
  totalRows: 0,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSPORT_WORKSHEET_COMPANY.REQUEST:
      return {
        ...state,
        success: null,
        loading: true,
      };
    case LOAD_TRANSPORT_WORKSHEET_COMPANY.SUCCESS:
      return {
        lists: action.payload.lists.data,
        totalRows: action.payload.lists.total,
        loading: false,
      };
    case LOAD_TRANSPORT_WORKSHEET_COMPANY.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import AssignJobForm from "./AssignJobForm"

export default () => (
    <Switch>
        <Route exact path="/dashboard/work-quotation" component={Layout} />
        <Route exact path="/dashboard/work-quotation/view/:worksheet_id/:qt_id/:vehicle_type_id" component={AssignJobForm} />
    </Switch>
);
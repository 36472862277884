import React from "react";
import { Row, Col } from "antd";

const PlaceMarker = ({ img, title, lat, lng }) => {
  return (
    <Row>
      <Col span={24}>
        {title !== "" && (
          <div>
            <div
              style={{
                zIndex: -2,
                width: "160px",
                borderRadius: "1px",
                padding: "7px 15px 7px 15px",
                display: "inline-flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                transform: "translate(-50%, -180%)",
                color: "#fff",
                backgroundColor: "#ff4d4d",
                position: "relative",
                left: 0,
                top: 18,
                bottom: 0,
                minHeight: "35px",
                border: "2px solid #f3abab",
                fontSize: 11
              }}
            >
              <span>{title}</span>
              <div
                style={{
                  position: "absolute",
                  left: 70,
                  bottom: -15,
                  zIndex: -2,
                  width: 0,
                  height: 0,
                  borderLeft: "7px solid transparent",
                  borderRight: "7px solid transparent",
                  borderTop: "12px solid #ff4d4d"
                }}
              />
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default PlaceMarker;

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadMasterVehicle(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      vehicle_visibility,
      filterObj,
      permis_super,
      permis_add,
      permis_edit,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicle`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        vehicle_visibility,
        filterObj,
        permis_super,
        permis_add,
        permis_edit,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadMasterVehicle.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadMasterVehicle.failure());
  }
}

function* loadDriverFormapping(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
      plant,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getdriverformapping`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
        plant,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDriverFormapping.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDriverFormapping.failure());
  }
}

function* loadVehicle(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicle`,
      // `http://booking-api/api/customer`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadVehicle.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicle.failure());
  }
}

function* loadAutocompleteLocation(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteLocation.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteLocation.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteLocation.failure());
  }
}

function* selectedAutocompleteLocation(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteLocation.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteLocation.failure());
  }
}

function* loadAutocompleteDrivername(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDrivername.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDrivername.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDrivername.failure());
  }
}

function* selectedAutocompleteDriverName(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriverName.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriverName.failure());
  }
}

function* loadAutocompleteDriver1(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDriver1.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDriver1.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDriver1.failure());
  }
}

function* selectedAutocompleteDriver1(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriver1.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriver1.failure());
  }
}

function* loadAutocompleteDriver2(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDriver2.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDriver2.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDriver2.failure());
  }
}

function* selectedAutocompleteDriver2(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriver2.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriver2.failure());
  }
}

function* loadAutocompleteDriverCode(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteDriverCode.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteDriverCode.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteDriverCode.failure());
  }
}

function* selectedAutocompleteDriverCode(action) {
  const { id, autoCompleteLists } = action.payload;
  try {
    let selected = autoCompleteLists.filter(function(ele) {
      return ele.id == id;
    })[0];

    yield put(actions.selectedAutocompleteDriverCode.success(selected));
  } catch (err) {
    console.log(err.message);
    yield put(actions.selectedAutocompleteDriverCode.failure());
  }
}

function* createVehicle(action) {
  const {
    company_id,
    user_id,
    vehicle_type,
    plate_no,
    plate_no_province,
    expire_date,
    expire_act_date,
    start_province,
    end_province,
    start_lat,
    start_lng,
    end_lat,
    end_lng,
    filefrontvehicle,
    image_vehicle,
    brand,
    model,
    year,
    color,
    accessToken,
  } = action.payload.data;
  const { loading } = action.payload;

  const formData = new FormData();
  filefrontvehicle.forEach((File) => {
    formData.append("filefrontvehicle", File);
  });

  image_vehicle.forEach((File) => {
    formData.append("image_vehicle", File);
  });

  formData.append("company_id", company_id);
  formData.append("user_id", user_id);
  formData.append("vehicle_type", vehicle_type);
  formData.append("plate_no", plate_no);
  formData.append("plate_no_province", plate_no_province);
  formData.append("brand", brand);
  formData.append("model", model);
  formData.append("year", year);
  formData.append("color", color);
  formData.append("expire_date", expire_date);
  formData.append("expire_act_date", expire_act_date);
  formData.append("start_province", start_province);
  formData.append("end_province", end_province);
  formData.append("start_lat", start_lat);
  formData.append("start_lng", start_lng);
  formData.append("end_lat", end_lat);
  formData.append("end_lng", end_lng);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/createvehicle`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.createVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createVehicle.failure());
  }
}

function* matchVehicle(action) {
  const {
    company_id,
    vehicles_id_defalse,
    driver_id_defalse,
    vehicles_id,
    driver_id,
    accessToken,
  } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/matchtruckdriver`,
      {
        company_id,
        vehicles_id_defalse,
        driver_id_defalse,
        vehicles_id,
        driver_id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.matchVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.matchVehicle.failure());
  }
}

function* unmatchVehicle(action) {
  const {
    company_id,
    vehicles_id,
    driver_id,
    vehicles_id_defalse,
    driver_id_defalse,
    checkUnmatch,
    accessToken,
  } = action.payload.data;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/unmatchtruckdriver`,
      {
        company_id,
        vehicles_id_defalse,
        driver_id_defalse,
        vehicles_id,
        driver_id,
        checkUnmatch,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.unmatchVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.unmatchVehicle.failure());
  }
}

function* loadVehicleForEdit(action) {
  const {
    data: { id, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleforedit/${id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    yield put(actions.loadVehicleForEdit.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehicleForEdit.failure());
  }
}

function* loadDriverHistory(action) {
  const {
    data: { vehicle_id, page, pageSize, orderBy, orderType, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/getdriverhistory`,
      {
        vehicle_id,
        page,
        pageSize,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDriverHistory.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDriverHistory.failure());
  }
}

function* loadVehiclePart(action) {
  const {
    data: { vehicle_id, page, pageSize, orderBy, orderType, accessToken },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/getpart`,
      {
        vehicle_id,
        page,
        pageSize,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadVehiclePart.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadVehiclePart.failure());
  }
}

function* updateMasterDataVehicle(action) {
  const {
    data: {
      vehicles_id,
      company_id,
      user_id,
      vehicle_type,
      plate_no,
      plate_no_province,
      expire_date,
      expire_act_date,
      start_province,
      end_province,
      start_lat,
      start_lng,
      end_lat,
      end_lng,
      filefrontvehicle,
      image_vehicle,
      brand,
      model,
      year,
      color,
      change_file_front_vehicle,
      change_file_vehicle,
      accessToken,
    },
    loading,
  } = action.payload;

  const formData = new FormData();
  if (change_file_front_vehicle === true) {
    filefrontvehicle.forEach((File) => {
      formData.append("filefrontvehicle", File);
    });
  } else {
    formData.append("filefrontvehicle", null);
  }

  if (change_file_vehicle === true) {
    image_vehicle.forEach((File) => {
      formData.append("image_vehicle", File);
    });
  } else {
    formData.append("image_vehicle", null);
  }

  formData.append("vehicles_id", vehicles_id);
  formData.append("company_id", company_id);
  formData.append("user_id", user_id);
  formData.append("vehicle_type", vehicle_type);
  formData.append("plate_no", plate_no);
  formData.append("plate_no_province", plate_no_province);
  formData.append("brand", brand);
  formData.append("model", model);
  formData.append("year", year);
  formData.append("color", color);
  formData.append("expire_date", expire_date);
  formData.append("expire_act_date", expire_act_date);
  formData.append("start_province", start_province);
  formData.append("end_province", end_province);
  formData.append("start_lat", start_lat);
  formData.append("start_lng", start_lng);
  formData.append("end_lat", end_lat);
  formData.append("end_lng", end_lng);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/vehicle/edit`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateMasterDataVehicle.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateMasterDataVehicle.failure());
  }
}

export default function* watchVehicle() {
  yield all([
    takeEvery(actions.LOAD_MASTERDATA_VEHICLE.REQUEST, loadMasterVehicle),
    takeEvery(actions.LOAD_MASTERDATA_DRIVER.REQUEST, loadDriverFormapping),
    takeEvery(actions.LOAD_VEHICLE.REQUEST, loadVehicle),
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE.REQUEST,
      selectedAutocompleteLocation
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER_NAME.REQUEST,
      loadAutocompleteDrivername
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER_NAME.REQUEST,
      selectedAutocompleteDriverName
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER1.REQUEST,
      loadAutocompleteDriver1
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER1.REQUEST,
      selectedAutocompleteDriver1
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER2.REQUEST,
      loadAutocompleteDriver2
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER2.REQUEST,
      selectedAutocompleteDriver2
    ),
    takeEvery(
      actions.LOAD_AUTOCOMPLETE_DRIVER_CODE.REQUEST,
      loadAutocompleteDriverCode
    ),
    takeEvery(
      actions.SELECTED_AUTOCOMPLETE_DRIVER_CODE.REQUEST,
      selectedAutocompleteDriverCode
    ),
    takeEvery(actions.CREATE_VEHICLE.REQUEST, createVehicle),
    takeEvery(actions.MATCH_VEHICLE.REQUEST, matchVehicle),
    takeEvery(actions.UNMATCH_VEHICLE.REQUEST, unmatchVehicle),
    takeEvery(actions.LOAD_VEHICLE_FOR_EDIT.REQUEST, loadVehicleForEdit),
    takeEvery(
      actions.UPDATE_MASTERDATA_VEHICLE.REQUEST,
      updateMasterDataVehicle
    ),
    takeEvery(actions.LOAD_DRIVER_HISTORY.REQUEST, loadDriverHistory),
    takeEvery(actions.LOAD_VEHICLE_PART.REQUEST, loadVehiclePart),
  ]);
}

export {
  loadVehicle,
  loadAutocompleteLocation,
  selectedAutocompleteLocation,
  loadAutocompleteDrivername,
  selectedAutocompleteDriverName,
  loadAutocompleteDriver1,
  selectedAutocompleteDriver1,
  loadAutocompleteDriver2,
  selectedAutocompleteDriver2,
  loadAutocompleteDriverCode,
  selectedAutocompleteDriverCode,
  createVehicle,
  loadMasterVehicle,
  loadDriverFormapping,
  matchVehicle,
  unmatchVehicle,
  loadVehicleForEdit,
  updateMasterDataVehicle,
  loadDriverHistory,
  loadVehiclePart,
};

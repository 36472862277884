import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";

import {
  StyledDiv,
  StyledRow,
  StyledCircleStep,
  StyledStepFloat,
} from "../../../styled/common-styled";
import { firebase } from "../../../lib/fcm/fcm_init";
import VerifyForm from "./VerifyForm";
import { setLoading } from "../actions";
import LangContext from "modules/shared/context/langContext";

class Verify extends Component {
  state = {
    confirmResult: "",
    valueSignUp: {
      email: "",
      phone: "",
      password: "",
      trypassword: "",
      company_type: ""
    },
    user_type: "",
    by: "",
    recaptcha_solve: false,
    step: 3,
  };

  componentDidMount() {
    console.log(this.props.location)
    if (this.props.location.state === undefined) {
        window.location = "/";
    } else {
      this.setState({
        valueSignUp: this.props.location.state.values,
        user_type: this.props.location.state.values.company_type == 4 ? "personal" : this.props.match.params.usertype,
        company_type:this.props.location.state.values.company_type,
        by: this.props.match.params.by,
        step:
          this.props.match.params.usertype !== "productowner" ? 4 : 3,
      });
    }
  }

  onSubmitForgot = (values) => {
    let self = this;
    let port = window.location.port !== "" ? ":" + window.location.port : "";
    if (values.button_type === "confirm") {
      self.props.setLoading(true);
      let pathhost =
        window.location.protocol + "//" + window.location.hostname + port;

      if (values.verifytype === "email") {
        var actionCodeSettings = {
          // After password reset, the user will be give the ability to go back
          // to this page.
          url: pathhost,
          handleCodeInApp: false,
        };
        firebase
          .auth()
          .sendPasswordResetEmail(
            self.state.valueSignUp.email,
            actionCodeSettings
          )
          .then(function() {
            Modal.info({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.s.send_email_forgotpassword}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });

            self.props.setLoading(false);
          })
          .catch(function(error) {
            console.log("error:", error);
            self.props.setLoading(false);
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.s.send_email_forgotpassword_error}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          });
      } else {
        window.confirmationResult
          .confirm(values.verifycode)
          .then(function(result) {
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then(function(idToken) {
                // Send token to your backend via HTTPS
                self.updatePasswordByPhone(idToken, values.password);
              })
              .catch(function(error) {
                Modal.error({
                  content: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.signup.cannot_reset_password}
                    </LangContext.Consumer>
                  ),
                  okText: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.o.ok}
                    </LangContext.Consumer>
                  ),
                  okButtonProps: { "data-cy" : "btn-modal-ok" }
                });
                self.props.setLoading(false);
              });
            // ...
          })
          .catch(function(error) {
            // User couldn't sign in (bad verification code?)

            if (error.code === "auth/invalid-verification-code") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.invalid_verification_code}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else if (error.code === "auth/missing-verification-code") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.invalid_verification_code}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            }
            //console.log("error confirm2:", error.code);
            self.props.setLoading(false);
          });
      }
    } else {
      const phoneNumber = "+66" + self.state.valueSignUp.phone;
      const appVerifier = window.recaptchaVerifier;
      if (this.state.recaptcha_solve) {
        self.props.setLoading(true);
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmResult) => {
            console.log("confirmresult:", confirmResult);
            window.confirmationResult = confirmResult;
            self.props.setLoading(false);
            this.setState({
              recaptcha_solve: false,
            });
            Modal.info({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.s.send_verify_code}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
            // success
          })
          .catch((error) => {
            window.recaptchaVerifier.clear();
            //console.log("captcha:", error);
            this.setState({
              recaptcha_solve: false,
            });
            self.props.setLoading(false);

            if (error.code === "auth/too-many-requests") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.otp_too_many_requests}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.cannot_send_otp}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            }
          });
      } else {
        Modal.error({
          content: (
            <LangContext.Consumer>
              {(i18n) => i18n.c.captcha_require}
            </LangContext.Consumer>
          ),
          okText: (
            <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
          ),
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
      }
    }
  };

  onSubmit = (values) => {
    let self = this;
    let port = window.location.port !== "" ? ":" + window.location.port : "";
    if (values.button_type === "confirm") {
      self.props.setLoading(true);
      let pathhost =
        window.location.protocol + "//" + window.location.hostname + port;

      if (values.verifytype === "email") {
        firebase
          .auth()
          .createUserWithEmailAndPassword(
            self.state.valueSignUp.email,
            self.state.valueSignUp.password
          )
          .then((u) => {
            //console.log(u);
            var actionCodeSettings = {
              // URL you want to redirect back to. The domain (www.example.com) for this
              // URL must be whitelisted in the Firebase Console.
              url:
                pathhost +
                "/verifyemail/" +
                firebase.auth().currentUser.email +
                "/" +
                self.state.valueSignUp.phone +
                "/" +
                self.state.valueSignUp.password +
                "/" +
                self.state.user_type+
                "/" +
                self.state.valueSignUp.company_type,
              // This must be true.
              handleCodeInApp: true,
            };

            firebase
              .auth()
              .currentUser.sendEmailVerification(actionCodeSettings)
              .then(function() {
                self.props.setLoading(false);

                Modal.info({
                  content: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.send_verify_email}
                    </LangContext.Consumer>
                  ),
                  okText: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.o.ok}
                    </LangContext.Consumer>
                  ),
                  onOk() {
                    window.location = "/";
                  },
                  okButtonProps: { "data-cy" : "btn-modal-ok" }
                });
                // window.location = "/form/personal/" + 702;
                // / self.props.history.push("/form/personal/" + 702); //test
              })
              .catch(function(error) {
                self.props.setLoading(false);
                let message = "";
                // if (error.code === "auth/email-already-in-use") {
                //   message = "Email address have already exist";
                // } else if (error.code === "auth/weak-password") {
                //   message = "Password is not strong enough";
                // } else {
                message = error.code;
                //}

                Modal.error({
                  content: message,
                  okText: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.o.ok}
                    </LangContext.Consumer>
                  ),
                  okButtonProps: { "data-cy" : "btn-modal-ok" }
                });
              });
          })
          .catch((error) => {
            self.props.setLoading(false);

            if (error.code === "auth/email-already-in-use") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.email_already}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else {
              Modal.error({
                content: error.code,
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            }
          });
      } else {
        window.confirmationResult
          .confirm(values.verifycode)
          .then(function(result) {
            // User signed in successfully.

            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then(function(idToken) {
                // Send token to your backend via HTTPS
                //console.log("idToken:", idToken);
                //console.log("loading:", self.state.verifyloading);
                self.createUserByPhone(idToken);
              })
              .catch(function(error) {
                Modal.error({
                  content: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.signup.cannot_signup_verify_by_phone}
                    </LangContext.Consumer>
                  ),
                  okText: (
                    <LangContext.Consumer>
                      {(i18n) => i18n.o.ok}
                    </LangContext.Consumer>
                  ),
                  okButtonProps: { "data-cy" : "btn-modal-ok" }
                });
                self.props.setLoading(false);
              });
            // ...
          })
          .catch(function(error) {
            if (error.code === "auth/invalid-verification-code") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.invalid_verification_code}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else if (error.code === "auth/missing-verification-code") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.invalid_verification_code}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            }
            self.props.setLoading(false);
          });
      }
    } else {
      // console.log("data:", self.state.valueSignUp);
      const phoneNumber = "+66" + self.state.valueSignUp.phone;
      const appVerifier = window.recaptchaVerifier;
      //console.log(phoneNumber);
      if (this.state.recaptcha_solve) {
        self.props.setLoading(true);
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then((confirmResult) => {
            //console.log("confirmresult:", confirmResult);
            window.confirmationResult = confirmResult;
            this.setState({
              recaptcha_solve: false,
            });
            self.props.setLoading(false);
            Modal.info({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.s.send_verify_code}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
            // success
          })
          .catch((error) => {
            window.recaptchaVerifier.clear();
            console.log("captcha:", error);
            this.setState({
              recaptcha_solve: false,
            });
            self.props.setLoading(false);

            if (error.code === "auth/too-many-requests") {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.otp_too_many_requests}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            } else {
              Modal.error({
                content: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.signup.cannot_send_otp}
                  </LangContext.Consumer>
                ),
                okText: (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.ok}
                  </LangContext.Consumer>
                ),
                okButtonProps: { "data-cy" : "btn-modal-ok" }
              });
            }
          });
      } else {
        Modal.error({
          content: (
            <LangContext.Consumer>
              {(i18n) => i18n.c.captcha_require}
            </LangContext.Consumer>
          ),
          okText: (
            <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
          ),
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
      }
    }
  };

  createUserByPhone = (token) => {
    console.log("createuser:", token, this.state);
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/signup`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.valueSignUp.email,
        password: this.state.valueSignUp.password,
        phone: this.state.valueSignUp.phone,
        user_type: this.state.user_type,
        company_type: this.state.valueSignUp.company_type,
        token,
        by: "phone",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.props.setLoading(false);
        if (res.status === "fail") {
          if (res.msg === "duplicate") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.signup.phone_already}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          } else {
            Modal.error({
              content: res.msg,
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          }
        } else {
          // console.log("res:", res.id);
          if (self.state.user_type === "personal") {
            window.location = "/form/personal/" + res.id;
            //self.props.history.push("/form/personal/" + res.id); //เอา id ส่งไปด้วยทำไว้รอ
          } else if (self.state.user_type === "productowner") {
            window.location = "/form/productowner/" + res.id;
            //self.props.history.push("/form/productowner/" + res.id); //เอา id ส่งไปด้วยทำไว้รอ
          } else {
            window.location = "/form/transporter/" + res.id;
            // self.props.history.push("/form/transporter/" + res.id); //เอา id ส่งไปด้วยทำไว้รอ
          }

          self.setState({
            valueSignUp: {
              email: "",
              password: "",
              trypassword: "",
              phone: "",
            },
          });
        }
      });
  };

  updatePasswordByPhone = (token, password) => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/setpassword`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // email: this.state.valueForgot.email,
        password,
        token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.props.setLoading(false);
        self.setState({
          valueSignUp: {
            email: "",
            password: "",
            trypassword: "",
            phone: "",
          },
        });

        Modal.info({
          content: (
            <LangContext.Consumer>
              {(i18n) => i18n.signup.update_password_success}
            </LangContext.Consumer>
          ),
          okText: (
            <LangContext.Consumer>{(i18n) => i18n.o.ok}</LangContext.Consumer>
          ),
          onOk() {
            window.location = "/";
          },
          okButtonProps: { "data-cy" : "btn-modal-ok" }
        });
      });
  };

  setRecapcha = () => {
    let self = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //console.log("resolve");
          self.setState({
            recaptcha_solve: true,
          });
          //
        },
        "expired-callback": function() {
          // Response expired. Ask user to solve reCAPTCHA again.
          self.setState({
            recaptcha_solve: false,
          });
        },
      }
    );

    window.recaptchaVerifier.render();
  };

  render() {
    return (
      <StyledDiv>
        <StyledRow>
          <StyledStepFloat>
            {/* <StyledCircleStep>{this.state.step}</StyledCircleStep> */}
          </StyledStepFloat>
          <VerifyForm
            onSubmit={
              this.state.by === "signup" ? this.onSubmit : this.onSubmitForgot
            }
            firebase={firebase}
            recaptcha_solve={this.state.recaptcha_solve}
            setRecapcha={this.setRecapcha}
            by={this.state.by}
            phone={this.state.valueSignUp.phone}
            user_type={this.state.user_type}
            step={this.state.step}
          />
        </StyledRow>
      </StyledDiv>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    setLoading: setLoading,
  }
)(Verify);

import React, { Component, Fragment } from "react";
import { Upload, Modal } from "antd";

export default class PreviewImg extends Component {
  state = {
    previewVisible: false,
  };
  handlePreview = () => {
    this.setState({ previewVisible: true })
  };

  handleCancel = () => {
      this.setState({ previewVisible: false })
  };

  render() {
    const { imageSrc, styleImg } = this.props
    const { previewVisible } = this.state

    const showImage = [
            {
              uid: "1",
              name: "display_img.png",
              url: imageSrc,
            },
          ]

    return (
      <Fragment>
        <Upload
        name="upload"
        className="test-upload-preview-image-dashboard"
          listType="picture-card"
          fileList={showImage}
          showRemoveIcon={false}
          onRemove={false}
          disabled={true}
          onPreview={this.handlePreview}
        >
        </Upload>
        <Modal
          className="test-modal-show-img"
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={styleImg} src={imageSrc} />
        </Modal>
      </Fragment>
    );
  }
}

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import axios from "axios";
import QRCode from "qrcode.react";
import { loadCompanyProfile } from "../actions";

import FormDivider from "../../../shared/components/FormDivider";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { StyledSearchForm } from "../../../../styled/common-styled";
import {
  Button,
  Row,
  Col,
  Select,
  Modal,
  Popconfirm,
  Input,
  Divider,
  Tooltip,
  Form,
  Icon,
  Rate,
} from "antd";
const columnStyle = { fontSize: "12px", fontWeight: "400" };
const columnStyle2 = { fontSize: "10px", fontWeight: "350" };
const Option = Select.Option;
const { confirm } = Modal;

const styleHeader = {
  border: "3px solid",
  width: "250px",
  height: "55px",
  fontSize: "16px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

const img = {
  width: "100px",
  height: "100px",
  marginBottom: "10px",
};

const product_img = {
  width: "200px",
  height: "200px",
  marginTop: "20px",
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      companyVehicleType: [{}],
      companytransportationrating: [{}],
      recenttransportationrating: [{}],
    };
  }

  componentDidMount() {
    let self = this;
    let companyId = this.props.auth.profile.company_id;

    this.loadData();

    if (typeof this.props.match !== "undefined") {
      let id = this.props.match.params.id;
      companyId =
        typeof id !== "undefined" ? id : this.props.auth.profile.company_id;
    }

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanyvehicletype`,
        {
          companyID: companyId,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({
            companyVehicleType: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanytransportationrating`,
        {
          companyID: companyId,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({
            companytransportationrating: response.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

      axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getrecentcompanytransportationrating`,
        {
          companyID: companyId,
        },
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({
            recenttransportationrating: response.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

  }

  componentWillUnmount() {}

  loadData = (loading) => {
    let companyId = this.props.auth.profile.company_id;

    if (typeof this.props.match !== "undefined") {
      let id = this.props.match.params.id;
      companyId =
        typeof id !== "undefined" ? id : this.props.auth.profile.company_id;
    }

    this.props.loadCompanyProfile(
      {
        companyID: companyId,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };

  render() {
    const {
      companyInformation,
      contactInformation,
      m_user,
    } = this.props.companyProfile.lists;

    let showComponent = false;
    if (typeof this.props.match !== "undefined") {
      showComponent =
        typeof this.props.match.params.id !== "undefined" ? true : false;
    }

    return (
      <AuthorizeComponent matching_name="company_profile">
        <GeneralStyledContent>
          {showComponent && (
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  customeurl={[null, "/productowner/addcompanygroup"]}
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
          )}
          <LangContext.Consumer>
            {(i18n) => (
              <Row style={{ lineHeight: "25px" }}>
                {companyInformation[0].types !== "personal" ? (
                  <Row>
                    <Row>
                      <Col span={24}>
                        <StyledSearchForm>
                          <Row style={{margin: "10px 20px -25px 0px"}} type="flex" justify="end">
                            <Col>
                              {companyInformation[0].verified === 1 ?
                                <div style={{display:"flex"}}>
                                  <p style={{color:"green", fontSize: "15px"}}>{i18n.v.verify_yes}</p>
                                  <img src="/img/backhaul/verify-green.png" style={{marginLeft:"5px", height:"20px", width:"20px"}}/>
                                </div>
                              : ""}
                            </Col>
                          </Row>
                          <Divider orientation="left">
                            {i18n.business.business_information}
                          </Divider>
                          <Row style={{ margin: "0px 0px 10px 20px" }}>
                            <Col span={1} />
                            <Col span={23}>
                              {companyInformation[0].logo_file ? (
                                <img
                                  src={companyInformation[0].logo_file}
                                  style={img}
                                />
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={2} />
                            <Col span={8}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.company_name} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].company_name}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.juristic_id} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].juristic_id}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.companyDetails} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {
                                      i18n.companyDetails[
                                        companyInformation[0].types
                                      ]
                                    }
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.company_type} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].company_type}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.province_head_offfice} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {
                                      companyInformation[0]
                                        .province_head_offfice
                                    }
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.number_employee} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].number_employee}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.business.founded_year} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].founded_year}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </StyledSearchForm>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <StyledSearchForm>
                          <Divider orientation="left">
                            {i18n.business.addresss_business}
                          </Divider>
                          <Row style={{ marginBottom: "20px" }}>
                            <Col span={2} />
                            <Col span={8}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.buildingName} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].building_name}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.villageNo} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].village_no}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.district} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].district}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.e.email} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {m_user ? m_user[0].email : ""}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.roomNumber} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].room_number}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.houseNo} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].house_no}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.province} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].province_name}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.c.contact_telno} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {m_user ? m_user[0].phone : ""}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.floor} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].floor}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.subdistrict} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].subdistrict}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.postalCode} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].postcode}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </StyledSearchForm>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <StyledSearchForm>
                          <Divider orientation="left">
                            {i18n.business.contact_business}
                          </Divider>
                          {contactInformation.map((item, i) => (
                            <Row style={{ marginBottom: "20px" }}>
                              <Col span={2}>
                                <div style={{textAlign:"right", fontWeight:"bold", marginRight:"20px"}}>
                                    <p>{i+1}</p>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div>
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{ fontWeight: "bold" }}
                                    >{`${i18n.j.job_contact_name} : `}</p>
                                    <p style={{ margin: "0px 0px 0px 5px" }}>
                                      {item.firstname}
                                    </p>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{ fontWeight: "bold" }}
                                    >{`${i18n.p.position} : `}</p>
                                    <p style={{ margin: "0px 0px 0px 5px" }}>
                                      {item.position}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={7}>
                                <div>
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{ fontWeight: "bold" }}
                                    >{`${i18n.j.job_contact_surname} : `}</p>
                                    <p style={{ margin: "0px 0px 0px 5px" }}>
                                      {item.lastname}
                                    </p>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{ fontWeight: "bold" }}
                                    >{`${i18n.c.contact_telno} : `}</p>
                                    <p style={{ margin: "0px 0px 0px 5px" }}>
                                      {item.phone_no}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col span={7}>
                                <div>
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{ fontWeight: "bold" }}
                                    >{`${i18n.e.email} : `}</p>
                                    <p style={{ margin: "0px 0px 0px 5px" }}>
                                      {item.email}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              {contactInformation.length - 1 == i ? "" : <Divider style={{marginBottom: "-5px"}} />}
                            </Row>
                          ))}
                        </StyledSearchForm>
                      </Col>
                    </Row>
                    {companyInformation[0].types === "productowner" ? (
                      <Row>
                        <Col span={8}>
                          <StyledSearchForm>
                            <Divider orientation="left">
                              {i18n.business.productTypeProductImage}
                            </Divider>
                            <div style={{ textAlign: "center" }}>
                              {companyInformation[0].product_file ? (
                                <img
                                  src={companyInformation[0].product_file}
                                  style={product_img}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </StyledSearchForm>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {companyInformation[0].types === "transporter" ? (
                      <Row>
                        <Col span={8}>
                          <StyledSearchForm>
                            <Divider orientation="left">
                              {i18n.business.available_vehicle_type}
                            </Divider>
                            {this.state.companyVehicleType.map((item, i) => (
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "30px",
                                }}
                              >
                                <div style={{ width: "100px" }}>
                                  {item.name_key ? (
                                    <img
                                      src={`/img/vehicletype/${item.name_key}.png`}
                                      style={{ width: "70px" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div>{item.name ? item.name : ""}</div>
                              </div>
                            ))}
                          </StyledSearchForm>
                        </Col>
                        <Col span={8} style={{ padding: "0px 0px 0px 15px" }}>
                          <StyledSearchForm>
                            <Divider orientation="left">
                              {i18n.r.rateServiceQuality}
                            </Divider>
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.rateConfirmationPeriod}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange(
                                          "confirmation_period",
                                          e
                                        )
                                      }
                                      value={
                                        this.state
                                          .companytransportationrating[0]
                                          .confirmation_period_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.ratePunctuality}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange("punctuality", e)
                                      }
                                      value={
                                        this.state
                                          .companytransportationrating[0]
                                          .punctuality_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.ratePersonality_driver}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange(
                                          "personality_driver",
                                          e
                                        )
                                      }
                                      value={
                                        this.state
                                          .companytransportationrating[0]
                                          .personality_driver_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.rateGoodservice}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange("good_service", e)
                                      }
                                      value={
                                        this.state
                                          .companytransportationrating[0]
                                          .good_service_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </StyledSearchForm>
                        </Col>
                        <Col span={8} style={{ padding: "0px 0px 0px 15px" }}>
                          <StyledSearchForm>
                            <Divider orientation="left">
                              {i18n.r.rateServiceQualityRecent}
                            </Divider>
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.rateConfirmationPeriod}
                                  </div>
                                  <div>
                                    <Rate
                                      value={
                                        this.state
                                          .recenttransportationrating[0]
                                          .confirmation_period_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.ratePunctuality}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange("punctuality", e)
                                      }
                                      value={
                                        this.state
                                          .recenttransportationrating[0]
                                          .punctuality_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.ratePersonality_driver}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange(
                                          "personality_driver",
                                          e
                                        )
                                      }
                                      value={
                                        this.state
                                          .recenttransportationrating[0]
                                          .personality_driver_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ width: "200px" }}>
                                    {i18n.r.rateGoodservice}
                                  </div>
                                  <div>
                                    <Rate
                                      onChange={(e) =>
                                        this.handleRateChange("good_service", e)
                                      }
                                      value={
                                        this.state
                                          .recenttransportationrating[0]
                                          .good_service_rate
                                      }
                                      allowClear={false}
                                      allowHalf={true}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </StyledSearchForm>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : companyInformation[0].types === "personal" ? (
                  <Row>
                    <Row>
                      <Col span={24}>
                        <StyledSearchForm>
                          <Row style={{margin: "10px 20px -25px 0px"}} type="flex" justify="end">
                            <Col>
                              {companyInformation[0].verified === 1 ?
                                <div style={{display:"flex"}}>
                                  <p style={{color:"green", fontSize: "15px"}}>{i18n.v.verify_yes}</p>
                                  <img src="/img/backhaul/verify-green.png" style={{marginLeft:"5px", height:"20px", width:"20px"}}/>
                                </div>
                              : ""}
                            </Col>
                          </Row>
                          <Divider orientation="left">
                            {i18n.c.contactinfo}
                          </Divider>
                          <Row>
                            <Col span={2} />
                            <Col span={8}>
                              <div style={{ display: "flex" }}>
                                <p
                                  style={{ fontWeight: "bold" }}
                                >{`${i18n.business.company_name} : `}</p>
                                <p style={{ margin: "0px 0px 0px 5px" }}>
                                  {companyInformation[0].company_name}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row style={{ marginBottom: "20px" }}>
                            <Col span={2} />
                            <Col span={8}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.buildingName} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].building_name}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.villageNo} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].village_no}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.district} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].district}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.e.email} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {m_user ? m_user[0].email : ""}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.roomNumber} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].room_number}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.houseNo} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].house_no}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.province} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].province_name}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.c.contact_telno} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {m_user ? m_user[0].phone : ""}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.floor} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].floor}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.subdistrict} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].subdistrict}
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{ fontWeight: "bold" }}
                                  >{`${i18n.addr.postalCode} : `}</p>
                                  <p style={{ margin: "0px 0px 0px 5px" }}>
                                    {companyInformation[0].postcode}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </StyledSearchForm>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <StyledSearchForm>
                          <Divider orientation="left">
                            {i18n.business.available_vehicle_type}
                          </Divider>
                          {this.state.companyVehicleType.map((item, i) => (
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "30px",
                              }}
                            >
                              <div style={{ width: "100px" }}>
                                {item.name_key ? (
                                  <img
                                    src={`/img/vehicletype/${item.name_key}.png`}
                                    style={{ width: "70px" }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>{item.name ? item.name : ""}</div>
                            </div>
                          ))}
                        </StyledSearchForm>
                      </Col>
                      <Col span={8} style={{ padding: "0px 0px 0px 15px" }}>
                        <StyledSearchForm>
                          <Divider orientation="left">
                            {i18n.r.rateServiceQuality}
                          </Divider>
                          <Row>
                            <Col>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.rateConfirmationPeriod}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange(
                                        "confirmation_period",
                                        e
                                      )
                                    }
                                    value={
                                      this.state
                                        .companytransportationrating[0]
                                        .confirmation_period_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.ratePunctuality}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange("punctuality", e)
                                    }
                                    value={
                                      this.state
                                        .companytransportationrating[0]
                                        .punctuality_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.ratePersonality_driver}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange(
                                        "personality_driver",
                                        e
                                      )
                                    }
                                    value={
                                      this.state
                                        .companytransportationrating[0]
                                        .personality_driver_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.rateGoodservice}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange("good_service", e)
                                    }
                                    value={
                                      this.state
                                        .companytransportationrating[0]
                                        .good_service_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </StyledSearchForm>
                      </Col>
                      <Col span={8} style={{ padding: "0px 0px 0px 15px" }}>
                        <StyledSearchForm>
                          <Divider orientation="left">
                            {i18n.r.rateServiceQualityRecent}
                          </Divider>
                          <Row>
                            <Col>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.rateConfirmationPeriod}
                                </div>
                                <div>
                                  <Rate
                                    value={
                                      this.state
                                        .recenttransportationrating[0]
                                        .confirmation_period_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.ratePunctuality}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange("punctuality", e)
                                    }
                                    value={
                                      this.state
                                        .recenttransportationrating[0]
                                        .punctuality_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.ratePersonality_driver}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange(
                                        "personality_driver",
                                        e
                                      )
                                    }
                                    value={
                                      this.state
                                        .recenttransportationrating[0]
                                        .personality_driver_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "200px" }}>
                                  {i18n.r.rateGoodservice}
                                </div>
                                <div>
                                  <Rate
                                    onChange={(e) =>
                                      this.handleRateChange("good_service", e)
                                    }
                                    value={
                                      this.state
                                        .recenttransportationrating[0]
                                        .good_service_rate
                                    }
                                    allowClear={false}
                                    allowHalf={true}
                                    disabled
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </StyledSearchForm>
                      </Col>
                    </Row>
                  </Row>
                ) : (
                  ""
                )}
                <Row style={{ margin: "0px 0px 100px 0px" }}>
                  <Col>
                    {showComponent == false && (
                      <Link
                        to={`/companyprofile/edit`}
                        style={{ padding: "0px 15px 0px 5px", zIndex: "20" }}
                      >
                        <Button
                         data-cy="btn-edit"
                          type="default"
                          style={{
                            marginLeft: "5px",
                            marginRight: "10px",
                          }}
                        >
                          {i18n.e.edit}
                        </Button>
                      </Link>
                    )}

                    {window.location.href.match(/addcompanygroup/g) && (
                      <Link to={`/productowner/addcompanygroup`}>
                      <Button
                        data-cy="btn-go-back"
                        type="default"
                        style={{
                          marginLeft: "5px",
                          marginRight: "10px",
                        }}
                      >
                        {i18n.b.back}
                      </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </Row>
            )}
          </LangContext.Consumer>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ vehicle, auth, companyProfile }) => ({
    companyProfile,
    vehicle,
    auth,
  }),
  {
    loadCompanyProfile: loadCompanyProfile.request,
  }
)(Index);

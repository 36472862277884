import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import LangContext from "modules/shared/context/langContext";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  AutoComplete,
  InputNumber,
  Modal,
} from "antd";

import { StyledSearchForm } from "../../../../styled/backhaul-styled";
import { getlocalstorage } from "./../../../../constants/local_storage";
import AddFacilityWorksheet from "modules/master-data/facility/components/AddFacilityWorksheet";
import axios from "axios";

const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const OptionAuto = AutoComplete.Option;
const format = "HH:mm";

export default class Forms extends PureComponent {
  state = {
    visible: false,
    button_type: "",
    vehicle_type: [],
    originlocationLists: [],
    destinationlocationLists: [],
    origin_location_name: "",
    origin_location_id: "",
    destination_location_name: "",
    destination_location_id: "",
    priceunitLists: [],
    destinationLat: "",
    destinationLng: "",
    originLat: "",
    originLng: "",

    vehicle_type_id: "",
    distance: "",
    price_unit_id: "",
    price: "",
    price_trip: "",
    price_trip_unit_id: "",
    id: "",
    should_load_location: false,
  };

  loadOriginLocation = (name) => {
    let self = this;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem("profile"),
      "company_id"
    );

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        company_id: COMPANY_ID,
        name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ originlocationLists: res.data });
      });
  };

  loadDestinationLocation = (name) => {
    let self = this;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    const COMPANY_ID = getlocalstorage(
      localStorage.getItem("profile"),
      "company_id"
    );

    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
      body: JSON.stringify({
        company_id: COMPANY_ID,
        name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ destinationlocationLists: res.data });
      });
  };

  vehicleyType = () => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdatavehicletype`,
        {}
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let vehicle_type = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name_key,
            };
          });
          self.setState({ vehicle_type });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  unitPrice = () => {
    let self = this;
    const ACCESSTOKEN = localStorage.getItem("access-token");
    //console.log("dd:", ACCESSTOKEN);
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getunitprice`,
        {},
        {
          headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let unit = response.data.data.map((d) => {
            return {
              id: d.id,
              name: d.name,
            };
          });
          self.setState({ priceunitLists: unit });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  componentDidMount() {
    this.loadOriginLocation("");
    this.loadDestinationLocation("");
    this.vehicleyType();
    this.unitPrice();
  }

  componentDidUpdate(prevProps) {
    const { dataExpense } = this.props.expense;
    if (
      this.props.id !== this.state.id &&
      this.props.id !== "" &&
      typeof dataExpense !== "undefined"
    ) {
      if (dataExpense.id !== "") {
        this.setState({
          id: this.props.id,
          vehicle_type_id: dataExpense.vehicle_type_id,
          origin_location_id: dataExpense.origin_location_id,
          destination_location_id: dataExpense.destination_location_id,
          origin_location_name: dataExpense.origin_location_name,
          destination_location_name: dataExpense.destination_location_name,
          distance: dataExpense.distance,
          price_unit_id: dataExpense.price_unit_id,
          price: parseFloat(dataExpense.price),
          price_trip: parseFloat(dataExpense.price_trip),
          price_trip_unit_id: dataExpense.price_trip_unit_id,
          should_load_location: true,
        });
      }
    }

    if (
      this.props.id !== "" &&
      this.state.originlocationLists.length > 0 &&
      this.state.should_load_location
    ) {
      let origin = this.state.originlocationLists.find(
        (x) => parseInt(x.id) === parseInt(dataExpense.origin_location_id)
      );
      let destination = this.state.originlocationLists.find(
        (x) => parseInt(x.id) === parseInt(dataExpense.destination_location_id)
      );
      this.setState({
        originLat: origin.lat,
        originLng: origin.lng,
        destinationLat: destination.lat,
        destinationLng: destination.lng,
        should_load_location: false,
      });
    }
  }

  selectOriginLocation = (value, option, setFieldValue) => {
    console.log("selectOriginLocation -> value", value);
    const { destinationLat, destinationLng } = this.state;

    let origin_location_name = option.props.children,
      origin_location_id = "",
      originLat = option.props.lat,
      originLng = option.props.lng;

    if (parseInt(value) > 0) {
      origin_location_id = value;
    } else {
      let idx = -1;

      if (typeof this.state.originlocationLists !== "undefined") {
        idx = this.state.originlocationLists.findIndex((x) => x.name === value);
      }

      if (idx > -1) {
        let values = this.state.originlocationLists[idx];
        origin_location_name = values.name;
        origin_location_id = values.id;
        originLat = values.lat;
        originLng = values.lng;
      }
    }

    setFieldValue("origin_location_id", origin_location_id);

    this.setState(
      {
        ...this.state,
        origin_location_name: origin_location_name,
        origin_location_id: origin_location_id,
        originLat: originLat,
        originLng: originLng,
        distance: "",
      },
      () => {
        if (
          destinationLat !== "" &&
          destinationLng !== "" &&
          originLat !== "" && typeof originLat !== "undefined" &&
          originLng !== "" && typeof originLng !== "undefined"
        ) {
          this.findDistance(
            originLat,
            originLng,
            destinationLat,
            destinationLng,
            setFieldValue
          );
        }
      }
    );
  };

  onSearchOriginLocation = (searchText) => {
    if (searchText != "") {
      this.loadOriginLocation(searchText);
    }
    this.setState({ origin_location_name: searchText });
  };

  selectDestinationLocation = (value, option, setFieldValue) => {
    const { originLat, originLng } = this.state;
    let destination_location_name = option.props.children,
      destination_location_id = "",
      destinationLat = option.props.lat,
      destinationLng = option.props.lng;

    if (parseInt(value) > 0) {
      destination_location_id = value;
    } else {
      let idx = -1;

      if (typeof this.state.destinationlocationLists !== "undefined") {
        idx = this.state.destinationlocationLists.findIndex(
          (x) => x.name === value
        );
      }

      if (idx > -1) {
        let values = this.state.destinationlocationLists[idx];
        destination_location_id = values.id;
        destination_location_name = values.name;
        destinationLat = values.lat;
        destinationLng = values.lng;
      }
    }

    setFieldValue("destination_location_id", destination_location_id);

    this.setState(
      {
        ...this.state,
        destination_location_name,
        destination_location_id,
        destinationLat,
        destinationLng,
        distance: "",
      },
      () => {
        if (
          originLat !== "" &&
          originLng !== "" &&
          destinationLat !== "" && typeof destinationLat !== "undefined" &&
          destinationLng !== "" && typeof destinationLng !== "undefined"
        ) {
          this.findDistance(
            originLat,
            originLng,
            destinationLat,
            destinationLng,
            setFieldValue
          );
        }
      }
    );
  };

  onSearchDestinationLocation = (searchText) => {
    if (searchText != "") {
      this.loadDestinationLocation(searchText);
    }
    this.setState({ destination_location_name: searchText });
  };

  findDistance = (sLat, sLng, eLat, eLng, setFieldValue) => {
    let self = this;
    fetch(
      `https://mmmap15.longdo.com/mmroute/geojson/route?flon=${sLng}&flat=${sLat}&tlon=${eLng}&tlat=${eLat}&mode=d&type=17&locale=th&key=${this.props.auth.profile.longdo_map_key}`
    )
      .then((response) => response.json())
      .then((data) => {
        let distance = "";
        if (typeof data.properties !== "undefined") {
          distance = data.properties.distance / 1000;
        }

        self.setState({
          distance: distance,
        });

        setFieldValue("distance", distance);
      });
  };

  showModal = (type) => {
    this.setState({
      visible: true,
      button_type: type,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
      button_type: "",
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      button_type: "",
    });
  };

  addArea = (id, name, lat, lng, setFieldValue) => {
    if (this.state.button_type === "origin") {
      this.setState(
        {
          visible: false,
          button_type: "",
          origin_location_id: id,
          origin_location_name: name,
          originLat: lat,
          originLng: lng,
        },
        () => {
          this.loadOriginLocation("");
          this.findDistance(
            lat,
            lng,
            this.state.destinationLat,
            this.state.destinationLng,
            setFieldValue
          );
        }
      );
    } else if (this.state.button_type === "destination") {
      this.setState(
        {
          visible: false,
          button_type: "",
          destination_location_name: name,
          destination_location_id: id,
          destinationLat: lat,
          destinationLng: lng,
        },
        () => {
          this.loadDestinationLocation("");
          this.findDistance(
            this.state.originLat,
            this.state.originLng,
            lat,
            lng,
            setFieldValue
          );
        }
      );
    }
  };

  render() {
    const { expense, onSubmit, id } = this.props;
    const {
      vehicle_type,
      originlocationLists,
      destinationlocationLists,
      origin_location_name,
      destination_location_name,
      priceunitLists,
      distance,
    } = this.state;

    const originlists = originlocationLists.map((v) => (
      <OptionAuto key={v.id} lat={v.lat} lng={v.lng}>
        {v.name}
      </OptionAuto>
    ));

    const destinationlists = destinationlocationLists.map((v) => (
      <OptionAuto key={v.id} lat={v.lat} lng={v.lng}>
        {v.name}
      </OptionAuto>
    ));

    return (
      <Spin spinning={expense.expenseLoading}>
        <Row>
          <LangContext.Consumer>
            {(i18n) => (
              <StyledSearchForm>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    vehicle_type_id: this.state.vehicle_type_id,
                    origin_location_id: this.state.origin_location_id,
                    destination_location_id: this.state.destination_location_id,
                    distance: this.state.distance,
                    price_unit_id: this.state.price_unit_id,
                    price: this.state.price,
                    price_trip: this.state.price_trip,
                    price_trip_unit_id: this.state.price_trip_unit_id,
                  }}
                  validate={(values) => {
                    let errors = {};

                    return errors;
                  }}
                  validationSchema={yup.object().shape({
                    vehicle_type_id: yup
                      .string()
                      .required(i18n.veh.vehicle_type_require),
                    origin_location_id: yup
                      .string()
                      .required(i18n.expense.origin_location_require),
                    destination_location_id: yup
                      .string()
                      .required(i18n.expense.destination_location_require),
                    price: yup.string().required(i18n.expense.price_require),
                    price_unit_id: yup
                      .string()
                      .required(i18n.expense.price_unit_require),
                    // price_trip: yup
                    //   .string()
                    //   .required(i18n.expense.price_trip_require),
                    // price_trip_unit_id: yup
                    //   .string()
                    //   .required(i18n.expense.price_unit_require),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    const loading = true;
                    onSubmit(values, loading);
                  }}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleFocus,
                    setFieldValue,
                    resetForm,
                  }) => (
                    <Row>
                      <Modal
                        className="test-modal-map"
                        title={
                          <LangContext.Consumer>
                            {(i18n) => i18n.p.pinDown}
                          </LangContext.Consumer>
                        }
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        cancelButtonProps={{ style: { display: "none" }, "data-cy": "btn-modal-cancel" }}
                        okButtonProps={{ style: { display: "none" }, "data-cy": "btn-modal-ok" }}
                        width={1000}
                        destroyOnClose={true}
                      >
                        <AddFacilityWorksheet
                          addLocation={this.addArea}
                          setFieldValue={setFieldValue}
                        ></AddFacilityWorksheet>
                      </Modal>
                      <LangContext.Consumer>
                        {(i18n) => (
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                <FormItem
                                  label={i18n.veh.vehicle_type}
                                  required={true}
                                  validateStatus={
                                    touched.vehicle_type_id &&
                                    errors.vehicle_type_id &&
                                    "error"
                                  }
                                  help={
                                    touched.vehicle_type_id &&
                                    errors.vehicle_type_id
                                  }
                                >
                                  <Select
                                    data-cy="vehicle_type_id"
                                    onChange={(value) => {
                                      setFieldValue("vehicle_type_id", value);
                                      this.setState({ vehicle_type_id: value });
                                    }}
                                    value={values.vehicle_type_id}
                                  >
                                    <Option value="">
                                      {i18n.p.pleaseSelect}
                                    </Option>
                                    {vehicle_type.map((v) => (
                                      <Option key={v.id} value={v.id}>
                                        {i18n.vehicle_type[v.name]}
                                      </Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                <FormItem
                                  label={i18n.expense.origin_location}
                                  required={true}
                                  validateStatus={
                                    touched.origin_location_id &&
                                    errors.origin_location_id &&
                                    "error"
                                  }
                                  help={
                                    touched.origin_location_id &&
                                    errors.origin_location_id
                                  }
                                >
                                  <InputGroup compact>
                                    <AutoComplete
                                      name="origin_location_id"
                                      className="origin_location_id"
                                      dataSource={originlists}
                                      //style={{ width: 200 }}
                                      optionLabelProp="children"
                                      filterOption={true}
                                      optionFilterProp="children"
                                      value={origin_location_name}
                                      onSelect={(value, option) =>
                                        this.selectOriginLocation(
                                          value,
                                          option,
                                          setFieldValue
                                        )
                                      }
                                      onChange={(value, option) =>
                                        this.selectOriginLocation(
                                          value,
                                          option,
                                          setFieldValue
                                        )
                                      }
                                      onSearch={this.onSearchOriginLocation}
                                    />
                                    <Button
                                      data-cy="btn-toggle-map-origin"
                                      icon="plus"
                                      style={{ width: "30%" }}
                                      onClick={() => this.showModal("origin")}
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.map}
                                      </LangContext.Consumer>
                                    </Button>
                                  </InputGroup>
                                </FormItem>
                              </Col>
                              <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                              <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                <FormItem
                                  label={i18n.expense.destination_location}
                                  required={true}
                                  validateStatus={
                                    touched.destination_location_id &&
                                    errors.destination_location_id &&
                                    "error"
                                  }
                                  help={
                                    touched.destination_location_id &&
                                    errors.destination_location_id
                                  }
                                >
                                  <InputGroup>
                                    <AutoComplete
                                      className="destination_location_id"
                                      name="destination_location_id"
                                      dataSource={destinationlists}
                                      //  style={{ width: 200 }}
                                      optionLabelProp="children"
                                      filterOption={true}
                                      optionFilterProp="children"
                                      value={destination_location_name}
                                      onSelect={(value, option) =>
                                        this.selectDestinationLocation(
                                          value,
                                          option,
                                          setFieldValue
                                        )
                                      }
                                      onChange={(value, option) =>
                                        this.selectDestinationLocation(
                                          value,
                                          option,
                                          setFieldValue
                                        )
                                      }
                                      onSearch={
                                        this.onSearchDestinationLocation
                                      }
                                    />
                                    <Button
                                      icon="plus"
                                      data-cy="btn-toggle-map-destination"
                                      style={{ width: "30%" }}
                                      onClick={() =>
                                        this.showModal("destination")
                                      }
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.m.map}
                                      </LangContext.Consumer>
                                    </Button>
                                  </InputGroup>
                                </FormItem>
                              </Col>
                              <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                              <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                <FormItem
                                  label={i18n.expense.distance}
                                  required={true}
                                  validateStatus={
                                    touched.distance &&
                                    errors.distance &&
                                    "error"
                                  }
                                  help={touched.distance && errors.distance}
                                >
                                  <Input
                                    data-cy="distance"
                                    type="text"
                                    name="distance"
                                    disabled={true}
                                    value={distance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.plate_no && errors.plate_no
                                        ? "invalid"
                                        : undefined
                                    }
                                  />
                                </FormItem>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                <FormItem
                                  label={i18n.expense.price}
                                  required={true}
                                  validateStatus={
                                    touched.price && errors.price && "error"
                                  }
                                  help={touched.price && errors.price}
                                >
                                  <InputNumber
                                    data-cy="price"
                                    style={{ width: "100%" }}
                                    value={values.price}
                                    min={0}
                                    max={9999999}
                                    step={0.01}
                                    onChange={(value) => {
                                      if (value !== null) {
                                        if (
                                          value.toString().match(/\d+/g) ||
                                          value === ""
                                        ) {
                                          this.setState({ price: value });
                                          setFieldValue("price", value);
                                        }
                                      }
                                    }}
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                <FormItem
                                  label={i18n.expense.price_unit}
                                  required={true}
                                  validateStatus={
                                    touched.price_unit_id &&
                                    errors.price_unit_id &&
                                    "error"
                                  }
                                  help={
                                    touched.price_unit_id &&
                                    errors.price_unit_id
                                  }
                                >
                                  <Select
                                    data-cy="price_unit_id"
                                    onChange={(value) => {
                                      this.setState({ price_unit_id: value });
                                      setFieldValue("price_unit_id", value);
                                    }}
                                    value={values.price_unit_id}
                                  >
                                    <Option value="">
                                      {i18n.p.pleaseSelect}
                                    </Option>
                                    {priceunitLists.map((v) => (
                                      <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                <FormItem
                                  label={i18n.expense.price_trip}
                                  required={true}
                                  validateStatus={
                                    touched.price_trip &&
                                    errors.price_trip &&
                                    "error"
                                  }
                                  help={touched.price_trip && errors.price_trip}
                                >
                                  <Input
                                    data-cy=""Number
                                    style={{ width: "100%" }}
                                    value={values.price_trip}
                                    min={0}
                                    max={9999999}
                                    step={0.01}
                                    onChange={(value) => {
                                      this.setState({ price_trip: value })
                                      setFieldValue("price_trip", value)
                                    }}
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                <FormItem
                                  label={i18n.expense.price_unit}
                                  required={true}
                                  validateStatus={
                                    touched.price_trip_unit_id &&
                                    errors.price_trip_unit_id &&
                                    "error"
                                  }
                                  help={
                                    touched.price_trip_unit_id &&
                                    errors.price_trip_unit_id
                                  }
                                >
                                  <Select
                                    data-cy=""
                                    onChange={(value) => {
                                      this.setState({ price_trip_unit_id: value })
                                      setFieldValue("price_trip_unit_id", value)
                                    } }
                                    value={values.price_trip_unit_id}
                                  >
                                    <Option value="">
                                      {i18n.p.pleaseSelect}
                                    </Option>
                                    {priceunitLists.map((v) => (
                                      <Option key={v.id} value={v.id}>
                                        {v.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            </Row> */}

                            <Row
                              style={{ marginTop: "20px", marginBottom: "5px" }}
                            >
                              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                <Form.Item>
                                  <Button
                                    type="defualt"
                                    data-cy="btn-go-back"
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  >
                                    <Link to="/route-expense">
                                      {i18n.b.back}
                                    </Link>
                                  </Button>

                                  <Button type="primary" htmlType="submit" data-cy="btn-submit">
                                    {i18n.s.save}
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </LangContext.Consumer>
                    </Row>
                  )}
                />
              </StyledSearchForm>
            )}
          </LangContext.Consumer>
        </Row>
      </Spin>
    );
  }
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import MarketList from "./MarketList";
import QuotationForm from "./QuotationForm"

export default () => (
    <Switch>
        <Route exact path="/dashboard/transport-market" component={MarketList} />
        <Route exact path="/dashboard/transport-market/view/:id" component={QuotationForm} />
    </Switch>
);
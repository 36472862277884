import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import { loadVehicleForEdit, removeLists } from "../actions";

import {
  StyledSearchForm,
  GeneralStyledContent,
  TitleText,
  DividerStyled,
} from "../../../../styled/backhaul-styled";

import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";

import { Button, Row, Col, Modal, Form, Upload, Icon } from "antd";

const { Item } = Form;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject();
  });
}
class FormAddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_front_vehicle_list: [],
      file_front_vehicle_preview_visible: false,
      file_front_vehicle_preview: "",
      file_vehicle_list: [],
      file_vehicle_preview_visible: false,
      file_vehicle_preview: "",
      origin_lat: "",
      origin_lng: "",
      end_lat: "",
      end_lng: "",
      data: {
        vehicle_type: "",
        plate_no: "",
        plate_no_province: "",
        expire_date: "",
        expire_act_date: "",
        start_province: "",
        end_province: "",
        brand: "",
        model: "",
        year: "",
        color: "",
      },

      display_button: "none",
    };
    this.goBack = this.goBack.bind(this);
  }
  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  componentDidMount() {
    this.loadData(true);
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(this.props.vehicle.check1, nextProps.vehicle.check1);
    if (this.props.vehicle.check1 != nextProps.vehicle.check1) {
      if (nextProps.vehicle.vehicleforedit != "") {
        // console.log("update");
        this.setState({
          display_button: "block",
          file_front_vehicle_list: [
            {
              uid: "-1",
              name: "filefrontvehicle.png",
              url: nextProps.vehicle.vehicleforedit.file_front_vehicle,
            },
          ],
          file_vehicle_list: [
            {
              uid: "-2",
              name: "file_vehicle.jpg",
              url: nextProps.vehicle.vehicleforedit.file_vehicle,
            },
          ],
          origin_lat: nextProps.vehicle.vehicleforedit.start_lat,
          origin_lng: nextProps.vehicle.vehicleforedit.start_lng,
          end_lat: nextProps.vehicle.vehicleforedit.end_lat,
          end_lng: nextProps.vehicle.vehicleforedit.end_lng,
          data: {
            vehicle_type: nextProps.vehicle.vehicleforedit.vehicle_type_name,
            plate_no: nextProps.vehicle.vehicleforedit.plate_no,
            vehicle_id: nextProps.vehicle.vehicleforedit.id,
            expire_date: moment(
              nextProps.vehicle.vehicleforedit.insurance_expire_date
            ).format("DD/MM/YYYY"),
            expire_act_date: moment(
              nextProps.vehicle.vehicleforedit.act_expire_date
            ).format("DD/MM/YYYY"),
            brand: nextProps.vehicle.vehicleforedit.brand,
            model: nextProps.vehicle.vehicleforedit.model,
            year: nextProps.vehicle.vehicleforedit.year,
            color: nextProps.vehicle.vehicleforedit.color,
            plate_no_province:
              nextProps.vehicle.vehicleforedit.plate_province_name,
            start_province:
              nextProps.vehicle.vehicleforedit.start_province_name,
            end_province: nextProps.vehicle.vehicleforedit.end_province_name,
          },
        });
      }
    }
    return true;
  }

  goBack() {
    this.props.history.goBack();
  }

  loadData = (loading) => {
    this.props.loadVehicleForEdit(
      {
        id: this.props.match.params.id,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };
  handleFrontVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank");
    } else {
      this.setState({
        file_front_vehicle_preview: file.url || file.preview,
        file_front_vehicle_preview_visible: true,
      });
    }
  };

  handleVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.url.match(/.pdf/g)) {
      window.open(file.url, "_blank");
    } else {
      this.setState({
        file_vehicle_preview: file.url || file.preview,
        file_vehicle_preview_visible: true,
      });
    }
  };

  handleVehicleCancel = () =>
    this.setState({ file_vehicle_preview_visible: false });

  handleCancel = () =>
    this.setState({ file_front_vehicle_preview_visible: false });

  render() {
    const {
      file_front_vehicle_list,
      file_front_vehicle_preview,
      file_front_vehicle_preview_visible,
      file_vehicle_list,
      file_vehicle_preview,
      file_vehicle_preview_visible,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="upload" />
        <LangContext.Consumer>
          {(i18n) => i18n.s.select_file}
        </LangContext.Consumer>
      </div>
    );
    return (
      <AuthorizeComponent matching_name="vehicle">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={24} lg={24} xl={24}>
              <StyledSearchForm>
                <Formik
                  enableReinitialize={true}
                  initialValues={this.state.data}
                  validate={(values) => {
                    let errors = {};

                    return errors;
                  }}
                  validationSchema={yup.object().shape({})}
                  onSubmit={(values, actions) => {
                    // this.showConfirm(values);
                  }}
                  render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <div>
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Row gutter={[8, 24]}>
                          <Col span={24}>
                            <TitleText>
                              <LangContext.Consumer>
                                {(i18n) => i18n.veh.vehicleinfo}
                              </LangContext.Consumer>
                            </TitleText>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.v.vehicleType}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.vehicle_type}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.plate_no_province}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.plate_no_province}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.m.model}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.model}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.color}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.color}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.expire_act_date}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.expire_act_date}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.p.plateNo}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.plate_no}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.brand}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.brand}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.year}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.year}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.expire_date}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.expire_date}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <DividerStyled />
                        <Row gutter={[8, 24]}>
                          <Col span={24}>
                            <TitleText>
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.picture}
                              </LangContext.Consumer>
                            </TitleText>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "12px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.filefrontvehicle}
                                    </LangContext.Consumer>
                                  }
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <Item style={{ margin: "0px" }}>
                                  <Upload
                                    className="test-upload-file-front-vehicle-list"
                                    name="avatar"
                                    data-cy="file_front_vehicle_list"
                                    listType="picture-card"
                                    fileList={file_front_vehicle_list}
                                    onPreview={this.handleFrontVehiclePreview}
                                    showRemoveIcon={false}
                                    onRemove={false}
                                    disabled={true}
                                  >
                                    {file_front_vehicle_list.length >= 1
                                      ? null
                                      : uploadButton}
                                  </Upload>
                                  <Modal
                                    className="test-modal-file-front-vehicle"
                                    visible={file_front_vehicle_preview_visible}
                                    footer={null}
                                    onCancel={this.handleCancel}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{ width: "100%" }}
                                      src={file_front_vehicle_preview}
                                    />
                                  </Modal>
                                </Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "12px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.image_vehicle}
                                    </LangContext.Consumer>
                                  }
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <Item style={{ margin: "0px" }}>
                                  <Upload
                                    className="test-upload-file-vehicle-list"
                                    data-cy="file_vehicle_list"
                                    name="avatar"
                                    listType="picture-card"
                                    fileList={file_vehicle_list}
                                    onPreview={this.handleVehiclePreview}
                                    showRemoveIcon={false}
                                    onRemove={false}
                                    disabled={true}
                                  >
                                    {file_vehicle_list.length >= 1
                                      ? null
                                      : uploadButton}
                                  </Upload>
                                  <Modal
                                    className="test-modal-file-vehicle"
                                    visible={file_vehicle_preview_visible}
                                    footer={null}
                                    onCancel={this.handleVehicleCancel}
                                  >
                                    <img
                                      alt="avatar"
                                      style={{ width: "100%" }}
                                      src={file_vehicle_preview}
                                    />
                                  </Modal>
                                </Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <DividerStyled />

                        <Row gutter={[8, 24]}>
                          <Col span={24}>
                            <TitleText>
                              <LangContext.Consumer>
                                {(i18n) => i18n.veh.service_area}
                              </LangContext.Consumer>
                            </TitleText>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.start_province}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.start_province}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.end_province}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.end_province}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.l.latitude}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p> {this.state.origin_lat}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.l.latitude}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p> {this.state.end_lat}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={24} md={2} lg={2} xl={2}></Col>
                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.l.longitude}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>

                              <Col span={14} style={{ padding: "5px" }}>
                                <p> {this.state.origin_lng}</p>
                              </Col>
                            </Row>
                          </Col>

                          <Col sm={24} md={11} lg={11} xl={11}>
                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.l.longitude}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>

                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{this.state.end_lng}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                            {this.state.data.vehicle_companies_id != "" ? (
                              <Item>
                                <div>
                                  <Link
                                    to={`/vehicle`}
                                    style={{ padding: "0px 15px 0px 5px" }}
                                  >
                                    <Button
                                      data-cy="btn-go-back"
                                      type="default"
                                      style={{
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.b.back}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Link>
                                  {checkPermissions(
                                    this.props.auth.profile.permissions,
                                    "vehicle",
                                    "can_edit"
                                  ) == true ? (
                                    <Link
                                      to={`/vehicle/edit/${this.props.match.params.id}`}
                                    >
                                      <Button type="primary" data-cy="btn-edit">
                                        <LangContext.Consumer>
                                          {(i18n) => i18n.e.edit}
                                        </LangContext.Consumer>
                                      </Button>
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Item>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                />
              </StyledSearchForm>
            </Col>
            {/* <Col  sm={24} md={2} lg={2} xl={2}></Col> */}
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(({ vehicle, auth }) => ({ vehicle, auth }), {
  loadVehicleForEdit: loadVehicleForEdit.request,
  removeLists,
})(FormAddVehicle);

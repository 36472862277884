import React, { Component, Fragment } from "react";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import styled from 'styled-components';

import {
  LandingParagraph,
  StyleBottomLanding,
  ContainerBottomLanding,
  StyleTitleLanding,
  StyleSubTitleLanding,
  LandingCardMeta,
  LandingDescription,
  LandingBottomTitle,
  LandingHeader,
  RegisterButton,
} from "../../../styled/common-styled";

const { Meta } = Card;

const iconStyle = {
  width: "100px",
  margin: "auto",
  marginTop: "20px",
  marginBottom: "20px",
};

const cardData = [
  { title: "ลดต้นทุน", content: "การขนส่ง 30%" },
  { title: "สามารถตรวจสอบ", content: "สถานะการส่งสินค้าได้" },
  { title: "ทุกขั้นตอนมี", content: "ระบบแจ้งเตือน" },
  { title: "การันตีว่าคุณจะได้พบ", content: "กับผู้ให้บริการขนส่ง" },
];

const HoverText = styled.p`
	color: #000;
	:hover {
		color: #3b69dc;
		cursor: pointer;
	}
`

class Landing extends Component {
  render() {
    return (
      <Fragment>
        <LandingHeader>
          <img
            style={{ width: "100%", height: "auto" }}
            src="/img/background-top.jpg"
          />
          <Link
            to={{ pathname: "/signup" }}
            style={{ padding: "0px 0px 15px 0px", color: "#F0FFFF" }}
          >
            <RegisterButton data-cy="btn-register" type="primary" size="large">
              สมัครสมาชิก
            </RegisterButton>
          </Link>
        </LandingHeader>
        <LandingDescription>
          <Col lg={8} md={7} xs={24}>
            <StyleSubTitleLanding>KhonKhong</StyleSubTitleLanding>
            <StyleSubTitleLanding>Platform</StyleSubTitleLanding>
            <StyleTitleLanding>คืออะไร?</StyleTitleLanding>
          </Col>
          <Col lg={16} md={17} xs={24} style={{ paddingTop: "15px" }}>
            <LandingParagraph>
              KhonKhong Platform (ขนของ แพลตฟอร์ม) คือตลาดค้นหางานส่งออนไลน์
            </LandingParagraph>
            <LandingParagraph>
              สำหรับการหาคู่ค้าระหว่าง "ผู้ให้บริการขนส่ง" และ "เจ้าของสินค้า"
              ที่จะช่วยทำให้
            </LandingParagraph>
            <LandingParagraph>
              การค้นหารถขนส่งและการเสนอราคาระหว่างกันเป็นเรื่องง่ายสะดวกสบาย
            </LandingParagraph>
            <LandingParagraph>และเชื่อถือได้</LandingParagraph>
          </Col>
        </LandingDescription>
        <Row gutter={[16, 16]}>
          <ContainerBottomLanding>
            <StyleBottomLanding>
              <Col span={24}>
                <LandingBottomTitle>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <StyleTitleLanding>ทำไมต้อง</StyleTitleLanding>
                    <StyleSubTitleLanding>
                      KhonKhong Platform
                    </StyleSubTitleLanding>
                  </Col>
                </LandingBottomTitle>
                <Row gutter={[16, 16]}>
                  {[1, 2, 3, 4].map((val, i) => {
                    return (
                      <Col lg={6} md={6} xs={24}>
                        <Card
                          key={i}
                          bordered={false}
                          style={{ width: "100%", background: "transparent" }}
                          cover={
                            <img
                              style={iconStyle}
                              alt="example"
                              src={`/img/icon${val}.png`}
                            />
                          }
                          bodyStyle={{ textAlign: "center" }}
                        >
                          <Meta
                            title={
                              <LandingCardMeta>
                                {cardData[i]["title"]}
                              </LandingCardMeta>
                            }
                            description={
                              <LandingCardMeta>
                                {cardData[i]["content"]}
                              </LandingCardMeta>
                            }
                          />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </StyleBottomLanding>
          </ContainerBottomLanding>
        </Row>
        <Row gutter={24} type="flex" justify="end" style={{padding: "25px 0px 5px 0px"}}>
          <Col xl={4} lg={6} md={6} sm={8} xs={12}>
          <a
            href="/privacypolicy"
            style={{ padding: "0px 0px 15px 0px", color: "#000041" }}  
          >
            <HoverText>นโยบายความเป็นส่วนตัว</HoverText>
          </a>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Landing;

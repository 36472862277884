import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Row, Col, Form, Spin, Input, Select, message, Button, Divider } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import {
  StyledSearchForm,
  GeneralStyledContent,
  NormalText as Text,
  PageTitleStyled,
  SubTitleText,
  TitleText,
  DividerStyled
} from "../../../../../../styled/backhaul-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { i18n } from "modules/shared/context/langContext";
import axios from "axios";
import { translate } from "../../../../../../lib/helper"

import {
  ACCESSTOKEN,
  COMPANY_ID,
} from "../../../../../../constants/local_storage";

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export class AssignJobForm extends Component {
  state = {
    vehicleList: [],
    driverList: [],
    driver_id: null,
    vehicle_id: null,
    vehicle: {},
    phone_no: null,
  };


  loadVehicleList = (query = "") => {
    let url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/autocomplete/getvehiclecode`,
      params = {
        query: query,
        vehicle_visibility: "",
        company_id: COMPANY_ID,
        vehicle_type_id: this.props.match.params.vehicle_type_id
      };

    axios
      .post(url, params, {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      })
      .then((res) => {
        this.setState({ vehicleList: res.data });
      });
  };

  loadDriverList = (query = "") => {
    let url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/autocomplete/getdriver`,
      params = {
        query: query,
        company_id: COMPANY_ID,
      };

    axios
      .post(url, params, {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      })
      .then((res) => {
        this.setState({ driverList: res.data });
      });
  };

  loadVehicleDetail = (id) => {
    let url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehicleforedit/${id}`;
    axios
      .get(url, { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } })
      .then((res) => {
        if (typeof res.data.data[0] !== "undefined") {
          this.setState({ vehicle: res.data.data[0] });
        }
      });
  };

  loadDriverDetail = (id, setFieldValue) => {
    let val = this.state.driverList.filter((val) => val.id === id);
    if (typeof val[0] !== "undefined") {
      this.setState({ phone_no: val[0].mobile_phone });
      setFieldValue("phone_no", val[0].mobile_phone);
    }
  };

  handleChangeVehicle = (val) => {
    this.setState({ vehicle_id: val }, this.loadVehicleDetail(val));
  };

  handleChangeDriver = (val, setFieldValue) => {
    this.setState(
      { driver_id: val },
      this.loadDriverDetail(val, setFieldValue)
    );
  };

  handleSearch = (val) => {
    this.loadVehicleList(val);
  };

  updateStatusQt = () => {
    let worksheet_id = this.props.match.params.worksheet_id;
    let qt_id = this.props.match.params.qt_id;
    this.props.updateStatusQt(worksheet_id, qt_id, "return");
  };

  assignJob = () => {
    let worksheet_id = this.props.match.params.worksheet_id;
    let qt_id = this.props.match.params.qt_id;
    const { vehicle_id, driver_id, phone_no } = this.state;
    this.props.assignJob(worksheet_id, qt_id, vehicle_id, driver_id, phone_no);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this.loadVehicleList();
    this.loadDriverList();
  }

  componentDidUpdate() {
    if (this.props.success === true) {
      message.success(i18n[this.props.langBtn].s.success);
      this.goBack();
    }

    if (this.props.success === false) {
      message.error(i18n[this.props.langBtn].f.fail);
    }
  }

  render() {
    const { vehicle } = this.state;
    return (
      <AuthorizeComponent matching_name="quotation_dashboard">
        <GeneralStyledContent>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Breadcrumb
                customeurl={[null, "/dashboard/work-quotation", null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <PageTitleStyled>{translate("assign_job", "assign")}</PageTitleStyled>
            <Spin spinning={this.props.loading}>
              <StyledSearchForm>
                <Formik
                  initialValues={{
                    driver_id: null,
                    vehicle_id: null,
                    phone_no: null,
                  }}
                  enableReinitialize={true}
                  onSubmit={(values) => this.assignJob()}
                  validationSchema={yup.object().shape({
                    driver_id: yup
                      .string()
                      .nullable()
                      .required(translate("driver_required", "d")),
                    vehicle_id: yup
                      .string()
                      .nullable()
                      .required(translate("device_required", "d")),
                    phone_no: yup
                      .string()
                      .nullable()
                      .required(translate("phone_require", "p")),
                  })}
                  render={({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <Form
                      {...formItemLayout}
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      layout="vertical"
                    >
                      <Row gutter={24}>
                        <Col span={6}>
                          <Form.Item
                            label={translate("select_vehicle", "assign")}
                            required={true}
                            validateStatus={
                              touched.vehicle_id && errors.vehicle_id
                                ? "error"
                                : undefined
                            }
                            help={
                              touched.vehicle_id &&
                              errors.vehicle_id &&
                              errors.vehicle_id
                            }
                          >
                            <Select
                              data-cy="vehicle_id"
                              value={this.state.vehicle_id}
                              showSearch
                              onChange={(value) => {
                                setFieldValue("vehicle_id", value);
                                this.handleChangeVehicle(value, setFieldValue);
                              }}
                              onSearch={this.handleSearch}
                            >
                              {this.state.vehicleList.map((val) => (
                                <Option key={val.id} value={val.id}>
                                  {val.plate_no}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24} style={{ marginBottom: "20px" }}>
                        <Col span={6}>
                          <Form.Item
                            label={translate("driver", "assign")}
                            required={true}
                            validateStatus={
                              touched.driver_id && errors.driver_id
                                ? "error"
                                : undefined
                            }
                            help={
                              touched.driver_id && errors.driver_id
                                ? errors.driver_id
                                : undefined
                            }
                          >
                            <Select
                              data-cy="driver_id"
                              value={this.state.driver_id}
                              showSearch
                              onSearch={this.handleSearch}
                              onChange={(value) => {
                                setFieldValue("driver_id", value);
                                this.handleChangeDriver(value, setFieldValue);
                              }}
                            >
                              {this.state.driverList.map((val) => (
                                <Option key={val.id} value={val.id}>
                                  {val.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label={translate("job_phone", "j")}
                            required={true}
                            validateStatus={
                              touched.phone_no && errors.phone_no
                                ? "error"
                                : undefined
                            }
                            help={
                              touched.phone_no && errors.phone_no
                                ? errors.phone_no
                                : undefined
                            }
                          >
                            <Input
                              data-cy="phone_no"
                              type="text"
                              value={values.phone_no}
                              onChange={(e) => {
                                setFieldValue("phone_no", e.target.value);
                                this.setState({ phone_no: e.target.value })
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <DividerStyled />

                      {typeof vehicle.id !== "undefined" && (
                        <Fragment>
                          <Row gutter={[24, 16]}>
                            <Col span={24}>
                              <TitleText>
                                {translate("vehicleDetail", "v")}
                              </TitleText>
                            </Col>
                          </Row>

                          <Row gutter={[24, 16]}>
                            <Col span={24}>
                              <Row>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("vehicleType", "v")}
                                  </SubTitleText>
                                </Col>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("plateNo", "p")}
                                  </SubTitleText>
                                </Col>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("plate_no_province", "veh")}
                                  </SubTitleText>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={8}>
                                  <Text>{translate(vehicle.name_key, "vehicle_type")}</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{vehicle.plate_no}</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{this.props.langBtn === "th" ? vehicle.plate_province_name : vehicle.plate_province_name_en}</Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row gutter={[24, 16]}>
                            <Col span={24}>
                              <Row>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("brand", "veh")}
                                  </SubTitleText>
                                </Col>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("model", "m")}
                                  </SubTitleText>
                                </Col>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("year", "veh")}
                                  </SubTitleText>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={8}>
                                  <Text>{vehicle.brand}</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{vehicle.model}</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{vehicle.year}</Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row gutter={[24, 16]}>
                            <Col span={24}>
                              <Row>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("color", "veh")}
                                  </SubTitleText>
                                </Col>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("expire_act_date", "veh")}
                                  </SubTitleText>
                                </Col>
                                <Col span={8}>
                                  <SubTitleText>
                                    {translate("expire_date", "veh")}
                                  </SubTitleText>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={8}>
                                  <Text>{vehicle.color}</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{vehicle.act_expire_date}</Text>
                                </Col>
                                <Col span={8}>
                                  <Text>{vehicle.insurance_expire_date}</Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row
                            gutter={[24, 24]}
                            style={{ paddingTop: "50px" }}
                          >
                            <Col span={6}>
                              <div style={{ width: "150px", height: "150px", padding: "20px" }}>
                                <img
                                  alt="example"
                                  src={vehicle.file_vehicle}
                                  style={{ width: "100%", height: "100%" }}
                                />
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  {translate("vehicle_picture", "assign")}
                                </p>
                              </div>
                            </Col>
                            <Col span={6} align="center">
                              <div style={{ width: "100%", height: "150px", padding: "20px" }}>
                                <img
                                  alt="example"
                                  src={`/img/vehicletype/${vehicle.name_key}.png`}
                                  style={{ width: "100%", height: "100%" }}
                                />
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  {translate("vehicle_type", "veh")}
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Divider />
                        </Fragment>
                      )}
                      <Row gutter={24}>
                        <Col sm={3}>
                          <Button
                            block
                            data-cy="btn-go-back"
                            type="default"
                            onClick={this.goBack}
                          >
                             {translate("back", "b")}
                          </Button>
                        </Col>
                        { this.props.match.params.qt_id > 0 && 
                        <Col sm={3}>
                          <Button block type="danger" onClick={this.updateStatusQt} data-cy="btn-assign">
                            {translate("return", "assign")}
                          </Button>
                        </Col>
                        }
                        <Col sm={3}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            block
                            data-cy="btn-submit"
                          >
                            {translate("confirm", "c")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </StyledSearchForm>
            </Spin>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportqt, ui: {langBtn} }) => ({
  loading: transportqt.loading,
  success: transportqt.success,
  langBtn,
});

const mapDispatchToProps = {
  updateStatusQt: actions.updateStatusQt.request,
  assignJob: actions.assignJob.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignJobForm);

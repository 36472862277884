import React, { useState, useEffect } from "react";

import { Avatar } from "antd";
import axios from "axios";
import * as moment from "moment";
import NoticeIcon from "ant-design-pro/lib/NoticeIcon";
import "ant-design-pro/dist/ant-design-pro.css";
import { helper_convertDateformat } from "../../../lib/helper";

const getNoti = (apiName, val, seen, offset, limit) =>
  axios.get(
    `${process.env.REACT_APP_NOTIFICATION_CENTER_ENDPOINT}/notification/${apiName}?seen=${seen}&id=${val}&offset=${offset}&limit=${limit}`
  );

function onItemClick(item, tabProps) {
  if (
    typeof item.value.booking_info !== "undefined" &&
    item.value.booking_info.job_status >= 11
  ) {
    setTimeout(function() {
      window.location =
        "/center_pool/detail/" + item.value.booking_info.booking_id;
    }, 100);
  }
}

function onClear(tabTitle) {
  console.log(tabTitle);
}

function removeDuplicates(myArr, prop) {
  let result = myArr.filter(
    (obj) => typeof obj == "object" && typeof obj.draw === "undefined"
  );

  return result.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

const Notification = (props) => {
  const [count, setCount] = useState(0);
  const [noti, setNoti] = useState([]);
  const [latestOpen, setLatestOpen] = useState(null);
  const [notiObj, setnotiObj] = useState([...noti]);

  const handle = () => console.log("mouse over");

  // useEffect(() => {
  //   getNoti(
  //     "get",
  //     JSON.parse(localStorage.getItem("profile")).id,
  //     -1,
  //     0,
  //     5
  //   ).then((response) => {
  //     setNoti(response.data.data);
  //     setnotiObj(removeDuplicates([...response.data.data], "doc_id"));
  //   });
  // }, []);

  function handleClick() {
    setLatestOpen(new Date().getTime());

    // if (
    //   (props.lastestNotificationAt == null && latestOpen == null) ||
    //   latestOpen < props.lastestNotificationAt
    // ) {
    //   getNoti(
    //     "get",
    //     JSON.parse(localStorage.getItem("profile")).id,
    //     -1,
    //     0,
    //     5
    //   ).then((response) => {
    //     setNoti(response.data.data);
    //     setnotiObj(removeDuplicates([...response.data.data], "doc_id"));
    //   });
    // }
  }

  return (
    <div onClick={() => handleClick()}>
      <NoticeIcon
        onItemClick={onItemClick}
        onClear={onClear}
        onViewMore={(tabProps, e) => props.history.push("/notification")}
        // locale={{ loadMore: "see more" }}
      >
        <NoticeIcon.Tab
          // list={[]}
          list={
            notiObj.length > 0
              ? notiObj.slice(0, 5).map((value) => ({
                  id: value.doc_id,
                  value: value,
                  avatar: (
                    <Avatar
                      icon="notification"
                      style={{
                        backgroundColor: "rgb(0, 102, 0, 0.8)",
                        verticalAlign: "middle",
                      }}
                    />
                  ),
                  title:
                    value.seen == 0 ? <b>{value.message}</b> : value.message,
                  datetime: "",

                  type: "Notification",
                  clickClose: true,
                }))
              : []
          }
          title="Notification"
          showClear={false}
          showViewMore={true}
          emptyText="No Data"
          emptyImage="https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg"
        />
      </NoticeIcon>
    </div>
  );
};

export default Notification;

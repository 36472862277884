import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { StyledSearchForm } from "../../../../styled/common-styled";
import { loadProvince, loadAmphur, loadSubAmphur } from "../../../auth/actions";
import FormPersonal from "./FormEditPersonal";
import FormProductowner from "./FormEditProductowner";
import FormTransporter from "./FormEditTransporter"
import {
  loadCompanyProfile,
} from "../actions";

class EditCompanyProfile extends Component {
  state = {
    loading: false,
    phone: "",
    email: "",
  };

  componentDidMount() {
    this.loadData();
    this.getUserInfor();
  }

  loadData = (loading) => {
    this.props.loadCompanyProfile(
      {
        companyID: this.props.profile.company_id,
        accessToken: this.props.accessToken,
      },
      loading
    );
  };

  getUserInfor = () => {
    let self = this;
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getuserbyid`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.profile.company_id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        self.setState({ email: res.email, phone: res.phone });
      });
  };

  render() {
    return (
      <AuthorizeComponent matching_name="company_profile">
          <GeneralStyledContent>
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={24} md={24} lg={24} xl={24}>
                <StyledSearchForm>
                  <Row>
                    <Col span={24}>
                      {this.props.companyProfile.lists.companyInformation[0].types === "personal" ?
                        <FormPersonal
                          loading={this.state.loading}
                          loadProvince={this.props.loadProvince}
                          loadAmphur={this.props.loadAmphur}
                          loadSubAmphur={this.props.loadSubAmphur}
                          provinceLists={this.props.provinceLists}
                          amphurLists={this.props.amphurLists}
                          subamphurLists={this.props.subamphurLists}
                          id={this.props.profile.company_id}
                          companyProfile={this.props.companyProfile}
                          langBtn={this.props.langBtn}
                        /> 
                      : this.props.companyProfile.lists.companyInformation[0].types === "productowner" ?
                        <FormProductowner
                          loading={this.state.loading}
                          loadProvince={this.props.loadProvince}
                          loadAmphur={this.props.loadAmphur}
                          loadSubAmphur={this.props.loadSubAmphur}
                          provinceLists={this.props.provinceLists}
                          amphurLists={this.props.amphurLists}
                          subamphurLists={this.props.subamphurLists}
                          id={this.props.profile.company_id}
                          companyProfile={this.props.companyProfile}
                          langBtn={this.props.langBtn}
                        />
                      : this.props.companyProfile.lists.companyInformation[0].types === "transporter" ?
                        <FormTransporter
                          loading={this.state.loading}
                          loadProvince={this.props.loadProvince}
                          loadAmphur={this.props.loadAmphur}
                          loadSubAmphur={this.props.loadSubAmphur}
                          provinceLists={this.props.provinceLists}
                          amphurLists={this.props.amphurLists}
                          subamphurLists={this.props.subamphurLists}
                          id={this.props.profile.company_id}
                          companyProfile={this.props.companyProfile}
                          langBtn={this.props.langBtn}
                        />
                      : "" }
                    </Col>
                  </Row>
                </StyledSearchForm>
              </Col>
            </Row>
          </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({
    token,
    auth: { provinceLists, amphurLists, subamphurLists, accessToken, profile },
    companyProfile,
    ui: {langBtn}
  }) => ({
    token,
    accessToken,
    profile,
    companyProfile,
    provinceLists,
    amphurLists,
    subamphurLists,
    langBtn
  }),
  {
    loadProvince: loadProvince.request,
    loadAmphur: loadAmphur.request,
    loadSubAmphur: loadSubAmphur.request,
    loadCompanyProfile: loadCompanyProfile.request,
  }
)(EditCompanyProfile);

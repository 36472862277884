import { createTypes, createAction } from "lib/action";

const LOAD_TRANSPORT_WORKSHEET_COMPANY = createTypes("transportworksheetcompany", "load");

const loadWorksheetCompany = {
  request: (page, pageSize, orderBy, orderType, filters) =>
    createAction(LOAD_TRANSPORT_WORKSHEET_COMPANY.REQUEST, { page, pageSize, orderBy, orderType, filters }),
  success: (lists) => createAction(LOAD_TRANSPORT_WORKSHEET_COMPANY.SUCCESS, { lists }),
  failure: () => createAction(LOAD_TRANSPORT_WORKSHEET_COMPANY.FAILURE),
};

export {
  LOAD_TRANSPORT_WORKSHEET_COMPANY,
  loadWorksheetCompany,
};

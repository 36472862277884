import { createTypes, createAction } from "lib/action";

const LOAD_TRANSPORT_WORKINPROGRESS = createTypes("transportworkinprogress", "load");
const LOAD_TRANSPORT_WORKINPROGRESS_DETAIL = createTypes("transportworkinprogress", "show");
const TRANSPORT_COPY_WORKSHEET = createTypes("transportworkinprogress", "copy");
const TRANSPORT_REMOVE_WORKSHEET = createTypes("transportworkinprogress", "remove");

const loadWorkinProgress = {
  request: (page, pageSize, types) =>
    createAction(LOAD_TRANSPORT_WORKINPROGRESS.REQUEST, { page, pageSize, types }),
  success: (lists) => createAction(LOAD_TRANSPORT_WORKINPROGRESS.SUCCESS, { lists }),
  failure: () => createAction(LOAD_TRANSPORT_WORKINPROGRESS.FAILURE),
};

const loadWorkinProgressDetail = {
  request: id =>
    createAction(LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.REQUEST, { id }),
  success: (data) => createAction(LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.SUCCESS, { data }),
  failure: () => createAction(LOAD_TRANSPORT_WORKINPROGRESS_DETAIL.FAILURE),
};

const copyWorksheet = {
  request: id =>
    createAction(TRANSPORT_COPY_WORKSHEET.REQUEST, { id }),
  success: (data) => createAction(TRANSPORT_COPY_WORKSHEET.SUCCESS, { data }),
  failure: () => createAction(TRANSPORT_COPY_WORKSHEET.FAILURE),
}

const removeWorksheet = {
  request: id =>
    createAction(TRANSPORT_REMOVE_WORKSHEET.REQUEST, { id }),
  success: (data) => createAction(TRANSPORT_REMOVE_WORKSHEET.SUCCESS, { data }),
  failure: () => createAction(TRANSPORT_REMOVE_WORKSHEET.FAILURE),
}


export {
  LOAD_TRANSPORT_WORKINPROGRESS,
  loadWorkinProgress,
  LOAD_TRANSPORT_WORKINPROGRESS_DETAIL,
  loadWorkinProgressDetail,
  removeWorksheet,
  copyWorksheet,
  TRANSPORT_REMOVE_WORKSHEET,
  TRANSPORT_COPY_WORKSHEET
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import IndexCompanyVerify from "./Index";
import CompanyVerifyDetail from "./CompanyVerifyDetail";

export default () => (
  <Switch>
    <Route exact path="/companyverify" component={IndexCompanyVerify} />
    <Route exact path="/companyverify/detail/:companyID" component={CompanyVerifyDetail}/>
  </Switch>
);

import React from "react";
import {
  Button,
  Form,
  Input,
  Drawer,
} from "antd";
import LangContext from  "modules/shared/context/langContext";

const IndexFilterDrawer = props => (
  <LangContext.Consumer>
    {
      i18n => <Drawer
                title={<LangContext.Consumer>{i18n=>i18n.f.filter}</LangContext.Consumer>}
                // title="Filter"
                width={520}
                placement="right"
                onClose={props.onClose}
                maskClosable={true}
                visible={props.visible}
                style={{
                  height: "calc(100% - 55px)",
                  overflow: "auto",
                  paddingBottom: 53
                }}
              >
                <Form>
                            <Form.Item label={<LangContext.Consumer>{i18n=>i18n.f.facilityName}</LangContext.Consumer>}>
                              <Input data-cy="name" onChange={props.handleFilterObjFacilityName} />
                            </Form.Item>
 
                            <Form.Item label={<LangContext.Consumer>{i18n=>i18n.l.locationCode}</LangContext.Consumer>}>
                              <Input data-cy="code" onChange={props.handleFilterObjLocationCode} />
                            </Form.Item>

                            <Form.Item label={<LangContext.Consumer>{i18n=>i18n.l.latitude}</LangContext.Consumer>}>
                              <Input data-cy="latitude" onChange={props.handleFilterObjLatitude} />
                            </Form.Item>

                            <Form.Item label={<LangContext.Consumer>{i18n=>i18n.l.longitude}</LangContext.Consumer>}>
                                <Input data-cy="longitude" onChange={props.handleFilterObjLongitude} />
                            </Form.Item>

                            <Form.Item label={<LangContext.Consumer>{i18n=>i18n.a.address}</LangContext.Consumer>}>
                              <Input data-cy="address" onChange={props.handleFilterObjAddress} />
                            </Form.Item>
     
                            <Form.Item label={<LangContext.Consumer>{i18n=>i18n.d.descrip}</LangContext.Consumer>}>
                              <Input data-cy="descrip" onChange={props.handleFilterObjDescription} />
                            </Form.Item>
    
                  {/* <LangContext.Consumer>
                    {
                      i18n =>  */}
                    {/* <Form.Item label="Type location">
                                <Select
                                  showSearch
                                  defaultValue={["-"]}
                                  style={{ width: 475 }}
                                  onChange={props.handleFilteObjTypeLocation}
                                >
                                  <Option key="-" value="Open">
                                    -
                                  </Option>
                                  <Option key="Load_point" value="Load_point">
                                    Load point
                                  </Option>
                                  <Option key="Deliver_point" value="Deliver_point">
                                    Deliver point
                                  </Option>
                                  <Option key="Parking_lot" value="Parking_lot">
                                    Parking lot
                                  </Option>
                                  <Option key="Deliver_point" value="Deliver_point">
                                    Rest area
                                  </Option>
                                  <Option key="Gas_station" value="Gas_station">
                                    Gas station
                                  </Option>
                                  <Option key="Car_service" value="Car_service">
                                    Car service
                                  </Option>
                                  <Option key="Bus_Stop_Point" value="Bus_Stop_Point">
                                    Bus Stop Point
                                  </Option>
                                  <Option key="Bus_Stop_1" value="Bus_Stop_1">
                                    Bus Stop 1
                                  </Option>                                  
                                  <Option key="Bus_Stop_2" value="Bus_Stop_2">
                                    Bus Stop 2
                                  </Option>
                                  <Option key="No_Parking" value="No_Parking">
                                    No Parking
                                  </Option>
                                </Select>
                              </Form.Item> */}
                  {/* }
                  </LangContext.Consumer> */}

                      <Button
                        data-cy="btn-filter-close"
                        style={{
                          marginRight: 8
                        }}
                        onClick={props.onClose}
                      >
                          <LangContext.Consumer>{i18n=>i18n.c.cancel}</LangContext.Consumer>
                      </Button>

                      <Button type="primary" onClick={props.onFilterButtonClick} data-cy="btn-filter-submit">
                        <LangContext.Consumer>{i18n=>i18n.a.apply}</LangContext.Consumer>
                      </Button>
                </Form>
              </Drawer>
    }
  </LangContext.Consumer>
);
export default IndexFilterDrawer;

import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext from "modules/shared/context/langContext";
import { Link } from "react-router-dom";
import axios from "axios";
import IconMarker from "../../../shared/components/map-assets/Marker/IconMarker";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { loadDetailMasterDataPlace, removeLists } from "../actions";
import { TrackingStyledContent } from "../../../../styled/common-styled";
import { StyledSearchForm } from "../../../../styled/common-styled";
import { CustomMap } from "./CustomMap";
import { checkPermissions } from "../../../../lib/helper";
import {
  Button,
  Row,
  Col,
  Input,
  Icon,
  Modal,
  Collapse,
  Card,
  Tooltip,
  Select,
  Spin,
} from "antd";

class FacilityDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapOptions: {
        center: {
          lat: 13.729852,
          lng: 100.559484,
        },
        zoom: 5,
      },
      panelWidth: 575,
      latest_config_change: new Date().getTime(),
      map: null,
      maps: null,
      showTrafficLayer: false,
      mapAPI: { trafficLayer: null, locationLayer: null },
      poiMarker: null,
      lat: 13.723756,
      lng: 100.549419,
      display: "none",
      locationInfoMarkerLists: [],
      poiMarker: [],
      icon_for_location_type: [],
      data: {
        facility_name1: "",
        facility_name2: "",
        facility_name3: "",
        facility_code: "",
        latitude: "",
        longitude: "",
        radius: "100",
        address: "",
        description: "",
        sendSMS: "",
        typeLocation: "",
        icon_default_normal_status_id: "",
        icon_default_normal_status_name: "",
        icon_default_normal_status_url: "",
      },
    };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    let self = this;

    //select Image for location type
    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getlocationtype`, {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      })
      .then(function(response) {
        if (typeof response.data !== "undefined") {
          self.setState({
            icon_for_location_type: response.data.data,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    // End select Image for location type
    this.loadData(true);
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  loadData = (loading) => {
    this.props.loadDetailMasterDataPlace(
      {
        id: this.props.match.params.id,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
    // this.handleSetLatLng();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.placeMasterData.check != nextProps.placeMasterData.check) {
      console.log(nextProps.placeMasterData.value);

      if (
        nextProps.placeMasterData.value.locations_geo_fence != "" &&
        nextProps.placeMasterData.value.locations_geo_fence != null
      ) {
        this.setState({
          geoFene: nextProps.placeMasterData.value.locations_geo_fence,
        });
      }

      this.setState(
        {
          data: {
            ...this.state.data,
            id: nextProps.placeMasterData.value.locations_id,
            facility_name1: nextProps.placeMasterData.value.locations_name,
            facility_name2: nextProps.placeMasterData.value.loations_name2,
            facility_name3: nextProps.placeMasterData.value.loations_name3,
            facility_code: nextProps.placeMasterData.value.locations_code,
            latitude: nextProps.placeMasterData.value.locations_lat,
            longitude: nextProps.placeMasterData.value.locations_lng,
            radius: nextProps.placeMasterData.value.locations_radius,
            address: nextProps.placeMasterData.value.locations_address,
            description: nextProps.placeMasterData.value.location_description,
            sendSMS: nextProps.placeMasterData.value.locations_sms_notify_to,
            typeLocation: nextProps.placeMasterData.value.locations_type,
            icon_default_normal_status_id:
              nextProps.placeMasterData.value.image_id,
            icon_default_normal_status_url:
              nextProps.placeMasterData.value.image_base64,
            icon_default_normal_status_name:
              nextProps.placeMasterData.value.image_name,
          },
          mapOptions: {
            ...this.state.mapOptions,
            center: {
              ...this.state.center,
              lat: nextProps.placeMasterData.value.locations_lat,
              lng: nextProps.placeMasterData.value.locations_lng,
            },
          },
        },
        this.disPlayPolygon(
          nextProps.placeMasterData.value.locations_geo_fence,
          nextProps.placeMasterData.value.locations_lat,
          nextProps.placeMasterData.value.locations_lng,
          nextProps.placeMasterData.value.locations_name,
          nextProps.placeMasterData.value.locations_id,
          nextProps.placeMasterData.value.locations_radius,
          nextProps.placeMasterData.value.image_base64
        )
      );
    }
    return true;
  }

  // เเสดงการจราจร
  handleShowTrafficLayer = (e) => {
    if (this.state.showTrafficLayer) {
      this.state.mapAPI.trafficLayer.setMap(null);
      this.setState({
        showTrafficLayer: false,
      });
    } else {
      this.state.mapAPI.trafficLayer.setMap(this.state.map);
      this.setState({
        showTrafficLayer: true,
      });
    }
  };
  goBack() {
    this.props.history.goBack();
  }
  // เเสดงการจราจร

  // Zoom
  handleZoomFocusIncrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel++;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };

  handleZoomFocusDecrease = (e) => {
    let zoomLevel = this.state.map.getZoom();
    zoomLevel--;
    this.state.map && this.state.map.setZoom(zoomLevel);
  };
  // Zoom
  // Map
  handleApiLoaded = (map, maps) => {
    let poiMarker = null;
    const { lists } = this.props.placeMasterData;
    const check = this.props.placeMasterData.detailMasterloading;

    if (typeof maps == "undefined" || maps == null) window.location.reload();

    this.setState({
      map: map,
      maps: maps,
      mapAPI: { ...this.state.mapAPI, trafficLayer: new maps.TrafficLayer() },
    });
  };

  // Map

  disPlayPolygon = (
    locations_geo_fence,
    locations_lat,
    locations_lng,
    locations_name,
    locations_id,
    locations_radius,
    image_base64
  ) => {
    if (locations_geo_fence == undefined) {
    } else if (locations_geo_fence == "") {
      let myLatLng = new this.state.maps.LatLng(locations_lat, locations_lng);
      let bounds = new this.state.maps.LatLngBounds();
      bounds.extend(myLatLng);

      let locationPolygon = new this.state.maps.Circle({
        strokeColor: "#8415FF",
        strokeOpacity: 1,
        strokeWeight: 0.8,
        fillColor: "#8415FF",
        fillOpacity: 0.2,
        // map: self.state.map,
        center: {
          lat: parseFloat(locations_lat),
          lng: parseFloat(locations_lng),
        },
        radius: locations_radius,
      });

      let radius = locations_radius + locations_radius / 2;
      let scale = radius / 500;
      let zoomLevel = 16 - Math.log(scale) / Math.log(2);

      locationPolygon.setMap(this.state.map);
      this.state.map.fitBounds(bounds);
      this.state.map.setZoom(zoomLevel);
    } else {
      console.log("Polygon");
      let bermudaTriangle = null;
      let arr = locations_geo_fence.split(",");
      let convertedStreet = [];
      let bounds = new this.state.maps.LatLngBounds();
      let myLatLng = new this.state.maps.LatLng(locations_lat, locations_lng);

      for (let i = 0; i < arr.length; i = i + 2) {
        convertedStreet.push(
          new this.state.maps.LatLng(parseFloat(arr[i]), parseFloat(arr[i + 1]))
        );
        bounds.extend(convertedStreet[i / 2]);
      }

      // this.disPlayMaker(locations_id, locations_lat, locations_lng, locations_name);

      bermudaTriangle = new this.state.maps.Polygon({
        paths: convertedStreet,
        strokeColor: "#8415FF",
        strokeOpacity: 1,
        strokeWeight: 0.8,
        fillColor: "#8415FF",
        fillOpacity: 0.2,
        fillOpacity: 0.35,
      });

      bermudaTriangle.setMap(this.state.map);
      this.state.map.fitBounds(bounds);
    }
  };

  // disPlayMaker = (locations_id, locations_lat, locations_lng, locations_name) => {
  //     console.log('Maker');
  // };

  render() {
    const data = this.props.placeMasterData.value;

    return (
      <AuthorizeComponent matching_name="location">
        <TrackingStyledContent>
          <Spin spinning={this.props.placeMasterData.check}>
            <Row>
              <Col
                sm={24}
                md={9}
                lg={9}
                xl={9}
                style={{
                  margin: "0px 0px 0px 0px",
                  // padding: "5px 10px 5px 10px"
                }}
              >
                <Row style={{ background: "#ECECEC" }}>
                  <LangContext.Consumer>
                    {(i18n) => (
                      <Card
                        title={
                          <LangContext.Consumer>
                            {(i18n) => i18n.l.locationInformation}
                          </LangContext.Consumer>
                        }
                        bordered={false}
                      >
                        <Row
                          style={{ marginTop: "17px", marginBottom: "20px" }}
                        >
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.t.typeLocation + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          {/* <Col span={16} style={{padding: "5px"}} >{data.locations_type}</Col> */}
                          {// this.state.icon_for_location_type.map((data, i) =>
                          this.state.data.typeLocation == "" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              -
                            </Col>
                          ) : this.state.data.typeLocation == "BS" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.b.busStopPoint}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "BS1" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.b.busStop1}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "BS2" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.b.busStop2}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "CarService" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.c.carService}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "GasStation" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.g.gasStation}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "NoParking" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.n.noParking}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "ParkingLot" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.parkingLot}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "Plant" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.l.loadpoint}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "RestArea" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.r.restArea}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "Site" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.d.deliverPoint}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "Temporary" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.l.locationTemporary}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "OnSite" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.o.onSite}
                              </LangContext.Consumer>
                            </Col>
                          ) : this.state.data.typeLocation == "PreparePoint" ? (
                            <Col span={16} style={{ padding: "5px" }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.p.preparePoint}
                              </LangContext.Consumer>
                            </Col>
                          ) : (
                            ""
                          )
                          // )
                          }
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.f.facilityName + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.locations_name}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.f.facilityName2 + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.loations_name2}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.l.locationCode + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.locations_code}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.l.latitude + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.locations_lat}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.l.longitude + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.locations_lng}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.p.polyGonRadius + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.locations_radius}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.a.address + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.locations_address}
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: "20px" }}>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          >
                            <b>
                              {
                                <LangContext.Consumer>
                                  {(i18n) => i18n.d.descrip + " :"}
                                </LangContext.Consumer>
                              }
                            </b>
                          </Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            {data.location_description}
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={8}
                            style={{ padding: "5px" }}
                            align="right"
                          ></Col>
                          <Col span={16} style={{ padding: "5px" }}>
                            <Link to={`/location`}>
                              <Button
                                data-cy="btn-go-back"
                                type="default"
                                style={{ marginRight: "10px" }}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.b.back}
                                </LangContext.Consumer>
                              </Button>
                            </Link>
                            {checkPermissions(
                              this.props.auth.profile.permissions,
                              "location",
                              "can_edit"
                            ) == true ? (
                              <Link
                                to={`/location/edit/${this.props.match.params.id}`}
                              >
                                <Button type="primary" data-cy="btn-edit">
                                  <LangContext.Consumer>
                                    {(i18n) => i18n.e.edit}
                                  </LangContext.Consumer>
                                </Button>
                              </Link>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </LangContext.Consumer>
                </Row>
              </Col>
              <Col sm={24} md={15} lg={15} xl={15}>
                <StyledSearchForm style={{ width: "100%", padding: "8px" }}>
                  <div
                    style={{
                      position: "absolute",
                      marginTop: "4px",
                      top: "0px",
                      right: "0px",
                      zIndex: "12",
                    }}
                  >
                    <div
                      className="edge-btn-group"
                      style={{
                        position: "absolute",
                        marginTop: "50%",
                        right: "5px",
                        zIndex: "12",
                      }}
                    >
                      <Tooltip
                        placement="left"
                        title={
                          <LangContext.Consumer>
                            {(i18n) => i18n.s.showTraffic}
                          </LangContext.Consumer>
                        }
                      >
                        <button data-cy="btn-traffic" onClick={this.handleShowTrafficLayer}>
                          <Icon type="swap" />
                        </button>
                      </Tooltip>

                      <Tooltip
                        placement="left"
                        title={
                          <LangContext.Consumer>
                            {(i18n) => i18n.z.zoomIn}
                          </LangContext.Consumer>
                        }
                      >
                        <button data-cy="btn-zoom-in" onClick={this.handleZoomFocusIncrease}>
                          <Icon type="zoom-in" />
                        </button>
                      </Tooltip>

                      <Tooltip
                        placement="left"
                        title={
                          <LangContext.Consumer>
                            {(i18n) => i18n.z.zoomOut}
                          </LangContext.Consumer>
                        }
                      >
                        <button data-cy="btn-zoom-out" onClick={this.handleZoomFocusDecrease}>
                          <Icon type="zoom-out" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  <CustomMap
                    // loadedDateTime={this.props.monitoringState.loadedDateTime}
                    bootstrapURLKeys={{
                      key: this.props.auth.profile.google_map_key,
                      libraries: process.env.REACT_APP_GOOGLE_MAP_USING_LIBRARIES.split(
                        ","
                      ),
                      region: "thailand",
                      language: "th",
                    }}
                    defaultCenter={{ lat: 13.729852, lng: 100.559484 }}
                    defaultZoom={this.state.mapOptions.zoom}
                    style={{
                      width: "100%",
                      minHeight: `${window.innerHeight - 50}px`,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      this.handleApiLoaded(map, maps)
                    }
                    options={{
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false,
                      maxZoom: 19,
                    }}
                  >
                    <IconMarker
                      key={data.locations_id}
                      lat={data.locations_lat}
                      lng={data.locations_lng}
                      title={data.locations_name}
                      image={data.image_base64}
                    />
                  </CustomMap>
                </StyledSearchForm>
              </Col>
            </Row>
          </Spin>
        </TrackingStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ placeMasterData, auth }) => ({ placeMasterData, auth }),
  {
    loadDetailMasterDataPlace: loadDetailMasterDataPlace.request,
    removeLists,
  }
)(FacilityDetail);

import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Facility from "./Index";
import ManageFacility from "./ManageFacility";
import DetailFaciliity from "./FacilityDetail";
import EditFacility from "./EditFacility";
import TrackingCreateLocation from "./ManageFacility";

export default () => (
    <Router>
      <div>
        <Route exact path="/location" component={Facility} />
        <Route exact path="/location/create" component={ManageFacility} />
        <Route exact path="/location/detail/:id" component={DetailFaciliity} />
        <Route exact path="/location/edit/:id" component={EditFacility} />
        <Route exact path="/location/create/:lat/:lng" component={TrackingCreateLocation} />
      </div>
    </Router>
  );
import React, { Component } from "react";
import { Upload, Icon, message } from "antd";
import LangContext from "modules/shared/context/langContext";
import { NormalText as Text } from "../../../../../../styled/backhaul-styled";
import { translate } from "../../../../../../lib/helper"

const { Dragger } = Upload;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject();
  });
}

export class UploadQT extends Component {
  state = {
    file_preview: "",
    file_preview_visible: false,
    file_list: [],
  };

  handleFrontVehiclePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      file_preview: file.url || file.preview,
      file_preview_visible: true,
    });
  };

  render() {
    return (
      <div style={{ marginBottom: "90px", width: "153px", height: "165px" }}>
        <Dragger
          name="avatar"
          fileList={this.state.file_list}
          beforeUpload={(file) => {
            return false;
          }}
          onChange={({ file, fileList }) => {
            if (fileList.length > 1) {
              message.error(translate("upload_amount1_support", "worksheet"));
            }

            const isCheckTypeFile =
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === ".pdf" ||
              file.type === "application/pdf";

            const checkSizeFile = file.size / 1024 <= 5120;
            if (isCheckTypeFile && checkSizeFile && fileList.length <= 1) {
              this.props.setValue("file", [file]);
              this.setState({
                file_list: fileList,
              });
            }
          }}
          onRemove={() => {
            this.setState({
              file_list: [],
            });
            this.props.setValue("file", []);
          }}
        >
          <p style={{ padding: "29px" }}>
            <Text>
              <Icon type="upload" />
            </Text>
            &nbsp;&nbsp;
            <Text style={{ lineHeight: "29px" }}>
              <LangContext.Consumer>
                {(i18n) => i18n.j.job_upload_qt}
              </LangContext.Consumer>
            </Text>
          </p>

        </Dragger>
      </div>
    );
  }
}

export default UploadQT;

import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { numberFormat } from "../../../../lib/helper";
import { checkPermissions } from "../../../../lib/helper";

import {
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Icon,
  Tooltip,
  Drawer,
  message,
  Divider
} from "antd";

import axios from "axios";
import { Link } from "react-router-dom";
import Filter from "./Filter";
const columnStyle = { fontSize: "12px", fontWeight: "400" };

class Index extends Component {
  state = {
    visible: false,
    vehicle_type: [],
    unit: [],
    page: 1,
    pageSize: 10,
    orderBy: "e.updated_at",
    orderType: "desc",
    filterObj: {
      vehicle_type_id: "",
      origin_location: "",
      destination_location: "",
    },
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onCloseSearch = (resetForm) => {
    this.setState({
      visible: false,
    });
  };

  filterExpense = (values) => {
    const { vehicle_type_id, origin_location, destination_location } = values;

    this.setState({
      filterObj: {
        vehicle_type_id,
        origin_location,
        destination_location,
      },
    });
    this.props.loadExpense(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        orderType: this.state.orderType,
        filterObj: {
          vehicle_type_id,
          origin_location,
          destination_location,
        },
      },
      true
    );
  };

  componentDidMount() {
    this.props.loadExpense(this.state, true);
    //this.props.loadReportSchedulereport();
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataResponse } = this.props.expense;

    if (
      dataResponse.action_type == "create" ||
      dataResponse.action_type == "update"
    ) {
      if (dataResponse.status == "success") {
        message.success(i18n[this.props.langBtn].c.create_success);
      } else if (dataResponse.status == "fail") {
        message.fail(dataResponse.msg);
      }
      this.props.clearDataResponse();
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      page: pagination.current,
    });

    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = this.state.orderBy;
      orderType = this.state.orderType;
    } else {
      switch (sorter.columnKey) {
        case "vehicle_type":
          orderBy = "v.name";
          break;
        case "origin_name":
          orderBy = "l.name";
          break;
        case "destination_name":
          orderBy = "l2.name";
          break;
        case "distance":
          orderBy = "e.distance";
          break;
        case "price":
          orderBy = "e.price";
          break;
        case "unit_name":
          orderBy = "u.name";
          break;
        default:
          break;
      }

      this.setState({
        orderBy: orderBy,
        orderType: sorter.order == "descend" ? "descend" : "ascend",
      });

      orderBy = orderBy;
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.props.loadExpense(
      {
        page: pagination.current,
        pageSize: this.state.pageSize,
        searchName: "",
        orderBy: orderBy,
        orderType: orderType,
        filterObj: {
          vehicle_type_id: this.state.filterObj.vehicle_type_id,
          origin_location: this.state.filterObj.origin_location,
          destination_location: this.state.filterObj.destination_location,
        },
      },
      true
    );
  };

  unit = () => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/masterdata/unit`)
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let unit = response.data.data.map((type) => {
            return {
              id: type.vehicle_types_id,
              name: type.vehicle_types_name,
            };
          });
          self.setState({ unit });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  deleteExpense = (id) => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/route-expense/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        self.props.loadExpense(
          {
            page: 1,
            pageSize: self.state.pageSize,
            orderBy: self.state.orderBy,
            orderType: self.state.orderType,
            filterObj: {
              vehicle_type_id: self.state.filterObj.vehicle_type_id,
              origin_location: self.state.filterObj.origin_location,
              destination_location: self.state.filterObj.destination_location,
            },
          },
          true
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const columns = [
      {
        title: "#",
        dataIndex: "row_number",
        width: 8,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleType}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_type",
        sorter: true,
        sortOrder: this.state.orderBy === "v.name" && this.state.orderType,
        width: "20%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div><LangContext.Consumer>
            {(i18n) => i18n.vehicle_type[text]}
          </LangContext.Consumer></div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.expense.origin_location}
          </LangContext.Consumer>
        ),
        dataIndex: "origin_name",
        sorter: true,
        sortOrder: this.state.orderBy === "l.name" && this.state.orderType,
        width: "20%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.expense.destination_location}
          </LangContext.Consumer>
        ),
        dataIndex: "destination_name",
        sorter: true,
        sortOrder: this.state.orderBy === "l2.name" && this.state.orderType,
        width: "20%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.expense.distance}
          </LangContext.Consumer>
        ),
        dataIndex: "distance",
        sorter: true,
        sortOrder: this.state.orderBy === "e.distance" && this.state.orderType,
        width: "10%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.expense.price}
          </LangContext.Consumer>
        ),
        dataIndex: "price",
        sorter: true,
        sortOrder: this.state.orderBy === "e.price" && this.state.orderType,
        width: "10%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div>{numberFormat(text)}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.expense.price_unit}
          </LangContext.Consumer>
        ),
        dataIndex: "unit_name",
        sorter: false,
        width: "8%",
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: <div><LangContext.Consumer>
              {(i18n) => i18n.m_unit_price[text]}
            </LangContext.Consumer></div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.a.action}</LangContext.Consumer>
        ),
        dataIndex: "action",
        key: "action",
        sorter: false,
        width: "8%",
        render: (text, record, index) => {
          return (
            <div>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "expense",
                "can_view"
              ) == true ? (
                <Tooltip
                  placement="left"
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.detail}
                    </LangContext.Consumer>
                  }
                  arrowPointAtCenter
                >
                  <Link
                    data-cy={`btn-view-${record.id}`}
                    to={`/route-expense/detail/${record.id}`}
                    style={{ padding: "0px 5px 0px 5px" }}
                  >
                    <Icon type="file-text" />
                  </Link>
                </Tooltip>
              ) : (
                ""
              )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                "expense",
                "can_edit"
              ) === true ? (
                <LangContext.Consumer>
                  {(i18n) => (
                    <Tooltip
                      placement="left"
                      title={i18n.e.edit}
                      arrowPointAtCenter
                    >
                      <Link data-cy={`btn-edit-${record.id}`} to={`/route-expense/edit/${record.id}`} style={{ padding: "0px 5px 0px 5px" }}>
                        <Icon type="edit" />
                      </Link>
                    </Tooltip>
                  )}
                </LangContext.Consumer>
              ) : (
                ""
              )}

              {checkPermissions(
                this.props.auth.profile.permissions,
                "expense",
                "can_delete"
              ) === true ? (
                <LangContext.Consumer>
                  {(i18n) => (
                    <Tooltip
                      placement="left"
                      title={i18n.d.delete}
                      arrowPointAtCenter
                    >
                      <Popconfirm
                        title={i18n.c.confirmToDeleteThisItem}
                        onConfirm={() => {
                          let self = this;
                          self.deleteExpense(record.id);
                        }}
                        cancelText={i18n.c.cancel}
                        okText={i18n.o.ok}
                      >
                        <a
                          data-cy={`btn-del-${record.id}`}
                          href="javascript:;"
                          style={{ padding: "0px 5px 0px 5px" }}
                        >
                          <Icon type="delete" />
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  )}
                </LangContext.Consumer>
              ) : (
                ""
              )}
            </div>
          );
        },
        // width: "5%"
      },
    ];

    return (
      <div>
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
              <Col span={24}>
                <Divider orientation="left">
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.expenses}
                    </LangContext.Consumer>
                </Divider>
              </Col>
            </Row>

          <Row gutter={24} type="flex" justify="end" style={{ height: "38px" }}>
            <Col span={22}>
              {checkPermissions(
                this.props.auth.profile.permissions,
                "expense",
                "can_add"
              ) === true ? (
                <Link to="/route-expense/create/">
                  <Button type="primary" icon="plus" size="small" data-cy="btn-create">
                    <span></span>
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.new}
                    </LangContext.Consumer>
                  </Button>
                </Link>
              ) : (
                ""
              )}
            </Col>

            <Col span={2}>
              <Button data-btn="btn-filter" icon="filter" size="small" onClick={this.showDrawer}>
                <LangContext.Consumer>
                  {(i18n) => i18n.f.filter}
                </LangContext.Consumer>
              </Button>
            </Col>
          </Row>
          <Table
            data-cy="data-table"
            bordered
            //size="small"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={this.props.expense.expenseLists}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.props.expense.expenseTotal,
              current: this.state.page,
            }}
            loading={this.props.expense.expenseLoading}
            onChange={this.handleTableChange}
          />

          <div>
            <LangContext.Consumer>
              {(i18n) => (
                <Drawer
                  title={i18n.f.filter}
                  width={450}
                  onClose={this.onCloseSearch}
                  visible={this.state.visible}
                >
                  <Filter
                    OnClose={this.onCloseSearch}
                    onSubmit={this.filterExpense}
                    vehicle_type={this.state.vehicle_type}
                  />
                </Drawer>
              )}
            </LangContext.Consumer>
          </div>
        </GeneralStyledContent>
      </div>
    );
  }
}

const mapStateToProps = ({ expense, auth, ui: {langBtn} }) => ({
  expense,
  auth,
  langBtn,
});

const mapDispatchToProps = {
  loadExpense: actions.loadExpense.request,
  clearDataResponse: actions.clearDataResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

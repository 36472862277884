import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadDeviceUser(action) {
  const {
    data: {
      page,
      pageSize,
      companyID,
      orderBy,
      orderType,
      accessToken,
      filterObj,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser`,
      {
        page,
        pageSize,
        companyID,
        orderBy,
        orderType,
        filterObj,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDeviceUser.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDeviceUser.failure());
  }
}

function* createDeviceUser(action) {
  const {
    data: {
      company_id,
      user_id,
      title_name,
      contact_firstname,
      contact_lastname,
      phone,
      driver_code,
      id_card,
      id_card_expire_date,
      id_driver_license,
      driver_license_expire_date,
      file_copy_driver_license,
      accessToken,
    },
    loading,
  } = action.payload;

  const formData = new FormData();

  file_copy_driver_license.forEach((File) => {
    formData.append("file_copy_driver_license", File);
  });
  formData.append("user_id", user_id);
  formData.append("company_id", company_id);
  formData.append("title_name", title_name);
  formData.append("contact_firstname", contact_firstname);
  formData.append("contact_lastname", contact_lastname);
  formData.append("phone", phone);
  formData.append("driver_code", driver_code);
  formData.append("id_card", id_card);
  formData.append("id_card_expire_date", id_card_expire_date);
  formData.append("id_driver_license", id_driver_license);
  formData.append("driver_license_expire_date", driver_license_expire_date);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/adddeviceuser`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    loading(res.data);
    yield put(actions.createDeviceUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createDeviceUser.failure());
  }
}

function* updateDeviceUser(action) {
  const {
    data: {
      device_user_id,
      company_id,
      user_id,
      title_name,
      contact_firstname,
      contact_lastname,
      phone,
      driver_code,
      id_card,
      id_card_expire_date,
      id_driver_license,
      driver_license_expire_date,
      file_copy_driver_license,
      change_file_copy_driver_license,
      accessToken,
    },
    loading,
  } = action.payload;

  const formData = new FormData();

  if (change_file_copy_driver_license === true) {
    file_copy_driver_license.forEach((File) => {
      formData.append("file_copy_driver_license", File);
    });
  } else {
    formData.append("file_copy_driver_license", null);
  }
  formData.append("user_id", user_id);
  formData.append("device_user_id", device_user_id);
  formData.append("company_id", company_id);
  formData.append("title_name", title_name);
  formData.append("contact_firstname", contact_firstname);
  formData.append("contact_lastname", contact_lastname);
  formData.append("phone", phone);
  formData.append("driver_code", driver_code);
  formData.append("id_card", id_card);
  formData.append("id_card_expire_date", id_card_expire_date);
  formData.append("id_driver_license", id_driver_license);
  formData.append("driver_license_expire_date", driver_license_expire_date);

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatedrivinglicense`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    loading(res.data);
    yield put(actions.updateDeviceUser.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updateDeviceUser.failure());
  }
}

function* loadAutocompleteLocation(action) {
  const { url, companyID, accessToken, queryString } = action.payload;
  try {
    if (queryString == "" || queryString.length <= 1) {
      yield put(actions.loadAutocompleteLocation.success([]));
    } else {
      const res = yield call(
        axios.get,
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}/${companyID}/${queryString}`,

        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      yield put(actions.loadAutocompleteLocation.success(res.data));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadAutocompleteLocation.failure());
  }
}

function* loadDeviceuserDetail(action) {
  const {
    data: { id, accessToken },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/deviceuser/detali`,
      {
        id,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDeviceuserDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDeviceuserDetail.failure());
  }
}

function* loadDatadrivinglicans(action) {
  const {
    data: { id, accessToken, page, pageSize },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/drivinglicense`,
      {
        id,
        page,
        pageSize,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDatadrivinglicans.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatadrivinglicans.failure());
  }
}

function* loadDatadrivingtranining(action) {
  const {
    data: { id, accessToken, page, pageSize },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/drivingtraining`,
      {
        id,
        page,
        pageSize,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    yield put(actions.loadDatadrivingtranining.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadDatadrivingtranining.failure());
  }
}

export default function* watchDeviceUser() {
  yield all([
    takeEvery(actions.LOAD_DEVICE_USER.REQUEST, loadDeviceUser),
    takeEvery(actions.CREATE_DEVICE_USER.REQUEST, createDeviceUser),
    takeEvery(actions.LOAD_DEVICE_USER_DETAIL.REQUEST, loadDeviceuserDetail),
    takeEvery(actions.LOAD_DRIVEING_LICENSEL.REQUEST, loadDatadrivinglicans),
    takeEvery(actions.LOAD_DRIVEING_TRAINING.REQUEST, loadDatadrivingtranining),
    takeEvery(actions.UPDATE_DEVICE_USER.REQUEST, updateDeviceUser),
    takeEvery(actions.LOAD_AUTOCOMPLETE.REQUEST, loadAutocompleteLocation),
  ]);
}

export {
  loadDeviceUser,
  createDeviceUser,
  loadDeviceuserDetail,
  loadDatadrivinglicans,
  loadDatadrivingtranining,
  updateDeviceUser,
  loadAutocompleteLocation,
};

import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Input, Alert, Icon, Col, Row } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Link } from "react-router-dom";

export default class Forms extends Component {
  state = {};

  render() {
    const { onSubmit } = this.props;
    return (
      <div>
        <LangContext.Consumer>
          {(i18n) => (
            <div>
              <h1>{i18n.f.forgotpassword}</h1>
              <Formik
                initialValues={{
                  email: "",
                }}
                validate={(values) => {
                  let errors = {};
                  if (!values.email) {
                    errors.email = i18n.e.emailorphone_require;
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      values.email
                    )
                  ) {
                    if (/^\d+$/.test(values.email) === false) {
                      errors.email = i18n.e.emailorphone_invalid;
                    }
                  }

                  return errors;
                }}
                validationSchema={yup.object().shape({})}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
                render={({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          //required={true}
                          validateStatus={
                            touched.email && errors.email && "error"
                          }
                          help={touched.email && errors.email}
                        >
                          <Input
                            data-cy="email"
                            type="text"
                            name="email"
                            placeholder={i18n.e.emailorphone}
                            values={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={
                              touched.email && errors.email
                                ? "invalid"
                                : undefined
                            }
                            style={{ margin: "0px 0px 5px 0px" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Button
                      data-cy="btn-go-back"
                      type="default"
                      style={{
                        width: "20%",
                        margin: "50px 20px 0px 0px",
                      }}
                    >
                      <Link to="/">{i18n.b.back}</Link>
                    </Button>

                    <Button
                      data-cy="btn-submit"
                      htmlType="submit"
                      type="primary"
                      style={{ width: "20%", margin: "0px 0px 15px 0px" }}
                    >
                      {i18n.s.send}
                    </Button>
                  </Form>
                )}
              />
            </div>
          )}
        </LangContext.Consumer>
      </div>
    );
  }
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import CompanyProfile from "../../../../../master-data/companyprofile/components/Index"

export default () => (
    <Switch>
        <Route exact path="/productowner/addcompanygroup" component={Layout} />
        <Route exact path="/productowner/addcompanygroup/view/:id" component={CompanyProfile} />
    </Switch>
);
import {
  LOAD_TRANSPORT_MARKETS,
  SHOW_TRANSPORT_MARKET,
  UPLOAD_QT_TRANSPORT_MARKET,
} from "./actions";

const initialState = {
  lists: [],
  totalRows: 0,
  loading: false,
  data: {},
  success: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSPORT_MARKETS.REQUEST:
      return {
        ...state,
        success: null,
        loading: true,
      };
    case LOAD_TRANSPORT_MARKETS.SUCCESS:
      return {
        lists: action.payload.lists.data,
        totalRows: action.payload.lists.total,
        loading: false,
      };
    case LOAD_TRANSPORT_MARKETS.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SHOW_TRANSPORT_MARKET.REQUEST:
      return {
        ...state,
        success: null,
        loading: true,
      };
    case SHOW_TRANSPORT_MARKET.SUCCESS:
      return {
        data: action.payload.data.data,
        loading: false,
      };
    case SHOW_TRANSPORT_MARKET.FAILURE:
      return {
        ...state,
        loading: false,
      };

      case UPLOAD_QT_TRANSPORT_MARKET.REQUEST:
        return {
          ...state,
          success: null,
          loading: true,
        };
      case UPLOAD_QT_TRANSPORT_MARKET.SUCCESS:
        return {
          data: action.payload.data.data,
          loading: false,
          success: true
        };
      case UPLOAD_QT_TRANSPORT_MARKET.FAILURE:
        return {
          ...state,
          loading: false,
          success: false
        };
  

    default:
      return state;
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Formik } from "formik";

import { Row, Col, Spin, Form, message, InputNumber, Input, Button } from "antd";
import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import {
  StyledSearchForm,
  GeneralStyledContent,
  PageTitleStyled,
  NormalText as Text,
} from "../../../../../../styled/backhaul-styled";

import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { i18n } from "modules/shared/context/langContext";

import JobDetail from "./JobDetail";
import UploadQT from "./UploadQT";
import { translate } from "../../../../../../lib/helper"
export class QuotationForm extends Component {
  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.showTransportMarket(id);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidUpdate() {
    if (this.props.success === true) {
      message.success(i18n[this.props.langBtn].s.success);
      this.props.history.goBack();
    }

    if (this.props.success === false) {
      message.error(i18n[this.props.langBtn].f.fail);
    }
  }

  render() {
    
    let str = window.location.pathname.split("/"),
        url = `/${str[1]}/${str[2]}`

    return (
      <AuthorizeComponent matching_name="transport_market_dashboard">
        <GeneralStyledContent>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Breadcrumb
                customeurl={[null, url, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <PageTitleStyled>
              {translate("job_upload_qt", "j")}
            </PageTitleStyled>
            <Spin spinning={this.props.loading}>
              <StyledSearchForm>
                <Formik
                  initialValues={{
                    file: [],
                    price: "",
                  }}
                  enableReinitialize={true}
                  onSubmit={
                    (values) =>
                    this.props.uploadQuotation(
                      this.props.match.params.id,
                      values.file,
                      values.price,
                      this.props.data.document_number
                    )
                  }
                  render={({ values, setFieldValue, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off">
                      <Row gutter={16}>
                        <Col span={24}>
                          {typeof this.props.data !== "undefined" && (
                            <JobDetail data={this.props.data} />
                          )}
                        </Col>
                      </Row>
                      {typeof this.props.data !== "undefined" &&
                        this.props.data.status === "A" && (
                          <Row gutter={[24, 24]}>
                            <Col span={4}>
                              <InputNumber
                                data-cy="price"
                                style={{ width: "100%" }}
                                name="price"
                                type="number"                                   
                                min={0}
                                max={9999999}
                                step={0.01}
                                value={values.price}
                                onChange={(value) => {

                                  if(value !== null) {
                                    if(value.toString().match(/\d+/g) || value === "") {
                                      setFieldValue("price", value);
                                    }
                                  }

                                }}
                              />
                            </Col>
                            <Col span={6}>
                              <Text>
                                {translate("job_price_per_times", "j")}
                              </Text>
                            </Col>
                            <Col span={8}>
                              <UploadQT setValue={setFieldValue} />
                            </Col>
                          </Row>
                        )}
                      <Row gutter={16}>
                        <Col sm={24} md={5} lg={5} xl={5}>
                          <Form.Item>
                            <Button
                              data-cy="btn-go-back"
                              type="default"
                              style={{
                                marginRight: "10px",
                              }}
                              onClick={this.goBack}
                            >
                              {translate("back", "b")}
                            </Button>

                            {typeof this.props.data !== "undefined" &&
                              this.props.data.status === "A" && (
                                <Button
                                  data-cy="btn-quotation"
                                  type="primary"
                                  htmlType="submit"
                                  disabled={values.file.length === 0 || values.price <= 0 || values.price == ""}
                                >
                                  {translate("quotation", "q")}
                                </Button>
                              )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </StyledSearchForm>
            </Spin>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportmarket, ui: {langBtn} }) => ({
  data: transportmarket.data,
  loading: transportmarket.loading,
  success: transportmarket.success,
  langBtn,
});

const mapDispatchToProps = {
  showTransportMarket: actions.showTransportMarket.request,
  uploadQuotation: actions.uploadQuotation.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationForm);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Col } from "antd";

import {
  StyledDiv,
  StyledRow,
  StyledLogoUserType,
} from "../../../styled/common-styled";
import { setLoading } from "../actions";
import LangContext, { i18n } from "modules/shared/context/langContext";

class Usertype extends Component {
  state = {};

  render() {
    const styleBorder = {
      backgroundColor:"#fafbfb", 
      minHeight: "calc( 100vh - 350px)",
      borderRadius: "15px",
      padding:"5% 10% ",
      textAlign:"center",
      boxShadow: "10px 16px 23px -12px rgba(0, 0, 0, 0.13)",
    }

    return (
      <StyledDiv >
        <StyledRow>
          <Col span={5}/>
          <Col span={14} style={{padding:"40px 0px 0px 0px"}}>
            <div style={styleBorder}>
              {/* <StyledRow style={{width:"100%",textAlign:"center"}} >  
                <Col xs={24} sm={20} md={20} lg={20} xl={20}> */}
                    <h2 style={{color:"#2f67dc",fontWeight:"bold",fontSize:"22px"}}>
                      สมัครสมาชิก KhonKhong
                      {/* <LangContext.Consumer>
                        {(i18n) => i18n.s.select_user_type}
                      </LangContext.Consumer> */}
                    </h2>
                    <h3 style={{color:"#2f67dc",fontSize:"22px"}}>
                      โปรดเลือกรูปแบบสมัครสมาชิก
                    </h3>
                    <Link
                      data-cy="productowner"
                      to={{
                        pathname: "/signup/productowner/signup",
                      }}
                      
                    >
                      <StyledLogoUserType src="/img/backhaul/productowner2.png" 
                         style={{ padding: "60px 20px 0px 0px",width:"200px" }}
                       />
                    </Link>
                    {/* <Link
                    data-cy="personal"
                      to={{
                        pathname: "/signup/personal/signup",
                      }}
                      style={{ padding: "0px 20px 0px 0px" }}
                    >
                      <StyledLogoUserType src="/img/backhaul/personal.png" />
                    </Link> */}
                    <Link
                    data-cy="transporter"
                      to={{
                        pathname: "/signup/transporter/signup",
                      }}
                    >
                      <StyledLogoUserType src="/img/backhaul/transpoter2.png" 
                        style={{ padding: "60px 20px 0px 0px",width:"200px" }}
                      />
                    </Link>
                  {/* </Col>
              </StyledRow> */}
            </div>

          </Col>
       
        </StyledRow>
      </StyledDiv>
    );
  }
}

  export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    setLoading: setLoading,
  }
)(Usertype);

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ACCESSTOKEN,
  COMPANY_ID,
  USER_ID
} from "../../../../../constants/local_storage";
import * as actions from "./actions";

function* loadTransportQT(action) {
  const { page, pageSize, orderBy, orderType, filters } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loadcompanyqt`,
      {
        page,
        pageSize,
        company_id:COMPANY_ID,
        orderBy, 
        orderType, 
        filters
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.loadTransportQT.success(res.data));
  } catch (err) {
    yield put(actions.loadTransportQT.failure());
  }
}

function* updateStatusQt(action) {
  const { worksheet_id, qt_id, status } = action.payload;
  let url = ""
  switch(status) {
    case "return": url = "dashboardtransport/returnjob";break;
    case "cancel": url = "dashboardtransport/cancelqt";break;
    default:break;
  }
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/${url}`,
      {
        worksheet_id, company_id:COMPANY_ID, qt_id, status
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.updateStatusQt.success(res.data));
  } catch (err) {
    yield put(actions.updateStatusQt.failure());
  }
}

function* showTransportQT(action) {
  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/loadworksheet/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.showTransportQT.success(res.data));
  } catch (err) {
    yield put(actions.showTransportQT.failure());
  }
}

function* assignJob(action) {
  const { worksheet_id, qt_id, vehicle_id, driver_id, phone_no } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/dashboardtransport/assign_job`,
      {
        worksheet_id, 
        transporter_id: COMPANY_ID,
        qt_id, 
        vehicle_id, 
        driver_id, 
        driver_phone: phone_no, 
        assign_user_id: USER_ID
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.assignJob.success(res.data));
  } catch (err) {
    yield put(actions.assignJob.failure());
  }
}



export default function* watchTransportQTState() {
  yield all([
    takeEvery(actions.LOAD_TRANSPORT_QUOTATION.REQUEST, loadTransportQT),
    takeEvery(actions.SHOW_TRANSPORT_QUOTATION.REQUEST, showTransportQT),
    takeEvery(actions.ASSIGN_JOB.REQUEST, assignJob),
    takeEvery(actions.UPDATE_STATUS_QT.REQUEST, updateStatusQt)
  ]);
}

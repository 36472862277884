import {
  LOAD_COMPANY_PROFILE,
  REMOVE_LISTS,
} from "./actions";

const initialState = {
  lists: {
    companyInformation:[{}],
    contactInformation:[]
  },
  loading: false,
  check: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_LISTS:
      return initialState;
    
    case LOAD_COMPANY_PROFILE.REQUEST:
      const { loading } = action.payload.data;
      return {
        ...state,
        loading: loading,
      };

    case LOAD_COMPANY_PROFILE.SUCCESS:
      return {
        ...state,
        lists: action.payload.lists,
        loading: false,
        check: false,
      };

    default:
      return state;
  }
};

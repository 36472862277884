import { fork } from "redux-saga/effects";

import watchTransportMarketState from "./components/market/sagas";
import watchTransporWorksheetCompanyState from "./components/mywork/sagas";
import watchTransporWorkInprogressState from "./components/inprogress/sagas";
import watchTransportQTState from "./components/quotation/sagas"
const watchDashboardTransport = [
  fork(watchTransportMarketState),
  fork(watchTransporWorksheetCompanyState),
  fork(watchTransporWorkInprogressState),
  fork(watchTransportQTState),
];

export { watchDashboardTransport };

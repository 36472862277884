import React, { Component } from "react";
import { connect } from "react-redux";
import LangContext from "modules/shared/context/langContext";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import axios from "axios";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Input,
  Transfer,
  message,
  Upload,
  Icon,
} from "antd";
import {
  upDateLogisticGroup,
  createLogisticGroup,
  removeLists,
} from "../actions";
import Breadcrumb from "../../../shared/components/Breadcrumb";

import {
  StyledSearchForm,
  GeneralStyledContent,
  PageTitleStyled,
  TitleText,
} from "../../../../styled/backhaul-styled";

const InputGroup = Input.Group;
const { Item } = Form;
const { TextArea } = Input;
const { confirm } = Modal;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject();
  });
}

class LogisticGroupAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false,
      expand: false,
      visible: false,
      currentPage: 1,
      page: 1,
      pageSize: 10,
      searchName: "",
      orderBy: "created_at",
      orderType: "desc",
      LogisticGroupUnSelected: [],
      LogisticGroupName: [],
      file_logo_list: [],
      file_logo_preview_visible: false,
      file_logo_preview: "",
      groupName: "",
      groupname: "",
      defaultValue: "",
      Ok: false,
      data: {
        group_name: "",
        detail: "",
        unSelected: [],
        seleclted: [],
        file_logo: [],
      },
    };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    let self = this;

    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/logisticgroup`,
        {},
        {
          headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
        }
      )
      .then(function(response) {
        if (typeof response.data.data !== "undefined") {
          let logisticgroupname = response.data.data.map((logistic) => {
            return { key: logistic.company_id, name: logistic.company_name };
          });

          self.getMock(logisticgroupname);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  handleGroupName = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      file_logo_preview: file.url || file.preview,
      file_logo_preview_visible: true,
    });
  };

  handleCancel = () => this.setState({ file_logo_preview_visible: false });

  showConfirm = (values, i18n) => {
    let self = this;
    confirm({
      title: i18n.d.doYouWantToAddTheseItems,
      content: i18n.c.confirmToAddThisItem,
      okText: i18n.o.ok,
      cancelText: i18n.c.cancel,
      onOk() {
        self.handleSubmit(values, i18n);
      },
      onCancel() {},
    });
  };

  handleSubmit = (values, i18n) => {
    let self = this;
    this.props.createLogisticGroup(
      {
        companyID: this.props.auth.profile.company_id,
        user_id: this.props.auth.profile.id,
        groupname: values.group_name,
        detail: values.detail,
        file_logo: values.file_logo,
        selected: values.seleclted,
        accessToken: this.props.auth.accessToken,
      },
      function(res) {
        console.log(res.status);
        if (res.status == "fail" && res.errors) {
          message.error(i18n.c.create_fail);
        } else {
          message.success(i18n.c.create_success);
          if (typeof self.props.closeAfterSave !== "undefined") {
            self.props.closeAfterSave();
          } else {
            self.props.history.push(`/logisticgroup`);
          }
        }
      }
    );
  };

  getMock = (logisticgroupname) => {
    console.log(logisticgroupname);
    let seleclted = [];
    let unSelected = [];

    for (let i = 0; i < logisticgroupname.length; i++) {
      const data = {
        key: logisticgroupname[i].key,
        title: logisticgroupname[i].name,
      };

      if (data.chosen) {
        seleclted.push(data.key);
      }
      unSelected.push(data);

      this.setState({
        data: {
          ...this.state.data,
          unSelected: unSelected,
          seleclted: seleclted,
        },
      });
    }
  };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      file_logo_list,
      file_logo_preview,
      file_logo_preview_visible,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="upload" />
        <LangContext.Consumer>
          {(i18n) => i18n.s.select_file}
        </LangContext.Consumer>
      </div>
    );

    const show_component =
      typeof this.props.match !== "undefined" ? true : false;

    const component = (
      <LangContext.Consumer>
        {(i18n) => (
          <Formik
            enableReinitialize={true}
            initialValues={this.state.data}
            validate={(values) => {
              let errors = {};

              if (values.file_logo.length == 0) {
                errors.file_logo = i18n.business.logistic_logo_file_require;
              }

              return errors;
            }}
            validationSchema={yup.object().shape({
              group_name: yup
                .string()
                .nullable()
                .required(i18n.g.group_name_require),
              seleclted: yup
                .string()
                .nullable()
                .required(i18n.c.company_select_require),
            })}
            onSubmit={(values, actions) => {
              this.showConfirm(values, i18n);
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <div>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.l.logisticGroupName}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.group_name && errors.group_name
                            ? "error"
                            : undefined
                        }
                        help={
                          touched.group_name && errors.group_name
                            ? errors.group_name.substr(
                                errors.group_name.indexOf(" ") + 1
                              )
                            : undefined
                        }
                      >
                        <InputGroup compact>
                          <Input
                            data-cy="name"
                            type="text"
                            name="name"
                            value={values.group_name}
                            onChange={(e) => {
                              setFieldValue("group_name", e.target.value);
                            }}
                            // onBlur={handleBlur}
                            style={{ width: "98%", marginRight: 1 }}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        //required="true"
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.d.detail}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.detail && errors.detail && "error"
                        }
                        help={touched.detail && errors.detail}
                      >
                        <TextArea
                        data-cy="detail"
                          name="detail"
                          rows={4}
                          onChange={(value) =>
                            setFieldValue("detail", value.target.value)
                          }
                          value={values.detail}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item
                        required={true}
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.business.logistic_logo_file}
                          </LangContext.Consumer>
                        }
                        validateStatus={
                          touched.file_logo && errors.file_logo && "error"
                        }
                        help={touched.file_logo && errors.file_logo}
                      >
                        <Upload
                          className="test-upload-file-logo-list"
                          name="avatar"
                          listType="picture-card"
                          fileList={file_logo_list}
                          onPreview={this.handlePreview}
                          beforeUpload={(file) => {
                            const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png";
                            if (!isCheckTypeFile) {
                              message.error(
                                i18n.worksheet.upload_image_support
                              );
                            }
                            // const isLt2M = file.size / 1024 / 1024 < 1;
                            const checkSizeFile = file.size / 1024 <= 5120;
                            if (!checkSizeFile) {
                              message.error(i18n.worksheet.upload_size_support);
                            }

                            if (isCheckTypeFile && checkSizeFile) {
                              setFieldValue("file_logo", [file]);
                            }

                            return false;
                          }}
                          onChange={({ file, fileList }) => {
                            const isCheckTypeFile =
                              file.type === "image/jpeg" ||
                              file.type === "image/png";
                            const checkSizeFile = file.size / 1024 <= 5120;
                            if (isCheckTypeFile && checkSizeFile) {
                              this.setState({
                                file_logo_list: fileList,
                              });

                              if (fileList.length == 0) {
                                setFieldValue("file_logo", []);
                              }
                            }
                          }}
                        >
                          {file_logo_list.length >= 1 ? null : uploadButton}
                        </Upload>

                        <Modal
                         className="test-modal-upload-logo"
                          visible={file_logo_preview_visible}
                          footer={null}
                          onCancel={this.handleCancel}
                        >
                          <img
                            alt="file"
                            style={{
                              width: "100%",
                            }}
                            src={file_logo_preview}
                          />
                        </Modal>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label={
                          <LangContext.Consumer>
                            {(i18n) => i18n.c.company}
                          </LangContext.Consumer>
                        }
                        required={true}
                        validateStatus={
                          touched.seleclted && errors.seleclted
                            ? "error"
                            : undefined
                        }
                        help={
                          touched.seleclted && errors.seleclted
                            ? errors.seleclted.substr(
                                errors.seleclted.indexOf(" ") + 1
                              )
                            : undefined
                        }
                      >
                        <InputGroup compact>
                          <Transfer  
                            className="test-transfer-company"
                            titles={[
                              <LangContext.Consumer>
                                {(i18n) => i18n.i.itemLeft}
                              </LangContext.Consumer>,
                              <LangContext.Consumer>
                                {(i18n) => i18n.i.itemSelected}
                              </LangContext.Consumer>,
                            ]}
                            name="company"
                            listStyle={{
                              width: show_component ? 400 : 320,
                              height: 320,
                              margin: "4px 0px 0px 0px",
                            }}
                            dataSource={this.state.data.unSelected}
                            showSearch
                            targetKeys={values.seleclted}
                            onChange={(targetKeys) => {
                              setFieldValue("seleclted", targetKeys);
                            }}
                            render={(item) => item.title}
                          />
                        </InputGroup>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "20px", marginBottom: "5px" }}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                      <LangContext.Consumer>
                        {(i18n) => (
                          <Form.Item>
                            <Link to="/logisticgroup">
                              <Button
                                data-cy="btn-go-back"
                                type="default"
                                style={{
                                  marginRight: "10px",
                                }}
                              >
                                <LangContext.Consumer>
                                  {(i18n) => i18n.b.back}
                                </LangContext.Consumer>
                              </Button>
                            </Link>
                            <Button type="primary" htmlType="submit" data-cy="btn-submit">
                              {i18n.s.save}
                            </Button>
                          </Form.Item>
                        )}
                      </LangContext.Consumer>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          />
        )}
      </LangContext.Consumer>
    );

    return (
      <AuthorizeComponent matching_name="logisticgroup">
        <GeneralStyledContent>
          {show_component && (
            <Row gutter={24}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
            </Row>
          )}

          <Row>
            <PageTitleStyled>
              <LangContext.Consumer>
                {(i18n) => i18n.logistic.logistic_create}
              </LangContext.Consumer>
            </PageTitleStyled>

            {show_component ? (
              <StyledSearchForm>{component}</StyledSearchForm>
            ) : (
              component
            )}
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ dataLogisticGroup, auth }) => ({ dataLogisticGroup, auth }),
  {
    upDateLogisticGroup: upDateLogisticGroup.request,
    createLogisticGroup: createLogisticGroup.request,
    removeLists,
  }
)(LogisticGroupAdd);

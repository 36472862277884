import React, {  Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form, Input, Alert, Icon, Col, Row } from "antd";
import { Link } from "react-router-dom";
import LangContext from "modules/shared/context/langContext";

export default ({ auth, onSubmit: authFormSubmit, firebase }) => (
  <div>
    <LangContext.Consumer>
      {(i18n) => (
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={yup.object().shape({
            username: yup.string().required(i18n.e.emailorphone_require),
            password: yup.string().required(i18n.p.password_require),
          })}
          onSubmit={(values) => authFormSubmit(values)}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              {auth.status == "" && auth.msg != "" && (
                <Alert
                  message={auth.msg}
                  type="error"
                  showIcon
                  closable
                  style={{ margin: "25px 0px 15px 0px" }}
                />
              )}

              <Row>
                <Col xs={24} sm={9} md={1} lg={9} xl={9} style={{padding:"0px 0px 0px 15px"}}>
                  <Link
                    to={`/`}
                    style={{ padding: "0px 15px 0px 5px" }}
                  >
                    <img style={{width:"40%",marginTop:"34px"}} src="/img/backhaul/logo-web.png" />
                  </Link>
                </Col>
                <Col xs={24} sm={5} md={8} lg={5} xl={5}>
                  <Form.Item
                    label={
                      <span style={{ color: "#F0FFFF" }}>
                        {i18n.e.emailorphone}
                      </span>
                    }
                    colon={false}
                    validateStatus={
                      auth.status != ""
                        ? auth.status == "error"
                          ? auth.status
                          : null
                        : touched.username && errors.username
                        ? "error"
                        : null
                    }
                    // help={
                    //   auth.status != ""
                    //     ? auth.status == "error"
                    //       ? ""
                    //       : null
                    //     : touched.username && errors.username
                    //     ? i18n.e.emailorphone_require
                    //     : null
                    // }
                    style={{marginTop: "20px"}}
                  >
                    <Input
                      data-cy="username"
                      type="text"
                      name="username"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={i18n.e.emailorphone}
                      values={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.username && errors.username
                          ? "invalid"
                          : undefined
                      }
                      //  style={{ margin: "0px 0px 2px 0px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={5} md={8} lg={5} xl={5}>
                  <Form.Item
                    label={
                      <span style={{ color: "#F0FFFF" }}>
                        {i18n.p.password}
                      </span>
                    }
                    colon={false}
                    validateStatus={
                      auth.status != ""
                        ? auth.status == "error"
                          ? auth.status
                          : null
                        : touched.password && errors.password
                        ? "error"
                        : null
                    }
                    // help={
                    //   auth.status != ""
                    //     ? auth.status == "error"
                    //       ?    (<Alert
                    //       message="Error"
                    //       description={i18n.login[auth.msg]}
                    //       type="error"
                    //       showIcon
                    //     />)
                    //       : null
                    //     : touched.password && errors.password
                    //     ? i18n.p.password_require
                    //     : null
                    // }
                    style={{marginTop: "20px"}}
                  >
                    <Input
                    data-cy="password"
                      type="password"
                      name="password"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder={i18n.p.password}
                      autoComplete="auth-password"
                      values={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={
                        touched.password && errors.password
                          ? "invalid"
                          : undefined
                      }
                      //style={{ margin: "0px 0px 2px 0px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={4} md={6} lg={4} xl={4}>
                  <Button
                    data-cy="bnt-submit"
                    htmlType="submit"
                    type="primary"
                    style={{  margin: "53px 0px 0px 20px" }}
                  >
                    {i18n.l.login}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={9} md={1} lg={9} xl={9}></Col>
                {auth.status === "error"?( <Col xs={24} sm={11} md={17} lg={11} xl={11}>
                  {
                      auth.status !== ""
                        ? auth.status === "error"
                          ?    (<Alert
                          message={i18n.login[auth.msg]}
                          type="error"
                          showIcon
                        />)
                          : null
                        : ((touched.password && errors.password) || (touched.username && errors.username))
                        ?  (touched.username && errors.username)?  (<Alert
                          message={i18n.e.emailorphone_require}
                          type="error"
                          showIcon
                        />)  :  (<Alert
                          message={i18n.p.password_require}
                          type="error"
                          showIcon
                        />)
                        : null
                    }</Col>):(  <Fragment><Col xs={24} sm={5} md={8} lg={5} xl={5}></Col><Col xs={0} sm={1} md={1} lg={1} xl={1}></Col>
                      <Col xs={24} sm={5} md={8} lg={5} xl={5}> <Link
                      data-cy="forgotpassword"
                      to={{ pathname: "/forgotpassword" }}
                      style={{ padding: "0px 0px 15px 0px", color: "#F0FFFF",margin: "32px 0px 0px 0px"  }}
                    >
                      <span>{i18n.f.forgotpassword}</span>
                    </Link></Col></Fragment>)}
               
              
                <Col xs={24} sm={4} md={6} lg={4} xl={4}>
                  {auth.status === "error"?(  <Link
                  data-cy="forgotpassword"
                    to={{ pathname: "/forgotpassword" }}
                    style={{ padding: "0px 0px 15px 0px", color: "#F0FFFF",margin: "32px 0px 0px 20px"  }}
                  >
                    <span>{i18n.f.forgotpassword}</span>
                  </Link>):""}
              
                </Col>
              </Row>
              {/* <span style={{ marginRight: "5px" }}>
                {i18n.d.donothaveaccount}
              </span> */}
              {/* <Link to={{ pathname: "/signup" }}>
                <span style={{ fontWeight: "bold" }}>{i18n.s.signup}</span>
              </Link> */}
            </Form>
          )}
        />
      )}
    </LangContext.Consumer>
  </div>
);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions, convertLatLngToXy } from "../../../../lib/helper";
import axios from "axios";
import QRCode from "qrcode.react";
import {
  loadCompanyProfile,
  removeLists,
} from "../../../master-data/companyprofile/actions";
import { verifyCompany } from "../actions";
import FormDivider from "../../../shared/components/FormDivider";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { StyledSearchForm } from "../../../../styled/common-styled";
import {
  Button,
  Row,
  Col,
  Select,
  Modal,
  Divider,
  Tooltip,
  Upload,
  Icon,
  message,
} from "antd";
import { withState } from "recompose";
const columnStyle = { fontSize: "12px", fontWeight: "400" };
const columnStyle2 = { fontSize: "10px", fontWeight: "350" };
const Option = Select.Option;
const { confirm } = Modal;

const styleHeader = {
  border: "3px solid",
  width: "250px",
  height: "55px",
  fontSize: "16px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

const img = {
  width: "100px",
  height: "100px",
  marginBottom: "10px",
};

const product_img = {
  width: "200px",
  height: "200px",
  marginTop: "20px",
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class CompanyVerifyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoFileVisible: false,
      logoFileImage: "",
      logoFileTitle: "",
      logoFileList: [],

      idCardFileVisible: false,
      idCardFileImage: "",
      idCardFileTitle: "",
      idCardFileList: [],

      driverLicenseFileVisible: false,
      driverLicenseFileImage: "",
      driverLicenseFileTitle: "",
      driverLicenseFileList: [],

      certificateFileVisible: false,
      certificateFileImage: "",
      certificateFileTitle: "",
      certificateFileList: [],

      licenseTransportFileVisible: false,
      licenseTransportFileImage: "",
      licenseTransportFileTitle: "",
      licenseTransportFileList: [],

      vat20FileVisible: false,
      vat20FileImage: "",
      vat20FileTitle: "",
      vat20FileList: [],

      previewProductFileVisible: false,
      previewProductFileImage: "",
      previewProductFileTitle: "",
      productFileList: [],

      visible: false,
      companyVehicleType: [{}],
      companytransportationrating: [{}],
      rendered: false,
    };
  }

  componentWillUnmount() {
    this.onRemoveLists();
  }

  onRemoveLists = () => {
    this.props.removeLists();
  };

  componentDidMount() {
    let self = this;
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companyProfile.lists.companyInformation) {
      this.setState({
        logoFileList: [
          {
            uid: "-1",
            name: "logofile",
            url: nextProps.companyProfile.lists.companyInformation[0].logo_file,
          },
        ],
        productFileList: [
          {
            uid: "-1",
            name: "productfile",
            url:
              nextProps.companyProfile.lists.companyInformation[0].product_file,
          },
        ],
        certificateFileList: [
          {
            uid: "-2",
            name: "certificatefile",
            url:
              nextProps.companyProfile.lists.companyInformation[0]
                .certificate_file,
          },
        ],
        idCardFileList: [
          {
            uid: "-3",
            name: "idcardfile",
            url:
              nextProps.companyProfile.lists.companyInformation[0].id_card_file,
          },
        ],
        driverLicenseFileList: [
          {
            uid: "-4",
            name: "driverlicensefile",
            url:
              nextProps.companyProfile.lists.companyInformation[0]
                .driver_license_file,
          },
        ],
        licenseTransportFileList: [
          {
            uid: "-5",
            name: "licensetransportfile",
            url:
              nextProps.companyProfile.lists.companyInformation[0]
                .license_transport_file,
          },
        ],
        vat20FileList: [
          {
            uid: "-6",
            name: "vat20file",
            url:
              nextProps.companyProfile.lists.companyInformation[0].vat_20_file,
          },
        ],
      });
    }
  }

  loadData = (loading) => {
    this.props.loadCompanyProfile(
      {
        companyID: this.props.match.params.companyID,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };

  //Logo File
  handleLogoFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      logoFileImage: file.url || file.preview,
      logoFileVisible: true,
      logoFileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleLogoFileCancel = () => this.setState({ logoFileVisible: false });

  //Certificate File
  handleCertificateFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      certificateFileImage: file.url || file.preview,
      certificateFileVisible: true,
      certificateFileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleCertificateFileCancel = () =>
    this.setState({ certificateFileVisible: false });

  //License Transport File
  handleLicenseTransportFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      licenseTransportFileImage: file.url || file.preview,
      licenseTransportFileVisible: true,
      licenseTransportFileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleLicenseTransportFileCancel = () =>
    this.setState({ licenseTransportFileVisible: false });

  //Product File
  handleProductFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewProductFileImage: file.url || file.preview,
      previewProductFileVisible: true,
      previewProductFileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleProductFileCancel = () =>
    this.setState({ previewProductFileVisible: false });

  //Vat 20 File
  handleVat20FilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      vat20FileImage: file.url || file.preview,
      vat20FileVisible: true,
      vat20FileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleVat20FileCancel = () => this.setState({ vat20FileVisible: false });

  //Id Card File
  handleIdCardFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      idCardFileImage: file.url || file.preview,
      idCardFileVisible: true,
      idCardFileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleIdCardFileCancel = () => this.setState({ idCardFileVisible: false });

  //Driver License File
  handleDriverLicenseFilePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      driverLicenseFileImage: file.url || file.preview,
      driverLicenseFileVisible: true,
      driverLicenseFileTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleDriverLicenseFileCancel = () =>
    this.setState({ driverLicenseFileVisible: false });

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = (i18n) => {
    let self = this;
    this.props.verifyCompany(
      {
        companyID: this.props.match.params.companyID,
        accessToken: this.props.auth.accessToken,
      },
      function(res) {
        if (res.status == "fail" && res.errors) {
          message.error(i18n.u.update_fail);
        } else {
          message.success(i18n.u.update_success);
          self.props.history.push(`/companyverify`);
        }
      }
    );
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      companyInformation,
      contactInformation,
    } = this.props.companyProfile.lists;
    const {
      idCardFileVisible,
      idCardFileImage,
      idCardFileTitle,
      idCardFileList,
      driverLicenseFileVisible,
      driverLicenseFileImage,
      driverLicenseFileTitle,
      driverLicenseFileList,
      certificateFileVisible,
      certificateFileImage,
      certificateFileTitle,
      certificateFileList,
      licenseTransportFileVisible,
      licenseTransportFileImage,
      licenseTransportFileTitle,
      licenseTransportFileList,
      vat20FileVisible,
      vat20FileImage,
      vat20FileTitle,
      vat20FileList,
      previewProductFileVisible,
      previewProductFileImage,
      previewProductFileTitle,
      productFileList,
      logoFileVisible,
      logoFileImage,
      logoFileTitle,
      logoFileList,
    } = this.state;

    return (
      <AuthorizeComponent matching_name="company_verify" >
        <GeneralStyledContent>
          <LangContext.Consumer>
            {(i18n) => (
              <Row>
                <Row>
                  <Col span={22}>
                    <StyledSearchForm>
                      <Row
                        style={{ margin: "10px 20px -22px 0px" }}
                        type="flex"
                        justify="end"
                      >
                        <Col>
                          {companyInformation[0].verified === 1 ? (
                            <div style={{ display: "flex" }}>
                              <p style={{ color: "green", fontSize: "15px" }}>
                                {i18n.v.verify_yes}
                              </p>
                              <img
                                src="/img/backhaul/verify-green.png"
                                style={{
                                  margin: "0px 0px 0px 5px",
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Divider orientation="left">
                        {i18n.business.business_information}
                      </Divider>
                      <Row style={{ margin: "0px 0px 10px 20px" }}>
                        <Col xs={0} sm={1} md={1} lg={1} xl={1} />
                        <Col xs={24} sm={1} md={23} lg={23} xl={23} >
                          {companyInformation[0].logo_file ? (
                            <div>
                              <Upload
                                className="test-upload-logoFileList"
                                name="avatar"
                                listType="picture-card"
                                fileList={logoFileList}
                                onPreview={this.handleLogoFilePreview}
                                showRemoveIcon={false}
                                onRemove={false}
                                disabled={true}
                              >
                                {logoFileList.length >= 1 ? null : ""}
                              </Upload>
                              <Modal
                                className="test-modal-logoFileVisible"
                                visible={logoFileVisible}
                                footer={null}
                                onCancel={this.handleLogoFileCancel}
                              >
                                <img
                                  alt="example"
                                  style={{ width: "100%" }}
                                  src={logoFileImage}
                                />
                              </Modal>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {/* 1 1 */}
                        <Col xs={0} sm={0} md={2} lg={2} xl={2} />

                        <Col xs={24} md={8} lg={8} xl={8} >
                          <Row >
                            <Col xs={24} sm={4} md={8} lg={6} xl={5}>
                              <span style={{ fontWeight: "bold" }} >
                                {`${i18n.business.company_name} : `}
                              </span>
                            </Col>
                            <Col xs={24} sm={20} md={16} lg={18} xl={19}>
                                <span >
                                  {companyInformation[0].company_name}
                                </span>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} md={7} lg={7} xl={7}>
                          <Row>
                            <Col xs={24} sm={6} md={13} lg={9} xl={8}>
                                <span style={{ fontWeight: "bold" }}>
                                  {`${i18n.business.company_type} : `}
                                </span>
                            </Col>
                            <Col xs={24} sm={18} md={11} lg={15} xl={16}>
                                <span >
                                  {companyInformation[0].company_type}
                                </span>
                            </Col>
                          </Row>
                        </Col>

                        <Col xs={24} md={7} lg={7} xl={7}>
                          <Row>
                            <Col xs={24} sm={6} md={15} lg={11} xl={8}>
                              <span style={{ fontWeight: "bold" }}>
                                {`${i18n.business.number_employee} : `}
                              </span>
                            </Col>
                            <Col xs={24} sm={18} md={9} lg={13} xl={16}>
                              <span >
                                {companyInformation[0].number_employee}
                              </span>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                      <Row>
                        {/* 1 2 */}
                        <Col md={2} xs={0} sm={0} lg={2} xl={2} />
                        <Col md={8} lg={8} xl={8}>

                          <Row>
                            <Col xs={24} sm={6} md={14} lg={14} xl={9}>
                              <span style={{ fontWeight: "bold" }}>
                                {`${i18n.business.juristic_id} : `}
                              </span>
                            </Col>
                            <Col xs={24} sm={18} md={10} lg={10} xl={15}>
                              <span >
                                {companyInformation[0].juristic_id}
                              </span>
                            </Col>
                          </Row>
                        </Col>

                        <Col md={7} lg={7} xl={7}>
                          <Row>
                            <Col xs={24} sm={6} md={14} lg={14} xl={10}>
                                <span style={{ fontWeight: "bold" }}>
                                {`${i18n.business.province_head_offfice} : `}
                                </span>
                              </Col>
                              <Col xs={24} sm={18} md={10} lg={10} xl={14}>
                                <span >
                                  {companyInformation[0].province_head_offfice}
                                </span>
                              </Col>
                          </Row>
                        </Col>

                        <Col md={7} lg={7} xl={7}>
                          <Row>
                          <Col xs={24} sm={6} md={14} lg={10} xl={8}>
                              <span style={{ fontWeight: "bold" }}>
                                {`${i18n.business.founded_year} : `}
                              </span>
                            </Col>
                            <Col xs={24} sm={18} md={10} lg={14} xl={16}>
                              <span >
                                {companyInformation[0].founded_year}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        {/* 1 3 */}
                        <Col md={2} xs={0} sm={0} lg={2} xl={2}/>
                        <Col md={8} lg={8} xl={8}>
                          <Row>
                            <Col xs={24} sm={6} md={15} lg={10} xl={8}>
                              <span style={{ fontWeight: "bold" }}>
                               {`${i18n.business.companyDetails} : `}
                              </span>
                            </Col>
                            <Col xs={24} sm={18} md={8} lg={14} xl={16}>
                              <span >
                              {
                                  i18n.companyDetails[
                                    companyInformation[0].types
                                  ]
                                }
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </StyledSearchForm>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <StyledSearchForm>
                      <Divider orientation="left">
                        {i18n.i.identification_documents}
                      </Divider>
                      {companyInformation[0].types === "productowner" ? (
                        <Row style={{ margin: "19px 0px 0px 0px" }}>
                          <Col xs={0} sm={2} md={2} lg={2} xl={2} />
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.business.product_file}
                            </p>
                            {companyInformation[0].product_file ? (
                              <div>
                                <Upload
                                  className="test-upload-productFileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={productFileList}
                                  onPreview={this.handleProductFilePreview}
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {productFileList.length >= 1 ? null : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-previewProductFileVisible"
                                  visible={previewProductFileVisible}
                                  title={
                                    i18n.companyFileList[
                                      previewProductFileTitle
                                    ]
                                  }
                                  footer={null}
                                  onCancel={this.handleProductFileCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={previewProductFileImage}
                                  />
                                </Modal>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.business.certificate_file}
                            </p>
                            {companyInformation[0].certificate_filetype ===
                            "image" ? (
                              <div>
                                <Upload
                                  className="test-upload-certificateFileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={certificateFileList}
                                  onPreview={this.handleCertificateFilePreview}
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {certificateFileList.length >= 1 ? null : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-certificateFileVisible"
                                  visible={certificateFileVisible}
                                  title={
                                    i18n.companyFileList[certificateFileTitle]
                                  }
                                  footer={null}
                                  onCancel={this.handleCertificateFileCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={certificateFileImage}
                                  />
                                </Modal>
                              </div>
                            ) : companyInformation[0].certificate_filetype ===
                              "file" ? (
                              <a
                                href={companyInformation[0].certificate_file}
                                target="_blank"
                                download
                              >
                                {i18n.c.clickToViewFile}
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.business.vat_20_file}
                            </p>
                            {companyInformation[0].vat_20_filetype ===
                            "image" ? (
                              <div>
                                <Upload
                                  className="test-upload-vat20FileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={vat20FileList}
                                  onPreview={this.handleVat20FilePreview}
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {vat20FileList.length >= 1 ? null : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-vat20FileVisible"
                                  visible={vat20FileVisible}
                                  title={i18n.companyFileList[vat20FileTitle]}
                                  footer={null}
                                  onCancel={this.handleVat20FileCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={vat20FileImage}
                                  />
                                </Modal>
                              </div>
                            ) : companyInformation[0].vat_20_filetype ===
                              "file" ? (
                              <a
                                href={companyInformation[0].vat_20_file}
                                target="_blank"
                                download
                              >
                                {i18n.c.clickToViewFile}
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {companyInformation[0].types === "transporter" ? (
                        <Row style={{ margin: "19px 0px 0px 0px" }}>
                          <Col xs={0} sm={2} md={2} lg={2} xl={2} />
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.business.certificate_file}
                            </p>
                            {companyInformation[0].certificate_filetype ===
                            "image" ? (
                              <div>
                                <Upload
                                  className="test-upload-certificateFileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={certificateFileList}
                                  onPreview={this.handleCertificateFilePreview}
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {certificateFileList.length >= 1 ? null : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-certificateFileVisible"
                                  visible={certificateFileVisible}
                                  title={
                                    i18n.companyFileList[certificateFileTitle]
                                  }
                                  footer={null}
                                  onCancel={this.handleCertificateFileCancel}
                                >
                                  <img
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                    src={certificateFileImage}
                                  />
                                </Modal>
                              </div>
                            ) : companyInformation[0].certificate_filetype ===
                              "file" ? (
                              <a
                                href={companyInformation[0].certificate_file}
                                target="_blank"
                                download
                              >
                                {i18n.c.clickToViewFile}
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.business.license_transport_file}
                            </p>
                            {companyInformation[0]
                              .license_transport_filetype === "image" ? (
                              <div>
                                <Upload
                                  className="test-upload-licenseTransportFileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={licenseTransportFileList}
                                  onPreview={
                                    this.handleLicenseTransportFilePreview
                                  }
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {licenseTransportFileList.length >= 1
                                    ? null
                                    : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-licenseTransportFileVisible"
                                  visible={licenseTransportFileVisible}
                                  title={
                                    i18n.companyFileList[
                                      licenseTransportFileTitle
                                    ]
                                  }
                                  footer={null}
                                  onCancel={
                                    this.handleLicenseTransportFileCancel
                                  }
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={licenseTransportFileImage}
                                  />
                                </Modal>
                              </div>
                            ) : companyInformation[0]
                                .license_transport_filetype === "file" ? (
                              <a
                                href={
                                  companyInformation[0].license_transport_file
                                }
                                target="_blank"
                                download
                              >
                                {i18n.c.clickToViewFile}
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {companyInformation[0].types === "personal" ? (
                        <Row style={{ margin: "19px 0px 0px 0px" }}>
                          <Col xs={0} sm={2} md={2} lg={2} xl={2} />
                          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.c.copy_id_card}
                            </p>
                            {companyInformation[0].id_card_filetype ===
                            "image" ? (
                              <div>
                                <Upload
                                  className="test-upload-idCardFileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={idCardFileList}
                                  onPreview={this.handleIdCardFilePreview}
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {idCardFileList.length >= 1 ? null : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-idCardFileVisible"
                                  visible={idCardFileVisible}
                                  title={i18n.companyFileList[idCardFileTitle]}
                                  footer={null}
                                  onCancel={this.handleIdCardFileCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={idCardFileImage}
                                  />
                                </Modal>
                              </div>
                            ) : companyInformation[0].id_card_filetype ===
                              "file" ? (
                              <a
                                href={companyInformation[0].id_card_file}
                                target="_blank"
                                download
                              >
                                {i18n.c.clickToViewFile}
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <p style={{ fontWeight: "bold" }}>
                              {i18n.c.copy_driver_license}
                            </p>
                            {companyInformation[0].driver_license_filetype ===
                            "image" ? (
                              <div>
                                <Upload
                                  className="test-upload-driverLicenseFileList"
                                  name="avatar"
                                  listType="picture-card"
                                  fileList={driverLicenseFileList}
                                  onPreview={
                                    this.handleDriverLicenseFilePreview
                                  }
                                  showRemoveIcon={false}
                                  onRemove={false}
                                  disabled={true}
                                >
                                  {driverLicenseFileList.length >= 1
                                    ? null
                                    : ""}
                                </Upload>
                                <Modal
                                  className="test-modal-driverLicenseFileVisible"
                                  visible={driverLicenseFileVisible}
                                  title={
                                    i18n.companyFileList[driverLicenseFileTitle]
                                  }
                                  footer={null}
                                  onCancel={this.handleDriverLicenseFileCancel}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={driverLicenseFileImage}
                                  />
                                </Modal>
                              </div>
                            ) : companyInformation[0]
                                .driver_license_filetype === "file" ? (
                              <a
                                href={companyInformation[0].driver_license_file}
                                target="_blank"
                                download
                              >
                                {i18n.c.clickToViewFile}
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </StyledSearchForm>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <StyledSearchForm>
                      <Divider orientation="left">
                        {i18n.business.addresss_business}
                      </Divider>
                      <Row style={{ marginBottom: "20px" }}>
                        <Col span={2} />
                        <Col span={8}>
                          <div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.buildingName} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].building_name}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.villageNo} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].village_no}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.district} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].district}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col span={7}>
                          <div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.roomNumber} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].room_number}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.houseNo} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].house_no}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.province} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].province_name}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col span={7}>
                          <div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.floor} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].floor}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.subdistrict} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].subdistrict}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{ fontWeight: "bold" }}
                              >{`${i18n.addr.postalCode} : `}</p>
                              <p style={{ margin: "0px 0px 0px 5px" }}>
                                {companyInformation[0].postcode}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </StyledSearchForm>
                  </Col>
                </Row>
                <Row>
                  <Col span={22}>
                    <StyledSearchForm>
                      <Divider orientation="left">
                        {i18n.business.contact_business}
                      </Divider>
                      {contactInformation.map((item, i) => (
                        <Row style={{ marginBottom: "15px" }}>
                          <Col span={2} />
                          <Col span={8}>
                            <div>
                              <div style={{ display: "flex" }}>
                                <p
                                  style={{ fontWeight: "bold" }}
                                >{`${i18n.j.job_contact_name} : `}</p>
                                <p style={{ margin: "0px 0px 0px 5px" }}>
                                  {item.firstname}
                                </p>
                              </div>
                              <div style={{ display: "flex" }}>
                                <p
                                  style={{ fontWeight: "bold" }}
                                >{`${i18n.e.email} : `}</p>
                                <p style={{ margin: "0px 0px 0px 5px" }}>
                                  {item.email}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col span={7}>
                            <div>
                              <div style={{ display: "flex" }}>
                                <p
                                  style={{ fontWeight: "bold" }}
                                >{`${i18n.j.job_contact_surname} : `}</p>
                                <p style={{ margin: "0px 0px 0px 5px" }}>
                                  {item.lastname}
                                </p>
                              </div>
                              <div style={{ display: "flex" }}>
                                <p
                                  style={{ fontWeight: "bold" }}
                                >{`${i18n.c.contact_telno} : `}</p>
                                <p style={{ margin: "0px 0px 0px 5px" }}>
                                  {item.phone_no}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col span={7}>
                            <div>
                              <div style={{ display: "flex" }}>
                                <p
                                  style={{ fontWeight: "bold" }}
                                >{`${i18n.p.position} : `}</p>
                                <p style={{ margin: "0px 0px 0px 5px" }}>
                                  {item.position}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </StyledSearchForm>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                    <div style={{ margin: "0px 0px 100px 0px" }}>
                      <Link to={`/companyverify`}>
                        <Button
                          data-cy="btn-go-back"
                          type="default"
                          style={{
                            marginLeft: "5px",
                            marginRight: "10px",
                          }}
                        >
                          {i18n.b.back}
                        </Button>
                      </Link>
                      {companyInformation[0].verified === 0 ? (
                        <Button
                          type="primary"
                          data-cy="btn-submit"
                          onClick={this.showModal}
                        >
                          {i18n.v.verify}
                        </Button>
                      ) : (
                        ""
                      )}
                      <Modal
                        className="test-modal-company-verify"
                        title={i18n.c.confirmVerifyCompany}
                        visible={this.state.visible}
                        onOk={() => this.handleSubmit(i18n)}
                        onCancel={this.hideModal}
                        okText={i18n.o.ok}
                        cancelText={i18n.c.cancel}
                        okButtonProps={{
                          "data-cy": "btn-modal-ok",
                        }}
                        cancelButtonProps={{
                          "data-cy": "btn-modal-cancel",
                        }}
                      >
                        <p>{i18n.c.confirmVerifyCompany}</p>
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
          </LangContext.Consumer>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(
  ({ auth, companyProfile }) => ({
    companyProfile,
    auth,
  }),
  {
    loadCompanyProfile: loadCompanyProfile.request,
    verifyCompany: verifyCompany.request,
    removeLists,
  }
)(CompanyVerifyDetail);

import { createTypes, createAction } from "lib/action";

const LOAD_TRANSPORT_COMPANIES = createTypes("productownercompanygroup", "load");
const SHOW_TRANSPORT_COMPANY = createTypes("productownercompanygroup", "show");
const ADD_TRANSPORT_COMPANY = createTypes("productownercompanygroup", "create");

const loadTransportCompanies = {
  request: (page, pageSize, orderBy, orderType, filters) =>
    createAction(LOAD_TRANSPORT_COMPANIES.REQUEST, { page, pageSize, orderBy, orderType, filters }),
  success: (lists) => createAction(LOAD_TRANSPORT_COMPANIES.SUCCESS, { lists }),
  failure: () => createAction(LOAD_TRANSPORT_COMPANIES.FAILURE),
};

const showTransportCompany = {
  request: id =>
    createAction(SHOW_TRANSPORT_COMPANY.REQUEST, { id }),
  success: (data) => createAction(SHOW_TRANSPORT_COMPANY.SUCCESS, { data }),
  failure: () => createAction(SHOW_TRANSPORT_COMPANY.FAILURE),
};

const addTransportCompany = {
  request: (company_id, group_id) =>
    createAction(ADD_TRANSPORT_COMPANY.REQUEST, { company_id, group_id }),
  success: (data) => createAction(ADD_TRANSPORT_COMPANY.SUCCESS, { data }),
  failure: () => createAction(ADD_TRANSPORT_COMPANY.FAILURE),
};

export {
  LOAD_TRANSPORT_COMPANIES,
  SHOW_TRANSPORT_COMPANY,
  ADD_TRANSPORT_COMPANY,
  loadTransportCompanies,
  showTransportCompany,
  addTransportCompany,
};

import { createTypes, createAction } from "lib/action";
const LOAD_COMPANIES_VERIFY = createTypes("companiesverify", "load");

const VERIFY_COMPANY = createTypes("verifycompany", "cerate");

const loadCompaniesVerify = {
  request: (data, loading) =>
      createAction(LOAD_COMPANIES_VERIFY.REQUEST, { data, loading}),
  success: (lists, page) =>
      createAction(LOAD_COMPANIES_VERIFY.SUCCESS, {lists, page}),
  failure: () => createAction(LOAD_COMPANIES_VERIFY.FAITLURE)  
};

const verifyCompany = {
  request: (data, loading) =>
      createAction(VERIFY_COMPANY.REQUEST, { data, loading}),
  success: res => createAction(VERIFY_COMPANY.SUCCESS, { res }),
      failure: () => createAction(VERIFY_COMPANY.FAILURE)
};

export{
  loadCompaniesVerify,
  LOAD_COMPANIES_VERIFY,
  verifyCompany,
  VERIFY_COMPANY,
};
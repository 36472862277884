import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import Forms from "./Forms";
import {
  GeneralStyledContent,
  PageTitleStyled,
} from "../../../../styled/backhaul-styled";
import LangContext from "modules/shared/context/langContext";

import Breadcrumb from "../../../shared/components/Breadcrumb";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";

import {
  createMasterDataPlace,
  removeLists,
} from "modules/master-data/facility/actions";

class Add extends Component {
  state = {
    id: "",
    company_name: "",
    origin_location_id: "",
    origin_receive_date: "",
    product_detail: "",
    vehicle_type: "",
    price: "",
    price_unit_id: "",
    expense: "",
    staff_lift: false,
    product_file: [],
    service_payment_type: "",
    credit_term_id: "",
    frequency_routine_id: "",
    contract_term_id: "",
    title_name: "mr",
    firstname: "",
    lastname: "",
    phone: "",
    comment: "",
  };

  render() {
    return (
      <AuthorizeComponent matching_name="worksheet">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[`/worksheet/${this.props.match.params.type}`, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <PageTitleStyled>
            {this.props.match.params.type === "onetime" ? (
              <LangContext.Consumer>
                {(i18n) => i18n.worksheet.worksheetonetime}
              </LangContext.Consumer>
            ) : (
              <LangContext.Consumer>
                {(i18n) => i18n.worksheet.worksheetroutine}
              </LangContext.Consumer>
            )}
          </PageTitleStyled>
          <Forms
            auth={this.props.auth}
            lang={this.props.langBtn}
            createMasterDataPlace={this.props.createMasterDataPlace}
            type={this.props.match.params.type}
            data={this.state}
            displayUploadImg={false}
            mode="create"
          />
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ auth, auth: { profile }, ui: { langBtn } }) => ({
    auth,
    profile,
    langBtn,
  }),
  {
    createMasterDataPlace: createMasterDataPlace.request,
    removeLists,
  }
)(Add);

import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import CompanyProfile from "./Index";
import EditCompanyProfile from "./EditCompanyProfile"

export default () => (
    <Router>
      <div>
        <Route exact path="/companyprofile" component={CompanyProfile} />
        <Route exact path="/companyprofile/edit" component={EditCompanyProfile} />
      </div>
    </Router>
  );
  
import React from "react";
import { Switch, Route } from "react-router-dom";
import Index from "./Index"
import Add from "./Add";
import Edit from "./Edit";

export default () => (
  <Switch>
    <Route exact path="/worksheet/:type" component={Index} />
    <Route exact path="/worksheet/create/:type" component={Add} />
    <Route exact path="/worksheet/edit/:type/:id" component={Edit} />
    <Route exact path="/worksheet/detail/:type/:id" component={Edit} />
  </Switch>
); 

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router-dom";
import { Divider, Row, Col, Table, Icon, Spin, Pagination } from "antd";

import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";
import { numberFormat, translate } from "../../../../../../lib/helper"

import "../../../../../../styled/transport-dashboard.css";

const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "m",
    keyLang: "md_job",
    dataKey: "document_number",
    sorter: false,
    render: "normal",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: false,
    render: "address",
    width: "20%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "address",
    width: "17%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_delivery_status",
    dataKey: "status",
    sorter: false,
    render: "status",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_receiver",
    dataKey: "received_name",
    sorter: false,
    render: "received_name",
    width: "20%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_delivery_time",
    dataKey: "receive_date",
    sorter: false,
    render: "normal",
    width: "15%",
    cls: "vertical-top",
  },
  {
    idxLang: "c",
    keyLang: "comment",
    dataKey: "comment",
    sorter: false,
    render: "action",
    width: "8%",
    cls: "vertical-top algin-center",
  },
];

const getText = (key, cateKey) => (
  <LangContext.Consumer>{(i18n) => i18n[cateKey][key]}</LangContext.Consumer>
);


const genTableHeader = () => {
  const columns = [];
  tableHeader.map((v) => {
    columns.push({
      title: getText(v.keyLang, v.idxLang),
      dataIndex: `${v.dataKey}`,
      key: `${v.dataKey}`,
      sorter: v.sorter,
      width: v.width,
      className: v.cls,
      render(text, record, index) {
        return {
          props: {
            style: columnStyle,
          },
          children: renderComponent(v.render, record, v.dataKey),
        };
      },
    });
  });

  return columns;
};

const renderComponent = (type, val, key) => {
  switch (type) {
    case "received_name" :
      return renderReceivedName(val)
    case "address":
        return renderAddress(val[key], key)
    case "status":
      return renderStatus(val.status_id, val[key]);
    case "action":
      return renderActionbtn(val.id);
    default:
      return renderNormalTxt(val[key]);
  }
};

const renderAddress = (val, key) => {if(typeof val === "undefined") return ""
  let items = val.split(",")
  let line = 1;
  let withLine = key === "origin" ? false : true

  if(val === "") {
    return <p></p>
  }

  return items.map((item, i) => {
    let txt = item.split(" (")
    if(withLine)
  return <p key={i}>{line+i}. {txt[0]} <br/> ({txt[1]}</p>
    else 
      return <p key={i}>{txt[0]} <br/> ({txt[1]}</p>
  })
}

const renderActionbtn = (val) => {
  return (
    <Link to={`/dashboard/work-inprogress/view/${val}`} data-cy={`btn-view-${val}`}>
    <Icon type="file-text" style={{ fontSize: "18px" }} />
  </Link>
  );
}

const renderStatus = (status_id, val) => {
let img = "/img/red.png"
if (status_id == 2) {
  img = "/img/yellow.png"
}

if (status_id == 3 || status_id == 4) {
  img = "/img/green.png"
}

return     <div style={{ textAlign: "center" }}>
<div>
  {translate(val, "worksheet")}
  <br />
  <img src={img} style={{ padding: "10px" }} />
</div>
</div>
}


const renderReceivedName = items => {
  return items.detail.map((val, i) => {
    let line = i + 1
    return <p>{line} {getText(val.contact_titlename, "m")} { val.receiver }</p>
  })
}

const renderNormalTxt = (val) => <div dangerouslySetInnerHTML={{__html: val}} />;

const columns = genTableHeader();

export class Layout extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.props.loadWorkinProgress({
      page: this.state.currentPage,
      pageSize: this.state.pageSize,
      types: "transporter"
    });
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadWorkinProgress({
      page: page,
      pageSize: pageSize,
      types: "transporter"
    });
  };

  getDeliveryStatus = (val, data) => {
    console.log("data", data.confirm_deliver_at === null, val)
    let status = ""
    if (typeof data.confirm_receive_at === "undefined") return status
    if(data.confirm_receive_at === null && data.confirm_deliver_at === null && data.cause_reject_id === null) {
      status = translate("m_status_1", "worksheet")
    }

    if(data.confirm_receive_at !== null && data.confirm_deliver_at === null) {
      status = translate("m_status_2", "worksheet")
    }

    if(data.confirm_deliver_at !== null && data.cause_reject_id === null) {
      status = translate("m_status_3", "worksheet")
    }

    if(data.confirm_deliver_at !== null && data.cause_reject_id !== null) {
      status = translate("m_status_4", "worksheet")
    }

    if(data.confirm_deliver_at !== null && data.cause_some_part_id !== null) {
      status = translate("m_status_5", "worksheet")
    }

    return status
  }

  renderExpandable = (record, index, indent, expanded) => {
    const columnsA = [
      { title: getText("md_destination", "m"), dataIndex: 'destination', key: 'destination' },
      { title: getText("md_receiver", "m"), dataIndex: 'receiver', key: 'receiver', render: (val, rec) => <span>{getText(rec.contact_titlename, "m")} { val }</span> },
      { title: getText("phone", "p"), dataIndex: 'contact_phone', key: 'contact_phone' },
      { title: getText("md_sending_qty", "m"), dataIndex: 'quantity', key: 'quantity',  render: (val) => numberFormat(val, 0) },
      { title: getText("md_qty", "m"), dataIndex: 'complete_quantity', key: 'complete_quantity',  render: (val) => numberFormat(val, 0) },
      { title: getText("md_delivery_status", "m"), dataIndex: 'status', key: 'status', render: (val, rec) => this.getDeliveryStatus(val, rec)},
    ]
    const data = (typeof this.props.data[index] !== "undefined") ? this.props.data[index].detail : []
    return <Table columns={columnsA} dataSource={data} pagination={false} className="sub-table"/>;
  }

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.props.loadWorkinProgress(page, pageSize, "transporter");
  }

  render() {
    return (
      <AuthorizeComponent matching_name="work_in_process_dashboard">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                <LangContext.Consumer>
                  {(i18n) => i18n.d.dashboard_work_process}
                </LangContext.Consumer>
              </Divider>
            </Col>
            <Col span={24}>
            <Spin spinning={this.props.loading}>
              <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                  expandedRowRender={this.renderExpandable}
              />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportworkinprogress }) => ({
  data: transportworkinprogress.lists,
  totalRows: transportworkinprogress.totalRows,
  loading: transportworkinprogress.loading,
});

const mapDispatchToProps = {
  loadWorkinProgress: actions.loadWorkinProgress.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

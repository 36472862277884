import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Row, Col, Spin } from "antd";

import Forms from "./Forms";
import { GeneralStyledContent, PageTitleStyled } from "../../../../styled/backhaul-styled";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";
import * as moment from "moment"
import LangContext from "modules/shared/context/langContext";

import {
  createMasterDataPlace,
  removeLists,
} from "modules/master-data/facility/actions";

import * as actions from "modules/dashboard/product-owner/components/mywork/actions";


class Edit extends PureComponent {
  state = {
    data: [],
    isFormat: false,
    disable: false,
    backUrl: "",
    displayUploadImg: true,
    mode: "edit"
  };

  formatProductImage = val => {
    if(val === "" || val === null) return []

   let url  = (new URL(decodeURIComponent(val))).pathname
   let img = []
   url.split(",").map((name, i) => {
    let pic = name.split("/")
    img.push({
      uid: i,
      name: pic[pic.length-1],
      url: name,
    })
   })

   return img
  }

  componentDidMount() {
    
    let url = this.props.match.url
    let reqt = false
    if(url.match(/detail|edit/g)) {
      let disable = false
      if(url.match(/detail/g)) disable = true
      this.setState({ 
        disable: disable, 
        backUrl: `/worksheet/${this.props.match.params.type}`,
      })
    }

    if(url.match(/work-inprogress/g)) {
      reqt = true
      this.setState({ mode: "reqt", backUrl: `/productowner/work-inprogress` })
    }


    let id = this.props.match.params.id;
    this.props.showWorksheet(id, reqt);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.data !== this.props.data) {
      let values = {};

      if(typeof this.props.data === "undefined") return 
      if (typeof this.props.data.id !== "undefined" && this.state.isFormat === false) {
        let items = this.props.data,
            list = []

        values["id"] = items.id;
        values["company_name"] = items.customer;
        values["document_number"] = items.document_number;
        values["origin_location_id"] = items.origin_location_id;
        values["origin_receive_date"] = moment(items.origin_receive_date, "DD/MM/YYYY HH:mm");
        values["product_detail"] = items.product_type_id;
        values["vehicle_type"] = items.vehicle_type_id;
        values["price"] = items.price;
        values["price_unit_id"] = items.price_unit_id;
        values["expense"] = items.expense_id;
        values["staff_lift"] = items.staff_lift;
        values["path_product"] = items.path_product
        values["product_file"] = []//this.formatProductImage(items.path_product);
        values["service_payment_type"] = items.service_payment_type;
        values["credit_term_id"] = items.credit_term_id;
        values["frequency_routine_id"] = items.frequency_routine_id;
        values["contract_term_id"] = items.contract_term_id;
        values["title_name"] = items.title_contact_load;
        values["firstname"] = items.firstname_contact_load;
        values["lastname"] = items.lastname_contact_load;
        values["phone"] = items.phone_contact_load;
        values["comment"] = (items.comment === null) ? "" : items.comment;
        values["origin_location"] = items.origin_location
        values["vehicle_type_img"] = items.name_key+".png"
        values["contract_term_id"] = items.contract_term_id
        values["frequency_routine_id"] = items.frequency_routine_id
        let line = 1
        items.destination.map((val, idx) => {
            list.push({
              id: val.id,
              row_number: line++,
              title_name: val.contact_titlename,
              firstname: val.contact_firstname,
              lastname: val.contact_lastname,
              phone: val.contact_phone,
              destination_location_name: val.destination,
              destination_location_id: val.destination_id,
              destination_receive_date: moment(val.receive_date, "DD/MM/YYYY HH:mm"),
              quantity: val.quantity,
              unit: val.unit,
            })
        })

        values["destinationlocationLists"] = list

        this.setState({ data: values, isFormat: true });
      }
    }
  }

  render() {
    return (
      <AuthorizeComponent matching_name="worksheet">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[`${this.state.backUrl}`, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <PageTitleStyled>
            {this.props.match.params.type === "onetime" ? (
              <LangContext.Consumer>
                {(i18n) => i18n.worksheet.worksheetonetime}
              </LangContext.Consumer>
            ) : (
              <LangContext.Consumer>
                {(i18n) => i18n.worksheet.worksheetroutine}
              </LangContext.Consumer>
            )}
          </PageTitleStyled>
          <Spin spinning={this.props.loading}>
            <Forms
              auth={this.props.auth}
              lang={this.props.langBtn}
              createMasterDataPlace={this.props.createMasterDataPlace}
              type={this.props.match.params.type}
              data={this.state.data}
              disable={this.state.disable}
              backUrl={this.state.backUrl}
              displayUploadImg={this.state.displayUploadImg}
              mode={this.state.mode}
            />
          </Spin>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ auth, productOwnerAllwork, auth: { profile }, ui: {langBtn} }) => ({
    auth,
    profile,
    data: productOwnerAllwork.data,
    loading: productOwnerAllwork.loading,
    langBtn
  }),
  {
    createMasterDataPlace: createMasterDataPlace.request,
    removeLists,
    showWorksheet: actions.showWorksheet.request,
  }
)(Edit);

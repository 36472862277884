import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router-dom";
import { Divider, Row, Col, Table, Button, Spin, Pagination, message, Tooltip } from "antd";
import { i18n } from "modules/shared/context/langContext";

import AuthorizeComponent from "../../../../../auth/components/AuthorizeComponent";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import * as moment from "moment";

import "../../../../../../styled/transport-dashboard.css";
import { translate } from "../../../../../../lib/helper"


const columnStyle = { fontSize: "12px", fontWeight: "400" };
const tableHeader = [
  {
    idxLang: "m",
    keyLang: "md_job",
    dataKey: "document_number",
    sorter: false,
    render: "normal",
    width: "10%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_product",
    dataKey: "product_type",
    sorter: true,
    render: "normal",
    width: "14%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_departure",
    dataKey: "origin",
    sorter: true,
    render: "address",
    width: "14%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_destination",
    dataKey: "destination",
    sorter: false,
    render: "address",
    width: "14%",
    cls: "vertical-top",
  },
  {
    idxLang: "m",
    keyLang: "md_porter",
    dataKey: "staff_lift",
    sorter: true,
    render: "staff_lift",
    width: "7%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_payment_status",
    dataKey: "service_payment_type",
    sorter: true,
    render: "payment_type",
    width: "8%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_vehicle_type",
    dataKey: "vehicle_type",
    sorter: true,
    render: "normal",
    width: "10%",
    cls: "vertical-top algin-center",
  },
  {
    idxLang: "m",
    keyLang: "md_process",
    dataKey: "worksheet_id",
    sorter: false,
    render: "action",
    width: "14%",
    cls: "vertical-top algin-center",
  },
];

const renderAddress = (val, key) => {if(typeof val === "undefined") return ""
  let items = val.split(",")
  let line = 1;
  let withLine = key === "origin" ? false : true

  if(val === "") {
    return <p></p>
  }

  return items.map((item, i) => {
    let txt = item.split(" (")
    if(withLine)
  return <p key={i}>{line+i}. {txt[0]} <br/> ({txt[1]}</p>
    else 
      return <p key={i}>{txt[0]} <br/> ({txt[1]}</p>
  })
}

export class Layout extends Component {
  state = {
    page: 1,
    currentPage: 1,
    pageSize: 10,
    orderBy: 'worksheets.created_at',
    orderType: 'desc',
    filters: []
  };

  genTableHeader = () => {
    let self = this
    const columns = [];
    tableHeader.map((v) => {
      columns.push({
        title: translate(`${v.keyLang}`, `${v.idxLang}`),
        dataIndex: `${v.dataKey}`,
        key: `${v.dataKey}`,
        sorter: v.sorter,
        width: v.width,
        className: v.cls,
        render(text, record, index) {
          return {
            props: {
              style: columnStyle,
            },
            children: self.renderComponent(v.render, record, v.dataKey),
          };
        },
      });
    });
    return columns;
  };

  renderComponent = (type, val, key) => {
    switch (type) {
      case "action":
        let cls =
          val.qt_status === "accept"
            ? "btn_status_success"
            : "btn_status_danger";
        return this.renderActionBtn(val, cls);
        case "staff_lift":
          let keyInx = val[key] === "มี" ? "have_porter" : "have_no_porter"
          return <div>{translate(keyInx, "h")}</div>;
        case "address":
            return renderAddress(val[key], key)
        case "payment_type":
          return <div>{translate(val[key], "worksheet")}</div>;
      default:
        return this.renderNormalTxt(val[key], key);
    }
  };

  renderNormalTxt = (val, key) =>  {
    let txt = ""
    switch(key) {
      case "vehicle_type" : txt = i18n[this.props.langBtn]["vehicle_type"][val]; break;
      case "product_type" : txt = i18n[this.props.langBtn]["m_product_type"][val]; break;
      default: txt = val; break;
    }

    if(typeof txt !== "undefined") {
      if (txt.length > 55)
      return  <Tooltip title={txt}><span>{txt.substr(0, 55) + "..."}</span></Tooltip>;
    else 
      return <span>{txt}</span>;
    } else {
      return <span>{val}</span>;
    }

  }
  
  
  getProcessBtn = (process, val, cls) => {
    if (process === "accept") {
      return (
        <Link
          to={`/dashboard/work-quotation/view/${val.worksheet_id}/${val.qt_id}/${val.vehicle_type_id}`}
        >
          <Button data-cy={`btn-confirm-${val.worksheet_id}`} className={cls} size="large">
            {translate("confirm", "c")}
          </Button>
        </Link>
      );
    }

    if (process === "reject") {
      return (
        <div style={{ height: "38px", padding: "20px" }}>
          <span style={{ color: "red", fontSize: "15px" }}>
            {translate("reject", "r")}
          </span>
        </div>
      );
    }

    if (process === "return") {
      return (
        <div style={{ height: "38px", padding: "20px" }}>
          <span style={{ fontSize: "15px" }}>
            {translate("return", "assign")}
          </span>
        </div>
      );
    }

    if (process === "cancel") {
      return (
        <div style={{ height: "38px", padding: "20px" }}>
          <span style={{ fontSize: "15px" }}>
          {translate("cancel", "c")}
          </span>
        </div>
      );
    }

    if (process === "complete") {
      return (
        <div style={{ height: "38px", padding: "20px" }}>
          <span style={{ color: "#1890ff", fontSize: "15px" }}>
          {translate("assign_f", "assign")}
          </span>
        </div>
      );
    }

    if (process === "waiting") {
      return (
        <Button
          data-cy={`btn-update-${val.worksheet_id}`}
          className="btn_status"
          size="large"
          onClick={this.updateStatusQt(val)}
        >
          {translate("cancel", "c")}
        </Button>
      );
    }
  };

  renderActionBtn = (val, cls) => (
    <div>
      {this.getProcessBtn(val.qt_status, val, cls)}

      <br />
      <span className="md_txt_announcement">
      {translate("announcement", "a")}
        &nbsp;&nbsp;{moment(val.announce_date).format("DD/MM/YYYY")}
      </span>
    </div>
  );

  handleTableChange = (pagination, filters, sorter) => {
    let orderBy = "";
    let orderType = "";

    if (Object.keys(sorter).length === 0 && sorter.constructor === Object) {
      orderBy = "worksheets.created_at";
      orderType = "asc";
    } else {
      orderBy = this.getRealKey(sorter.columnKey);
      orderType = sorter.order == "descend" ? "desc" : "asc";
    }

    this.setState({
      orderBy,
      orderType
    });

    this.props.loadTransportQT(
      this.state.currentPage,
      this.state.pageSize,
      orderBy,
      orderType,
      this.state.filters
    );
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page: page, currentPage: page });
    this.props.loadTransportQT(
      page,
      pageSize,
      this.state.orderBy,
      this.state.orderType,
      this.state.filters
    );
  };

  getRealKey = key => {
    switch(key){
      case "product_type" : return "m_product_type.name"
      case "origin" : return "locations.name"
      case "vehicle_type" : return "vehicle_types.name"
      case "status" : return "worksheets.status"
      default: return key
    }
  }

  updateStatusQt = (val) => () => {
    this.props.updateStatusQt(val.worksheet_id, val.qt_id, "cancel");
  };

  componentDidMount() {
    const { page, pageSize, orderBy, orderType, filters } = this.state;
    this.props.loadTransportQT(page, pageSize, orderBy, orderType, filters);
  }

  componentDidUpdate() {
    if (this.props.success === true) {
      message.success(i18n[this.props.langBtn].s.success);
    }
    if (this.props.success === false) {
      message.error(i18n[this.props.langBtn].f.fail);
    }
  }

  render() {
    const columns = this.genTableHeader();
    return (
      <AuthorizeComponent matching_name="quotation_dashboard">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                customeurl={[null, null]}
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Divider orientation="left">
                {translate("dashboard_work_qt", "d")}
              </Divider>
            </Col>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <Table
                  data-cy="data-table"
                  rowKey="id"
                  columns={columns}
                  dataSource={this.props.data}
                  bordered
                  // size="small"
                  pagination={false}
                  onChange={this.handleTableChange}
                />
              </Spin>
            </Col>
          </Row>
          <Row type="flex" justify="end" style={{ padding: "15px" }}>
            <Col span={24} align="right">
              <Pagination
                data-cy="pagination"
                defaultPageSize={this.state.pageSize}
                size="small"
                current={this.state.currentPage}
                total={this.props.totalRows}
                onChange={this.handlePageChange}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ transportqt, ui: {langBtn} }) => ({
  data: transportqt.lists,
  totalRows: transportqt.totalRows,
  loading: transportqt.loading,
  success: transportqt.success,
  langBtn,
});

const mapDispatchToProps = {
  loadTransportQT: actions.loadTransportQT.request,
  updateStatusQt: actions.updateStatusQt.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

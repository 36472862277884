import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
import LangContext from "modules/shared/context/langContext";
import { StyledSider, StyledImageHeader } from "../../../styled/common-styled";
import { AutorizeMenu } from "../../shared/components/CustomMenu";

import { autoGenKey } from "../../../lib/helper";

class SliderBar extends Component {
  render() {
    const { collapsed } = this.props;

    const { permissions } = this.props.profile;

    return (
      <StyledSider trigger={null} collapsible collapsed={collapsed}>
        <Link to="/">
          <StyledImageHeader>
            <img style={{ width: "60px" }} src="/img/kk_logo.png" alt="" />
          </StyledImageHeader>
        </Link>

        <Menu theme="dark" mode="inline" inlineIndent={0}>
          {/* ข้อมูลบริษัท */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="user" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.companyProfile}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              //className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="company_profile"
            >
              <Link to="/companyprofile">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.companyProfile}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ ข้อมูลบริษัท */}

          {/* แดชบอร์ด */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="dashboard" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="transport_market_dashboard"
            >
              <Link to="/dashboard/transport-market">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard_transport_market}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="mywork_dashboard"
            >
              <Link to="/dashboard/mywork">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard_your_work}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="quotation_dashboard"
            >
              <Link to="/dashboard/work-quotation">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard_work_qt}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="work_in_process_dashboard"
            >
              <Link to="/dashboard/work-inprogress">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard_work_process}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="company_group_dashboard"
            >
              <Link to="/productowner/addcompanygroup">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.md_companygroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="allwork_dashboard"
            >
              <Link to="/productowner/allwork">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard_your_work}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="workinprogress_dashboard_prd"
            >
              <Link to="/productowner/work-inprogress">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard_work_process}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

          </AutorizeMenu>
          {/* จบ แดชบอร์ด */}

          {/* สำหรับผู้ดูแลระบบ */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="team" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.admin_system}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="company_verify"
            >
              <Link to="/companyverify">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.company_verify}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="vehicle_verify"
            >
              <Link to="/vehicleverify">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.v.vehicle_verify}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

          </AutorizeMenu>
          {/* จบ สำหรับผู้ดูแลระบบ */}


          {/* ข้อมูลหลัก */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="folder" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.masterData}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.SubMenu
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.v.vehicleAndDriver}
                </LangContext.Consumer>
              }
              // className={collapsed ? "" : "menu-item-wrap"}
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="vehicle"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/vehicle">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicle}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="vehiclegroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/vehiclegroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicleGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="logisticgroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/logisticgroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.logisticGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deviceuser"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deviceuser">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.chauffeur}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="parttype"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/parttype">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.partType}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="partgroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/partgroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.partgroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="refuel"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/refuel">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.reFuelTitle}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="insurance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/insurance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.i.insurance}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="maintenance"
              >
                <Link to="/maintenance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.maintenance}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.l.locationAndRoute}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="location"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/location">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.Location}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="locationgroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/locationgroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.locationGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="nearbylocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/nearbylocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.nearByLocation}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="zone"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/zone">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.z.zone}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="route"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/route">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.route}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="expense"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/route-expense">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.expenses}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="customer"
            >
              <Link to="/customer">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.customer}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="pull_vehicle_sink"
            >
              <Link to="/pullvehiclesink">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.pullvehiclesink}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="sendemail"
            >
              <Link to="/verifyemail">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.accountVerification}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ ข้อมูลหลัก */}

          {/* สร้างใบงาน */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="form" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.worksheet}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="worksheet"
            >
              <Link to="/worksheet/onetime">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.worksheetonetime}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="worksheet"
            >
              <Link to="/worksheet/routine">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.worksheetroutine}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ สร้างใบงาน */}

          {/* Import สร้างใบงาน */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="import" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.importworksheet}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="downloadworksheet"
            >
              <Link to="/downloadworksheet/onetime">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.downloadworksheetonetime}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="downloadworksheet"
            >
              <Link to="/downloadworksheet/routine">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.downloadworksheetroutine}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="importworksheet"
            >
              <Link to="/importworksheet/onetime">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.importworksheetonetime}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="importworksheet"
            >
              <Link to="/importworksheet/routine">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.worksheet.importworksheetroutine}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ สร้างใบงาน */}

          {/* การจัดการ */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="folder-open" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.management_menu}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item key={autoGenKey("slider-sub-menu")} matching_name="user">
              <Link to="/user">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.u.user}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="usergroup"
            >
              <Link to="/usergroup">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.u.userGroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="datavisibility"
            >
              <Link to="/datavisibility">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="permission"
            >
              <Link to="/permission">
                <span className="nav-text">Permission</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="partner"
            >
              <Link to="/partner">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.partnerCar}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="vehicle_sharing"
            >
              <Link to="/vehicle_sharing">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.v.vehicleSharing}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="sharelocation"
            >
              <Link to="/sharelocation">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.shareLocation}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="gallery"
            >
              <Link to="/gallery">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.g.gallery}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="management_contactmanagement"
            >
              <Link to="/management/contactmanagement">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.Contactmanagement}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="management_downloadcertificate"
            >
              <Link to="/management/downloadcertificate">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.downloadcertificate}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ การจดการ */}

          {/* การจัดกการภายใน */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="audit" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.i.internalOperation}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.deliveryItem}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deliveryitem"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deliveryitem">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.deliveryItemPool}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deliveryitem_delete_history"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deliveryitem_delete_history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.deliveryItemDeleteHistoryPool}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey("slider-sub-menu")}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.g.gpsCertification}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="gps_certification_request"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/gps_certification_request">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.g.gpsCertRequest}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                className={collapsed ? "" : "menu-item-wrap"}
                matching_name="gps_certification_request_history"
              >
                <Link to="/gps_certification_request_history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.g.gpsCertRequestHistory}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey("slider-sub-menu")}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.a.announcement}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                className={collapsed ? "" : "menu-item-wrap"}
                matching_name="announcement_broadcast"
              >
                <Link to="/announcement_broadcast">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.announcementBroadcast}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="company"
            >
              <Link to="/company">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.company}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ การจัดกการภายใน */}

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="retweet" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.deliver}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item key={autoGenKey("slider-sub-menu")} matching_name="trip">
              <Link to="/trip">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.trip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="delivery_trip"
            >
              <Link to="/deliverytrip">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.deliverytrip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="route_master"
            >
              <Link to="/routemaster">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.routemaster}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="question" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.h.help_menu}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="servicerepairdevice"
            >
              <Link to="/servicerepairdevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.Servicerepairdevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="servicemovedevice"
            >
              <Link to="/servicemovedevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.moveDevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="serviceinstalldevice"
            >
              <Link to="/serviceinstalldevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.installDevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item> */}
          </AutorizeMenu>

          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="setting" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.setting}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="policy"
            >
              <Link to="/policy">
                <Icon type="read" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policy}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
        </Menu>
      </StyledSider>
    );
  }
}

// export default SliderBar;
const mapStateToProps = ({ auth: { profile } }) => ({
  profile,
});

export default connect(mapStateToProps, null)(SliderBar);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import * as actions from "../actions";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { checkPermissions } from "../../../../lib/helper";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";

import { Button, Row,  Form, Col } from "antd";

import {
  StyledSearchForm,
  GeneralStyledContent,
  PageTitleStyled,
} from "../../../../styled/backhaul-styled";


const { Item } = Form;

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        origin_location_name: "",
        destination_location_name: "",
        vehicle_type: "",
        distance: "",
        price: "",
        price_trip: "",
        price_unit: "",
        price_trip_unit: "",
      },
    };
  }

  componentDidMount() {
    this.props.getExpense(this.props.match.params.id);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { expense } = nextProps;
    const { dataExpense } = expense;

    if (this.props.expense.check_expense !== nextProps.expense.check_expense) {
      this.setState({
        data: {
          vehicle_type: dataExpense.vehicle_type,
          price_unit: dataExpense.price_unit,
          price_trip_unit: dataExpense.price_trip_unit,
          origin_location_name: dataExpense.origin_location_name,
          destination_location_name: dataExpense.destination_location_name,
          distance: dataExpense.distance,
          price: parseFloat(dataExpense.price),
          price_trip: parseFloat(dataExpense.price_trip),
        },
      });
    }
    return true;
  }

  render() {
    return (
      <AuthorizeComponent matching_name="expense">
        <GeneralStyledContent>
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row>
            <PageTitleStyled>
              <LangContext.Consumer>
                {(i18n) => i18n.expense.expenseinfo}
              </LangContext.Consumer>
            </PageTitleStyled>
            <Col sm={24} md={24} lg={24} xl={24}>
              <StyledSearchForm>
                <Formik
                  enableReinitialize={true}
                  initialValues={this.state.data}
                  validate={(values) => {
                    let errors = {};

                    return errors;
                  }}
                  validationSchema={yup.object().shape({})}
                  onSubmit={(values, actions) => {
                    // this.showConfirm(values);
                  }}
                  render={({
                    values,
                    errors,
                    status,
                    touched,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <div>
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Row gutter={24}>
                          <Col sm={24} md={1} lg={1} xl={1}></Col>
                          <Col sm={24} md={7} lg={7} xl={7}>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.veh.vehicle_type}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.vehicle_type}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.expense.origin_location}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.origin_location_name}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.expense.price}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.price}</p>
                              </Col>
                            </Row>

                            {/* <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.expense.price_trip}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.price_trip}</p>
                              </Col>
                            </Row> */}
                          </Col>

                          <Col sm={24} md={7} lg={7} xl={7}>
                            <Row style={{ marginTop: "35px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              ></Col>
                              <Col span={14} style={{ padding: "5px" }}></Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) =>
                                        i18n.expense.destination_location
                                      }
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.destination_location_name}</p>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.expense.price_unit}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.price_unit}</p>
                              </Col>
                            </Row>

                            {/* <Row style={{ marginTop: "5px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.expense.price_unit}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.price_trip_unit}</p>
                              </Col>
                            </Row> */}
                          </Col>

                          <Col sm={24} md={7} lg={7} xl={7}>
                            <Row style={{ marginTop: "35px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              ></Col>
                              <Col span={14} style={{ padding: "5px" }}></Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                              <Col
                                span={10}
                                style={{ padding: "5px" }}
                                align="right"
                              >
                                <p>
                                  {
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.expense.distance}
                                    </LangContext.Consumer>
                                  }
                                  :
                                </p>
                              </Col>
                              <Col span={14} style={{ padding: "5px" }}>
                                <p>{values.distance}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}></Col>
                          <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                            <Item>
                              <div>
                                <Link
                                  to={`/route-expense`}
                                  style={{ padding: "0px 15px 0px 5px" }}
                                >
                                  <Button
                                    type="default"
                                    style={{
                                      marginLeft: "5px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <LangContext.Consumer>
                                      {(i18n) => i18n.b.back}
                                    </LangContext.Consumer>
                                  </Button>
                                </Link>
                                {checkPermissions(
                                  this.props.auth.profile.permissions,
                                  "expense",
                                  "can_edit"
                                ) == true ? (
                                  <Link
                                    to={`/route-expense/edit/${this.props.match.params.id}`}
                                  >
                                    <Button type="primary">
                                      <LangContext.Consumer>
                                        {(i18n) => i18n.e.edit}
                                      </LangContext.Consumer>
                                    </Button>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                />
              </StyledSearchForm>
            </Col>
            {/* <Col  sm={24} md={2} lg={2} xl={2}></Col> */}
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}
export default connect(({ expense, auth }) => ({ expense, auth }), {
  getExpense: actions.getExpenseByID.request,
})(Detail);

import {
  LOAD_TRANSPORT_QUOTATION,
  SHOW_TRANSPORT_QUOTATION,
  ASSIGN_JOB,
  UPDATE_STATUS_QT
} from "./actions";

const initialState = {
  lists: [],
  totalRows: 0,
  loading: false,
  data: {},
  success: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSPORT_QUOTATION.REQUEST:
      return {
        ...state,
        success: null,
        loading: true,
      };
    case LOAD_TRANSPORT_QUOTATION.SUCCESS:
      return {
        lists: action.payload.lists.data,
        totalRows: action.payload.lists.total,
        loading: false,
      };
    case LOAD_TRANSPORT_QUOTATION.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SHOW_TRANSPORT_QUOTATION.REQUEST:
      return {
        ...state,
        success: null,
        loading: true,
      };
    case SHOW_TRANSPORT_QUOTATION.SUCCESS:
      return {
        data: action.payload.data.data,
        loading: false,
      };
    case SHOW_TRANSPORT_QUOTATION.FAILURE:
      return {
        ...state,
        loading: false,
      };

      case ASSIGN_JOB.REQUEST:
        return {
          ...state,
          success: null,
          loading: true,
        };
      case ASSIGN_JOB.SUCCESS:
        return {
          data: action.payload.data.data,
          loading: false,
          success: true
        };
      case ASSIGN_JOB.FAILURE:
        return {
          ...state,
          loading: false,
          success: false
        };
  
        case UPDATE_STATUS_QT.REQUEST:
          return {
            ...state,
            success: null,
            loading: true,
          };
        case UPDATE_STATUS_QT.SUCCESS:
          const newState = state.lists
          const idx = newState.findIndex(x => x.qt_id == action.payload.data.qt_id && x.worksheet_id === action.payload.data.worksheet_id)
          if (idx !== -1) {
            newState[idx].qt_status = action.payload.data.qt_status
          }
          return {
            ...state,
            lists: newState,
            loading: false,
            success: true
          };
        case UPDATE_STATUS_QT.FAILURE:
          return {
            ...state,
            loading: false,
            success: false
          };

    default:
      return state;
  }
};

import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Indexvehicle from "./Index";
import AddVehicle from "./FormAddVehicle";
import MappingDriver from "./MappingDriver";
import EditVehicle from "./FormEditVehicle";
import Detail from "./FormDetailVehicle";
import PartGroup from "./FormEditPartGroup";

export default () => (
    <Router>
      <div>
        <Route exact path="/vehicle" component={Indexvehicle} />
        <Route exact path="/vehicle/create" component={AddVehicle} />
        <Route exact path="/vehicle/mapppingdriver" component={MappingDriver} />
        <Route exact path="/vehicle/edit/:id" component={EditVehicle} />
        <Route exact path="/vehicle/detail/:id" component={Detail} />
        <Route exact path="/vehicle/parttype/:id/:vehicle_companies_id" component={PartGroup} />
      </div>
    </Router>
  );
  
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  List,
  Row,
  Col,
  Button,
  Descriptions,
  Popconfirm,
  Tooltip,
  Icon,
  Spin,
  Modal,
} from "antd";
import Contactform from "./Contactform";
import LangContext, { i18n } from "modules/shared/context/langContext";
import axios from "axios";
import "../../../styled/contact.css";
import { checkContact } from "../actions";
class Contact extends Component {
  state = {
    contactcompany: [],
    loading: false,
    edit: false,
    add: false,
    contact: {
      title_name: "mr",
      firstname: "",
      lastname: "",
      position: "",
      phone: "",
      email: "",
    },
    contact_id: 0,
  };
  componentDidMount() {
    //console.log("props", this.props);
    //console.log("componentdidmount");
    this.getContactcompany();
  }

  onSubmit = (values) => {
    let self = this;
    const { title_name, firstname, lastname, position, phone, email } = values;
    self.setState({ loading: true });
    if (this.state.contact_id != 0) {
      //edit
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contactcompany/update`,
          {
            id: self.props.id,
            title_name,
            firstname,
            lastname,
            position,
            phone,
            email,
            contact_id: self.state.contact_id,
          }
        )
        .then(function(response) {
          if (response.data.status === "fail") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.f.fail_save}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          } else {
            self.cancelContact();
          }
          self.setState({
            loading: false,
            contact_id: 0,
            contact: {
              title_name: "mr",
              firstname: "",
              lastname: "",
              position: "",
              phone: "",
              email: "",
            },
          });
        })
        .catch(function(error) {
          console.log(error);
          self.setState({ loading: false });
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contactcompany/create`,
          {
            id: self.props.id,
            title_name,
            firstname,
            lastname,
            position,
            phone,
            email,
          }
        )
        .then(function(response) {
          // console.log(response.data.status);
          if (response.data.status === "fail") {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.f.fail_save}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          } else {
            self.getContactcompany();
          }
          self.setState({ loading: false });
        })
        .catch(function(error) {
          console.log(error);
          self.setState({ loading: false });
          Modal.error({
            content: (
              <LangContext.Consumer>
                {(i18n) => i18n.f.fail_save}
              </LangContext.Consumer>
            ),
            okButtonProps: { "data-cy" : "btn-modal-ok" }
          });
        });
    }
  };

  getContactcompany = () => {
    let self = this;

    axios
      .post(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contactcompany`, {
        id: self.props.id,
      })
      .then(function(response) {
        //console.log(response.data);
        if (response.data.data.length === 0) {
          self.props.checkContact(false);
        } else {
          self.props.checkContact(true);
        }
        self.setState({ contactcompany: response.data.data, add: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  deleteContact = (id) => {
    let self = this;

    axios
      .get(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/contactcompany/delete/${id}`,
        {}
      )
      .then(function(response) {
        self.getContactcompany();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  editContact = (
    id,
    title_name,
    firstname,
    lastname,
    position,
    phone,
    email
  ) => {
    if (this.state.add === true) {
      this.setState({
        contactcompany: [
          ...this.state.contactcompany.slice(
            1,
            this.state.contactcompany.length
          ),
        ],
      });
    }
    this.setState({
      contact_id: id,
      contact: {
        title_name,
        firstname,
        lastname,
        position,
        phone,
        email,
      },
    });
  };

  addContact = (id) => {
    this.setState({
      contactcompany: [{ id: 0 }, ...this.state.contactcompany],
      add: true,
    });
    //alert(id);
  };

  cancelContact = () => {
    if (this.state.contact_id !== 0) {
      this.setState({
        add: false,
        contact_id: 0,
        contact: {
          title_name: "mr",
          firstname: "",
          lastname: "",
          position: "",
          phone: "",
          email: "",
        },
      });
    } else {
      this.setState({
        contactcompany: [
          ...this.state.contactcompany.slice(
            1,
            this.state.contactcompany.length
          ),
        ],
        add: false,
        contact_id: 0,
        contact: {
          title_name: "mr",
          firstname: "",
          lastname: "",
          position: "",
          phone: "",
          email: "",
        },
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Spin spinning={this.state.loading}>
          <div
            style={{
              border: "2px solid #BEC2C2",
              padding: "10px 10px 10px 10px",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.contactcompany}
              header={
                <Row>
                  <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                    {this.state.add === false ? (
                      <Button type="primary" onClick={() => this.addContact()}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.add_contact}
                        </LangContext.Consumer>
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              }
              renderItem={(item) => (
                <List.Item>
                  {item.id === 0 ? (
                    <Contactform
                      userid={this.props.id}
                      contact={this.state.contact}
                      cancelContact={this.cancelContact}
                      onSubmit={this.onSubmit}
                    />
                  ) : item.id === this.state.contact_id ? (
                    <Contactform
                      userid={this.props.id}
                      contact={this.state.contact}
                      cancelContact={this.cancelContact}
                      onSubmit={this.onSubmit}
                    />
                  ) : (
                    <Fragment>
                      <Descriptions
                        title={
                          <LangContext.Consumer>
                            {(i18n) =>
                              i18n.m[item.title_name] +
                              item.firstname +
                              " " +
                              item.lastname
                            }
                          </LangContext.Consumer>
                        }
                        column={4}
                      >
                        <Descriptions.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.position}
                            </LangContext.Consumer>
                          }
                        >
                          {item.position}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.p.phone}
                            </LangContext.Consumer>
                          }
                        >
                          {item.phone}
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={
                            <LangContext.Consumer>
                              {(i18n) => i18n.e.email}
                            </LangContext.Consumer>
                          }
                        >
                          {item.email}
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <LangContext.Consumer>
                            {(i18n) => (
                              <Tooltip
                                placement="left"
                                title={i18n.e.edit}
                                arrowPointAtCenter
                                style={{
                                  padding: "0px 1px 0px 0px",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                <a
                                  onClick={() =>
                                    this.editContact(
                                      item.id,
                                      item.title_name,
                                      item.firstname,
                                      item.lastname,
                                      item.position,
                                      item.phone,
                                      item.email
                                    )
                                  }
                                  style={{ padding: "0px 5px 0px 5px" }}
                                >
                                  <Icon type="edit" />
                                </a>
                              </Tooltip>
                            )}
                          </LangContext.Consumer>
                          <LangContext.Consumer>
                            {(i18n) => (
                              <Tooltip
                                placement="left"
                                title={i18n.d.delete}
                                arrowPointAtCenter
                                style={{
                                  padding: "0px 1px 0px 0px",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                <Popconfirm
                                  title={i18n.c.confirmToDeleteThisItem}
                                  onConfirm={() => {
                                    let self = this;
                                    self.deleteContact(item.id);
                                  }}
                                  cancelText={i18n.c.cancel}
                                  okText={i18n.o.ok}
                                >
                                  <a
                                    href="javascript:;"
                                    style={{ padding: "0px 5px 0px 5px" }}
                                  >
                                    <Icon type="delete" />
                                  </a>
                                </Popconfirm>
                              </Tooltip>
                            )}
                          </LangContext.Consumer>
                        </Descriptions.Item>
                      </Descriptions>
                    </Fragment>
                  )}
                </List.Item>
              )}
            />
          </div>
        </Spin>
      </Fragment>
    );
  }
}

export default connect(({ auth }) => ({ auth }), {
  checkContact: checkContact,
})(Contact);

import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";

import * as actions from "./actions";
import moment from "moment";

function* loadExpense(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const company_id = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );

  const {
    data: { page, pageSize, orderBy, orderType, filterObj },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/route-expense`,
      {
        page,
        pageSize,
        orderBy,
        orderType,
        filterObj,
        company_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadExpense.success(res.data));
  } catch (err) {
    yield put(actions.loadExpense.failure());
  }
}

function* createExpense(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const company_id = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  // const user_id = getlocalstorage(localStorage.getItem("profile"), "id");

  const {
    data: {
      vehicle_type_id,
      distance,
      price_unit_id,
      price,
      price_trip,
      price_trip_unit_id,
      origin_location_id,
      destination_location_id,
    },
    history,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/route-expense/create`,
      {
        company_id,
        vehicle_type_id,
        distance,
        price_unit_id,
        price,
        price_trip,
        price_trip_unit_id,
        origin_location_id,
        destination_location_id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.createExpense.success(res.data));
    history.push("/route-expense");
    console.log("success");
  } catch (err) {
    yield put(actions.createExpense.failure());
  }
}

function* updateExpense(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");

  const {
    data: {
      vehicle_type_id,
      distance,
      price_unit_id,
      price,
      price_trip,
      price_trip_unit_id,
      origin_location_id,
      destination_location_id,
    },
    id,
    history,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/route-expense/update`,
      {
        vehicle_type_id,
        distance,
        price_unit_id,
        price,
        price_trip,
        price_trip_unit_id,
        origin_location_id,
        destination_location_id,
        id,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.updateExpense.success(res.data));
    history.push("/route-expense");
  } catch (err) {
    yield put(actions.updateExpense.failure());
  }
}

function* getExpenseByID(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const { id } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/route-expense/${id}`,
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );

    yield put(actions.getExpenseByID.success(res.data));
  } catch (err) {
    yield put(actions.getExpenseByID.failure());
  }
}

export default function* watchReportPoolState() {
  yield all([
    takeEvery(actions.LOAD_EXPENSE.REQUEST, loadExpense),
    takeEvery(actions.CREATE_EXPENSE.REQUEST, createExpense),
    takeEvery(actions.UPDATE_EXPENSE.REQUEST, updateExpense),
    takeEvery(actions.GET_EXPENSE.REQUEST, getExpenseByID),
  ]);
}

export { loadExpense, createExpense, updateExpense, getExpenseByID };

import React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./Layout";
import Detail from "./Detail"

export default () => (
    <Switch>
        <Route exact path="/dashboard/work-inprogress" component={Layout} />
        <Route exact path="/dashboard/work-inprogress/view/:id" component={Detail} />
    </Switch>
);
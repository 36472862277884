import React, { Component } from "react";
import { connect } from "react-redux";

import {
  StyledDiv,
  StyledRow,
  StyledCircleStep,
  StyledStepFloat,
} from "../../../styled/common-styled";
import { firebase } from "../../../lib/fcm/fcm_init";
import Form from "./SignupForm";
import { setLoading } from "../actions";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Modal } from "antd";
import axios from "axios";

const { confirm } = Modal;
class Signup extends Component {
  state = {
    valueSignUp: {
      email: "",
      phone: "",
      password: "",
      trypassword: "",
    },
    user_type: "",
    by: "",
    step: "1/3",
    companytypeLists: [],
  };

  componentDidMount() {
    this.setState({
      user_type: this.props.match.params.usertype,
      by: this.props.match.params.by,
      step: this.props.match.params.usertype !== "productowner" ? 4 : 3,
    });
    this.companyType();
  }

  onSubmit = (values) => {
    const self = this;
    self.props.setLoading(true);
    fetch(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/user/duplicateuser`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: values.phone,
        email: values.email,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === "false") {
          this.props.history.push({
            pathname: "/signup/verify/" + this.state.user_type + "/signup",
            state: { values: values },
          });
        } else {
          if (res.msg === "data_not_complete") {
            confirm({
              title: (
                <LangContext.Consumer>
                  {(i18n) => i18n.signup.signup_not_complete2}
                </LangContext.Consumer>
              ),
              okText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.o.ok}
                </LangContext.Consumer>
              ),
              cancelText: (
                <LangContext.Consumer>
                  {(i18n) => i18n.c.cancel}
                </LangContext.Consumer>
              ),
              content: "",
              onOk() {
                window.location = res.path;
              },
              onCancel() {
                console.log("Cancel");
              },
            });
          } else {
            Modal.error({
              content: (
                <LangContext.Consumer>
                  {(i18n) => i18n.signup.signup_duplicate}
                </LangContext.Consumer>
              ),
              okButtonProps: { "data-cy" : "btn-modal-ok" }
            });
          }
        }

        self.props.setLoading(false);
      });
  };

  companyType = () => {
    let self = this;

    axios
      .get(`${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getcompanytype`, {})
      .then(function(response) {
        //console.log("responsecom", response.data);
        self.setState({ companytypeLists: response.data.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    return (
      <StyledDiv>
        <StyledRow>
          <StyledStepFloat>
            {/* <StyledCircleStep>{this.state.step}</StyledCircleStep> */}
          </StyledStepFloat>
          <Form
            auth={this.props.auth}
            onSubmit={this.onSubmit}
            firebase={firebase}
            step={this.state.step}
            companytypeLists={this.state.companytypeLists}
          />
        </StyledRow>
      </StyledDiv>
    );
  }
}

export default connect(
  ({ token, profile, auth }) => ({ token, profile, auth }),
  {
    setLoading: setLoading,
  }
)(Signup);

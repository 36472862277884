import styled from "styled-components";
import { Layout, Row, Col, Typography, Button } from "antd";
const { Sider } = Layout;
const { Paragraph } = Typography;

const StyledImageHeader = styled.h2`
  padding: 7px 0px 0px 12px;
  height: 45px;
  width: 270px;
  background-color: white;
  line-height: 15px;
`;

const StyledHeader1 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 3em;
`;

const StyledHeader2 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 2em;
`;

const StyledButton = styled.button`
  color: red;
  background-color: white;
`;

const StyledSearchForm = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 10px 10px 20px 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

const StyledSearchFormMark = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 24px;
  border-radius: 6px;
`;

const StyledSearchForm3 = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 16px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;

const StyledSearchForm2 = styled.div`
  margin: 15px 15px 15px 15px;
  padding: 15px;
  border: 1px solid #000000;
  border-radius: 6px;
`;

const StyledWidget = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03),
    0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03),
    0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05),
    0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
`;

const StyledWidgetShadowOnly = styled.div`
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03),
    0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03),
    0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05),
    0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03);
`;

const GeneralStyledContent = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 55}px;
`;

const PureBackgroundStyledContent = styled(Layout.Content)`
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 55}px;
`;

const TrackingStyledContent = styled(Layout.Content)`
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  min-height: ${window.innerHeight - 55}px;
`;

const ReplayStyledContent = styled(Layout.Content)`
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
`;

const StyledSider = styled(Sider)`
  padding: 0;
  max-width: 270;
  min-width: 270;
  width: 270;
`;
const StyledHeader = styled.h2`
  padding: 13px 0px 0px 14px;
  height: 45px;
  width: 270px;
  background-color: white;
  line-height: 15px;
`;

const StyledLogo = styled.img`
  /* margin-left: 22%; */
  margin-top: 100px;
`;

const StyledLogoUserType = styled.img``;

const StyledLogoVerifyEmail = styled.img`
  width: 270px !important;
  max-height: 100% !important;
`;

const StyledDiv = styled.div`
  height: 86%;
  top: 14%;
`;
const StyleBackgroundImage = styled.div`
  background-image: url("/img/background-top.jpg") ;
  min-height: calc(100vh - 102px);
  opacity: 0.2;
  top: ${props => props.top ? props.top : "102px"};
  background-repeat: no-repeat;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;  
`;

const StyledSignUp = styled.div`
  padding-top: 50px;
`;

const StyledBarDiv = styled.div`
  height: 14%;
  background-color: #3b68d9;
  top: 0;
  padding-bottom: 10px;
  color: green;
  @media (max-width: 800px) {
    padding: 10px;
    text-align: right;
  }
`;

const StyledBarDiv2 = styled.div`
  height: 65px;
  background-color: #3b68d9;
  top: 0;
  padding-bottom: 10px;
  color: green;
`;

const StyledRow = styled(Row)``;

const StyledCol = styled(Col)`
  padding-top: 150px;
`;

const StyledFormRegister = styled(Col)`
  padding: 20px;
`;

const StyledColVerify = styled(Col)`
  padding-top: 250px;
`;
const StyledColRegister = styled(Col)`
  padding-top: 50px;
`;
const StyledCircleStep = styled.div`
  border-radius: 50%;
  border: 1px solid black;
  font-size: 22px;
  padding: 5px 5px 5px 5px;
`;

const StyledStepFloat = styled.div`
  float: right;
  clear: both;
  padding-right: 5%;
`;

const StyleFontLanding = styled.div`
    color:#3B68D9;
    padding:4%;
    font-size:35px;
    line-height:5px;
    font-weight: bold;
    font-family:DB Helvethaica X
`;

const ContainerBottomLanding= styled.div`
  height: 800px
  margin: 0;
  @media (max-width: 768px) {
    height: 600px
  }
  @media (max-width: 500px) {
    height: 1200px
  }
`

const StyleBottomLanding = styled.div`
  background-image: url("/img/background-bottom.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
`;

const StyleTitleLanding = styled.h1`
  margin-bottom: 0px;
  color: #3B68D9;
  font-family:LandingFont;
  line-height: 32px;
  font-size: 35px;
  font-weight: 600;
  margin-top: 12px;
  @media (min-width: 1366px) {
    font-weight: 600;
    font-size: 55px;
    line-height: 54px;
    margin-top: 8px;
  }
  @media (max-width: 500px) {
    font-size:29px;
  }
`

const StyleSubTitleLanding = styled.h2`
  margin-bottom: 0px;
  color: #3B68D9;
  margin-top: 12px;
  font-family:LandingFont;
  letter-spacing: 2px;
  line-height: 20px;
  @media (min-width: 1366px) {
    font-weight: 600;
    font-size: 39px;
    line-height: 50px;
    margin-top: 0px;
  }
  @media (max-width: 500px) {
    font-size:18px;
  }
`

const LandingCardMeta = styled.span`
  font-family:LandingFont;
  font-size: 18px;
  color: #3B68D9;
  font-weight: 600;
  letter-spacing: 1px;
`

const LandingParagraph = styled(Paragraph)`
  font-size: 18px;
  font-family:LandingFont;
  color: #3B68D9;
  letter-spacing: 2px;
  margin-bottom: 0.5em;
  @media (max-width: 500px) {
    font-size:14px;
  }
`

const LandingDescription = styled(Row)`
  margin: 100px 200px 100px 200px;
  @media (max-width: 768px) {
    margin: 50px 20px 50px 20px;
  }
`
const LandingBottomTitle = styled(Row)`
  margin-top: 50px;
  margin-bottom: 120px;
`

const LandingHeader = styled.div`
  position: relative;
`

const RegisterButton = styled(Button)`
  position: absolute;
  top: 66%;
  left: 5%;
  font-family:LandingFont;
  font-weight: 600;
  background: #3B68D9;
  border-color: #3B68D9;
  @media (min-width: 1366px) {
    height: 60px;
    width: 212px;
    font-size: 24px;
  }
`

export {
  StyleFontLanding,
  StyledLogoVerifyEmail,
  StyledLogo,
  StyledDiv,
  StyledRow,
  StyledCol,
  StyledColRegister,
  StyledColVerify,
  StyledButton,
  StyledSearchForm,
  StyledSearchForm2,
  StyledSearchForm3,
  StyledSearchFormMark,
  StyledHeader1,
  StyledHeader2,
  GeneralStyledContent,
  PureBackgroundStyledContent,
  TrackingStyledContent,
  ReplayStyledContent,
  StyledSider,
  StyledHeader,
  StyledImageHeader,
  StyledWidget,
  StyledWidgetShadowOnly,
  StyledBarDiv,
  StyledSignUp,
  StyledLogoUserType,
  StyledFormRegister,
  StyledCircleStep,
  StyledStepFloat,
  StyledBarDiv2,
  StyleBottomLanding,
  ContainerBottomLanding,
  StyleTitleLanding,
  StyleSubTitleLanding,
  LandingCardMeta,
  LandingParagraph,
  LandingDescription,
  LandingBottomTitle,
  LandingHeader,
  RegisterButton,
  StyleBackgroundImage
};
